import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Paper, PaperContent, Text, Link, Button, Icon, Divider } from '../../'
import { PetPlusUsLogo, PetWatchLogo } from '../SvgIcons'


export class MerckRedirectLandingPage extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    buttonProps: PropTypes.shape({
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      id: PropTypes.string
    }),
    linkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    })
  }

  render() {
    const { title, id, className, style, refProp, buttonProps, linkProps } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-merck-redirect-landing-page', className)}
        style={style}
        ref={refProp}
        type={Paper.Types.clean}
      >
        <PaperContent>
          <div className='ui-merck-redirect-landing-page--logo'>
            <PetPlusUsLogo />
            <PetWatchLogo />
          </div>
          <div className='ui-merck-redirect-landing-page--main-content'>
            <Text type={Text.Types.headline}>Your Pets Plus Us Insurance policy is now a <span className='ui-merck-redirect-landing-page--span'>24Petwatch</span> Insurance policy.</Text>
            <Button 
              size={Button.Sizes.small}
              color={Button.Colors.tertiary}
              rightIcon={"open_in_new"}
              {...buttonProps}
            >
              Click here to access the 24Petwatch Customer Portal
            </Button>
            <div className='ui-merck-redirect-landing-page--body-text'>
              In future, you can access the 24Petwatch Portal directly at {' '}
              <Link color={Link.Colors.secondary} {...linkProps}>
                www.mypethealth.com
              </Link>
              <Icon type="open_in_new"></Icon>
            </div>
            <Text type={Text.Types.body}>
              Your login credentials and all account information will remain unchanged. All account information including pet, claims history, policy, and billing details will be available.
            </Text>
            <Text className='ui-merck-redirect-landing-page--text'>
              This page will redirect automatically in 10 seconds.
            </Text>
          </div>
          <Divider></Divider>
          <div className='ui-merck-redirect-landing-page--footer'>
            <Text type={Text.Types.caption}>
              Insurance products are underwritten by The North River Insurance Company (NAIC #21105, Morristown, NJ), and produced and administered by PTZ Insurance Agency, Ltd. (NPN: 5328528. Domiciled in Illinois with offices at 1208 Massillon Road, Suite G200, Akron, Ohio 44306). 
              (California residents only: PTZ Insurance Agency, Ltd., d.b.a PIA Insurance Agency, Ltd. CA license #0E36937).
            </Text>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

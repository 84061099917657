import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Icon } from '../../'

export class IconButton extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    type: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      disabled,
      type,
      onClick,
      id,
      style,
      className,
      refProp,
      ...rest
    } = this.props

    return (
      <div
        style={style}
        className={cn(
          'ui-icon-button',
          {
            'ui-icon-button--disabled': disabled
          },
          className
        )}
        ref={refProp}
        role='button'
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        <Icon id={id} type={type} />
      </div>
    )
  }
}

import React from "react";
import {
  NewShoppingCart,
  TwoColumnContent,
  PageHeader,
  PageFooter,
  PageContent,
  DashboardFooter,
  Link
} from "../../../UI-Component-Library";
import { connect } from "react-redux";
import { AppUtils } from "../../../utils/app-utils";
import { withRouter } from "react-router-dom";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import {
  clearCartData,
  saveCartData,
} from "../../../redux/reducers/membership-purchase-reducer";
import { MembershipPurchaseUtils } from "../membership/membership-purchase.util";
import PaymentContainer from "./payment-container";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
import { PageUtils } from "../page-utils";

// Minimal width for the desktop layout to look good, after this break point render mobile view
const MOBILE_VIEW_BREAKPOINT = 927;
class ShoppingCartWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      cartItems: [],
      showPromo: false,
      promoCode: "",
      petCart: {},
      errorMessage: "",
      showMobileCart: false,
      promoCodeErrorMessage: "",
      logos: [],
      legalLines: []
    };
    this.cRef = React.createRef();
  }
  componentDidMount() {
    let cartData = this.props.cartData;
    if (!cartData) {
      cartData = MembershipPurchaseUtils.getCartDataFromLocalStorage();
      this.props.saveCartData(cartData);
    }
    this.extractCartData(cartData);
    this.fetchLogos();
  }
  extractCartData(cartData, isCheckout= false) {
    const orderItems = [];
    const petCart = {};
    if (cartData && cartData.orderLines) {
      for (let order of cartData?.orderLines) {
        if (!petCart[order.animalId]) {
          petCart[order.animalId] = [];
        }
        petCart[order.animalId].push({
          productName: AppUtils.getProductNameFromItemId(order.itemId),
          productCost: AppUtils.getConvertedPrice(order.unitPrice),
          ...order,
        });
      }
    }
    // Show only lpm bundle
    for (let key in petCart) {
      const filteredProducts = [];
      let lpmProduct;
      let lpmTotalPrice = 0;
      let lpmPlusProduct;
      let lpmPlusTotalPrice = 0;
      for (let product of petCart[key]) {
        if (AppUtils.isLpmProduct(product)) {
          lpmProduct = product;
          continue;
        }
        if (product.itemType === "LPMBundleProduct") {
          lpmTotalPrice = lpmTotalPrice + product.unitPrice;
          continue;
        }

        //for lpmPlusMembership
        if (AppUtils.isLpmPlusProduct(product)) {
          lpmPlusProduct = product;
          continue;
        }
        if (AppUtils.isLpmPlusBundleProduct(product)) {
          lpmPlusTotalPrice = lpmPlusTotalPrice + product.unitPrice;
          continue;
        }
        filteredProducts.push(product);
      }
      if (lpmProduct) {
        lpmProduct.productCost = AppUtils.getConvertedPrice(lpmTotalPrice);
        filteredProducts.push(lpmProduct);
      }

      if (lpmPlusProduct) {
        lpmPlusProduct.productCost = AppUtils.getConvertedPrice(lpmPlusTotalPrice);
        filteredProducts.push(lpmPlusProduct);
      }
      petCart[key] = filteredProducts;
    }
    const customerAnimalsInfo = PageUtils.getAnimalDetailsFromLocalStorage();
    for (let key in petCart) {
      orderItems.push({
        title: (isCheckout)
          ? ''
          : MembershipPurchaseUtils.getAnimalName(
              key,
              customerAnimalsInfo
            ),
        items: petCart[key],
      });
    }
    if (isCheckout) {
      return orderItems;
    }
    // DataLayerUtils.viewCartEvent(orderItems, cartData?.total);
    this.setState({
      cartItems: orderItems,
      petCart,
    });
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  applyPromoCode() {
    this.clearErrorMessage();
    if (AppUtils.isEmptyString(this.state.promoCode)) {
      this.setState({
        promoCodeErrorMessage: "Promocode should not be empty",
      });
      return;
    }
    axios
      .processGet(
        `${ApiConstants.URL_APPLY_PROMOCODE}${this.props.cartData.cartId}/${this.state.promoCode}`
      )
      .then((cartData) => {
        this.props.saveCartData(cartData);
        this.setState({
          showPromo: false,
          promoCode: ""
        })
        this.extractCartData(this.props.cartData);
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            promoCodeErrorMessage: errors[0],
          });
        }
      });
  }
  clearErrorMessage() {
    this.setState({
      errorMessage: "",
      promoCodeErrorMessage: "",
    });
  }
  removeOrder(petIndex, itemIndex) {
    this.clearErrorMessage();
    const petId = Object.keys(this.state.petCart)[petIndex];
    const items = this.state.petCart[petId];
    const foundItem = items.find((item, index) => index === itemIndex);
    // DataLayerUtils.removeFromCartEvent(foundItem);
    axios
      .processGet(
        `${ApiConstants.URL_REMOVE_ORDER}${this.props.cartData.cartId}/${
          this.state.promoCode ? this.state.promoCode : "none"
        }/${foundItem.itemType}/${foundItem.basketLineId}`,
        this.cRef
      )
      .then((cartData) => {
        this.props.saveCartData(cartData);
        this.extractCartData(this.props.cartData);
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
        }
      });
  }
  render() {
    return (
      <div>
         <PageHeader
            className={
              FeatureFlagUtils.shouldAlterLogoHeight()
                ? "custom-logo-height"
                : FeatureFlagUtils.shouldAlterLogoSize() ? "custom-logo-size" : ""
            }
            showAccount={false}
            showMobileMenu={false}
            logo={FeatureFlagUtils.getLogo()}
            showReportLostPetButton={false}
            reportLostPetButtonProps={{
              onClick: () => {},
            }}
            onLogoClick={() => {
              DataLayerUtils.navigationEvent(
                `${window.innerWidth > MOBILE_VIEW_BREAKPOINT  ? 'header' : 'mobile'}`,
                "logo"
              );
              this.props.history.replace("/");
            }}
            accountMenuProps={{
              id: "account_button",
              items: [],
              showNotificationMark: false,
              closeMenu: () => {}
            }}
            showPetProPortalButton={false}
            petProPortalButtonProps={{
              onClick: () => {}
            }}
          ></PageHeader>
        <TwoColumnContent
          className="new-payment-page"
          leftColumn={<div className="new-payment-left-column">
            <PaymentContainer
              disableCheckoutButton={this.props.cartData?.total === 0}
              extractCartData={this.extractCartData}
            ></PaymentContainer>
          </div>}
          rightColumn={<div className="new-payment-right-column" ref={this.cRef}>
            <NewShoppingCart
              errorMessage={this.state.errorMessage}
              cartItems={this.state.cartItems}
              onRemoveClick={(index1, index2) => {
                this.removeOrder(index1, index2);
              }}
              totalBeforeTax={AppUtils.getConvertedPrice(
                this.props.cartData?.subTotal
              )}
              showPromoRow={this.state.showPromo}
              addPromoProps={{
                id: "promoButton",
                href: "#",
                className: "new-payment-link-override",
                label: this.props.cartData?.promoDiscount?.isApplied
                  ? this.props.cartData?.promoDiscount.code
                  : "Add promo code",
                discount: this.props.cartData?.promoDiscount?.isApplied
                ? AppUtils.getConvertedPrice(this.props.cartData?.promoDiscount.amount)
                : '',
                onClick: () => {
                  // if (!this.props.cartData?.promoDiscount?.isApplied) {
                  //   this.applyPromoCode();
                  // }
                  this.setState({
                    showPromo: true
                  })
                },
              }}
              closePromoProps={{
                id: "closePromo",
                onClick: () => {
                  this.setState({
                    showPromo: false,
                    promoCode: ""
                  })
                }
              }}
              promoCodeInputProps={{
                value: this.state.promoCode,
                id: "promo_code_input",
                name: "promoCode",
                error: this.state.promoCodeErrorMessage !== '' ? true : false,
                hint: this.state.promoCodeErrorMessage,
                onChange: this.handleInputChange,
              }}
              applyButtonProps={{
                id: "promo_code_apply_button",
                onClick: () => {
                  this.applyPromoCode();
                },
              }}
              showCartContent={this.state.showMobileCart}
              onCartClick={{
                onClick: () => {
                  this.setState({
                    showMobileCart: !this.state.showMobileCart
                  })
                },
              }}
              totalTaxes={AppUtils.getConvertedPrice(
                this.props.cartData?.taxes
              )}
              subtotal={AppUtils.getConvertedPrice(
                this.props.cartData?.total
              )}
              shippingAmount={AppUtils.getConvertedPrice(
                this.props.cartData?.shipping
              )}
            />
          </div>}
        ></TwoColumnContent>
        {FeatureFlagUtils.shouldShowDashboardFooter() && (
          <PageContent>
            <DashboardFooter />
          </PageContent>
        )}
        <PageFooter
          logos={this.state.logos}
          legalLines={this.state.legalLines}
          footerLinks={this.getFooterLinks()}
        ></PageFooter>
      </div>
    );
  }

  fetchLogos() {
    axios
      .processGet(
        `${
          ApiConstants.URL_FOOTER_LOGOS_FOR_LOOGED_IN
        }${AppUtils.getCountryCode()}`
      )
      .then((response) => {
        this.setState({
          logos: response.data?.forLoggedIn?.logos,
          legalLines: response.data?.forLoggedIn?.legalLines,
        });
      });
  }

  getPrivacyPolicy = () => {
    if (AppUtils.getCountryCode() === "US") {
      return require("../../../assets/" + FeatureFlagUtils.getPrivacyPolicyUS());
    } else {
      return require("../../../assets/" + FeatureFlagUtils.getPrivacyPolicyCA());
    }
  };

  getTermsOfUse = () => {
    return require("../../../assets/" + FeatureFlagUtils.getTermOfUse());
  };

  getFooterLinks = () => {
    return [
      {
        href: "",
        onClick: (e) => {
          DataLayerUtils.navigationEvent("footer", "contact us");
          this.props.history.push("/pages/contact-us");
          e.preventDefault();
        },
        className: FeatureFlagUtils.showLinkOverrideCss()
        ? "dd-text-link-override"
        : "",
        color: Link.Colors.secondary,
        label: "Contact Us",
      },
      {
        href: "",
        color: Link.Colors.secondary,
        className: FeatureFlagUtils.showLinkOverrideCss()
        ? "dd-text-link-override"
        : "",
        label: "Frequently Asked Questions",
        onClick: (e) => {
          DataLayerUtils.navigationEvent("footer", "faq");
          this.props.history.push("/pages/faq");
          e.preventDefault();
        },
      },
      {
        href: this.getPrivacyPolicy(),
        download: "privacy_policy.pdf",
        color: Link.Colors.secondary,
        className: FeatureFlagUtils.showLinkOverrideCss()
        ? "dd-text-link-override"
        : "",
        label: "Privacy Policy",
        onClick: () => {
          DataLayerUtils.navigationEvent("footer", "privacy policy");
        }
      },
      {
        href: this.getTermsOfUse(),
        download: "terms-of-use.pdf",
        color: Link.Colors.secondary,
        className: FeatureFlagUtils.showLinkOverrideCss()
        ? "dd-text-link-override"
        : "",
        label: "Terms of Use",
        onClick: () => {
          DataLayerUtils.navigationEvent("footer", "term of use");
        }
      },
      FeatureFlagUtils.accessibilityDownload()
        ? {
            href: require("../../../assets/Spot_accessibility.pdf"),
            download: "Spot_accessibility.pdf",
            color: Link.Colors.secondary,
            className: FeatureFlagUtils.showLinkOverrideCss()
            ? "dd-text-link-override"
            : "",
            label: "Accessibility",
            onClick: () => DataLayerUtils.navigationEvent("footer", "accessibility")
          }
        : {
            href: "",
            onClick: (e) => {
              DataLayerUtils.navigationEvent("footer", "accessibility");
              this.props.history.push("/pages/accessblity");
              e.preventDefault();
            },
            color: Link.Colors.secondary,
            className: FeatureFlagUtils.showLinkOverrideCss()
            ? "dd-text-link-override"
            : "",
            label: "Accessibility",
          },
    ];
  };
}
const mapStateToProps = (state) => {
  const membershipPurchaseStore = state.membershipPurchaseStore;
  const customerProfileStore = state.customerProfileStore;

  return {
    cartData: membershipPurchaseStore.cartData,
    customerAnimals: customerProfileStore.customerAnimals,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCartData: (data) => dispatch(saveCartData(data)),
    clearCartData: () => dispatch(clearCartData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShoppingCartWrapper));

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text, Icon } from '../../'

export class Banner extends React.PureComponent {
  static Colors = Object.freeze({
    secondary: 'secondary',
    success: 'success',
    danger: 'danger',
    warning: 'warning'
  })

  static Flows = Object.freeze({
    default: 'default',
    absolute: 'absolute'
  })

  static propTypes = {
    flow: PropTypes.oneOf(Object.values(Banner.Flows)),
    color: PropTypes.oneOf(Object.values(Banner.Colors)),
    title: PropTypes.any,
    subtitle: PropTypes.any,
    showIcon: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    color: Banner.Colors.danger,
    flow: Banner.Flows.default,
    showIcon: false
  }

  render() {
    const {
      flow,
      color,
      title,
      subtitle,
      showIcon,
      id,
      className,
      style,
      refProp,
      ...rest
    } = this.props

    const classes = getClasses(color, flow)

    return (
      <div
        id={id}
        className={cn(classes, className)}
        style={style}
        ref={refProp}
        {...rest}
      >
        <span className={cn({ 'ui-banner--offer' : showIcon})}>
          {showIcon && <Icon type='error_outline'></Icon>}
          <Text type={Text.Types.bodyBold}>{title}</Text>
        </span>
        {subtitle && <Text>{subtitle}</Text>}
      </div>
    )
  }
}

const getClasses = (color, flow) => {
  const classes = ['ui-banner']

  switch (color) {
    case Banner.Colors.success:
      classes.push('ui-banner--success')
      break
    case Banner.Colors.secondary:
      classes.push('ui-banner--secondary')
      break
    case Banner.Colors.warning:
      classes.push('ui-banner--warning')
      break

    case Banner.Colors.danger:
    default:
      classes.push('ui-banner--danger')
      break
  }

  switch (flow) {
    case Banner.Flows.absolute:
      classes.push('ui-banner--absolute')
      break
    default:
      break
  }

  return classes
}

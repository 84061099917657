import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Text,
  Icon,
  Link
} from '../..'

export class IncomingTransferMessageCard extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    className: PropTypes.string,
    onClose: PropTypes.func,
    id: PropTypes.string,
    closeButtonIconProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    showTurnOffAlertButton: PropTypes.bool,
    turnedOffAlertDate:PropTypes.string,
    rejectButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    date: PropTypes.string,
    acceptButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    alertCancelMessage: PropTypes.string,
    incomingTransferAlertMessage: PropTypes.any,

    goBackLinkProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string
    }),
    selectOptionInfo: PropTypes.string
  }

  static defaultProps = {
    selectOptionInfo: "Please click below to either accept or reject the transfer of this pet. If you do not accept or reject within 7 days, the pet will automatically be transferred to your account.",
  }

  render() {
    const {
      id,
      className,
      date,
      acceptButtonProps,
      incomingTransferAlertMessage,
      rejectButtonProps,
      showTurnOffAlertButton,
      alertCancelMessage,
      goBackLinkProps,
      selectOptionInfo,
    } = this.props
    return (
      <Paper
        id={id}
        className={cn(className, 'ui-incoming-transfer-message-delivered-card')}
      >
        <PaperContent className='ui-incoming-transfer-message-delivered-card-content'>
          <div className='ui-incoming-transfer-message-delivered-card-content-back-link'>
            <Icon type='chevron_left' />
            <Link {...goBackLinkProps}>Back to notifications</Link>
          </div>

          <div className='ui-incoming-transfer-message-delivered-card-content-message'>
            <Text
              types={Text.Types.bodyBold}
              className='ui-incoming-transfer-message-modal-date'
            >
              {date}
            </Text>
            {incomingTransferAlertMessage}
           
            <Text
              type={Text.Types.caption}
              className="ui-incoming-tranfer-message-modal-text"
            >
              {selectOptionInfo}
            </Text>
          </div>
          
          <div className='ui-incoming-transfer-message-delivered-card-content-alert-button'>
            {showTurnOffAlertButton ? (<div className='ui-incoming-transfer-pet-modal__actions'>
              <Button
                id="incoming-accept-button"
                size={Button.Sizes.small}
                {...acceptButtonProps}
              >
                Accept
              </Button>
              <Button
                id="incoming-reject-button"
                size={Button.Sizes.small}
                {...rejectButtonProps}
              >
                Reject
              </Button>
            </div>
            ) : (
              <Text>{ alertCancelMessage }</Text>
            )}
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { ToggleButton, Text } from '../../'
import { Hint } from '../Input/InputComponents'

export class ToggleButtonGroup extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    /**
     * Flag to stretch out toggle buttons
     */
    fluid: PropTypes.bool,

    value: PropTypes.string,
    onChange: PropTypes.func,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        buttonId: PropTypes.string,
        value: PropTypes.string,
        label: PropTypes.string
      })
    ),

    error: PropTypes.bool,
    hint: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    items: [],
    fluid: true
  }

  render() {
    const {
      label,
      fluid,
      value,
      onChange,
      error,
      hint,
      items,
      id,
      className,
      style,
      refProp
    } = this.props

    const classes = getClasses(fluid, error)

    return (
      <div
        id={id}
        className={cn(classes.container, className)}
        style={style}
        ref={refProp}
      >
        {label && <Text className={cn(classes.label)}>{label}</Text>}

        <div className={cn(classes.buttonGroup)}>
          {items.map((item) => (
            <ToggleButton
              id={item.buttonId}
              key={`${label}-${item.value}`}
              active={item.value === value}
              onClick={() => item.value !== value && onChange(item.value)}
            >
              {item.label}
            </ToggleButton>
          ))}
        </div>

        {hint && <Hint hint={hint} className={classes.hint} />}
      </div>
    )
  }
}

const getClasses = (fluid, error) => {
  const classes = {
    container: ['ui-toggle-button-group'],
    label: ['ui-toggle-button-group__label'],
    buttonGroup: ['ui-toggle-button-group__horizontal'],
    hint: ['ui-toggle-button-group__hint']
  }

  fluid && classes.buttonGroup.push('ui-toggle-button-group--fluid')
  error && classes.container.push('ui-toggle-button-group--error')

  return classes
}

import React, { PureComponent } from "react";
import {
  NotificationsCard,
  TwoColumnContent,
  NotificationsFiltersCard,
  PageBanner
} from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { AppUtils } from "../../../utils/app-utils";
import { ApiConstants } from "../../../utils/api-constants";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import moment from "moment";
import { LoadMask } from "../../../utils/loadmask.util";


class Notifications extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        allFilter: true,
        incomingFilter: true,
        outgoingFilter: true,
        foundPetFilter: true,
        showWarningMessage: false,
        pageNumber: 1,
        notifications:[],
        filteredNotifications: []
      };
      this.cRef = React.createRef();
    }

    getNotifications(){
      const divRef = this.cRef.current;
      LoadMask.showPreloader(divRef);
      axios
      .processGet(
        `${ApiConstants.URL_GET_NOTIFICATIONS}?brAccount=${AppUtils.getBusRelAccount()}`,
        null
      )
      .then((response) => {
        if(!Array.isArray(response)){
          this.setState({
            notifications: [],
            showWarningMessage: true,
          })
          return;
        }

        const petID = this.props.location.state;
        const notifications = (!petID)
                              ? response
                              : response.filter( (value) => value.animalId === petID)

        notifications.sort(function(a, b) {
          return new Date(b.requestDate) - new Date(a.requestDate);
        })

        notifications.sort(function(a, b) {
          return new Date(b.communicationDate) - new Date(a.communicationDate);
        })

        notifications.forEach((notification, index) => {
          notifications[index].requestDate = moment(notification.communicationDate).format("MM-DD-YYYY")
        })
        this.setState({
          filteredNotifications: notifications.filter( (notification) => notification.notificationCategoryId === 1 ),
          notifications,
          showWarningMessage: false,
        })
      })
      .catch((err) => {})
      .finally(() => {
        LoadMask.hidePreloader(divRef);
      });
    }

    getFilteredNotifications(){
      let all= this.state.allFilter,
          incoming= false,
          outgoing= false,
          foundPet= this.state.foundPetFilter;
      const notifications = [];

      if( all || (!all && incoming && outgoing && !foundPet)){
        this.setState({
          filteredNotifications: this.state.notifications.filter( (notification) => notification.notificationCategoryId === 1 )
        })
        return;
      }
      if(incoming){
         for(let i=0; i<this.state.notifications.length; i++){
           if(AppUtils.isEqualString(this.state.notifications[i].type, 'Transfer Ownership Pull')){
             notifications.push(this.state.notifications[i]);
           }
         }
      }

      if(foundPet){
        for(let i=0; i<this.state.notifications.length; i++){
          if(AppUtils.isEqualString(this.state.notifications[i].type, 'FoundPet')){
            notifications.push(this.state.notifications[i]);
          }
        }
      }

      if(outgoing){
        for(let i=0; i<this.state.notifications.length; i++){
          if(AppUtils.isEqualString(this.state.notifications[i].type, 'Transfer Ownership Push')){
            notifications.push(this.state.notifications[i]);
          }
        }
      }

      this.setState({
        filteredNotifications: notifications,
      })
    }

    componentDidMount() {
      if (FeatureFlagUtils.shouldShowNotifications()) {
        this.getNotifications();
      }
    }
    render() {
      const selectedPetId = this.props.location.state;
      return (
        <React.Fragment>
          {this.state.showWarningMessage && <PageBanner
            showIcon={true}
            subtitle={"Notifications are currently unavailable. Please try again later or call 1-866-597-2424."}
          />}
          <TwoColumnContent
            className="notification-center-container"
            leftColumn={
              <div className = "notification-filter-container">
                <NotificationsFiltersCard
                allFilterProps={{
                  checked: this.state.allFilter,
                  onChange: () => {
                    this.setState({
                      allFilter: !this.state.allFilter,
                      incomingFilter: !this.state.allFilter,
                      foundPetFilter: !this.state.allFilter,
                      outgoingFilter: !this.state.allFilter,
                    }, () => {
                      this.getFilteredNotifications();
                    });
                  },
                }}
                incomingTransferProps={{
                  hidden: true,
                  checked: this.state.incomingFilter,
                  onChange: () => {
                    this.setState({
                      incomingFilter: !this.state.incomingFilter,
                      allFilter: false,
                    }, () => {
                      this.getFilteredNotifications();
                    });
                  },
                }}
                foundPetProps={{
                  checked: this.state.foundPetFilter,
                  onChange: () => {
                    this.setState({
                      foundPetFilter: !this.state.foundPetFilter,
                      allFilter: !this.state.foundPetFilter,
                    }, () => {
                      this.getFilteredNotifications();
                    });
                  },
                }}
                outgoingTransferProps={{
                  hidden: true,
                  checked: this.state.outgoingFilter,
                  onChange: () => {
                    this.setState({
                      outgoingFilter: !this.state.outgoingFilter,
                      allFilter: false,
                    }, () => {
                      this.getFilteredNotifications();
                    });
                  },
                }}
                ></NotificationsFiltersCard>
              </div>

            }
            rightColumn={
                <div ref={this.cRef} className = "notification-container" >
                    <NotificationsCard
                        notificationMessages={this.state.filteredNotifications}
                        openButtonProps={ (index) => {
                          let currIndex = (this.state.pageNumber-1)*10 + index;
                          const id = this.state.filteredNotifications[currIndex].communicationGroupId  ;
                          this.props.history.push({
                            pathname:`/pages/notifications/${id}`,
                            state: {
                              notifications: this.state.notifications,
                              selectedPetId: selectedPetId,
                            }
                          });
                        }}
                        page={this.state.pageNumber}
                        onPageChange={(newPage) => {
                            this.setState({
                                pageNumber: newPage,
                            });
                        }}
                        itemsPerPage={10}
                    ></NotificationsCard>
                </div>
            } 
           ></TwoColumnContent>
        </React.Fragment>
      );
    }
}

export default Notifications;



import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class Spinner extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { id, className, style, refProp } = this.props

    return (
      <div
        id={id}
        className={cn(
          'ui-loading-spinner',
          'ui-loading-spinner--regular',
          className
        )}
        style={style}
        ref={refProp}
      />
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class Paper extends React.PureComponent {
  static Types = Object.freeze({
    framed: 'framed',
    clean: 'clean'
  })

  static Colors = Object.freeze({
    primary: 'primary',
    success: 'success'
  })

  static propTypes = {
    type: PropTypes.oneOf(Object.values(Paper.Types)),
    color: PropTypes.oneOf(Object.values(Paper.Colors)),
    children: PropTypes.any,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    type: Paper.Types.clean,
    color: Paper.Colors.primary
  }

  render() {
    const { color, type, children, id, className, style, refProp } = this.props

    const classes = getClasses(type, color)

    return (
      <div
        id={id}
        className={cn(classes, className)}
        style={style}
        ref={refProp}
      >
        {children}
      </div>
    )
  }
}

const getClasses = (type, color) => {
  const classes = ['ui-paper']

  type === Paper.Types.framed && classes.push('ui-paper--framed')
  type === Paper.Types.framed &&
    color === Paper.Colors.primary &&
    classes.push('ui-paper--primary')
  type === Paper.Types.framed &&
    color === Paper.Colors.success &&
    classes.push('ui-paper--success')

  return classes
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Text,
  Checkbox,
  Button,
  Select,
  Input,
  Form
} from '../../'
import { LocaleWithUnknown as importedLocale } from '../../constants'
import { getLabelsByLocale } from '../../utils'
import { InputHelperWithTooltip } from '../InputHelperWithTooltip'

export class ReportLostPetCard extends React.PureComponent {
  static Locale = importedLocale

  static propTypes = {
    banner: PropTypes.element,
    locale: PropTypes.oneOf(Object.values(ReportLostPetCard.Locale)),
    authenticated: PropTypes.bool,
    petNameInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    petNameSelectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    microchipNumberInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    addressInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    cityInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    countrySelectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    stateSelectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    ownerNameInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    phoneNumberInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    emailInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    checkboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),

    backButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    submitButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    captcha: PropTypes.any,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    locale: ReportLostPetCard.Locale.unknown
  }

  render() {
    const {
      banner,
      petNameInputProps,
      petNameSelectProps,
      microchipNumberInputProps,
      addressInputProps,
      cityInputProps,
      countrySelectProps,
      stateSelectProps,
      ownerNameInputProps,
      phoneNumberInputProps,
      emailInputProps,
      checkboxProps,
      backButtonProps,
      submitButtonProps,
      captcha,
      locale,
      authenticated,
      id,
      className,
      style,
      refProp
    } = this.props

    const { stateLabel } = getLabelsByLocale(locale)

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn('ui-report-lost-pet-card', className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>I have lost a pet</PaperHeadline>

        {banner}

        <PaperContent>
          <Text type={Text.Types.title} className='ui-report-lost-pet__title'>
            We’re sorry to hear that, please confirm the information below:
          </Text>

          <Form>
            {authenticated ? (
              <Select label='Pet name' {...petNameSelectProps} />
            ) : (
              <Input label='Pet name' {...petNameInputProps} />
            )}

            <Input label='Pet Microchip or ID Number' {...microchipNumberInputProps} />

            <Text type={Text.Types.title}>Last seen:</Text>

            <div className='ui-report-lost-pet__form-row'>
              <Input label='Intersection/Address' {...addressInputProps} />

              <Input label='City' {...cityInputProps} />
            </div>

            <div className='ui-report-lost-pet__form-row'>
              <Select label='Country' {...countrySelectProps} />

              <Select label={stateLabel} {...stateSelectProps} />
            </div>

            <Text type={Text.Types.title}>Owner details:</Text>

            <div className='ui-report-lost-pet__form-row'>
              <Input label='Name' {...ownerNameInputProps} />

              <Input label='Phone Number' {...phoneNumberInputProps} />
            </div>

            <Input label='Email Address' {...emailInputProps} />

            <Checkbox
              label='In the event your pet is found by a good Samaritan, a Veterinarian or shelter, do you consent for us to provide your information (name, address, email, and phone number) to the person who finds your pet?'
              {...checkboxProps}
            />

            {captcha}

            <div className='ui-report-lost-pet__form-action'>
              <Button
                color={Button.Colors.tertiary}
                variant={Button.Variants.outlined}
                {...backButtonProps}
              >
                Go back
              </Button>
              <Button color={Button.Colors.tertiary} {...submitButtonProps}>
                Submit lost pet
              </Button>
            </div>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

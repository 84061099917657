import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  OptionCheckbox,
  HeaderDivider,
  Text,
  IconText
} from '../../'
import { MedAlert, HelpLine } from '../SvgIcons'
import { createArrayIncludesValueCheck } from '../../utils'

export class LTProtectionMembershipCard extends React.PureComponent {
  static Icons = Object.freeze({
    petMedAlert: 'petMedAlert',
    vetHelpLine: 'vetHelpLine'
  })

  static propTypes = {
    subscriptionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Unique key prop to render as array
         */
        key: PropTypes.string.isRequired,
        /**
         * The rest are OptionCheckbox Props
         */
        variant: PropTypes.oneOf(Object.values(OptionCheckbox.Variants)),
        checked: PropTypes.bool,
        onClick: PropTypes.func,
        strikeThroughPrice: PropTypes.bool,
        showCheckMark: PropTypes.bool,
        label: PropTypes.any,
        description: PropTypes.shape({
          price: PropTypes.string,
          frequency: PropTypes.string,
          detail: PropTypes.string
        }),

        id: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        refProp: PropTypes.oneOfType([
          PropTypes.func,
          PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ])
      })
    ),

    iconsToHide: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(LTProtectionMembershipCard.Icons))
    ),

    banner: PropTypes.element,

    disableMedAlertIconText: PropTypes.bool,
    disableVetHelplineIconText: PropTypes.bool,
    petMedAlertIconTextBody: PropTypes.string,
    vetHelpLineIconTextBody: PropTypes.string,
    legalLine: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    subscriptionOptions: [],
    iconsToHide: [],
    petMedAlertIconTextBody:
      'Critical medical and behavioural information will be relayed to the shelter or vet they’re brought to when found. Complimentary access for 1 year with a Lifetime Protection Membership.',
    vetHelpLineIconTextBody:
      'Reach veterinary professionals anytime by phone, email, or live chat. Complimentary access for 1 year with a Lifetime Protection Membership.'
  }

  render() {
    const {
      disableMedAlertIconText,
      disableVetHelplineIconText,
      banner,
      subscriptionOptions,
      iconsToHide,
      id,
      className,
      strikeThroughPrice,
      style,
      legalLine,
      petMedAlertIconTextBody,
      vetHelpLineIconTextBody,
      refProp
    } = this.props

    const showIcon = createArrayIncludesValueCheck(iconsToHide)

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn(
          'ui-lt-protection-membership-card',
          {
            'ui-lt-protection-membership-card--disabled':
              disableMedAlertIconText && disableVetHelplineIconText
          },
          className
        )}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Lifetime Protection Membership plan</PaperHeadline>

        {banner}

        <PaperContent>
          <HeaderDivider>Available subscriptions:</HeaderDivider>

          <div className='ui-lt-protection-membership-card__options'>
            {subscriptionOptions.map(({ key, ...restProps }) => (
              <OptionCheckbox key={key} {...restProps} />
            ))}
          </div>

          <Text className='ui-lt-protection-membership-card__upgrade-prompt'>
            Additional coverage:
          </Text>

          <div className={cn('ui-lt-protection-membership-card__icons')}>
            {showIcon(LTProtectionMembershipCard.Icons.petMedAlert) && (
              <IconText
                svgIcon={<MedAlert />}
                title={
                  <span>
                    24PetMedAlert<sup>®</sup> for 1 year
                  </span>
                }
                className={
                  disableMedAlertIconText &&
                  'ui-lt-protection-membership-card__icon--disabled'
                }
                body={petMedAlertIconTextBody}
              />
            )}

            {showIcon(LTProtectionMembershipCard.Icons.vetHelpLine) && (
              <IconText
                className={
                  disableVetHelplineIconText &&
                  'ui-lt-protection-membership-card__icon--disabled'
                }
                svgIcon={<HelpLine />}
                title='24/7 Vet Helpline for 1 year'
                body={vetHelpLineIconTextBody}
              />
            )}
          </div>
          {legalLine && (
            <Text
              className='ui-membership-card__legal-line'
              type={Text.Types.caption}
            >
              {legalLine}
            </Text>
          )}
        </PaperContent>
      </Paper>
    )
  }
}

import React, { Component } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { AppUtils } from "../../utils/app-utils";
import "./pages.css";
import axios from "../../custom-axios";
import { ApiConstants } from "../../utils/api-constants";
import { connect } from "react-redux";
import Dashboard from "./dashboard/dashboard";
import UserProfileContainer from "./user-profile/user-profile-container";
import Notifications from "./Notifications/Notifications"
import NotificationDetails from "./Notifications/NotificationDetails";
import TransferPet from "./TransferPet/TransferPet";
import {
  PageHeader,
  PageFooter,
  AddAnotherPetModal,
  TransferOfRegistrationModal,
  IframeModal,
  DashboardFooter,
  PageContent,
  NoDataCard,
  Link,
  Button,
  ConsentPreferenceModal,
  PromoBanner
} from "../../UI-Component-Library";
import { PageUtils } from "./page-utils";
import {
  changeSelectedPetId,
  saveCustomerProfile,
  saveCustomerContactInfo,
} from "../../redux/reducers/customer-profile-reducer";
import { AppConstants } from "../../utils/app-constants";
import PetRegistrationContainer from "./pet-registration/pet-registration-container";
import Checkout from "./checkout/checkout";
import newCardPayment from "./checkout/new-card-payment";
import PaymentCompleteComponent from "./checkout/payment-complete";
import AuthReportPet from "./AuthReportPet/auth-report-pet";
import { FeatureFlagUtils } from "../../utils/feature-flag-utils";
import { ContactUs } from "./contact-us/contact-us";
import FAQComponent from "./faq/faq";
import Accessblity from "./accessblity/accessblity";
import { clearUserDataAfterLogout } from "../../redux/actions/authActions";
import {
  backupCartData,
  clearCartData,
} from "../../redux/reducers/membership-purchase-reducer";
import { showButtonLoader, hideButtonLoader} from "../../utils/buttonLoader-util";
import { DataLayerUtils } from "../../utils/dataLayer-utils";
import ShoppingCartWrapper from "./new-payment/shopping-cart-wrapper";

// Minimal width for the desktop layout to look good, after this break point render mobile view
const MOBILE_VIEW_BREAKPOINT = 927;
class Pages extends Component {
  constructor() {
    super();
    this.state = {
      loggedInUser: "",
      isAddAnotherPetModalOpen: false,
      isTransferOwnershipModalOpen: false,
      isBplModalOpen: false,
      logos: [],
      legalLines: [],
      isInfoLoaded: false,
      selectedPetId: undefined,
      isAddAnotherPethealthPet: false,
      inValidPolicies: [],
      isFailedToFetchData: false,
      policies: [],
      openClaim: undefined,
      isNewRegistered: false,
      customer: undefined,
      isValidClaimSubmissionLimit:false,
      // found Pet Notification Center
      selectedItemId: "AccountProfile1",
      showNotificationMark: false,
      showLostPetAlertIcon: false,
      contactInfo: undefined,
      isConsentModalOpen: false,
      contactConsent: true,
    };
    this.cRef = React.createRef();
  }

  notificationMark(){
    const selectedPetId = this.props.selectedPetId;
    if(!AppUtils.getBusRelAccount()){
      return;
    }
    axios
    .processGet(
      `${ApiConstants.URL_GET_NOTIFICATIONS}?brAccount=${AppUtils.getBusRelAccount()}`,
      null
    )
    .then((response) => {
      if(!Array.isArray(response)){
        return;
      }
      let showNotificationMark = false;
      let showLostPetAlertIcon = false;
      for(let index=0; index<response.length; index++){
        const value = response[index];
        if(!value.isRead){
          showNotificationMark = true;
        }
      }
      // sort notifications on date
      // response.sort(function(a, b) {
      //   return new Date(b.requestDate) - new Date(a.requestDate);
      // })

      let checkAlertCancelled = response.filter(function(notification){
        let isFoundPet = AppUtils.isEqualString(notification.type, 'FoundPet');
        if( isFoundPet && 
            notification.animalId === selectedPetId && 
            notification.status === 'A'
        ){
          return true;
        }
        return false;
      })

      showLostPetAlertIcon = (checkAlertCancelled.length)? true : false;
      this.setState({
        showNotificationMark,
        showLostPetAlertIcon,
      })
    })
    .catch((err) => {});
  }

  fetchLogos() {
    axios
      .processGet(
        `${
          ApiConstants.URL_FOOTER_LOGOS_FOR_LOOGED_IN
        }${AppUtils.getCountryCode()}`
      )
      .then((response) => {
        this.setState({
          logos: response.data?.forLoggedIn?.logos,
          legalLines: response.data?.forLoggedIn?.legalLines,
        });
      });
  }
  getFooterLinks = () => {
    return [
      {
        href: "",
        onClick: (e) => {
          DataLayerUtils.navigationEvent("footer", "contact us");
          this.props.history.push("/pages/contact-us");
          e.preventDefault();
        },
        className: FeatureFlagUtils.showLinkOverrideCss()
        ? "dd-text-link-override"
        : "",
        color: Link.Colors.secondary,
        label: "Contact Us",
      },
      {
        href: "",
        color: Link.Colors.secondary,
        className: FeatureFlagUtils.showLinkOverrideCss()
        ? "dd-text-link-override"
        : "",
        label: "Frequently Asked Questions",
        onClick: (e) => {
          DataLayerUtils.navigationEvent("footer", "faq");
          this.props.history.push("/pages/faq");
          e.preventDefault();
        },
      },
      {
        href: this.getPrivacyPolicy(),
        download: "privacy_policy.pdf",
        color: Link.Colors.secondary,
        className: FeatureFlagUtils.showLinkOverrideCss()
        ? "dd-text-link-override"
        : "",
        label: "Privacy Policy",
        onClick: () => {
          DataLayerUtils.navigationEvent("footer", "privacy policy");
        }
      },
      {
        href: this.getTermsOfUse(),
        download: "terms-of-use.pdf",
        color: Link.Colors.secondary,
        className: FeatureFlagUtils.showLinkOverrideCss()
        ? "dd-text-link-override"
        : "",
        label: "Terms of Use",
        onClick: () => {
          DataLayerUtils.navigationEvent("footer", "term of use");
        }
      },
      FeatureFlagUtils.accessibilityDownload()
        ? {
            href: require("../../assets/Spot_accessibility.pdf"),
            download: "Spot_accessibility.pdf",
            color: Link.Colors.secondary,
            className: FeatureFlagUtils.showLinkOverrideCss()
            ? "dd-text-link-override"
            : "",
            label: "Accessibility",
            onClick: () => DataLayerUtils.navigationEvent("footer", "accessibility")
          }
        : {
            href: "",
            onClick: (e) => {
              DataLayerUtils.navigationEvent("footer", "accessibility");
              this.props.history.push("/pages/accessblity");
              e.preventDefault();
            },
            color: Link.Colors.secondary,
            className: FeatureFlagUtils.showLinkOverrideCss()
            ? "dd-text-link-override"
            : "",
            label: "Accessibility",
          },
    ];
  };
  getCustomerProfile = () => {
    axios
      .processGet(
        `${ApiConstants.URL_CUSTOMER_PROFILE}${AppUtils.getToken()}`,
        this.cRef
      )
      .then((response) => {
        this.props.saveCustomerProfile(response);
        const isNewRegistered =
          response.customerAccounts[0]?.customerAnimals[0]?.isNewRegistered;
        const customer = response.customerAccounts[0];
        this.setState({
          isInfoLoaded: true,
          isNewRegistered,
          customer,
        });
      })
      .catch((err) => {
        this.setState({
          isFailedToFetchData: true,
        });
      });
  };

  getContactInfo = () => {
    axios
      .processGet(
        `${ApiConstants.URL_GET_CONTACT_INFO}${AppUtils.getBusRelAccount()}`
      )
      .then((response) => {
        this.props.saveCustomerContactInfo(response);
        this.setState({
          isConsentModalOpen: response.consentConfirmationPending,
          contactInfo: response,
        })
      })
      .catch((err) => {});
  };

  updateContactInfo = () => {
    const updateContactInfo = this.state.contactInfo;
    delete updateContactInfo["consentConfirmationPending"];
    updateContactInfo["contactConsent"] = true;

    const saveButton = document.getElementById("contactConsentButton");
    showButtonLoader(saveButton);
    axios
      .processPost(
        `${ApiConstants.URL_POST_CONTACT_INFO}`,
        null,
        updateContactInfo
      )
      .then((response) => {
        this.setState({
          isConsentModalOpen: false,
        })
        this.getContactInfo(true);
        hideButtonLoader(saveButton);
      })
      .catch((err) => {
        hideButtonLoader(saveButton);
      })
  }

  componentDidMount() {
    const user = AppUtils.getLoggedInUser();
    this.setState({
      loggedInUser: user.email,
      selectedPetId: this.props.selectedPetId,
    });
    this.getCustomerProfile();
    this.getContactInfo();
    this.fetchLogos();
    window.loadGAScript();
    window.loadAdobeTargetScript();
    if (FeatureFlagUtils.shouldShowNotifications()) {
      this.notificationMark();
      this.interval = setInterval(() => this.notificationMark(), 30 * 60 * 1000);
    }
  }

  componentWillUnmount(){
    clearInterval(this.interval);
  }

  componentDidUpdate() {
    if (this.state.selectedPetId !== this.props.selectedPetId) {
      this.setState({
        selectedPetId: this.props.selectedPetId,
      });
    }

    if (this.state.selectedAnimal !== this.props.selectedAnimal) {
      this.setState(
        {
          selectedAnimal: this.props.selectedAnimal,
        },
        () => {
          this.isPoliciesAreInSuspendedState();
        }
      );
    }

    if (this.state.openClaim !== this.props.openClaim) {
      this.setState({
        openClaim: this.props.openClaim,
        isBplModalOpen: this.props.openClaim,
      });
    }
  }

  isPoliciesAreInSuspendedState = () => {
    const insurances = this.state.selectedAnimal?.insurances;
    const policies = [];
    if (insurances?.length > 0) {
      for (let insurance of insurances) {
        policies.push(...insurance.policynumber);
      }
      this.setState({
        policies: policies,
      });
    }

    for (let policy of policies) {
      axios
        .processGet(`${ApiConstants.URL_GET_STATUS_POLICY}/${policy}`)
        .then((response) => {
          const isOutstandingPending = response?.data?.hasOutstandingPremium;
          const isSuspended = response?.data?.isNotActive;
          const isCovid = response?.data?.isDueToCovid19;
          if (isOutstandingPending || isSuspended || isCovid) {
            this.setState((prevState) => {
              return {
                inValidPolicies: [...prevState.inValidPolicies, policy],
              };
            });
          }
        })
        .catch((error) => {});
        }

        if (this.state.inValidPolicies.length == policies.length && this.state.selectedAnimal != undefined) {
            //Get Claim submission limit status
            axios
                .processGet(`${ApiConstants.URL_GET_CLAIM_SUBMISSION_LIMIT_STATUS}/${this.state.selectedAnimal.animalId}`)
                .then((response) => {

                    const isValidClaimSubmissionLimit = response?.data?.isValidDaxSubmissionLimit;

                    this.setState({
                        isValidClaimSubmissionLimit: isValidClaimSubmissionLimit
                    });

                })
                .catch((error) => { });
        }
  };

  fetchRegisterNewPetUrl() {
    axios
      .processPost(`${ApiConstants.URL_REDIRECT_TO_SHOPPING_CART}`, null, {
        busRelAccount: AppUtils.getBusRelAccount(),
        email: AppUtils.getUserEmail(),
        country: AppUtils.getCountryCode(),
      })
      .then((response) => {
        AppUtils.openLinkInNewTab(response.data);
      });
  }
  downloadOwnershipTransferForm() {
    axios
      .processGet(
        `${
          ApiConstants.URL_OWNERSHIP_TRANSFER_FORM
        }${AppUtils.getCountryCode()}`,
        null
      )
      .then((response) => {
        AppUtils.openLinkInNewTab(response.data);
      });
  }

    isPolicySuspendedForAnimal = () => {        
    //// If animal don't have policies
    //  if (this.state.policies.length === 0) {
    //        return true;
    //    }

    //If claim is in submission limit range then display the submit claim button
    if(this.state.isValidClaimSubmissionLimit)
              return false;
    // Invalid policies present.
    if (this.state.inValidPolicies.length === this.state.policies.length) {
      return true;
    }
    return false;
  };

  getPrivacyPolicy = () => {
    if (AppUtils.getCountryCode() === "US") {
      return require("../../assets/" + FeatureFlagUtils.getPrivacyPolicyUS());
    } else {
      return require("../../assets/" + FeatureFlagUtils.getPrivacyPolicyCA());
    }
  };

  getTermsOfUse = () => {
    return require("../../assets/" + FeatureFlagUtils.getTermOfUse());
  };
  logoutUser = () => {
    axios
      .processPost(ApiConstants.URL_LOGOUT)
      .then((response) => {})
      .catch((err) => {})
      .finally((err) => {
        this.props.backupCartData();
        this.props.logOut();
        this.props.clearCartData();
        AppUtils.clearLoginData();
        this.props.history.replace("/auth/login");
      });
  };

  changeHandler = (event, type) => {
    this.setState({
      [type]: event,
    });
  }

  getPromoBannerText = () => {
    return (<>
      Get 20% off our Lifetime Membership until October 31st.{' '}
      <Link
        onClick={() => {
          this.props.history.push('/pages/cart');
        }}
      >
       <u>Save now</u>
      </Link>
    </>)
  }

  render() {
    return (
      <React.Fragment>
        <div className="auth-container-wrapper">
          {AppUtils.isPethealthProgram() && <ConsentPreferenceModal
            headline={"Consent from Pethealth Services Inc."}
            open={this.state.isConsentModalOpen}
            onClose={() => {
              this.setState({
                isConsentModalOpen: false,
              });
            }}
            consentTextBodyProp={FeatureFlagUtils.getConsentMessageText()}
            consentWithdrawProps={{
              style: {"display": "none"},
              name: "contactConsent",
              value: this.state.contactConsent,
              onChange: (event) => {
                this.changeHandler(event, "contactConsent")
              },
              items: [
                {
                  label: 'I do not agree',
                  value: false
                },
                {
                  label: 'I agree',
                  value: true
                }
              ]
            }}
            submitButtonText={"Continue"}
            saveButtonProps={{
              id: "contactConsentButton",
              onClick: () => {
                this.updateContactInfo();
              }
            }}
          >
          </ConsentPreferenceModal>}
          <IframeModal
            modalHeader="Submit a claim"
            open={this.state.isBplModalOpen}
            onClose={() => {
              this.setState({
                isBplModalOpen: false,
              });
              window.location.reload();
            }}
            iframeProps={{
              src:
                AppConstants.CONFIG_JSON.URL_BPL_CLAIMS_PAGE +
                AppUtils.getEncodedBusRelAccount(),
            }}
            forceHideSpinner={false}
          />
          <AddAnotherPetModal
            showTransferPetRegistrationButton={FeatureFlagUtils.shouldShowTransferPetButton()}
            showTextContent={FeatureFlagUtils.isShowTextContent()}
            showBackButton={!FeatureFlagUtils.hideBackButtonInAddPetModal()}
            open={this.state.isAddAnotherPetModalOpen}
            onClose={() => {
              this.setState({
                isAddAnotherPetModalOpen: false,
              });
            }}
            registerNewPetButtonProps={{
              id: "registerNewPetButton",
              onClick: () => {
                if (FeatureFlagUtils.shouldClickOnRegisterPet()) {
                  this.setState({
                    isAddAnotherPetModalOpen: false,
                  });
                  DataLayerUtils.registerNewPetEvent(
                    AppUtils.getUserRelationId(),
                    null,
                    "click_to_register"
                  )
                  this.props.history.push("/pages/addPet");
                } else {
                  this.fetchRegisterNewPetUrl();
                }
              },
            }}
            registerPetWithoutChipButtonProps={{
              id: "registerPetWithoutChipButton",
              onClick: () => {
                this.setState({
                  isAddAnotherPetModalOpen: false,
                });
                DataLayerUtils.registerNewPetEvent(
                  AppUtils.getUserRelationId(),
                  null,
                  "click_to_register"
                )
                this.props.history.push({
                  pathname: "/pages/addPet",
                  state: {
                    type: "phone"
                  }
                });
              },
            }}
            transferPetRegistrationProps={{
              id: "transferPetRegistrationButton",
              onClick: () => {
                this.setState({
                  isAddAnotherPetModalOpen: false,
                  isTransferOwnershipModalOpen: true,
                });
                DataLayerUtils.transferPetRegistrationEvent("click to transfer", AppUtils.getUserRelationId());
              },
            }}
            backButtonProps={{
              id: "back_button",
              onClick: () => {
                this.setState({
                  isAddAnotherPetModalOpen: false,
                });
              },
            }}
          />
          <TransferOfRegistrationModal
            className={(FeatureFlagUtils.hideCardImage())?"hide-card-image":""}
            open={this.state.isTransferOwnershipModalOpen}
            onClose={() => {
              this.setState({
                isTransferOwnershipModalOpen: false,
              });
            }}
            downloadOwnershipButtonProps={{
              id: "downloadOwnershipButton",
              onClick: () => {
                DataLayerUtils.transferPetRegistrationEvent("download transfer form", AppUtils.getUserRelationId());
                this.downloadOwnershipTransferForm();
              },
            }}
            backButtonProps={{
              onClick: () => {
                this.setState({
                  isTransferOwnershipModalOpen: false,
                });
              },
            }}
          />
          <PromoBanner
            id="promo-banner"
            isShowPromoBanner={FeatureFlagUtils.shouldShowPromoBanner()}
            title={this.getPromoBannerText()}
          />
          <PageHeader
            className={
              FeatureFlagUtils.shouldAlterLogoHeight()
                ? "custom-logo-height"
                : FeatureFlagUtils.shouldAlterLogoSize() ? "custom-logo-size" : ""
            }
            logo={FeatureFlagUtils.getLogo()}
            showReportLostPetButton={AppUtils.isLostPetEnabled()}
            reportLostPetButtonProps={{
              id: "report_lost_found_button",
              onClick: (event) => {
                DataLayerUtils.navigationEvent(
                  `${window.innerWidth > MOBILE_VIEW_BREAKPOINT  ? 'header' : 'mobile'}`,
                  "report lost pet"
                );
                this.props.history.push({
                  pathname: "/pages/reportPet",
                  state: {
                    reloadKey: new Date().getTime()
                  }
                });
              },
            }}
            showAccount={true}
            showDropDown= {true}
            showPetMenu={true}
            showSubmitClaim={!this.isPolicySuspendedForAnimal()}
            onLogoClick={() => {
              DataLayerUtils.navigationEvent(
                `${window.innerWidth > MOBILE_VIEW_BREAKPOINT  ? 'header' : 'mobile'}`,
                "logo"
              );
              this.props.history.replace("/pages");
            }}
            petMenuProps={{
              id: "pet_menu",
              pets: PageUtils.formatPets(this.props.customerAnimals),
              selectedPetId: this.props.selectedPetId,
              onMenuClick: () => {
                DataLayerUtils.navigationEvent(
                  `${window.innerWidth > MOBILE_VIEW_BREAKPOINT  ? 'header' : 'mobile'}`,
                  "pet menu"
                );
                this.props.history.push("/pages/dashboard");
              },
              onSelectedPetChange: (id) => {
                this.props.changeSelectedPetId(id);
                this.props.history.push("/pages/dashboard");
              },
              newPetButtonProps: {
                id: "new-pet-button",
                onClick: () => {
                  DataLayerUtils.navigationEvent(
                    `${window.innerWidth > MOBILE_VIEW_BREAKPOINT  ? 'header' : 'mobile'}`,
                    "add another pet"
                  );
                  this.setState({
                    isAddAnotherPetModalOpen: true,
                  });
                },
              },
            }}
            accountMenuProps={{
              id: "account_button",
              items: PageUtils.getAccountMenuList(FeatureFlagUtils.shouldShowNotifications()),
              selectedItemId: this.state.selectedItemId,
              onSelectedItemChange: (id) => {
                this.setState({
                  selectedItemId: id
                }, () => {
                  switch (id) {
                    case 'AccountProfile1':
                      DataLayerUtils.navigationEvent(
                        `${window.innerWidth > MOBILE_VIEW_BREAKPOINT ? 'header' : 'mobile'}`,
                        "profile"
                      );
                      this.props.history.push("/pages/user-profile");
                      break;
                    case 'AccountNotifications2':
                      DataLayerUtils.navigationEvent(
                        `${window.innerWidth > MOBILE_VIEW_BREAKPOINT ? 'header' : 'mobile'}`,
                        "notifications"
                      );
                      this.props.history.push("/pages/notifications");
                      break;
                    case 'AccountLogout3':
                      DataLayerUtils.navigationEvent(
                        `${window.innerWidth > MOBILE_VIEW_BREAKPOINT ? 'header' : 'mobile'}`,
                        "logout"
                      );
                      this.logoutUser();
                      break;
                  }
                })
              },
              nameOnAccount: this.props.customerName,
              onAccountClick: () => {
                DataLayerUtils.navigationEvent(
                  `${window.innerWidth > MOBILE_VIEW_BREAKPOINT  ? 'header' : 'mobile'}`,
                  "account"
                );
                this.props.history.push("/pages/dashboard")
              },
              showNotificationMark: this.state.showNotificationMark,
              closeMenu: () => {}
            }}
            submitClaimButtonProps={{
              id: "claim_button",
              variant: (FeatureFlagUtils.shouldShowTertiaryButton())
              ? Button.Variants.outlined
              : Button.Variants.filled,
              color:(FeatureFlagUtils.shouldShowTertiaryButton())
              ? Button.Colors.tertiary
              : Button.Colors.success,
              disabled: this.isPolicySuspendedForAnimal(),
              onClick: () => {
                DataLayerUtils.navigationEvent(
                  `${window.innerWidth > MOBILE_VIEW_BREAKPOINT  ? 'header' : 'mobile'}`,
                  "submit a claim"
                );
                DataLayerUtils.submitClaimEvent(
                  AppUtils.getUserRelationId(),
                  this.props.animalFullDetails[this.props.selectedPetId]?.microchipNumber,
                  "start"
                )
                this.setState({
                  isBplModalOpen: true,
                });
              },
            }}
          ></PageHeader>
          <div ref={this.cRef}></div>

          {this.state.isFailedToFetchData && (
            <PageContent>
              <NoDataCard
                logoutButtonProps={{
                  id: "logout button",
                  onClick: () => {
                    this.logoutUser();
                  },
                }}
                message="A data error has occurred, and we are having difficulty accessing your profile. Please call us at 1-866-597-2424, and we will correct the issue. We apologize for the inconvenience."
              ></NoDataCard>
            </PageContent>
          )}
          {this.state.isInfoLoaded ? (
            <div>
              {this.props.history.location.pathname === "/pages" && (
                <Route
                  path="/"
                  render={() => {
                    return <Redirect to="/pages/dashboard" />;
                  }}
                ></Route>
              )}
              <Switch>
                <Route
                  path="/pages/dashboard"
                  render={(props) => (
                    <Dashboard
                      isNewRegistered={this.state.isNewRegistered}
                      customerInfo={this.state.customer}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/pages/user-profile"
                  render={(props) => (
                    <UserProfileContainer
                      {...props}
                    />
                  )}
                ></Route>
                <Route
                  exact
                  path="/pages/notifications/:id"
                  component={NotificationDetails}
                ></Route>
                <Route
                  path="/pages/notifications"
                  component={Notifications}
                ></Route>
                <Route
                  path="/pages/transfer-pet"
                  component={TransferPet}
                ></Route>
                <Route path="/pages/cart" component={Checkout}></Route>
                <Route
                  path="/pages/payment/success"
                  component={PaymentCompleteComponent}
                ></Route>
                <Route path="/pages/payment" component={newCardPayment}></Route>
                <Route
                  path="/pages/addPet"
                  component={PetRegistrationContainer}
                ></Route>
                <Route
                  path="/pages/reportPet"
                  component={AuthReportPet}
                ></Route>
                <Route path="/pages/contact-us" component={ContactUs}></Route>
                <Route path="/pages/faq" component={FAQComponent}></Route>
                <Route path="/pages/accessblity">
                  <Accessblity
                    showUsContent={AppUtils.isUsCountry()}
                  ></Accessblity>
                </Route>
              </Switch>
            </div>
          ) : (
            <div></div>
          )}
          {FeatureFlagUtils.shouldShowDashboardFooter() && (
            <PageContent>
              <DashboardFooter />
            </PageContent>
          )}

          <PageFooter
            logos={this.state.logos}
            legalLines={this.state.legalLines}
            footerLinks={this.getFooterLinks()}
          ></PageFooter>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  const globalStore = state.globalStore;
  return {
    customerProfile: customerProfileStore.customerProfile,
    customerAnimals: customerProfileStore.customerAnimals,
    animalFullDetails: customerProfileStore.animalFullDetails,
    selectedPetId: customerProfileStore.selectedPetId,
    customerName: customerProfileStore.customerName,
    selectedAnimal: customerProfileStore.selectedAnimal,
    openClaim: globalStore.openClaim,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectedPetId: (id) => dispatch(changeSelectedPetId(id)),
    saveCustomerProfile: (data) => dispatch(saveCustomerProfile(data)),
    saveCustomerContactInfo: (data) => dispatch(saveCustomerContactInfo(data)),
    logOut: () => dispatch(clearUserDataAfterLogout()),
    clearCartData: () => dispatch(clearCartData()),
    backupCartData: () => dispatch(backupCartData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Pages));

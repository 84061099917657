import React from 'react'
import PropTypes from 'prop-types'

import { Paper, PaperContent, PaperHeadline, SocialButton, Text } from '../../'

export class SocialSignUp extends React.PureComponent {
  static propTypes = {
    errorMessage: PropTypes.string,

    googleSignUpButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    facebookSignUpButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      errorMessage,
      googleSignUpButtonProps,
      facebookSignUpButtonProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={className}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Sign up with social media</PaperHeadline>

        <PaperContent className='ui-social-sign-up__actions'>
          {errorMessage && (
            <Text
              type={Text.Types.bodyBold}
              className='ui-social-sign-up__error-message'
            >
              {errorMessage}
            </Text>
          )}

          <SocialButton {...googleSignUpButtonProps}>
            Sign up with Google
          </SocialButton>
          <SocialButton
            {...facebookSignUpButtonProps}
            variant={SocialButton.Variants.facebook}
          >
            Sign up with Facebook
          </SocialButton>
        </PaperContent>
      </Paper>
    )
  }
}

import { createSlice } from "@reduxjs/toolkit";
import { AppUtils } from "../../utils/app-utils";

const AuthReducer = createSlice({
  name: "auth-user",
  initialState: {
    isAuthenticated: false,
    isLoading: false,
    user: {},
    errorMessages: [],
  },
  reducers: {
    loginRequest: (state, action) => {
      state.isLoading = true;
      state.errorMessages = [];
    },
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.user = action.payload;
      AppUtils.login(state.user);
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.errorMessages = action.payload;
      AppUtils.clearLoginData();
    },
    logoutSuccess: (state, action) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.user = {};
      state.errorMessages = [];
    },
    destroyAuthData: (state, action) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.user = {};
      state.errorMessages = [];
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  destroyAuthData,
} = AuthReducer.actions;
export default AuthReducer.reducer;

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class PaperContent extends React.PureComponent {
  static propTypes = {
    backgroundImg: PropTypes.shape({
      src: PropTypes.string,
      position: PropTypes.oneOf(['right', 'left'])
    }),
    children: PropTypes.any,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    backgroundImg: {}
  }

  render() {
    const {
      backgroundImg,
      children,
      id,
      className,
      style,
      refProp
    } = this.props

    const backgroundImgStyles = {}
    if (backgroundImg.src) {
      backgroundImgStyles.backgroundImage = `url(${backgroundImg.src})`
      backgroundImgStyles.backgroundPosition = backgroundImg.position || 'right'
    }

    return (
      <div
        id={id}
        className={cn('ui-paper__content', className)}
        style={{
          ...backgroundImgStyles,
          ...style
        }}
        ref={refProp}
      >
        {children}
      </div>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperHeadline,
  PaperContent,
  Option,
  Text,
  Select
} from '../../'

import {
  TagShapeSelect,
  TagStyleSelect,
  TagShapes,
  TagStyles
} from './PetTagsCardComponents'

import { PUBLIC_URL } from '../../constants'
import { createArrayIncludesValueCheck } from '../../utils'

export class PetTagsCard extends React.PureComponent {
  static PetTagOptions = Object.freeze({
    standard: 'standard',
    lifetime: 'lifetime'
  })

  static TagShapes = TagShapes

  static TagStyles = TagStyles

  static propTypes = {
    disablePetTagOptions: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(PetTagsCard.PetTagOptions))
    ),
    petTagOption: PropTypes.oneOf(Object.values(PetTagsCard.PetTagOptions)),
    onTagOptionChange: PropTypes.func,
    tagStyle: PropTypes.oneOf(Object.values(PetTagsCard.TagStyles)),
    tagShape: PropTypes.oneOf(Object.values(PetTagsCard.TagShapes)),
    onTagShapeChange: PropTypes.func,
    onTagStyleChange: PropTypes.func,
    standardMetalTagsPrice: PropTypes.string,
    lifetimeWarrantyTagsPrice: PropTypes.string,
    showSizeSelect: PropTypes.bool,
    banner: PropTypes.element,
    sizeSelectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    petTagOption: PetTagsCard.PetTagOptions.standard,
    tagStyle: PetTagsCard.TagStyles.red,
    tagShape: PetTagsCard.TagShapes.bone,
    showSizeSelect: true,
    disablePetTagOptions: []
  }

  render() {
    const {
      disablePetTagOptions,
      petTagOption,
      onTagOptionChange,
      tagStyle,
      tagShape,
      onTagShapeChange,
      onTagStyleChange,
      standardMetalTagsPrice,
      showSizeSelect,
      lifetimeWarrantyTagsPrice,
      sizeSelectProps,
      banner,
      id,
      className,
      style,
      refProp
    } = this.props

    const isStandardMetalTags =
      petTagOption === PetTagsCard.PetTagOptions.standard

    const tagImage = getMetalTagImage(tagShape, tagStyle, isStandardMetalTags)
    const copies = getCopies(isStandardMetalTags)

    const enabledOption = createArrayIncludesValueCheck(disablePetTagOptions)

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={className}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Buy new pet tags</PaperHeadline>
        {banner}
        <PaperContent>
          <div className={cn('ui-pet-tags-card__tag-options')}>
            <Option
              id='standard-metal-tag-option'
              label='Standard metal tags'
              description={
                <Text type={Text.Types.bodyBold}>{standardMetalTagsPrice}</Text>
              }
              active={PetTagsCard.PetTagOptions.standard === petTagOption}
              onClick={() =>
                onTagOptionChange(PetTagsCard.PetTagOptions.standard)
              }
              disabled={!enabledOption(PetTagsCard.PetTagOptions.standard)}
            />
            <Option
              id='lifetime-warranty-tag-option'
              label='Lifetime warranty tags'
              description={
                <Text type={Text.Types.bodyBold}>
                  {lifetimeWarrantyTagsPrice}
                </Text>
              }
              active={PetTagsCard.PetTagOptions.lifetime === petTagOption}
              onClick={() =>
                onTagOptionChange(PetTagsCard.PetTagOptions.lifetime)
              }
              disabled={!enabledOption(PetTagsCard.PetTagOptions.lifetime)}
            />
          </div>

          <div className={cn('ui-pet-tags-card__tag-customize')}>
            <div>
              <img
                className={cn('ui-pet-tags-card__tag-image')}
                alt={`${tagShape}-${tagStyle}`}
                src={tagImage}
              />

              <div className={cn('ui-pet-tags-card__design')}>
                <TagShapeSelect
                  isStandardMetalTags={isStandardMetalTags}
                  selectedShape={tagShape}
                  onChange={onTagShapeChange}
                />

                <TagStyleSelect
                  isStandardMetalTags={isStandardMetalTags}
                  selectedStyle={tagStyle}
                  onChange={onTagStyleChange}
                />
              </div>

              {showSizeSelect && (
                <Select
                  id='select-size-input'
                  className='ui-pet-tags-card__size-select'
                  {...sizeSelectProps}
                />
              )}
            </div>
            <div>
              <Text type={Text.Types.bodyBold}>{copies.title}</Text>

              <ul className={cn('ui-pet-tags-card__copies__pros')}>
                {copies.pros.map((text) => (
                  <li key={text}>{text}</li>
                ))}
              </ul>
            </div>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

function getCopies(isStandardMetalTagsSelected) {
  if (isStandardMetalTagsSelected) {
    return {
      title: 'Standard metal tags',
      pros: [
        'Long-lasting and durable',
        'Includes your pet’s name and unique microchip number',
        'Highly visible design'
      ]
    }
  } else {
    return {
      title: 'Lifetime warranty tags',
      pros: [
        'Long-lasting and durable',
        'Includes your pet’s unique microchip number and name',
        'Made of steel with a special coating for a porcelain look and feel',
        'Lifetime warranty – if damaged and unreadable (not if lost)',
        'Highly visible design'
      ]
    }
  }
}

function getMetalTagImage(tagShape, tagStyle, isStandardMetalTags) {
  const defaultTagStyle = isStandardMetalTags
    ? PetTagsCard.TagStyles.red
    : PetTagsCard.TagStyles.traditional

  const result =
    standardTagsImageMap[tagShape][tagStyle] ||
    standardTagsImageMap[tagShape][defaultTagStyle]

  if (!result)
    throw new Error(
      `No pet tag image found for shape [${tagShape}] and style [${tagStyle}]`
    )

  return PUBLIC_URL + result
}

const standardTagsImageMap = {
  [PetTagsCard.TagShapes.bone]: {
    [PetTagsCard.TagStyles.red]: 'StandardBoneRed@2x.png',
    [PetTagsCard.TagStyles.charcoal]: 'StandardBoneCharcoal@2x.png',
    [PetTagsCard.TagStyles.teal]: 'StandardBoneTeal@2x.png',

    [PetTagsCard.TagStyles.traditional]: 'LifetimeBoneTraditional@2x.png',
    [PetTagsCard.TagStyles.paw]: 'LifetimeBonePaw@2x.png'
  },
  [PetTagsCard.TagShapes.heart]: {
    [PetTagsCard.TagStyles.red]: 'StandardHeartRed@2x.png',
    [PetTagsCard.TagStyles.charcoal]: 'StandardHeartCharcoal@2x.png',
    [PetTagsCard.TagStyles.teal]: 'StandardHeartTeal@2x.png',
  },
  [PetTagsCard.TagShapes.circle]: {
    [PetTagsCard.TagStyles.traditional]: 'LifetimeRoundTraditional@2x.png',
    [PetTagsCard.TagStyles.paw]: 'LifetimeRoundPaw@2x.png'
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { ShoppingCart, Button } from '../../'

export class DashboardShoppingCart extends React.PureComponent {
  static propTypes = {
    cartItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            productName: PropTypes.string,
            productCost: PropTypes.string
          })
        )
      })
    ),
    errorMessage: PropTypes.string,
    /**
     * Function accepts 2 arguments: index of cartItems array and index of items array
     */
    onRemoveClick: PropTypes.func,
    subtotal: PropTypes.string,
    proceedToPaymentButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * id is applied to ShoppingCart component
     */
    id: PropTypes.string,
    classes: PropTypes.shape({
      container: PropTypes.string,
      shoppingCart: PropTypes.string
    }),
    style: PropTypes.object,
    /**
     * refProp is applied to ShoppingCart component
     */
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    classes: {}
  }

  render() {
    const {
      subtotal,
      cartItems,
      onRemoveClick,
      proceedToPaymentButtonProps,
      id,
      classes,
      errorMessage,
      style,
      refProp
    } = this.props

    return (
      <div style={{ style }} className={cn(classes.container)}>
        <ShoppingCart
          id={id}
          className={cn('ui-dashboard-shopping-cart', classes.shoppingCart)}
          ref={refProp}
          showPromoRow={false}
          errorMessage={errorMessage}
          cartItems={cartItems}
          subtotal={subtotal}
          onRemoveClick={onRemoveClick}
        />

        <Button
          className={'ui-dashboard-shopping-cart__action'}
          color={Button.Colors.tertiary}
          {...proceedToPaymentButtonProps}
        >
          Proceed to payment
        </Button>
      </div>
    )
  }
}

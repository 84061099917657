import React from 'react'
import PropTypes from 'prop-types'

import{ 
    Input,
    Paper,
    PaperContent,
    PaperHeadline,
    Form,
    FormAction,
    Button,
    Link,
    Divider,
    IconButton,
    Icon,
    Text
 } from '../../'
import cn from 'classnames'

export class EmergencyContactCard extends React.PureComponent {
    static Types = Object.freeze({
        framed: 'framed',
        clean: 'clean'
    })

    static propTypes = {
        showAddContactLink:PropTypes.bool,
        showCrossIcon: PropTypes.bool,
        emergencyContactFields:PropTypes.arrayOf(
            PropTypes.shape({
                firstNameInputProps: PropTypes.shape({
                    value: PropTypes.string,
                    onChange: PropTypes.func,
                    onBlur: PropTypes.func,
                    onFocus: PropTypes.func,
                    name: PropTypes.string,
                    error: PropTypes.bool,
                    hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
                    id: PropTypes.string
                  }),
              
                lastNameInputProps: PropTypes.shape({
                    value: PropTypes.string,
                    onChange: PropTypes.func,
                    onBlur: PropTypes.func,
                    onFocus: PropTypes.func,
                    name: PropTypes.string,
                    error: PropTypes.bool,
                    hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
                    id: PropTypes.string
                  }),
          
                phoneInputFieldProps: PropTypes.shape({
                      value: PropTypes.string,
                      onChange: PropTypes.func,
                      onBlur: PropTypes.func,
                      onFocus: PropTypes.func,
                      name: PropTypes.string,
                      error: PropTypes.bool,
                      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
                      id: PropTypes.string
                  }),
          
                emailInputFieldProps: PropTypes.shape({
                      value: PropTypes.string,
                      onChange: PropTypes.func,
                      onBlur: PropTypes.func,
                      onFocus: PropTypes.func,
                      name: PropTypes.string,
                      error: PropTypes.bool,
                      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
                      id: PropTypes.string
                    }),
            })
        ),
        AddButtonProps: PropTypes.shape({
            disabled: PropTypes.bool,
            onClick: PropTypes.func,
            id: PropTypes.string
        }),
        linkProps: PropTypes.shape({
            href: PropTypes.string,
            target: PropTypes.string,
            id: PropTypes.string
        }),
        closeContactProps: PropTypes.func,
        onFormSubmit: PropTypes.func,
        id: PropTypes.string,
        errorMessage:PropTypes.string,
        successMessage:PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
    }
    static defaultProps = {
        emergencyContactFields:[
            {
                firstNameInputProps:{
                },
                lastNameInputProps:{
                },
                phoneInputFieldProps:{
                },
                emailInputFieldProps:{
                }
            }
        ],
        showAddContactLink: true,
        showCrossIcon: true,
    }
    
    render(){
        const {
            emergencyContactFields,
            errorMessage,
            successMessage,
            showAddContactLink,
            showCrossIcon,
            AddButtonProps,
            linkProps,
            closeContactProps,
            onFormSubmit,
            id,
            className,
            style
          } = this.props
        return (
            <div
                id={id}
                style={style}
                className={cn('ui-emergency-contact-card', className )}
            >
                <Paper type={Paper.Types.framed}>
                    <PaperHeadline>Emergency contact info</PaperHeadline>
                    <PaperContent className="ui-emergency-contact-card__content">
                        <Form onSubmit={onFormSubmit}>
                            {successMessage && (
                                <Text
                                    id='ECSuccessMessageId'
                                    type={Text.Types.bodyBold}
                                    className='ui-emergency-contact-card__success-message'
                                >
                                    {successMessage}
                                </Text>
                            )}

                            {errorMessage && (
                                <Text
                                    id='ECErrorMessageId'
                                    type={Text.Types.bodyBold}
                                    className='ui-emergency-contact-card__error-message'
                                >
                                    {errorMessage}
                                </Text>
                            )}
                            {emergencyContactFields.map(( fieldValue, index ) =>(
                                <div key={`ECId${index}`} className='ui-emergency-contact-card__inputs'>
                                    {showCrossIcon &&
                                       <div
                                            role='button'
                                            className='ui-emergency-contact-card__header'
                                        >
                                            <Text className='ui-emergency-contact-card__text'>
                                                {`Emergency contact ${index+1}`}
                                            </Text>
                                            <IconButton id={`ECDeleteId${index+1}`} type='close' onClick={() => closeContactProps(index)} />
                                        </div>
                                    }
                                    <Input
                                        id={`ECFirstNameId${index+1}`}
                                        label='First name'
                                        placeholder='Example: John'
                                        autoComplete='given-name'
                                        {...fieldValue.firstNameInputProps}
                                    />

                                    <Input
                                        id={`ECLastNameId${index+1}`}
                                        label='Last name'
                                        placeholder='Example: Smith'
                                        autoComplete='family-name'
                                        {...fieldValue.lastNameInputProps}
                                    />

                                    <Input
                                        id={`ECEmailId${index+1}`}
                                        label='Email Address'
                                        placeholder='Example: yourname@email.com'
                                        {...fieldValue.emailInputFieldProps}
                                    />
                                    
                                    <Input
                                        id={`ECPhoneId${index+1}`}
                                        label='Phone Number'
                                        placeholder='(###) ### ####'
                                        {...fieldValue.phoneInputFieldProps}
                                    />
                                    {(index !== emergencyContactFields.length-1 ) && <Divider className="ui-emergency-contact-card__divider" /> }

                                </div>
                            ))}
                            { showAddContactLink && <Link {...linkProps} id="addAnotherECId"><Icon type='add_circle' className='ui-emergency-contact-card__icon' /><span className='ui-emergency-contact-card__span'> Add another emergency contact</span></Link>}
                            <FormAction>
                                <Button
                                    id='ECSaveButtonId'
                                    color={Button.Colors.tertiary}
                                    type='submit'
                                    {...AddButtonProps}
                                >
                                    Save Changes
                                </Button>
                            </FormAction>
                        </Form>
                    </PaperContent>
                </Paper>
            </div>
            
        )    
    }
}
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Text,
  Form,
  Input,
  FormAction,
  Button
} from '../../'

export class ForgotPasswordCard extends React.PureComponent {
  static propTypes = {
    errorMessage: PropTypes.string,
    onFormSubmit: PropTypes.func,
    emailInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    sendEmailLinkButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      errorMessage,
      onFormSubmit,
      emailInputProps,
      sendEmailLinkButtonProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-forgot-password-card', className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Forgot your password</PaperHeadline>
        <PaperContent>
          <Form onSubmit={onFormSubmit}>
            <Text type={Text.Types.caption}>
              To reset your password please enter the email address associated
              with your account. An email will be sent to you with follow up
              information.
            </Text>

            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-forgot-password-card__error-message'
              >
                {errorMessage}
              </Text>
            )}

            <Input
              type='email'
              placeholder='Email address'
              {...emailInputProps}
            />

            <FormAction>
              <Button
                type='submit'
                color={Button.Colors.tertiary}
                {...sendEmailLinkButtonProps}
              >
                Email link
              </Button>
            </FormAction>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

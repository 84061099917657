import React from 'react'
import PropTypes from 'prop-types'

import {
  Paper,
  PaperContent,
  Text,
  Form,
  Input,
  FormAction,
  Button
} from '../..'
import { Link } from '../Link'

export class RmStep1Card extends React.PureComponent {
  static propTypes = {
    errorMessage: PropTypes.string,
    pageTitle: PropTypes.string,
    backToLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string,
      children: PropTypes.any
    }),
    loginLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),
    microchipNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    emailInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    registerButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    id: PropTypes.string,
    className: PropTypes.string,
    isAddingNewPet: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    onFormSubmit: PropTypes.func
  }

  render() {
    const {
      id,
      className,
      style,
      errorMessage,
      refProp,
      onFormSubmit,
      microchipNumberInputFieldProps,
      registerButtonProps,
      backToLinkProps,
      pageTitle,
      loginLinkProps,
      emailInputFieldProps,
    } = this.props

    return (
      <Paper id={id} className={className} style={style} ref={refProp}>
        <PaperContent>
          <Link {...backToLinkProps} color={Link.Colors.secondary}>
            {backToLinkProps.children}
          </Link>
          <Text type={Text.Types.headlineBold}>{pageTitle}</Text>

          <Form onSubmit={onFormSubmit} className='c-form'>
            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-rm-step1-card__error-message'
              >
                {errorMessage}
              </Text>
            )}
            <Input
              label='Microchip Number: (required)'
              {...microchipNumberInputFieldProps}
            />
            <Input
              label='Email Address: (required)'
              {...emailInputFieldProps}
            />
            <FormAction>
              <Button
                id="registerMicrochipId"
                color={Button.Colors.tertiary}
                type='submit'
                {...registerButtonProps}
              >
                Register
              </Button>
            </FormAction>
            <Text type={Text.Types.caption}>
              By clicking “Register”, you understand and consent to the collection, storage and
              use of your personal data for the purposes outlined in the 24Petwatch Privacy
              Policy. Your personal data privacy rights are outlined therein.
            </Text>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

import React from "react";
import { AppConstants } from "./app-constants";
import { AppUtils } from "./app-utils";
import { MerckLogo, NewMphLogo, SpotLogo } from "./svg-icons";
import { FAQ, FAQMerck, Link } from "../UI-Component-Library";
export class FeatureFlagUtils {
  static getLogo() {
    switch (AppConstants.CONFIG_JSON?.Program?.toLowerCase()) {
      case "merck":
        return <MerckLogo></MerckLogo>;
      case "spot":
        return <SpotLogo></SpotLogo>;
      case "pethealth":
        return <NewMphLogo></NewMphLogo>
      default:
        return null;
    }
  }
  static getShoppinCartUrl() {
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShoppingCartUrl;
  }
  static getFaq() {
    switch (AppConstants.CONFIG_JSON?.Program?.toLowerCase()) {
      case "merck":
        return <FAQMerck />;
      default:
        return <FAQ />;
    }
  }
  static getBoolean(value) {
    if (value && value.toString().toLowerCase() === "true") {
      return true;
    }
    return false;
  }

  static shouldShowNotifications() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowNotifications
    );
  }

  static shouldShowTextUnderProfilePicture() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowTextUnderProfilePicture
    );
  }

  static shouldShowInlineVariant() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowInlineVariant
    );
  }
  static getCompanyName() {
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.CompanyName;
  }
  static getPhoneNumber() {
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.PhoneNumber;
  }
  static getCompanyNameInContactUs() {
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.CompanyNameInContactUs;
  }
  static getDDMessage() {
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.DDMessage;
  }
  static showLinkOverrideCss(){
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS.ShowLinkOverrideCSS
    );
  }
  static isHideUserAddressField() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS.HideUserAddressFields
    );
  }
  static getIsEmojiEnabled() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.EnableEmoji
    );
  }
  static getContactInfoChangeWarning() {
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ContactInfoChangeWarning;
  }
  static getPaymentSettingsMessage() {
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.PaymentSettingsMessage;
  }
  static getNoPoliciesMessage() {
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.NoPoliciesMessage;
  }
  static shouldAllowFetchingPetRecoveryHistory() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.AllowPetRecoveryHistory
    );
  }
  static isShowTextContent() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowTextContentInAddAnotherPet
    );
  }
  static hideBackButtonInAddPetModal() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.HideBackButtonInAddPet
    );
  }
  static shouldShowAccountMessage() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS
        ?.ShowPersonalSignupAccountMessage
    );
  }
  static shouldDisableProductSelection() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.DisableProductSelection
    );
  }

  static shouldDisableInsuranceSelection() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.DisableInsuranceSelection
    );
  }

  static shouldShowTransferPetButton() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowTransferPetButton
    );
  }
  static shouldShowTertiaryButton() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowTertiaryButton
    );
  }
  static shouldShowDefaultButton() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowDefaultButton
    );
  }
  static shouldShowCancelPolicyLink() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowCancelPolicyLink
    );
  }
  static shouldShowOutlineButton() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowOutlinedButton
    );
  }
  static shouldShowTertiaryCheckbox() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowTertiaryCheckbox
    );
  }
  static shouldAlterLogoHeight() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.AlterLogoHeight
    );
  }
  static shouldAlterLogoSize() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.AlterLogoSize
    );
  }
  static shouldShowDashboardFooter() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowDashboardFooter
    );
  }
  static shouldShowVetHelplinecard() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowVetHelplineCard
    );
  }
  static shouldShowPetMedAlertCard() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowPetMedAlert
    );
  }
  static getSatTiming() {
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.SatTiming;
  }
  static shouldShowPetShoppingCart() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowPetShoppingCart
    );
  }
  static shouldShowServiceProgress() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowServiceProgress
    );
  }
  static shouldClickOnRegisterPet() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ClickOnRegisterPet
    );
  }
  static shouldShowLoginLabel() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowLoginLabel
    );
  }
  static getPrivacyPolicyUS(){
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.PrivacyPolicyUS;
  }
  static getPrivacyPolicyCA(){
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.PrivacyPolicyCA;
  }
  static getTermOfUse(){
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.TermOfUse;
  }
  static getInsuranceRestrictionMessage(){
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.InsuranceRestrictionMessage;
  }
  static getCancelInsurancePolicyMessage() {
    if (AppUtils.isEqualString(AppUtils.getCountryCode(), "US")) {
      return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.CancelInsurancePolicyMessageUS;
    } else {
      return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.CancelInsurancePolicyMessageCA;
    }
  }
  static showPrivacyPolicyCA(){
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.downloadPrivacyPolicyCA
    );
  }
  static showPrivacyPolicyUS(){
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.downloadPrivacyPolicyUS
    );
  }
  static hideCardImage(){
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.HideImageInCards
    );
  }
  static showHoursOfOperationContent(){
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowHoursOfOperationContent
    );
  }

  static showCustomContactHeader(){
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowCustomContactHeader
    );
  }

  static accessibilityDownload(){
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.AccessibilityDownload
    );
  }

  static hideLogoutButton(){
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.HideLogoutButton
    );
  }

  static getFaxNumber(){
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.FaxNumber;
  }
  static getResponseDays(){
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ResponseDays;
  }
  static getFAQMail(){
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.FAQMail;
  }
  static getFAQChecklist(){
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.FAQChecklist;
  }
  static getFAQPreApprovalLink(){
    return [{
      href:AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.FAQPreApproval,
      label:AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.PreApprovalLabel
    }]
  }
  static hideMphFAQSections(){
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.HideMPHFAQSections
    );
  }
  static shouldOverrideHeadingFont(){
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.OverrideHeadingFont
    );
  }
  static shouldShowPetNotes() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowPetNotes
    );
  }
  static shouldShowWarningMessage() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowWarningMessage
    );
  }
  static shouldShowPromoBanner() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowPromoBanner
    );
  }
  static hideFBLoginOption(){
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.HideFacebookLoginOption
    );
  }
  static showPetProPortalLink() {
    return this.getBoolean(
      AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.ShowPetProProtalLink
    );
  }
  static getPetProPortalLink() {
    return AppConstants.CONFIG_JSON?.PARTNER_SETTINGS?.PetProPortalLink;
  }
  static getRemovePetOptions(){
    return [
      {
        label:"Gave away",
        active:false
      },
      {
        label:"Deceased",
        active:false
      },
      {
        label:"Other",
        active:false
      },
    ]
  }

  static getCancelFoundPetAlertOptions() {
    return [
      {
        label: "I picked up my pet from the shelter/finder",
        active: false,
        value: 4
      },
      {
          label: "I found my pet myself",
          active: false,
          value: 2
      },
      {
          label: "I surrendered my pet",
          active: false,
          value: 11
      },
      {
          label: "My pet was never lost",
          active: false,
          value: 1
      },
      {
          label: "I do not own this pet",
          active: false,
          value: 18
      },
      {
        label: "This pet is deceased",
          active: false,
          value: 3
      }
    ]
  }
  static getSalesText() {
    if (AppUtils.isMerckProgram()) {
      return "Sales";
    }

    return "Membership Products & Services";
  }

  static getCanadaContactHours() {
    return [
      {
        title: `${this.getSalesText()}`,
        lines: [
          '1-866-597-2424',
          'Monday – Friday 9am – 9pm ET.',
          'Saturday and Sunday – Closed'
        ]
      },
      {
        title: "Lost Pet Recovery",
        lines:[
          '1-866-597-2424',
          'Monday – Sunday (7 days a week)'
        ]
      },
      {
        title: 'Pet Insurance Claims and Customer Service',
        lines: [
          '1-877-821-2424',
          'Monday – Friday 10am – 6pm ET.',
          'Saturday and Sunday – Closed'
        ]
      }
    ]
  }
  static getUSContactHours(){
    return [
      {
        title: `${this.getSalesText()}`,
        lines: [
          '1-866-597-2424',
          'Monday – Friday 9am – 9pm ET.',
          'Saturday and Sunday – Closed'
        ]
      },
      {
        title: "Lost Pet Recovery",
        lines:[
          '1-866-597-2424',
          'Monday – Sunday (7 days a week)'
        ]
      },
      {
        title: 'Pet Insurance Claims and Customer Service',
        lines: [
          '1-877-821-2424',
          'Monday – Friday 10am – 6pm ET.',
          'Saturday and Sunday – Closed'
        ]
      }
    ]
  }
  static getHoursOfOperationContent(showSpotTimings){
    if (showSpotTimings) {
      return [
        {
          lines: [
            'Mon - Fri: 8AM-8PM EST',
            'Sat: CLOSED',
            'Sun: CLOSED'
          ]
        }
      ]
    } else {
      if ((AppUtils.isEqualString(AppUtils.getCountryCode(), "US"))){
        return this.getUSContactHours();
      }

      return this.getCanadaContactHours();
    }
  }
  static getMaintenanceNoticeMessage() {
    return AppConstants.CONFIG_JSON?.MaintenanceNoticeMessage;
  }

  static reduceBottomLogoWidth(){
    if(AppConstants.CONFIG_JSON?.Program?.toLowerCase() === "pethealth"){
      return true;
    }

    return false;
  }

  static getConsentMessageText(){
    if (AppUtils.isEqualString(AppUtils.getCountryCode(), "US")){
      return (<>
        With your 24Pet® microchip, Pethealth Services (USA) Inc. may offer you
        free lost pet services, as well as exclusive offers, promotions and
        the latest information from 24Pet regarding microchip services.
        Additionally, PTZ Insurance Agency, Ltd. including its parents,
        PetPartners, Inc. and Independence Pet Group, Inc. and their subsidiaries
        (“collectively PTZ Insurance Agency, Ltd”)  may offer you promotions and the
        latest information from 24Petprotect™ regarding pet insurance services
        and products.
        < br /> <br />
        By giving consent, Pethealth Services (USA) Inc. and PTZ Insurance Agency, Ltd.
        including its parents, PetPartners, Inc. and Independence Pet Group, Inc. and
        their subsidiaries (“collectively PTZ Insurance Agency, Ltd”)  may contact you
        via commercial electronic messages, automatic telephone dialing systems,
        prerecorded/automated messages or text messages at the telephone number provided above,
        including your mobile number. These calls or emails are not a condition of the purchase
        of any goods or services.
        < br /> <br />
        You understand that if you choose not to provide your consent, you will
        not receive the above-mentioned communications or free lost pet services,
        which includes being contacted with information in the event that your pet
        goes missing.
        < br /> <br />
        You may withdraw your consent at any time.
      </>)
    } else {
      return (<>
        With your 24Pet® microchip, Pethealth Services Inc. may offer you
        free lost pet services, as well as exclusive offers, promotions and
        the latest information from 24Pet regarding microchip services.
        Additionally, PTZ Insurance Services Ltd. may offer you promotions and
        the latest information from Pets Plus Us® regarding pet insurance services
        and products.
        <br /><br />
        By giving consent, Pethealth Services Inc. and PTZ Insurance Services Ltd.
        may contact you via commercial electronic messages, automatic
        telephone dialing systems, pre-recorded/automated messages or text
        messages at the telephone number provided above, including your mobile
        number. These calls or emails are not a condition of the purchase of any
        goods or services.
        <br /><br />
        You understand that if you choose not to provide your consent, you will
        not receive the above-mentioned communications or free lost pet services,
        which includes being contacted with information in the event that your pet
        goes missing.
        <br /><br />
        You may withdraw your consent at any time.
      </>)
    }
  }

  static getCheckBoxConsentText() {
    if (AppUtils.isEqualString(AppUtils.getCountryCode(), "US")){
      return <>With your 24Pet® microchip, Pethealth Services (USA) Inc. ("PSU") may offer you free lost pet services, as well as exclusive offers, promotions and the latest information from 24Pet regarding microchip services. Additionally, PSU's affiliates, including PTZ Insurance Agency, Ltd., PetPartners, Inc. and Independence Pet Group, Inc., and their subsidiaries (collectively, "PTZ") may offer you promotions and the latest information regarding pet insurance services and products. PSU may also have or benefit from contractual arrangements with third parties ("Partners") who may offer you related services, products, offers and/or promotions.
      <br />By giving consent, you agree that PSU, its Partners and/or PTZ may contact you for the purposes identified herein via commercial electronic messages at the e-mail address you provided, via mailer at the mailing address you provided and/or via automatic telephone dialing systems, pre-recorded/automated messages and/or text messages at the telephone number(s) you provided.  Data and message rates may apply. This consent is not a condition of the purchase of any goods or services. You understand that if you choose not to provide your consent, you will not receive the above-mentioned communications or free lost pet services, which includes being contacted with information in the event that your pet goes missing.
      <br />You may withdraw your consent at any time.</>
    }

    return <>With your 24Pet® microchip, Pethealth Services Inc. ("PSI") may offer you free lost pet services, as well as exclusive offers, promotions and the latest information from 24Pet regarding microchip services. Additionally, PSI's affiliates, including PTZ Insurance Services Ltd., PetPartners, Inc. and Independence Pet Group, Inc., and their subsidiaries (collectively, "PTZ") may offer you promotions and the latest information regarding pet insurance services and products.  PSI may also have or benefit from contractual arrangements with third parties ("Partners") who may offer you related services, products, offers and/or promotions.
    <br />By giving consent, you agree that PSI, its Partners and/or PTZ may contact you for the purposes identified herein via commercial electronic messages at the e-mail address you provided, via mailer at the mailing address you provided and/or via automatic telephone dialing systems, pre-recorded/automated messages and/or text messages at the telephone number(s) you provided.  Data and message rates may apply. This consent is not a condition of the purchase of any goods or services. You understand that if you choose not to provide your consent, you will not receive the above-mentioned communications or free lost pet services, which includes being contacted with information in the event that your pet goes missing.
    <br />You may withdraw your consent at any time.</>
  }
}

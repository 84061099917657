import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { AppUtils } from "../../../utils/app-utils";
import RemovePetWrapperComponent from "../RemovePetWrapperComponent/RemovePetWrapperComponent";
import { showButtonLoader, hideButtonLoader} from "../../../utils/buttonLoader-util";
import {
  PetInfoCard,
  EditPetNameModal,
  ConfirmTransferPetModal,
  CancelTransferPetModal,
  RemovePetModal,
  UploadImageModal,
  AddMicrochipModal,
  PetHasInsuranceModal,
  Banner,
  UpdatePetStatusModal,
  Link,
  Text,
  Button,
  GeneralInfoModal,
} from "../../../UI-Component-Library";
import { ValidationMessages } from "../../../utils/validation-messages";
import heic2any from "heic2any";
import { AppConstants } from "../../../utils/app-constants";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { saveAnimalDetails } from "../../../redux/reducers/customer-profile-reducer";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";

class PetDetails extends React.Component {
  static propTypes = {
    pet: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      petInfo: undefined,
      isEditPetModalOpen: false,
      isRemovePetModalOpen: false,
      //Transfer Pet
      isTransferPetModalOpen: false,
      isCancelTransferPetModalOpen: false,
      isTansferCancelled: false,
      cancelTransferPet: false,
      isUploadImageModalOpen: false,
      petName: "",
      species:"",
      primaryBreed:"",
      secondaryBreed:"",
      petImageSelectedFile: undefined,
      uploadImageError: "",
      imageValidationError: "",
      editPetNameError: "",
      isAddMicrochipStep1ModalShown: false,
      isAddMicrochipStep2ModalShown: false,
      newMicrochipNumber: "",
      newMicrochipErrorMessage: "",
      microchipCompanySelectionFields: [],
      microchipCompany: "",
      newMicrochipError: false,
      isLostPet: false,
      petInfoOptionValue: "0",
      isShowPetUpdateModel: false,
      selectedPetId: undefined,
      breedsList:[],
      isRemovePetHasInsuranceDialogOpen: false,
      showRemovePetHasInsuranceModal: false,
      notifications: [],
      petNotes: "",
      isLostPetInfoLoaded: false,
      isFoundPetInfoLoaded: false,
      showLostPetAlertIcon: false,
      transferPetStatus: false,
      isGeneralInfoModalOpen: false,
      isBasicServiceModalOpen: false,
    };
    this.cRef = React.createRef();
  }

  getBreedList = (species, breedType) => {
    if(!species || !breedType ){
      return;
      }

    if (species > 2) {
        this.setState({
            breedsList: [],
        });

        return;
    }

    const payload = {
      species: AppConstants.SPECIESMAP[species],
      breedType: breedType,
      country: AppUtils.getCountryCode().toLowerCase(),
    };

    axios
      .processPost(`${ApiConstants.URL_MICRO_LOAD_PET_LIST}`, null, payload)
      .then((response) => {
        this.setState({
          breedsList: response.data,
        });
      })
      .catch((error) => {});
  }


  getPetDetails() {
    axios
      .processGet(
        `${ApiConstants.URL_GET_ANIMAL_INFO}${
          this.props.pet.animalId
        }/${AppUtils.getBusRelAccount()}`,
        this.cRef
      )
      .then((petDetails) => {
        this.setState({
          petInfo: petDetails,
        });
        this.getBreedList(petDetails.speciesId, "Purebred");
        this.props.saveAnimalDetails(petDetails);
      });

  }

    getBreedOptions = (list) => {
        if (Object.keys(list).length == 0) {
            return [];
        }

        const options = [{
            value: "0",
            label: "Select One...",
        }];

        const firstbreed = [];

        for (let optionObj of list) {
            firstbreed.push({
                value: optionObj.Key.toString(),
                label: optionObj.Value,
            });

        }

        firstbreed.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        });

        let firstbreedlist = options.concat(firstbreed);

        return firstbreedlist;
    };

    getSecondaryBreedOptions = (list) => {
        if (Object.keys(list).length == 0) {
            return [];
        }

        const options = [{
            value: "0",
            label: "Select One...",
        }];

        const secondbreed = [];

        for (let optionObj of list) {
            secondbreed.push({
                value: optionObj.Key.toString(),
                label: optionObj.Value,
            });
        }

        secondbreed.push({
            value: "5637146421",
            label: "Crossbreed"
        });

        secondbreed.sort(function (a, b) {
            return a.label.localeCompare(b.label);
        });

        let secondbreedlist = options.concat(secondbreed);

        return secondbreedlist;
    };

  getLostPetStatus = () => {
    axios
      .processGet(
        `${ApiConstants.URL_GET_PET_STATUS}/${this.props.pet?.animalId}`,
        null
      )
      .then((response) => {
        let isMissing = response?.data?.isMissing;
        this.setState({
          isLostPet: isMissing,
          isLostPetInfoLoaded: true,
        });
      })
      .catch((error) => {});
  };

  updateLostPetStatus = () => {
    const petInfoOptionValue = parseInt(this.state.petInfoOptionValue);
    if (this.props.selectedAnimal.insurances[0]?.policynumber.length && (
      petInfoOptionValue === 11 ||
      petInfoOptionValue === 3 ||
      petInfoOptionValue === 18
    )) {
        let hasActiveInsurance = ( this.props.selectedAnimal.insurances[0]?.policynumber.length) ? true : false;
        this.setState({
          isShowPetUpdateModel: false,
          petInfoOptionValue: "0",
          showRemovePetHasInsuranceModal: hasActiveInsurance
        })
        return;
    }

    const payload = {
      busRelAccount: AppUtils.getBusRelAccount(),
      animalId: this.state.petInfo?.animalId,
      microchipNumber: this.state.petInfo?.microchipNumber,
      resolveReason: petInfoOptionValue,
    };
    const saveButton = document.getElementById("Lost_Pet_Status");
    showButtonLoader(saveButton);
    axios
      .processPost(ApiConstants.URL_CLOSE_REPORT, null, payload)
      .then((response) => {
        this.setState({
          isLostPet: !this.state.isLostPet,
          isShowPetUpdateModel: false,
        });
        this.props.updatePetStatusMessage();
        this.getPetInfoBanner();
        hideButtonLoader(saveButton);
        this.getPetDetails();
      })
      .catch((error) => {
        this.setState({
          isShowPetUpdateModel: false,
        });
        hideButtonLoader(saveButton);
      });
  };

  getPetInfoBanner = () => {
    if (this.state.isLostPet && AppUtils.isPethealthProgram()) {
      const title = `${this.state.petInfo?.name} has been reported as missing.`;
      const subtitle = (
        <Text>
          If your pet is no longer missing or this is an error, please{" "}
          <Link
            id="lost-pet-missing"
            onClick={(event) => {
              this.setState({
                isShowPetUpdateModel: true,
              });
            }}
            style={{ textColor: "red" }}
          >
            <u>click here</u>
          </Link>
          .
        </Text>
      );
      return (
        <Banner
          title={title}
          subtitle={subtitle}
          color={Banner.Colors.warning}
        />
      );
    }
  };

  getNotifications(){
    if(!AppUtils.getBusRelAccount()){
      return;
    }
    axios
    .processGet(
      `${ApiConstants.URL_GET_NOTIFICATIONS}?brAccount=${AppUtils.getBusRelAccount()}`,
      null
    )
    .then((response) => {
      if(!Array.isArray(response)){
        this.setState({
          notifications: [],
        })
        return;
      }
      const notifications = response;
      const showLostPetAlertIcon = this.isLostPetFound(notifications);
      // const transferPetStatus = this.transferStatus(notifications);
      this.setState({
        notifications,
        showLostPetAlertIcon,
        isFoundPetInfoLoaded: true,
      })
    })
    .catch((err) => {});
  }

  isLostPetFound(notifications){
    const selectedPetId = this.props.selectedPetId;
    let showLostPetAlertIcon = false;
    if(notifications.length === 0) return false;
    let checkAlertCancelled = notifications.filter(function(notification){
      let isFoundPet = AppUtils.isEqualString(notification.type, 'FoundPet');
      if( isFoundPet &&
          notification.animalId === selectedPetId &&
          notification.status === 'A'
      ){
        return true;
      }
      return false;
    })

    showLostPetAlertIcon = (checkAlertCancelled.length)? true : false;

    return showLostPetAlertIcon
  }

  transferStatus(notifications) {
    const selectedPetId = this.props.selectedPetId;
    let transferPetActive = false;
    if(notifications.length === 0) return transferPetActive;
    let transferPetAlert = notifications.filter(function(notification){
      let isTransferPetAlert = (notification.notificationCategoryId === 2 || notification.notificationCategoryId === 3);
      let isStatusActive = (notification.status === 'A' || notification.status === 'N')

    if( isTransferPetAlert &&
        isStatusActive &&
        notification.animalId === selectedPetId
    ){
      return true;
    }
      return false;
    })

    transferPetActive = (transferPetAlert.length)? true : false;

    return transferPetActive;
  }

  componentDidMount() {
    const { pet } = this.props;
    if (!pet.isNewRegistered) {
      this.getPetDetails();
    }
    if (AppUtils.isPethealthProgram()) {
      this.getLostPetStatus();
    }
    if (FeatureFlagUtils.shouldShowNotifications()) {
      this.getNotifications();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.species !== prevState.species) {
      this.getBreedList(this.state.species, "Purebred");
    }
    if (this.props.selectedPetId !== prevProps.selectedPetId) {
      const showLostPetAlertIcon = this.isLostPetFound(this.state.notifications);
      // const transferPetStatus = this.transferStatus(this.state.notifications);
      this.setState({
        showLostPetAlertIcon
      })
    }
  };

  updatePet = () => {
    this.setState({
      editPetNameError: "",
    });
    let petName=this.state.petInfo.name,
        primaryBreed=this.state.petInfo.breedPrimaryId,
        secondaryBreed=this.state.petInfo.breedSecondaryId,
        species=this.state.petInfo.speciesId;
    
    if(!AppUtils.isEmptyString(this.state.petName)){
      petName=this.state.petName;
    }

    if(this.state.primaryBreed){
      primaryBreed=parseInt(this.state.primaryBreed,10);
    }

    if(this.state.secondaryBreed){
      secondaryBreed=parseInt(this.state.secondaryBreed,10);
    }

    if(this.state.species){
      species=parseInt(this.state.species,10);
    }

    this.setState(
      {
        petName,
        primaryBreed,
        secondaryBreed,
        species,
      },
      () => {
        const payload = {
          busRelAccount: AppUtils.getBusRelAccount(),
          animalId: this.state.petInfo.animalId,
          name: this.state.petName,
          MicrochipNumber: this.state.petInfo.microchipNumber,
          DOB: this.state.petInfo.dob,
          SpeciesId: this.state.species,
          BreedPrimaryId: this.state.primaryBreed,
          BreedSecondaryId: this.state.secondaryBreed,
          Sex: this.state.petInfo.sex,
          SpayedNeutered: this.state.petInfo.spayedNeutered,
          petNotes: this.state.petNotes,
        }

        axios
          .processPost(`${ApiConstants.URL_POST_UPDATE_PET_PROFILE}`,
            this.cRef,
            payload
          )
          .then((response) => {
            this.setState(
              {
                isEditPetModalOpen: false,
              },
              this.getPetDetails
            );
          })
          .catch((err) => {
            let errors = err?.response?.data?.errors;
            if (errors && errors.length > 0) {
              this.setState({
                editPetNameError: errors[0],
              });
            }
          });
      }
    )
    
  };

  getBase64(file, callback) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) => {};
  }
  upsertPetImage = () => {
    if (!this.state.petImageSelectedFile) {
      this.setState({
        uploadImageError: "Please select a photo to upload.",
      });
      return;
    }
    this.setState({
      uploadImageError: "",
    });
    this.getBase64(this.state.petImageSelectedFile, (data) => {
      const saveButton = document.getElementById("pet-image-save-button");
      showButtonLoader(saveButton);
      axios
        .processPost(ApiConstants.URL_UPSERT_ANIMAL_PHOTO, null, {
          animalId: this.state.petInfo.animalId,
          busRelAccount: AppUtils.getBusRelAccount(),
          photo: data,
          photoName: this.state.petImageSelectedFile.name,
          contentType: this.state.petImageSelectedFile.type,
          fileImageId: this.state.petInfo.fileImageId,
        })
        .then((response) => {
          this.setState({
            isUploadImageModalOpen: false,
          });
          this.getPetDetails();
          hideButtonLoader(saveButton);
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              uploadImageError: errors[0],
            });
          }
          hideButtonLoader(saveButton);
        });
    });
  };
  handleImageChange = async (e) => {
    let imageValidationError = "";
    const allowedFileTypes = [
      "image/png",
      "image/jpeg",
      "image/heic",
      "image/gif",
    ];
    let file = e.target.files[0];
    const fileName = file.name;
    if (file.type === "" || file.type === "image/heic") {
      file = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.5,
      });
      file.lastModifiedDate = new Date();
      file.name = fileName.replace("heic", "jpg");
    }
    if (!allowedFileTypes.includes(file.type)) {
      imageValidationError =
        "Sorry, accepted files are  .jpeg .heic .png .gif ";
    }
    if (file.size > 10 * 1024 * 1024) {
      imageValidationError = "File exceeds maximum size limit.";
    }
    this.setState({
      imageValidationError,
      petImageSelectedFile:
        imageValidationError.length === 0 ? file : undefined,
    });
  };

  clickHereLinkHandler(e){
    this.props.history.push({
      pathname: "/pages/notifications",
      state: this.props.selectedPetId
    });
    e.preventDefault();
  }

  petLostInfo() {
    return (
      <>
        <Text
          type={Text.Types.body}
          className='ui-pet-info-card__alert_message'
        >
          Lost
        </Text>
      </>
    )
  }

  petFoundInfo(){
    return (
      <>
        <Text
            type={Text.Types.body}
            className='ui-pet-info-card__alert_message'
          >
            Your pet has been found!{' '}
            <Link
              className='ui-pet-info-card__click_here dd-text-link-override'
              onClick={
                (event) => { this.clickHereLinkHandler(event)}
              }
            >
              Click here
            </Link>{' '}
            for more details
        </Text>
      </>
    )
  }

  getLostPetInfo(showLostPetAlertIcon) {
    if ( this.state.isFoundPetInfoLoaded &&
      this.state.isLostPetInfoLoaded &&
      FeatureFlagUtils.shouldShowTextUnderProfilePicture()
    ) {
      if (showLostPetAlertIcon) {
        return this.petFoundInfo();
      }
      if (this.state.isLostPet) {
        return this.petLostInfo();
      }
    }

    return (
      <>
        {''}
      </>
    )
  }

  cancelTransferPet = () => {
    // const payload = {}
    // axios
    //   .processPost(
    //     `${ApiConstants.URL_POST_CANCEL_TRANSFER_PET}`,
    //     null,
    //     payload
    //   )
    //   .then((response) => {
      
    //   })
    //   .catch((err) => {
    //   })
    this.setState({
      isTansferCancelled: true,
    })
  }
  removePetModalHandler = (isRemovePetModalOpen, isRemovePetHasInsuranceDialogOpen) => {
    this.setState({
        isRemovePetModalOpen,
        isRemovePetHasInsuranceDialogOpen,
    })
  }

  getNotesLabel() {
    if (this.state.petInfo?.petNotesEditable) {
      return "The health and safety of your pet is important to us. Please include any medical condition, behavioural or special need that you want someone to know should your pet go missing and is found. This note is visible to anyone who enters your pet's microchip number in our database. 400 character limit.";
    } else {
      return "To add notes about your pet (i.e. medical, behavioural or special needs), please call 1-866-597-2424."
    }
  }

  render() {
    const { pet } = this.props;
    const breedsOption = this.getBreedOptions(this.state.breedsList);
    const SecondaryBreedsOption = this.getSecondaryBreedOptions(this.state.breedsList);
    return (
      <React.Fragment>
        <div ref={this.cRef}>
          {!this.state.petInfo || pet.isNewRegistered ? (
            <div>
              <PetInfoCard
                showServicesProgress={FeatureFlagUtils.shouldShowServiceProgress()}
                showTick={AppUtils.isLostPetEnabled()}
                petAvatar={""}
                showLostPetAlertIcon={this.state.showLostPetAlertIcon}
                info= { this.getLostPetInfo(this.state.showLostPetAlertIcon)}
                petName={pet.animalName}
                petNameEditElementId="pet-name"
                avatarElementId="avatar"
                showRegisterMicrochipButton={AppUtils.isEmptyString("")}
                registerMicrochipButtonProps={{
                  id: "registerMicrochipButton",
                  onClick: () => {},
                }}
                onPetNameEditClick={() => {}}
                removePetLinkProps={{
                  id:"removePetId",
                  href:"#",
                  className: FeatureFlagUtils.showLinkOverrideCss()
                  ? "dd-text-link-override"
                  : "",
                  color: (FeatureFlagUtils.shouldShowDefaultButton())
                    ? Link.Colors.default
                    : Link.Colors.tertiary,
                  onClick:(e) => {
                    e.preventDefault();
                  }
                }}
                isPetBeingTransferred={false} // this.state.transferPetStatus
                transferPetLinkProps={{
                  id:"transferPetId",
                  href: "#",
                  className: FeatureFlagUtils.showLinkOverrideCss()
                  ? "dd-text-link-override"
                  : "",
                  style: FeatureFlagUtils.shouldShowTransferPetButton()
                  ? {}
                  : {"display": "none"},
                  color: (FeatureFlagUtils.shouldShowDefaultButton())
                    ? Link.Colors.default
                    : Link.Colors.tertiary,
                  onClick:(e) => {
                    e.preventDefault();
                  }
                }}
                showBasicServiceProtection={AppUtils.isLostPetEnabled()}
                basicServiceLinkProps={{
                  id: "basicServiceId",
                  href:"#",
                  className: FeatureFlagUtils.showLinkOverrideCss()
                  ? "dd-text-link-override"
                  : "",
                  color: (FeatureFlagUtils.shouldShowDefaultButton())
                    ? Link.Colors.default
                    : Link.Colors.tertiary,
                  onClick:(e) => {
                    e.preventDefault();
                  }
                }}
                petAge={""}
                petBreed={""}
                petColor={""}
                petMicroChipNumber={""}
                petPetWatchId={""}
                onPetAvatarClick={() => {}}
                numberOfServicesEnrolled={0}
                isNewRegistered={pet.isNewRegistered}
              />
            </div>
          ) : (
            <div>
              <PetInfoCard
                showServicesProgress={FeatureFlagUtils.shouldShowServiceProgress()}
                showTick={AppUtils.isLostPetEnabled()}
                petAvatar={this.state.petInfo?.photo}
                petName={this.state.petInfo?.name}
                showLostPetAlertIcon={this.state.showLostPetAlertIcon}
                info= { this.getLostPetInfo(this.state.showLostPetAlertIcon) }
                petNameEditElementId="pet-name"
                avatarElementId="avatar"
                showRegisterMicrochipButton={AppUtils.isEmptyString(
                  this.state.petInfo?.microchipNumber
                )}
                registerMicrochipButtonProps={{
                  id: "registerMicrochipButton",
                  onClick: () => {
                    this.setState({
                      isAddMicrochipStep1ModalShown: true,
                      newMicrochipError: false,
                      newMicrochipErrorMessage: "",
                      newMicrochipNumber: "",
                    });
                  },
                }}
                onPetNameEditClick={() => {
                  this.setState({ 
                    isEditPetModalOpen: true,
                    primaryBreed:this.state.petInfo.breedPrimaryId,
                    secondaryBreed:this.state.petInfo.breedSecondaryId,
                    species:this.state.petInfo.speciesId,
                    petName: this.state.petInfo.name,
                    petNotes: this.state.petInfo.petNotes,
                   });
                }}
                removePetLinkProps={{
                  id: "removePetId",
                  color: (FeatureFlagUtils.shouldShowDefaultButton())
                    ? Link.Colors.default
                    : Link.Colors.tertiary,
                  href:"#",
                  className: FeatureFlagUtils.showLinkOverrideCss()
                  ? "dd-text-link-override"
                  : "",
                  onClick:(e) => {
                    e.preventDefault();
                    DataLayerUtils.removePetEvent(
                      AppUtils.getUserRelationId(),
                      this.state.petInfo?.microchipNumber,
                      "click_to_remove",
                    )
                    this.setState({
                      isRemovePetModalOpen: (this.props.selectedAnimal.insurances[0]?.policynumber.length)? false : true,
                      isRemovePetHasInsuranceDialogOpen: (this.props.selectedAnimal.insurances[0]?.policynumber.length) ? true : false
                    })
                  }
                }}
                transferPetLinkProps={{
                  id: "transferPetId",
                  color: (FeatureFlagUtils.shouldShowDefaultButton())
                    ? Link.Colors.default
                    : Link.Colors.tertiary,
                  href: "#",
                  className: FeatureFlagUtils.showLinkOverrideCss()
                  ? "dd-text-link-override"
                  : "",
                  style: FeatureFlagUtils.shouldShowTransferPetButton()
                  ? {}
                  : {"display": "none"},
                  onClick: (e) => {
                    e.preventDefault();
                    this.setState({
                      // isTransferPetModalOpen: true,
                      isGeneralInfoModalOpen: true,
                    })
                  }
                }}
                showBasicServiceProtection={AppUtils.isLostPetEnabled()}
                basicServiceLinkProps={{
                  id: "basicServiceId",
                  href:"#",
                  className: FeatureFlagUtils.showLinkOverrideCss()
                  ? "dd-text-link-override"
                  : "",
                  color: (FeatureFlagUtils.shouldShowDefaultButton())
                    ? Link.Colors.default
                    : Link.Colors.tertiary,
                  onClick:(e) => {
                    e.preventDefault();
                    this.setState({
                      isBasicServiceModalOpen: true,
                    })
                  }
                }}
                isPetBeingTransferred={false} // this.state.transferPetStatus
                cancelTransferLinkProps={{
                  id: "cancelTransferId",
                  color: Link.Colors.tertiary,
                  href:"#",
                  onClick: (e) => {
                    e.preventDefault();
                    this.setState({
                      isCancelTransferPetModalOpen: true,
                    })
                  }
                }}
                petAge={this.state.petInfo?.age}
                petBreed={this.state.petInfo?.breedPrimary}
                petColor={this.state.petInfo?.color}
                petMicroChipNumber={this.state.petInfo?.microchipNumber}
                petPetWatchId={this.state.petInfo?.petWatchId}
                onPetAvatarClick={() => {
                  this.setState({
                    isUploadImageModalOpen: true,
                  });
                }}
                numberOfServicesEnrolled={
                  this.state.petInfo?.serviceEnrolled?.trim() === ""
                    ? 0
                    : this.state.petInfo?.serviceEnrolled?.split(",")?.length
                }
              />
              {this.getPetInfoBanner()}
              <EditPetNameModal
                errorMessage={this.state.editPetNameError}
                open={this.state.isEditPetModalOpen}
                onClose={() => {
                  this.setState({ isEditPetModalOpen: false });
                }}
                petNameInputProps={{
                  id: "EP_pet_Name",
                  label: "Pet name:",
                  value: this.state.petName,
                  placeholder: "New pet name",
                  onChange: (e) => {
                    this.setState({
                      petName: e.target.value,
                    });
                  },
                }}
                insuranceCarrierInputProps={{
                  hidden: true
                }}
                generalDescriptionInputProps={{
                  id: "EP_pet_notes",
                  hidden: !FeatureFlagUtils.shouldShowPetNotes(),
                  value: this.state.petNotes,
                  disabled: !this.state.petInfo?.petNotesEditable,
                  label: (FeatureFlagUtils.shouldShowPetNotes())
                    ? this.getNotesLabel()
                    : "",
                  maxLength: "400",
                  onChange: (e) => {
                    this.setState({
                      petNotes: e.target.value,
                    });
                  },
                }}
                microchipNumberInputProps={{
                  id: "EP_microchip",
                  value: (AppUtils.isEmptyString(
                    this.state.petInfo?.microchipNumber
                  )) ? "" : this.state.petInfo.microchipNumber,
                  disabled : true,
                }}
                dobInputProps={{
                  id: "EP_dob",
                  value : (AppUtils.isEmptyString(
                    this.state.petInfo?.dob
                  )) ? "" : moment(this.state.petInfo.dob).format("DD/MM/YYYY"),
                  disabled: true,
                }}
                speciesInputProps={{
                  id: "EP_species",
                  value:this.state.species.toString(),
                  label: "Species:",
                  options:AppConstants.SPECIES,
                  placeholder:"New Species",
                  onChange: (e) => {
                    this.setState({
                      species: e.target.value,
                    });
                  },
                  disabled : (this.props.selectedAnimal.insurances[0]?.policynumber.length)? true : false,
                }}
                primaryBreedInputProps={{
                  id: "EP_primary_breed",
                  value:this.state.primaryBreed.toString(),
                  options: breedsOption,
                  placeholder:"New Primary Breed",
                  onChange: (e) => {
                    this.setState({
                      primaryBreed: e.target.value,
                    });
                  },
                  disabled : (this.props.selectedAnimal.insurances[0]?.policynumber.length)? true : false,
                }}
                secondaryBreedInputProps={{
                  id: "EP_sec_breed",
                  value:this.state.secondaryBreed.toString(),
                  options: SecondaryBreedsOption,
                  placeholder:"New Secondary Breed",
                  onChange: (e) => {
                    this.setState({
                      secondaryBreed: e.target.value,
                    });
                  },
                  disabled : (this.props.selectedAnimal.insurances[0]?.policynumber.length)? true : false,
                }}
                sexInputProps={{
                  id: "EP_sex",
                  value:AppConstants.SEX[this.state.petInfo.sex],
                  disabled: true,
                }}
                neuterInputProps={{
                  id: "EP_neuter",
                  value:AppConstants.SPAYEDNEUTER[this.state.petInfo.spayedNeutered],
                  disabled: true,
                }}
                cancelButtonProps={{
                  id: "EP_cancel-button",
                  variant:(FeatureFlagUtils.shouldShowInlineVariant())?Button.Variants.inline:Button.Variants.outlined,
                  color: (FeatureFlagUtils.shouldShowInlineVariant())?Button.Colors.secondary:Button.Colors.tertiary,
                  onClick: () => {
                    this.setState({
                      isEditPetModalOpen: false,
                      petName: "",
                      primaryBreed:"",
                      secondaryBreed:"",
                      species: "",
                      petNotes: "",
                    });
                  },
                }}
                saveButtonProps={{
                  id: "EP_save_button",
                  disabled: (this.state.petName || this.state.primaryBreed || this.state.secondaryBreed || this.state.species)
                  ? false : true,
                  color: Button.Colors.tertiary,
                  onClick: () => {
                    this.updatePet();
                  },
                }}
              />
              <GeneralInfoModal
                open={this.state.isGeneralInfoModalOpen}
                onClose={() => {
                  this.setState({
                    isGeneralInfoModalOpen: false,
                  })
                }}
                modalHeadline={"Transfer pet"}
                buttonText={"OK"}
                textId={"transferPetId"}
                cancelButtonProps={{
                  id:"transferPetButtonId",
                  size:Button.Sizes.small,
                  onClick: () => {
                    this.setState({
                      isGeneralInfoModalOpen: false,
                    })
                  }
                }}
                callInMessage={"To transfer a pet's microchip registration to a new registrant, please call 1-877-707-7297 where one of our customer experience specialists will be happy to assist you."}
              >
              </GeneralInfoModal>
              <RemovePetWrapperComponent
                microchipNumber={this.state.petInfo?.microchipNumber}  
                isRemovePetModalOpen={this.state.isRemovePetModalOpen}
                removePetModalHandler={this.removePetModalHandler}
                isRemovePetHasInsuranceDialogOpen={this.state.isRemovePetHasInsuranceDialogOpen}
              />
              <ConfirmTransferPetModal
                open={this.state.isTransferPetModalOpen}
                onClose={() => {
                  this.setState({
                    isTransferPetModalOpen: false,
                  })
                }}
                confirmTransferButtonProps = {{
                  onClick: () => {
                    this.props.history.push("/pages/transfer-pet");
                  }
                }}
                cancelTransferButtonProps = {{
                  onClick: () => {
                    this.setState({
                      isTransferPetModalOpen: false,
                    })
                  }
                }}
              />
              <CancelTransferPetModal
                open={this.state.isCancelTransferPetModalOpen}
                onClose={() => {
                  this.setState({
                    isCancelTransferPetModalOpen: false,
                  })
                }}
                title={this.state.isTansferCancelled ? "Success" : "Are you sure you want to cancel the transfer of this pet?" }
                successText={this.state.isTansferCancelled ? 'You have cancelled the transfer request of this pet successfully.' : ''}
                rejectButtonText={"Yes"}
                cancelButtonText={ this.state.isTansferCancelled ? 'Close' : 'Back'}
                cancelTransferButtonProps = {{
                  onClick: () => {
                    // call api to cancel transfer pet
                    this.cancelTransferPet();
                  }
                }}
                backButtonProps = {{
                  onClick: () => {
                    this.setState({
                      isCancelTransferPetModalOpen: false,
                      isTansferCancelled: false,
                    })
                  }
                }}
              />
              <UploadImageModal
                errorMessage={this.state.uploadImageError}
                open={this.state.isUploadImageModalOpen}
                onClose={() => {
                  this.setState({
                    isUploadImageModalOpen: false,
                    uploadImageError: "",
                    imageValidationError: "",
                  });
                }}
                imageInputProps={{
                  fileName: this.state.petImageSelectedFile?.name,
                  id: "pet-image-input",
                  error: this.state.imageValidationError?.length !== 0,
                  hint: (this.state.imageValidationError?.length !== 0)
                    ? this.state.imageValidationError
                    : "Must be a .jpg or .png file smaller than 10 MB",
                  onChange: this.handleImageChange,
                }}
                cancelButtonProps={{
                  id: "pet-image-cancel-button",
                  variant:(FeatureFlagUtils.shouldShowInlineVariant())?Button.Variants.inline:Button.Variants.outlined,
                  color: (FeatureFlagUtils.shouldShowInlineVariant())?Button.Colors.secondary:Button.Colors.tertiary,
                  onClick: () => {
                    this.setState({
                      isUploadImageModalOpen: false,
                      uploadImageError: "",
                      imageValidationError: "",
                      petImageSelectedFile: undefined,
                    });
                  },
                }}
                saveButtonProps={{
                  id: "pet-image-save-button",
                  disabled: (this.state.petImageSelectedFile)? false : true,
                  color: Button.Colors.tertiary,
                  onClick: (e) => {
                    this.upsertPetImage();
                  },
                }}
              />
            </div>
          )}
        </div>
        <AddMicrochipModal
          phoneNumber={FeatureFlagUtils.getPhoneNumber()}
          open={this.state.isAddMicrochipStep1ModalShown}
          errorMessage=""
          onClose={() => {}}
          showMicrochipCompanySelectField={true}
          microchipNumberInputProps={{
            value: this.state.newMicrochipNumber,
            onChange: (e) => {
              this.setState({
                newMicrochipNumber: e.target.value,
              });
            },
            name: "newMicrochipNumber",
            error: this.state.newMicrochipError,
            hint: this.state.newMicrochipErrorMessage,
            id: "newMicrochipNumber",
          }}
          cancelButtonProps={{
            id: "AM_cancelButton",
            variant:(FeatureFlagUtils.shouldShowInlineVariant())?Button.Variants.inline:Button.Variants.outlined,
            color: (FeatureFlagUtils.shouldShowInlineVariant())?Button.Colors.secondary:Button.Colors.tertiary,
            onClick: () => {
              this.setState({
                isAddMicrochipStep1ModalShown: false,
                newMicrochipError: false,
                newMicrochipErrorMessage: "",
                newMicrochipNumber: "",
              });
            },
          }}
          saveButtonProps={{
            id: "AM_saveButton",
            disabled: (this.state.newMicrochipNumber)? false : true,
            onClick: () => {
              this.validateMicroChip();
            },
          }}
        />
        <AddMicrochipModal
          phoneNumber={FeatureFlagUtils.getPhoneNumber()}
          open={this.state.isAddMicrochipStep2ModalShown}
          onClose={() => {}}
          microchipNumberInputProps={{
            value: this.state.newMicrochipNumber,
            onChange: (e) => {
              this.setState({
                newMicrochipNumber: e.target.value,
              });
            },
            name: "newMicrochipNumber",
            disabled: true,
            error: this.state.newMicrochipError,
            hint: this.state.newMicrochipErrorMessage,
            id: "AM_newMicrochipNumber",
          }}
          cancelButtonProps={{
            id: "AM_cancelButton",
            variant:(FeatureFlagUtils.shouldShowInlineVariant())?Button.Variants.inline:Button.Variants.outlined,
            color: (FeatureFlagUtils.shouldShowInlineVariant())?Button.Colors.secondary:Button.Colors.tertiary,
            onClick: () => {
              this.setState({
                isAddMicrochipStep2ModalShown: false,
                newMicrochipError: false,
                newMicrochipErrorMessage: "",
                newMicrochipNumber: "",
              });
            },
          }}
          showMicrochipCompanySelect={true}
          microchipCompanySelectFieldProps={{
            label: "Microchip Provider",
            options: this.state.microchipCompanySelectionFields,
            value: this.state.microchipCompany,
            onChange: (e) => {
              this.setState({
                microchipCompany: e.target.value,
              });
            },
            id: "AM_microchipCompanySelectField",
          }}
          saveButtonProps={{
            id: "AM_saveButton",
            label: "Save Changes",
            onClick: () => {
              this.addMicrochip();
            },
          }}
        />
        <UpdatePetStatusModal
          open={this.state.isShowPetUpdateModel}
          onClose={() => {}}
          petSelectProps={{
            options: AppUtils.petInfoOptions,
            value: this.state.petInfoOptionValue,
            onChange: (event) => {
              this.setState({
                petInfoOptionValue: event.target.value,
              });
            },
          }}
          cancelButtonProps={{
            variant:(FeatureFlagUtils.shouldShowInlineVariant())?Button.Variants.inline:Button.Variants.outlined,
            color: (FeatureFlagUtils.shouldShowInlineVariant())?Button.Colors.secondary:Button.Colors.tertiary,
            onClick: () => {
              this.setState({
                isShowPetUpdateModel: false,
                petInfoOptionValue: "0",
              });
            },
          }}
          saveButtonProps={{
            id: "Lost_Pet_Status",
            disabled: this.state.petInfoOptionValue === "0" ? true : false,
            onClick: () => {
              this.updateLostPetStatus();
            },
          }}
        />
        <PetHasInsuranceModal
            open={this.state.showRemovePetHasInsuranceModal}
            onClose={() => {}}
            cancelButtonProps={{
              onClick: () => {
                  this.setState({
                    showRemovePetHasInsuranceModal: false,
                  })
              }
            }}
                callInMessage={"There is an active insurance policy associated with this pet. Please call 24Petwatch for further assistance 1-877-821-2424"}
        />
        <GeneralInfoModal
          open={this.state.isBasicServiceModalOpen}
          onClose={() => {
            this.setState({
              isBasicServiceModalOpen: false,
            })
          }}
          modalHeadline={"24Petwatch Basic pet protection services"}
          buttonText={"Close"}
          cancelButtonProps={{
            variant:Button.Variants.filled,
            color:Button.Colors.secondary,
            size:Button.Sizes.small,
            onClick: () => {
              this.setState({
                isBasicServiceModalOpen: false,
              })
            }
          }}
          callInMessage={"If your pet ever goes missing and is found, they can be scanned at a vet or animal shelter to identify their microchip number. When the chip number is shared with 24Petwatch, you'll be notified using the consent and information on file. This may include email, automated phone call and SMS. Be sure to keep your contact details up-to-date."}
        />
      </React.Fragment>
    );
  }
  validateMicroChip() {
    this.setState({
      newMicrochipErrorMessage: "",
      newMicrochipError: false,
    });
    if (AppUtils.isEmptyString(this.state.newMicrochipNumber)) {
      this.setState({
        newMicrochipErrorMessage: ValidationMessages.EMPTY_MICROCHIP,
        newMicrochipError: true,
      });
      return;
    }
    const saveButton = document.getElementById("AM_saveButton");
    showButtonLoader(saveButton);
    axios
      .processGet(
        `${ApiConstants.URL_ADD_MICROCHIP}${this.state.newMicrochipNumber}`
      )
      .then((response) => {
        const data = response.data;
        const index = data?.indexOf("Other\\Unknown");
        if (index >= 0) {
          data.splice(index, 1);
          data.push("Other\\Unknown");
        }
        const microchipCompanySelectionFields = [];
        data &&
          data.forEach((d) => {
            microchipCompanySelectionFields.push({
              value: d,
              label: d,
            });
          });
        this.setState({
          isAddMicrochipStep1ModalShown: false,
          isAddMicrochipStep2ModalShown: true,
          microchipCompanySelectionFields,
          microchipCompany: data && data.length > 0 ? data[0] : "",
        });
        hideButtonLoader(saveButton);
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            newMicrochipErrorMessage: errors[0],
            newMicrochipError: true,
          });
        }
        hideButtonLoader(saveButton);
      });
  }

  addMicrochip() {
    this.setState({
      newMicrochipErrorMessage: "",
      newMicrochipError: false,
    });
    if (AppUtils.isEmptyString(this.state.newMicrochipNumber)) {
      return;
    }
    const saveButton = document.getElementById("AM_saveButton");
    showButtonLoader(saveButton);
    axios
      .processPost(ApiConstants.URL_SAVE_MICROCHIP, null, {
        microchipNumber: this.state.newMicrochipNumber,
        microchipCompany: this.state.microchipCompany,
        busRelAccount: AppUtils.getBusRelAccount(),
        animalId: this.props.selectedPetId,
        country: AppUtils.getCountryCode(),
      })
      .then((response) => {
        this.setState({
          isAddMicrochipStep1ModalShown: false,
          isAddMicrochipStep2ModalShown: false,
        });
        this.getPetDetails();
        hideButtonLoader(saveButton);
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            newMicrochipErrorMessage: errors[0],
            newMicrochipError: true,
          });
        }
        hideButtonLoader(saveButton);
      });
  }
}

const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    customerAnimals: customerProfileStore.customerAnimals,
    selectedPetId: customerProfileStore.selectedPetId,
    selectedAnimal: customerProfileStore.selectedAnimal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveAnimalDetails: (animalDetails) =>
      dispatch(saveAnimalDetails(animalDetails)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PetDetails));

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Tooltip } from '../Tooltip'
import { IconButton } from '../IconButton'

export class InputHelperWithTooltip extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    helperContent: PropTypes.string,
    tooltipIconType: PropTypes.string,
    tooltipContent: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    tooltipIconType: 'info',
    disabled: false
  }

  constructor(props) {
    super(props)

    this.state = {
      showTooltip: false
    }
  }

  showTooltip = (e) => {
    e.preventDefault()
    !this.props.disabled && this.setState({ showTooltip: true })
  }

  hideTooltip = () => this.setState({ showTooltip: false })

  render() {
    const {
      disabled,
      helperContent,
      tooltipIconType,
      tooltipContent,
      id,
      className,
      style,
      refProp,
      ...rest
    } = this.props

    return (
      <span
        id={id}
        style={style}
        ref={refProp}
        className={cn('ui-input-helper-with-tooltip', className)}
        {...rest}
      >
        {helperContent}
        <Tooltip
          open={this.state.showTooltip}
          onClose={this.hideTooltip}
          tooltipContent={tooltipContent}
        >
          <IconButton
            disabled={disabled}
            className='ui-input-helper-with-tooltip__icon-button'
            onClick={this.showTooltip}
            type={tooltipIconType}
          />
        </Tooltip>
      </span>
    )
  }
}

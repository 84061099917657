import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'
import { PaperContent, Text, IconButton, Checkbox, Icon, Paper } from '../../'


// Minimal width for the desktop layout to look good, after this break point render mobile view
const MOBILE_VIEW_BREAKPOINT = 555

export class NotificationsFiltersCard extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      filterOpen: (window.innerWidth > MOBILE_VIEW_BREAKPOINT),
      showFilterIcon: false,
      showCloseIcon: false
    }

    this.changeFilterOpenHandler = this.changeFilterOpenHandler.bind(this);
    this.closeFilterOpenHandler = this.closeFilterOpenHandler.bind(this);
    this.openFilterOpenHandler = this.openFilterOpenHandler.bind(this);
  }

  static propTypes = {
    allFilterProps: PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.any,
      errorMessage: PropTypes.string,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      onClick: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),
    incomingTransferProps: PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.any,
      errorMessage: PropTypes.string,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      onClick: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),
    foundPetProps: PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.any,
      errorMessage: PropTypes.string,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      onClick: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),
    outgoingTransferProps: PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.any,
      errorMessage: PropTypes.string,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      onClick: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  changeFilterOpenHandler(){
    if(window.innerWidth > MOBILE_VIEW_BREAKPOINT){
      this.setState({
        filterOpen: true, 
        showFilterIcon: false,
        showCloseIcon: false
      })
    }else{
      this.setState({
        filterOpen: false,
        showFilterIcon:true,
        showCloseIcon: false
      })
    }
  }

  closeFilterOpenHandler() {
    this.setState({
      filterOpen: false,
      showCloseIcon: false
   });
  }

  openFilterOpenHandler() {
    this.setState({
       filterOpen: true,
       showCloseIcon: true,
    });
  }

  componentDidMount() {
    this.changeFilterOpenHandler()

    window.addEventListener('resize', this.changeFilterOpenHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeFilterOpenHandler)
  }

  render() {
    const {
      allFilterProps,
      incomingTransferProps,
      foundPetProps,
      outgoingTransferProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper  id={id} className={cn('ui-notifications-filters-card__content', className)} style={style} ref={refProp}>
        <PaperContent>
          <div className='ui-notifications-filters-card_heading'>
            {this.state.showFilterIcon && (
              <div 
                className='ui-notifications-filters-card_filterText'
                onClick= {this.openFilterOpenHandler}
              >
                <Icon
                  type='filter_alt'
                />
                <Text
                  type={Text.Types.bodyBold}
                >
                  Filters:
                </Text>
              </div>
            )}
            {!this.state.showFilterIcon && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-notifications-filters-card_filterText'
              >
                Filters:
              </Text>
            )}
            {this.state.showCloseIcon && this.state.filterOpen && (
              <IconButton
                type='close'
                className='ui-notifications-filters-card_closeIcon'
                onClick= {this.closeFilterOpenHandler}
              />
            )}
          </div>
          { this.state.filterOpen && (
            <>
              <Text
                type={Text.Types.bodyBold}
                className='ui-notifications-filters-card_NotificationText'
              >
                Notifications
              </Text>
              <div className='ui-notifications-filters-card_filterTypes'>
                <Checkbox
                  type='check_circle'
                  label='All'
                  {...allFilterProps}
                />
              </div>
              <div className='ui-notifications-filters-card_filterTypes'>
                <Checkbox
                  type='check_circle'
                  label='Incoming transfer requests'
                  {...incomingTransferProps}
                />
              </div>
              <div className='ui-notifications-filters-card_filterTypes'>
                <Checkbox
                  type='check_circle'
                  label='Incoming found pet alerts'
                  {...foundPetProps}
                />
              </div>
              <div className='ui-notifications-filters-card_filterTypes'>
                <Checkbox
                  type='check_circle'
                  label='Outgoing transfer requests'
                  {...outgoingTransferProps}
                />
              </div>
            </>
          )}
        </PaperContent>
      </Paper>
    )
  }
}

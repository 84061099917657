import React from 'react'
import PropTypes from 'prop-types'

import { Paper, PaperHeadline, PaperContent, Button, Text } from '../../'

import { PUBLIC_URL } from '../../constants'

export class VetHelpLineCard extends React.PureComponent {
  static propTypes = {
    openChatButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { openChatButtonProps, id, className, style, refProp } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={className}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>24/7 Vet Helpline</PaperHeadline>
        <PaperContent
          className='ui-vet-help-card__content'
          backgroundImg={{
            src: PUBLIC_URL + 'fadeImg3.png',
            position: 'left'
          }}
        >
          <Text type={Text.Types.headlineBold}>
            Have any questions? Ask whiskerDocs<sup>®</sup>
          </Text>
          <Text>
            Veterinary experts are standing by to answer them. You can also
            contact whiskerDocs at 1-800-873-8838.
          </Text>
          <Button color={Button.Colors.tertiary} {...openChatButtonProps}>
            Open chat
          </Button>
        </PaperContent>
      </Paper>
    )
  }
}

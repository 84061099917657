import React from 'react'
import PropTypes from 'prop-types'

import { Paper, PaperHeadline, PaperContent, Text,  Icon, Link } from '../../'

export class ReunificationContactCard extends React.PureComponent {
  static propTypes = {
    rcontactsProps: PropTypes.array,
    deleteContactProps: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    linkProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    aboutReunificationContactText: PropTypes.string,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    rcontactsProps: [],
    aboutReunificationContactText: "Learn more about reunification contacts"
  }



  render() {
    const {  id, className, style, refProp, rcontactsProps, deleteContactProps, aboutReunificationContactText, linkProps } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={className}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Reunification Contact</PaperHeadline>
        <PaperContent>
          {rcontactsProps.map(( fieldValue, index ) =>(
            <div key={`RC${index}`} className='ui-reunification-contact-card__content' >
              <Text type={Text.Types.bodyBold}>
                  {fieldValue.name}
              </Text>
              <div
                  className="ui-reunification-contact-card__delete"
                  role='button'
                  onClick={() => deleteContactProps(index)}
                >
                <Text className="ui-reunification-contact-card__text">Delete</Text>
                  <Icon type='close' />
                </div>
            </div>
          ))}

          {rcontactsProps.length === 0 && (
            <div className='ui-reunification-contact-card__no-contacts'>
              <Text type={Text.Types.bodyBold}>
                No contact added
              </Text>
            </div>
          )}
          <Link color={Link.Colors.secondary} {...linkProps}>
            {aboutReunificationContactText}
          </Link>
        </PaperContent>
      </Paper>
    )
  }
}

import React, { Component } from "react";
import {
  UserProfileCard,
  TwoColumnContent,
  PageContent,
  UploadImageModal,
  Button,
  Link,
  Text,
} from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { AppUtils } from "../../../utils/app-utils";
import { ApiConstants } from "../../../utils/api-constants";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { withRouter } from "react-router-dom";
import { clearUserDataAfterLogout } from "../../../redux/actions/authActions";
import { connect } from "react-redux";
import ContactInfo from "./contact-info/contact-info";
import PaymentSetting from "./payment-settings/payment-settings";
import PaymentHistory from "./payment-history/payment-history";
import { showButtonLoader, hideButtonLoader} from "../../../utils/buttonLoader-util";
import heic2any from "heic2any";
import {
  backupCartData,
  clearCartData,
} from "../../../redux/reducers/membership-purchase-reducer";
const queryString = require("query-string");

class UserProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: undefined,
      isEmailChanged: false,
      isUploadImageModalOpen: false,
      userImageSelectedFile: undefined,
      uploadImageError: "",
      userImageId: undefined,
      userImage: undefined,
      userPhoto: undefined,
      isLostPet: false,
      notifications: [],
    };
  }
  logoutUser = () => {
    axios
      .processPost(ApiConstants.URL_LOGOUT)
      .then((response) => {})
      .catch((err) => {})
      .finally((err) => {
        this.props.backupCartData();
        this.props.logOut();
        this.props.clearCartData();
        AppUtils.clearLoginData();
        this.props.history.replace("/auth/login");
      });
  };
  getCustomerInfo() {
    axios
      .processGet(
        `${ApiConstants.URL_CUSTOMER_INFO}${AppUtils.getBusRelAccount()}`
      )
      .then((response) => {
        this.setState({
          userProfile: response,
        });
      })
      .finally(() => {});
  }

  getUserProfile = () => {
    axios
      .processGet(
        `${ApiConstants.URL_GET_USER_IMAGE}/${AppUtils.getUserRelationId()}`,
        null
      )
      .then((response) => {
        const fileImageId = response?.data?.fileImageId;
        const photo = response?.data?.photo;

        this.setState({
          userImageId: fileImageId,
          userPhoto: photo,
        });
      })
      .catch((error) => {});
  };
  getBase64(file, callback) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) => {};
  }

  uploadUserPhoto = () => {
    if (!this.state.userImageSelectedFile) {
      this.setState({
        uploadImageError: "Please select a photo to upload.",
      });
      return;
    }

    this.setState({
      uploadImageError: "",
    });

    this.getBase64(this.state.userImageSelectedFile, (data) => {
      const saveButton = document.getElementById("user-image-save-button");
      showButtonLoader(saveButton);
      const payload = {
        userRelationId: AppUtils.getUserRelationId(),
        photo: data,
        photoName: this.state.userImageSelectedFile.name,
        contentType: this.state.userImageSelectedFile.type,
        fileImageId: this.state.userImageId,
      };

      axios
        .processPost(ApiConstants.URL_UPLOAD_USER_PHOTO, null, payload)
        .then((response) => {
          this.setState({
            isUploadImageModalOpen: false,
          });
          this.getUserProfile();
          hideButtonLoader(saveButton);
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              uploadImageError: errors[0],
            });
          }
          hideButtonLoader(saveButton);
        });
    });
  };

  handleImageChange = async (e) => {
    let imageValidationError = "";
    const allowedFileTypes = [
      "image/png",
      "image/jpeg",
      "image/heic",
      "image/gif",
    ];
    let file = e.target.files[0];
    const fileName = file.name;
    if (file.type === "") {
      file = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.5,
      });
      file.lastModifiedDate = new Date();
      file.name = fileName.replace("heic", "jpg");
    }
    if (!allowedFileTypes.includes(file.type)) {
      imageValidationError =
        "Sorry, accepted files are  .jpeg .heic .png .gif ";
    }
    if (file.size > 10 * 1024 * 1024) {
      imageValidationError = "File exceeds maximum size limit.";
    }
    this.setState({
      imageValidationError,
      userImageSelectedFile:
        imageValidationError.length === 0 ? file : undefined,
    });
  };

  getLostPetStatus = () => {
    axios
      .processGet(
        `${ApiConstants.URL_GET_PET_STATUS}/${this.props?.selectedPetId}`,
        null
      )
      .then((response) => {
        let isMissing = response?.data?.isMissing;
        this.setState({
          isLostPet: isMissing,
        });
      })
      .catch((error) => {});
  };


  clickHereLinkHandler(e){
    this.props.history.push({
      pathname: "/pages/notifications",
    });
    e.preventDefault();
  }

  petLostInfo() {
    return (
      <>
        <Text
          type={Text.Types.body}
          className='ui-pet-info-card__alert_message'
        >
          Lost
        </Text>
      </>
    )
  }

  petFoundInfo(){
    return (
      <>
        <Text
            type={Text.Types.body}
            className='ui-user-profile-card__alert_message'
          >
            Your pet has been found!{' '}
            <Link
              className='ui-user-profile-card__click_here dd-text-link-override'
              onClick={
                (event) => { this.clickHereLinkHandler(event)}
              }
            >
              Click here
            </Link>{' '}
            for more details
        </Text>
      </>
    )
  }

  getLostPetInfo(showLostPetAlertIcon) {
    if (FeatureFlagUtils.shouldShowTextUnderProfilePicture()) {
      if (showLostPetAlertIcon) {
        return this.petFoundInfo();
      }
      // if (this.state.isLostPet) {
      //   return this.petLostInfo();
      // }
    }

    return (
      <>
        {''}
      </>
    )
  }

  getNotifications(){
    if(!AppUtils.getBusRelAccount()){
      return;
    }
    axios
    .processGet(
      `${ApiConstants.URL_GET_NOTIFICATIONS}?brAccount=${AppUtils.getBusRelAccount()}`,
      null
    )
    .then((response) => {
      if(!Array.isArray(response)){
        this.setState({
          notifications: [],
        })
        return;
      }
      const notifications = response;
      this.setState({
        notifications,
      })
    })
    .catch((err) => {});
  }

  isLostPetFound(notifications){
    const selectedPetId = this.props.selectedPetId;
    let showLostPetAlertIcon = false;
    if(notifications.length === 0) return false;
    let checkAlertCancelled = notifications.filter(function(notification){
      let isFoundPet = AppUtils.isEqualString(notification.type, 'FoundPet');
      if( isFoundPet &&
          notification.status === 'A'
      ){
        return true;
      }
      return false;
    })

    showLostPetAlertIcon = (checkAlertCancelled.length)? true : false;

    return showLostPetAlertIcon
  }


  componentDidMount() {
    const parsed = queryString.parse(this.props.history.location.search);
    if (parsed && AppUtils.isEqualString(parsed.focus, "dd")) {
      AppUtils.focusOnDiv("DD_FORM");
    }
    this.getCustomerInfo();
    this.getUserProfile();
    if (AppUtils.isPethealthProgram()) {
      this.getLostPetStatus();
    }
    if (FeatureFlagUtils.shouldShowNotifications()) {
      this.getNotifications();
    }
  }
  render() {
    const showLostPetAlertIcon = this.isLostPetFound(this.state.notifications);
    return (
      <React.Fragment>
        <PageContent>
          <UserProfileCard
            userName={this.props.customerFullName}
            userEmail={AppUtils.getUserEmail()}
            avatarElementId="userAvatarId"
            userAvatar={this.state.userPhoto}
            showErrorAlertIcon={showLostPetAlertIcon}
            info= { this.getLostPetInfo(showLostPetAlertIcon)}
            onUserAvatarClick={(event) => {
              this.setState({
                isUploadImageModalOpen: true,
              });
            }}
            clickHereLinkProps={{
              className: "dd-text-link-override",
              onClick: () => {}
            }}
            logoutButtonProps={{
              id: "logout button",
              style: FeatureFlagUtils.hideLogoutButton()
              ? {"display": "none"}
              : {},
              onClick: () => {
                this.logoutUser();
              },
            }}
          />

          {this.props.selectedAnimal && (
            <PaymentHistory
              allAnimals={this.props.customerAnimals}
            ></PaymentHistory>
          )}
          <TwoColumnContent
            leftColumn={
              <ContactInfo
                isEmailChanged={() => {
                  this.setState({
                    isEmailChanged: true,
                  });
                }}
              ></ContactInfo>
            }
            rightColumn={<PaymentSetting></PaymentSetting>}
          ></TwoColumnContent>
          <UploadImageModal
            errorMessage={this.state.uploadImageError}
            open={this.state.isUploadImageModalOpen}
            onClose={() => {}}
            imageInputProps={{
              fileName: this.state.userImageSelectedFile?.name,
              id: "user-image-input",
              // error: this.state.imageValidationError?.length > 0,
              // hint: this.state.imageValidationError,
              error: this.state.imageValidationError?.length > 0,
              hint: (this.state.imageValidationError?.length > 0)
                ? this.state.imageValidationError
                : "Must be a .jpg or .png file smaller than 10 MB",
              onChange: this.handleImageChange,
            }}
            cancelButtonProps={{
              id: "user-image-cancel-button",
              variant:(FeatureFlagUtils.shouldShowInlineVariant())?Button.Variants.inline:Button.Variants.outlined,
              color: (FeatureFlagUtils.shouldShowInlineVariant())?Button.Colors.secondary:Button.Colors.tertiary,
              onClick: () => {
                this.setState({
                  isUploadImageModalOpen: false,
                  uploadImageError: "",
                  imageValidationError: "",
                  userImageSelectedFile: undefined,
                });
              },
            }}
            saveButtonProps={{
              id: "user-image-save-button",
              disabled: (this.state.userImageSelectedFile)? false : true,
              color: Button.Colors.tertiary,
              onClick: (e) => {
                this.uploadUserPhoto();
              },
            }}
          />
        </PageContent>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    customerProfile: customerProfileStore.customerProfile,
    customerAnimals: customerProfileStore.customerAnimals,
    selectedPetId: customerProfileStore.selectedPetId,
    selectedAnimal: customerProfileStore.selectedAnimal,
    customerFullName: customerProfileStore.customerFullName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(clearUserDataAfterLogout()),
    clearCartData: () => dispatch(clearCartData()),
    backupCartData: () => dispatch(backupCartData()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserProfileContainer));

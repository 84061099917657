import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Text,
  Button,
  Input,
  Form
} from '../../'
import { Link } from '../Link'

export class ReportFoundPetStep1Card extends React.PureComponent {
  static propTypes = {
    microchipNumberInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    skipLinkProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    searchButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    submitButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      microchipNumberInputProps,
      skipLinkProps,
      searchButtonProps,
      submitButtonProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn('ui-report-found-pet-step1-card', className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>I have found a pet</PaperHeadline>
        <PaperContent>
          <Text
            type={Text.Types.title}
            className='ui-report-found-pet-step1__title'
          >
            We’re happy to hear that, please confirm the information below:
          </Text>

          <Form>
            <div className='ui-report-found-pet-step1__form-row'>
              <Input label='Pet Microchip or ID Number' {...microchipNumberInputProps} />
              <Button
                color={Button.Colors.tertiary}
                {...searchButtonProps}
                className='ui-report-found-pet-step1-search'
              >
                Search
              </Button>
            </div>   
            <Text type={Text.Types.caption}>
              Do not include any spaces or special characters when entering the
              Pet Microchip or ID Number.
            </Text>

            <div className='ui-report-found-pet-step1__form-action'>
              <Button
                variant={Button.Variants.outlined}
                color={Button.Colors.tertiary}
                {...submitButtonProps}
              >
                Go Back
              </Button>
              <Link {...skipLinkProps}>Skip this step</Link>
            </div>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

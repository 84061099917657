import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text } from '../../'

export class SocialButton extends React.PureComponent {
  static Variants = Object.freeze({
    google: 'google',
    facebook: 'facebook'
  })

  static propTypes = {
    variant: PropTypes.oneOf(Object.values(SocialButton.Variants)),
    children: PropTypes.any,
    onClick: PropTypes.func,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      onClick,
      id,
      variant,
      className,
      style,
      refProp,
      children
    } = this.props

    const classes = getClasses(variant)

    return (
      <button
        id={id}
        className={cn('ui-social-button', classes, className)}
        style={style}
        ref={refProp}
        onClick={onClick}
      >
        {variant === SocialButton.Variants.facebook ? (
          <FacebookIcon />
        ) : (
          <GoogleIcon />
        )}
        <Text type={Text.Types.button}>{children}</Text>
      </button>
    )
  }
}

const getClasses = (variant) =>
  variant === SocialButton.Variants.facebook
    ? 'ui-social-button--facebook'
    : 'ui-social-button--google'

const GoogleIcon = () => (
  <svg
    width='35'
    height='34'
    viewBox='0 0 35 34'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.5008 12.4817C18.8841 12.4817 19.8172 13.0793 20.3493 13.5786L22.4283 11.5486C21.1514 10.3618 19.4898 9.6333 17.5008 9.6333C14.6196 9.6333 12.1313 11.2867 10.9199 13.6932L13.3018 15.543C13.8993 13.7668 15.5527 12.4817 17.5008 12.4817Z'
      fill='#EA4335'
    />
    <path
      d='M24.572 17.1637C24.572 16.558 24.5229 16.116 24.4165 15.6576H17.5V18.3914H21.5599C21.478 19.0708 21.036 20.094 20.0538 20.7815L22.3784 22.5823C23.7699 21.2972 24.572 19.4064 24.572 17.1637Z'
      fill='#4285F4'
    />
    <path
      d='M13.3096 18.4569C13.1541 17.9985 13.0641 17.5074 13.0641 16.9999C13.0641 16.4924 13.1541 16.0013 13.3015 15.543L10.9196 13.6931C10.4203 14.6917 10.1338 15.8131 10.1338 16.9999C10.1338 18.1868 10.4203 19.3082 10.9196 20.3067L13.3096 18.4569Z'
      fill='#FBBC05'
    />
    <path
      d='M17.5004 24.3666C19.4894 24.3666 21.1592 23.7118 22.3788 22.5822L20.0542 20.7815C19.4321 21.2153 18.5973 21.5182 17.5004 21.5182C15.5524 21.5182 13.899 20.2331 13.3096 18.4569L10.9277 20.3068C12.1391 22.7132 14.6193 24.3666 17.5004 24.3666Z'
      fill='#34A853'
    />
  </svg>
)

const FacebookIcon = () => (
  <svg
    width='34'
    height='34'
    viewBox='0 0 34 34'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.7718 26.9956V17.7467H21.3249L21.6632 14.5595H18.7718L18.7761 12.9643C18.7761 12.133 18.8551 11.6876 20.0491 11.6876H21.6452V8.5H19.0917C16.0246 8.5 14.945 10.0461 14.945 12.6463V14.5599H13.0332V17.7471H14.945V26.9956H18.7718Z'
      fill='white'
    />
  </svg>
)

import React from "react";
import {
  PetTagsCard,
  Banner,
  ByteTagsCard,
} from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { AppUtils } from "../../../utils/app-utils";
import { connect } from "react-redux";
import {
  changeCartTotal,
  addToShoppingCartItems,
  changeSelectedPetTagOption,
  changeSelectedByteTagRoundOption,
  changeSelectedByteTagSlideOption,
  removeFromShoppingCartItems,
  syncExistingOrder,
  setPromoCodeApplied,
  setExistingOrdersLoaded,
  changeSeletectedTagShape,
  changeSeletectedTagStyle,
  changeSeletectedTagSize,
} from "../../../redux/reducers/membership-purchase-reducer";
import { MembershipPurchaseUtils } from "./membership-purchase.util";
import { ApiConstants } from "../../../utils/api-constants";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
class TagPurchaseComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedPetId: undefined,
      showDropdown: true,
      roundTagColor: undefined,
      isSlideTagAvailable: undefined,
      isRoundTagAvailable: undefined,
      slideTagsPrice: undefined,
      roundTagsPrice: undefined,
      ...MembershipPurchaseUtils.getDefaultData(),
    };
    this.cRef = React.createRef();
  }

  isCatOrDog() {
    let speciesId = this.state.speciesId?.toString();
    if (speciesId !== '1' && speciesId !== '2') {
      speciesId = '1';
    }

    return speciesId;
  }
  loadExistingOrders() {
    if (this.state.byteTagList) {
      let showDropdown = true;
      let roundTagColor;
      let speciesId = this.isCatOrDog();
      let isSlideTagAvailable = false;
      let isRoundTagAvailable = false;
      let slideTagsPrice, roundTagsPrice;

      this.state.byteTagList.forEach((item) => {
        let productName = item?.itemName?.toLowerCase();
        if (productName.indexOf("slide") !== -1) {
          isSlideTagAvailable = true;
          slideTagsPrice = AppUtils.getConvertedPrice(item?.price);
        }
        if (productName.indexOf("round") !== -1) {
          isRoundTagAvailable = true;
          roundTagsPrice = AppUtils.getConvertedPrice(item?.price);
        }
        if (
          AppUtils.isEqualString(
            item.speciesId,
            speciesId
          )
        ) {
          const color = item.color.toLowerCase();
          if (color === "black" || color === "white") {
            showDropdown = !showDropdown;
            roundTagColor = item.color;
          }
        }
      });

      // Show the color return by API.
      if (!showDropdown) {
        this.syncPetStyle(roundTagColor);
      } else {
        roundTagColor = "Black";
      }
      this.setState({
        showDropdown,
        roundTagColor,
        isSlideTagAvailable,
        isRoundTagAvailable,
        slideTagsPrice,
        roundTagsPrice
      });
    }
    if (this.props.existingOrdersLoaded) {
      return;
    }
    this.props.setPromoCodeApplied("");
    axios
      .processGet(
        `${
          ApiConstants.URL_LOAD_EXISTING_ORDERS
        }${AppUtils.getBusRelAccount()}`,
        this.cRef
      )
      .then((response) => {
        this.props.setExistingOrdersLoaded(true);
        response.forEach((order) => {
          if (!AppUtils.isEmptyString(order.promoCode)) {
            this.props.setPromoCodeApplied(order.promoCode);
          }
        });
        const orders =
          MembershipPurchaseUtils.getFilteredOrdersForLifetimeProduct(
            response
          );
        orders.forEach((order) => {
          this.props.syncExistingOrder({
            selectedPetId: order.animalId,
            item: {
              ...order,
              standardTagList: this.state.standardTagList,
              lifetimeTagList: this.state.lifetimeTagList,
              byteTagList: this.state.byteTagList,
              productName: AppUtils.getProductNameFromItemId(order.itemId, order.itemName),
              productCost: MembershipPurchaseUtils.getPriceForExistingOrder(order),
            },
          });
        });
        orders.forEach((order) => {
          if (!this.props.winbackOfferAvailability[order.animalId]) {
            const indexes = MembershipPurchaseUtils.getPetIndexAndItemType(
              this.props.petShoppingCart,
              order.animalId,
              "WINBACK Product"
            );
            if (indexes) {
              this.props.removeFromShoppingCartItems(indexes);
              return;
            }
          }
          this.props.addToShoppingCartItems({
            selectedPetId: order.animalId,
            item: {
              ...order,
              productName: AppUtils.getProductNameFromItemId(order.itemId, order.itemName),
              productCost: MembershipPurchaseUtils.getPriceForExistingOrder(order),
            },
            isExistingItem: true,
          });
        });
      });
  }
  componentDidMount() {
    this.setState(
      {
        selectedPetId: this.props.selectedPetId,
      },
      this.mapParentPropsToState
    );
  }
  shouldDisablePurchasing() {
    return AppUtils.isEmptyString(
      this.props.animalFullDetails[this.props.selectedPetId]?.microchipNumber
    );
  }
  mapParentPropsToState() {
    const membershipProducts = this.props.membershipProducts;
    const speciesId = this.props.speciesId;
    let annualMembership,
      lpmMembership,
      lpmPlusMembership,
      petMedAlertProduct,
      vetHelplineProduct,
      winBackBundle;
    annualMembership = membershipProducts.annualMembership;
    if (annualMembership) {
      petMedAlertProduct = annualMembership.additionalProductList.find((item) =>
        AppUtils.isEqualString(item.itemName, "24PetMedAlert")
      );
      vetHelplineProduct = annualMembership?.additionalProductList.find(
        (item) => AppUtils.isEqualString(item.itemName, "WD Annual Membership")
      );
    }
    lpmMembership = membershipProducts?.lpmMembership;
    if (lpmMembership) {
      lpmMembership.bundleList = MembershipPurchaseUtils.getFilteredBundleList(
        lpmMembership.bundleList
      );
    }

    lpmPlusMembership = membershipProducts?.lpmPlusMembership;
    if (lpmPlusMembership) {
      lpmPlusMembership.bundleList =
        MembershipPurchaseUtils.getFilteredBundleList(
          lpmPlusMembership.bundleList
        );
    }

    winBackBundle = membershipProducts?.winBackBundle;
    if (winBackBundle) {
      winBackBundle.bundleList = MembershipPurchaseUtils.getFilteredBundleList(
        winBackBundle.bundleList
      );
    }
    this.setState(
      {
        annualMembership,
        petMedAlertProduct,
        vetHelplineProduct,
        lpmMembership,
        lpmPlusMembership,
        winBackBundle,
        standardTagList: membershipProducts.tags.standardTagList,
        lifetimeTagList: membershipProducts.tags.lifetimeTagList,
        byteTagList: membershipProducts.tags.byteTag,
        speciesId,
      },
      this.loadExistingOrders
    );
  }
  syncPetStyle(tagStyle) {
    this.props.changeSeletectedTagStyle({
      petId: this.props.selectedPetId,
      tagStyle,
    });
  }
  syncPetSize(size) {
    this.props.changeSeletectedTagSize({
      petId: this.props.selectedPetId,
      size,
    });
  }

  syncByteTagSlideOption(option){
    this.props.changeSelectedByteTagSlideOption({
      selectedByteTagSlideOptions: option,
      petId: this.props.selectedPetId,
    });
  }
  syncByteTagRoundOption(option){
    this.props.changeSelectedByteTagRoundOption({
      selectedByteTagRoundOptions: option,
      petId: this.props.selectedPetId,
    });
  }
  changeByteTagOption(option) {
    let style, size;

    if(this.props.selectedByteTagSlideOptions[this.props.selectedPetId] === option) {
      this.removePreviousTagsFromCart(option);
      this.syncByteTagSlideOption("none");
      return;
    }

    if(this.props.selectedByteTagRoundOptions[this.props.selectedPetId] === option) {
      this.removePreviousTagsFromCart(option);
      this.syncByteTagRoundOption("none");
      return;
    }

    if (option === "slide") {
      this.syncByteTagSlideOption(option);
    }
    if (option === "round") {
      this.syncByteTagRoundOption(option)
    }
    this.addTagToCart(option, style, size);
  }
  addTagToCart(option, style, size) {
    if (!style) {
      style = this.getSelectedStyle();
    }
    if (!size) {
      size = this.getSelectedSize();
    }
    this.removePreviousTagsFromCart(option);
    let speciesId = this.isCatOrDog();
    let item;
    if (option === "round") {
      // To Uniquely identify the round tag - color, speciesId needed.
      const tag = this.state.byteTagList.find((iTag) => {
        return (
          AppUtils.isEqualString(iTag.color, style) &&
          AppUtils.isEqualString(iTag.speciesId, speciesId)
        );
      });
      if (tag) {
        item = {
          itemId: tag.itemId,
          itemType: tag.itemType,
          productName: AppUtils.getProductNameFromItemId(tag.itemId, tag.itemName),
          productCost: AppUtils.getConvertedPrice(tag.price),
        };
      }
    }
    if (option === "slide") {
      const tag = this.state.byteTagList.find((iTag) => {
        return AppUtils.isEqualString(iTag.size, size);
      });
      if (tag) {
        item = {
          itemId: tag.itemId,
          itemType: tag.itemType,
          productName: AppUtils.getProductNameFromItemId(tag.itemId, tag.itemName),
          productCost: AppUtils.getConvertedPrice(tag.price),
        };
      }
    }
    if (item) {
      DataLayerUtils.addToCartEvent(item);
      this.props.addToShoppingCartItems({
        selectedPetId: this.props.selectedPetId,
        item,
      });
    }
  }
  changeSize(e) {
    const tagType = this.props.selectedByteTagSlideOptions[this.props.selectedPetId];
    this.syncPetSize(e.target.value);
    if(tagType){
      this.addTagToCart(tagType, null, e.target.value);
    }
  }
  changeColor(e) {
    const tagType = this.props.selectedByteTagRoundOptions[this.props.selectedPetId];
    this.syncPetStyle(e.target.value);
    if(tagType){
      this.addTagToCart(tagType, e.target.value, null);
    }
  }

  removePreviousTagsFromCart(option) {
    const indexes = MembershipPurchaseUtils.getPetIndexAndItemType(
      this.props.petShoppingCart,
      this.props.selectedPetId,
      "TagProduct",
      option,
    );
    if (indexes) {
      this.props.removeFromShoppingCartItems(indexes);
    }
  }
  // getDisabledTags() {
  //   if (this.shouldDisablePurchasing()) {
  //     return [
  //       PetTagsCard.PetTagOptions.lifetime,
  //       PetTagsCard.PetTagOptions.standard,
  //       PetTagsCard.PetTagOptions.none,
  //     ];
  //   }
  //   return [];
  // }
  getSelectedStyle() {
    const style = this.props.selectTagStylesMap[this.props.selectedPetId];
    return style ? style : this.state.roundTagColor;
  }
  getSelectedSize() {
    const size = this.props.selectTagSizeMap[this.props.selectedPetId];
    return size ? size : "Large";
  }
  render() {
    return (
      <React.Fragment>
        <div ref={this.cRef}>
          <ByteTagsCard
            // disablePetTagOptions={this.getDisabledTags()}
            showByteTagSlideOption={this.state.isSlideTagAvailable}
            showByteTagRoundOption={this.state.isRoundTagAvailable}
            byteTagRoundOption={
              this.props.selectedByteTagRoundOptions[this.props.selectedPetId] ||
              "none"
            }
            byteTagSlideOption={
              this.props.selectedByteTagSlideOptions[this.props.selectedPetId] ||
              "none"
            }
            tagStyle={
              this.props.selectTagStylesMap[this.props.selectedPetId] || "Black"
            }
            tagSize={
              this.props.selectTagSizeMap[this.props.selectedPetId] || "Large"
            }
            tagType={this.isCatOrDog()}
            onByteTagRoundOptionChange={(newOption) => {
              this.changeByteTagOption(newOption);
            }}
            onByteTagSlideOptionChange={(newOption) => {
              this.changeByteTagOption(newOption);
            }}
            showSizeSelect={true}
            showColorSelect={
              this.state.showDropdown
            }
            sizeSelectProps={{
              value: this.props.selectTagSizeMap[this.props.selectedPetId],
              onChange: (e) => {
                this.changeSize(e);
              },
              options: [
                { value: "Large", label: "Large" },
                { value: "Small/Medium", label: "Small" },
              ],
            }}
            colorSelectProps={{
              value: this.props.selectTagStylesMap[this.props.selectedPetId],
              onChange: (e) => {
                this.changeColor(e);
              },
              options: [
                { value: "Black", label: "Black" },
                { value: "White", label: "White" },
              ],
            }}
            slideTagsPrice={this.state.slideTagsPrice}
            roundTagsPrice={this.state.roundTagsPrice}
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  const membershipPurchaseStore = state.membershipPurchaseStore;
  return {
    selectedPlans: membershipPurchaseStore.selectedPlans,
    petShoppingCart: membershipPurchaseStore.petShoppingCart,
    selectedByteTagRoundOptions: membershipPurchaseStore.selectedByteTagRoundOptions,
    selectedByteTagSlideOptions: membershipPurchaseStore.selectedByteTagSlideOptions,
    existingOrdersLoaded: membershipPurchaseStore.existingOrdersLoaded,
    selectTagShapesMap: membershipPurchaseStore.selectTagShapesMap,
    selectTagStylesMap: membershipPurchaseStore.selectTagStylesMap,
    selectTagSizeMap: membershipPurchaseStore.selectTagSizeMap,
    winbackOfferAvailability: membershipPurchaseStore.winbackOfferAvailability,
    disableLpmTags: membershipPurchaseStore.disableLpmTags,
    selectedAnimal: customerProfileStore.selectedAnimal,
    selectedPetId: customerProfileStore.selectedPetId,
    animalFullDetails: customerProfileStore.animalFullDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeCartTotal: (price) => dispatch(changeCartTotal(price)),
    setExistingOrdersLoaded: (flag) => dispatch(setExistingOrdersLoaded(flag)),
    changeSelectedPetTagOption: (option) =>
      dispatch(changeSelectedPetTagOption(option)),
    changeSelectedByteTagRoundOption: (option) =>
      dispatch(changeSelectedByteTagRoundOption(option)),
    changeSelectedByteTagSlideOption: (option) =>
      dispatch(changeSelectedByteTagSlideOption(option)),
    addToShoppingCartItems: (item) => dispatch(addToShoppingCartItems(item)),
    syncExistingOrder: (item) => dispatch(syncExistingOrder(item)),
    removeFromShoppingCartItems: (data) =>
      dispatch(removeFromShoppingCartItems(data)),
    setPromoCodeApplied: (code) => dispatch(setPromoCodeApplied(code)),
    changeSeletectedTagShape: (data) =>
      dispatch(changeSeletectedTagShape(data)),
    changeSeletectedTagStyle: (data) =>
      dispatch(changeSeletectedTagStyle(data)),
    changeSeletectedTagSize: (data) => dispatch(changeSeletectedTagSize(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagPurchaseComponent);

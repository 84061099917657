import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  RemovePetModal,
  PetHasInsuranceModal,
} from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { AppUtils } from "../../../utils/app-utils";
import { ApiConstants } from "../../../utils/api-constants";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { AppConstants } from "../../../utils/app-constants";
import { withRouter } from "react-router-dom";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";

class RemovePetWrapperComponent extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        isRemovePetModalOpen: this.props.isRemovePetModalOpen,
        isRemovePet: false,
        isSubmit: false,
        dateOfDeath: undefined,
        removePetOption:"",
        removeOptionsArray: FeatureFlagUtils.getRemovePetOptions(),
        isDatePickerHidden: true,
        removePetError: "",
        dodError:false,
        isRemovePetHasInsuranceDialogOpen : false,
      };
      this.cRef = React.createRef();
    }

    defaultRemovePetProps= () =>{
      this.setState({
        isRemovePet: false,
        isSubmit: false,
        removeOptionsArray: FeatureFlagUtils.getRemovePetOptions(),
        removePetOption:"",
        dodError:false,
        removePetError:"",
        dateOfDeath: undefined,
        isDatePickerHidden: true,
      })
      this.props.removePetModalHandler(false, false);
    }

    removePet = () => {
      this.setState({
        removePetError: "",
        dodError:false,
      });
      if(AppUtils.isEmptyString(this.state.removePetOption)){
        this.setState({
          removePetError: "Please select atleast one option.",
        });
        return;
      }
      DataLayerUtils.removePetEvent(
        AppUtils.getUserRelationId(),
        this.props.microchipNumber,
        "remove_pet",
        this.state.removePetOption.toLowerCase(),
      )
      const payload = {
        busRelAccount: AppUtils.getBusRelAccount(),
        animalId: this.props.selectedPetId,
        MicrochipNumber: this.props.animalFullDetails[this.props.selectedPetId]?.microchipNumber,
        PetRemoveReason: AppConstants.REMOVEPETOPTIONS[this.state.removePetOption]
      }
  
      if(this.state.removePetOption === AppConstants.REMOVEOPTIONSENUM.DECEASED){
        if(!this.state.dateOfDeath){
          this.setState({
            dodError:true,
          })
          return;
        }
        payload["PetDateDeath"] = this.state.dateOfDeath.toISOString();
      }
      axios
      .processPost(`${ApiConstants.URL_REMOVE_PET}`,
        this.cRef,
        payload
      )
      .then((response) => {
        this.setState(
          {
            isSubmit: true,
          },
        );
        DataLayerUtils.removePetEvent(
          AppUtils.getUserRelationId(),
          this.props.microchipNumber,
          null,
          this.state.removePetOption.toLowerCase(),
          true,
        )
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            removePetError: errors[0],
          });
        }
      });
    }

    optionSelectHandler(index, label){
      let removeOptionsArray = FeatureFlagUtils.getRemovePetOptions();
      let isDatePickerHidden = (label === "Deceased")? false: true;
      let removePetOption = label;
      let dateOfDeath = undefined;
  
      removeOptionsArray[index]["active"] = true;
      this.setState({
        removeOptionsArray,
        isDatePickerHidden,
        removePetOption,
        dateOfDeath,
      })
    }

    componentWillReceiveProps(props) {
        this.setState({
            isRemovePetModalOpen: props.isRemovePetModalOpen,
            isRemovePetHasInsuranceDialogOpen : props.isRemovePetHasInsuranceDialogOpen
        })
    }

    render() {
      return (
        <React.Fragment>
            <RemovePetModal
              open={this.state.isRemovePetModalOpen}
              onClose={() => {
                this.defaultRemovePetProps();
              }}
              errorMessage={this.state.removePetError}
              isRemovePet={this.state.isRemovePet}
              isSubmit={this.state.isSubmit}
              datePickerProps={{
                id:"datePickerId",
                selected: this.state.dateOfDeath,
                placeholderText:"mm/dd/yyyy",
                error: this.state.dodError,
                hint: (this.state.dodError)? "Please enter the date": "",
                onChange: (date) => {
                  this.setState({
                    dateOfDeath: date,
                  })
                },
                hidden:this.state.isDatePickerHidden,
              }}
              removeOptionsArray = {this.state.removeOptionsArray}
              optionClickedProp = { (index, label) => {
                  this.optionSelectHandler(index, label);
              }}
              yesButtonProps={{
                id: "removePetConfirmId",
                onClick: () => {
                  DataLayerUtils.removePetEvent(
                    AppUtils.getUserRelationId(),
                    this.props.microchipNumber,
                    "confirm_to_remove",
                  )
                  this.setState({
                    isRemovePet: true
                  })
                },
              }}
              cancelButtonProps={{
                id: "removePetCancelId",
                onClick: () => {
                  this.defaultRemovePetProps();
                }
              }}
              goBackLinkProps={{
                href:"#",
                onClick: (e) => {
                  this.defaultRemovePetProps();
                  window.location.reload();
                  e.preventDefault();
                }
              }}
              submitButtonProps={{
                id: "removePetSubmitId",
                onClick: (e) => {
                  this.removePet();
                }
              }}
            />
            <PetHasInsuranceModal
              open={this.state.isRemovePetHasInsuranceDialogOpen}
              onClose={() => {}}
              cancelButtonProps={{
                onClick: () => {
                    this.props.removePetModalHandler(false, false);
                }
              }}
              callInMessage={"If your pet is insured with us, please call us " + FeatureFlagUtils.getPhoneNumber() + " to have your pet removed from your account."}
            />
        </React.Fragment>
      );
    }
}

const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    selectedPetId: customerProfileStore.selectedPetId,
    selectedAnimal: customerProfileStore.selectedAnimal,
    animalFullDetails: customerProfileStore.animalFullDetails,
  };
};

export default connect(mapStateToProps)(withRouter(RemovePetWrapperComponent));



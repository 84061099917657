export class ValidationMessages {
  static EMAIL_VALIDATION_MESSAGE = "Please enter valid email";
  static INVALID_PHONE_NUMBER = "Please enter a valid phone number";
  static EMAIL_DOESNT_MATCH = "Email doesn't match";
  static PASSWORD_DOESNT_MATCH = "Password doesn't match";
  static PASSWORD_EMPTY = "Password should not be empty";
  static EMPTY_VALUE = "This field should not be empty";
  static EMPTY_MICROCHIP = "Microchip number is required.";
  static PASSWORD_VALIDATION_MESSAGE =
    "Passwords must contain 8 characters and contain at least one number and one upper case letter and one lower case letter. Passwords are case sensitive.";
  static IS_REQUIRED = " is required";
  static INVALID_CARD_NUMBER = "Invalid card number";
  static INVALID_CARD_EXPIRY = "Invalid card expiry";
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
    Modal,
    Paper,
    PaperHeadline,
    PaperContent,
    Button,
    Text
} from '../..'

export class GeneralInfoModal extends React.PureComponent {
    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        callInMessage: PropTypes.string,
        cancelButtonProps: PropTypes.shape({
            id: PropTypes.string,
            size: PropTypes.string,
            onClick: PropTypes.func
        }),
        buttonText: PropTypes.string,
        modalHeadline: PropTypes.string,
        textId: PropTypes.string,
    }
    
    static defaultProps = {
        buttonText: "Ok",
        modalHeadline: "General Modal"
    }

    render() {
        const {
            open,
            onClose,
            callInMessage,
            cancelButtonProps,
            buttonText,
            modalHeadline,
            textId
        } = this.props


        return (
            <Modal open={open} onClose={onClose}>
                <div className='ui-general-info-modal'>
                    <Paper type={Paper.Types.framed}>
                        <PaperHeadline>{modalHeadline}</PaperHeadline>
                        <PaperContent id={textId}>
                            <Text 
                                className='ui-general-info-modal__text'
                                type={Text.Types.body}
                            >{callInMessage}</Text>
                            <div className='ui-general-info-modal__actions'>
                                <Button
                                    type='button'
                                    variant={Button.Variants.outlined}
                                    color={Button.Colors.tertiary}
                                    {...cancelButtonProps}
                                >
                                    {buttonText}
                                </Button>
                            </div>
                        </PaperContent>
                    </Paper>
                </div>
            </Modal>

            )
    }
}
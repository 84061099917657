import React from 'react'
import PropTypes from 'prop-types'
import { DatePickerInput } from '../DatePickerInput'

import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Form,
  Link,
  Option,
  Text,
  Icon
} from '../../'

export class RemovePetModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    phoneNumber: PropTypes.string,
    onFormSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
    messageTextBody: PropTypes.string,
    showMicrochipCompanySelect: PropTypes.bool,
    isRemovePet:PropTypes.bool,
    cancelButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    removeOptionsArray: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        onClick: PropTypes.func,
        active: PropTypes.bool,
        label: PropTypes.string,
      })
    ),
    optionClickedProp: PropTypes.func,
    isSubmit:PropTypes.bool,
    submitButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    datePickerProps: PropTypes.shape({
      selected: PropTypes.string,
      hidden: PropTypes.bool,
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    yesButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    goBackLinkProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
  }

  static defaultProps = {
    isRemovePet: true,
    isSubmit:false,
    hideDatePicker: false,
    messageTextBody: "Are you sure you want to remove this pet? Removing the pet unregisters them from your account. You will no longer receive services or notifications for this pet."
  }

  render() {
    const {
      open,
      onClose,
      errorMessage,
      isRemovePet,
      cancelButtonProps,
      yesButtonProps,
      isSubmit,
      messageTextBody,
      removeOptionsArray,
      optionClickedProp,
      submitButtonProps,
      goBackLinkProps,
      datePickerProps,
      onFormSubmit
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-remove-pet-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline>Remove pet</PaperHeadline>
            <PaperContent>
            {errorMessage && (
                <Text
                  className='ui-remove-pet-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}
              { (!isRemovePet) ? (
                <Text
                  className='ui-remove-pet-modal__text'
                  type={Text.Types.headlineBold}
                >
                  {messageTextBody}
                  <div className='ui-remove-pet-modal__actions'>
                  <Button
                    type='button'
                    variant={Button.Variants.outlined}
                    color={Button.Colors.tertiary}
                    {...cancelButtonProps}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    color={Button.Colors.tertiary}
                    {...yesButtonProps}
                  >
                    Yes
                  </Button>
                </div>
                </Text>) :
                ( !isSubmit && <Text
                  className='ui-remove-pet-modal__text'
                  type={Text.Types.headlineBold}
                >
                  Why Are You Removing This Pet?
                  <Text
                    type={Text.Types.body}
                  >
                    Please provide us with context as to why you'd like to remove this pet from your account.
                  </Text>
                  <Form onSubmit={onFormSubmit}>
                    {removeOptionsArray.map((option, index) => {
                        return (
                          <div
                            className= 'ui-remove-pet-modal__options'
                            key= {`removeOption#${index}`}
                          >
                            <Option
                              showCheckMark
                              label= {option.label}
                              id={`removePetOption${index}`}
                              active= {option.active}
                              onClick= { () => optionClickedProp(index, option.label) }
                            ></Option>
                            {option.label === "Deceased" && (
                              <div className='ui-remove-pet-modal__date-picker'>
                                <DatePickerInput
                                  label="Date of Death*"
                                  rightIcon={<Icon type='calendar_today' />}
                                  {...datePickerProps}
                                ></DatePickerInput>
                              </div>
                            )}
                          </div>
                      )})}
                    <div className='ui-remove-pet-modal__actions'>
                      <Button
                        type='button'
                        variant={Button.Variants.outlined}
                        color={Button.Colors.tertiary}
                        {...cancelButtonProps}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='button'
                        color={Button.Colors.tertiary}
                        {...submitButtonProps}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </Text>
              )}

              { isSubmit &&
                <Text
                  className='ui-remove-pet-modal__text'
                  type={Text.Types.headlineBold}
                >
                  This pet has been removed
                  <div className='ui-remove-pet-modal__actions'>
                    <Link
                      color={Link.Colors.tertiary}
                      {...goBackLinkProps}
                    >
                      Go Back
                    </Link>
                  </div>
                </Text>
                
              }
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

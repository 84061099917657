import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Form,
  FormAction,
  Button,
  Text
} from '../../'
import { PasswordInput } from '../PasswordInput'

export class ResetPasswordCard extends React.PureComponent {
  static propTypes = {
    errorMessage: PropTypes.string,
    onFormSubmit: PropTypes.func,
    headline: PropTypes.string,
    buttonText: PropTypes.string,
    passwordInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    confirmPasswordInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    continueButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }
  static defaultProps = {
    headline: "Reset your password",
    buttonText: "Continue",
  }
  render() {
    const {
      errorMessage,
      onFormSubmit,
      passwordInputFieldProps,
      confirmPasswordInputFieldProps,
      continueButtonProps,
      id,
      className,
      style,
      headline,
      buttonText,
      refProp
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-reset-password-card', className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>{headline}</PaperHeadline>
        <PaperContent>
          <Form onSubmit={onFormSubmit}>
            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-reset-password-card__error-message'
              >
                {errorMessage}
              </Text>
            )}

            <PasswordInput
              autoComplete='new-password'
              placeholder='Password'
              {...passwordInputFieldProps}
            />

            <PasswordInput
              placeholder='Re-enter password'
              {...confirmPasswordInputFieldProps}
            />

            <FormAction>
              <Button
                type='submit'
                color={Button.Colors.tertiary}
                {...continueButtonProps}
              >
                {buttonText}
              </Button>
            </FormAction>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  CartFloatButton,
  DashboardShoppingCart,
  OtherPetsNotificationModal,
} from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { AppUtils } from "../../../utils/app-utils";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { ApiConstants } from "../../../utils/api-constants";
import {
  addToShoppingCartItems,
  removeFromShoppingCartItems,
  saveCartData,
} from "../../../redux/reducers/membership-purchase-reducer";
import { MembershipPurchaseUtils } from "../membership/membership-purchase.util";
import { AppConstants } from "../../../utils/app-constants";
class PetShoppingCart extends React.Component {
  constructor() {
    super();
    this.state = {
      isUsCountry: AppUtils.getCountryCode().toLowerCase() === "us",
      isAnotherPetNotificationModalOpen: false,
      errorMessage: "",
    };
    this.cRef = React.createRef();
  }
  componentDidMount() {}
  isMissingSomePet() {
    return (
      this.props.customerAnimals.length !==
      Object.keys(this.props.petShoppingCart).length
    );
  }
  getAutoRenew(checkbox) {
    return checkbox ? 1 : 0;
  }
  proceedToCheckout() {
    this.setState({
      errorMessage: "",
    });
    const orders = [];
    const currDataLayer={
      ownerid: AppUtils.getUserRelationId(),
      country: AppUtils.getCountryCode(),
      zipcode: this.props.zipCode,
      pets:[]
    };
    
    for (let key in this.props.petShoppingCart) {
      const petCartItems = this.props.petShoppingCart[key].items;
      const petInfoWithProducts = {
        petid:key,
        microchipid:this.props.customerAnimalsFullDetails[key].microchipNumber,
      }
      const purchaseProduct ={};
      for (let item of petCartItems) {
        purchaseProduct[item.productName] = item.productCost.slice(1);
        orders.push({
          animalId: key,
          animalName: MembershipPurchaseUtils.getAnimalName(
            key,
            this.props.customerAnimals
          ),
          itemId: item.itemId,
          itemType: item.itemType,
          quantity: 1,
          autoRenew: AppUtils.isEqualString(item.itemType, "AnnualProduct")
            ? this.getAutoRenew(this.props.consentCheckboxes[key])
            : this.getAutoRenew(this.props.autoRenewCheckboxes[key]),
          unitPrice: AppUtils.getExtractedPrice(item.productCost),
        });
        if (item.bundleList) {
          item.bundleList.forEach((bundle) => {
            orders.push({
              animalId: key,
              animalName: MembershipPurchaseUtils.getAnimalName(
                key,
                this.props.customerAnimals
              ),
              itemId: bundle.itemId,
              itemType: bundle.itemType,
              quantity: 1,
              autoRenew: this.props.autoRenewCheckboxes[key] ? 1 : 0,
              unitPrice: AppUtils.getExtractedPrice(bundle.itemPrice),
            });
          });
        }
      }
      petInfoWithProducts["products"]= purchaseProduct;
      currDataLayer.pets.push(petInfoWithProducts);
    }
    
    if(window.dataLayer){
      window.dataLayer.push(currDataLayer);
    }
    axios
      .processPost(
        `${ApiConstants.URL_PLACE_NEW_ORDER}${AppUtils.getBusRelAccount()}`,
        this.cRef,
        {
          currencyCode: AppUtils.getCurrency(),
          countryCode: AppUtils.getCountryCode(),
          orders,
          promoDiscount: AppUtils.isEmptyString(this.props.promoCode)
            ? null
            : {
                code: this.props.promoCode,
                currencyCode: AppUtils.getCurrency(),
                countryCode: AppUtils.getCountryCode(),
              },
        }
      )
      .then((response) => {
        this.props.saveCartData(response);
        this.props.history.push("/pages/cart");
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
        }
      });
  }
  getShoppingCartItems() {
    const items = [];
    for (let key in this.props.petShoppingCart) {
      items.push({
        title:
          "For " +
          MembershipPurchaseUtils.getAnimalName(
            key,
            this.props.customerAnimals
          ),
        items: this.props.petShoppingCart?.[key]?.items,
      });
    }
    return items;
  }

  calculateSubTotal() {
    if (!this.props.petShoppingCart) {
      return 0;
    }
    let subtotal = 0;
    for (let key in this.props.petShoppingCart) {
      for (let item of this.props.petShoppingCart[key].items) {
        subtotal = subtotal + AppUtils.getExtractedPrice(item.productCost);
      }
    }
    return subtotal;
  }
  getSubTotal() {
    const subtotal = this.calculateSubTotal();
    return AppUtils.getConvertedPrice(subtotal);
  }
  render() {
    return (
      <div ref={this.cRef} id="pet-shopping-cart">
        <OtherPetsNotificationModal
          className={(FeatureFlagUtils.hideCardImage())?"hide-card-image":""}
          open={this.state.isAnotherPetNotificationModalOpen}
          onClose={() => {
            this.setState({
              isAnotherPetNotificationModalOpen: false,
            });
          }}
          continueButtonProps={{
            id: "another_pet_continue_button",
            onClick: () => {
              this.setState({
                isAnotherPetNotificationModalOpen: false,
              });
              this.proceedToCheckout();
            },
          }}
          backButtonProps={{
            id: "another_pet_back_button",
            onClick: () => {
              this.setState({
                isAnotherPetNotificationModalOpen: false,
              });
            },
          }}
        />
        <DashboardShoppingCart
          errorMessage={this.state.errorMessage}
          className={"dashboard-shopping-card-heading"}
          cartItems={this.getShoppingCartItems()}
          onRemoveClick={(index1, index2) => {
            this.props.removeFromShoppingCartItems({
              petIndex: index1,
              itemIndex: index2,
              shouldSync: true,
            });
          }}
          subtotal={this.getSubTotal()}
          proceedToPaymentButtonProps={{
            id: 'proceed-to-payment-button',
            disabled: parseFloat(this.calculateSubTotal()) === 0,
            onClick: () => {
              if (this.isMissingSomePet()) {
                this.setState({
                  isAnotherPetNotificationModalOpen: true,
                });
              } else {
                this.proceedToCheckout();
              }
            },
          }}
        />
        <CartFloatButton
          cartTotal={this.getSubTotal()}
          onClick={() => {
            if (this.isMissingSomePet()) {
              this.setState({
                isAnotherPetNotificationModalOpen: true,
              });
            } else {
              this.proceedToCheckout();
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  const membershipPurchaseStore = state.membershipPurchaseStore;
  return {
    autoRenewCheckboxes: membershipPurchaseStore.autoRenewCheckboxes,
    customerAnimals: customerProfileStore.customerAnimals,
    customerAnimalsFullDetails: customerProfileStore.animalFullDetails,
    petShoppingCart: membershipPurchaseStore.petShoppingCart,
    cartData: membershipPurchaseStore.cartData,
    consentCheckboxes: membershipPurchaseStore.consentCheckboxes,
    promoCode: membershipPurchaseStore.promoCode,
    zipCode:customerProfileStore.zipcode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCartData: (data) => dispatch(saveCartData(data)),
    addToShoppingCartItems: (item) => dispatch(addToShoppingCartItems(item)),
    removeFromShoppingCartItems: (data) =>
      dispatch(removeFromShoppingCartItems(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PetShoppingCart));

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Text,
  TextArea,
  Checkbox,
  Button,
  Select,
  Input,
  Form,
  Avatar,
  HeaderDivider
} from '../../'
import { LocaleWithUnknown as importedLocale } from '../../constants'
import { getLabelsByLocale } from '../../utils'

export class ReportFoundPetCard extends React.PureComponent {
  static Locale = importedLocale

  static propTypes = {
    banner: PropTypes.element,
    locale: PropTypes.oneOf(Object.values(ReportFoundPetCard.Locale)),
    paperHeadline: PropTypes.string,
    microchipNumberInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    errorText: PropTypes.any,
    addressInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    cityInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    countrySelectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    stateSelectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    ownerNameInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    phoneNumberInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    emailInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    notesInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    messageText: PropTypes.string,

    checkboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),

    backButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    submitButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    captcha: PropTypes.any,
    isLookUpSkipped: PropTypes.bool,
    isLookUpSuccess:PropTypes.bool,
    avatarElementId: PropTypes.string,
    petAvatar: PropTypes.string,
    isAvatar: PropTypes.bool,
    petName: PropTypes.string,
    ownerName: PropTypes.string,
    microchipNumber: PropTypes.string,
    gender: PropTypes.string,
    breed: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    foundMessageText: PropTypes.any,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    paperHeadline: "I have found a pet",
    locale: ReportFoundPetCard.Locale.unknown,
    isLookUpSkipped: false,
    isLookUpSuccess: true,
    isAvatar: true,
  }

  render() {
    const {
      banner,
      microchipNumberInputProps,
      addressInputProps,
      cityInputProps,
      countrySelectProps,
      stateSelectProps,
      ownerNameInputProps,
      phoneNumberInputProps,
      errorText,
      emailInputProps,
      notesInputProps,
      checkboxProps,
      backButtonProps,
      submitButtonProps,
      captcha,
      isLookUpSkipped,
      isLookUpSuccess,
      avatarElementId,
      petAvatar,
      isAvatar,
      petName,
      ownerName,
      microchipNumber,
      paperHeadline,
      foundMessageText,
      gender,
      messageText,
      breed,
      locale,
      id,
      className,
      style,
      refProp
    } = this.props

    const { stateLabel } = getLabelsByLocale(locale)

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        classNamI have found a pete={cn('ui-report-found-pet-card', className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>{paperHeadline}</PaperHeadline>

        {banner}

        <PaperContent>
          {/* <Text type={Text.Types.title} className='ui-report-found-pet__title'>
             We’re happy to hear that, please confirm the information below: 
          </Text> */}

          <Form>
            {!isLookUpSkipped &&
              (!isLookUpSuccess ? (
                <div>
                  <Input
                    label='Pet Microchip or ID Number'
                    {...microchipNumberInputProps}
                  />
                  <br />
                  <Text
                    type={Text.Types.caption}
                    className='ui-report-found-pet-card__error-text'
                  >
                    {errorText}
                  </Text>
                </div>
              ) : (
                <div>
                  <Text>
                    {foundMessageText}
                  </Text>
                  <div>
                    <div className='ui-report-found-pet-card__microchip-search-header'>
                      <HeaderDivider className='ui-report-found-pet-card_left_content'>
                        Registered to:
                      </HeaderDivider>
                      <HeaderDivider className='ui-report-found-pet-card__owner-name'>
                        {ownerName}
                      </HeaderDivider>
                    </div>

                    <div
                      className={
                        isAvatar
                          ? 'ui-report-found-pet-card__microchip-search-body'
                          : 'ui-report-found-pet-card__microchip-search-body-no-avatar'
                      }
                    >
                      <div className='ui-report-found-pet-card_left_content'>
                        {isAvatar && (
                          <Avatar id={avatarElementId} img={petAvatar} />
                        )}
                      </div>

                      <div className='ui-report-found-pet-card__pet-info'>
                        <Text
                          type={Text.Types.title}
                          className='ui-report-found-pet-card__info-mobile'
                        >
                          {petName}
                        </Text>
                        <Text className='ui-report-found-pet-card__info-mobile'>
                          {microchipNumber}
                        </Text>
                        <div className='ui-report-found-pet-card__details'>
                          <div>
                            <Text className='ui-text--bodyBold_'>Gender</Text>
                            <Text className='ui-text--body_'>{gender}</Text>
                          </div>

                          <div>
                            <Text className='ui-text--bodyBold_'>Breed</Text>
                            <Text className='ui-text--body_'>{breed}</Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <Text type={Text.Types.title}>Found location:</Text>

            <div className='ui-report-found-pet__form-row'>
              <Select label='Country' {...countrySelectProps} />

              <Select label={stateLabel} {...stateSelectProps} />
            </div>

            <div className='ui-report-found-pet__form-row'>
              <Input label='City' {...cityInputProps} />

              <Input label='Intersection' {...addressInputProps} />
            </div>

            <Text type={Text.Types.title}>Finder contact information:</Text>

            <div className='ui-report-found-pet__form-row'>
              <Input label='Name' {...ownerNameInputProps} />

              <Input label='Phone Number' {...phoneNumberInputProps} />
            </div>

            <Input label='Email Address' {...emailInputProps} />

            <Checkbox
              label='I authorize 24Petwatch and affiliates to release my contact information to the pet owner or emergency contact to contact me for lost pet services only.'
              {...checkboxProps}
            />

            <TextArea
              label='Please include any additional detail that will help to reunite the lost pet with its owner.'
              {...notesInputProps}
            />

            {captcha}

            <div className='ui-report-found-pet__form-action'>
              <Button
                color={Button.Colors.tertiary}
                variant={Button.Variants.outlined}
                {...backButtonProps}
              >
                Go back
              </Button>
              <Button color={Button.Colors.tertiary} {...submitButtonProps}>
                Submit found pet
              </Button>
            </div>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

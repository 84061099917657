import React from "react";
import { PurchaseMembershipPlanCard } from "../../../UI-Component-Library";
import { AppUtils } from "../../../utils/app-utils";
import { connect } from "react-redux";
import {
  changeCartTotal,
  addToShoppingCartItems,
  removeFromShoppingCartItems,
  changeSelectedPlan,
  toggleConsentCheckbox,
  setIsVetHelplineSelected,
  setIsPetMedAlertSelected,
} from "../../../redux/reducers/membership-purchase-reducer";
import { MembershipPurchaseUtils } from "./membership-purchase.util";
import { toggleAutoRenewCheckbox } from "../../../redux/reducers/membership-purchase-reducer";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
class MembershipPurchaseComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedPetId: undefined,
      ...MembershipPurchaseUtils.getDefaultData(),
      iconsToHide: [],
      // lpmHideIcons: [PurchaseMembershipPlanCard.Icons.petcademy],
      // lpmPlusHideIcons: [],
      // annualHideIcons: [
      //   PurchaseMembershipPlanCard.Icons.warranty,
      //   PurchaseMembershipPlanCard.Icons.petco,
      //   PurchaseMembershipPlanCard.Icons.rover,
      //   PurchaseMembershipPlanCard.Icons.petcademy,
      // ],
    };
    this.cRef = React.createRef();
  }
  // componentDidMount() {
    // const lpmHideIcons = [];
    // const lpmPlusHideIcons = [];
    // if (AppUtils.isUsCountry()) {
    //   lpmHideIcons.push(PurchaseMembershipPlanCard.Icons.warranty);
    // } else {
    //   lpmHideIcons.push(PurchaseMembershipPlanCard.Icons.petco);
    //   lpmPlusHideIcons.push(PurchaseMembershipPlanCard.Icons.petco);
    // }
    // this.setState(
    //   {
    //     selectedPetId: this.props.selectedPetId,
    //     lpmHideIcons,
    //     lpmPlusHideIcons,
    //     iconsToHide: (AppUtils.isEqualString(this.props.selectedPlans[this.props.selectedPetId], "LPM-PLUS"))
    //       ? lpmPlusHideIcons
    //       : lpmHideIcons,
    //   },
    //   this.mapParentPropsToState
    // );
  // }
   

  // componentDidUpdate(prevProps) {
  //   if (this.props.selectedPlans[this.props.selectedPetId] !== prevProps.selectedPlans[this.props.selectedPetId]) {
  //     const iconsToHide = (AppUtils.isEqualString(this.props.selectedPlans[this.props.selectedPetId], this.state.lpmPlusMembership?.itemId))
  //       ? this.state.lpmPlusHideIcons
  //       : (AppUtils.isEqualString(this.props.selectedPlans[this.props.selectedPetId], this.state.annualMembership?.itemId))
  //         ? this.state.annualHideIcons
  //         : this.state.lpmHideIcons
  //     this.setState(
  //       {
  //         iconsToHide
  //       },
  //     )
  //   }
  // }

  componentDidMount() {
    this.setState(
      {
       selectedPetId: this.props.selectedPetId,
      },
      this.mapParentPropsToState
    );
  }

  shouldDisablePurchasing() {
    return AppUtils.isEmptyString(
      this.props.animalFullDetails[this.props.selectedPetId]?.microchipNumber
    );
  }
  mapParentPropsToState() {
    const membershipProducts = this.props.membershipProducts;
    const annualMembership = membershipProducts.annualMembership;
    const petMedAlertProduct = annualMembership.additionalProductList.find(
      (item) => AppUtils.isEqualString(item.itemName, "24PetMedAlert")
    );
    const vetHelplineProduct = annualMembership.additionalProductList.find(
      (item) => AppUtils.isEqualString(item.itemName, "WD Annual Membership")
    );
    const lpmMembership = membershipProducts.lpmMembership;
    lpmMembership.bundleList = MembershipPurchaseUtils.getFilteredBundleList(
      lpmMembership.bundleList
    );

    const lpmPlusMembership = membershipProducts.lpmPlusMembership;
    lpmPlusMembership.bundleList = MembershipPurchaseUtils.getFilteredBundleList(
      lpmPlusMembership.bundleList
    );
    this.setState({
      annualMembership,
      lpmMembership,
      lpmPlusMembership,
      petMedAlertProduct,
      vetHelplineProduct,
    });
  }
  changeSelectedPlan(plan) {
    let item;
    if (plan === "annual") {
      this.props.changeSelectedPlan({
        petId: this.props.selectedPetId,
        selectedPlan: this.state.annualMembership.itemId,
      });
      // this.setState({
      //   iconsToHide: this.state.annualHideIcons,
      // });
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.lpmMembership.itemId
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.lpmPlusMembership.itemId
      );
      item = {
        itemId: this.state.annualMembership.itemId,
        itemType: this.state.annualMembership.itemType,
        productName: AppUtils.getProductNameFromItemId(
          this.state.annualMembership.itemId
        ),
        productCost: AppUtils.getConvertedPrice(
          this.state.annualMembership.salesPrice
        ),
      };
      DataLayerUtils.addToCartEvent(item);
      this.props.addToShoppingCartItems({
        selectedPetId: this.props.selectedPetId,
        item,
      });
    } else if (plan === "lpm") {
      this.props.changeSelectedPlan({
        petId: this.props.selectedPetId,
        selectedPlan: this.state.lpmMembership.itemId,
      });
      this.changePetMedAlertSelected(false);
      this.changeVetHelplineSelected(false);
      // this.setState({
      //   iconsToHide: this.state.lpmHideIcons,
      // });
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.vetHelplineProduct.itemId
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.petMedAlertProduct.itemId
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.annualMembership.itemId
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.lpmPlusMembership.itemId
      );
      item = {
        itemId: this.state.lpmMembership.itemId,
        itemType: this.state.lpmMembership.itemType,
        productName: AppUtils.getProductNameFromItemId(
          this.state.lpmMembership.itemId
        ),
        productCost: AppUtils.getConvertedPrice(
          this.state.lpmMembership.itemPrice
        ),
        bundleList: this.state.lpmMembership.bundleList,
      };
      DataLayerUtils.addToCartEvent(item);
      this.props.addToShoppingCartItems({
        selectedPetId: this.props.selectedPetId,
        item,
      });
    } else if (plan === "lpmPlus") {
      this.props.changeSelectedPlan({
        petId: this.props.selectedPetId,
        selectedPlan: this.state.lpmPlusMembership.itemId,
      });
      this.changePetMedAlertSelected(false);
      this.changeVetHelplineSelected(false);
      // this.setState({
      //   iconsToHide: this.state.lpmPlusHideIcons,
      // });
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.vetHelplineProduct.itemId
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.petMedAlertProduct.itemId
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.annualMembership.itemId
      );
      this.removeItemFromCart(
        this.props.selectedPetId,
        this.state.lpmMembership.itemId
      );
      item = {
        itemId: this.state.lpmPlusMembership.itemId,
        itemType: this.state.lpmPlusMembership.itemType,
        productName: AppUtils.getProductNameFromItemId(
          this.state.lpmPlusMembership.itemId
        ),
        productCost: AppUtils.getConvertedPrice(
          this.state.lpmPlusMembership.itemPrice
        ),
        bundleList: this.state.lpmPlusMembership.bundleList,
      };
      DataLayerUtils.addToCartEvent(item);
      this.props.addToShoppingCartItems({
        selectedPetId: this.props.selectedPetId,
        item,
      });
    }
  }
  removeItemFromCart(petId, itemId) {
    const indexes = MembershipPurchaseUtils.getPetIndexAndItemIndex(
      this.props.petShoppingCart,
      petId,
      itemId
    );
    if (indexes) {
      this.props.removeFromShoppingCartItems(indexes);
    }
  }

  changePetMedAlertSelected(isSelected) {
    this.props.setIsPetMedAlertSelected({
      petId: this.props.selectedPetId,
      isPetMedAlertSelected: isSelected,
    });
  }
  changeVetHelplineSelected(isSelected) {
    this.props.setIsVetHelplineSelected({
      petId: this.props.selectedPetId,
      isVetHelplineSelected: isSelected,
    });
  }
  toggleAdditionalProductSelection(productName) {
    let item;
    if (AppUtils.isEqualString(productName, "PetMedAlert")) {
      if (!this.props.isPetMedAlertSelectedMap[this.props.selectedPetId]) {
        item = {
          itemId: this.state.petMedAlertProduct.itemId,
          itemType: this.state.petMedAlertProduct.itemType,
          productName: AppUtils.getProductNameFromItemId(
            this.state.petMedAlertProduct.itemId
          ),
          productCost: AppUtils.getConvertedPrice(
            this.state.petMedAlertProduct.price
          ),
        };
        DataLayerUtils.addToCartEvent(item);
        this.props.addToShoppingCartItems({
          selectedPetId: this.props.selectedPetId,
          item,
        });
      } else {
        this.removeItemFromCart(
          this.props.selectedPetId,
          this.state.petMedAlertProduct.itemId
        );
      }
      this.changePetMedAlertSelected(
        !this.props.isPetMedAlertSelectedMap[this.props.selectedPetId]
      );
    } else {
      if (!this.props.isVetHelplineSelectedMap[this.props.selectedPetId]) {
        item = {
          itemId: this.state.vetHelplineProduct.itemId,
          itemType: this.state.vetHelplineProduct.itemType,
          productName: AppUtils.getProductNameFromItemId(
            this.state.vetHelplineProduct.itemId
          ),
          productCost: AppUtils.getConvertedPrice(
            this.state.vetHelplineProduct.price
          ),
        };
        DataLayerUtils.addToCartEvent(item);
        this.props.addToShoppingCartItems({
          selectedPetId: this.props.selectedPetId,
          item,
        });
      } else {
        this.removeItemFromCart(
          this.props.selectedPetId,
          this.state.vetHelplineProduct.itemId
        );
      }
      this.changeVetHelplineSelected(
        !this.props.isVetHelplineSelectedMap[this.props.selectedPetId]
      );
    }
  }
  isLpmPlanSelected() {
    return this.state.lpmMembership
      ? AppUtils.isEqualString(
          this.props.selectedPlans[this.props.selectedPetId],
          this.state.lpmMembership.itemId
        )
      : false;
  }

  isLpmPlusPlanSelected() {
    return this.state.lpmPlusMembership
      ? AppUtils.isEqualString(
        this.props.selectedPlans[this.props.selectedPetId],
        this.state.lpmPlusMembership.itemId
      )
      : false;
  }
  getConsentText() {
    if (this.isLpmPlanSelected()) {
      return (
        <>
          To continue accessing 24PetMedAlert® and 24/7 Vet Helpline beyond your complimentary
          first year, click here to opt-in to auto-renew these services for only $19.95/year.
        </>
      )
    }
    if (this.isLpmPlusPlanSelected()) {
      return (
        <>
          Auto-renew 24PetMedAlert<sup>®</sup> and 24/7 Vet Helpline to keep enjoying these benefits once your complimentary access expires after 1 year.
        </>
      )
    }

    return "To continue accessing 24PetMedAlert® and 24/7 Vet Helpline beyond your complimentary first year, click here to opt-in to auto-renew these services for only $19.95/year."
  }
  shouldHideAutoRenew() {
    return (
      !this.props.isVetHelplineSelectedMap[this.props.selectedPetId] &&
      !this.props.isPetMedAlertSelectedMap[this.props.selectedPetId] &&
      !this.isLpmPlanSelected() &&
      !this.isLpmPlusPlanSelected()
    );
  }
  shouldHideAnnualConsent() {
    if (
      this.state.annualMembership &&
      AppUtils.isEqualString(
        this.props.selectedPlans[this.props.selectedPetId],
        this.state.annualMembership.itemId
      )
    ) {
      return false;
    }
    return true;
  }
  getMembershipHeadingText(){
    if(AppUtils.getCountryCode().toUpperCase() === 'US'){
      return "With our Lifetime and Annual Protection Memberships, our Lost Pet Recovery Specialists are available to help reunite lost pets with their families";
    }

    return "With our Lifetime and Lifetime Plus Protection Memberships, our Lost Pet Recovery Specialists are available to help reunite lost pets with their families";
  }
  render() {
    return (
      <React.Fragment>
        <div ref={this.cRef}>
          <PurchaseMembershipPlanCard
            extraMessage="Microchips registered with 24Petwatch receive basic lost pet recovery services which includes access to online self-serve recovery and profile updates for pet parents."
            legalLine={"Applicable taxes will be applied at checkout"}
            banner={
              this.shouldDisablePurchasing()
                ? MembershipPurchaseUtils.errorBanner(
                    "Please note a registered microchip is required to enroll in a membership."
                  )
                : null
            }
            isUS={AppUtils.getCountryCode().toUpperCase() === 'US'}
            membershipHeadingTextBody= {this.getMembershipHeadingText()}
            petName={this.props.selectedAnimal.animalName}
            iconsToHide={this.state.iconsToHide}
            petMedAlertIconTextBody={
              "Critical medical and behavioural information will be relayed to the shelter or vet they’re brought to when found. Complimentary access for 1 year with a Lifetime Protection Membership."
            }
            vetHelpLineIconTextBody={
              "Reach veterinary professionals anytime by phone, email, or live chat. Complimentary access for 1 year with a Lifetime Protection Membership."
            }
            annualProtectionOptionProps={{
              id: "annual-membership-button",
              disabled: this.shouldDisablePurchasing(),
              hidden: !AppUtils.isUsCountry(),
              active: this.state.annualMembership
                ? AppUtils.isEqualString(
                    this.props.selectedPlans[this.props.selectedPetId],
                    this.state.annualMembership.itemId
                  )
                : false,
              onClick: (e) => {
                this.changeSelectedPlan("annual");
              },
              priceFirstYear: AppUtils.getConvertedPrice(
                this.state.annualMembership?.salesPrice
              ),
              priceYearAfter: AppUtils.getConvertedPrice(
                this.state.annualMembership?.renewalPrice
              ),
            }}
            lifeTimeProtectionOptionProps={{
              id: "lifetime-membership-button",
              disabled: this.shouldDisablePurchasing(),
              active: this.isLpmPlanSelected(),
              onClick: (e) => {
                this.changeSelectedPlan("lpm");
              },
              price: AppUtils.getConvertedPrice(
                this.state.lpmMembership?.itemPrice
              ),
            }}
            lifeTimeProtectionPlusOptionProps={{
              id: "lifetime-plus-membership-button",
              disabled: this.shouldDisablePurchasing(),
              active: this.isLpmPlusPlanSelected(),
              onClick: (e) => {
                this.changeSelectedPlan("lpmPlus");
              },
              price: AppUtils.getConvertedPrice(
                this.state.lpmPlusMembership?.itemPrice
              ),
            }}
            petMedAlertOptionProps={{
              id: "pet-med-alert-button",
              disabled: this.shouldDisablePurchasing(),
              active: this.props.isPetMedAlertSelectedMap[
                this.props.selectedPetId
              ],
              productName: this.state.petMedAlertProduct?.uiName,
              onClick: () => {
                this.toggleAdditionalProductSelection("PetMedAlert");
              },
              price: AppUtils.getConvertedPrice(
                this.state.petMedAlertProduct?.price
              ),
            }}
            vetHelplineOptionProps={{
              id: "vet-helpline-button",
              disabled: this.shouldDisablePurchasing(),
              active: this.props.isVetHelplineSelectedMap[
                this.props.selectedPetId
              ],
              productName: this.state.vetHelplineProduct?.uiName,
              onClick: () => {
                this.toggleAdditionalProductSelection("VetHelpLine");
              },
              price: AppUtils.getConvertedPrice(
                this.state.vetHelplineProduct?.price
              ),
            }}
            autoRenewCheckboxProps={{
              id: "auto-renew-checkbox",
              disabled: this.shouldDisablePurchasing(),
              hidden: this.shouldHideAutoRenew(),
              label: this.getConsentText(),
              checked: this.props.autoRenewCheckboxes?.[
                this.props.selectedPetId
              ],
              onChange: (e) => {
                this.props.toggleAutoRenewCheckbox(this.props.selectedPetId);
              },
            }}
            concentCheckboxProps={{
              id: "concent-checkbox",
              hidden: this.shouldHideAnnualConsent(),
              label:
                "Your Annual Protection Membership will automatically renew one year from date of purchase, at $19.95 per year (plus applicable taxes). Price is subject to change.",
              disabled: this.shouldDisablePurchasing(),
              checked: this.props.consentCheckboxes?.[this.props.selectedPetId],
              onChange: (e) => {
                this.props.toggleConsentCheckbox(this.props.selectedPetId);
              },
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  const membershipPurchaseStore = state.membershipPurchaseStore;
  return {
    selectedPlans: membershipPurchaseStore.selectedPlans,
    isVetHelplineSelectedMap: membershipPurchaseStore.isVetHelplineSelectedMap,
    isPetMedAlertSelectedMap: membershipPurchaseStore.isPetMedAlertSelectedMap,
    autoRenewCheckboxes: membershipPurchaseStore.autoRenewCheckboxes,
    consentCheckboxes: membershipPurchaseStore.consentCheckboxes,
    petShoppingCart: membershipPurchaseStore.petShoppingCart,
    selectedAnimal: customerProfileStore.selectedAnimal,
    selectedPetId: customerProfileStore.selectedPetId,
    animalFullDetails: customerProfileStore.animalFullDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeCartTotal: (price) => dispatch(changeCartTotal(price)),
    changeSelectedPlan: (plan) => dispatch(changeSelectedPlan(plan)),
    addToShoppingCartItems: (item) => dispatch(addToShoppingCartItems(item)),
    removeFromShoppingCartItems: (data) =>
      dispatch(removeFromShoppingCartItems(data)),
    toggleAutoRenewCheckbox: (selectedPetId) =>
      dispatch(toggleAutoRenewCheckbox(selectedPetId)),
    toggleConsentCheckbox: (selectedPetId) =>
      dispatch(toggleConsentCheckbox(selectedPetId)),
    setIsVetHelplineSelected: (data) =>
      dispatch(setIsVetHelplineSelected(data)),
    setIsPetMedAlertSelected: (data) =>
      dispatch(setIsPetMedAlertSelected(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipPurchaseComponent);

import React, { Component } from 'react'
import { RmSuccessCard } from "../../../UI-Component-Library";
import { DataLayerUtils } from '../../../utils/dataLayer-utils';

class RegistrationSuccess extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }

    componentDidMount() {
      DataLayerUtils.createAccountEvent(
        this.props.type,
        'pre-verification success',
        'organic',
        this.props.formValues.microChipNumber
      )
    }
    
    render() {
        return (
          <RmSuccessCard
            id="RmSuccessId"
            userFirstName={this.props.formValues.firstName}
            userEmail={this.props.formValues.email}
            petHealthEmail={"info@24petwatch.com"}
            petHealthPhone={"1-866-597-2424"}
            color={"secondary"}
            petName={this.props.formValues.petName}
          ></RmSuccessCard>
        );
    }
}

export default RegistrationSuccess
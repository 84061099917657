import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Modal, Button, Paper, PaperHeadline, PaperContent, Text } from '../..'

export class CancelInsurancePolicyModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    successText: PropTypes.string,
    cancelInsuranceButtonText: PropTypes.string,
    closeButtonText: PropTypes.string,
    id: PropTypes.string,
    cancelInsuranceButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    closeButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  static defaultProps = {
    title: "",
    closeButtonText: "I do not want to cancel my policy",
    cancelInsuranceButtonText: "I want to cancel my policy",
  }

  render() {
    const {
      open,
      onClose,
      className,
      id,
      title,
      errorMessage,
      cancelInsuranceButtonText,
      closeButtonText,
      successText,
      cancelInsuranceButtonProps,
      closeButtonProps
    } = this.props

    return (
      <Modal
        open={open}
        onClose={onClose}
        className={className}
        id={id}
        refProp
      >
        <div className='ui-cancel-policy-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline> Cancel this insurance policy </PaperHeadline>
            <PaperContent className='ui-cancel-policy-modal-content'>
              {errorMessage && (
                <Text
                  className='ui-cancel-policy-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}
              {!successText && <Text type={Text.Types.body}>
                {title}
                <br></br><br></br>
                Cancelled policies are ineligible for reinstatement.
              </Text>}
                {successText && <Text type={Text.Types.body} className="ui-cancel-policy-card__text">
                  {successText}
                </Text> }
              <div className='ui-cancel-policy-modal__actions'>
                <Button
                  variant={Button.Variants.filled}
                  size={Button.Sizes.small}
                  {...closeButtonProps}
                >
                  {closeButtonText}
                </Button>
                {!successText && (
                  <Button
                    type='button'
                    size={Button.Sizes.small}
                    variant={Button.Variants.outlined}
                    {...cancelInsuranceButtonProps}
                  >
                    I want to cancel my policy
                  </Button>
                )}
              </div>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

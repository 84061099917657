import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Form,
  Input,
  FormAction,
  Button,
  Text,
  Select,
  Link,
  Icon,
  Checkbox,
  Divider,
  IconButton
} from '../../'
import { Locale as importedLocale } from '../../constants'
import { getLabelsByLocale } from '../../utils'

export class UserContactInfoCard extends React.PureComponent {
  static Locale = importedLocale

  static propTypes = {
    locale: PropTypes.oneOf(Object.values(UserContactInfoCard.Locale)),
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    onFormSubmit: PropTypes.func,
    isPetHealthProgram: PropTypes.bool,
    showAddPhoneLink:PropTypes.bool,
    /**
     * phoneNumberFields
     */
    phoneNumberFieldsProps: PropTypes.arrayOf(
      PropTypes.shape({
        selectPhoneFieldProps: PropTypes.shape({
          options: PropTypes.arrayOf(
            PropTypes.shape({
              value: PropTypes.string,
              label: PropTypes.string
            })
          ),
          value: PropTypes.string,
          onChange: PropTypes.func,
          disabled: PropTypes.bool,

          id: PropTypes.string
        }),
        inputPhoneFieldProps: PropTypes.shape({
          value: PropTypes.string,
          onChange: PropTypes.func,
          onBlur: PropTypes.func,
          onFocus: PropTypes.func,
          name: PropTypes.string,
          error: PropTypes.bool,
          hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
          id: PropTypes.string
        })
      })
    ),
    /**
     * Email address
     */
    emailInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * First name
     */
    firstNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Last name
     */
    lastNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Country
     */
    countrySelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Address line 1
     */
    address1InputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Address line 2
     */
    address2InputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * City
     */
    cityInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * State
     */
    stateSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Email address
     */
    zipInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    checkboxProps: PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.any,
      errorMessage: PropTypes.string,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      onClick: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),
    petParentInfoConsentProps: PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.any,
      errorMessage: PropTypes.string,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      onClick: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),
    closePhoneNumberProps: PropTypes.func,
    phoneLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),
    saveChangesButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      locale,
      successMessage,
      errorMessage,
      onFormSubmit,
      isPetHealthProgram,
      phoneNumberFieldsProps,
      showAddPhoneLink,
      phoneLinkProps,
      closePhoneNumberProps,
      emailInputFieldProps,
      firstNameInputFieldProps,
      lastNameInputFieldProps,
      countrySelectFieldProps,
      address1InputFieldProps,
      address2InputFieldProps,
      cityInputFieldProps,
      stateSelectFieldProps,
      zipInputFieldProps,
      checkboxProps,
      petParentInfoConsentProps,
      saveChangesButtonProps,
      id,
      className,
      style,
      refProp
    } = this.props

    const { stateLabel, zipCodeLabel } = getLabelsByLocale(locale)

    return (
      <Paper
        id={id}
        className={cn('ui-user-contact-info-card', className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Your contact info</PaperHeadline>
        <PaperContent>
          <Form onSubmit={onFormSubmit}>
            {successMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-user-contact-info-card__success-message'
              >
                {successMessage}
              </Text>
            )}

            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-user-contact-info-card__error-message'
              >
                {errorMessage}
              </Text>
            )}
            {phoneNumberFieldsProps.map((fieldValue, index) => {
              return (
                <div key={index}>
                  {index > 0 && <Divider className='ui-emergency-contact-card__divider' />}
                  {index === 0 ? (
                    <>
                      <label
                        className='ui-user_-contact-phone-label'
                        htmlFor={
                          index === 0
                            ? 'phoneNumberrow'
                            : `phoneNumberrow${index}`
                        }
                      >
                        Phone number {index === 0 ? '' : index + 1}: (required)
                      </label>
                    </>
                  ) : (
                    <div className='ui-user-contact-phone-container'>
                      <label
                        htmlFor={
                          index === 0
                            ? 'phoneNumberrow'
                            : `phoneNumberrow${index}`
                        }
                      >
                        Phone number {index === 0 ? '' : index + 1}:
                      </label>
                      <div className='ui-user-contact-phone-close-icon'>
                        <IconButton 
                            type='close' 
                            onClick={() => closePhoneNumberProps(index)}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className='ui-user-contact-info-card__form__row'
                    id={
                      index === 0 ? 'phoneNumberrow' : `phoneNumberrow${index}`
                    }
                  >
                    <div className='ui-user-contact-info-phoneSelect'>
                      <Select {...fieldValue.selectPhoneFieldProps} />
                    </div>
                    <Input {...fieldValue.inputPhoneFieldProps} />
                  </div>
                </div>
              )
            })}
          
            {showAddPhoneLink && <Link { ...phoneLinkProps }>
              <Icon
                type='add_circle'
                className='ui-emergency-contact-card__icon'
              />
              <span className='ui-emergency-contact-card__span'>
                Add another phone number
              </span>
            </Link> }
            <Input label='Email Address' {...emailInputFieldProps} />

            <Input label='First Name' {...firstNameInputFieldProps} />

            <Input label='Last Name' {...lastNameInputFieldProps} />

            <Select label='Country' {...countrySelectFieldProps} />

            <Input label='Address Line 1' {...address1InputFieldProps} />

            <Input label='Address Line 2' {...address2InputFieldProps} />

            <Input label='City' {...cityInputFieldProps} />

            <div className='ui-user-contact-info-card__form__row'>
              <Select label={stateLabel} {...stateSelectFieldProps} />

              <Input label={zipCodeLabel} {...zipInputFieldProps} />
            </div>

            <Text>
              You understand and consent to the collection, storage and use of your personal
              data for the purposes outlined in the 24Petwatch Privacy Policy. Your personal
              data privacy rights are outlined therein.
            </Text>

            {isPetHealthProgram && (
              <>
                <Checkbox
                  className="ui-user-contact-info-card__form__checkbox"
                  label='With a microchip, we offer you free lost pet services, as well as exclusive offers, promotions and the latest information from 24Petwatch regarding microchip and insurance services.'
                  {...checkboxProps}
                />
                <Checkbox
                  className="ui-user-contact-info-card__form__checkbox"
                  label='In the event that your pet is missing and is found by a Good Samaritan, you give your consent for us to release your contact information to the finder. This may include your name, phone number, address and email address.'
                  {...petParentInfoConsentProps}
                />
              </>
            )}

            {/* {isPetHealthProgram && (
              <Text>
                Pethealth Services (USA) Inc., Pethealth Services Inc, PTZ
                Insurance Services Ltd. and PTZ Insurance Agency Ltd. may
                contact you via commercial electronic messages, automatic
                telephone dialing systems, pre-recorded/automated messages or
                text messages at the telephone number provided above, including
                your mobile number. These calls or emails are not a condition of
                the purchase of any goods or services.
                <br />
                <br />
                You understand that if you choose not to provide your consent,
                you will not receive electronic notifications regarding
                insurance and/or free lost pet services which includes being
                contacted with information in the event that your pet goes
                missing.
                <br />
                <br />
                You may withdraw your consent at any time.
              </Text>
            )} */}

            <FormAction>
              <Button
                color={Button.Colors.tertiary}
                type='submit'
                {...saveChangesButtonProps}
              >
                Save changes
              </Button>
            </FormAction>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

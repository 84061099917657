import React from "react";
import {
  LTProtectionMembershipCard,
  MembershipContactSupportCard,
} from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { AppUtils } from "../../../utils/app-utils";
import { connect } from "react-redux";
import { MembershipPurchaseUtils } from "./membership-purchase.util";
import {
  setIsActiveMedAlert,
  setIsActiveVetHelpline,
} from "../../../redux/reducers/customer-profile-reducer";
class LpmMembershipComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      subscriptionOptions: [],
      disableMedAlertIconText: false,
      disableVetHelplineIconText: false,
      errorMessage: "",
      hasSubProducts: true,
      shouldShowBanner: false,
      strikePrice: false,
      banner: MembershipPurchaseUtils.noBanner(),
    };
    this.cRef = React.createRef();
  }

  isAllowedToChange(element) {
    if (element.expiredProduct && !element.hasRenewToken) {
      this.setState({
        banner: MembershipPurchaseUtils.warningBanner(),
      });
      return false;
    }
    return true;
  }
  postAutoRenew(element) {
    this.setState({
      errorMessage: "",
    });
    if (!this.isAllowedToChange(element)) {
      return;
    }
    axios
      .processPost(ApiConstants.URL_SAVE_AUTO_RENEW_POST, this.cRef, {
        email: AppUtils.getUserEmail(),
        busRelAccount: AppUtils.getBusRelAccount(),
        animalId: this.props.selectedPetId,
        itemId: element.itemId,
        recId: element.recId,
        autoRenew: !element.autoRenew,
      })
      .then((response) => {
        this.fetchMembershipProductsOfPet(true);
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState(
            {
              errorMessage: errors[0],
            },
            () => {
              this.showBanner(false);
            }
          );
        }
      });
  }

  showBanner(success) {
    this.setState({
      banner: success
        ? MembershipPurchaseUtils.subscriptionUpdatedBanner()
        : MembershipPurchaseUtils.errorBanner(this.state.errorMessage),
    });
    setTimeout(() => {
      this.updateBanner();
    }, 5000);
  }
  getDetail(element) {
    if (element.expiredProduct) {
      return "Expired: " + element.expiryDate;
    } else if (element.autoRenew) {
      return "Renews: " + element.renewDate;
    } else {
      return "Will Expire: " + element.expiryDate;
    }
  }
  fetchMembershipProductsOfPet(showBanner) {
    axios
      .processGet(
        `${
          ApiConstants.URL_GET_MEMBERSHIP_PRODUCTS_OF_PET
        }/${AppUtils.getBusRelAccount()}/${this.props.selectedPetId}`,
        this.cRef
      )
      .then((response) => {
        const subscriptionOptions = [];
        const data = response.data;
        let disableMedAlertIconText = true;
        let disableVetHelplineIconText = true;
        let vetHelpLine = data.productList.find((item) =>
          AppUtils.isEqualString(item.itemId, "WD Annual Membership")
        );
        let petMedAlert = data.productList.find((item) =>
          AppUtils.isEqualString(item.itemId, "PetMedInfo Fees")
        );
        if (vetHelpLine && petMedAlert) {
          if (vetHelpLine.autoRenew && petMedAlert.autoRenew) {
            this.setState({
              strikePrice: true,
            });
            vetHelpLine.strikePrice = true;
            petMedAlert.strikePrice = true;
          } else {
            this.setState({
              shouldShowBanner: true,
            });
          }
        }
        data.productList.forEach((element) => {
          if (
            AppUtils.isEqualString(element.itemId, "PetMedInfo Fees") &&
            !element.expiredProduct
          ) {
            this.props.setIsActiveMedAlert(true);
          }
          if (
            AppUtils.isEqualString(element.itemId, "WD Annual Membership") &&
            !element.expiredProduct
          ) {
            this.props.setIsActiveVetHelpline(true);
          }
          if (element.renewable) {
            if (!element.expiredProduct) {
              if (AppUtils.isEqualString(element.itemId, "PetMedInfo Fees")) {
                disableMedAlertIconText = false;
              }
              if (
                AppUtils.isEqualString(element.itemId, "WD Annual Membership")
              ) {
                disableVetHelplineIconText = false;
              }
            }
            subscriptionOptions.push({
              strikePrice: element.strikePrice,
              key: element.itemId,
              variant: MembershipPurchaseUtils.getVariant(element),
              onClick: (event) => {
                this.postAutoRenew(element);
              },
              showCheckMark: true,
              checked: element.autoRenew,
              label: AppUtils.getAlreadyPurchasedLifeTimeProductName(
                element.itemId
              ),
              description: {
                price: AppUtils.getConvertedPrice(element.price),
                detail: this.getDetail(element),
                frequency: AppUtils.getProductFrequency(element.frequency)
              },
            });
          }
        });

        const hasSubProducts = subscriptionOptions.length !== 0;
        this.setState(
          {
            hasSubProducts,
            subscriptionOptions,
            disableMedAlertIconText,
            disableVetHelplineIconText,
          },
          () => {
            if (showBanner) {
              this.showBanner(true);
            } else {
              this.updateBanner();
            }
          }
        );
      });
  }
  updateBanner() {
    this.setState({
      banner: this.state.shouldShowBanner
        ? MembershipPurchaseUtils.offerBanner()
        : this.state.strikePrice
        ? MembershipPurchaseUtils.anotherBanner()
        : MembershipPurchaseUtils.noBanner(),
    });
  }
  componentDidMount() {
    this.fetchMembershipProductsOfPet();
  }
  getMembershipCard() {
    if (this.state.hasSubProducts) {
      return (
        <LTProtectionMembershipCard
          strikePrice={this.state.strikePrice}
          legalLine={"Applicable taxes will be applied at checkout"}
          errorMessage={this.state.errorMessage}
          disableMedAlertIconText={this.state.disableMedAlertIconText}
          disableVetHelplineIconText={this.state.disableVetHelplineIconText}
          banner={this.state.banner}
          checkboxProps={{
            checked: true,
            onChange: () => {},
          }}
          subscriptionOptions={this.state.subscriptionOptions}
        />
      );
    }

    return (
      <MembershipContactSupportCard
        title={
          this.props.memberType === 2
            ? "Lifetime Membership Plan"
            : "Lifetime Protection Membership Plan"
        }
      />
    );
  }
  render() {
    return <div ref={this.cRef}>{this.getMembershipCard()}</div>;
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    selectedPetId: customerProfileStore.selectedPetId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsActiveMedAlert: (flag) => dispatch(setIsActiveMedAlert(flag)),
    setIsActiveVetHelpline: (flag) => dispatch(setIsActiveVetHelpline(flag)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LpmMembershipComponent);

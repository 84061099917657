import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import PolicyDetails from "../policy/policy-details";
import { connect } from "react-redux";
import {
  changeSelectedPetId,
  saveCustomerProfile,
} from "../../../redux/reducers/customer-profile-reducer";
import {
  clearCartData,
  restoreData,
  backupBeforeRefresh,
} from "../../../redux/reducers/membership-purchase-reducer";
import PetClaimHistory from "../pet-claim/pet-claim-history";
import PetDetails from "../pet-details/pet-details";
import {
  PageContent,
  PaperCarousel,
  NoActivePoliciesCard,
  StartInsuranceCard,
  VetHelpLineCard,
  RemoveReunificationContactModal,
  ReunificationContactCard,
  GeneralInfoModal,
  Banner,
  Text,
  Link,
  Button,
  PageBanner,
} from "../../../UI-Component-Library";
import PetMedAlert from "../pet-med-alert/pet-med-alert";
import PetShoppingCart from "../PetShoppingCart/pet-shopping-cart";
import Membership from "../membership/membership";
import { MembershipPurchaseUtils } from "../membership/membership-purchase.util";
import { AppUtils } from "../../../utils/app-utils";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { LoadMask } from "../../../utils/loadmask.util";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";

class Dashboard extends Component {
  static propTypes = {
    isNewRegistered: PropTypes.bool,
  };

  constructor() {
    super();
    this.state = {
      responData: {},
      errorMessages: [],
      selectedPetIndex: undefined,
      selectedPetId: undefined,
      policyNumbers: [],
      suspendedPolicies: [],
      errorPolicies: [],
      covidPolicies: [],
      petSuccessMessage: "",
      isRemoveRContactModalOpen: false,
      deleteIndex: undefined,
      removeRContactsError: "",
      isContactRemoved: false,
      reunificationContacts: [],
      isGeneralInfoModalOpen: false,
    };
    this.cRef = React.createRef();
    window.onbeforeunload = (event) => {
      this.props.backupBeforeRefresh();
    };
  }

  getReunificaitonsContacts(animalId) {
    if(!animalId){
      return;
    }
    const divRef = this.cRef.current;
    LoadMask.showPreloader(divRef);
    axios
      .processGet(
        `${ApiConstants.URL_GET_REUNIFICATION_CONTACTS}/${animalId}`,
        null,
      )
      .then((response) => {
        const reunificationContacts = [];
        if (
          response.busRelAccount &&
          (response.contactPersonId ||
          response.name ||
          response.firstName ||
          response.lastName ||
          response.phoneNumber ||
          response.email)
        ) {
          reunificationContacts.push(response);
        }
        this.setState({
          reunificationContacts,
        })
        LoadMask.hidePreloader(divRef);
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
        }
        LoadMask.hidePreloader(divRef);
    })
  }

  deleteReunificationContact(animalId, index) {
    this.setState({
      removeRContactsError: "",
    });
    const reunificationContacts = this.state.reunificationContacts;
    axios
      .processPost(
        `${ApiConstants.URL_DELETE_REUNIFICATION_CONTACTS}/${animalId}`,
        null
      )
      .then((response) => {
        reunificationContacts.splice(index);
        this.setState({
          isContactRemoved: true,
          reunificationContacts,
        })
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            removeRContactsError: errors[0],
            isRemoveRContactModalOpen: true,
            isContactRemoved: false,
          });
        }
    })
  }
  componentDidMount() {
    this.props.clearCartData();
    this.props.restoreData();
    MembershipPurchaseUtils.removeCartDataFromLocalStorage();
    let selectedPetIndex;
    for (let i = 0; i < this.props.customerAnimals.length; i++) {
      if (this.props.customerAnimals[i].animalId === this.props.selectedPetId) {
        selectedPetIndex = i;
        break;
      }
    }
    this.setState(
      {
        selectedPetIndex,
      },
      () => {
        this.getAllPolicies();
      }
    );
    this.getReunificaitonsContacts(this.props.selectedPetId);
  }
  componentDidUpdate() {
    if (this.state.selectedPetId !== this.props.selectedPetId
      && AppUtils.isLostPetEnabled()
    ) {
      this.getReunificaitonsContacts(this.props.selectedPetId);
    }
    if (
      this.props.selectedPetId &&
      this.props.selectedPetId !== this.state.selectedPetId
    ) {
      let selectedPetIndex;
      for (let i = 0; i < this.props.customerAnimals.length; i++) {
        if (
          this.props.customerAnimals[i].animalId === this.props.selectedPetId
        ) {
          selectedPetIndex = i;
          break;
        }
      }
      this.setState({
        selectedPetId: this.props.selectedPetId,
        selectedPetIndex,
      });
    }
  }
  navigateToCart() {
    AppUtils.openLinkInNewTab(FeatureFlagUtils.getShoppinCartUrl());
  }
  getPolicyCard() {
    const speciesId = this.props.animalFullDetails[this.props.selectedPetId]
      ?.speciesId;
    if (this.props.selectedAnimal) {
      if (
        this.props.selectedAnimal?.insurances?.[0]?.policynumber?.length > 0
      ) {
        return (
          <PolicyDetails
            policynumbers={
              this.props.selectedAnimal?.insurances?.[0]?.policynumber
            }
          ></PolicyDetails>
        );
      } else if (speciesId && (speciesId === 1 || speciesId === 2)) {
        if (!AppUtils.isInsuranceEnabled()) {
          return null;
        }
        return (
          <StartInsuranceCard
            className={(FeatureFlagUtils.hideCardImage())?"hide-card-image":""}
            getStartedButtonProps={{
              id: " getStartedButton",
              onClick: () => {
                DataLayerUtils.getStartedInsuranceEvent(
                  AppUtils.getUserRelationId(),
                  this.props.animalFullDetails[this.props.selectedPetId]?.microchipNumber
                )
                this.navigateToCart();
              },
            }}
          ></StartInsuranceCard>
        );
      } else {
        return null;
      }
    }
  }

  getCovidPolicyBanner = () => {
    if (this.state.covidPolicies.length > 0) {
      const subtitle = (
        <React.Fragment>
          <Text>
            Your policy is in a suspended state, as per your recent request to
            withhold payments during the COVID-19 pandemic. As such, we cannot
            process claims on your policy at this time. <br />
            Affected Policies: <b> {this.state.covidPolicies.join(", ")}</b>
          </Text>
        </React.Fragment>
      );

      return <Banner subtitle={subtitle} color={Banner.Colors.warning} />;
    }
  };
  getDuePolicyBanner = () => {
    if (this.state.errorPolicies.length > 0) {
      const title = `Your policy premium is currently in arrears.`;
      const phoneNumber = FeatureFlagUtils.getPhoneNumber();
      let m = (
        <React.Fragment>
          <Text>
            Please{" "}
            <Link
              onClick={() => {
                this.props.history.push("/pages/user-profile");
              }}
            >
              <u>click here</u>
            </Link>{" "}
            to update method of your payment for premium collection or contact
            our Customer Care for assistance: {phoneNumber}. <br />
            Upon the successful collection of outstanding premiums, your ability
            to submit claims online will be restored. Affected policies:{" "}
            <b> {this.state.errorPolicies.join(", ")}</b>
          </Text>
        </React.Fragment>
      );

      return (
        <Banner title={title} subtitle={m} color={Banner.Colors.warning} />
      );
    }
  };

  getSuspendedBanner = () => {
    if (this.state.suspendedPolicies.length > 0) {
      const title = `Your policy is in a suspended state.`;
      const phoneNumber = FeatureFlagUtils.getPhoneNumber();
      let m = (
        <React.Fragment>
          <Text>
            Please contact our customer care unit at {phoneNumber}. Please note
            we are not able to process claims on your policy at this time.
            Affected policies: <b> {this.state.suspendedPolicies.join(", ")}</b>
          </Text>
        </React.Fragment>
      );

      return (
        <Banner title={title} subtitle={m} color={Banner.Colors.warning} />
      );
    }
  };

  getStatusOfPolicies = () => {
    for (let policy of this.state.policyNumbers) {
      axios
        .processGet(`${ApiConstants.URL_GET_STATUS_POLICY}/${policy}`)
        .then((response) => {
          const isOutstandingPending = response?.data?.hasOutstandingPremium;
          const isSuspended = response?.data?.isNotActive;
          const isCovid = response?.data?.isDueToCovid19;

          if (isOutstandingPending) {
            this.setState((prevState) => {
              return {
                errorPolicies: [...prevState.errorPolicies, policy],
              };
            });
          }

          if (isSuspended) {
            this.setState((prevState) => {
              return {
                suspendedPolicies: [...prevState.suspendedPolicies, policy],
              };
            });
          }

          if (isCovid) {
            this.setState((prevState) => {
              return {
                covidPolicies: [...prevState.covidPolicies, policy],
              };
            });
          }
        })
        .catch((error) => {});
    }
  };

  getAllPolicies = () => {
    const policies = [];
    const animals = this.props.customerAnimals;
    if (animals && animals.length > 0) {
      for (let animal of animals) {
        const insurances = animal?.insurances;

        if (insurances && insurances.length > 0) {
          for (let insurance of insurances) {
            if (insurance.policynumber) {
              policies.push(...insurance.policynumber);
            }
          }
        }
      }
    }
    this.setState(
      {
        policyNumbers: policies,
      },
      () => {
        this.getStatusOfPolicies();
      }
    );
  };

  updatePetStatusMessage = () => {
    this.setState({
      petSuccessMessage: "Thank you for the update!",
    });

    setTimeout(() => {
      this.setState({
        petSuccessMessage: "",
      });
    }, 5000);
  };

  getPetUpdateSuccessMessage = () => {
    if (this.state.petSuccessMessage.length > 0) {
      return (
        <Banner
          title={this.state.petSuccessMessage}
          color={Banner.Colors.success}
        />
      );
    }
  };

  showWinbackOfferHandler = (e) => {
    const el = document.getElementById("membership-purchase");

    if(el) {
      el.scrollIntoView({behavior: "smooth"});
    }

    e.preventDefault();
  }

  getWinbackOffer = () => {
    if(
      this.props.winbackOfferAvailability && this.props.winbackOfferAvailability[this.props.selectedPetId]
    ){
      const offerStatement = (
        <>
          Limited Time Offer! Save 25% on our <b>24/7 Vet Helpline</b> and <b>24PetMedAlert subscription!</b>
          {' '}
          <Link
            href= '#'
            onClick={ (e) => this.showWinbackOfferHandler(e)}
          >
            <span style={{fontSize: "16px", textDecoration: "underline"}}>Take me there</span>
          </Link>
        </>
      )
      return (
        <Banner
          showIcon = {true}
          title = {offerStatement}
        />
      )
    }

    return;
  }

  render() {
    return (
      <div>
        {this.getPetUpdateSuccessMessage()}
        {this.getDuePolicyBanner()}
        {this.getSuspendedBanner()}
        {this.getCovidPolicyBanner()}
        {this.getWinbackOffer()}
        <PageContent>
          <PaperCarousel
            selectedItem={this.state.selectedPetIndex}
            onChange={(index, reactElement) => {
              const petId = this.props.customerAnimals[index].animalId;
              this.props.changeSelectedPetId(petId);
            }}
          >
            {this.props.customerAnimals?.map((animal) => {
              return (
                <PetDetails
                  pet={animal}
                  updatePetStatusMessage={this.updatePetStatusMessage}
                  key={animal.animalId}
                ></PetDetails>
              );
            })}
          </PaperCarousel>
          {this.props.customerProfile &&
            this.props.customerAnimals.length === 0 && (
              <NoActivePoliciesCard
                message={FeatureFlagUtils.getNoPoliciesMessage()}
                phoneNumber={FeatureFlagUtils.getPhoneNumber()}
              />
            )}
          {this.props.animalFullDetails[this.props.selectedPetId] && (
            <PetClaimHistory
              animalDetails={
                this.props.animalFullDetails[this.props.selectedPetId]
              }
            ></PetClaimHistory>
          )}
          {this.getPolicyCard()}
          {FeatureFlagUtils.shouldShowVetHelplinecard() &&
            this.props.isActiveVetHelpline && (
            <VetHelpLineCard
                className={(FeatureFlagUtils.hideCardImage())?"hide-card-image":""}
                openChatButtonProps={{
                  id: "vet_helpline_button",
                  onClick: (event) => {
                    window.wdChatLinkClicked(
                      "aT1QSDE5RU5JWCZlPXdkY2hhdGJveHBoMSU0MHdoaXNrZXJkb2NzLmNvbSZzdD0x"
                    );
                  },
                }}
              ></VetHelpLineCard>
            )}
          {FeatureFlagUtils.shouldShowPetMedAlertCard() && (
            <PetMedAlert></PetMedAlert>
          )}
          {AppUtils.isMembershipEnabled() &&
            this.props.selectedPetId &&
            !this.props.noPets && <Membership id="membership-purchase"></Membership>}

          {FeatureFlagUtils.shouldShowPetShoppingCart() &&
            !this.props.noPets && <PetShoppingCart></PetShoppingCart>}
          {AppUtils.isLostPetEnabled() && <div ref={this.cRef}>
            <ReunificationContactCard
              rcontactsProps = { this.state.reunificationContacts }
              deleteContactProps={(index) => {
                this.setState({
                  deleteIndex: index,
                  isRemoveRContactModalOpen: true,
                })
              }}
              linkProps={{
                id: "aboutRCId",
                color: Link.Colors.secondary,
                href: "",
                onClick: (e) => {
                  e.preventDefault();
                  this.setState({
                    isGeneralInfoModalOpen: true,
                  })
                }
              }}
            >
            </ReunificationContactCard>
          </div>}
          <GeneralInfoModal
            open={this.state.isGeneralInfoModalOpen}
            onClose={() => {
              this.setState({
                isGeneralInfoModalOpen: false,
              })
            }}
            modalHeadline={"About reunification contacts"}
            buttonText={"Got it"}
            cancelButtonProps={{
              size:Button.Sizes.small,
              onClick: () => {
                this.setState({
                  isGeneralInfoModalOpen: false,
                })
              }
            }}
            callInMessage={"Reunification contact refers to the organization (often a shelter or rescue) that is linked to your pet. If your pet goes missing and is found, the organization will be notified to help reunite you with your pet. You can delete the reunification contact if you do not want them to be notified."}
          >
          </GeneralInfoModal>
          {AppUtils.isLostPetEnabled() && <RemoveReunificationContactModal
            errorMessage={this.state.removeRContactsError}
            open={this.state.isRemoveRContactModalOpen}
            onClose={() => {
              this.setState({ isRemoveRContactModalOpen: false });
            }}
            title={this.state.isContactRemoved ? "Success" : "Are you sure you want to remove the reunification contact of this pet?"}
            cancelButtonText={this.state.isContactRemoved ? "Close" : "Cancel"}
            successText={this.state.isContactRemoved ? 'You have removed the contact successfully.' : ''}
            cancelTransferButtonProps={{
              onClick: () => {
                if (this.state.isContactRemoved) {
                  this.getReunificaitonsContacts(this.props.selectedPetId);
                }
                this.setState({
                  isRemoveRContactModalOpen: false,
                  isContactRemoved: false,
                  removeRContactsError: ""
                })
              }
            }}
            removeButtonProps={{
              onClick: () => {
                this.deleteReunificationContact(this.props.selectedPetId, this.state.deleteIndex)
              }
            }}
          >
          </RemoveReunificationContactModal>}
        </PageContent>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  const membershipPurchaseStore = state.membershipPurchaseStore;
  return {
    customerProfile: customerProfileStore.customerProfile,
    noPets: customerProfileStore.noPets,
    animalFullDetails: customerProfileStore.animalFullDetails,
    isActiveVetHelpline: customerProfileStore.isActiveVetHelpline,
    selectedAnimal: customerProfileStore.selectedAnimal,
    customerAnimals: customerProfileStore.customerAnimals,
    selectedPetId: customerProfileStore.selectedPetId,
    petShoppingCart: membershipPurchaseStore.petShoppingCart,
    winbackOfferAvailability: membershipPurchaseStore.winbackOfferAvailability,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCustomerProfile: (data) => dispatch(saveCustomerProfile(data)),
    changeSelectedPetId: (id) => dispatch(changeSelectedPetId(id)),
    clearCartData: () => dispatch(clearCartData()),
    restoreData: () => dispatch(restoreData()),
    backupBeforeRefresh: () => dispatch(backupBeforeRefresh()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {
  Paper,
  PaperContent,
  Button,
  Text,
  Icon,
  Link
} from '../..'

export class OutgoingTransferMessageCard extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    className: PropTypes.string,
    onClose: PropTypes.func,
    id: PropTypes.string,
    showCancelTransferButton: PropTypes.bool,
    turnedOffAlertDate:PropTypes.string,
    date: PropTypes.string,
    cancelTransferButtonProp: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    alertCancelMessage: PropTypes.string,
    outgoingTransferAlertMessage: PropTypes.any,

    goBackLinkProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string
    }),
    selectOptionInfo: PropTypes.string
  }

  static defaultProps = {
    selectOptionInfo: "",
  }

  render() {
    const {
      id,
      className,
      date,
      cancelTransferButtonProp,
      outgoingTransferAlertMessage,
      showCancelTransferButton,
      alertCancelMessage,
      goBackLinkProps,
      selectOptionInfo,
    } = this.props
    return (
      <Paper
        id={id}
        className={cn(className, 'ui-outgoing-transfer-message-delivered-card')}
      >
        <PaperContent className='ui-outgoing-transfer-message-delivered-card-content'>
          <div className='ui-outgoing-transfer-message-delivered-card-content-back-link'>
            <Icon type='chevron_left' />
            <Link {...goBackLinkProps}>Back to notifications</Link>
          </div>

          <div className='ui-outgoing-transfer-message-delivered-card-content-message'>
            <Text
              types={Text.Types.bodyBold}
              className='ui-outgoing-transfer-message-modal-date'
            >
              {date}
            </Text>
            {outgoingTransferAlertMessage}
           
            <Text
              type={Text.Types.caption}
              className="ui-outgoing-tranfer-message-modal-text"
            >
              {selectOptionInfo}
            </Text>
          </div>
          <div className='ui-outgoing-transfer-message-delivered-card-content-alert-button'>
            {showCancelTransferButton ? (<div className='ui-outgoing-transfer-pet-modal__actions'>
              <Button
                id="outgoing-cancel-button"
                size={Button.Sizes.small}
                {...cancelTransferButtonProp}
              >
                Cancel Transfer
              </Button>
            </div>
            ) : (
              <Text>{ alertCancelMessage }</Text>
            )}
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

import { createSlice } from "@reduxjs/toolkit";

const GlobalReducer = createSlice({
  name: "global",
  initialState: {
    openClaim: false,
  },
  reducers: {
    openClaim: (state, action) => {
      state.openClaim = true;
    }
  },
});

export const {
  openClaim
} = GlobalReducer.actions;
export default GlobalReducer.reducer;

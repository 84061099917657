import React from "react";
import { ResetPasswordCard, PageContent } from "../../../UI-Component-Library";
import { AppUtils } from "../../../utils/app-utils";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { ValidationMessages } from "../../../utils/validation-messages";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";

const queryString = require("query-string");

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      userId: "",
      password: "",
      confirmPassword: "",
      code: "",
      isPasswordError: false,
      isConfirmPasswordError: false,
      errorMessage: "",
    };
    this.cRef = React.createRef();
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.history.location.search);
    if (parsed) {
      this.setState({
        code: parsed.code,
        userId: parsed.userId,
      });
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  resetPassword() {
    let isPasswordError = false;
    let isConfirmPasswordError = false;
    let errorMessage = "";
    if (
      AppUtils.isEmptyString(this.state.password) ||
      !AppUtils.isValidPassword(this.state.password)
    ) {
      isPasswordError = true;
    }
    if (this.state.password.trim() !== this.state.confirmPassword.trim()) {
      isConfirmPasswordError = true;
    }
    this.setState({
      isPasswordError,
      isConfirmPasswordError,
      errorMessage,
    });
    if (!isPasswordError && !isConfirmPasswordError) {
      const requestData = (({ userId, password, confirmPassword, code }) => ({
        userId,
        password,
        confirmPassword,
        code,
      }))(this.state);
      requestData.password = requestData.password.trim();
      requestData.confirmPassword = requestData.confirmPassword.trim();
      axios
        .processPost(
          ApiConstants.URL_POST_RESET_PASSWORD,
          this.cRef,
          requestData
        )
        .then((response) => {
          DataLayerUtils.forgotPasswordEvent("reset your password");
          DataLayerUtils.createAccountSuccessEvent(
            null,
            this.state.userId,
            null,
            "email",
            null
          );

          this.props.history.push("/auth/reset-password/success");
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              errorMessage: errors[0],
            });
            DataLayerUtils.formErrorEvent("reset password", null, errors[0]);
          }
        });
    }
  }

  render() {
    return (
      <div ref={this.cRef}>
        <PageContent>
          <ResetPasswordCard
            headline={"Reset password"}
            errorMessage={this.state.errorMessage}
            onFormSubmit={() => {
              this.resetPassword();
            }}
            passwordInputFieldProps={{
              id: "rp_password",
              label: "Password: ",
              value: this.state.password,
              onChange: this.handleInputChange,
              error: this.state.isPasswordError,
              hint: this.state.isPasswordError
                ? ValidationMessages.PASSWORD_VALIDATION_MESSAGE
                : "",
              name: "password",
            }}
            confirmPasswordInputFieldProps={{
              id: "rp_confirm_password",
              label: "Confirm password: ",
              value: this.state.confirmPassword,
              onChange: this.handleInputChange,
              error: this.state.isConfirmPasswordError,
              hint: this.state.isConfirmPasswordError
                ? ValidationMessages.PASSWORD_DOESNT_MATCH
                : "",
              name: "confirmPassword",
            }}
            buttonText={"Set password"}
            continueButtonProps={{
              id: "rp_continue_button",
              onClick: () => {},
            }}
          />
        </PageContent>
      </div>
    );
  }
}
export default ResetPassword;

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  PaperContent,
  Avatar,
  Text,
  Icon,
  Button,
  Link,
} from '../../'

export class PetInfoCard extends React.PureComponent {
  static propTypes = {
    /**
     * An attribute that is a new registered should disabled edit & just show name of pet
     */
    isNewRegistered: PropTypes.bool,
    /**
     * An id attribute that is applied to pet profile picture (avatar)
     */
    avatarElementId: PropTypes.string,
    onPetAvatarClick: PropTypes.func,
    petAvatar: PropTypes.string,
    petName: PropTypes.string,
    info: PropTypes.any,
    /**
     * An id attribute that is applied to edit pet name title
     */
    petNameEditElementId: PropTypes.string,
    isPetBeingTransferred: PropTypes.bool,
    onPetNameEditClick: PropTypes.func,
    petAge: PropTypes.string,
    petBreed: PropTypes.string,
    petColor: PropTypes.string,
    petMicroChipNumber: PropTypes.string,
    petPetWatchId: PropTypes.string,
    showServicesProgress: PropTypes.bool,
    numberOfServicesEnrolled: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
    showRegisterMicrochipButton: PropTypes.bool,
    registerMicrochipButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    banner: PropTypes.element,
    clickHereLinkProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    removePetLinkProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    cancelTransferLinkProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    transferPetLinkProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    basicServiceLinkProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    showBasicServiceProtection: PropTypes.bool,
    showTick: PropTypes.bool,
    showLostPetAlertIcon: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    showServicesProgress: true,
    showLostPetAlertIcon: false,
    isPetBeingTransferred: false,
    info : (
      <>
        {(
          ''
        )}
      </>
    )
  }

  render() {
    const {
      isNewRegistered,
      avatarElementId,
      onPetAvatarClick,
      petAvatar,
      info,
      petName,
      petNameEditElementId,
      isPetBeingTransferred,
      onPetNameEditClick,
      petAge,
      petBreed,
      petColor,
      petMicroChipNumber,
      petPetWatchId,
      showServicesProgress,
      numberOfServicesEnrolled,
      showRegisterMicrochipButton,
      registerMicrochipButtonProps,
      removePetLinkProps,
      cancelTransferLinkProps,
      transferPetLinkProps,
      showLostPetAlertIcon,
      showBasicServiceProtection,
      showTick,
      banner,
      id,
      className,
      style,
      refProp,
      basicServiceLinkProps
    } = this.props

    const petInfoLabel = `${petAge} ${petBreed}${
      petColor ? ` - ${petColor}` : ''
    }`
    const microChipLabel = `Microchip: ${petMicroChipNumber}`
    const petWatchLabel = `24Petwatch ID: ${petPetWatchId}`
    return (
      <div
        id={id}
        className={cn('ui-pet-info-card', className)}
        style={style}
        ref={refProp}
      >
        <div className='ui-pet-info-card__avatar'>
          <div className='ui-pet-info-card__avatar-image' id='petImageId'>
            <Avatar
              id={avatarElementId}
              noImageId={"petAddPhotoId"}
              onClick={onPetAvatarClick}
              img={petAvatar}
              className={
                showLostPetAlertIcon
                  ? 'ui-user-profile-card__avatar-outline'
                  : ''
              }
            />
            {showLostPetAlertIcon && (
              <Icon
                type='error'
                className='ui-pet-info-card__avatar-alert-icon'
              />
            )}
          </div>
        </div>
        {info}
        <PaperContent className='ui-pet-info-card__content'>
          <div
            id={petNameEditElementId}
            className={cn('ui-pet-info-card__pet-name')}
            role='button'
            onClick={onPetNameEditClick}
          >
            <Text type={Text.Types.headline}>{petName}</Text>
            {!isNewRegistered && <Icon type='edit' id="edit-icon"/>}
          </div>

          <div className='ui-pet-info-card__pet-links'>
            {isPetBeingTransferred ? (
              <>
                <div> Transfer pending </div>
                <Link color={Link.Colors.tertiary} {...cancelTransferLinkProps}>
                  Cancel transfer
                </Link>
              </>
            ) : (
              <>
                <Link color={Link.Colors.tertiary} {...removePetLinkProps}>
                  Remove pet
                </Link>
                <Link color={Link.Colors.tertiary} {...transferPetLinkProps}>
                  Transfer pet
                </Link>
              </>
            )}
          </div>

          {!isNewRegistered && (
            <div className='ui-pet-info-card__pet-details'>
              <Text>{petInfoLabel}</Text>
              {petMicroChipNumber && <Text className="ui-pet-info-card__microchip">{microChipLabel} {showTick && <Icon className="ui-pet-info-card__tick" type="check_circle" />}</Text>}
              {petPetWatchId && <Text className="ui-pet-info-card__microhip">
                <div className="campToolTipBox">
                  {petWatchLabel}
                  <div className="campToolTipTxt">
                    Your pet's 24Petwatch ID is a special code we've assigned to them in our system. It's different from their microchip number, which helps identify them if they ever get lost.
                  </div>
                </div>
              </Text>}
            </div>
          )}

          {showServicesProgress && (
            <ServiceProgress
              numberOfServicesEnrolled={numberOfServicesEnrolled}
              isNewRegistered={isNewRegistered}
              className={isNewRegistered ? 'registered' : ''}
            />
          )}

          {showRegisterMicrochipButton && !isNewRegistered && (
            <Button
              className='ui-pet-info-card__register-action'
              color={Button.Colors.tertiary}
              {...registerMicrochipButtonProps}
            >
              Add a microchip
            </Button>
          )}
          { showBasicServiceProtection && <Text className="ui-pet-info-card__basic-service">
            All registered microchips include 24Petwatch<br/>
            <Link color={Link.Colors.tertiary} {...basicServiceLinkProps}>Basic pet protection services</Link>
          </Text>}
        </PaperContent>

        {banner}
      </div>
    )
  }
}

export const ServiceProgress = ({
  numberOfServicesEnrolled,
  className,
  isNewRegistered
}) => {
  const [color, progressWidth, barOpacity] = getColorWidth(numberOfServicesEnrolled)
  const defaultText = isNewRegistered
    ? 'Pending'
    : `${numberOfServicesEnrolled} of 5 services enrolled`
  const isNewRegisteredBgColor = '#E5E5E5'
  const isNewRegisteredColor = '#5B5B5B'

  return (
    <div className={cn('ui-service-progress', className)}>
      <Text
        style={{
          color: isNewRegistered
            ? isNewRegisteredColor
            : numberOfServicesEnrolled
            ? '#fff'
            : color
        }}
        type={Text.Types.button}
      >
        {defaultText}
      </Text>
      <div
        className={cn('ui-service-progress__background')}
        style={{
          backgroundColor: isNewRegistered ? isNewRegisteredBgColor : color
        }}
      />
      {!isNewRegistered && (
        <div
          className='ui-service-progress__progress'
          style={{ backgroundColor: color, width: progressWidth, opacity: barOpacity }}
        />
      )}
    </div>
  )
}
ServiceProgress.propTypes = {
  numberOfServicesEnrolled: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  className: PropTypes.string,
  isNewRegistered: PropTypes.bool
}

const getColorWidth = (numberOfServicesEnrolled) => {
  switch (numberOfServicesEnrolled) {
    case 0:
      return ['#007680', '11px', 0.16]
    case 1:
      return ['#007680', '50px', 0.16]
    case 2:
      return ['#007680', '100px', 0.32]
    case 3:
      return ['#007680', '151px', 0.48]
    case 4:
      return ['#007680', '200px', 0.64]
    case 5:
      return ['#007680', '252px', 1]
    default:
      return ['#007680', '11px', 0.16]
  }
}

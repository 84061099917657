import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Paper, PaperHeadline, PaperContent, Button, Text } from '../../'

export class AddAnotherPetModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,

    registerNewPetButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    registerPetWithoutChipButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    showTransferPetRegistrationButton: PropTypes.bool,
    transferPetRegistrationProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    showTextContent: PropTypes.bool,
    showBackButton: PropTypes.bool,
    backButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  static defaultProps = {
    showTransferPetRegistrationButton: true,
    showTextContent: true,
    showBackButton: true
  }

  render() {
    const {
      showTextContent,
      showBackButton,
      open,
      onClose,
      registerNewPetButtonProps,
      registerPetWithoutChipButtonProps,
      showTransferPetRegistrationButton,
      transferPetRegistrationProps,
      backButtonProps
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-add-another-pet'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline>Add Another Pet</PaperHeadline>
            <PaperContent className='ui-add-another-pet__content'>
              {showTextContent && (
                <Text type={Text.Types.headlineBold}>
                  Is this a new or previously registered Pet?
                </Text>
              )}
              {showTextContent && <Text>You have three options below:</Text>}

              <Button
                className='ui-add-pet-button'
                color={Button.Colors.tertiary}
                {...registerNewPetButtonProps}
              >
                Register a new pet with a microchip
              </Button>

              <Button
                className='ui-add-pet-button'
                color={Button.Colors.tertiary}
                {...registerPetWithoutChipButtonProps}
              >
                Register a new pet without a microchip
              </Button>

              {showTransferPetRegistrationButton && (
                <Button
                  className='ui-add-pet-button'
                  color={Button.Colors.tertiary}
                  {...transferPetRegistrationProps}
                >
                  Transfer a pet’s registration and service
                </Button>
              )}

              {showBackButton && (
                <Button
                  className='ui-back-button'
                  color={Button.Colors.tertiary}
                  variant={Button.Variants.outlined}
                  {...backButtonProps}
                >
                  Back
                </Button>
              )}
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

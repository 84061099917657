import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class Icon extends React.PureComponent {
  static Sizes = Object.freeze({
    default: 'default',
    large: 'large'
  })

  static propTypes = {
    size: PropTypes.string,
    type: PropTypes.string,
    svgType: PropTypes.any,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    size: Icon.Sizes.default
  }

  render() {
    const { size, type, svgType, id, style, className, refProp, ...rest } = this.props

    const classes = getClasses(size)

    return (
      <div
        id={id}
        style={style}
        className={cn('ui-icon__container', classes, className)}
        ref={refProp}
        {...rest}
      >
        {type
          ? <i className='material-icons'>{type}</i>
          : <i className='material-icons'>{svgType}</i>
        }
      </div>
    )
  }
}

const getClasses = (size) => {
  switch (size) {
    case Icon.Sizes.large:
      return 'ui-icon--large'
    case Icon.Sizes.default:
    default:
      return 'ui-icon--default'
  }
}

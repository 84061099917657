import React from "react";
import {
  PetplaceShoppingCart,
  PageContent,
  TwoColumnContent,
  PetplaceAddPromoCodeModal,
  DashboardFooter,
  BreadCrumb,
  PetplaceLogoText
} from "../../../../UI-Component-Library";
import MakePaymentWrapper from "./make-payment-wrapper";
import { connect } from "react-redux";
import { AppUtils } from "../../../../utils/app-utils";
import { FeatureFlagUtils } from "../../../../utils/feature-flag-utils";
import { withRouter } from "react-router-dom";
import axios from "../../../../custom-axios";
import { ApiConstants } from "../../../../utils/api-constants";
import {
  backupCartData,
  clearCartData,
  saveCartData,
} from "../../../../redux/reducers/membership-purchase-reducer";
import {
  changeSelectedPetId
} from "../../../../redux/reducers/customer-profile-reducer";
import { DataLayerUtils } from "../../../../utils/dataLayer-utils";
import { PetWatchLogo } from "../../../../utils/svg-icons";
const queryString = require("query-string");

class Checkout extends React.Component {
  constructor() {
    super();
    this.state = {
      cartItems: [],
      isPromocodeModalOpen: false,
      promoCode: "",
      petCart: {},
      errorMessage: "",
      promoCodeErrorMessage: "",
      legalLines: [],
      animalId: undefined,
      planId: undefined,
    };
    this.cRef = React.createRef();
  }

  getCartData() {
    axios
      .processGet(
        `${ApiConstants.URL_GET_CART_DATA}${AppUtils.getUserRelationId()}`,
        this.cRef
      )
      .then((cartData) => {
        this.props.saveCartData(cartData);
        this.extractCartData(cartData);
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
        }
      });
  }


  componentDidMount() {
    window.scrollTo(0, 0);

    const parsed = queryString.parse(this.props.history.location.search);

    if( parsed ) {
      this.setState({
        animalId: parsed.animalID,
        planId: parsed.planID
      })
    }

    let cartData = this.props.cartData;
    if (!cartData) {
      this.getCartData();
    } else {
      this.extractCartData(cartData);
    }
  }

  extractCartData(cartData, isCheckout= false) {
    const orderItems = [];
    const petCart = {};
    if (cartData && cartData.orderLines) {
      for (let order of cartData?.orderLines) {
        if (!petCart[order.animalId]) {
          petCart[order.animalId] = [];
        }
        petCart[order.animalId].push({
          productName: AppUtils.getProductNameFromItemId(order.itemId, order.itemName),
          productCost: AppUtils.getConvertedPrice(order.unitPrice),
          ...order,
        });
      }
    }
    // Show only lpm bundle
    for (let key in petCart) {
      const filteredProducts = [];
      let lpmProduct;
      let lpmTotalPrice = 0;
      let lpmPlusProduct;
      let lpmPlusTotalPrice = 0;
      let winbackProduct, winbackTotalPrice = 0;
      for (let product of petCart[key]) {
        if (AppUtils.isLpmProduct(product)) {
          lpmProduct = product;
          continue;
        }
        if (product.itemType === "LPMBundleProduct") {
          lpmTotalPrice = lpmTotalPrice + product.unitPrice;
          continue;
        }

        //for lpmPlusMembership
        if (AppUtils.isLpmPlusProduct(product)) {
          lpmPlusProduct = product;
          continue;
        }
        if (AppUtils.isLpmPlusBundleProduct(product)) {
          lpmPlusTotalPrice = lpmPlusTotalPrice + product.unitPrice;
          continue;
        }

        // for winback offer
        if (AppUtils.isWinbackProduct(product)) {
          winbackProduct = product;
          continue;
        }
        if (AppUtils.isWinbackBundleProduct(product)) {
          winbackTotalPrice = winbackTotalPrice + product.unitPrice;
          continue;
        }
        filteredProducts.push(product);
      }
      if (lpmProduct) {
        lpmProduct.productCost = AppUtils.getConvertedPrice(lpmTotalPrice);
        filteredProducts.push(lpmProduct);
      }

      if (lpmPlusProduct) {
        lpmPlusProduct.productCost = AppUtils.getConvertedPrice(lpmPlusTotalPrice);
        filteredProducts.push(lpmPlusProduct);
      }

      if (winbackProduct) {
        winbackProduct.productCost = AppUtils.getConvertedPrice(winbackTotalPrice);
        filteredProducts.push(winbackProduct);
      }
      petCart[key] = filteredProducts;
    }
    for (let key in petCart) {
      if (key !== this.props?.selectedPetId) {
        this.props?.changeSelectedPetId(key);
      }
      orderItems.push({
        title: (isCheckout)
          ? ''
          : "For " +
            petCart[key]?.[0]?.animalName,
        items: petCart[key],
      });
    }
    if (isCheckout) {
      return orderItems;
    }
    DataLayerUtils.viewCartEvent(orderItems, cartData?.total);
    this.setState({
      cartItems: orderItems,
      petCart,
    });
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  applyPromoCode() {
    this.clearErrorMessage();
    if (AppUtils.isEmptyString(this.state.promoCode)) {
      this.setState({
        promoCodeErrorMessage: "Promocode should not be empty",
      });
      return;
    }
    axios
      .processGet(
        `${ApiConstants.URL_APPLY_PROMOCODE}${this.props.cartData.cartId}/${this.state.promoCode}`
      )
      .then((cartData) => {
        this.props.saveCartData(cartData);

        this.setState(
          {
            isPromocodeModalOpen: false,
          },
          () => {
            this.extractCartData(this.props.cartData);
          }
        );
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            promoCodeErrorMessage: errors[0],
          });
        }
      });
  }
  clearErrorMessage() {
    this.setState({
      errorMessage: "",
      promoCodeErrorMessage: "",
    });
  }
  removeOrder(petIndex, itemIndex) {
    this.clearErrorMessage();
    const petId = Object.keys(this.state.petCart)[petIndex];
    const items = this.state.petCart[petId];
    const foundItem = items.find((item, index) => index === itemIndex);
    DataLayerUtils.removeFromCartEvent(foundItem);
    axios
      .processGet(
        `${ApiConstants.URL_REMOVE_ORDER}${this.props.cartData.cartId}/${
          this.state.promoCode ? this.state.promoCode : "none"
        }/${foundItem.itemType}/${foundItem.basketLineId}`,
        this.cRef
      )
      .then((cartData) => {
        this.props.saveCartData(cartData);
        this.setState(
          {
            isPromocodeModalOpen: false,
          },
          () => {
            this.extractCartData(this.props.cartData);
          }
        );
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
        }
      });
  }
  render() {
    return (
      <>
        <PageContent>
          <PetplaceAddPromoCodeModal
            errorMessage={this.state.promoCodeErrorMessage}
            open={this.state.isPromocodeModalOpen}
            onClose={() => {}}
            promoCodeInputProps={{
              value: this.state.promoCode,
              id: "promo_code_input",
              name: "promoCode",
              // error: this.state.promoCodeError,
              // hint: this.state.promoCodeError
              //   ? ValidationMessages.EMAIL_VALIDATION_MESSAGE
              //   : "",
              onChange: this.handleInputChange,
            }}
            cancelButtonProps={{
              id: "promo_code_cancel_button",
              onClick: () => {
                this.setState({
                  promoCode: "",
                  isPromocodeModalOpen: false,
                  promoCodeErrorMessage: "",
                });
              },
            }}
            applyButtonProps={{
              id: "promo_code_apply_button",
              onClick: () => {
                this.applyPromoCode();
              },
            }}
          />
          <BreadCrumb
            isPetplace={true}
            breadCrumbs={[
              {
                name: "Product Selection",
                href: `${AppUtils.getPetPlaceBaseUrl()}/checkout/products?petId=${this.state.animalId}&plan=${this.state.planId}`,
                active: false,
                onClick: (e) => {
                  this.props.clearCartData();
                    window.location.replace(`${AppUtils.getPetPlaceBaseUrl()}/checkout/products?petId=${this.state.animalId}&plan=${this.state.planId}`)
                  e.preventDefault();
                }
              },
            ]}
          />
          <PetplaceLogoText 
            text="Complete Your Purchase"   
            logo={<PetWatchLogo />}
          />
          <div ref={this.cRef}>
            <TwoColumnContent
              isPetplace={true}
              leftColumn={
                <PetplaceShoppingCart
                  errorMessage={this.state.errorMessage}
                  cartItems={this.state.cartItems}
                  onRemoveClick={(index1, index2) => {
                    this.removeOrder(index1, index2);
                  }}
                  totalBeforeTax={AppUtils.getConvertedPrice(
                    this.props.cartData?.subTotal
                  )}
                  promoProps={{
                    id: "promoButton",
                    label: this.props.cartData?.promoDiscount?.isApplied
                      ? this.props.cartData?.promoDiscount.description
                      : "Add promo code",
                    onClick: () => {
                      if (!this.props.cartData?.promoDiscount?.isApplied) {
                        this.setState({
                          isPromocodeModalOpen: true,
                        });
                      }
                    },
                  }}
                  totalTaxes={AppUtils.getConvertedPrice(
                    this.props.cartData?.taxes
                  )}
                  subtotal={AppUtils.getConvertedPrice(
                    this.props.cartData?.total
                  )}
                  shippingAmount={AppUtils.getConvertedPrice(
                    this.props.cartData?.shipping
                  )}
                />
              }
              rightColumn={
                <MakePaymentWrapper
                  disableCheckoutButton={this.props.cartData?.total === 0}
                  extractCartData={this.extractCartData}
                  animalId={this.state.animalId}
                  planId={this.state.planId}
                ></MakePaymentWrapper>
              }
            ></TwoColumnContent>
          </div>
          <DashboardFooter />
        </PageContent>
      </>

    );
  }
}
const mapStateToProps = (state) => {
  const membershipPurchaseStore = state.membershipPurchaseStore;
  const customerProfileStore = state.customerProfileStore;

  return {
    cartData: membershipPurchaseStore.cartData,
    customerAnimals: customerProfileStore.customerAnimals,
    selectedPetId: customerProfileStore.selectedPetId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCartData: (data) => dispatch(saveCartData(data)),
    clearCartData: () => dispatch(clearCartData()),
    backupCartData: () => dispatch(backupCartData()),
    changeSelectedPetId: (id) => dispatch(changeSelectedPetId(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Checkout));

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperHeadline,
  PaperContent,
  Text,
  Option,
  Checkbox,
  Link,
  Icon
  // HeaderDivider
} from '../../'

// import {
//   LostPet,
//   DirectConnect,
//   MedAlert,
//   HelpLine,
//   PetCo,
//   Rover,
//   Dog,
//   Petcademy
// } from '../SvgIcons'
import { IconText } from '../../'

export class PurchaseMembershipPlanCard extends React.PureComponent {
  static Icons = Object.freeze({
    petMedAlert: 'petMedAlert',
    vetHelpLine: 'vetHelpLine',
    petco: 'petco',
    rover: 'rover',
    warranty: 'warranty',
    petcademy: 'petcademy'
  })

  static propTypes = {
    iconsToHide: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(PurchaseMembershipPlanCard.Icons))
    ),

    annualProtectionOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      onClick: PropTypes.func,
      priceFirstYear: PropTypes.string,
      priceYearAfter: PropTypes.string
    }),

    lifeTimeProtectionOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      onClick: PropTypes.func,
      price: PropTypes.string
    }),

    lifeTimeProtectionPlusOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      onClick: PropTypes.func,
      price: PropTypes.string
    }),

    petMedAlertOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      onClick: PropTypes.func,
      price: PropTypes.string,
      productName: PropTypes.string
    }),

    vetHelplineOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      onClick: PropTypes.func,
      price: PropTypes.string,
      productName: PropTypes.string
    }),

    autoRenewCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),

    concentCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),
    isUS: PropTypes.bool,
    membershipHeadingTextBody: PropTypes.string,
    // petMedAlertIconTextBody: PropTypes.string,
    // vetHelpLineIconTextBody: PropTypes.string,
    petName: PropTypes.string,
    banner: PropTypes.element,
    /**
     * use '' empty string to hide legalLine
     */
    legalLine: PropTypes.string,
    extraMessage: PropTypes.string,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    iconsToHide: [],
    isUS: true,
    showLegalLine2: true,
    membershipHeadingTextBody:
      "With our Lifetime and Annual Protection Memberships, our Lost Pet Recovery Specialists are available to help reunite lost pets with their families",
    petMedAlertIconTextBody:
      "Critical medical and behavioral information will be relayed to the shelter or vet they're brought to when found (complimentary for 1 year).",
    vetHelpLineIconTextBody:
      'Reach veterinary professionals anytime by phone, email or live chat (complimentary for 1 year, provided by whiskerDocs® ).'
  }

  render() {
    const {
      concentCheckboxProps,
      legalLine,
      banner,
      iconsToHide,
      lifeTimeProtectionOptionProps,
      lifeTimeProtectionPlusOptionProps,
      annualProtectionOptionProps,
      petMedAlertOptionProps,
      vetHelplineOptionProps,
      autoRenewCheckboxProps,
      membershipHeadingTextBody,
      // petMedAlertIconTextBody,
      // vetHelpLineIconTextBody,
      isUS,
      petName,
      extraMessage,
      id,
      className,
      style,
      refProp
    } = this.props

    const {
      priceFirstYear,
      priceYearAfter,
      ...restAnnualProtectionOptionProps
    } = annualProtectionOptionProps

    const {
      price: lifeTimePrice,
      ...restLifeTimeProtectionOptionProps
    } = lifeTimeProtectionOptionProps

    const {
      price: lifeTimePlusPrice,
      ...restLifeTimeProtectionPlusOptionProps
    } = lifeTimeProtectionPlusOptionProps

    const {
      price: petMedAlertPrice,
      ...restPetMedAlertOptionProps
    } = petMedAlertOptionProps

    const {
      price: vetHelpLinePrice,
      ...restVetHelplineOptionProps
    } = vetHelplineOptionProps

    const isAnnualProtectionActive = annualProtectionOptionProps.active;
    const isLpmActive = lifeTimeProtectionOptionProps.active;
    const isLpmPlusActive = lifeTimeProtectionPlusOptionProps.active
    const activeGrayBackground = (isAnnualProtectionActive ||
      isLpmActive ||
      isLpmPlusActive
    ) ? "" : "activeGrayBgCols";
    const annualActiveClass = isAnnualProtectionActive ? "ui-membership-card__active-col" : "";
    const lpmActiveClass = isLpmActive ? "ui-membership-card__active-col" : "";
    const lpmPlusActiveClass = isLpmPlusActive ? "ui-membership-card__active-col" : "";
    // const showIcon = createArrayIncludesValueCheck(iconsToHide)

    return (
      <Paper
        id={id}
        className={cn("ui-membership-card", className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>24Petwatch Membership</PaperHeadline>

        {banner}

        <PaperContent>
          <Text type={Text.Types.headlineBold} className='ui-membership-card__body'>
            Choose a membership that’s right for{' '}
            <span className='ui-membership-card__body-text'>{petName}</span>
          </Text>
          <Text className='ui-membership-card__body'>
            {membershipHeadingTextBody}
          </Text>

          <div className="ui-membership-card__table totalCols_4">
            <div className="ui-membership-card__each-row ui-membership-card__top-row">
                <div className="ui-membership-card__col ui-membership-card__label-col" data-name="label">
                  <div className="memberEachLabel"></div>
                </div>
              {!restAnnualProtectionOptionProps.hidden && (<div className={cn("ui-membership-card__col", "ui-membership-card__annual-col", annualActiveClass)} data-name="annual">
                <Option
                    className="ui-membership-card__annual_option"
                    label='Annual'
                    {...restAnnualProtectionOptionProps}
                  />
                  <p {...restAnnualProtectionOptionProps}><strong>{priceFirstYear}</strong> for the first year<br /> {priceYearAfter}/year thereafter</p>
                </div>)}
                <div className={cn("ui-membership-card__col", "ui-membership-card__lifetime-col", activeGrayBackground, lpmActiveClass)} data-name="lifeTime">
                <Option
                    className="ui-membership-card__lifetime-option"
                    label='Lifetime'
                    {...restLifeTimeProtectionOptionProps}
                  />
                  <p {...restLifeTimeProtectionOptionProps}><strong>{lifeTimePrice}</strong><br /> (one-time fee)</p>
                </div>
                <div className={cn("ui-membership-card__col", "ui-membership-card__lifetime-plus-col", lpmPlusActiveClass)} data-name="lifeTimePlus">
                <Option
                    className="ui-membership-card__lifetime-plus-option"
                    label='Lifetime Plus'
                    {...restLifeTimeProtectionPlusOptionProps}
                  />
                  <p {...restLifeTimeProtectionPlusOptionProps}><strong>{lifeTimePlusPrice}</strong><br /> (one-time fee)</p>
                </div>
            </div>
            <div className="ui-membership-card__each-row">
                <div className="ui-membership-card__col ui-membership-card__label-col" data-name="label">
                  <div className="memberEachLabel">
                      Lost Pet Support
                      <div className="campToolTipBox">
                        <i className="material-icons">info_outline</i>
                        <div className="campToolTipTxt">Access to specialists who begin the process to help you find your pet.</div>
                      </div>
                  </div>
                </div>
                {!restAnnualProtectionOptionProps.hidden && (<div {...restAnnualProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__annual-col", annualActiveClass)} data-name="annual"><i className="material-icons tickMartIcon">done</i></div>)}
                <div {...restLifeTimeProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-col", activeGrayBackground, lpmActiveClass)} data-name="lifeTime"><i className="material-icons tickMartIcon">done</i></div>
                <div {...restLifeTimeProtectionPlusOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-plus-col", lpmPlusActiveClass)} data-name="lifeTimePlus"><i className="material-icons tickMartIcon">done</i></div>
            </div>
            <div className="ui-membership-card__each-row">
                <div className="ui-membership-card__col ui-membership-card__label-col" data-name="label">
                  <div className="memberEachLabel">
                      DirectConnect 
                      <div className="campToolTipBox">
                        <i className="material-icons">info_outline</i>
                        <div className="campToolTipTxt">Connect with your pet's finder and arrange a quick, safe reunion.</div>
                      </div>
                  </div>
                </div>
                {!restAnnualProtectionOptionProps.hidden && (<div {...restAnnualProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__annual-col", annualActiveClass)} data-name="annual"><i className="material-icons tickMartIcon">done</i></div>)}
                <div {...restLifeTimeProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-col", activeGrayBackground, lpmActiveClass)} data-name="lifeTime"><i className="material-icons tickMartIcon">done</i></div>
                <div {...restLifeTimeProtectionPlusOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-plus-col", lpmPlusActiveClass)} data-name="lifeTimePlus"><i className="material-icons tickMartIcon">done</i></div>
            </div>
            <div className="ui-membership-card__each-row">
                <div className="ui-membership-card__col ui-membership-card__label-col" data-name="label">
                  <div className="memberEachLabel">
                      24/7 VetHelpline 
                      <div className="campToolTipBox">
                        <i className="material-icons">info_outline</i>
                          <div className="campToolTipTxt">Reach veterinary professionals anytime by phone, email or live chat for advice (provided by whiskerDocs®).</div>
                      </div>
                  </div>
              </div>
              {!restAnnualProtectionOptionProps.hidden && (<div {...restAnnualProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__annual-col", annualActiveClass)} data-name="annual"><i className="dashIcon">-</i></div>)}
              <div {...restLifeTimeProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-col", activeGrayBackground, lpmActiveClass)} data-name="lifeTime"><i className="material-icons tickMartIcon">done</i></div>
              <div {...restLifeTimeProtectionPlusOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-plus-col", lpmPlusActiveClass)} data-name="lifeTimePlus"><i className="material-icons tickMartIcon">done</i></div>
            </div>
            <div className="ui-membership-card__each-row">
                <div className="ui-membership-card__col ui-membership-card__label-col" data-name="label">
                  <div className="memberEachLabel">
                      24PetMedAlert® 
                      <div className="campToolTipBox">
                        <i className="material-icons">info_outline</i>
                        <div className="campToolTipTxt">Critical medical and behavioral information are relayed to the shelter or vet they're brought to.</div>
                      </div>
                  </div>
                </div>
                {!restAnnualProtectionOptionProps.hidden && (<div {...restAnnualProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__annual-col", annualActiveClass)} data-name="annual"><i className="dashIcon">-</i></div>)}
                <div {...restLifeTimeProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-col", activeGrayBackground, lpmActiveClass)} data-name="lifeTime"><i className="material-icons tickMartIcon">done</i></div>
                <div {...restLifeTimeProtectionPlusOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-plus-col", lpmPlusActiveClass)} data-name="lifeTimePlus"><i className="material-icons tickMartIcon">done</i></div>
            </div>
            <div className="ui-membership-card__each-row">
                <div className="ui-membership-card__col ui-membership-card__label-col" data-name="label">
                  <div className="memberEachLabel">
                      $30 Rover Coupon 
                      <div className="campToolTipBox">
                        <i className="material-icons">info_outline</i>
                        <div className="campToolTipTxt">
                          Save on pet-sitting and dog walking services from{' '} 
                          <span className='ui-membership-card__rover-link'>
                            <Link href='https://www.rover.com' target='_blank' color={Link.Colors.secondary}>
                              Rover.com
                            </Link>
                            <Icon type="open_in_new"></Icon>
                          </span>
                          .
                        </div>
                      </div>
                  </div>
                </div>
                {!restAnnualProtectionOptionProps.hidden && (<div {...restAnnualProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__annual-col", annualActiveClass)} data-name="annual"><i className="dashIcon">-</i></div>)}
                <div {...restLifeTimeProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-col", activeGrayBackground, lpmActiveClass)} data-name="lifeTime"><i className="material-icons tickMartIcon">done</i></div>
                <div {...restLifeTimeProtectionPlusOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-plus-col", lpmPlusActiveClass)} data-name="lifeTimePlus"><i className="material-icons tickMartIcon">done</i></div>
            </div>
            {isUS && <div className="ui-membership-card__each-row">
                <div className="ui-membership-card__col ui-membership-card__label-col" data-name="label">
                  <div className="memberEachLabel">
                      $25 Petco Coupon 
                      <div className="campToolTipBox">
                        <i className="material-icons">info_outline</i>
                        <div className="campToolTipTxt">Redeem for in-store purchases at Petco.</div>
                      </div>
                  </div>
                </div>
                {!restAnnualProtectionOptionProps.hidden && (<div {...restAnnualProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__annual-col", annualActiveClass)} data-name="annual"><i className="dashIcon">-</i></div>)}
                <div {...restLifeTimeProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-col", activeGrayBackground, lpmActiveClass)} data-name="lifeTime"><i className="material-icons tickMartIcon">done</i></div>
                <div {...restLifeTimeProtectionPlusOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-plus-col", lpmPlusActiveClass)} data-name="lifeTimePlus"><i className="material-icons tickMartIcon">done</i></div>
            </div>}
            <div className="ui-membership-card__each-row">
                <div className="ui-membership-card__col ui-membership-card__label-col" data-name="label">
                  <div className="memberEachLabel">
                      Lifetime Warranty ID Tag 
                      <div className="campToolTipBox">
                        <i className="material-icons">info_outline</i>
                        <div className="campToolTipTxt">Customized ID tags make it easier for your pet's finder to contact us and report your pet as found.</div>
                      </div>
                  </div>
                </div>
                {!restAnnualProtectionOptionProps.hidden && (<div {...restAnnualProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__annual-col", annualActiveClass)} data-name="annual"><i className="dashIcon">-</i></div>)}
                <div {...restLifeTimeProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-col", activeGrayBackground, lpmActiveClass)} data-name="lifeTime">
                  {isUS ? <i className="dashIcon">-</i> : <i className="material-icons tickMartIcon">done</i>}
                </div>
                <div {...restLifeTimeProtectionPlusOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-plus-col", lpmPlusActiveClass)} data-name="lifeTimePlus"><i className="material-icons tickMartIcon">done</i></div>
            </div>
            <div className="ui-membership-card__each-row">
                <div className="ui-membership-card__col ui-membership-card__label-col" data-name="label">
                  <div className="memberEachLabel">
                      Petcademy 
                      <div className="campToolTipBox">
                        <i className="material-icons">info_outline</i>
                        <div className="campToolTipTxt">Simple, easy to follow lessons with customized training for you and your pet.</div>
                      </div>
                  </div>
                </div>
                {!restAnnualProtectionOptionProps.hidden && (<div {...restAnnualProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__annual-col", annualActiveClass)} data-name="annual"><i className="dashIcon">-</i></div>)}
                <div {...restLifeTimeProtectionOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-col", activeGrayBackground, lpmActiveClass)} data-name="lifeTime"><i className="dashIcon">-</i></div>
                <div {...restLifeTimeProtectionPlusOptionProps} className={cn("ui-membership-card__col", "ui-membership-card__lifetime-plus-col", lpmPlusActiveClass)} data-name="lifeTimePlus"><i className="material-icons tickMartIcon">done</i></div>
            </div>
          </div>
          
          <Checkbox
            className='ui-membership-card__checkbox'
            label='Concent line'
            {...concentCheckboxProps}
          />

          {isAnnualProtectionActive && (<div className="ui-membership-card__addon">
            <div className="ui-membership-card__each-addon">
              <div className="ui-membership-card__addon-name">
                <h4>
                  {restPetMedAlertOptionProps.productName}®
                  <div className="campToolTipBox">
                    <i className="material-icons">info_outline</i>
                    <div className="campToolTipTxt">Store and share critical medical and behavioral information. Your first year is complimentary with a Lifetime Protection Membership.</div>
                  </div>
                </h4>
                <p>Store and share critical medical and behavioral information. Your first year is complimentary with a Lifetime Protection Membership.</p>
                <div className="ui-membership-card__addon-price"><strong>{petMedAlertPrice}</strong>/year</div>
              </div>
              <div className="ui-membership-card__addon-cart-wrap">
                <Option
                  label={(restPetMedAlertOptionProps.active) ? "Added" : "Add"}
                  {...restPetMedAlertOptionProps}
                />
                <div className="ui-membership-card__addon-price"><strong>{petMedAlertPrice}</strong>/year</div>
              </div>
            </div>
            <div className="ui-membership-card__each-addon">
              <div className="ui-membership-card__addon-name">
                <h4>
                  {restVetHelplineOptionProps.productName}
                  <div className="campToolTipBox">
                    <i className="material-icons">info_outline</i>
                    <div className="campToolTipTxt">Contact a veterinary professional any time or day by phone, email or live chat. Your first year is complimentary with a Lifetime Protection Membership.</div>
                  </div>
                </h4>
                <p>Contact a veterinary professional any time or day by phone, email or live chat. Your first year is complimentary with a Lifetime Protection Membership.</p>
                <div className="ui-membership-card__addon-price"><strong>{vetHelpLinePrice}</strong>/year</div>
              </div>
              <div className="ui-membership-card__addon-cart-wrap">
                <Option
                  label={(restVetHelplineOptionProps.active) ? "Added" : "Add"}
                  {...restVetHelplineOptionProps}
                />
                <div className="ui-membership-card__addon-price"><strong>{vetHelpLinePrice}</strong>/year</div>
              </div>
            </div>
          </div>)}

          {/* <div
            className={cn(
              'ui-membership-card__options__container',
              'ui-membership-card__options__container--long-text'
            )}
          >
            {!restLifeTimeProtectionPlusOptionProps.hidden && (
              <Option
                label='Lifetime Protection Membership Plus'
                {...restLifeTimeProtectionPlusOptionProps}
                description={
                  <Text>
                    <strong>{lifeTimePlusPrice}</strong> (one-time fee)
                  </Text>
                }
              />
            )}
            {!restLifeTimeProtectionOptionProps.hidden && (
              <Option
                label='Lifetime Protection Membership'
                {...restLifeTimeProtectionOptionProps}
                description={
                  <Text>
                    <strong>{lifeTimePrice}</strong> (one-time fee)
                  </Text>
                }
              />
            )}
            {!restAnnualProtectionOptionProps.hidden && (
              <Option
                label='Annual Protection Membership'
                {...restAnnualProtectionOptionProps}
                description={
                  <Text>
                    {priceFirstYear} for the first year, and{' '}
                    <strong>{priceYearAfter}/year</strong> thereafter
                  </Text>
                }
              />
            )}
          </div>

          <div className='ui-membership-card__icon-text__container'>
            <IconText
              svgIcon={<LostPet />}
              title='Lost Pet Recovery Specialists'
              body='Get 24/7 access to specialists who can quickly launch the process of finding your pet'
            />
            <IconText
              svgIcon={<DirectConnect />}
              title='DirectConnect'
              body='Connect with the person who found your pet, and arrange a quick, safe reunion'
            />
          </div>

          <Checkbox
            className='ui-membership-card__checkbox'
            label='Concent line'
            {...concentCheckboxProps}
          />

          <HeaderDivider className='ui-membership-card__header-divider'>
            {isAnnualProtectionActive
              ? 'Additional protection for Annual membership:'
              : 'You`ll also enjoy:'}
          </HeaderDivider>

          {isAnnualProtectionActive && (
            <div className={cn('ui-membership-card__options__container')}>
              <Option
                showCheckMark
                label={
                  <span>
                    24PetMedAlert<sup>®</sup> annual subscription
                  </span>
                }
                description={
                  <Text>
                    <strong>{petMedAlertPrice}</strong>/year
                  </Text>
                }
                {...restPetMedAlertOptionProps}
              />

              <Option
                showCheckMark
                label='24/7 Vet Helpline annual subscription'
                description={
                  <Text>
                    <strong>{vetHelpLinePrice}</strong>/year
                  </Text>
                }
                {...restVetHelplineOptionProps}
              />
            </div>
          )}

          {isLpmPlusMembershipActive && (<div className='ui-membership-card__icon-petcademy--container'>
            {showIcon(PurchaseMembershipPlanCard.Icons.petcademy) && (
              <IconText
                svgIcon={<Petcademy />}
                title={
                  <span>
                    Customized pet training for 1 year
                  </span>
                }
                body='Simple, easy to follow lessons with customized training for you and your pet (complimentary for 1 year)'
              />
            )}
          </div>)}

          <div className='ui-membership-card__icon-text--container'>
              {showIcon(PurchaseMembershipPlanCard.Icons.petMedAlert) && (
                <IconText
                  svgIcon={<MedAlert />}
                  title={
                    <span>
                      24PetMedAlert<sup>®</sup> for 1 year
                    </span>
                  }
                  body={petMedAlertIconTextBody}
                />
              )}

              {showIcon(PurchaseMembershipPlanCard.Icons.vetHelpLine) && (
                <IconText
                  svgIcon={<HelpLine />}
                  title='24/7 Vet Helpline for 1 year'
                  body={vetHelpLineIconTextBody}
                />
              )}
          </div>
          <div className='ui-membership-card__icon-text-new--container'>
            {showIcon(PurchaseMembershipPlanCard.Icons.petco) && (
              <IconText
                svgIcon={<PetCo />}
                title='$25 Petco Coupon'
                body='Redeem for in-store purchases at Petco with Pals Rewards member sign-up.'
              />
            )}

            {showIcon(PurchaseMembershipPlanCard.Icons.rover) && (
              <IconText
                svgIcon={<Rover />}
                title='$30 Rover Discount'
                body='Save on pet-sitting and dog walking services from Rover.com'
              />
            )}

            {showIcon(PurchaseMembershipPlanCard.Icons.warranty) && (
              <IconText
                svgIcon={<Dog />}
                title='Lifetime Warranty ID Tag'
                body='Get an ID tag customized with your pet’s name and unique microchip number.'
              />
            )}
          </div> */}

          <Checkbox
            className='ui-membership-card__checkbox'
            label={
              <>
                After one year, your complimentary access expires—auto-renew
                24PetMedAlert<sup>®</sup> and 24/7 Vet Helpline to keep enjoying
                these great benefits.
              </>
            }
            {...autoRenewCheckboxProps}
          />
          <br></br>
          {extraMessage && <Text type={Text.Types.body}>{extraMessage}</Text>}

          {legalLine && (
            <Text
              className='ui-membership-card__legal-line'
              type={Text.Types.caption}
            >
              {legalLine}
            </Text>
          )}
        </PaperContent>
      </Paper>
    )
  }
}

const createArrayIncludesValueCheck = (iconsToHide) => (iconName) =>
  !iconsToHide.includes(iconName)

import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '../../'

export const Hint = ({ hint, refProp, className }) => {
  if (typeof hint === 'string')
    return (
      <Text ref={refProp} className={className} type={Text.Types.caption}>
        {hint}
      </Text>
    )

  if (hint instanceof Array && hint.length > 0)
    return (
      <div ref={refProp} className={className}>
        {hint.map((h, i) => (
          <React.Fragment key={h}>
            <Text type={Text.Types.caption}>{h}</Text>
            {i + 1 !== hint.length && <br />}
          </React.Fragment>
        ))}
      </div>
    )

  return null
}
Hint.propTypes = {
  hint: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  className: PropTypes.string
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperHeadline, PaperContent, Text, Button } from '../../'

export class GoPaperlessCard extends React.PureComponent {
  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    optInToPaperLessButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    enableEmoji: PropTypes.bool
  }

  static defaultProps = {
    enableEmoji: true
  }

  render() {
    const {
      successMessage,
      errorMessage,
      optInToPaperLessButtonProps,
      id,
      className,
      style,
      refProp,
      enableEmoji
    } = this.props

    const emoji = (em) => (enableEmoji ? em : '')

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn('ui-go-paperless-card', className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>{`${emoji('🌲')} Go Paperless ${emoji(
          '🌳'
        )}`}</PaperHeadline>

        <PaperContent className={cn('ui-go-paperless-card__content')}>
          <Text>
            Receive your Policy Documents, Claims notifications and policy
            notifications electronically.
          </Text>

          {successMessage && (
            <Text
              type={Text.Types.bodyBold}
              className='ui-go-paperless-card__success-message'
            >
              {successMessage}
            </Text>
          )}

          {errorMessage && (
            <Text
              type={Text.Types.bodyBold}
              className='ui-go-paperless-card__error-message'
            >
              {errorMessage}
            </Text>
          )}
          <Button
            className='ui-go-paperless-card__action'
            {...optInToPaperLessButtonProps}
          >
            Opt-in to Paperless Delivery
          </Button>
        </PaperContent>
      </Paper>
    )
  }
}

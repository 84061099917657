import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text } from '../../'

export class ToggleButton extends React.PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    children: PropTypes.any
  }

  render() {
    const {
      active,
      onClick,
      id,
      className,
      style,
      refProp,
      children
    } = this.props

    const classes = getClasses(active)

    return (
      <button
        id={id}
        className={cn(classes, className)}
        style={style}
        ref={refProp}
        onClick={onClick}
      >
        <Text type={Text.Types.button}>{children}</Text>
      </button>
    )
  }
}

const getClasses = (active) => {
  const classes = ['ui-toggle-button']

  active && classes.push('ui-toggle-button--active')

  return classes
}

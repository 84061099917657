import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { IconButton, Text } from '../../'

export class Accordion extends React.PureComponent {
  static propTypes = {
    title: PropTypes.any,
    children: PropTypes.any,

    openContent: PropTypes.string,
    onContentOpenChange: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  constructor(props) {
    super(props)

    this.state = {
      open: this.props.openContent || false
    }
  }

  handleArrowClick = () => {
    typeof this.props.onContentOpenChange === 'function'
      ? this.props.onContentOpenChange()
      : this.setState({ open: !this.state.open })
  }

  render() {
    const { open } = this.state
    const {
      children,
      title,
      id,
      className,
      style,
      refProp,
      openContent
    } = this.props

    const _open = typeof openContent === 'boolean' ? openContent : open

    return (
      <div
        id={id}
        className={cn('ui-accordion', className)}
        style={style}
        ref={refProp}
      >
        <div
          className={cn('ui-accordion__title', {
            ['ui-accordion__title--opened']: _open
          })}
          role='button'
          onClick={this.handleArrowClick}
        >
          <Text>{title}</Text>
          <IconButton type={_open ? 'expand_less' : 'expand_more'} />
        </div>

        {_open && <div className={cn('ui-accordion__content')}>{children}</div>}
      </div>
    )
  }
}

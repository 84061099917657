import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Icon, Text } from '../../'

export class Avatar extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    img: PropTypes.string,

    id: PropTypes.string,
    noImageId: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { onClick, img, id, noImageId, className, style, refProp } = this.props

    return (
      <div
        id={id}
        className={cn('ui-avatar__container', className)}
        style={style}
        ref={refProp}
        role='button'
        onClick={onClick}
      >
        {img ? <Image img={img} /> : <NoImage id={noImageId} />}
      </div>
    )
  }
}

const Image = ({ img }) => (
  <div
    className={cn('ui-avatar', 'ui-avatar--image')}
    style={{ backgroundImage: `url(${img})` }}
  />
)
Image.propTypes = { img: PropTypes.string }

const NoImage = ({ id }) => (
  <div className={cn('ui-avatar', 'ui-avatar--no-image')}>
    <Icon id={id} size={Icon.Sizes.large} type='add_a_photo' />
    <Text type={Text.Types.caption}>Add a photo</Text>
  </div>
)

import React from 'react'
import cn from 'classnames'
import { PageHeaderProps, LogoSvg } from './RmPageHeaderComponents'

const MobilePageHeader = ({ logo, onLogoClick, id, className, style, refProp }) => {
  return (
    <div
      id={id}
      className={cn(
        'rm-ui-page-header',
        'rm-ui-page-header--mobile',
        className
      )}
      style={style}
      ref={refProp}
    >
      <div
        className='rm-ui-page-header__logo'
        role='button'
        onClick={onLogoClick}
      >
        {logo ? logo : <LogoSvg />}
      </div>
    </div>
  )
}
MobilePageHeader.propTypes = PageHeaderProps

export default MobilePageHeader

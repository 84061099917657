import React from 'react'
import PropTypes from 'prop-types'

import { Paper, PaperHeadline, PaperContent, Text, Link } from '../../'
import { CustomerService } from '../SvgIcons'

export class MembershipContactSupportCard extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { title, id, className, style, refProp } = this.props

    return (
      <Paper
        id={id}
        className={className}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>{title}</PaperHeadline>
        <PaperContent className='ui-membership-contact-support-card__content'>
          <CustomerService />

          <Text type={Text.Types.bodyBold}>
            Need to modify your account? We need to do this over the phone.
          </Text>

          <Text>
            Please call <Link href='tel:18665972424'>1-866-597-2424</Link> to
            add any features to your account.
          </Text>
        </PaperContent>
      </Paper>
    )
  }
}

import React from "react";
import {
  AnnualProtectionMembershipCard,
  OptionCheckbox,
} from "../../../UI-Component-Library";
import { connect } from "react-redux";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { AppUtils } from "../../../utils/app-utils";
import { MembershipPurchaseUtils } from "./membership-purchase.util";
import {
  addToShoppingCartItems,
  toggleAutoRenewCheckbox,
  changeDisableContentForUpgrade,
} from "../../../redux/reducers/membership-purchase-reducer";
import {
  setIsActiveMedAlert,
  setIsActiveVetHelpline,
} from "../../../redux/reducers/customer-profile-reducer";

class AnnualMembershipComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      banner: MembershipPurchaseUtils.noBanner(),
      subscriptionOptions: [],
      shouldShowBanner: false,
      strikePrice: false,
      data: {},
      enableContent: false,
    };
  }
  render() {
    return (
      <div ref={this.cRef}>
        <AnnualProtectionMembershipCard
          iconsToHide={
            AppUtils.isUsCountry()
              ? [AnnualProtectionMembershipCard.Icons.warranty]
              : [AnnualProtectionMembershipCard.Icons.petco]
          }
          strikePrice={this.state.strikePrice}
          legalLine={"Applicable taxes will be applied at checkout"}
          disableContent={!this.state.enableContent}
          banner={this.state.banner}
          checkboxProps={{
            disabled:
              !this.props.disableContentForUpgradeMap[this.props.selectedPetId],
            label: (
              <>
                After one year, your complimentary access expires—auto-renew
                24PetMedAlert<sup>®</sup> and 24/7 Vet Helpline to keep enjoying
                these great benefits at $19.95/year.
              </>
            ),
            checked: this.props.autoRenewCheckboxes?.[this.props.selectedPetId],
            onChange: (e) => {
              this.props.toggleAutoRenewCheckbox(this.props.selectedPetId);
            },
          }}
          subscriptionOptions={this.state.subscriptionOptions}
        />
      </div>
    );
  }
  isAllowedToChange(element) {
    if (element.expiredProduct) {
      this.setState({
        banner: MembershipPurchaseUtils.warningBanner(),
      });
      return false;
    }
    return true;
  }
  postAutoRenew(element) {
    this.setState({
      errorMessage: "",
    });
    if (!this.isAllowedToChange(element)) {
      return;
    }
    axios
      .processPost(ApiConstants.URL_SAVE_AUTO_RENEW_POST, this.cRef, {
        email: AppUtils.getUserEmail(),
        busRelAccount: AppUtils.getBusRelAccount(),
        animalId: this.props.selectedPetId,
        itemId: element.itemId,
        recId: element.recId,
        autoRenew: !element.autoRenew,
      })
      .then((response) => {
        this.fetchMembershipProductsOfPet(true);
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState(
            {
              errorMessage: errors[0],
            },
            () => {
              this.showBanner(false);
            }
          );
        }
      });
  }
  showBanner(success) {
    this.setState({
      banner: success
        ? MembershipPurchaseUtils.subscriptionUpdatedBanner()
        : MembershipPurchaseUtils.errorBanner(this.state.errorMessage),
    });
    setTimeout(() => {
      this.updateBanner();
    }, 5000);
  }
  updateBanner() {
    this.setState({
      banner: this.state.shouldShowBanner
        ? MembershipPurchaseUtils.offerBanner()
        : this.state.strikePrice
        ? MembershipPurchaseUtils.anotherBanner()
        : MembershipPurchaseUtils.noBanner(),
    });
  }
  getDetail(element) {
    if (element.expiredProduct) {
      return "Expired:" + element.expiryDate;
    } else if (element.autoRenew) {
      return "Renews:" + element.renewDate;
    } else {
      return "Will Expire:" + element.expiryDate;
    }
  }
  upgradeToLifetime() {
    this.props.changeDisableContentForUpgrade({
      petId: this.props.selectedPetId,
      disableContentForUpgrade: true,
    });
    this.setState(
      {
        disableContent: false,
      },
      () => {
        this.generateSubscriptionOptions(this.state.data, false);
      }
    );
    if (this.props.lpmMembership) {
      this.props.addToShoppingCartItems({
        selectedPetId: this.props.selectedPetId,
        item: {
          itemId: this.props.lpmMembership.itemId,
          itemType: this.props.lpmMembership.itemType,
          productName: AppUtils.getProductNameFromItemId(
            this.props.lpmMembership.itemId
          ),
          productCost: AppUtils.getConvertedPrice(
            this.props.lpmMembership.itemPrice
          ),
          bundleList: MembershipPurchaseUtils.getFilteredBundleList(
            this.props.lpmMembership.bundleList
          ),
        },
      });
    }
  }
  fetchMembershipProductsOfPet(showBanner) {
    axios
      .processGet(
        `${
          ApiConstants.URL_GET_MEMBERSHIP_PRODUCTS_OF_PET
        }/${AppUtils.getBusRelAccount()}/${this.props.selectedPetId}`,
        this.cRef
      )
      .then((response) => {
        const data = response.data;
        this.setState({
          data,
        });
        this.generateSubscriptionOptions(data, showBanner);
      });
  }
  generateSubscriptionOptions(data, showBanner) {
    if (!data || !data.productList) {
      return;
    }
    const subscriptionOptions = [];
    let vetHelpLine = data.productList.find((item) =>
      AppUtils.isEqualString(item.itemId, "WD Annual Membership")
    );
    let petMedAlert = data.productList.find((item) =>
      AppUtils.isEqualString(item.itemId, "PetMedInfo Fees")
    );

    if (vetHelpLine && petMedAlert) {
      if (vetHelpLine.autoRenew && petMedAlert.autoRenew) {
        this.setState({
          strikePrice: true,
        });
      } else {
        this.setState({
          shouldShowBanner: true,
        });
      }
    }

    data.productList.forEach((element) => {
      if (
        AppUtils.isEqualString(element.itemId, "PetMedInfo Fees") &&
        !element.expiredProduct
      ) {
        this.props.setIsActiveMedAlert(true);
      }
      if (
        AppUtils.isEqualString(element.itemId, "WD Annual Membership") &&
        !element.expiredProduct
      ) {
        this.props.setIsActiveVetHelpline(true);
      }
      subscriptionOptions.push({
        key: element.itemId,
        variant: MembershipPurchaseUtils.getVariant(
          element,
          this.state.enableContent
        ),
        onClick: (event) => {
          this.postAutoRenew(element);
        },
        showCheckMark: true,
        checked: element.autoRenew,
        label: AppUtils.getAlreadyPurchasedAnnualProductName(element.itemId),
        description: {
          price: AppUtils.getConvertedPrice(element.price),
          detail: this.getDetail(element),
          frequency: AppUtils.getProductFrequency(element.frequency),
        },
      });
    });
    subscriptionOptions.push({
      key: "annual-membership-option-3",
      isOneTimeShow: true,
      variant: this.state.enableContent
        ? OptionCheckbox.Variants.active
        : OptionCheckbox.Variants.inactive,
      onClick: () => {
        this.upgradeToLifetime();
      },
      showCheckMark: false,
      label: (
        <>
          <span
            style={{
              color: "#000000",
            }}
          >
            Upgrade
          </span>{" "}
          to Lifetime Protection Membership
        </>
      ),
      description: {
        price: AppUtils.getConvertedPrice(this.props.lpmMembership?.itemPrice),
      },
    });
    this.setState(
      {
        subscriptionOptions,
      },
      () => {
        if (showBanner) {
          this.showBanner(true);
        } else {
          this.updateBanner();
        }
      }
    );
  }
  componentDidMount() {
    this.setState({
      enableContent:
        this.props.disableContentForUpgradeMap[this.props.selectedPetId],
    });
    this.fetchMembershipProductsOfPet();
  }
  componentDidUpdate() {
    if (
      this.state.enableContent !==
      this.props.disableContentForUpgradeMap[this.props.selectedPetId]
    ) {
      this.setState(
        {
          enableContent:
            this.props.disableContentForUpgradeMap[this.props.selectedPetId],
        },
        () => {
          this.generateSubscriptionOptions(this.state.data, false);
        }
      );
    }
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  const membershipPurchaseStore = state.membershipPurchaseStore;
  return {
    selectedPetId: customerProfileStore.selectedPetId,
    autoRenewCheckboxes: membershipPurchaseStore.autoRenewCheckboxes,
    disableContentForUpgradeMap:
      membershipPurchaseStore.disableContentForUpgradeMap,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleAutoRenewCheckbox: (selectedPetId) =>
      dispatch(toggleAutoRenewCheckbox(selectedPetId)),
    addToShoppingCartItems: (item) => dispatch(addToShoppingCartItems(item)),
    setIsActiveMedAlert: (flag) => dispatch(setIsActiveMedAlert(flag)),
    setIsActiveVetHelpline: (flag) => dispatch(setIsActiveVetHelpline(flag)),
    changeDisableContentForUpgrade: (data) =>
      dispatch(changeDisableContentForUpgrade(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnualMembershipComponent);

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Modal, Button, Paper, PaperHeadline, PaperContent, Text } from '../../'

export class ConfirmTransferPetModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    successText: PropTypes.string,
    acceptButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    id: PropTypes.string,
    confirmTransferButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    cancelTransferButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }
  
  static defaultProps = {
    title: "Are you sure you want to transfer this pet?",
    acceptButtonText: "Yes",
    cancelButtonText: "Cancel",
    successText: "",
  }

  render() {
    const {
      open,
      onClose,
      className,
      id,
      title,
      errorMessage,
      acceptButtonText,
      cancelButtonText,
      successText,
      confirmTransferButtonProps,
      cancelTransferButtonProps
    } = this.props

    return (
      <Modal
        open={open}
        onClose={onClose}
        className={className}
        id={id}
        refProp
      >
        <div className='ui-confirm-transfer-pet-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline> Transfer pet </PaperHeadline>
            <PaperContent className='ui-confirm-transfer-pet-modal-content'>
              {errorMessage && (
                <Text
                  className='ui-confirm-transfer-pet-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}
              <Text type={Text.Types.bodyBold}>
              {title}
              </Text>
              {successText && <Text type={Text.Types.body} className="ui-confirm-transfer-pet-card__text">
                {successText}
              </Text> }
              <div className='ui-confirm-transfer-pet-modal__actions'>
                {!successText && (
                  <Button
                    type='button'
                    variant={Button.Variants.filled}
                    {...confirmTransferButtonProps}
                  >
                    {acceptButtonText}
                  </Button>
                )}
                <Button
                  variant={Button.Variants.outlined}
                  {...cancelTransferButtonProps}
                >
                  {cancelButtonText}
                </Button>
              </div>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

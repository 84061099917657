import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text } from '../Text'

export class PetplaceLogoText extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string,
    logo: PropTypes.element,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { text, logo, id, className, style, refProp } = this.props

    return (
      <div
        id={id}
        className={cn('ui-petplace-logo-text', className)}
        style={style}
        ref={refProp}
      >
        <div>{logo}</div>
        {text && (<Text>{text}</Text>)}
      </div>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  OptionCheckbox,
  HeaderDivider,
  Text,
  IconText,
  Button
} from '../..'
import { MedAlert, HelpLine, WinbackOffer } from '../SvgIcons'
import { createArrayIncludesValueCheck } from '../../utils'

export class WinbackMembershipCard extends React.PureComponent {
  static propTypes = {
    petMedAlertIconTextBody: PropTypes.string,
    vetHelpLineIconTextBody: PropTypes.string,
    renewButtonProps: PropTypes.shape({
      active: PropTypes.bool,
      onClick: PropTypes.func,
    }),
    expiryDate: PropTypes.string,
    offerPrice: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    petMedAlertIconTextBody:
      'Critical medical and behavioural information will be relayed to the shelter or vet they’re brought to when found',
    vetHelpLineIconTextBody:
      'Reach veterinary professionals anytime by phone, email or live chat.'
  }

  render() {
    const {
      id,
      className,
      style,
      petMedAlertIconTextBody,
      vetHelpLineIconTextBody,
      renewButtonProps,
      expiryDate,
      offerPrice,
      refProp
    } = this.props


    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn(
          'ui-winback-membership-card',
          className
        )}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>24/7 Vet Helpline and 24PetMedAlert</PaperHeadline>


        <PaperContent>
          <div className='ui-winback-membership-card--banner'>
             {<WinbackOffer />}
             <div className='ui-winback-membership-card--content'>
                <Text type={Text.Types.headline}>Save money, time and stress!</Text>
                <Text type={Text.Types.body}> 
                  Save 25% on these additional coverage!
                </Text>
                <Text type={Text.Types.body}>
                  Only {offerPrice} (reg $19.95) for 1 full year of access to both services. Offer ends {expiryDate}
                </Text>
                <Button
                  size={Button.Sizes.small}
                  variant={Button.Variants.outlined}
                  color={Button.Colors.tertiary}
                  {...renewButtonProps}
                >
                  Renew now
                </Button>
             </div>
          </div>

          <Text type={Text.Types.title} className='ui-winback-membership-card__upgrade-prompt'>
            Additional coverage:
          </Text>

          <div className={cn('ui-winback-membership-card__icons')}>
            <div className='ui-winback-membership-card__icons-text'>
              {<HelpLine />}
              <Text>
                <strong>24/7 Vet Helpline for 1 year</strong>
                <br />
                {vetHelpLineIconTextBody}
              </Text>
            </div>
            <div className='ui-winback-membership-card__icons-text'>
              {<MedAlert />}
              <Text>
                <strong>24PetMedAlert<sup>®</sup> for 1 year</strong>
                <br />
                {petMedAlertIconTextBody}
              </Text>
            </div>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class Divider extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
  }

  render() {
    const { id, className, style } = this.props

    return <div id={id} className={cn('ui-divider', className)} style={style} />
  }
}

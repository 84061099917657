import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  Form,
  Input,
  FormAction,
  Button,
  Text,
  Checkbox,
  Icon,
  BreadCrumb,
  Link
} from '../../'
import { Visa, MasterCard, Discover } from '../SvgIcons'

export class NewPaymentPage extends React.PureComponent {

  static propTypes = {
    errorMessage: PropTypes.string,
    onFormSubmit: PropTypes.func,
    warning1: PropTypes.string,
    warning2: PropTypes.string,
    useMailingAddressCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),
    useDifferentAddressCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),

    useCardForFutureCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),

    /**
     * Credit card number
     */
    creditCardNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Card holder name
     */
    creditCardHolderNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Expiry date
     */
    creditCardExpiryInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * CVV
     */
    creditCardCVVInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    /**
     * Phone number
     */
    phoneInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * First name
     */
    firstNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Last name
     */
    lastNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Country
     */
    countrySelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Address line 1
     */
    address1InputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Address line 2
     */
    address2InputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * City
     */
    cityInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * State
     */
    stateSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Zip code
     */
    zipInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    saveChangesButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool
    }),

    existingCardCheckbox: PropTypes.arrayOf(
      PropTypes.shape({
        checked: PropTypes.bool,
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.string,
        id: PropTypes.string,
        ccLastFour: PropTypes.string,
        expiryDate: PropTypes.string,
        cardType: PropTypes.string 
      })
    ),

    payWithDifferentCardOption: PropTypes.shape({
      hidden: PropTypes.bool,
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),

    breadCrumbList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        onClick: PropTypes.func,
        active: PropTypes.bool
      })
    ),
    backToDashboardLink: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    useMailingAddressCheckboxProps: {},
    useDifferentAddressCheckboxProps: {},
    showAllowInsurancePremium: true,
    showAllowMicrochipMembershipRenewal: true,
    showUseCreditCardForAllPolicies: true,
    // cartItems: [],
    // promoProps: {},
    // showPromoRow: true
    existingCardOptions: [
      {
        checked: true,
        onChange: () => { },
        ccLastFour: '1234',
        expiryDate: '10/23',
        cardType: 'visa'
      },
      {
        checked: false,
        onChange: () => { },
        ccLastFour: '5678',
        expiryDate: '01/24',
        cardType: 'mastercard'
      }
    ],
  }

  render() {
    const {
      errorMessage,
      onFormSubmit,

      useMailingAddressCheckboxProps,
      useDifferentAddressCheckboxProps,
      useCardForFutureCheckboxProps,
      creditCardNumberInputFieldProps,
      creditCardHolderNameInputFieldProps,
      creditCardExpiryInputFieldProps,
      creditCardCVVInputFieldProps,
      phoneInputFieldProps,
      warning1,
      warning2,
      firstNameInputFieldProps,
      lastNameInputFieldProps,
      address1InputFieldProps,
      address2InputFieldProps,
      cityInputFieldProps,
      stateSelectFieldProps,
      zipInputFieldProps,
      saveChangesButtonProps,

      existingCardOptions,
      payWithDifferentCardOption,
      breadCrumbList,
      backToDashboardLink,

      id,
      className,
      style,
      refProp
    } = this.props


    return (
      <div className='ui-new-payment-page'>
        <Paper
          id={id}
          className={cn('ui-new-payment-page-credit-card', className)}
          style={style}
          ref={refProp}
        >
          <PaperContent>
            <BreadCrumb breadCrumbs={breadCrumbList}></BreadCrumb>
            <Form onSubmit={onFormSubmit} className="ui-new-payment-page-credit-card__form">
              <Text type={Text.Types.headline}>Payment</Text>
              <Text type={Text.Types.body}>All transactions are secure and encrypted.</Text>
              {existingCardOptions.length <=0 && <Text type={Text.Types.headline} className="ui-new-payment-page__heading">
                Credit Card Info
                <span className='ui-new-payment-page__logos'>
                  <Visa></Visa>
                  <MasterCard></MasterCard>
                  <Discover></Discover>
                </span>
              </Text>}

              {errorMessage && (
                <Text
                  type={Text.Types.bodyBold}
                  className='ui-new-payment-page__error-message'
                >
                  {errorMessage}
                </Text>
              )}
              <div className='ui-make-a-payment-card__options'>
                {existingCardOptions.map(({ ccLastFour, expiryDate, cardType, ...rest }) => (
                  <Checkbox
                    className='ui-make-a-payment-card__checkbox'
                    key={`payment-option-${ccLastFour}`}
                    label={
                      <span className='ui-new-payment-page__existing-card'>
                        {getCardLogo(cardType)}
                        <span>•••• •••• •••• {ccLastFour} <br /> Exp: {expiryDate}</span>
                      </span>
                    }
                    {...rest}
                  />
                ))}
                {!payWithDifferentCardOption.hidden && (
                  <Checkbox
                    className='ui-make-a-payment-card__checkbox'
                    label='Add another payment'
                    {...payWithDifferentCardOption}
                  />
                )}
              </div>
              {(payWithDifferentCardOption.hidden || payWithDifferentCardOption.checked) && (
                <>
                  {!(existingCardOptions.length <=0) && <Text type={Text.Types.headline} className="ui-new-payment-page__heading">
                    New payment
                    <span className='ui-new-payment-page__logos'>
                      <Visa></Visa>
                      <MasterCard></MasterCard>
                      <Discover></Discover>
                    </span>
                  </Text>}
                  <div className='ui-new-payment-page__new__form__row'>
                    <Input
                      label='Credit card number'
                      placeholder='Credit card number'
                      {...creditCardNumberInputFieldProps}
                    />
                  </div>
                  <div className='ui-new-payment-page__new__form__row'>
                    <Input
                      label='Name on card'
                      placeholder='Name on card'
                      {...creditCardHolderNameInputFieldProps}
                    />
                  </div>

                  <div className='ui-new-payment-page__form__row'>
                    <Input label='Card expiry'  placeholder='Card expiry' {...creditCardExpiryInputFieldProps} />
                    <Input label='Security code'  placeholder='Security code' {...creditCardCVVInputFieldProps} />
                  </div>
                  <Checkbox
                    label='Save this card'
                    showSquareCheckbox={true}
                    {...useCardForFutureCheckboxProps}
                  />
                </>
              )}
              <Text type={Text.Types.headline}>Billing Address</Text>

              <Checkbox
                className='ui-make-a-payment-card__checkbox'
                label='Same as mailing address'
                {...useMailingAddressCheckboxProps}
              />
              <Checkbox
                className='ui-make-a-payment-card__checkbox'
                label='Use a different billing address'
                {...useDifferentAddressCheckboxProps}
              />

              {useDifferentAddressCheckboxProps?.checked && (
                <>
                  <div className='ui-new-payment-page__form__row'>
                    <Input label='First name' placeholder='Example: Sarah' {...firstNameInputFieldProps} />

                    <Input label='Last name' placeholder='Example: Smith' {...lastNameInputFieldProps} />
                  </div>

                  {/* <Select label='Country' {...countrySelectFieldProps} /> */}

                  <div className='ui-new-payment-page__form__row'>
                    <Input label='Address line 1' placeholder='Example: 123 Tornto Street' {...address1InputFieldProps} />

                    <Input label='Address line 2' placeholder='Example: Unite 2' {...address2InputFieldProps} />
                  </div>

                  <div className='ui-new-payment-page__form__row'>
                    <Input label='City' placeholder='Example: Toronto' {...cityInputFieldProps} />

                    <Input label='State/Province' placeholder='State/Province' {...stateSelectFieldProps} />
                  </div>

                  <div className='ui-new-payment-page__form__row'>
                    <Input label='Postal code' placeholder='Postal Code' {...zipInputFieldProps} />
                    <Input label='Phone Number' placeholder='###-###-#### ' {...phoneInputFieldProps} />
                  </div>
                </>
              )}

              <Text type={Text.Types.caption}>{warning2}</Text>
              <Text type={Text.Types.caption}>{warning1}</Text>
              <FormAction>
                <Button
                  className='ui-new-payment-card__button'
                  color={Button.Colors.tertiary}
                  type='submit'
                  {...saveChangesButtonProps}
                >
                  <Icon type="gpp_good"></Icon> Make a secure payment
                </Button>
              </FormAction>
              <span className='ui-new-payment-page__back-link'>
                <Icon type="arrow_back_ios"></Icon>
                <Link {...backToDashboardLink}>Return to dashboard</Link>
              </span>
            </Form>
          </PaperContent>
        </Paper>
        {/* <div className='ui-new-payment-page__shopping-cart'>
          <NewShoppingCart showHeader={false} {...shoppingCartProps}></NewShoppingCart>
        </div> */}
        
      </div>
    )
  }
}


const getCardLogo = (type) => {
  switch (type.toLowerCase()) {
    case 'visa':
      return <Visa></Visa>
    case 'mastercard':
      return <MasterCard></MasterCard>
    case 'discover':
      return <Discover></Discover>
    default:
      return <></>
  }
}
import { createSlice } from "@reduxjs/toolkit";
import { MembershipPurchaseUtils } from "../../components/pages/membership/membership-purchase.util";
import { AppUtils } from "../../utils/app-utils";
import { DataLayerUtils } from "../../utils/dataLayer-utils";

const MembershipPurchasereducer = createSlice({
  name: "membership-purchase",
  initialState: {
    cartTotal: 0,
    shoppingCartItems: [],
    petShoppingCart: {},
    cartData: undefined,
    disableLpmTags: {},
    autoRenewCheckboxes: {},
    selectedPlans: {},
    consentCheckboxes: {},
    selectedPetTagOptions: {},
    selectedByteTagRoundOptions: {},
    selectedByteTagSlideOptions: {},
    selectTagShapesMap: {},
    selectTagStylesMap: {},
    selectTagSizeMap: {},
    isVetHelplineSelectedMap: {},
    isPetMedAlertSelectedMap: {},
    promoCode: "",
    existingOrdersLoaded: false,
    winbackOfferAvailability: {},
    disableContentForUpgradeMap: {},
  },
  reducers: {
    clearCartData: (state, action) => {
      state.cartTotal = 0;
      state.shoppingCartItems = [];
      state.petShoppingCart = {};
      state.cartData = undefined;
      state.autoRenewCheckboxes = {};
      state.consentCheckboxes = {};
      state.selectedPlans = {};
      state.selectedPetTagOptions = {};
      state.selectedByteTagRoundOptions = {};
      state.selectedByteTagSlideOptions = {};
      state.disableLpmTags = {};
      state.isVetHelplineSelectedMap = {};
      state.isPetMedAlertSelectedMap = {};
      state.promoCode = "";
      state.existingOrdersLoaded = false;
      state.selectTagShapesMap = {};
      state.selectTagStylesMap = {};
      state.selectTagSizeMap = {};
      state.winbackOfferAvailability = {};
      state.disableContentForUpgradeMap = {};
    },
    backupBeforeRefresh: (state, action) => {
      localStorage.setItem("CartBeforeRefresh", JSON.stringify(state));
    },
    backupCartData: (state, action) => {
      localStorage.setItem(AppUtils.getUserRelationId(), JSON.stringify(state));
    },
    restoreData: (state, action) => {
      const refreshData = JSON.parse(localStorage.getItem("CartBeforeRefresh"));
      const parsed = refreshData
        ? refreshData
        : JSON.parse(localStorage.getItem(AppUtils.getUserRelationId()));
      if (parsed) {
        state.cartTotal = parsed.cartTotal;
        state.shoppingCartItems = parsed.shoppingCartItems;
        state.petShoppingCart = parsed.petShoppingCart;
        state.cartData = parsed.cartData;
        state.autoRenewCheckboxes = parsed.autoRenewCheckboxes;
        state.consentCheckboxes = parsed.consentCheckboxes;
        state.selectedPlans = parsed.selectedPlans;
        state.selectedPetTagOptions = parsed.selectedPetTagOptions;
        state.selectedByteTagRoundOptions = parsed.selectedByteTagRoundOptions || {};
        state.selectedByteTagSlideOptions = parsed.selectedByteTagSlideOptions || {};
        state.disableLpmTags = parsed.disableLpmTags;
        state.isVetHelplineSelectedMap = parsed.isVetHelplineSelectedMap;
        state.isPetMedAlertSelectedMap = parsed.isPetMedAlertSelectedMap;
        state.promoCode = parsed.promoCode;
        state.selectTagShapesMap = parsed.selectTagShapesMap;
        state.selectTagStylesMap = parsed.selectTagStylesMap;
        state.selectTagSizeMap = parsed.selectTagSizeMap;
        state.winbackOfferAvailability = parsed.winbackOfferAvailability || {};
        state.disableContentForUpgradeMap = parsed.disableContentForUpgradeMap;
      }
      localStorage.removeItem(AppUtils.getUserRelationId());
      localStorage.removeItem("CartBeforeRefresh");
    },
    changeDisableContentForUpgrade: (state, action) => {
      const petId = action.payload.petId;
      state.disableContentForUpgradeMap[petId] =
        action.payload.disableContentForUpgrade;
    },
    changeSeletectedTagShape: (state, action) => {
      const petId = action.payload.petId;
      const tagShape = action.payload.tagShape;
      state.selectTagShapesMap[petId] = tagShape;
    },
    changeSeletectedTagStyle: (state, action) => {
      const petId = action.payload.petId;
      const tagStyle = action.payload.tagStyle;
      state.selectTagStylesMap[petId] = tagStyle;
    },
    changeSeletectedTagSize: (state, action) => {
      const petId = action.payload.petId;
      const size = action.payload.size;
      state.selectTagSizeMap[petId] = size;
    },
    changeWinbackOfferAvailability: (state, action) => {
      const petId = action.payload.petId;
      const isAvailable = action.payload.isAvailable;
      state.winbackOfferAvailability[petId] = isAvailable;
    },
    setExistingOrdersLoaded: (state, action) => {
      state.existingOrdersLoaded = action.payload;
    },
    setPromoCodeApplied: (state, action) => {
      state.promoCode = action.payload;
    },
    changeCartTotal: (state, action) => {
      state.cartTotal = action.payload;
    },
    syncExistingOrder: (state, action) => {
      const item = action.payload.item;
      const petId = action.payload.selectedPetId;
      if (AppUtils.isTagProduct(item)) {
        const standardTagList = item.standardTagList;
        const lifetimeTagList = item.lifetimeTagList;
        const byteTagList = item.byteTagList || [];
        if (AppUtils.isBytetagOrder(item.itemId)) {
          if (item.itemName.toLowerCase().indexOf('round') !== -1) {
            state.selectedByteTagRoundOptions[petId] = "round";
          } else {
             state.selectedByteTagSlideOptions[petId] = "slide";
          }
          const tag = byteTagList.find((iItem) => {
            return AppUtils.isEqualString(iItem.itemId, item.itemId);
          })
          if (tag) {
            state.selectTagStylesMap[petId] = tag.color;
            state.selectTagSizeMap[petId] = tag.size;
          }
        } else {
          if (AppUtils.isLpmTagOrder(item.itemId)) {
            const tag = lifetimeTagList.find((iItem) => {
              return AppUtils.isEqualString(iItem.itemId, item.itemId);
            });
            state.selectedPetTagOptions[petId] = "lifetime";
            if (tag) {
              state.selectTagShapesMap[petId] = AppUtils.toLowerCase(
                tag.shape
              );
              state.selectTagStylesMap[petId] = AppUtils.toLowerCase(
                tag.design
              );
              state.selectTagSizeMap[petId] = AppUtils.toLowerCase(
                tag.size
              );
            }
          } else {
            const tag = standardTagList.find((iItem) => {
              return AppUtils.isEqualString(iItem.itemId, item.itemId);
            });
            state.selectedPetTagOptions[petId] = "standard";
            if (tag) {
              state.selectTagShapesMap[petId] = AppUtils.toLowerCase(
                tag.shape
              );
              state.selectTagStylesMap[petId] = AppUtils.toLowerCase(
                tag.color
              );
            }
          }
        }
      } else if (AppUtils.isAnnualProduct(item)) {
        state.selectedPlans[petId] = item.itemId;
        state.consentCheckboxes[petId] = item.autoRenew === 1;
      } else if (AppUtils.isLpmProduct(item)) {
        state.selectedPlans[petId] = item.itemId;
        state.disableContentForUpgradeMap[petId] = true;
        state.autoRenewCheckboxes[petId] = item.autoRenew === 1;
      } else if (AppUtils.isLpmPlusProduct(item)) {
        state.selectedPlans[petId] = item.itemId;
        state.disableContentForUpgradeMap[petId] = true;
        state.autoRenewCheckboxes[petId] = item.autoRenew === 1;
      } else if (AppUtils.isWinbackProduct(item)) {
        state.selectedPlans[petId] = item.itemId;
        state.winbackOfferAvailability[petId] = true;
      } else if (AppUtils.isAnnualAdditionalProduct(item)) {
        state.autoRenewCheckboxes[petId] = item.autoRenew === 1;
        if (AppUtils.isVetHelplineProduct(item)) {
          state.isVetHelplineSelectedMap[petId] = true;
        }
        if (AppUtils.isPetMedAlertAnnualProduct(item)) {
          state.isPetMedAlertSelectedMap[petId] = true;
        }
      }
    },
    addToShoppingCartItems: (state, action) => {
      const payload = action.payload;
      const isExistingItem = action.payload.isExistingItem;
      if (!isExistingItem) {
        state.promoCode = "";
      }
      if (AppUtils.isLpmBundleProduct(payload.item)) {
        return;
      }
      if (AppUtils.isLpmPlusBundleProduct(payload.item)) {
        return;
      }
      if (AppUtils.isWinbackBundleProduct(payload.item)) {
        return;
      }
      if (AppUtils.isLpmProduct(payload.item) && !AppUtils.isUsCountry()) {
        const petId = payload.selectedPetId;
        if (state.disableLpmTags[petId]) {
          state.disableLpmTags[petId] = true;
        } else {
          state.disableLpmTags[petId] = true;
        }
      }
      if (AppUtils.isLpmPlusProduct(payload.item)) {
        const petId = payload.selectedPetId;
        if (state.disableLpmTags[petId]) {
          state.disableLpmTags[petId] = true;
        } else {
          state.disableLpmTags[petId] = true;
        }
      }

      if (AppUtils.isAnnualProduct(payload.item) && !AppUtils.isUsCountry()) {
        const petId = payload.selectedPetId;
        if (state.disableLpmTags[petId]) {
          state.disableLpmTags[petId] = false;
        } else {
          state.disableLpmTags[petId] = false;
        }
      }
      if (state.petShoppingCart[payload.selectedPetId]) {
        const items = AppUtils.copyObject(
          state.petShoppingCart[payload.selectedPetId].items
        );
        let membershipProduct;
        let filtered = items.filter((item) => {
          if (
            !AppUtils.isEqualString(item.productName, payload.item.productName)
          ) {
            return true;
          } else {
            membershipProduct = item;
            return false;
          }
        });
        if (membershipProduct && payload.item.productCost === "") {
          filtered.push(membershipProduct);
        } else {
          filtered.push(payload.item);
        }
        state.petShoppingCart[payload.selectedPetId].items = filtered;
      } else {
        state.petShoppingCart = {
          ...state.petShoppingCart,
          [payload.selectedPetId]: {
            items: [payload.item],
          },
        };
      }
      state.shoppingCartItems.push(action.payload);
    },
    saveCartData: (state, action) => {
      state.cartData = action.payload;
      MembershipPurchaseUtils.saveCartDataInLocalStorage(action.payload);
    },
    toggleAutoRenewCheckbox: (state, action) => {
      const petId = action.payload;
      if (state.autoRenewCheckboxes[petId]) {
        state.autoRenewCheckboxes[petId] = !state.autoRenewCheckboxes[petId];
      } else {
        state.autoRenewCheckboxes[petId] = true;
      }
    },
    setIsVetHelplineSelected: (state, action) => {
      const petId = action.payload.petId;
      const isSelected = action.payload.isVetHelplineSelected;
      state.isVetHelplineSelectedMap[petId] = isSelected;
    },
    setIsPetMedAlertSelected: (state, action) => {
      const petId = action.payload.petId;
      const isSelected = action.payload.isPetMedAlertSelected;
      state.isPetMedAlertSelectedMap[petId] = isSelected;
    },
    toggleConsentCheckbox: (state, action) => {
      const petId = action.payload;
      if (state.consentCheckboxes[petId]) {
        state.consentCheckboxes[petId] = !state.consentCheckboxes[petId];
      } else {
        state.consentCheckboxes[petId] = true;
      }
    },
    removeFromShoppingCartItems: (state, action) => {
      const shouldSync = action.payload.shouldSync;
      const petIndex = action.payload.petIndex;
      const itemIndex = action.payload.itemIndex;
      const parsedState = AppUtils.copyObject(state.petShoppingCart);
      const petId = Object.keys(parsedState)[petIndex];
      const items = AppUtils.copyObject(parsedState[petId].items);
      const item = items[itemIndex];
      if (!AppUtils.isEmptyString(item.basketLineId)) {
        MembershipPurchaseUtils.removeItemFromDatabase(item.basketLineId);
      }
      state.promoCode = "";
      if (AppUtils.isLpmProduct(item)) {
        if (state.disableLpmTags[petId]) {
          state.disableLpmTags[petId] = false;
        }
        // Disable upgrading
        state.disableContentForUpgradeMap[petId] = false;
      }
      if (AppUtils.isLpmPlusProduct(item)) {
        if (state.disableLpmTags[petId]) {
          state.disableLpmTags[petId] = false;
        }
        // Disable upgrading
        state.disableContentForUpgradeMap[petId] = false;
      }
      let removeVetHelpline = false;
      let removePetMedAlert = false;
      let filtered = [];
      if (AppUtils.isAnnualProduct(item)) {
        removeVetHelpline = true;
        removePetMedAlert = true;
      }
      filtered = items.filter((item, index) => {
        let shouldAdd = true;
        if (index === itemIndex) {
          shouldAdd = false;
        }
        if (removeVetHelpline) {
          if (AppUtils.isVetHelplineProduct(item)) {
            shouldAdd = false;
          }
        }
        if (removePetMedAlert) {
          if (AppUtils.isPetMedAlertAnnualProduct(item)) {
            shouldAdd = false;
          }
        }
        return shouldAdd;
      });
      DataLayerUtils.removeFromCartEvent(item);

      if (!filtered || filtered.length === 0) {
        delete state.petShoppingCart[petId];
      } else {
        state.petShoppingCart[petId].items = filtered;
      }
      if (shouldSync) {
        if (AppUtils.isTagProduct(item)) {
          if(item.productName.toLowerCase().includes("slide")){
            state.selectedByteTagSlideOptions[petId] = "none";
          } else if(item.productName.toLowerCase().includes("round")){
            state.selectedByteTagRoundOptions[petId] = "none";
          } else {
            state.selectedPetTagOptions[petId] = "none";
          }
        } else if (AppUtils.isAnnualProduct(item)) {
          state.selectedPlans[petId] = undefined;
          state.isVetHelplineSelectedMap[petId] = false;
          state.isPetMedAlertSelectedMap[petId] = false;
        } else if (AppUtils.isLpmProduct(item)) {
          state.selectedPlans[petId] = undefined;
        } else if (AppUtils.isLpmPlusProduct(item)) {
          state.selectedPlans[petId] = undefined;
        } else if (AppUtils.isVetHelplineProduct(item)) {
          state.isVetHelplineSelectedMap[petId] = false;
        } else if (AppUtils.isPetMedAlertAnnualProduct(item)) {
          state.isPetMedAlertSelectedMap[petId] = false;
        }
      }
    },
    changeSelectedPlan: (state, action) => {
      const petId = action.payload.petId;
      const selectedPlan = action.payload.selectedPlan;
      if (state.selectedPlans[petId]) {
        state.selectedPlans[petId] = selectedPlan;
      } else {
        state.selectedPlans[petId] = selectedPlan;
      }
    },
    changeSelectedPetTagOption: (state, action) => {
      const petId = action.payload.petId;
      const selectedPetTagOption = action.payload.selectedPetTagOption;
      if (state.selectedPetTagOptions[petId]) {
        state.selectedPetTagOptions[petId] = selectedPetTagOption;
      } else {
        state.selectedPetTagOptions[petId] = selectedPetTagOption;
      }
    },
    changeSelectedByteTagRoundOption: (state, action) => {
      const petId = action.payload.petId;
      const selectedByteTagRoundOptions = action.payload.selectedByteTagRoundOptions;
      if (state.selectedByteTagRoundOptions[petId]) {
        state.selectedByteTagRoundOptions[petId] = selectedByteTagRoundOptions;
      } else {
        state.selectedByteTagRoundOptions[petId] = selectedByteTagRoundOptions;
      }
    },
    changeSelectedByteTagSlideOption: (state, action) => {
      const petId = action.payload.petId;
      const selectedByteTagSlideOptions = action.payload.selectedByteTagSlideOptions;
      if (state.selectedByteTagSlideOptions[petId]) {
        state.selectedByteTagSlideOptions[petId] = selectedByteTagSlideOptions;
      } else {
        state.selectedByteTagSlideOptions[petId] = selectedByteTagSlideOptions;
      }
    },
  },
});

export const {
  clearCartData,
  changeCartTotal,
  addToShoppingCartItems,
  removeFromShoppingCartItems,
  saveCartData,
  toggleAutoRenewCheckbox,
  changeSelectedPlan,
  changeSelectedPetTagOption,
  changeSelectedByteTagRoundOption,
  changeSelectedByteTagSlideOption,
  toggleConsentCheckbox,
  setIsVetHelplineSelected,
  setIsPetMedAlertSelected,
  syncExistingOrder,
  setPromoCodeApplied,
  changeSeletectedTagShape,
  changeSeletectedTagStyle,
  changeSeletectedTagSize,
  setExistingOrdersLoaded,
  changeDisableContentForUpgrade,
  backupCartData,
  restoreData,
  backupBeforeRefresh,
  changeWinbackOfferAvailability,
} = MembershipPurchasereducer.actions;
export default MembershipPurchasereducer.reducer;

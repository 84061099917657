import React from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Form,
  Text,
  ToggleButtonGroup,
} from '../../'

export class ConsentPreferenceModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onFormSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
    headline: PropTypes.string,
    submitButtonText: PropTypes.string,
    consentTextBodyProp: PropTypes.any,
    consentWithdrawProps: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
            })
        ),
        label: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        id: PropTypes.string
    }),
    saveButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  static defaultProps = {
    headline: "Consent Form",
    submitButtonText: "Save changes",
    consentTextBodyProp: (
      <>
          With your 24Pet® microchip, Pethealth Services (USA) Inc.
          may offer you free lost pet services, as well as exclusive offers,
          promotions and the latest information from 24Pet regarding microchip
          services. Additionally, C&F Insurance Agency, Inc. may offer you promotions
          and the latest information from 24Petprotect™ regarding pet insurance
          services and products.
          < br /> <br />
          By clicking “I agree”, Pethealth Services (USA) Inc. and C&F Insurance
          Agency, Inc. may contact you via commercial electronic messages,
          automatic telephone dialing systems, pre-recorded/automated messages or
          text messages at the telephone number provided above, including your mobile
          number. These calls or emails are not a condition of the purchase of any
          goods or services.
          < br /> <br />
          You understand that if you choose not to provide your consent, you will
          not receive the above-mentioned communications or free lost pet services,
          which includes being contacted with information in the event that your pet
          goes missing.
          < br /> <br />
          You may withdraw your consent at any time.
      </>
    )
  }

  render() {
    const {
      open,
      onClose,
      errorMessage,
      headline,
      submitButtonText,
      consentTextBodyProp,
      consentWithdrawProps,
      saveButtonProps,
      onFormSubmit
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-consent-preference'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline>{headline}</PaperHeadline>
            <PaperContent>
              {errorMessage && (
                <Text
                  className='ui-consent-preference__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}
              <Form onSubmit={onFormSubmit}>
                <Text type={Text.Types.body} className="ui-consent-preference-text">
                  { consentTextBodyProp }
                </Text>
                <ToggleButtonGroup
                    className="ui-consent-preference-toggle-button"  
                    label=''
                    {...consentWithdrawProps}
                />
                <div className='ui-consent-preference__actions'>
                  <div>
                    <Button
                      type='submit'
                      color={Button.Colors.tertiary}
                      {...saveButtonProps}
                    >
                      {submitButtonText}
                    </Button>
                  </div>
                </div>
              </Form>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

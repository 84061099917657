import React, { Component } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import ShoppingCartWrapper from "./shopping-cart-wrapper";
import { TwoColumnContent, NewPaymentPage, NewShoppingCart } from "../../../UI-Component-Library";
import { ValidationMessages } from "../../../utils/validation-messages";
import { AppUtils } from "../../../utils/app-utils";
import { PaymentUtils } from "../user-profile/payment-settings/payment-utils";
import { AppConstants } from "../../../utils/app-constants";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { connect } from "react-redux";
import { saveCartData, clearCartData, } from "../../../redux/reducers/membership-purchase-reducer";
import { MembershipPurchaseUtils } from "../membership/membership-purchase.util";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
class PaymentContainer extends Component {
  constructor() {
    super();
    this.state = {
      cartId: "",
      isUsCountry: AppUtils.getCountryCode().toLowerCase() === "us",
      cardNumber: "",
      cardExpiry: "",
      cardHolderName: "",
      cvv: "",
      phoneNumber: "",
      firstname: "",
      lastname: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: AppUtils.getCountryCode()?.toLowerCase() === "us" ? "us" : "ca",
      state: "",
      zipcode: "",
      useCardForFuture: false,
      useMailingAddress: false,
      useDifferentMailingAddress: false,
      shouldDisplayMessage: false,
      selectedCard: "",
      existingCards: [],
      isPayingWithDifferentCard: false,
      errors: {
        cardHolderNameError: false,
        cardNumberError: false,
        cardExpirtyError: false,
        cvvError: false,
        phoneNumberError: false,
        firstNameError: false,
        lastNameError: false,
        addressLine1Error: false,
        cityError: false,
        stateError: false,
        zipCodeError: false,
      },
    };
    this.cRef = React.createRef();
  }
  clearErrors() {
    this.setState({
      errors: { ...PaymentUtils.getDefaultData().errors },
    });
  }

  handleUseMailingAddressChange(event) {
    this.clearErrors();
    this.setState({
      useMailingAddress: event.target.checked,
      useDifferentMailingAddress: false,
    });
  }

  handleUseDifferentMailingAddressChange(event) {
    this.clearErrors();
    this.setState({
      useMailingAddress: false,
      useDifferentMailingAddress: event.target.checked,
    });
  }

  handleUseCardForFuture(event) {
    this.setState({
      useCardForFuture: event.target.checked,
    });
  }

  handleNewCardSelection() {
    this.handleCardSelection("", true, !this.state.isPayingWithDifferentCard);
  }

  handleCardSelection(
    tokenId,
    resetAll = false,
    isPayingWithDifferentCard = false
  ) {
    const existingCards = this.state.existingCards.map((card) => {
      return {
        ...card,
        checked: AppUtils.isEqualString(card.tokenId, tokenId) && !resetAll,
      };
    });
    this.setState({
      existingCards,
      isPayingWithDifferentCard: isPayingWithDifferentCard,
    });
  }

  payWithNewCard() {
    const cartData = this.props.cartData;
    const orderItems = this.props.extractCartData(cartData, true);

    let cardExpiryMonth, cardExpiryYear;

    // existing card
    const selectedCard = this.state.existingCards.find(
      (card) => card.checked === true
    );

    if (selectedCard && selectedCard.length !== 0) {
      axios
      .processPost(
        `${ApiConstants.URL_CHECKOUT_SHOPPING_CART}${this.props.cartData.cartId}`,
        this.cRef,
        {
          token: selectedCard.tokenId,
          cardType: selectedCard.cardType,
          maskedCardNumber: selectedCard.ccLastFour,
          countrycode: AppUtils.getCountryCode(),
        }
      )
      .then((response) => {
        this.props.history.push({
          pathname: "/pages/payment/success",
          state: orderItems
        });
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
        }
      });
    }


    if (this.state?.cardExpiry && this.state?.cardExpiry.length > 0) {
      const splitted = this.state.cardExpiry.split("/");
      if (splitted && splitted.length === 2) {
        cardExpiryMonth = splitted[0];
        cardExpiryYear = splitted[1];
      }
    }
    const errors = {
      ...PaymentUtils.getDefaultData().errors,
    };
    if (AppUtils.isEmptyString(this.state.cardHolderName)) {
      errors.cardHolderNameError = true;
    }
    if (
      AppUtils.isEmptyString(this.state.cardNumber) ||
      !AppUtils.isOnlyDigits(this.state.cardNumber)
    ) {
      errors.cardNumberError = true;
    } else if (!MembershipPurchaseUtils.isValidNewCard(this.state.cardNumber)) {
      errors.cardNotExpectedError = true;
    }
    if (
      AppUtils.isEmptyString(this.state.cvv) ||
      !AppUtils.isOnlyDigits(this.state.cvv)
    ) {
      errors.cvvError = true;
    }
    if (
      !AppUtils.isValidExpiry(this.state.cardExpiry) ||
      cardExpiryMonth < 1 ||
      cardExpiryMonth > 12
    ) {
      errors.cardExpirtyError = true;
    }
    if (!this.state.useMailingAddress) {
      if (!AppUtils.isValidPhoneNumber(this.state.phoneNumber)) {
        errors.phoneNumberError = true;
      }
      if (AppUtils.isEmptyString(this.state.firstname)) {
        errors.firstNameError = true;
      }
      if (AppUtils.isEmptyString(this.state.lastname)) {
        errors.lastNameError = true;
      }
      if (
        AppUtils.isEmptyString(this.state.zipcode) ||
        !AppUtils.isValidZipCode(this.state.zipcode)
      ) {
        errors.zipCodeError = true;
      }
      if (AppUtils.isEmptyString(this.state.state)) {
        errors.stateError = true;
      }
      if (AppUtils.isEmptyString(this.state.city)) {
        errors.cityError = true;
      }
      if (AppUtils.isEmptyString(this.state.addressLine1)) {
        errors.addressLine1Error = true;
      }
    }

    this.setState({
      errors,
    });
    let isValid = true;
    for (let key in errors) {
      if (errors[key]) {
        isValid = false;
        break;
      }
    }
    if (isValid) {
      axios
        .processPost(
          `${ApiConstants.URL_CHECKOUT_WITH_NEW_CARD}${this.state.cartId}`,
          this.cRef,
          {
            countrycode: AppUtils.getCountryCode(),
            cardType: "string",
            cardHolderName: this.state.cardHolderName,
            cardNumber: this.state.cardNumber,
            cardExpiryDate: "string",
            cardExpiryMonth: cardExpiryMonth,
            cardExpiryYear: cardExpiryYear,
            cvv: this.state.cvv,
            isUseMailAdddress: this.state.useMailingAddress,
            isForFutureUse: this.state.useCardForFuture,
            address: {
              firstname: this.state.firstname,
              lastname: this.state.lastname,
              country: this.state.country,
              addressLine1: this.state.addressLine1,
              addressLine2: this.state.addressLine2,
              city: this.state.city,
              stateProvince: this.state.state,
              postalCode: this.state.zipcode,
              phoneNumber: this.state.phoneNumber,
              emailAddress: "",
            },
          }
        )
        .then((response) => {
          this.props.history.push({
            pathname: "/pages/payment/success",
            state: orderItems
          });
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            AppUtils.focusOnDiv("new-payment-page-top");
            this.setState({
              errorMessage: errors[0],
            });
          }
        });
    } else {
      AppUtils.focusOnDiv("new-payment-page-top");
    }
  }

  fetchCreditCards() {
    axios
      .processGet(
        `${ApiConstants.URL_DISPLAY_CC_CARDS}${AppUtils.getBusRelAccount()}`,
        this.cRef
      )
      .then((response) => {
        const cards = response.data;
        const existingCards = [];
        cards.forEach((card) => {
          existingCards.push({
            checked: false,
            onChange: () => {
              this.handleCardSelection(card.tokenId);
            },
            tokenId: card.tokenId,
            cardType: card.paymMode,
            ccLastFour: card.ccLastFour,
            expiryDate: card.ccExpiryDate,
          });
        });
        this.setState({
          existingCards,
        });
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
        }
      });
  }
  render() {
    return (
      <div id="new-payment-page-top" ref={this.cRef}>
        <NewPaymentPage
          errorMessage={this.state.errorMessage}
          breadCrumbList={[
            {
              name: "Dashboard",
              active: false,
              className: "new-payment-link-override",
              onClick: (e) => {
                this.props.clearCartData();
                this.props.history.push("/pages/dashboard");
                e.preventDefault();
              }
            },
            {
              name: "Payment",
              active: true,
              onClick: (e) => {}
            }
          ]}
          warning1={FeatureFlagUtils.shouldShowWarningMessage() ? (
            <>
              <strong>WARNING:</strong> It is a crime to knowingly provide
              false, incomplete, or misleading information to an insurance
              company for the purpose of defrauding the company.
            </>
          ) : (
              ""
          )
          }
          warning2={
            this.state.shouldDisplayMessage &&
            !this.state.useCardForFuture ? (
              <>
                <strong>WARNING:</strong> You purchased auto-renewable
                products but have not saved a card. To ensure no interruption
                to your service, keep a payment method saved.
              </>
            ) : (
              ""
            )
          }
          onFormSubmit={() => { }}
          existingCardOptions={this.state.existingCards}
          payWithDifferentCardOption={{
            hidden: this.state.existingCards?.length > 0 ? false : true,
            checked: this.state.isPayingWithDifferentCard,
            onChange: () => {
              this.handleNewCardSelection();
            },
          }}
          useMailingAddressCheckboxProps={{
            checked: this.state.useMailingAddress,
            onChange: (e) => {
              this.handleUseMailingAddressChange(e);
            },
          }}
          useDifferentAddressCheckboxProps={{
            checked: this.state.useDifferentMailingAddress,
            onChange: (e) => {
              this.handleUseDifferentMailingAddressChange(e);
            },
          }}
          useCardForFutureCheckboxProps={{
            checked: this.state.useCardForFuture,
            onChange: (e) => {
              this.handleUseCardForFuture(e);
            },
          }}
          creditCardNumberInputFieldProps={{
            id: "NPS_cardNumber",
            name: "cardNumber",
            maxLength: 16,
            value: this.state.cardNumber,
            error:
              this.state?.errors?.cardNumberError ||
              this.state?.errors?.cardNotExpectedError,
            hint: MembershipPurchaseUtils.getCardErrorMessage(
              this.state?.errors?.cardNumberError,
              this.state?.errors?.cardNotExpectedError
            ),
            onChange: (event) => {
              if (AppUtils.isOnlyDigitsOrEmptyString(event.target.value)) {
                this.handleInputChange(event);
              }
            },
          }}
          creditCardHolderNameInputFieldProps={{
            id: "PS_cardHolderName",
            name: "cardHolderName",
            maxLength: 30,
            error: this.state?.errors?.cardHolderNameError,
            hint: this.state?.errors?.cardHolderNameError
              ? "Card holder name" + ValidationMessages.IS_REQUIRED
              : "",
            value: this.state.cardHolderName,
            onChange: this.handleInputChange,
          }}
          creditCardExpiryInputFieldProps={{
            id: "PS_cardExpiry",
            name: "cardExpiry",
            value: this.state.cardExpiry,
            placeholder: "MM/YY",
            error: this.state?.errors?.cardExpirtyError,
            hint: this.state?.errors?.cardExpirtyError
              ? ValidationMessages.INVALID_CARD_EXPIRY
              : "",
            onChange: (e) => {
              this.handleCardExpiryChange(e);
            },
          }}
          creditCardCVVInputFieldProps={{
            id: "PS_cvv",
            name: "cvv",
            maxLength: 4,
            error: this.state?.errors?.cvvError,
            hint: this.state?.errors?.cvvError
              ? "CVV" + ValidationMessages.IS_REQUIRED
              : "",
            value: this.state.cvv,
            onChange: (event) => {
              if (AppUtils.isOnlyDigitsOrEmptyString(event.target.value)) {
                this.handleInputChange(event);
              }
            },
          }}
          phoneInputFieldProps={{
            name: "phoneNumber",
            id: "PS_phoneNumber",
            value: this.state.phoneNumber,
            error: this.state?.errors?.phoneNumberError,
            hint: this.state?.errors?.phoneNumberError
              ? ValidationMessages.INVALID_PHONE_NUMBER
              : "",
            onChange: (event) => {
              if (AppUtils.isValidPhoneNumberCharacters(event.target.value)) {
                this.handlePhoneNumber(event);
              }
            },
            maxLength: "12",
          }}
          firstNameInputFieldProps={{
            name: "firstname",
            id: "PS_firstname",
            maxLength: 30,
            error: this.state?.errors?.firstNameError,
            hint: this.state?.errors?.firstNameError
              ? "Firstname" + ValidationMessages.IS_REQUIRED
              : "",
            value: this.state.firstname,
            onChange: this.handleInputChange,
          }}
          lastNameInputFieldProps={{
            name: "lastname",
            id: "PS_lastname",
            maxLength: 30,
            placeholder: "Last Name",
            error: this.state?.errors?.lastNameError,
            hint: this.state?.errors?.lastNameError
              ? "Lastname" + ValidationMessages.IS_REQUIRED
              : "",
            value: this.state.lastname,
            onChange: this.handleInputChange,
          }}
          countrySelectFieldProps={{
            name: "country",
            id: "PS_country",
            value: this.state.country,
            onChange: this.handleInputChange,
            options: AppConstants.COUNTRIES,
            disabled: true,
          }}
          address1InputFieldProps={{
            name: "addressLine1",
            id: "PS_addressLine1",
            maxLength: 30,
            error: this.state?.errors?.addressLine1Error,
            hint: this.state?.errors?.addressLine1Error
              ? "Address" + ValidationMessages.IS_REQUIRED
              : "",
            value: this.state.addressLine1,
            onChange: this.handleInputChange,
          }}
          address2InputFieldProps={{
            name: "addressLine2",
            id: "PS_addressLine2",
            maxLength: 30,
            value: this.state.addressLine2,
            placeholder: "Address Line 2",
            onChange: this.handleInputChange,
          }}
          cityInputFieldProps={{
            name: "city",
            id: "PS_city",
            maxLength: 30,
            error: this.state?.errors?.cityError,
            hint: this.state?.errors?.cityError
              ? "City" + ValidationMessages.IS_REQUIRED
              : "",
            value: this.state.city,
            placeholder: "City",
            onChange: this.handleInputChange,
          }}
          stateSelectFieldProps={{
            name: "state",
            id: "PS_state",
            value: this.state.state,
            error: this.state?.errors?.stateError,
            hint: this.state?.errors?.stateError
              ? "State" + ValidationMessages.IS_REQUIRED
              : "",
            onChange: this.handleStateChange,
            // options: this.state.statesList,
          }}
          zipInputFieldProps={{
            name: "zipcode",
            id: "PS_zipcode",
            maxLength: AppUtils.isUsCountry() ? 10 : 7,
            error: this.state?.errors?.zipCodeError,
            hint: this.state?.errors?.zipCodeError
              ? "Zip code" + ValidationMessages.IS_REQUIRED
              : "",
            value: this.state.zipcode,
            onChange: this.handleInputChange,
          }}
          saveChangesButtonProps={{
            id: "saveChangesButton",
            disabled: this.props.disableCheckoutButton,
            onClick: this.handleSaveChanges,
          }}
          backToDashboardLink={{
            id: "backToDashboard",
            href: '#',
            onClick: (e) => {
              this.props.history.push("/pages/dashboard");
              e.preventDefault();
            },
          }}
        ></NewPaymentPage>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      cartId: AppUtils.getCartId(),
    });
    this.setShouldDisplayMessage();
    this.fetchCreditCards();
    window.scrollTo(0, 0)
  }
  setShouldDisplayMessage() {
    if (this.props.cartData) {
      this.props.cartData.orderLines.forEach((order) => {
        if (order.autoRenew === 1 && !AppUtils.isTagProduct(order)) {
          this.setState({
            shouldDisplayMessage: true,
          });
        }
      });
    }
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleStateChange = (e) => {
    this.setState({
      state: e.target.value,
    });
  };
  handleCardExpiryChange(e) {
    const formatted = PaymentUtils.formatExpiryDate(
      this.state.cardExpiry,
      e.target.value
    );
    this.setState({
      [e.target.name]: formatted,
    });
  }
  handlePhoneNumber = (e) => {
    const phoneNumberElement = document.getElementById("PS_phoneNumber");
    const phoneElements = phoneNumberElement.value.split("");
    if (
      phoneNumberElement.value.length === 4 &&
      this.state.phoneNumber.length !== 5
    ) {
      phoneNumberElement.value =
        phoneNumberElement.value.substr(0, 3) + "-" + phoneElements[3];
    }

    if (
      phoneNumberElement.value.length === 8 &&
      this.state.phoneNumber.length !== 9
    ) {
      const phoneElements = phoneNumberElement.value.split("");
      phoneNumberElement.value =
        phoneNumberElement.value.substr(0, 7) + "-" + phoneElements[7];
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSaveChanges = () => {
    this.payWithNewCard();
  };
}


const mapStateToProps = (state) => {
  const membershipPurchaseStore = state.membershipPurchaseStore;
  const customerProfileStore = state.customerProfileStore;

  return {
    cartData: membershipPurchaseStore.cartData,
    promoCode: membershipPurchaseStore.promoCode,
    customerAnimals: customerProfileStore.customerAnimals,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCartData: (data) => dispatch(saveCartData(data)),
    clearCartData: () => dispatch(clearCartData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentContainer));

export const getPositions = ({ current: childEl }, { current: tooltipEl }) => {
  try {
    const c = childEl?.getBoundingClientRect && childEl.getBoundingClientRect()
    const t =
      tooltipEl?.getBoundingClientRect && tooltipEl.getBoundingClientRect()

    if (!Object.keys(c.toJSON()).length || !Object.keys(t.toJSON()).length)
      return []

    // window width
    const ww = window.innerWidth
    // tooltip cant be too close to edge of the window -> 8px padding
    const m = 8
    // tooltip width with margin
    const twm = t.width + m
    // scroll x, y
    const sy = window.scrollY
    const sx = window.scrollX

    // tooltip coords to render on the right
    let x = c.x + c.width + 12 + sx
    let y = c.y - t.height / 4 + sy
    // arrow coords
    let ax = null
    let ay = c.y - y + c.height / 2 + sy

    // if tooltip extends beyond right side of screen width, get coords to render at the bottom
    if (x + twm > ww) {
      x = c.x - t.width / 2 + c.width / 2 + sx
      y = c.y + c.height + 8 + sy
      ax = c.x - x + c.width / 2 + sx
      ay = null

      // if tooltip still extends beyond right side of screen width
      if (x + twm > ww) {
        x = ww - m - t.width + sx
        ax = c.x - x + c.width / 2 + sx

        return [x, y, ax, ay]
        // if tooltip extends beyond left side of screen width
      } else if (x < 0) {
        ax = c.x - m + c.width / 2 + sx

        return [m, y, ax, ay]
      }
    }

    return [x, y, ax, ay]
  } catch (error) {
    console.error(error)

    return []
  }
}

export const getTooltipPositionStyles = ({ x, y }) => {
  if (x && y) {
    return {
      transform: `translate(${x}px, ${y}px)`
    }
  }

  return {}
}

export const getArrowPositionStyles = ({ x, y }) => {
  if (x && !y) {
    return {
      left: `${x}px`,
      bottom: '100%'
    }
  } else if (!x && y) {
    return {
      top: `${y}px`,
      right: '100%'
    }
  }
}

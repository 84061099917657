import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { IconButton } from '../IconButton'

export const Summary = ({ label, amount }) => (
  <div
    className={cn('ui-petplace-shopping-cart__cart-summary', 'ui-petplace-shopping-cart__row')}
  >
    <span>{label}</span>
    <span>{amount}</span>
  </div>
)
Summary.propTypes = {
  label: PropTypes.string,
  amount: PropTypes.string
}

export const Promo = ({ label, ...rest }) => (
  <div
    className={cn('ui-petplace-shopping-cart__cart-summary', 'ui-petplace-shopping-cart__row')}
  >
    <span>Discount</span>
    <button type='button' className='ui-petplace-shopping-cart__promo_button' {...rest}>
      {label}
    </button>
  </div>
)
Promo.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string
}

export const Total = ({ amount }) => (
  <div className={cn('ui-petplace-shopping-cart__cart-total', 'ui-petplace-shopping-cart__row')}>
    <span>Total</span>
    <span id='total-amount'>{amount}</span>
  </div>
)
Total.propTypes = {
  amount: PropTypes.string
}

export const CartItem = ({ productName, productCost, onRemoveClick }) => (
  <div className={cn('ui-petplace-shopping-cart__cart-item', 'ui-petplace-shopping-cart__row')}>
    <span>{productName}</span>
    <span className='ui-petplace-shopping-cart__cart-item__price'>{productCost}</span>
    <IconButton
      id='remove-product-icon'
      className='ui-petplace-shopping-cart__cart-item__remove'
      type='remove_circle_outline'
      onClick={onRemoveClick}
    />
  </div>
)
CartItem.propTypes = {
  productName: PropTypes.string,
  productCost: PropTypes.string,
  onRemoveClick: PropTypes.func
}

// Prefix public url to all images
export const PUBLIC_URL = '/images/component/'

export const Locale = Object.freeze({
  us: 'us',
  ca: 'ca'
})

export const LocaleWithUnknown = Object.freeze({
  ...Locale,
  unknown: 'unknown'
})

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { sendLoginRequest } from "../../../redux/actions/authActions";
import { connect } from "react-redux";
import axios from "../../../custom-axios";
import {
  LoginCard,
  TwoColumnContent,
  LoginPageFooter,
  NoAccountCard,
  ForgotEmailModal,
  RmPetInfoCard,
  UpgradeAvailableCard,
  Link,
  PageContent,
  Button,
  NewFAUserModal
} from "../../../UI-Component-Library";
import { ApiConstants } from "../../../utils/api-constants";
import { AppUtils } from "../../../utils/app-utils";
import { ValidationMessages } from "../../../utils/validation-messages";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { AppConstants } from "../../../utils/app-constants";
import { clearCartData } from "../../../redux/reducers/membership-purchase-reducer";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
class Login extends Component {
  constructor() {
    super();
    this.state = {
      Email: "",
      Password: "",
      error: "",
      isForgotEmailModalOpen: false,
      hideEmailField: false,
      rememberMe: true,
      isSubmited: false,
      isEmailError: false,
      isPasswordError: false,
      isPopUpModalOpen: false,
      disableEmailField: false,
    };
    this.cRef = React.createRef();
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  validateAndLogin = () => {
    this.props.clearCartData();
    this.setState({
      isSubmited: true,
    });
    let isValid = true;
    let isEmailError = false;
    let isPasswordError = false;
    let hideEmailField = true;
    if (
      !this.state.Email ||
      this.state.Email.trim() === "" ||
      !AppUtils.isValidEmail(this.state.Email)
    ) {
      isValid = false;
      isEmailError = true;
      hideEmailField = false;
    }
    if (!this.state.Password || this.state.Password.trim() === "") {
      isValid = false;
      isPasswordError = true;
      DataLayerUtils.loginErrorEvent("password not entered");
    }
    if (isValid) {
      this.props.loginRequest(
        {
          Email: this.state.Email,
          Password: this.state.Password.trim(),
          rememberMe: this.state.rememberMe,
        },
        this.props.history,
        this.cRef
      );
      this.setState({
        isSubmited: false,
        isEmailError: false,
        isPasswordError: false,
      });
    } else {
      this.setState({
        isEmailError,
        isPasswordError,
        hideEmailField,
      });
    }
  };

  sendResetEmail = () => {
    let error = "";
    this.setState({
      error
    })
    const requestData = (({ Email }) => ({
      Email,
    }))(this.state);
    axios
      .processPost(
        ApiConstants.URL_POST_FORGOT_PASSWORD_EMAIL,
        this.cRef,
        requestData
      )
      .then((response) => {
        this.props.history.push("/auth/forgot-password/success");
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            error: errors[0],
          });
        }
      });
    
  };

  checkUserInfo() {
    let isEmailError = false;
    let error = "";
    if (
      !this.state.Email ||
      this.state.Email.trim() === "" ||
      !AppUtils.isValidEmail(this.state.Email)
    ) {
      isEmailError = true;
      DataLayerUtils.loginErrorEvent("invalid email");
    }
    this.setState({
      isEmailError,
      error
    })
    if (!isEmailError) {
      const email = this.state.Email;
      const loginSegment = AppConstants.CONFIG_JSON?.LoginSegment;
      axios
        .processGet(
          `${ApiConstants.URL_CHECK_FA_MPH_USER}/?email=${email}&segment=${loginSegment}`,
          this.cRef
        )
        .then((response) => {
          if (response.data === 2) {
            this.sendResetEmail();
          } else {
            this.setState((prevState) => {
              return {
                hideEmailField: !prevState.hideEmailField,
              };
            });
          }
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              error: errors[0],
            });
            DataLayerUtils.loginErrorEvent(errors[0]);
            DataLayerUtils.formErrorEvent("login", "email", errors[0]);
          }
        });
      }
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorMessages !== prevProps.errorMessages) {
      this.setState({
        hideEmailField: false
      })
    }
  }
  

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const user = params.get('user');
    if (AppUtils.isEqualString(user, "FA")) {
      this.setState({
        isPopUpModalOpen: true
      })
    }
    if (this.props.location?.state) {
      this.setState({
        Email: (this.props.location.state?.email) ? this.props.location.state.email : "",
        disableEmailField: true,
      })
    }
  }
  render() {
    return (
      <React.Fragment>
        <TwoColumnContent
          leftColumn={
            <div ref={this.cRef}>
              <LoginCard
                description={
                  !this.state.hideEmailField
                    ? AppConstants.CONFIG_JSON?.PARTNER_SETTINGS
                        ?.LoginPageDescription
                    : this.state.Email
                }
                emailInputProps={{
                  id: "login_email_input",
                  hidden: this.state.hideEmailField,
                  disabled: this.state.disableEmailField,
                  label: FeatureFlagUtils.shouldShowLoginLabel()
                    ? "Email Address"
                    : "",
                  value: this.state.Email,
                  name: "Email",
                  error: this.state.isEmailError,
                  hint: this.state.isEmailError
                    ? ValidationMessages.EMAIL_VALIDATION_MESSAGE
                    : "",
                  onChange: this.handleInputChange,
                }}
                passwordInputProps={{
                  id: "login_password_input",
                  hidden: !this.state.hideEmailField,
                  label: FeatureFlagUtils.shouldShowLoginLabel()
                    ? "Password"
                    : "",
                  value: this.state.Password,
                  name: "Password",
                  error: this.state.isPasswordError,
                  hint: this.state.isPasswordError
                    ? ValidationMessages.PASSWORD_EMPTY
                    : "",
                  onChange: this.handleInputChange,
                }}
                hideLoginButton={!this.state.hideEmailField}
                loginButtonProps={{
                  id: "login_button",
                }}
                backArrowProps={{
                  onClick: (e) => {
                    this.setState((prevState) => {
                      return {
                        hideEmailField: !prevState.hideEmailField,
                      };
                    });
                  },
                }}
                continueButtonProps={{
                  id: "continue_button",
                }}
                rememberMeCheckboxProps={{
                  id: "rememberme_checkbox",
                  hidden: true,
                  checked: this.state.rememberMe,
                  name: "rememberMe",
                  onChange: (e) => {
                    this.setState((prevState) => {
                      return {
                        rememberMe: !prevState.rememberMe,
                      };
                    });
                  },
                }}
                otherSignInText={"OR"}
                socialButtonGoogleProps={{
                  id: "social_button_google",
                  onClick: () => {
                    window.location = ApiConstants.URL_SOCIAL_LOGIN_GOOGLE;
                  },
                }}
                socialButtonFacebookProps={{
                  id: "social_button_facebook",
                  style: FeatureFlagUtils.hideFBLoginOption()
                    ? { display: "none" }
                    : {},
                  onClick: () => {
                    window.location = ApiConstants.URL_SOCIAL_LOGIN_FACEBOOK;
                  },
                }}
                errorMessage={
                  this.props.errorMessages.length
                    ? this.props.errorMessages[0]
                    : this.state.error
                    ? this.state.error
                    : ""
                }
                onFormSubmitOne={() => {
                  this.checkUserInfo();
                }}
                onFormSubmitTwo={() => {
                  this.validateAndLogin();
                }}
                forgotEmailLinkProps={{
                  href: "#",
                  id: "forgot_email_link",
                  onClick: (e) => {
                    this.setState({
                      isForgotEmailModalOpen: true,
                    });
                  },
                }}
                forgotPasswordLinkProps={{
                  href: "#",
                  id: "forgot_password_link",
                  onClick: (e) => {
                    DataLayerUtils.forgotPasswordEvent(
                      "click to forgot password"
                    );
                    this.sendResetEmail();
                  },
                }}
              ></LoginCard>
            </div>
          }
          rightColumn={
            <NoAccountCard
              showCreateAccountFromMicrochip={AppUtils.isMicrochipEnabled()}
              createAccountFromMicrochipProps={{
                onClick: () => {
                  DataLayerUtils.createAccountEvent(
                    "microchip",
                    `create account from microchip`,
                    "organic"
                  );
                  this.props.history.push(
                    "/auth/signup/step1?type=microchip&flow=organic"
                  );
                },
                id: "createAccountFromMicrochipButton",
                variant: FeatureFlagUtils.shouldShowOutlineButton()
                  ? Button.Variants.outlined
                  : Button.Variants.filled,
                color: FeatureFlagUtils.shouldShowOutlineButton()
                  ? Button.Colors.secondary
                  : Button.Colors.tertiary,
              }}
              createAccountFromPolicyProps={{
                onClick: () => {
                  DataLayerUtils.createAccountEvent(
                    "policy",
                    `create account from policy`,
                    "organic"
                  );
                  this.props.history.push(
                    "/auth/signup/step1?type=policy&flow=organic"
                  );
                },
                id: "createAccountFromPolicyButton",
                variant: FeatureFlagUtils.shouldShowOutlineButton()
                  ? Button.Variants.outlined
                  : Button.Variants.filled,
                color: FeatureFlagUtils.shouldShowOutlineButton()
                  ? Button.Colors.secondary
                  : Button.Colors.tertiary,
              }}
              createAccountWithoutChipProps={{
                onClick: () => {
                  DataLayerUtils.createAccountEvent(
                    "phone",
                    `create account with phone and email `,
                    "organic"
                  );
                  this.props.history.push(
                    "/auth/signup/step1?type=phone&flow=organic"
                  );
                },
                id: "createAccountFromPolicyButton",
                variant: FeatureFlagUtils.shouldShowOutlineButton()
                  ? Button.Variants.outlined
                  : Button.Variants.filled,
                color: FeatureFlagUtils.shouldShowOutlineButton()
                  ? Button.Colors.secondary
                  : Button.Colors.tertiary,
              }}
            ></NoAccountCard>
          }
        ></TwoColumnContent>
        <ForgotEmailModal
          open={this.state.isForgotEmailModalOpen}
          onClose={() => {
            this.setState({
              isForgotEmailModalOpen: false,
            });
          }}
          modalHeadline={"Forgot email"}
          buttonText={"Close"}
          cancelButtonProps={{
            size: Button.Sizes.small,
            onClick: () => {
              this.setState({
                isForgotEmailModalOpen: false,
              });
            },
          }}
          phoneNumber={FeatureFlagUtils.getPhoneNumber()}
        ></ForgotEmailModal>
        <NewFAUserModal
          open={this.state.isPopUpModalOpen}
          onClose={() => {
            this.setState({
              isPopUpModalOpen: false,
            });
          }}
          modalHeadline={"Important notice to Found Animals customers"}
          buttonText={"Continue"}
          cancelButtonProps={{
            size: Button.Sizes.small,
            onClick: () => {
              this.setState({
                isPopUpModalOpen: false,
              });
            },
          }}
        ></NewFAUserModal>
        <LoginPageFooter
          companyName={FeatureFlagUtils.getCompanyName()}
          phoneNumber={FeatureFlagUtils.getPhoneNumber()}
          onClickToCall={() => DataLayerUtils.clickToCallEvent()}
          showPetProProtalLink={FeatureFlagUtils.showPetProPortalLink()}
          onClickPetProLink={() =>
            window.open(FeatureFlagUtils.getPetProPortalLink(), "_blank")
          }
          messageText={
            <>
              If you’re having trouble logging in, or previously created an
              account using Facebook, please call
            </>
          }
        >
          Headline
        </LoginPageFooter>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const authStore = state.authStore;
  return {
    isAuthenticated: authStore.isAuthenticated,
    isLoading: authStore.isLoading,
    errorMessages: authStore.errorMessages,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (data, history, cRef) =>
      dispatch(sendLoginRequest(data, history, cRef)),
    clearCartData: () => dispatch(clearCartData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text, Icon } from '../../'

export class Option extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onClick: PropTypes.func,

    showCheckMark: PropTypes.bool,
    label: PropTypes.any,
    description: PropTypes.element,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    disabled: false
  }

  render() {
    const {
      disabled,
      active,
      onClick,
      showCheckMark,
      label,
      description,
      id,
      className,
      style,
      refProp
    } = this.props

    const classes = getClasses(active, showCheckMark, disabled)

    const checkMarkType = active ? 'check_circle' : 'radio_button_unchecked'

    return (
      <div
        id={id}
        className={cn(classes.container, className)}
        style={style}
        ref={refProp}
        role='button'
        onClick={(e) => !disabled && onClick(e)}
      >
        {showCheckMark && (
          <Icon className={cn(classes.checkMark)} type={checkMarkType} />
        )}

        <div className={cn(classes.content)}>
          <Text className={cn(classes.label)} type={Text.Types.bodyBold}>
            {label}
          </Text>

          <div className={cn(classes.descriptionContainer)}>{description}</div>
        </div>
      </div>
    )
  }
}

const getClasses = (active, showCheckMark, disabled) => {
  const classes = {
    container: ['ui-option__container'],
    content: ['ui-option__content'],
    checkMark: ['ui-option__check-mark'],
    label: ['ui-option__label'],
    descriptionContainer: ['ui-option__description-container']
  }

  active && classes.container.push('ui-option__container--active')
  showCheckMark && classes.container.push('ui-option__container--check-mark')
  disabled && classes.container.push('ui-option__container--disabled')

  return classes
}

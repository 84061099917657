import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text } from '../Text'
import { Link } from '../Link'
import { Icon } from '../Icon'

export class LoginPageFooter extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    messageText: PropTypes.any,
    phoneNumber: PropTypes.string,
    companyName: PropTypes.string,
    onClickToCall: PropTypes.func,
    onClickPetProLink: PropTypes.func,
    showPetProProtalLink: PropTypes.bool,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    messageText: <>Having trouble logging in? Call</>
  }

  render() {
    const {
      id,
      className,
      style,
      refProp,
      phoneNumber,
      companyName,
      messageText,
      onClickToCall,
      onClickPetProLink,
      showPetProProtalLink
    } = this.props

    return (
      <div
        id={id}
        className={cn('ui-login-content__footer', className)}
        style={style}
        ref={refProp}
      >
        <Text type={Text.Types.bodyBold}>
          {messageText}{' '}
          <Link href={`tel: + ${ phoneNumber ? phoneNumber: '1-866-597-2424' }`} onClick={onClickToCall}>
            {phoneNumber ? phoneNumber : '1-866-597-2424'}
          </Link>
        </Text>
        <Text>
          By clicking “Login”, you agree and consent to {companyName}., its
          subsidiaries, affiliates, brands, trademarks, and/or partners
          collecting, using, and disclosing your personal information.
        </Text>
        {showPetProProtalLink && (<Text type={Text.Types.bodyBold} className="ui-login-content__footer_petpro-text">
          Are you a pet professional? {' '}
          <Link href='#' onClick={onClickPetProLink} className="ui-login-content__footer_petpro-link">
            Login here{' '}
          </Link>
          <Icon type="open_in_new"></Icon>
        </Text>)}
      </div>
    )
  }
}

import { LocaleWithUnknown } from './constants'

export const getLabelsByLocale = (locale) => {
  switch (locale) {
    case LocaleWithUnknown.unknown:
      return {
        stateLabel: 'Province/State',
        zipCodeLabel: 'Postal/ZIP code'
      }
    case LocaleWithUnknown.ca:
      return {
        stateLabel: 'Province',
        zipCodeLabel: 'Postal code'
      }
    case LocaleWithUnknown.us:
    default:
      return {
        stateLabel: 'State',
        zipCodeLabel: 'ZIP Code'
      }
  }
}

export const createArrayIncludesValueCheck = (iconsToHide) => (iconName) =>
  !iconsToHide.includes(iconName)

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import ForgotPassword from "./fogot-password/forgot-password";
import Login from "./login/Login";
import SignUpContainer from "./signup/signup-container";
import { PageHeader, PageFooter, Link, MerckRedirectLandingPage } from "../../UI-Component-Library";
import ForgotPasswordSuccess from "./fogot-password/forgot-password-success";
import ResetPassword from "./reset-password/reset-password";
import ResetPasswordSuccess from "./reset-password/reset-password-success";
import axios from "../../custom-axios";
import { ApiConstants } from "../../utils/api-constants";
import { AppUtils } from "../../utils/app-utils";
import ReportPet from "../auth/ReportPet/report-pet";
import { FeatureFlagUtils } from "../../utils/feature-flag-utils";
import Accessblity from "../pages/accessblity/accessblity";
import Privacy from "./privacy";
import { DataLayerUtils } from "../../utils/dataLayer-utils";

// Minimal width for the desktop layout to look good, after this break point render mobile view
const MOBILE_VIEW_BREAKPOINT = 927;
let timer;
class AuthContainer extends React.Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      logos: [],
      legalLines: [],
      isRedirectedFromMerck: false,
    };
  }
  getSortedLegalLines(legalLines) {
    if (legalLines) {
      legalLines = legalLines.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
    console.log("Sorted", legalLines);
    return legalLines;
  }
  getFooterLinks() {
    return [
      FeatureFlagUtils.accessibilityDownload()
        ? {
            href: require("../../assets/Spot_accessibility.pdf"),
            download: "Spot_accessibility.pdf",
            color: Link.Colors.secondary,
            className: FeatureFlagUtils.showLinkOverrideCss()
            ? "dd-text-link-override"
            : "",
            label: "Accessibility",
            onClick: () => DataLayerUtils.navigationEvent("footer", "accessibility")
          }
        : {
            href: "",
            onClick: (e) => {
              DataLayerUtils.navigationEvent("footer", "accessibility");
              this.props.history.push("/auth/accessblity");
              e.preventDefault();
            },
            color: Link.Colors.secondary,
            className: FeatureFlagUtils.showLinkOverrideCss()
            ? "dd-text-link-override"
            : "",
            label: "Accessibility",
          },
      {
        href: this.getPrivacyPolicy(),
        onClick: (e) => {
          DataLayerUtils.navigationEvent("footer", "privacy policy");
          if (AppUtils.isPethealthProgram()) {
            this.props.history.push("/auth/privacy");
            e.preventDefault();
          }
        },
        download: "privacy_policy.pdf",
        color: Link.Colors.secondary,
        className: FeatureFlagUtils.showLinkOverrideCss()
        ? "dd-text-link-override"
        : "",
        label: "Privacy Policy",
      },
      {
        href: this.getTermsOfUse(),
        download: "terms-of-use.pdf",
        color: Link.Colors.secondary,
        className: FeatureFlagUtils.showLinkOverrideCss()
        ? "dd-text-link-override"
        : "",
        label: "Terms of Use",
        onClick: () => DataLayerUtils.navigationEvent("footer", "term of use")
      },
    ];
  }
  fetchLogos() {
    axios
      .processGet(ApiConstants.URL_FOOTER_LOGOS_FOR_LOOGED_OUT)
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            logos: response.data?.forLoggedOut?.logos,
            legalLines: this.getSortedLegalLines(
              response.data?.forLoggedOut?.legalLines
            ),
          });
        }
      });
  }
  componentDidMount() {
    this._isMounted = true;

    this.fetchLogos();

    const params = new URLSearchParams(this.props.location.search);
    const user = params.get('user');

    if(AppUtils.isEqualString(user, 'merck')){
      this.setState({
        isRedirectedFromMerck: true,
      }, () => {
        timer = setTimeout(() => {
          window.location.replace("/auth/login");
        }, 10000);
      })
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  getPrivacyPolicy = () => {
    if (FeatureFlagUtils.showPrivacyPolicyUS()) {
      return require("../../assets/" + FeatureFlagUtils.getPrivacyPolicyUS());
    }
    if (FeatureFlagUtils.showPrivacyPolicyCA()) {
      return require("../../assets/" + FeatureFlagUtils.getPrivacyPolicyCA());
    }
    return "";
  };

  getTermsOfUse = () => {
    return require("../../assets/" + FeatureFlagUtils.getTermOfUse());
  };

  getAuthComponent = (isRedirectedFromMerck) => {
    if(isRedirectedFromMerck){
      return (
        <MerckRedirectLandingPage
          buttonProps={{
            id: 'redirect-button-id',
            onClick: () => {
              clearTimeout(timer);
              window.location.replace("/auth/login");
            },
          }}
          linkProps={{
            id: 'redirect-link-id',
            href:'#',
            onClick: () => {
              clearTimeout(timer);
              window.location.replace("/auth/login");
            }
          }}
        />
      )
    }

    return (
      <div className="auth-container-wrapper">
        <PageHeader
          className={
            FeatureFlagUtils.shouldAlterLogoHeight()
              ? "custom-logo-height"
              : FeatureFlagUtils.shouldAlterLogoSize() ? "custom-logo-size" : ""
          }
          showAccount= {false}
          logo={FeatureFlagUtils.getLogo()}
          showReportLostPetButton={AppUtils.isLostPetEnabled()}
          reportLostPetButtonProps={{
            id: "auth_report_lost_found_button",
            onClick: (event) => {
              DataLayerUtils.navigationEvent(
                `${window.innerWidth > MOBILE_VIEW_BREAKPOINT  ? 'header' : 'mobile'}`,
                "report lost pet"
              );
              this.props.history.push({
                pathname: "/auth/report/pet",
                state: {
                  reloadKey: new Date().getTime(),
                }
              });
            },
          }}
          accountMenuProps={{
            id: "account_button",
            items: [],
            showNotificationMark: false,
            closeMenu: () => {}
          }}
          showPetProPortalButton={FeatureFlagUtils.showPetProPortalLink()}
          petProPortalButtonProps={{
            onClick: () => {
              window.open(FeatureFlagUtils.getPetProPortalLink(), "_blank")
            }
          }}
          onLogoClick={() => {
            DataLayerUtils.navigationEvent(
              `${window.innerWidth > MOBILE_VIEW_BREAKPOINT  ? 'header' : 'mobile'}`,
              "logo"
            );
            this.props.history.replace("/");
          }}
        ></PageHeader>
        {FeatureFlagUtils.getMaintenanceNoticeMessage() && (
          <div className="maintenance-error-message">
            {FeatureFlagUtils.getMaintenanceNoticeMessage()}
          </div>
        )}
        {this.props.history.location.pathname === "/auth" && (
          <Route
            path="/"
            render={() => {
              return <Redirect to="/auth/login" />;
            }}
          ></Route>
        )}
        <Switch>
          <Route path="/auth/login" component={Login}></Route>
          <Route
            path="/auth/reset-password/success"
            component={ResetPasswordSuccess}
          ></Route>
          <Route
            path="/auth/reset-password"
            component={ResetPassword}
          ></Route>
          <Route path="/auth/signup" component={SignUpContainer}></Route>
          <Route
            path="/auth/forgot-password/success"
            component={ForgotPasswordSuccess}
          ></Route>
          <Route path="/auth/report/pet" component={ReportPet}></Route>
          <Route path="/auth/accessblity" component={Accessblity}></Route>
          <Route path="/auth/privacy" component={Privacy}></Route>
        </Switch>
        <div>
          <PageFooter
            className={(FeatureFlagUtils.reduceBottomLogoWidth()) ? "bottomLogoWidth" : ""}
            footerLinks={this.getFooterLinks()}
            logos={this.state.logos}
            legalLines={this.state.legalLines}
          ></PageFooter>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.getAuthComponent(this.state.isRedirectedFromMerck)}
      </>
    );
  }
}
export default withRouter(AuthContainer);

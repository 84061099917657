import React, { Component } from "react";
import { AccessibilityCard, PageContent } from "../../../UI-Component-Library";
import { AppUtils } from "../../../utils/app-utils";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";

export default class Accessblity extends Component {
  render() {
    return (
      <PageContent>
        <AccessibilityCard
          isUs={this.props.showUsContent}
          isMerckProgram={AppUtils.isMerckProgram()}
          phoneNumber={FeatureFlagUtils.getPhoneNumber()}
        />
      </PageContent>
    );
  }
}

import { openClaim } from "../reducers/global-reducer";

/**
 * action dispatch handleOpenClaim 
 */
export function handleOpenClaim() {
  return (dispatch) => {
    dispatch(openClaim());
  }
}

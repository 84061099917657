import React from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Form,
  Select,
  Text
} from '../../'

export class UpdatePetStatusModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,

    onFormSubmit: PropTypes.func,

    banner: PropTypes.element,
    errorMessage: PropTypes.string,
    petSelectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),

    cancelButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    saveButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  render() {
    const {
      banner,
      open,
      onClose,
      errorMessage,
      petSelectProps,
      cancelButtonProps,
      saveButtonProps,
      onFormSubmit
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-update-pet-status-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline>Update pet status</PaperHeadline>
            <PaperContent>
              {banner}

              {errorMessage && (
                <Text
                  className='ui-update-pet-status-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}

              <Text className='ui-update-pet-status-modal__helper-text'>
                Please select one of the following to close the report:
              </Text>

              <Form onSubmit={onFormSubmit}>
                <Select id="update-pet-status" label='Pet status' {...petSelectProps} />
                <div className='ui-update-pet-status-modal__actions'>
                  <Button
                    type='button'
                    color={Button.Colors.tertiary}
                    variant={Button.Variants.outlined}
                    {...cancelButtonProps}
                  >
                    Cancel
                  </Button>
                  <Button
                    color={Button.Colors.tertiary}
                    type='submit'
                    {...saveButtonProps}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperContent, Button, Text, Link, HeaderDivider } from '../../'
import { PhoneCheckMark, PhoneMessage, Dashboard } from '../SvgIcons'

export class PaymentCompleteCard extends React.PureComponent {
  static propTypes = {
    userFirstName: PropTypes.string,
    message: PropTypes.string,
    continueButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    chatWithUsLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      userFirstName,
      message,
      continueButtonProps,
      chatWithUsLinkProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper id={id} className={className} style={style} ref={refProp}>
        <PaperContent>
          <HeaderDivider className='ui-payment-complete-card__title'>
            Congrats, {userFirstName}!
          </HeaderDivider>

          <Text className='ui-payment-complete-card__message'>{message}</Text>

          <div>
            <div className='ui-payment-complete-card__icon-text'>
              <div
                className={cn(
                  'ui-payment-complete-card__icon',
                  'ui-payment-complete-card__icon--adjusted'
                )}
              >
                <Dashboard />
              </div>
              <Text>
                Please note it will take approximately 24 hours for updates to
                reflect on your account.
              </Text>
            </div>

            <div className='ui-payment-complete-card__icon-text'>
              <div className='ui-payment-complete-card__icon'>
                <PhoneCheckMark />
              </div>
              <Text>
                You will receive a confirmation receipt email shortly.
              </Text>
            </div>

            <div className='ui-payment-complete-card__icon-text'>
              <div className='ui-payment-complete-card__icon'>
                <PhoneMessage />
              </div>
              <Text>
                You can contact us at{' '}
                <Link
                  className='ui-payment-complete-card__link'
                  href='tel:18665972424'
                >
                  1-866-597-2424
                </Link>{' '}
                with any membership questions.
              </Text>
            </div>
          </div>

          <Button
            className='ui-payment-complete-card__action'
            color={Button.Colors.tertiary}
            {...continueButtonProps}
          >
            Continue
          </Button>
        </PaperContent>
      </Paper>
    )
  }
}

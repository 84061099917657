import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text, Link } from '../../'

export class PageFooter extends React.PureComponent {
  static propTypes = {
    logos: PropTypes.arrayOf(
      PropTypes.shape({
        logoName: PropTypes.string,
        url: PropTypes.string
      })
    ),
    legalLines: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string
      })
    ),
    footerLinks: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string,
          target: PropTypes.string,
          label: PropTypes.string
        })
      ),
      PropTypes.arrayOf(PropTypes.object)
    ]),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    footerLinks: [],
    logos: [],
    legalLines: []
  }

  render() {
    const {
      logos,
      legalLines,
      footerLinks,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <div
        id={id}
        className={cn('ui-page-footer', className)}
        style={style}
        ref={refProp}
      >
        <div className='ui-page-footer__container'>
          <div className='ui-page-footer__images'>
            {logos.map(({ url, logoName }) => (
              <img key={url} alt={logoName} src={url} />
            ))}
          </div>

          {legalLines.map(({ name, description }) => (
            <Text key={name} id={name} type={Text.Types.caption} renderAs='p'>
              <span
                dangerouslySetInnerHTML={{
                  __html: description
                }}
              />
            </Text>
          ))}

          {footerLinks.length > 0 && (
            <div className='ui-page-footer__links'>
              {footerLinks.map(({ label, ...rest }) => (
                <Link color={Link.Colors.secondary} key={label} {...rest}>
                  {label}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

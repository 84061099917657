import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Form,
  Input,
  FormAction,
  Button,
  Text,
  Select,
  Checkbox
} from '../../'
import { Locale as importedLocale } from '../../constants'
import { getLabelsByLocale } from '../../utils'

export class PetplaceAddCreditCard extends React.PureComponent {
  static Locale = importedLocale

  static propTypes = {
    locale: PropTypes.oneOf(Object.values(PetplaceAddCreditCard.Locale)),
    errorMessage: PropTypes.string,
    onFormSubmit: PropTypes.func,
    warning1: PropTypes.string,
    warning2: PropTypes.string,
    useMailingAddressCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),

    useCardForFutureCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string
    }),

    /**
     * Credit card number
     */
    creditCardNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Card holder name
     */
    creditCardHolderNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Expiry date
     */
    creditCardExpiryInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * CVV
     */
    creditCardCVVInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    /**
     * Phone number
     */
    phoneInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * First name
     */
    firstNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Last name
     */
    lastNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Country
     */
    countrySelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Address line 1
     */
    address1InputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Address line 2
     */
    address2InputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * City
     */
    cityInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * State
     */
    stateSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Zip code
     */
    zipInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    saveChangesButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    useMailingAddressCheckboxProps: {},
    showAllowInsurancePremium: true,
    showAllowMicrochipMembershipRenewal: true,
    showUseCreditCardForAllPolicies: true,
    locale: PetplaceAddCreditCard.Locale.us
  }

  render() {
    const {
      locale,
      errorMessage,
      onFormSubmit,

      useMailingAddressCheckboxProps,
      useCardForFutureCheckboxProps,
      creditCardNumberInputFieldProps,
      creditCardHolderNameInputFieldProps,
      creditCardExpiryInputFieldProps,
      creditCardCVVInputFieldProps,
      phoneInputFieldProps,
      warning1,
      warning2,
      firstNameInputFieldProps,
      lastNameInputFieldProps,
      countrySelectFieldProps,
      address1InputFieldProps,
      address2InputFieldProps,
      cityInputFieldProps,
      stateSelectFieldProps,
      zipInputFieldProps,
      saveChangesButtonProps,

      id,
      className,
      style,
      refProp
    } = this.props

    const { stateLabel, zipCodeLabel } = getLabelsByLocale(locale)

    return (
      <Paper
        id={id}
        className={cn('ui-petplace-add-credit-card', className)}
        style={style}
        ref={refProp}
        // type={Paper.Types.framed}
      >
        {/* <PaperHeadline>Credit Card Info</PaperHeadline> */}
        <PaperContent>
          <Form onSubmit={onFormSubmit}>
            <Text type={Text.Types.headline}>Credit Card Info</Text>

            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-petplace-add-credit-card__error-message'
              >
                {errorMessage}
              </Text>
            )}

            <div className='ui-user-contact-info-card__form__row'>
              <Input
                label='Cardholder Name'
                {...creditCardHolderNameInputFieldProps}
              />

              <Input
                label='Credit Card Number'
                {...creditCardNumberInputFieldProps}
              />
            </div>

            <div className='ui-user-contact-info-card__form__row_expiry_cvv'>
              <Input label='Expires' {...creditCardExpiryInputFieldProps} />
              <Input label='CVV' placeholder='XXX' {...creditCardCVVInputFieldProps} />
            </div>

            <Text type={Text.Types.headline}>Billing Address</Text>

            <Checkbox
              showPetplaceCheckbox={true}
              label='Use my mailing address'
              {...useMailingAddressCheckboxProps}
            />

            {!useMailingAddressCheckboxProps?.checked && (
              <>
                <div className='ui-user-contact-info-card__form__row'>
                  <Input label='First Name' {...firstNameInputFieldProps} />

                  <Input label='Last Name' {...lastNameInputFieldProps} />
                </div>

                <Select isPetplace= {true} label='Country' {...countrySelectFieldProps} />

                <div className='ui-user-contact-info-card__form__row'>
                  <Input label='Address Line 1' {...address1InputFieldProps} />

                  <Input label='Address Line 2' {...address2InputFieldProps} />
                </div>

                <div className='ui-user-contact-info-card__form__row'>
                  <Input label='City' {...cityInputFieldProps} />

                  <Select isPetplace= {true} label={stateLabel} {...stateSelectFieldProps} />
                </div>

                <div className='ui-user-contact-info-card__form__row'>
                  <Input label={zipCodeLabel} {...zipInputFieldProps} />
                  <Input label='Phone Number' {...phoneInputFieldProps} />
                </div>
              </>
            )}

            <Checkbox
              showPetplaceCheckbox={true}
              label='Use this credit card number for future purchases'
              {...useCardForFutureCheckboxProps}
            />
            {warning2 && (<Text type={Text.Types.caption}>{warning2}</Text>)}
            {warning1 && (<Text type={Text.Types.caption}>{warning1}</Text>)}
            <FormAction>
              <Button
                // color={Button.Colors.tertiary}
                type='submit'
                {...saveChangesButtonProps}
              >
                Submit Payment
              </Button>
            </FormAction>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

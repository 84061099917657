import React from 'react'
import PropTypes from 'prop-types'

import { Paper, PaperHeadline, PaperContent, Text, Link } from '../../'

export class NoActivePoliciesCard extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    message: PropTypes.string,
    phoneNumber: PropTypes.string,

    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { id, className, style, refProp, phoneNumber, message } = this.props

    return (
      <Paper
        id={id}
        className={className}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>My Pets</PaperHeadline>
        <PaperContent className='ui-no-active-policies-card__content'>
          <Text type={Text.Types.headlineBold}>
            There are no active policies
          </Text>

          <Text>
            {message} <Link href={'tel:' + phoneNumber}>{phoneNumber}</Link>.
          </Text>
        </PaperContent>
      </Paper>
    )
  }
}

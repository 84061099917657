import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class Form extends React.PureComponent {
  static propTypes = {
    preventDefaultOnSubmit: PropTypes.bool,
    onSubmit: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string,
    id: PropTypes.string
  }

  static defaultProps = {
    preventDefaultOnSubmit: true
  }

  render() {
    const {
      children,
      preventDefaultOnSubmit,
      onSubmit,
      className,
      id
    } = this.props

    return (
      <form
        id={id}
        role='form'
        onSubmit={(e) => {
          preventDefaultOnSubmit && e.preventDefault()
          onSubmit?.(e)
        }}
        className={cn('ui-form__container--vertical', className)}
      >
        {children}
      </form>
    )
  }
}

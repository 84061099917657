import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperContent, Text, HeaderDivider } from '../..'
import { RmPasswordSuccessIcon } from '../SvgIcons'
import { Button } from '../Button'

export class RmPasswordCreationSuccess extends React.PureComponent {
  static propTypes = {
    userFirstName: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    loginNowButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  render() {
    const {
      userFirstName,
      id,
      className,
      style,
      refProp,
      loginNowButtonProps
    } = this.props

    return (
      <Paper id={id} className={className} style={style} ref={refProp}>
        <PaperContent>
          <div className='ui-rm-success_frame'>
            <div className={cn('ui-rm-success-card__icon')}>
              <RmPasswordSuccessIcon></RmPasswordSuccessIcon>
            </div>
            <HeaderDivider className='ui-payment-complete-card__title'>
              Congrats, {userFirstName}!
            </HeaderDivider>
            <Text className='ui-rm-success-card__centered_text'>
              Thank you for setting your password! Your account has been
              verified and your pet’s microchip has been registered.  Please note this pet will be visible on the portal in approximately one hour as our system refreshes.
            </Text>

            <Text className='ui-rm-success-card__centered_text'>
              Sincerely,
            </Text>
            <Text className='ui-rm-success-card__centered_text'>
              {' '}
              The 24Petwatch Team
            </Text>

            <div className='ui-rm-success-card__view_dashboard_button'>
              <Button
                color={Button.Colors.tertiary}
                type='submit'
                {...loginNowButtonProps}
              >
                Login Now
              </Button>
            </div>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

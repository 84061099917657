import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Select,
  Table,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableDataCell,
  Button,
  Link,
  Text
} from '../../'

export class PetplaceInsurancePolicy extends React.PureComponent {
  static propTypes = {
    insuranceSelectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),

    downloadCurrentPolicyButton: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    downloadUpcomingRenewalPolicyButton: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    policyData: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.string
      })
    ),

    showCancelPolicyLink: PropTypes.bool,

    isPolicyCancelled: PropTypes.bool,

    cancelledDate: PropTypes.string,

    cancelPolicyLinkProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    policyData: [],
    showCancelPolicyLink: true
  }

  render() {
    const {
      downloadUpcomingRenewalPolicyButton,
      downloadCurrentPolicyButton,
      insuranceSelectProps,
      policyData,
      cancelPolicyLinkProps,
      showCancelPolicyLink,
      isPolicyCancelled,
      cancelledDate,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-petplace-insurance-policy', className)}
        style={style}
        ref={refProp}
      >
        <PaperContent>
          <Select
            isPetplace= {true}
            className='ui-petplace-insurance-policy__select'
            {...insuranceSelectProps}
          />

          <Table>
            <TableBody>
              {policyData.map(({ label, data }) => (
                <TableRow key={`${label}-${data}`}>
                  <TableHeaderCell>{label}</TableHeaderCell>
                  <TableDataCell>{data}</TableDataCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <div className='ui-petplace-insurance-policy__actions'>
            <Button
              size={Button.Sizes.small}
              variant={Button.Variants.outlined}
              color={Button.Colors.tertiary}
              {...downloadCurrentPolicyButton}
            >
              Download current policy document
            </Button>
            <Button
              size={Button.Sizes.small}
              variant={Button.Variants.outlined}
              color={Button.Colors.tertiary}
              {...downloadUpcomingRenewalPolicyButton}
            >
              Download policy renewal information
            </Button>
            {showCancelPolicyLink &&
              !isPolicyCancelled && <Link {...cancelPolicyLinkProps}>Cancel this insurance policy</Link>
              // (isPolicyCancelled && <Text
              //   className='ui-petplace-insurance-policy__cancel-text'
              //   type={Text.Types.bodyBold}
              // >
              //   {`This insurance policy was cancelled on ${cancelledDate}`}
              // </Text>)
            }
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

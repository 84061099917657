import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Text,
  Icon,
  Link
} from '../..'

export class FoundPetMessageCard extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    className: PropTypes.string,
    onClose: PropTypes.func,
    id: PropTypes.string,
    closeButtonIconProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    showTurnOffAlertButton: PropTypes.bool,
    turnedOffAlertDate:PropTypes.string,
    turnOffAlertButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    date: PropTypes.string,
    otherContactDetails: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        phoneNumber: PropTypes.string,
        emailAddress: PropTypes.string,
        time: PropTypes.string,
      })
    ),
    alertMessage: PropTypes.string,
    foundPetAlertMessage:PropTypes.any,

    goBackLinkProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string
    })
  }

  render() {
    const {
      id,
      className,
      date,
      otherContactDetails,
      foundPetAlertMessage,
      turnOffAlertButtonProps,
      showTurnOffAlertButton,
      alertMessage,
      goBackLinkProps,
    } = this.props
    return (
      <Paper
        id={id}
        className={cn(className, 'ui-found-pet-message-delivered-card')}
      >
        <PaperContent className='ui-found-pet-message-delivered-card-content'>
          <div className='ui-found-pet-message-delivered-card-content-back-link'>
            <Icon type='chevron_left' />
            <Link {...goBackLinkProps}>Back to notifications</Link>
          </div>

          <div className='ui-found-pet-message-delivered-card-content-message'>
            <Text
              types={Text.Types.bodyBold}
              className='ui-found-pet-message-modal-date'
            >
              {date}
            </Text>

            {foundPetAlertMessage}
            <Text type={Text.Types.caption}>
              The following people have been contacted:
            </Text>
            <div className='ui-found-pet-message-delivered-card-content-delivered-status'>
              {otherContactDetails.map((value, index) => (
                <div key={`FoundPetMessage${index}`}>
                  <div>{value.type}</div>
                  {value.title.map((msg, index1) => (
                    <div key={`FoundPetMessage${index}${index1}`}>{msg}</div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className='ui-found-pet-message-delivered-card-content-alert-button'>
            {showTurnOffAlertButton ? (
              <Button
                id="found-alert-button"
                size={Button.Sizes.small}
                {...turnOffAlertButtonProps}
              >
                Turn off Found Pet Alert
              </Button>
            ) : (
              <Text>{ alertMessage }</Text>
            )}
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

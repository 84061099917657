import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { showButtonLoader, hideButtonLoader } from "../../../utils/buttonLoader-util";
import {
  ReportLostOrFoundPetCard,
  ReportFoundPetStep1Card,
  PageContent,
  ReportLostPetCard,
  ReportFoundPetCard,
  Banner,
  Button,
  InputHelperWithTooltip,
  FoundPetReportSubmittedModal,
} from "../../../UI-Component-Library";

import { AppConstants } from "../../../utils/app-constants";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { LoadMask } from "../../../utils/loadmask.util";
import ReCAPTCHA from "react-google-recaptcha";
import { AppUtils } from "../../../utils/app-utils";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";

class ReportPet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      petFoundHeadline: "I have found a pet",
      isLostPet: false,
      isFoundPet: false,
      petName: "",
      microChipNumber: "",
      interSectionAddress: "",
      city: "",
      country: "ca",
      state: "AB",
      ownerName: "",
      ownerPhoneNumber: "",
      ownerEmailAddress: "",
      lostPetConsent: true,
      shouldShowLostFoundPage: true,
      lostPetErrorMessage: "",
      lostPetSuccessMessage: "",
      isLostPetSubmittedSuccessful: false,
      isLostPetError: false,
      isLostCaptchaVerified: false,
      isFoundCaptchaVerified: false,
      isFoundPetSubmittedSuccessful: false, // Found
      isFoundPetError: false,
      foundPetErrorMessage: "",
      finderName: "",
      finderPhone: "",
      finderEmail: "",
      finderNotes: "",
      finderAddress: "",
      finderCity: "",
      finderCountry: "ca",
      finderState: "AB",
      finderMicroChipNumber: "",
      finderConsent: true,
      isSubmitLostPetClicked: false,
      isSubmitFoundPetClicked: false,
      shouldShowDBSearchPage: true,
      isSearchSkipped: false,
      searchSuccess: false,
      petPhoto: undefined,
      foundPetName: "",
      foundPetOwnerName: "",
      gender: "",
      breed: "",
      microChipNumberError: false,
      isFoundPetSubmittedModalOpen: false,
      identifierId: "",
      identifierType: "",
    };
  }

  setToDefaultState = () => {
    this.setState({
      petFoundHeadline: "I have found a pet",
      isLostPet: false,
      isFoundPet: false,
      petName: "",
      microChipNumber: "",
      interSectionAddress: "",
      city: "",
      country: "ca",
      state: "AB",
      ownerName: "",
      ownerPhoneNumber: "",
      ownerEmailAddress: "",
      lostPetConsent: true,
      shouldShowLostFoundPage: true,
      lostPetErrorMessage: "",
      lostPetSuccessMessage: "",
      isLostPetSubmittedSuccessful: false,
      isLostPetError: false,
      isLostCaptchaVerified: false,
      isFoundCaptchaVerified: false,
      isFoundPetSubmittedSuccessful: false, // Found
      isFoundPetError: false,
      foundPetErrorMessage: "",
      finderName: "",
      finderPhone: "",
      finderEmail: "",
      finderNotes: "",
      finderAddress: "",
      finderCity: "",
      finderCountry: "ca",
      finderState: "AB",
      finderMicroChipNumber: "",
      finderConsent: true,
      isSubmitLostPetClicked: false,
      isSubmitFoundPetClicked: false,
      shouldShowDBSearchPage: true,
      isSearchSkipped: false,
      searchSuccess: false,
      petPhoto: undefined,
      foundPetName: "",
      foundPetOwnerName: "",
      gender: "",
      breed: "",
      microChipNumberError: false,
      isFoundPetSubmittedModalOpen: false,
    });
  }

  handleChanges = (event, name) => {
    if(name === "finderPhone"){
      const number = AppUtils.removePhoneFormatting(event.target.value);
      const len = number.length;
      if(( AppUtils.isTypeNumber(event.target.value)
          || event.keyCode === 8
          || event.target.value === "" )
          && len < 13
      ) {
        this.setState({
          [name]: AppUtils.formatPhoneNumber(event.target.value, this.state.finderPhone),
        });
      }
      return;
    }

    this.setState({
      [name]: event.target.value,
    });
  };

  getStatesOrProvinces = () => {
    if (this.state.country.toLowerCase() === "us") {
      return AppConstants.STATES;
    } else {
      return AppConstants.PROVINCES;
    }
  };

  getStatesOrProvincesFinder = () => {
    if (this.state.finderCountry.toLowerCase() === "us") {
      return AppConstants.STATES;
    } else {
      return AppConstants.PROVINCES;
    }
  };

  getFormattedOwnerName = (name) => {
    let formattedName = name;
    formattedName = formattedName.split(" ");
    if(formattedName.length === 1) {
      return formattedName[0];
    }

    return `${formattedName[0]} ${formattedName[1].charAt(0)}.`
  }

  getFormattedPetName = (fullName, name1, name2, name3) => {
    if(name1 === "" && name2 === "" && name3 === ""){
      return fullName;
    }

    if(name2 !== ""){
      return `${name1} ${name2.charAt(0)}.`
    }

    if(name3 !== ""){
      return `${name1} ${name3.charAt(0)}.`
    }

    return name1;
  }

  searchDatabase = async () => {
    if(AppUtils.isEmptyString(this.state.finderMicroChipNumber)){
      this.setState({
         microChipNumberError: true,
      })
      return;
    }

    this.setState({
      microChipNumberError: false,
    })
    try{
      let animalID,
          ownerBR,
          ownerName,
          petName,
          petGender,
          petPhoto,
          identifierId,
          identifierType,
          petBreed;
      const microChipNumber = this.state.finderMicroChipNumber;
      LoadMask.showPreloader(this.refs.foundPetReportStep1);

      const response1 = await axios.processGet(
        `${ApiConstants.URL_CHIPLET_PET_INFO}${
          microChipNumber
        }/0`,
        null
      )
      animalID=response1.data["animalId"];
      identifierId = response1.data["identifierId"];
      identifierType = response1.data["identifierType"];

      petGender = AppConstants.SEX[response1.data["sex"]];
      petBreed = response1.data["primaryBreedName"]

      petName = this.getFormattedPetName(response1.data["fullPrintableName"], response1.data["nameOne"], response1.data["nameTwo"], response1.data["nameThree"]);

      const response2 = await axios.processGet(
        `${ApiConstants.URL_CHIPLET_OWNER_INFO_BY_PET_ID}${animalID}`,
        null
      )
      let primaryOwner = response2.data.filter( (value) => value.primaryFlag === 1)
      ownerBR = primaryOwner[0]?.busrelAccount;


      //get pet photo
      const petdetails = await axios.processGet(
        `${ApiConstants.URL_GET_PET_PHOTO}${animalID}`,
        null
      )
      petPhoto =  petdetails?.photo;

      const response3 = await axios.processGet(
        `${ApiConstants.URL_CHIPLET_OWNER_BY_OWNERSHIP}${animalID}/${ownerBR}`,
        null
      )

      ownerName = this.getFormattedOwnerName(response3.data.name);

      this.setState({
        foundPetOwnerName:ownerName,
        foundPetName:petName,
        gender:petGender,
        breed:petBreed,
        petPhoto: petPhoto,
        searchSuccess: true,
        identifierId,
        identifierType
      });
      LoadMask.hidePreloader(this.refs.foundPetReportStep1);

    }
    catch(err){
      this.setState({
        searchSuccess: false,
      })
      LoadMask.hidePreloader(this.refs.foundPetReportStep1);
    }
    finally{
      this.setState({
        shouldShowDBSearchPage:false,
        isSearchSkipped:false,
      })
      LoadMask.hidePreloader(this.refs.foundPetReportStep1);
    }
  }

  submitLostInfo = () => {
    const reportDate = new Date().toISOString();

    this.setState({
      isSubmitLostPetClicked: true,
    });

    if (
      this.state.petName.length > 0 &&
      this.state.microChipNumber.length > 0 &&
      this.state.ownerName.length > 0 &&
      this.state.interSectionAddress.length > 0 &&
      this.state.city.length > 0 &&
      this.state.country.length > 0 &&
      this.state.state.length > 0 &&
      AppUtils.isValidEmail(this.state.ownerEmailAddress) &&
      AppUtils.isValidPhoneNumber(this.state.ownerPhoneNumber) &&
      this.state.isLostCaptchaVerified
    ) {
      const payload = {
        busRelAccount: "",
        animalID: "",
        petName: this.state.petName,
        microchipNumber: this.state.microChipNumber,
        ownerName: this.state.ownerName,
        phoneNumber: this.state.ownerPhoneNumber,
        emailAddress: this.state.ownerEmailAddress,
        streetAddress: this.state.interSectionAddress,
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        dateReport: reportDate,
        contactConsent: this.state.lostPetConsent,
      };

      const submitLostPet = document.getElementById("submit_lost_pet")
      showButtonLoader(submitLostPet);
      axios
        .processPost(ApiConstants.URL_REPORT_LOST_PET, null, payload)
        .then((response) => {
          this.setState({
            isLostPetSubmittedSuccessful: true,
            lostPetSuccessMessage: "Thank you, we have received your report.",
          });
        })
        .catch((error) => {
          let errors = error?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              isLostPetError: true,
              lostPetErrorMessage: errors[0],
            });
          }
        })
        .finally(() => {
          AppUtils.focusOnDiv("rp-top-div");
          hideButtonLoader(submitLostPet);
          this.lostCaptcha.reset();
        });
    }
  };

  submitFoundReport = () => {
    const reportDate = new Date().toISOString();

    this.setState({
      isSubmitFoundPetClicked: true,
    });

    if (
      !AppUtils.isEmptyString(this.state.finderMicroChipNumber) &&
      !AppUtils.isEmptyString(this.state.finderName) &&
      !AppUtils.isEmptyString(this.state.finderAddress) &&
      !AppUtils.isEmptyString(this.state.finderCountry) &&
      !AppUtils.isEmptyString(this.state.finderCity) &&
      !AppUtils.isEmptyString(this.state.finderState) &&
      AppUtils.isValidEmail(this.state.finderEmail) &&
      AppUtils.isValidPhoneNumber(this.state.finderPhone) &&
      this.state.isFoundCaptchaVerified
    ) {
      const payload = {
        busRelAccount: "",
        microchipNumber: this.state.finderMicroChipNumber,
        finderName: this.state.finderName,
        contactPhoneNumber: this.state.finderPhone,
        contactEmailAddress: this.state.finderEmail,
        foundLocation: this.state.finderAddress,
        foundCountry: this.state.finderCountry,
        foundCity: this.state.finderCity,
        foundState: this.state.finderState,
        foundZipCode: "",
        notes: this.state.finderNotes,
        dateReport: reportDate,
        contactConsent: this.state.finderConsent,
        identifierType: this.state.identifierType,
        identifierId: this.state.identifierId
      };

      const submitFoundPet = document.getElementById("submit_found_pet")
      showButtonLoader(submitFoundPet);
      axios
        .processPost(ApiConstants.URL_REPORT_FOUND_PET, null, payload)
        .then((response) => {
          this.setState({
            isFoundPetSubmittedSuccessful: true,
            petFoundHeadline: "Thank you, We have successfully received your report.",
            isFoundPetSubmittedModalOpen: true,
          });
          DataLayerUtils.reportLostPetEvent(
            null,
            this.state.finderMicroChipNumber,
            'found a pet',
            'success'
          )
        })
        .catch((error) => {
          let errors = error?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              isFoundPetError: true,
              petFoundHeadline: "I have found a pet",
              foundPetErrorMessage: errors[0],
            });
            DataLayerUtils.formErrorEvent("found pet", null, errors[0]);
          }
        })
        .finally(() => {
          AppUtils.focusOnDiv("rp-top-div");
          hideButtonLoader(submitFoundPet);
          this.foundCaptcha.reset();
        });
    }
  };

  getBanner = () => {
    if (this.state.isLostPetSubmittedSuccessful) {
      return (
        <Banner
          title={this.state.lostPetSuccessMessage}
          color={Banner.Colors.success}
        ></Banner>
      );
    }

    if (this.state.isLostPetError) {
      return <Banner title={this.state.lostPetErrorMessage}></Banner>;
    }
  };

  getFoundPetBanner = () => {
    // if (this.state.isFoundPetSubmittedSuccessful) {
    //   return (
    //     <Banner
    //       title="Thank you, we have received your report."
    //       color={Banner.Colors.success}
    //     ></Banner>
    //   );
    // }

    if (this.state.isFoundPetError) {
      return <Banner title={this.state.foundPetErrorMessage}></Banner>;
    }
  };

  getErrorText = () => {
    return (
      <>
        We couldn't find this Pet Microchip or ID Number in our database. For assistance, please call {FeatureFlagUtils.getPhoneNumber()} to reach our Lost Pet Recovery Team.
      </>
    )
  }

  getFoundMessageText = () => {
    return (
      <>
        Good news, this pet is registered in our database! If you have this pet in your care,
        please enter the information requested below. By doing so, your name and
        phone number will be shared in an effort to reunite this pet with its owner.
        If you do not wish to share your information, please call {FeatureFlagUtils.getPhoneNumber()}.
      </>
    )
  }

  getComponents = () => {
    const countryStates = this.getStatesOrProvinces();
    const finderCountryStates = this.getStatesOrProvincesFinder();

    if (this.state.shouldShowLostFoundPage) {
      return (
        <ReportLostOrFoundPetCard
          lostPetButtonText= "Login to report a lost pet"
          lostPetButtonProps={{
            id: "Lost_Pet",
            variant: Button.Variants.outlined,
            onClick: (event) => {
              this.props.history.push("/auth/login");
            },
          }}
          foundPetButtonProps={{
            id: "Found_Pet",
            onClick: (event) => {
              this.setState({
                isFoundPet: true,
                isLostPet: false,
                shouldShowLostFoundPage: false,
              });
            },
          }}
        ></ReportLostOrFoundPetCard>
      );
    }

    if (this.state.isLostPet) {
      return (
        <div ref="lostReportRef">
          <ReportLostPetCard
            petNameInputProps={{
              id: "petName",
              name: "petName",
              value: this.state.petName,
              placeholder: "Pet name",
              error:
                AppUtils.isEmptyString(this.state.petName) &&
                this.state.isSubmitLostPetClicked,
              hint:
                AppUtils.isEmptyString(this.state.petName) &&
                this.state.isSubmitLostPetClicked
                  ? "Enter Pet Name"
                  : "",
              onChange: (event) => {
                this.handleChanges(event, "petName");
              },
            }}
            microchipNumberInputProps={{
              id: "microChipNumber",
              name: "microChipNumber",
              label: (
                <InputHelperWithTooltip
                  helperContent="Pet Microchip or ID Number"
                  tooltipContent={`To locate your pet's microchip, check the adoption papers. If you need assistance, call ${FeatureFlagUtils.getPhoneNumber()}.`}
                />
              ),
              value: this.state.microChipNumber,
              placeholder: "Pet Microchip or ID Number",
              error:
                AppUtils.isEmptyString(this.state.microChipNumber) &&
                this.state.isSubmitLostPetClicked,
              hint:
                "Please do not include any spaces or special characters when entering the Pet Microchip or ID Number.",
              onChange: (event) => {
                this.handleChanges(event, "microChipNumber");
              },
            }}
            addressInputProps={{
              id: "interSectionAddress",
              name: "interSectionAddress",
              placeholder: "Intersection/Address",
              error:
                AppUtils.isEmptyString(this.state.interSectionAddress) &&
                this.state.isSubmitLostPetClicked,
              hint:
                AppUtils.isEmptyString(this.state.interSectionAddress) &&
                this.state.isSubmitLostPetClicked
                  ? "Enter Address"
                  : "",
              value: this.state.interSectionAddress,
              onChange: (event) => {
                this.handleChanges(event, "interSectionAddress");
              },
            }}
            cityInputProps={{
              id: "city",
              name: "city",
              value: this.state.city,
              error:
                AppUtils.isEmptyString(this.state.city) &&
                this.state.isSubmitLostPetClicked,
              hint:
                AppUtils.isEmptyString(this.state.city) &&
                this.state.isSubmitLostPetClicked
                  ? "Enter City"
                  : "",
              placeholder: "City",
              onChange: (event) => {
                this.handleChanges(event, "city");
              },
            }}
            countrySelectProps={{
              id: "country",
              name: "country",
              value: this.state.country,
              error:
                AppUtils.isEmptyString(this.state.country) &&
                this.state.isSubmitLostPetClicked,
              hint:
                AppUtils.isEmptyString(this.state.country) &&
                this.state.isSubmitLostPetClicked
                  ? "Select Country"
                  : "",
              options: AppConstants.COUNTRIES,
              onChange: (event) => {
                this.handleChanges(event, "country");
              },
            }}
            stateSelectProps={{
              id: "state",
              name: "state",
              value: this.state.state,
              error:
                AppUtils.isEmptyString(this.state.state) &&
                this.state.isSubmitLostPetClicked,
              hint:
                AppUtils.isEmptyString(this.state.state) &&
                this.state.isSubmitLostPetClicked
                  ? "Select State"
                  : "",
              options: countryStates,
              onChange: (event) => {
                this.handleChanges(event, "state");
              },
            }}
            ownerNameInputProps={{
              id: "ownerName",
              name: "ownerName",
              value: this.state.ownerName,
              error:
                AppUtils.isEmptyString(this.state.ownerName) &&
                this.state.isSubmitLostPetClicked,
              hint:
                AppUtils.isEmptyString(this.state.ownerName) &&
                this.state.isSubmitLostPetClicked
                  ? "Enter Full Name"
                  : "",
              placeholder: "Full Name",
              onChange: (event) => {
                this.handleChanges(event, "ownerName");
              },
            }}
            phoneNumberInputProps={{
              id: "ownerPhoneNumber",
              name: "ownerPhoneNumber",
              value: this.state.ownerPhoneNumber,
              error:
                !AppUtils.isValidPhoneNumber(this.state.ownerPhoneNumber) &&
                this.state.isSubmitLostPetClicked,
              hint:
                !AppUtils.isValidPhoneNumber(this.state.ownerPhoneNumber) &&
                this.state.isSubmitLostPetClicked
                  ? "Enter Phone Number"
                  : "",
              placeholder: "Phone Number",
              onChange: (event) => {
                this.handleChanges(event, "ownerPhoneNumber");
              },
            }}
            emailInputProps={{
              id: "ownerEmailAddress",
              name: "ownerEmailAddress",
              value: this.state.ownerEmailAddress,
              error:
                !AppUtils.isValidEmail(this.state.ownerEmailAddress) &&
                this.state.isSubmitLostPetClicked,
              hint:
                !AppUtils.isValidEmail(this.state.ownerEmailAddress) &&
                this.state.isSubmitLostPetClicked
                  ? "Enter Email Address"
                  : "",
              placeholder: "Email Address",
              onChange: (event) => {
                this.handleChanges(event, "ownerEmailAddress");
              },
            }}
            backButtonProps={{
              onClick: (event) => {
                this.setState({
                  shouldShowLostFoundPage: true,
                });
              },
            }}
            submitButtonProps={{
              id:"submit_lost_pet",
              disabled: this.state.isLostPetSubmittedSuccessful,
              onClick: (event) => {
                this.submitLostInfo();
              },
            }}
            banner={this.getBanner()}
            checkboxProps={{
              checked: this.state.lostPetConsent,
              onChange: (event) => {
                this.setState({
                  lostPetConsent: !this.state.lostPetConsent,
                });
              },
            }}
            captcha={
              <ReCAPTCHA
                ref={(r) => (this.lostCaptcha = r)}
                sitekey={AppConstants.CONFIG_JSON.CAPTCHA_SITE_KEY}
                onChange={(event) => {
                  this.setState({
                    isLostCaptchaVerified: true,
                  });
                }}
                onExpired={(event) => {
                  this.setState({
                    isLostCaptchaVerified: false,
                  });
                }}
              />
            }
          ></ReportLostPetCard>
        </div>
      );
    }

    if (this.state.isFoundPet) {
      if(this.state.shouldShowDBSearchPage){
        return (
          <div ref="foundPetReportStep1">
            <ReportFoundPetStep1Card
              microchipNumberInputProps={{
                value: this.state.finderMicroChipNumber,
                name: "finderMicroChipNumber",
                id: "finderMicroChipNumber",
                placeholder: "Pet Microchip or ID Number",
                error: this.state.microChipNumberError,
                hint: (this.state.microChipNumberError)
                  ? "Please enter the Pet Microchip or ID Number"
                  : "",
                onChange: (event) => {
                  this.handleChanges(event, "finderMicroChipNumber");
                },
              }}
              skipLinkProps={{
                className:"dd-text-link-override",
                hidden: true,
                onClick:() =>{
                  // change state to show component false
                  this.setState({
                    shouldShowDBSearchPage:false,
                    isSearchSkipped:true
                  })
                }
              }}
              searchButtonProps={{
                color: Button.Colors.secondary,
                onClick: () => {
                  // call method to search in database for microchip
                  this.searchDatabase();
                  DataLayerUtils.reportLostPetEvent(
                    null,
                    this.state.finderMicroChipNumber,
                    'found a pet'
                  )
                }
              }}
              submitButtonProps={{
                color: Button.Colors.secondary,
                onClick: () => {
                  this.setState({
                    microChipNumberError: false,
                    shouldShowLostFoundPage: true,
                    finderMicroChipNumber: "",
                  })
                }
              }}
            >
            </ReportFoundPetStep1Card>
          </div>
        )
      }else{
        return (
          <div ref="foundPetReport">
            <ReportFoundPetCard
              paperHeadline={this.state.petFoundHeadline}
              isLookUpSkipped={this.state.isSearchSkipped}
              isLookUpSuccess={this.state.searchSuccess}
              petAvatar={this.state.petPhoto}
              isAvatar={(this.state.petPhoto)? true : false}
              petName={this.state.foundPetName}
              ownerName={this.state.foundPetOwnerName}
              microchipNumber={this.state.finderMicroChipNumber}
              gender={this.state.gender}
              breed={this.state.breed}
              banner={this.getFoundPetBanner()}
              errorText={this.getErrorText()}
              foundMessageText={this.getFoundMessageText()}
              microchipNumberInputProps={{
                value: this.state.finderMicroChipNumber,
                name: "finderMicroChipNumber",
                id: "finderMicroChipNumber",
                placeholder: "Pet Microchip or ID Number",
                disabled: !this.state.searchSuccess,
              }}
              addressInputProps={{
                value: this.state.finderAddress,
                id: "finderAddress",
                name: "finderAddress",
                placeholder: "Intersection",
                error:
                  AppUtils.isEmptyString(this.state.finderAddress) &&
                  this.state.isSubmitFoundPetClicked,
                hint:
                  AppUtils.isEmptyString(this.state.finderAddress) &&
                  this.state.isSubmitFoundPetClicked
                    ? "Enter Address"
                    : "",
                onChange: (event) => {
                  this.handleChanges(event, "finderAddress");
                },
              }}
              cityInputProps={{
                value: this.state.finderCity,
                id: "finderCity",
                name: "finderCity",
                placeholder: "City",
                error:
                  AppUtils.isEmptyString(this.state.finderCity) &&
                  this.state.isSubmitFoundPetClicked,
                hint:
                  AppUtils.isEmptyString(this.state.finderCity) &&
                  this.state.isSubmitFoundPetClicked
                    ? "Enter City"
                    : "",
                onChange: (event) => {
                  this.handleChanges(event, "finderCity");
                },
              }}
              countrySelectProps={{
                value: this.state.finderCountry,
                name: "finderCountry",
                id: "finderCountry",
                error:
                  AppUtils.isEmptyString(this.state.finderCountry) &&
                  this.state.isSubmitFoundPetClicked,
                hint:
                  AppUtils.isEmptyString(this.state.finderCountry) &&
                  this.state.isSubmitFoundPetClicked
                    ? "Select Country"
                    : "",
                options: AppConstants.COUNTRIES,
                onChange: (event) => {
                  this.handleChanges(event, "finderCountry");
                },
              }}
              stateSelectProps={{
                value: this.state.finderState,
                name: "finderState",
                id: "finderState",
                options: finderCountryStates,
                error:
                  AppUtils.isEmptyString(this.state.finderState) &&
                  this.state.isSubmitFoundPetClicked,
                hint:
                  AppUtils.isEmptyString(this.state.finderState) &&
                  this.state.isSubmitFoundPetClicked
                    ? "Select State"
                    : "",
                onChange: (event) => {
                  this.handleChanges(event, "finderState");
                },
              }}
              ownerNameInputProps={{
                name: "finderName",
                value: this.state.finderName,
                id: "finderName",
                placeholder: "First name",
                error:
                  AppUtils.isEmptyString(this.state.finderName) &&
                  this.state.isSubmitFoundPetClicked,
                hint:
                  AppUtils.isEmptyString(this.state.finderName) &&
                  this.state.isSubmitFoundPetClicked
                    ? "Enter Full Name"
                    : "",
                onChange: (event) => {
                  this.handleChanges(event, "finderName");
                },
              }}
              phoneNumberInputProps={{
                value: this.state.finderPhone,
                name: "finderPhone",
                id: "finderPhone",
                placeholder: "Phone Number",
                maxLength: "12",
                error:
                  !AppUtils.isValidPhoneNumber(this.state.finderPhone) &&
                  this.state.isSubmitFoundPetClicked,
                hint:
                  !AppUtils.isValidPhoneNumber(this.state.finderPhone) &&
                  this.state.isSubmitFoundPetClicked
                    ? "Enter Phone Number"
                    : "",
                onChange: (event) => {
                  this.handleChanges(event, "finderPhone");
                },
              }}
              emailInputProps={{
                value: this.state.finderEmail,
                name: "finderEmail",
                id: "finderEmail",
                placeholder: "Email Address",
                error:
                  !AppUtils.isValidEmail(this.state.finderEmail) &&
                  this.state.isSubmitFoundPetClicked,
                hint:
                  !AppUtils.isValidEmail(this.state.finderEmail) &&
                  this.state.isSubmitFoundPetClicked
                    ? "Enter Email Address"
                    : "",
                onChange: (event) => {
                  this.handleChanges(event, "finderEmail");
                },
              }}
              notesInputProps={{
                value: this.state.finderNotes,
                name: "finderNotes",
                id: "finderNotes",
                placeholder: "Notes",
                onChange: (event) => {
                  this.handleChanges(event, "finderNotes");
                },
              }}
              checkboxProps={{
                hidden: true,
                checked: this.state.finderConsent,
                onChange: (event) => {
                  this.setState({
                    finderConsent: !this.state.finderConsent,
                  });
                },
              }}
              backButtonProps={{
                color: Button.Colors.secondary,
                onClick: (event) => {
                  this.setState({
                    shouldShowLostFoundPage: true,
                    shouldShowDBSearchPage: true,
                    finderMicroChipNumber: "",
                    petFoundHeadline: "I have found a pet",
                  });
                },
              }}
              submitButtonProps={{
                color: Button.Colors.secondary,
                id: "submit_found_pet",
                disabled: this.state.isFoundPetSubmittedSuccessful || !this.state.searchSuccess,
                onClick: (event) => {
                  this.submitFoundReport();
                },
              }}
              captcha={
                <ReCAPTCHA
                  ref={(r) => (this.foundCaptcha = r)}
                  sitekey={AppConstants.CONFIG_JSON.CAPTCHA_SITE_KEY}
                  onChange={(event) => {
                    this.setState({
                      isFoundCaptchaVerified: true,
                    });
                  }}
                  onExpired={(event) => {
                    this.setState({
                      isFoundCaptchaVerified: false,
                    });
                  }}
                />
              }
              id="reportFoundPet"
            ></ReportFoundPetCard>
            <FoundPetReportSubmittedModal
              open={this.state.isFoundPetSubmittedModalOpen}
              onClose={() => {
                this.setState({ isFoundPetSubmittedModalOpen: false });
              }}
              closeButtonProps={{
                onClick: (e) => {
                  this.props.history.push("/auth/login");
                  e.preventDefault();
                }
              }}
              submitAnotherReportButtonProps={{
                onClick: () => {
                  this.setState({
                    shouldShowDBSearchPage: true,
                    finderMicroChipNumber: "",
                    isFoundPetSubmittedSuccessful: false,
                    petFoundHeadline: "I have found a pet",
                    isFoundPetSubmittedModalOpen: false,
                  })
                }
              }}
            >
            </FoundPetReportSubmittedModal>
          </div>
        );
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.location.state?.reloadKey !== this.props.location.state?.reloadKey){
      this.setToDefaultState();
    }
  }

  render() {
    return (
      <PageContent>
        <div id="rp-top-div">{this.getComponents()}</div>
      </PageContent>
    );
  }
}

export default withRouter(ReportPet);

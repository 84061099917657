import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Text,
  IconButton,
  Link
} from '../../'

export class FoundPetMesssageModalCard extends Component {
  static propTypes = {
    open: PropTypes.bool,

    onClose: PropTypes.func,

    closeButtonIconProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    ownerName: PropTypes.string,

    date: PropTypes.string,

    petName: PropTypes.string,

    petMicrochipNumber: PropTypes.string,

    findersDetail: PropTypes.shape({
      finderName: PropTypes.string,
      finderAddress: PropTypes.string,
      finderPhoneNumber: PropTypes.string,
      finderEmail: PropTypes.string,
      ImportantNotes: PropTypes.string
    }),

    turnOffAlertButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    goBackLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    })
  }

  render() {
    const {
      open,
      onClose,
      closeButtonIconProps,
      date,
      ownerName,
      petName,
      petMicrochipNumber,
      findersDetail,
      turnOffAlertButtonProps,
      goBackLinkProps
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-found-pet-message-modal'>
          <Paper types={Paper.Types.clean}>
            <PaperHeadline />

            <PaperContent className='ui-found-pet-message-modal-content'>
              <IconButton
                type='close'
                className='ui-found-pet-message-modal-close-icon'
                {...closeButtonIconProps}
              />

              <Text
                types={Text.Types.bodyBold}
                className='ui-found-pet-message-modal-date'
              >
                {date}
              </Text>

              <Text
                types={Text.Types.body}
                className='ui-found-pet-message-modal-name'
              >
                Hi {ownerName},
              </Text>

              <Text
                className='ui-found-pet-message-modal-message'
                types={Text.Types.body}
              >
                You'll be relieved to know that <span>{petName}</span>
                {petMicrochipNumber} has been found! We know that you're excited
                to reunite, so call the finder below to coordinate a pickup.
              </Text>

              <div className='ui-found-pet-message-modal-finder-info'>
                <div>
                  <span>Finder's name:</span> {findersDetail.finderName}
                </div>

                <div>
                  <span>Finder's address:</span> {findersDetail.finderAddress}
                </div>

                <div>
                  <span>Finder's phone number:</span>{' '}
                  {findersDetail.finderPhoneNumber}
                </div>

                <div>
                  <span>Finder's email:</span> {findersDetail.finderEmail}
                </div>

                <div>
                  <span>Important notes:</span> {findersDetail.ImportantNotes}
                </div>
              </div>

              <Text type={Text.Types.body}>
                Once {petName} is back at home, be sure to turn off the Found
                Pet Alert®
              </Text>

              <Text type={Text.Types.body}>
                Here's to good news, and thank you for microchipping your pet!
              </Text>

              <div className='ui-found-pet-message-modal-close-alert-button'>
                <Button
                  variant={Button.Variants.outlined}
                  {...turnOffAlertButtonProps}
                >
                  Turn off Found Pet Alert
                </Button>
              </div>

              <div className='ui-found-pet-message-modal-go-back-link'>
                <Link {...goBackLinkProps}>Go Back</Link>
              </div>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

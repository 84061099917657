import React from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Form,
  FileInput,
  Text
} from '../../'

export class UploadImageModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,

    errorMessage: PropTypes.string,
    imageInputProps: PropTypes.shape({
      name: PropTypes.string,
      capture: PropTypes.string,
      accept: PropTypes.string,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      error: PropTypes.bool,
      fileName: PropTypes.string,
      onChange: PropTypes.func,
      /**
       * `id` is required prop! without it file input might now work
       */
      id: PropTypes.string.isRequired
    }),

    cancelButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    saveButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  render() {
    const {
      open,
      onClose,
      errorMessage,
      imageInputProps,
      cancelButtonProps,
      saveButtonProps
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-upload-image-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline>Upload photo</PaperHeadline>
            <PaperContent>
              {errorMessage && (
                <Text
                  className='ui-upload-image-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}

              <Form>
                <FileInput
                  id='testing'
                  label='Choose a photo to upload:'
                  {...imageInputProps}
                />
                <div className='ui-upload-image-modal__actions'>
                  <Button
                    variant={Button.Variants.outlined}
                    type='button'
                    {...cancelButtonProps}
                  >
                    Cancel
                  </Button>
                  <Button type='submit' {...saveButtonProps}>
                    Save changes
                  </Button>
                </div>
              </Form>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

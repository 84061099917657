import axios from "axios";
import { AppUtils } from "./utils/app-utils";
import { LoadMask } from "./utils/loadmask.util";
import { ApiConstants } from "./utils/api-constants";
const maskElUrlMap = {};
const _HANDLER = {};

_HANDLER.processGet = function (url, cRef, options, isClean) {
  return new Promise((resolve, reject) => {
    url = `${ApiConstants.URL_SERVER}${url}`;
    if (cRef) {
      maskElUrlMap[url] = cRef?.current;
      if (maskElUrlMap[url]) {
        LoadMask.showPreloader(maskElUrlMap[url]);
      }
    }

    instance
      .get(url, options)
      .then((response) => {
        hideLoadMask(url);
        if (isClean) {
          resolve(response);
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        hideLoadMask(url);
        reject(error);
      });
  });
};
function handleError(error) {
  if (401 === error.response?.status) {
    AppUtils.logUserOutUsingCallback();
  } else if (
    error.response?.status >= 500 ||
    error?.message === "Network Error"
  ) {
    AppUtils.showGenericError();
  }
  return Promise.reject(error);
}
function hideLoadMask(url) {
  if (maskElUrlMap[url]) {
    LoadMask.hidePreloader(maskElUrlMap[url]);
    delete maskElUrlMap[url];
  }
}
_HANDLER.processPost = function (url, cRef, body, params, isClean) {
  return new Promise((resolve, reject) => {
    url = `${ApiConstants.URL_SERVER}${url}`;
    if (cRef) {
      maskElUrlMap[url] = cRef?.current;
      if (maskElUrlMap[url]) {
        LoadMask.showPreloader(maskElUrlMap[url]);
      }
    }

    instance
      .post(url, body, params)
      .then((response) => {
        if (isClean) {
          resolve(response);
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        if (maskElUrlMap[url]) {
          LoadMask.hidePreloader(maskElUrlMap[url]);
          delete maskElUrlMap[url];
        }
      });
  });
};

_HANDLER.processDelete = function (url, cRef, body, params, isClean) {
  return new Promise((resolve, reject) => {
    url = `${ApiConstants.URL_SERVER}${url}`;
    if (cRef) {
      maskElUrlMap[url] = cRef?.current;
      if (maskElUrlMap[url]) {
        LoadMask.showPreloader(maskElUrlMap[url]);
      }
    }
    instance({
      method: 'delete',
      url: url,
      data: body
    })
      .then((response) => {
        if (isClean) {
          resolve(response);
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        if (maskElUrlMap[url]) {
          LoadMask.hidePreloader(maskElUrlMap[url]);
          delete maskElUrlMap[url];
        }
      });
  });
};

_HANDLER.processPut = function (url, cRef, options, isClean) {
  return new Promise((resolve, reject) => {
    url = `${ApiConstants.URL_SERVER}${url}`;
    if (cRef) {
      maskElUrlMap[url] = cRef?.current;
      if (maskElUrlMap[url]) {
        LoadMask.showPreloader(maskElUrlMap[url]);
      }
    }

    instance
      .put(url, options)
      .then((response) => {
        hideLoadMask(url);
        if (isClean) {
          resolve(response);
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        hideLoadMask(url);
        reject(error);
      });
  });
};


const instance = axios.create();
instance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return handleError(error);
  }
);
export default _HANDLER;

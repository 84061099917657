import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ReactSelect from 'react-select'

import { Text } from '../../'
import { Hint } from '../Input/InputComponents'

export class SelectWithSearch extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      // track input focused state to apply an appropriate class to label
      focused: false
    }

    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  static propTypes = {
    error: PropTypes.bool,
    useFilterOptions: PropTypes.bool,
    helper: PropTypes.any,
    hint: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    label: PropTypes.any,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    ),
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    searchable: PropTypes.bool,
    inputValue: PropTypes.string,
    onInputChange: PropTypes.func,
    isLoading: PropTypes.bool,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    options: [],
    disabled: false,
    error: false,
    searchable: false
  }

  handleFocus = (e) => {
    this.setState({ focused: true })
    this.props.onFocus?.(e)
  }

  handleBlur = (e) => {
    this.setState({ focused: false })
    this.props.onBlur?.(e)
  }

  render() {
    const { focused } = this.state
    const filterOptions = (candidate, input) => {
      return true
    }
    const {
      error,
      helper,
      hint,
      label,
      value,
      options,
      id,
      inputValue,
      onInputChange,
      isLoading,
      className,
      style,
      refProp,
      disabled,
      onChange,
      placeholder,
      searchable,
      useFilterOptions
    } = this.props

    const componentClasses = getClasses(focused, error, disabled)

    return (
      <div
        className={cn(componentClasses.container, className)}
        style={style}
        ref={refProp}
      >
        {label && (
          <label className={cn(componentClasses.label)} htmlFor={id}>
            {label}
          </label>
        )}

        {helper && (
          <Text
            className={cn(componentClasses.helper)}
            type={Text.Types.caption}
          >
            {helper}
          </Text>
        )}

        {useFilterOptions ? (
          <ReactSelect
            id={id}
            isDisabled={disabled}
            value={findValueObject(options, value)}
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            options={options}
            className={cn(componentClasses.select)}
            classNamePrefix='ui-react-select'
            isSearchable={searchable}
            placeholder={placeholder}
            inputValue={inputValue}
            filterOption={filterOptions}
            onInputChange={onInputChange}
            isLoading={isLoading}
          />
        ) : (
          <ReactSelect
            id={id}
            isDisabled={disabled}
            value={findValueObject(options, value)}
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            options={options}
            className={cn(componentClasses.select)}
            classNamePrefix='ui-react-select'
            isSearchable={searchable}
            placeholder={placeholder}
            inputValue={inputValue}
            onInputChange={onInputChange}
            isLoading={isLoading}
          />
        )}

        {hint && <Hint hint={hint} className={cn(componentClasses.hint)} />}
      </div>
    )
  }
}

const getClasses = (focused, error, disabled) => {
  const classes = {
    container: ['ui-select-with-search__container'],
    label: ['ui-select-with-search__label'],
    helper: ['ui-select-with-search__helper'],
    selectWrapper: ['ui-select-with-search--wrapper'],
    select: ['ui-select-with-search'],
    hint: ['ui-select-with-search__hint']
  }

  focused && classes.container.push('ui-select-with-search__container--focused')
  disabled &&
    classes.container.push('ui-select-with-search__container--disabled')
  error &&
    !disabled &&
    classes.container.push('ui-select-with-search__container--error')
  error && !disabled && classes.select.push('ui-select-with-search--error')

  return classes
}

const findValueObject = (options = [], value) =>
  options.find((option) => value === option.value)

import { combineReducers } from "@reduxjs/toolkit";
import CustomerProfileReducer from "./customer-profile-reducer";
import AuthReducer from "./auth-reducer";
import MembershipPurchaseReducer from "./membership-purchase-reducer";
import GlobalReducer from "./global-reducer";

export default combineReducers({
  customerProfileStore: CustomerProfileReducer,
  authStore: AuthReducer,
  membershipPurchaseStore: MembershipPurchaseReducer,
  globalStore: GlobalReducer,
});

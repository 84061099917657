import React from "react";
import {
  ShoppingCart,
  PageContent,
  TwoColumnContent,
  NavigationButton,
  AddPromoCodeModal,
} from "../../../UI-Component-Library";
import MakePaymentWrapper from "./make-payment-wrapper";
import { connect } from "react-redux";
import { AppUtils } from "../../../utils/app-utils";
import { withRouter } from "react-router-dom";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import {
  backupCartData,
  clearCartData,
  saveCartData,
  removeFromShoppingCartItems
} from "../../../redux/reducers/membership-purchase-reducer";
import { MembershipPurchaseUtils } from "../membership/membership-purchase.util";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
class Checkout extends React.Component {
  constructor() {
    super();
    this.state = {
      cartItems: [],
      isPromocodeModalOpen: false,
      promoCode: "",
      petCart: {},
      errorMessage: "",
      promoCodeErrorMessage: "",
    };
    this.cRef = React.createRef();
  }
  componentDidMount() {
    let cartData = this.props.cartData;
    if (!cartData) {
      cartData = MembershipPurchaseUtils.getCartDataFromLocalStorage();
      this.props.saveCartData(cartData);
    }
    this.extractCartData(cartData);
  }
  extractCartData(cartData, isCheckout= false) {
    const orderItems = [];
    const petCart = {};
    if (cartData && cartData.orderLines) {
      for (let order of cartData?.orderLines) {
        if (!petCart[order.animalId]) {
          petCart[order.animalId] = [];
        }
        petCart[order.animalId].push({
          productName: AppUtils.getProductNameFromItemId(order.itemId, order.itemName),
          productCost: AppUtils.getConvertedPrice(order.unitPrice),
          ...order,
        });
      }
    }
    // Show only lpm bundle
    for (let key in petCart) {
      const filteredProducts = [];
      let lpmProduct;
      let lpmTotalPrice = 0;
      let lpmPlusProduct;
      let lpmPlusTotalPrice = 0;
      let winbackProduct, winbackTotalPrice = 0;
      for (let product of petCart[key]) {
        if (AppUtils.isLpmProduct(product)) {
          lpmProduct = product;
          continue;
        }
        if (product.itemType === "LPMBundleProduct") {
          lpmTotalPrice = lpmTotalPrice + product.unitPrice;
          continue;
        }

        //for lpmPlusMembership
        if (AppUtils.isLpmPlusProduct(product)) {
          lpmPlusProduct = product;
          continue;
        }
        if (AppUtils.isLpmPlusBundleProduct(product)) {
          lpmPlusTotalPrice = lpmPlusTotalPrice + product.unitPrice;
          continue;
        }

        // for winback offer
        if (AppUtils.isWinbackProduct(product)) {
          winbackProduct = product;
          continue;
        }
        if (AppUtils.isWinbackBundleProduct(product)) {
          winbackTotalPrice = winbackTotalPrice + product.unitPrice;
          continue;
        }
        filteredProducts.push(product);
      }
      if (lpmProduct) {
        lpmProduct.productCost = AppUtils.getConvertedPrice(lpmTotalPrice);
        filteredProducts.push(lpmProduct);
      }

      if (lpmPlusProduct) {
        lpmPlusProduct.productCost = AppUtils.getConvertedPrice(lpmPlusTotalPrice);
        filteredProducts.push(lpmPlusProduct);
      }

      if (winbackProduct) {
        winbackProduct.productCost = AppUtils.getConvertedPrice(winbackTotalPrice);
        filteredProducts.push(winbackProduct);
      }
      petCart[key] = filteredProducts;
    }
    for (let key in petCart) {
      orderItems.push({
        title: (isCheckout)
          ? ''
          : "For " +
            MembershipPurchaseUtils.getAnimalName(
              key,
              this.props.customerAnimals
            ),
        items: petCart[key],
      });
    }
    if (isCheckout) {
      return orderItems;
    }
    DataLayerUtils.viewCartEvent(orderItems, cartData?.total);
    this.setState({
      cartItems: orderItems,
      petCart,
    });
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  applyPromoCode() {
    this.clearErrorMessage();
    if (AppUtils.isEmptyString(this.state.promoCode)) {
      this.setState({
        promoCodeErrorMessage: "Promocode should not be empty",
      });
      return;
    }
    axios
      .processGet(
        `${ApiConstants.URL_APPLY_PROMOCODE}${this.props.cartData.cartId}/${this.state.promoCode}`
      )
      .then((cartData) => {
        this.props.saveCartData(cartData);

        this.setState(
          {
            isPromocodeModalOpen: false,
          },
          () => {
            this.extractCartData(this.props.cartData);
          }
        );
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            promoCodeErrorMessage: errors[0],
          });
        }
      });
  }
  clearErrorMessage() {
    this.setState({
      errorMessage: "",
      promoCodeErrorMessage: "",
    });
  }
  removeOrder(petIndex, itemIndex) {
    this.clearErrorMessage();
    const petId = Object.keys(this.state.petCart)[petIndex];
    const items = this.state.petCart[petId];
    const foundItem = items.find((item, index) => index === itemIndex);
    DataLayerUtils.removeFromCartEvent(foundItem);
    axios
      .processGet(
        `${ApiConstants.URL_REMOVE_ORDER}${this.props.cartData.cartId}/${
          this.state.promoCode ? this.state.promoCode : "none"
        }/${foundItem.itemType}/${foundItem.basketLineId}`,
        this.cRef
      )
      .then((cartData) => {
        this.props.saveCartData(cartData);
        if (Object.keys(this.props.petShoppingCart).length > 0) {
          const indexes = MembershipPurchaseUtils.getPetIndexAndItemIndex(
            this.props.petShoppingCart,
            petId,
            foundItem.itemId
          );
          indexes["shouldSync"] = true;
          this.props.removeFromShoppingCartItems(indexes);
        }
        this.setState(
          {
            isPromocodeModalOpen: false,
          },
          () => {
            this.extractCartData(this.props.cartData);
          }
        );
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
        }
      });
  }
  render() {
    return (
      <PageContent>
        <AddPromoCodeModal
          errorMessage={this.state.promoCodeErrorMessage}
          open={this.state.isPromocodeModalOpen}
          onClose={() => {}}
          promoCodeInputProps={{
            value: this.state.promoCode,
            id: "promo_code_input",
            name: "promoCode",
            // error: this.state.promoCodeError,
            // hint: this.state.promoCodeError
            //   ? ValidationMessages.EMAIL_VALIDATION_MESSAGE
            //   : "",
            onChange: this.handleInputChange,
          }}
          cancelButtonProps={{
            id: "promo_code_cancel_button",
            onClick: () => {
              this.setState({
                promoCode: "",
                isPromocodeModalOpen: false,
                promoCodeErrorMessage: "",
              });
            },
          }}
          applyButtonProps={{
            id: "promo_code_apply_button",
            onClick: () => {
              this.applyPromoCode();
            },
          }}
        />

        <NavigationButton
          label="Back to dashboard"
          onClick={() => {
            this.props.backupCartData();
            this.props.clearCartData();
            this.props.history.push("/pages");
          }}
        />

        <div ref={this.cRef}>
          <TwoColumnContent
            leftColumn={
              <ShoppingCart
                errorMessage={this.state.errorMessage}
                cartItems={this.state.cartItems}
                onRemoveClick={(index1, index2) => {
                  this.removeOrder(index1, index2);
                }}
                totalBeforeTax={AppUtils.getConvertedPrice(
                  this.props.cartData?.subTotal
                )}
                promoProps={{
                  id: "promoButton",
                  label: this.props.cartData?.promoDiscount?.isApplied
                    ? this.props.cartData?.promoDiscount.description
                    : "Add promo code",
                  onClick: () => {
                    if (!this.props.cartData?.promoDiscount?.isApplied) {
                      this.setState({
                        isPromocodeModalOpen: true,
                      });
                    }
                  },
                }}
                totalTaxes={AppUtils.getConvertedPrice(
                  this.props.cartData?.taxes
                )}
                subtotal={AppUtils.getConvertedPrice(
                  this.props.cartData?.total
                )}
                shippingAmount={AppUtils.getConvertedPrice(
                  this.props.cartData?.shipping
                )}
              />
            }
            rightColumn={
              <MakePaymentWrapper
                disableCheckoutButton={this.props.cartData?.total === 0}
                extractCartData={this.extractCartData}
              ></MakePaymentWrapper>
            }
          ></TwoColumnContent>
        </div>
      </PageContent>
    );
  }
}
const mapStateToProps = (state) => {
  const membershipPurchaseStore = state.membershipPurchaseStore;
  const customerProfileStore = state.customerProfileStore;

  return {
    cartData: membershipPurchaseStore.cartData,
    customerAnimals: customerProfileStore.customerAnimals,
    petShoppingCart: membershipPurchaseStore.petShoppingCart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCartData: (data) => dispatch(saveCartData(data)),
    clearCartData: () => dispatch(clearCartData()),
    backupCartData: () => dispatch(backupCartData()),
    removeFromShoppingCartItems: (data) =>
      dispatch(removeFromShoppingCartItems(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Checkout));
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Text
} from '../..'

export class NewFAUserModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    callInMessage: PropTypes.string,
    cancelButtonProps: PropTypes.shape({
        id: PropTypes.string,
        size: PropTypes.string,
        onClick: PropTypes.func
    }),
    buttonText: PropTypes.string,
    modalHeadline: PropTypes.string,
  }
    
  static defaultProps = {
    buttonText: "Ok",
    modalHeadline: "General Modal"
  }

  render() {
    const {
      open,
      onClose,
      callInMessage,
      cancelButtonProps,
      buttonText,
      modalHeadline,
    } = this.props


    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-new-fa-user-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline>{modalHeadline}</PaperHeadline>
            <PaperContent>
              <Text 
                className='ui-new-fa-user-modal__text'
                type={Text.Types.body}
              >
                You and your pet’s information has moved to the 24Petwatch Registry. 
                To login, simply <strong>enter the email address associated with your Found Animals account</strong> and you will be prompted to set a password for your 24Petwatch account.
              </Text>
              <div className='ui-new-fa-user-modal__actions'>
                <Button
                  type='button'
                  color={Button.Colors.tertiary}
                  {...cancelButtonProps}
                >
                  {buttonText}
                </Button>
              </div>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}
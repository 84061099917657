import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { HeaderDivider, Button, Icon, Text } from '../../'
import {
  PageHeaderProps,
  PageHeaderButton,
  LogoSvg
} from './PageHeaderComponents'
import { DataLayerUtils } from '../../../utils/dataLayer-utils'

const MobilePageHeader = ({
  logo,
  onLogoClick,
  showPetMenu,
  showMobileMenu,
  petMenuProps,
  showAccount,
  showDropDown,
  accountMenuProps,
  submitClaimButtonProps,
  showSubmitClaim,
  showReportLostPetButton,
  showPetProPortalButton,
  petProPortalButtonProps,
  reportLostPetButtonProps,
  reportLostPetButtonText,

  id,
  className,
  style,
  refProp
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false)

  const { nameOnAccount, ...restAccountProps } = accountMenuProps;

  return (
    <div
      id={id}
      className={cn('ui-page-header', 'ui-page-header--mobile', className)}
      style={style}
      ref={refProp}
    >
      <div className='ui-page-header__logo' role='button' onClick={onLogoClick}>
        {logo ? logo : <LogoSvg />}
      </div>

      {showMobileMenu && <div className='ui-page-header__items'>
        <PageHeaderButton
          rightIconType={menuOpen ? 'close' : 'arrow_drop_down'}
          onClick={() => {
            DataLayerUtils.navigationEvent("mobile", "menu");
            setMenuOpen(!menuOpen)
          }}
        >
          {menuOpen ? 'Close' : 'Menu'}{' '}
          {showDropDown && accountMenuProps.showNotificationMark && (
            <div className='ui-page-header-notification-mark'></div>
          )}
        </PageHeaderButton>
      </div>}

      {menuOpen && (
        <div className='ui-page-header__mobile-menu'>
          <div className='ui-page-header__mobile-menu__container'>
            {showPetMenu && (
              <MobilePetMenu
                {...petMenuProps}
                closeMenu={() => setMenuOpen(false)}
              />
            )}

            {(showAccount && showDropDown) && (<MobileAccountMenu {...accountMenuProps} closeMenu={() => setMenuOpen(false)} />)}
            {(showAccount && !showDropDown) && ( <div className='ui-page-header__mobile__pets'>
                    <PageHeaderButton
                      leftIconType='account_circle'
                      id={restAccountProps?.id}
                      onClick={() => {
                        restAccountProps?.onAccountClick()
                        setMenuOpen(false)
                      }}
                    >
                      {nameOnAccount || 'Account'}
                    </PageHeaderButton>
                </div>)
            }

            <div className='ui-page-header__mobile-menu__actions'>

              {showSubmitClaim && (
                <div className='ui-page-header__mobile-menu-item'>
                  <Button
                    color={Button.Colors.success}
                    size={Button.Sizes.small}
                    fluid
                    id={submitClaimButtonProps?.id}
                    onClick={() => {
                      submitClaimButtonProps?.onClick()
                      setMenuOpen(false)
                    }}
                    {...submitClaimButtonProps}
                  >
                    Submit a claim
                  </Button>
                </div>
              )}
              {showPetProPortalButton && (
                <div className='ui-page-header__mobile-menu-item'>
                  <Button
                    size={Button.Sizes.small}
                    variant={Button.Variants.outlined}
                    fluid
                    className={"ui-page-header__petpro-portal"}
                    id={petProPortalButtonProps?.id}
                    onClick={() => {
                      petProPortalButtonProps?.onClick()
                      setMenuOpen(false)
                    }}
                  >
                   <span className='ui-page-header__petpro-text'>Pet Professionals</span><Icon type="open_in_new"></Icon>
                  </Button>
                </div>
              )}
              {showReportLostPetButton && (
                <div className='ui-page-header__mobile-menu-item'>
                  <Button
                    color={Button.Colors.tertiary}
                    size={Button.Sizes.small}
                    fluid
                    id={reportLostPetButtonProps?.id}
                    onClick={() => {
                      reportLostPetButtonProps?.onClick()
                      setMenuOpen(false)
                    }}
                  >
                    {reportLostPetButtonText}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
MobilePageHeader.propTypes = PageHeaderProps

export default MobilePageHeader

const MobilePetMenu = ({
  elementId,
  pets = [],
  selectedPetId,
  onSelectedPetChange,
  newPetButtonProps,
  closeMenu,
  onMenuClick
}) => (
  <div id={elementId} className='ui-page-header__mobile__pets'>
    <div>
      <HeaderDivider
        className='cursor-pointer'
        onClick={() => {
          onMenuClick?.()
          closeMenu()
        }}
      >
        My pets
      </HeaderDivider>
    </div>

    {Object.keys(pets).length > 0 && (
      <div className='ui-page-header__mobile__pet-list'>
        {pets.map((pet) => (
          <MobilePetMenuItem
            key={pet.id}
            buttonId={`menu${pet.id}`}
            petName={pet.name}
            selected={selectedPetId === pet.id}
            onClick={() => {
              onSelectedPetChange?.(pet.id)
              closeMenu()
            }}
          />
        ))}
      </div>
    )}

    <div className='ui-page-header__mobile__pets__add-action'>
      <PageHeaderButton
        id={newPetButtonProps.id}
        onClick={() => {
          newPetButtonProps?.onClick?.()
          closeMenu()
        }}
        leftIconType='pets'
      >
        Add another pet
      </PageHeaderButton>
    </div>
  </div>
)
MobilePetMenu.propTypes = {
  elementId: PropTypes.string,
  onMenuClick: PropTypes.func,
  pets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  onSelectedPetChange: PropTypes.func,
  selectedPetId: PropTypes.string,
  newPetButtonProps: PropTypes.shape({
    onClick: PropTypes.func,
    id: PropTypes.string
  }),
  closeMenu: PropTypes.func
}

const MobileAccountMenu = ({
  id,
  nameOnAccount,
  onAccountClick,
  selectedItemId,
  onSelectedItemChange,
  items = [],
  closeMenu,
  showNotificationMark
}) => (
  <div id={id} className='ui-page-header__mobile__pets ui-page-header__mobile__account'>
      <div>
        <HeaderDivider
          className='cursor-pointer ui-page-header__mobile__account__header'
          onClick={() => {
            onAccountClick?.()
            closeMenu()
          }}
        >
        <Icon
          className='ui-page-header__page-header__mobile-button__icon--left'
          type='account_circle'
        />
        <Text type={Text.Types.title}>
          {nameOnAccount || 'Account'}
        </Text>
        </HeaderDivider>
      </div>

    {Object.keys(items).length > 0 && (
      <div className='ui-page-header__mobile__pet-list'>
        {items.map((item) => (
          <MobilePetMenuItem
            key={item.id}
            buttonId={item.id}
            petName={item.name}
            selected={selectedItemId === item.id}
            onClick={() => {
              onSelectedItemChange?.(item.id)
              closeMenu()
            }}
            showNotificationMark={showNotificationMark}
          />
        ))}
      </div>
    )}
  </div>
)
MobileAccountMenu.propTypes = {
  id: PropTypes.string,
  nameOnAccount: PropTypes.string,
  onAccountClick: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  onSelectedItemChange: PropTypes.func,
  selectedItemId: PropTypes.string,
  closeMenu: PropTypes.func,
  showNotificationMark: PropTypes.bool
}

const MobilePetMenuItem = ({
  buttonId,
  selected,
  onClick,
  petName,
  showNotificationMark
}) => (
  <div className='ui-page-header__mobile__pet-list__item'>
    <PageHeaderButton
      id={buttonId}
      className={
        selected ? 'ui-page-header__mobile__pet-list__item--selected' : ''
      }
      leftIconType={selected ? 'check' : null}
      onClick={onClick}
    >
      <div>
        <div>{petName}</div>
        {petName === 'Notifications' && showNotificationMark && (
          <div className='ui-page-header-notification-mark_'></div>
        )}
      </div>
    </PageHeaderButton>
  </div>
)
MobilePetMenuItem.propTypes = {
  buttonId: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  petName: PropTypes.string,
  showNotificationMark: PropTypes.bool
}
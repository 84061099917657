import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {
  RmPasswordValidationCrossMark,
  RmPasswordValidationCheckMark
} from '../SvgIcons'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Form,
  Input,
  FormAction,
  Button,
  Text,
  Checkbox
} from '../..'

export class RmCreatePassword extends React.PureComponent {
  static propTypes = {
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    onFormSubmit: PropTypes.func,
    is8CharsEntered: PropTypes.bool,
    isAtLeastOneNumberEntered: PropTypes.bool,
    isUppercaseEntered: PropTypes.bool,
    isLowercaseEntered: PropTypes.bool,
    showValidations: PropTypes.bool,
    passwordInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    confirmPasswordInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    consentCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string,
      errorMessage: PropTypes.string
    }),
    submitButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      successMessage,
      errorMessage,
      onFormSubmit,
      passwordInputProps,
      confirmPasswordInputProps,
      submitButtonProps,
      consentCheckboxProps,
      is8CharsEntered,
      isAtLeastOneNumberEntered,
      isUppercaseEntered,
      isLowercaseEntered,
      showValidations,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-user-contact-info-card', className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Create Your Password</PaperHeadline>
        <PaperContent>
          <Form onSubmit={onFormSubmit} className='c-form'>
            {successMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-user-contact-info-card__success-message'
              >
                {successMessage}
              </Text>
            )}

            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-user-contact-info-card__error-message'
              >
                {errorMessage}
              </Text>
            )}

            <Input label='Password: (required)' {...passwordInputProps} />
            <Text>
              Passwords are case sensitive, and must contain:
              <div className='rm-create-password-validation-row'>
                <div className={!showValidations ? 'display-none' : ''}>
                  {is8CharsEntered ? (
                    <RmPasswordValidationCheckMark></RmPasswordValidationCheckMark>
                  ) : (
                    <RmPasswordValidationCrossMark></RmPasswordValidationCrossMark>
                  )}
                </div>
                <Text>8 characters</Text>
              </div>
              <div className='rm-create-password-validation-row'>
                <div className={!showValidations ? 'display-none' : ''}>
                  {isAtLeastOneNumberEntered ? (
                    <RmPasswordValidationCheckMark></RmPasswordValidationCheckMark>
                  ) : (
                    <RmPasswordValidationCrossMark></RmPasswordValidationCrossMark>
                  )}
                </div>
                <Text>at least one number</Text>
              </div>
              <div className='rm-create-password-validation-row'>
                <div className={!showValidations ? 'display-none' : ''}>
                  {isUppercaseEntered ? (
                    <RmPasswordValidationCheckMark></RmPasswordValidationCheckMark>
                  ) : (
                    <RmPasswordValidationCrossMark></RmPasswordValidationCrossMark>
                  )}
                </div>
                <Text>at least one upper case letter</Text>
              </div>
              <div className='rm-create-password-validation-row'>
                <div className={!showValidations ? 'display-none' : ''}>
                  {isLowercaseEntered ? (
                    <RmPasswordValidationCheckMark></RmPasswordValidationCheckMark>
                  ) : (
                    <RmPasswordValidationCrossMark></RmPasswordValidationCrossMark>
                  )}
                </div>
                <Text>at least one lower case letter</Text>
              </div>
            </Text>

            <Input
              label='Confirm Password: (required)'
              {...confirmPasswordInputProps}
            />

            <Checkbox
              label='With your 24Petwatch microchip, we offer you free lost pet services, as well as exclusive offers, promotions, and the latest information from 24Petwatch regarding microchip and insurance services.'
              {...consentCheckboxProps}
            />
            <div>
              <Text>
                Pethealth Services (USA) Inc, Pethealth Services Inc, PTZ
                Insurance Services Ltd. may contact you via commercial
                electronic messages, automatic telephone dialing systems,
                pre-recorded/ automated messages or text messages at the mobile
                number provided above. These calls or emails are not a condition
                of the purchases of any goods or services.
              </Text>
              <Text>
                You understand that if you choose not to provide your consent,
                you will receive eletronic enrolment notification regarding the
                trial/ gift/ voucher of insurance and/or free lost pet services
                which includes being contacted with information in the event
                that your pet goes missing.
              </Text>
              <Text>You may withdraw your consent at any time.</Text>
            </div>

            <FormAction>
              <Button
                className='rm-ui-pet-info-card-action-button'
                color={Button.Colors.tertiary}
                {...submitButtonProps}
              >
                Submit Password
              </Button>
            </FormAction>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

import { createSlice } from "@reduxjs/toolkit";
import { PageUtils } from "../../components/pages/page-utils";

const CustomerProfileReducer = createSlice({
  name: "auth-user",
  initialState: {
    customerProfile: undefined,
    selectedPetId: undefined,
    customerAnimals: [],
    selectedAnimal: undefined,
    customerName: "",
    customerFullName: "",
    animalFullDetails: {},
    noPets: false,
    isActiveMedAlert: false,
    isActiveVetHelpline: false,
    city: "",
    state: "",
    zipcode: "",
  },
  reducers: {
    saveCustomerProfile: (state, action) => {
      state.customerProfile = action.payload;
      const animals = PageUtils.getCustomerAnimals(state.customerProfile);
      if (animals.length === 0) {
        state.noPets = true;
      }
      state.customerAnimals = animals;
      state.customerName = PageUtils.getCustomerFirstName(
        state.customerProfile
      );
      state.customerFullName = PageUtils.getCustomerFullName(
        state.customerProfile
      );
      if (!state.selectedPetId) {
        const pets = PageUtils.formatPets(state.customerAnimals);
        if (pets && pets.length > 0) {
          state.selectedPetId = pets[0].id;
          state.selectedAnimal = state.customerAnimals.find(
            (animal) => animal.animalId === state.selectedPetId
          );
        }
      }
    },
    changeSelectedPetId: (state, action) => {
      state.isActiveMedAlert = false;
      state.isActiveVetHelpline = false;
      state.selectedPetId = action.payload;
      state.selectedAnimal = state.customerAnimals.find(
        (animal) => animal.animalId === state.selectedPetId
      );
    },
    saveCustomerContactInfo: (state, action) => {
      if(action.payload?.address){
        state.city = action.payload.address.city;
        state.state = action.payload.address.state;
        state.zipcode = action.payload.address.zipcode;
      }
    },
    destroyCustomerProfileData: (state, action) => {
      state.customerProfile = undefined;
      state.selectedPetId = undefined;
      state.customerAnimals = [];
      state.selectedAnimal = undefined;
      state.customerName = "";
      state.customerFullName = "";
      state.animalFullDetails = {};
      state.noPets = false;
      state.isActiveMedAlert = false;
      state.isActiveVetHelpline = false;
      state.city = "";
      state.state = "";
      state.zipcode = "";
      PageUtils.destroyAnimalDetailsInLocalStorage();
    },
    saveAnimalDetails: (state, action) => {
      state.animalFullDetails[action.payload.animalId] = action.payload;
    },
    setIsActiveMedAlert: (state, action) => {
      state.isActiveMedAlert = action.payload;
    },
    setIsActiveVetHelpline: (state, action) => {
      state.isActiveVetHelpline = action.payload;
    },
  },
});

export const {
  saveCustomerProfile,
  changeSelectedPetId,
  destroyCustomerProfileData,
  saveAnimalDetails,
  setIsActiveMedAlert,
  setIsActiveVetHelpline,
  saveCustomerContactInfo,
} = CustomerProfileReducer.actions;
export default CustomerProfileReducer.reducer;

import { AppUtils } from "../../../../utils/app-utils";

export class PaymentUtils {
  static copyCcInfo(selectedCard, cardType) {
    let ccInfo;
    if (selectedCard) {
      const cardExpiry =
        selectedCard.cardExpiryMonth + "/" + selectedCard.cardExpiryYear;
      ccInfo = {
        cardHolderName: "",
        oldCardHolderName: selectedCard.cardNumber,
        cardType: cardType,
        cardNumber: "",
        oldCardNumber: selectedCard.cardNumber,
        cardExpiryMonth: selectedCard.cardExpiryMonth,
        cardExpiryYear: selectedCard.cardExpiryYear,
        cvv: selectedCard.cvv,
        oldCvv: selectedCard.cvv,
        cardExpiry: "",
        oldCardExpiry: cardExpiry,

        placeholders: {
          cardHolderNamePlaceholder: AppUtils.isEmptyString(
            selectedCard.cardHolderName
          )
            ? "Cardholder Name"
            : selectedCard.cardHolderName,
          cardExpiryPlaceholder:
            AppUtils.isEmptyString(selectedCard.cardExpiryMonth) ||
            AppUtils.isEmptyString(selectedCard.cardExpiryYear)
              ? "MM/YY"
              : cardExpiry,
          cardCvvPlaceholder: AppUtils.isEmptyString(selectedCard.cvv)
            ? "CVV"
            : selectedCard.cvv,
          cardNumberPlaceholder: AppUtils.isEmptyString(selectedCard.cardNumber)
            ? "Credit Card Number"
            : "**** **** **** " + selectedCard.cardNumber,
        },
      };
    } else {
      ccInfo = {
        cardHolderName: "",
        cardType: cardType,
        cardNumber: "",
        cardExpiryMonth: "",
        cardExpiryYear: "",
        cvv: "",
        cardExpiry: "",
        placeholders: {
          cardHolderNamePlaceholder: "Cardholder Name",
          cardExpiryPlaceholder: "MM/YY",
          cardCvvPlaceholder: "CVV",
          cardNumberPlaceholder: "Credit Card Number",
        },
      };
    }
    return ccInfo;
  }
  static copyDdInfo(selectedCard) {
    let ddInfo;
    if (selectedCard) {
      ddInfo = {
        accountHolderName: "",
        oldAccountHolderName: selectedCard.accountHolderName,
        bankName: "",
        oldBankName: selectedCard.bankName,
        routingNumber: selectedCard.routingNumber,
        accountNumber: "",
        oldAccountNumber: selectedCard.accountNumber,
        institutionNumber: selectedCard.institutionNumber,
        transitNumber: selectedCard.transitNumber,
        placeholders: {
          accountHolderNamePlaceholder: AppUtils.isEmptyString(
            selectedCard.accountHolderName
          )
            ? "Account Name"
            : selectedCard.accountHolderName,
          bankNamePlaceholder: AppUtils.isEmptyString(selectedCard.bankName)
            ? "Bank Name"
            : selectedCard.bankName,
          accountNumberPlaceholder: AppUtils.isEmptyString(
            selectedCard.accountNumber
          )
            ? "Account Number"
            : selectedCard.accountNumber,
        },
      };
    } else {
      ddInfo = {
        accountHolderName: "",
        bankName: "",
        routingNumber: "",
        accountNumber: "",
        institutionNumber: "",
        transitNumber: "",
        placeholders: {
          accountHolderNamePlaceholder: "Account Name",
          bankNamePlaceholder: "Bank Name",
          accountNumberPlaceholder: "Account Number",
        },
      };
    }
    return ddInfo;
  }
  static formatExpiryDate(prevValue, value) {
    const oneDigitRegex = new RegExp(/^\d{1}$/);
    const twoDigitRegex = new RegExp(/^\d{2}$/);
    const threeDigitRegex = new RegExp(/^\d{2}\/$/);
    const fourDigitRegex = new RegExp(/^\d{2}\/\d{1}$/);
    const fiveDigitRegex = new RegExp(/^\d{2}\/\d{2}$/);
    const regExs = [
      oneDigitRegex,
      twoDigitRegex,
      threeDigitRegex,
      fourDigitRegex,
      fiveDigitRegex,
    ];
    if (value.length === 0) {
      return value;
    }
    if (value.length === 1) {
      if (value < 0 || value > 1) {
        return prevValue;
      }
    }
    if (value.length === 2) {
      if (value < 1 || value > 12) {
        return prevValue;
      }
    }
    if (value.length === 5) {
      const min = new Date().getFullYear().toString().substr(-2);
      const splitted = value.split("/");
      const cardExpiryYear = splitted[1];
      if (cardExpiryYear < min) {
        return prevValue;
      }
    }
    if (value.length > 5) {
      return prevValue;
    }
    if (twoDigitRegex.test(value) && !threeDigitRegex.test(prevValue)) {
      return value + "/";
    }
    if (regExs[value.length - 1].test(value)) {
      return value;
    } else {
      return prevValue;
    }
  }
  static getDefaultData() {
    return {
      errors: {
        cardHolderNameError: false,
        cardNumberError: false,
        cardExpirtyError: false,
        addressError: false,
        cityError: false,
        stateError: false,
        zipCodeError: false,
        phoneNumberError: false,
        firstNameError: false,
        lastNameError: false,
        addressLine1Error: false,
        accountNameError: false,
        cvvError: false,
        bankNameError: false,
        routingNumberError: false,
        transitNumberError: false,
        institutionNumberError: false,
        accountNumberError: false,
        membershipConsentError: false,
        insuranceConsentError: false,
      },
      selectedProduct: undefined,
      billingAddress: {
        busrelaccount: "",
        phoneNumber: "",
        firstname: "",
        lastname: "",
        country:
          AppUtils.getCountryCode()?.toLowerCase() === "us" ? "us" : "ca",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
      },
      ccInfo: {
        cardHolderName: "",
        cardType: "",
        cardNumber: "",
        cardExpiryMonth: "",
        cardExpiryYear: "",
        oldCardNumber: "",
        cvv: "",
        cardExpiry: "",
      },
      ddInfo: {
        accountHolderName: "",
        bankName: "",
        routingNumber: "",
        accountNumber: "",
        institutionNumber: "",
        transitNumber: "",
      },
      useMailingAddress: true,
      selectedPet: undefined,
      allProductList: [],
      membershipConsent: false,
      insuranceConsent: false,
      allProductsConsent: false,
      selectedPaymentMethod: undefined,
      bankAccountTypeSelectFields: [
        { value: "Direct Debit", label: "Direct Debit" },
      ],
      selectedBankAccountType: "Direct Debit",
      selectedPreAuthCountry:
        AppUtils.getCountryCode()?.toLowerCase() === "us" ? "us" : "ca",
      productSelectionFields: [],
      paymentMethodSelectionFields: [],
    };
  }
  static isPreAuthWithdrawal(str) {
    return str === "Pre-Authorised Withdrawal";
  }
  static isInsurance(str) {
    return str && str.toLowerCase().indexOf("insurance") > -1;
  }
  static isMembership(str) {
    return str && str.toLowerCase().indexOf("membership") > -1;
  }
  static isMembershipAndInsurance(str) {
    return str && str.toLowerCase().indexOf("membership & insurance") > -1;
  }
  static getPlaceholder(original, value) {
    return value && !AppUtils.isEmptyString(value) ? value : original;
  }
  static getUpdatedCcInfo(ccInfo) {
    if (!ccInfo) {
      return null;
    }
    return {
      cardExpiry: AppUtils.isEmptyString(ccInfo.cardExpiry)
        ? ccInfo.oldCardExpiry
        : ccInfo.cardExpiry,
      cardHolderName: AppUtils.isEmptyString(ccInfo.cardHolderName)
        ? ccInfo.oldCardHolderName
        : ccInfo.cardHolderName,
      cardNumber: ccInfo.cardNumber,
      cardType: ccInfo.cardType,
      cvv: AppUtils.isEmptyString(ccInfo.cvv) ? ccInfo.oldCvv : ccInfo.cvv,
    };
  }
  static getUpdatedDdInfo(ddInfo) {
    if (!ddInfo) {
      return null;
    }
    return {
      accountHolderName: AppUtils.isEmptyString(ddInfo.accountHolderName)
        ? ddInfo.oldAccountHolderName
        : ddInfo.accountHolderName,
      bankName: AppUtils.isEmptyString(ddInfo.bankName)
        ? ddInfo.oldBankName
        : ddInfo.bankName,
      routingNumber: ddInfo.routingNumber,
      accountNumber: AppUtils.isEmptyString(ddInfo.accountNumber)
        ? ddInfo.oldAccountNumber
        : ddInfo.accountNumber,
      institutionNumber: ddInfo.institutionNumber,
      transitNumber: ddInfo.transitNumber,
    };
  }
  static getLabel(productType) {
    const labels = {
      insurance:
        "Optional – Please update my payment information across all my pet insurance policies.",
      membership:
        "Optional – Please update my payment information across all membership products.",
      default:
        "Optional – Please update my payment information across all my pet insurance policies and membership products.",
    };
    return PaymentUtils.isMembershipAndInsurance(productType)
      ? labels.default
      : PaymentUtils.isInsurance(productType)
      ? labels.insurance
      : labels.membership;
  }
  static getDefaultDdErrors() {
    return {
      accountHolderNameError: false,
      bankNameError: false,
      routingNumberError: false,
      accountNumberError: false,
      institutionNumberError: false,
      transitNumberError: false,
    };
  }
  static getExistingProduct(allProductList, productType) {
    let existingProduct;
    for (let product of allProductList) {
      if (product.productType === productType) {
        existingProduct = product;
        break;
      }
    }
    return existingProduct;
  }
  static getBusRelAccount(billingInfoList, selectedPetId) {
    if (!billingInfoList) {
      return "";
    }
    const billingInfo = billingInfoList.find(
      (info) => info.animalId === selectedPetId
    );
    return billingInfo?.busrelaccount || "";
  }
  static getCustomerAddress(billingInfoList, selectedPetId) {
    if (!billingInfoList) {
      return "";
    }
    const billingInfo = billingInfoList.find(
      (info) => info.animalId === selectedPetId
    );
    return billingInfo?.customerAddress || {};
  }
}

import React from "react";
import { withRouter } from "react-router-dom";
import {
  TwoColumnContent,
  PersonalSignUp,
  SocialSignUp,
} from "../../../UI-Component-Library";

import axios from "../../../custom-axios";
import { AppUtils } from "../../../utils/app-utils";
import { ApiConstants } from "../../../utils/api-constants";
import { ValidationMessages } from "../../../utils/validation-messages";
import { fetchUserDetailsForSocialLogin } from "../../../redux/actions/authActions";
import { connect } from "react-redux";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
const queryString = require("query-string");

class SignUpStepTwo extends React.Component {
  constructor() {
    super();
    this.state = {
      busRelAccount: "",
      animalId: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      isEmailError: false,
      isConfirmEmailError: false,
      isPasswordError: false,
      isConfirmPasswordError: false,
      errorMessage: "",
      accType: "",
      socialSignupError: "",
      flow: "",
      lookUpNumber: undefined,
      isDisableAll: false,
      disableEmail: false,
      hideConfirmEmail: false
    };
    this.cRef = React.createRef();
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if ( AppUtils.isEqualString(e.target.name, 'email') &&
      this.state.hideConfirmEmail
    ) {
      this.setState({
        confirmEmail: e.target.value
      })
    }
  };
  signUp() {
    let isEmailError = false;
    let isConfirmEmailError = false;
    let isPasswordError = false;
    let isConfirmPasswordError = false;

    if (
      AppUtils.isEmptyString(this.state.email) ||
      !AppUtils.isValidEmail(this.state.email)
    ) {
      isEmailError = true;
    }
    if (this.state.email !== this.state.confirmEmail) {
      isConfirmEmailError = true;
    }
    if (
      AppUtils.isEmptyString(this.state.password) ||
      !AppUtils.isValidPassword(this.state.password)
    ) {
      isPasswordError = true;
    }
    if (this.state.password.trim() !== this.state.confirmPassword.trim()) {
      isConfirmPasswordError = true;
    }

    this.setState({
      isEmailError,
      isConfirmEmailError,
      isPasswordError,
      isConfirmPasswordError,
      socialSignupError: "",
      provider: "",
      errorMessage: "",
    });
    if (
      !isEmailError &&
      !isConfirmEmailError &&
      !isPasswordError &&
      !isConfirmPasswordError
    ) {
      const requestData = (({
        email,
        confirmEmail,
        confirmPassword,
        password,
        busRelAccount,
      }) => ({
        email,
        confirmEmail,
        confirmPassword,
        password,
        busRelAccount,
      }))(this.state);
      requestData.password = requestData.password.trim();
      requestData.confirmPassword = requestData.confirmPassword.trim();
      axios
        .processPost(
          ApiConstants.URL_POST_SIGNUP_SECOND_STEP,
          this.cRef,
          requestData
        )
        .then((response) => {
          this.navigateToSignUpSuccessPage("username and password");
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              errorMessage: errors[0],
            });
            DataLayerUtils.formErrorEvent("Sign up", null, errors[0]);
          }
        });
    }
  }

  navigateToSignUpSuccessPage(method) {
    this.props.fetchUserDetails(this.props.history, this.cRef, this.state.accType, method, this.state.flow);
  }

  verifyNewAccount() {
    this.setState({
      errorMessage: "",
      isDisableAll: false
    })
    const requestData = {
      "busRelAccount": this.state.busRelAccount,
      "animalId": this.state.animalId,
    }
    axios
      .processPost(
        ApiConstants.URL_POST_SIGNUP_SECOND_STEP_VERIFICATION,
        this.cRef,
        requestData
      )
      .then((response) => {
        if (AppUtils.isEqualString(response.status, "pending")) {
          const emailId = (response?.emailId) ? response.emailId : "";
          this.setState({
            email: emailId,
            confirmEmail: emailId,
            disableEmail: true,
          })
          return;
        }
        if (AppUtils.isEqualString(response.status, "valid")) {
          this.props.history.push({
            pathname:`/auth/login`,
            state: {
              email: response.emailId
            }
          });
        }
      })
      .catch((err) => {
        if (err.response?.data?.status) {
          if (AppUtils.isEqualString(err.response.data.status, "invalid")) {
            this.setState({
              errorMessage: `Please call ${FeatureFlagUtils.getPhoneNumber()} for assistance with setting up your account.`,
              isDisableAll: true
            })
          }
          return;
        }
        if (err.response?.data?.errors) {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              errorMessage: errors[0],
            });
          }
        }
      });
  }

  getBusRelNAnimalID(token) {
    const parseToken = token.split("-");
    return {
      busRel: parseToken[0],
      animalId: parseToken[1]
    }
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.history.location.search);
    const state = this.props.history?.location?.state;
    const lookUpNumber = state?.lookUpNumber;
    this.setState({
      lookUpNumber,
    })
    if (parsed) {
      if (parsed.success) {
        this.navigateToSignUpSuccessPage(null);
      } else {
        if (parsed.Token) {
          const token = this.getBusRelNAnimalID(parsed.Token);
          this.setState({
            busRelAccount: token.busRel,
            animalId: token.animalId,
            accType: parsed.accType,
            hideConfirmEmail: true,
            flow: "email",
            socialSignupError: parsed.error ? parsed.error : "",
          }, () => {
            this.verifyNewAccount();
          })
        } else {
          this.setState({
            busRelAccount: parsed.busRel,
            accType: parsed.accType,
            hideConfirmEmail: (state?.email)? true : false,
            email: state?.email,
            confirmEmail: state?.email,
            disableEmail: (state?.email)? true : false,
            flow: parsed.flow ? parsed.flow : "",
            socialSignupError: parsed.error ? parsed.error : "",
          });
        }
      }
      DataLayerUtils.createAccountEvent(
        parsed.accType,
        'email link',
        `${(parsed.flow)? parsed.flow : "email"}`,
        `${(lookUpNumber) ? lookUpNumber : null}`
      )
    }
  }
  render() {
    return (
      <div ref={this.cRef}>
        <TwoColumnContent
          leftColumn={
            <PersonalSignUp
              showAccountMessage={FeatureFlagUtils.shouldShowAccountMessage()}
              isConfirmEmailHidden={this.state.hideConfirmEmail}
              phoneNumber={FeatureFlagUtils.getPhoneNumber()}
              companyName={FeatureFlagUtils.getCompanyName()}
              errorMessage={this.state.errorMessage}
              emailInputProps={{
                id: "SS_email_input",
                value: this.state.email,
                name: "email",
                disabled: this.state.isDisableAll || this.state.disableEmail,
                error: this.state.isEmailError,
                hint: this.state.isEmailError
                  ? ValidationMessages.EMAIL_VALIDATION_MESSAGE
                  : "",
                onChange: this.handleInputChange,
              }}
              checkEmailInputProps={{
                id: "SS_confirm_email_input",
                value: this.state.confirmEmail,
                name: "confirmEmail",
                hidden: this.state.hideConfirmEmail,
                disabled: this.state.isDisableAll || this.state.disableEmail,
                error: this.state.isConfirmEmailError,
                hint: this.state.isConfirmEmailError
                  ? ValidationMessages.EMAIL_DOESNT_MATCH
                  : "",
                onChange: this.handleInputChange,
              }}
              passwordInputProps={{
                id: "SS_password_input",
                value: this.state.password,
                name: "password",
                disabled: this.state.isDisableAll,
                error: this.state.isPasswordError,
                hint: this.state.isPasswordError
                  ? ValidationMessages.PASSWORD_VALIDATION_MESSAGE
                  : "",
                onChange: this.handleInputChange,
              }}
              checkPasswordInputProps={{
                id: "SS_confirm_password_input",
                value: this.state.confirmPassword,
                name: "confirmPassword",
                disabled: this.state.isDisableAll,
                error: this.state.isConfirmPasswordError,
                hint: this.state.isConfirmPasswordError
                  ? ValidationMessages.PASSWORD_DOESNT_MATCH
                  : "",
                onChange: this.handleInputChange,
              }}
              signUpButtonProps={{
                id: "SS_sign_up_button",
                disabled: this.state.isDisableAll,
                onClick: () => {
                  DataLayerUtils.createAccountEvent(
                    this.state.accType,
                    'enter info to sign up',
                    this.state.flow,
                    `${(this.state.lookUpNumber) ? this.state.lookUpNumber : null}`
                  )
                  this.signUp();
                },
              }}
            />
          }
          rightColumn={
            <SocialSignUp
              errorMessage={this.state.socialSignupError}
              googleSignUpButtonProps={{
                id: "SS_social_singup_button_google",
                onClick: () => {
                  this.setState({
                    socialSignupError: "",
                  });
                  DataLayerUtils.createAccountEvent(
                    this.state.accType,
                    `sign up with google`,
                     this.state.flow,
                    `${(this.state.lookUpNumber) ? this.state.lookUpNumber : null}`
                  )
                  window.location = `${ApiConstants.URL_SOCIAL_SIGNUP_GOOGLE}&busRel=${this.state.busRelAccount}&accType=${this.state.accType}`;
                },
              }}
              facebookSignUpButtonProps={{
                id: "SS_social_singup_button_facebook",
                style: (FeatureFlagUtils.hideFBLoginOption())? {"display" : "none"} : {},
                onClick: () => {
                  this.setState({
                    socialSignupError: "",
                  });
                  DataLayerUtils.createAccountEvent(
                    this.state.accType,
                    `sign up with facebook`,
                    this.state.flow,
                    `${(this.state.lookUpNumber) ? this.state.lookUpNumber : null}`
                  )
                  window.location = `${ApiConstants.URL_SOCIAL_SIGNUP_FACEBOOK}&busRel=${this.state.busRelAccount}&accType=${this.state.accType}`;
                },
              }}
            />
          }
        ></TwoColumnContent>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDetails: (history, cRef, accType, method, flow) =>
      dispatch(fetchUserDetailsForSocialLogin(history, cRef, accType, method, flow)),
  };
};
export default connect(null, mapDispatchToProps)(withRouter(SignUpStepTwo));

import React from "react";
import {
  WinbackMembershipCard,
} from "../../../UI-Component-Library";
// import axios from "../../../custom-axios";
// import { ApiConstants } from "../../../utils/api-constants";
import { AppUtils } from "../../../utils/app-utils";
import { connect } from "react-redux";
import {
  addToShoppingCartItems,
} from "../../../redux/reducers/membership-purchase-reducer";
import { MembershipPurchaseUtils } from "./membership-purchase.util";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
class WinbackOfferComponent extends React.Component {
  constructor() {
    super();
  }

  addWinbackOfferToCart = (e) => {
    const el = document.getElementById("pet-shopping-cart");

    const item = {
      itemId: this.state.winbackProducts.itemId,
      itemType: this.state.winbackProducts.itemType,
      productName: AppUtils.getProductNameFromItemId(
        this.state.winbackProducts.itemId
      ),
      productCost: AppUtils.getConvertedPrice(
        this.state.winbackProducts.itemPrice
      ),
      bundleList: this.state.winbackProducts.bundleList,
    };
    DataLayerUtils.addToCartEvent(item);
    this.props.addToShoppingCartItems({
      selectedPetId: this.props.selectedPetId,
      item,
    });

    if(el) {
      el.scrollIntoView({behavior: "smooth"});
    }

    e.preventDefault();
  }

  componentDidMount() {
    this.setState(
      {
       selectedPetId: this.props.selectedPetId,
      },
      this.mapParentPropsToState
    );
  }

  mapParentPropsToState() {
    const winbackProducts = this.props.membershipProducts;
    this.setState({
      winbackProducts
    });
  }

  getMembershipCard() {
    return (
      <WinbackMembershipCard 
        expiryDate={this.state?.winbackProducts?.offerEndDate}
        offerPrice={AppUtils.getConvertedPrice(this.state?.winbackProducts?.itemPrice)}
        renewButtonProps={{
          id: "renew-button-id",
          onClick: (e) => {
            this.addWinbackOfferToCart(e);
          }
        }}
      />
    );
  }
  render() {
    return <div ref={this.cRef}>{this.getMembershipCard()}</div>;
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    selectedPetId: customerProfileStore.selectedPetId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToShoppingCartItems: (item) => dispatch(addToShoppingCartItems(item)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WinbackOfferComponent);

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { TableRow, TableDataCell, TableHeaderCell, Icon } from '../../'

export const ClaimHistoryItem = ({
  claim,
  id,
  onDocumentClick,
  onSubmissionNumberClick
}) => {
  const {
    instanceID,
    submissionNumber,
    submissionNumberActive,
    dateSubmitted,
    status,
    submittedAmount,
    amountPaid,
    documents = []
  } = claim
  const { error: statusError = false, name: statusName } = status

  return (
    <TableRow id={id}>
      <TableDataCell>
        <span
          className={cn({
            'ui-claim-history__submission-number-button': submissionNumberActive
          })}
          onClick={() =>
            submissionNumberActive && onSubmissionNumberClick(instanceID)
          }
          role={submissionNumberActive ? 'button' : undefined}
        >
          {submissionNumber}
        </span>
      </TableDataCell>
      <TableDataCell>{dateSubmitted}</TableDataCell>
      <TableDataCell
        className={cn({
          'ui-claim-history__status--error': statusError
        })}
      >
        {statusName}
      </TableDataCell>
      <TableDataCell>{submittedAmount}</TableDataCell>
      <TableDataCell>{amountPaid}</TableDataCell>
      <TableDataCell className={cn('ui-claim-history__download')}>
        {documents.map((doc) => (
          <div
            role='button'
            key={doc?.key}
            onClick={() => onDocumentClick?.(doc)}
            className={cn({
              'ui-claim-history__download--active': doc?.active
            })}
          >
            <Icon type='download' />
          </div>
        ))}
      </TableDataCell>
    </TableRow>
  )
}
ClaimHistoryItem.propTypes = {
  claim: PropTypes.shape({
    instanceID: PropTypes.string,
    submissionNumber: PropTypes.string,
    submissionNumberActive: PropTypes.bool,
    dateSubmitted: PropTypes.string,
    status: PropTypes.shape({
      error: PropTypes.bool,
      name: PropTypes.string
    }),
    submittedAmount: PropTypes.string,
    amountPaid: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        key: PropTypes.string.isRequired,
        documentData: PropTypes.any
      })
    )
  }),
  onDocumentClick: PropTypes.func,
  onSubmissionNumberClick: PropTypes.func
}

export const RecoveryHistoryItem = ({ recovery, id }) => {
  const { caseID, dateOpened, lastUpdated, status, note } = recovery
  return (
    <TableRow id={id} >
      <TableDataCell className='top-aligned-td'>{caseID}</TableDataCell>
      <TableDataCell className='top-aligned-td'>{dateOpened}</TableDataCell>
      <TableDataCell className='top-aligned-td'>{lastUpdated}</TableDataCell>
      <TableDataCell className='top-aligned-td'>{status}</TableDataCell>
      <TableDataCell className='ui-recovery-note top-aligned-td'>
        {note}
      </TableDataCell>
    </TableRow>
  )
}

RecoveryHistoryItem.propTypes = {
  recovery: PropTypes.shape({
    caseID: PropTypes.string,
    dateOpened: PropTypes.string,
    lastUpdated: PropTypes.string,
    status: PropTypes.string,
    note: PropTypes.string
  })
}
export const MobileClaimHistory = ({
  claim,
  id,
  onDocumentClick,
  onSubmissionNumberClick
}) => {
  const {
    instanceID,
    submissionNumber,
    submissionNumberActive,
    dateSubmitted,
    status,
    submittedAmount,
    amountPaid,
    documents = []
  } = claim
  const { error: statusError = false, name: statusName } = status

  return (
    <div className='mobile-claim-history' id={id}>
      <div className='history-item'>
        <TableHeaderCell>Submission #</TableHeaderCell>

        <TableDataCell>
          <span
            className={cn({
              'ui-claim-history__submission-number-button': submissionNumberActive
            })}
            onClick={() =>
              submissionNumberActive && onSubmissionNumberClick(instanceID)
            }
            role={submissionNumberActive ? 'button' : undefined}
          >
            {submissionNumber}
          </span>
        </TableDataCell>
      </div>
      <div className='history-item'>
        <TableHeaderCell>Date submitted</TableHeaderCell>
        <TableDataCell>{dateSubmitted}</TableDataCell>
      </div>
      <div className='history-item'>
        <TableHeaderCell>Status</TableHeaderCell>
        <TableDataCell
          className={cn({
            'ui-claim-history__status--error': statusError
          })}
        >
          {statusName}
        </TableDataCell>
      </div>
      <div className='history-item'>
        <TableHeaderCell>Submitted amount</TableHeaderCell>
        <TableDataCell>{submittedAmount}</TableDataCell>
      </div>
      <div className='history-item'>
        <TableHeaderCell>Amount paid</TableHeaderCell>
        <TableDataCell>{amountPaid}</TableDataCell>
      </div>
      <div className='history-item'>
        <TableHeaderCell>Document</TableHeaderCell>
        <TableDataCell className={cn('ui-claim-history__download')}>
          {documents.map((doc) => (
            <div
              role='button'
              key={doc?.key}
              onClick={() => onDocumentClick?.(doc)}
              className={cn({
                'ui-claim-history__download--active': doc?.active
              })}
            >
              <Icon type='download' />
            </div>
          ))}
        </TableDataCell>
      </div>
    </div>
  )
}
MobileClaimHistory.propTypes = {
  claim: PropTypes.shape({
    instanceID: PropTypes.string,
    submissionNumber: PropTypes.string,
    submissionNumberActive: PropTypes.bool,
    dateSubmitted: PropTypes.string,
    status: PropTypes.shape({
      error: PropTypes.bool,
      name: PropTypes.string
    }),
    submittedAmount: PropTypes.string,
    amountPaid: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        key: PropTypes.string.isRequired,
        documentData: PropTypes.any
      })
    )
  }),
  onDocumentClick: PropTypes.func,
  onSubmissionNumberClick: PropTypes.func
}

export const MobileRecoveryHistory = ({ recovery, id }) => {
  const { caseID, dateOpened, lastUpdated, status, note } = recovery
  return (
    <div className='mobile-claim-history' id={id}>
      <div className='history-item'>
        <TableHeaderCell>Case ID</TableHeaderCell>
        <TableDataCell>{caseID}</TableDataCell>
      </div>
      <div className='history-item'>
        <TableHeaderCell>Opened</TableHeaderCell>
        <TableDataCell>{dateOpened}</TableDataCell>
      </div>
      <div className='history-item'>
        <TableHeaderCell>Last updated</TableHeaderCell>
        <TableDataCell>{lastUpdated}</TableDataCell>
      </div>
      <div className='history-item'>
        <TableHeaderCell>Status</TableHeaderCell>
        <TableDataCell>{status}</TableDataCell>
      </div>
      <div className='history-item'>
        <TableHeaderCell>Note</TableHeaderCell>
        <TableDataCell>{note}</TableDataCell>
      </div>
    </div>
  )
}

MobileRecoveryHistory.propTypes = {
  recovery: PropTypes.shape({
    caseID: PropTypes.string,
    dateOpened: PropTypes.string,
    lastUpdated: PropTypes.string,
    status: PropTypes.string,
    note: PropTypes.string
  })
}

import React from 'react'
import PropTypes from 'prop-types'

import { Paper, PaperHeadline, PaperContent, Button, Text, Link } from '../../'

import { PUBLIC_URL } from '../../constants'

export class PetMedAlertCard extends React.PureComponent {
  static propTypes = {
    status: PropTypes.string,
    downloadHistoryButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    emailHistoryLinkProps: PropTypes.shape({
      disabled: PropTypes.bool,
      id: PropTypes.string
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      status,
      downloadHistoryButtonProps,
      emailHistoryLinkProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={className}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>
          24PetMedAlert<sup>®</sup>
        </PaperHeadline>
        <PaperContent
          className='ui-pet-med-alert__content'
          backgroundImg={{
            src: PUBLIC_URL + 'fadeImg4.png',
            position: 'left'
          }}
        >
          <Text type={Text.Types.headlineBold}>
            Update your pet’s medical history
          </Text>

          <Text>
            Download the medical history form, fill it out and then email it to
            us. Please allow 5 business days for your account to be{'\u00A0'}
            updated.
          </Text>

          <Text>
            <strong>Status:</strong> {status ? status : 'N/A'}
          </Text>

          <div className='ui-pet-med-alert__actions'>
            <Button
              color={Link.Colors.tertiary}
              {...downloadHistoryButtonProps}
            >
              1. Download history form
            </Button>
            <Link
              color={Link.Colors.tertiary}
              type={Link.Types.button}
              href='mailto:24PetMedAlert@24PetWatch.com'
              {...emailHistoryLinkProps}
            >
              2. Email to 24PetMedAlert@24Petwatch.com
            </Link>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}
// TODO: ADD A NEW COLOR FOR LINK

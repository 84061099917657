import React from "react";
import { AccountPreferencesCard } from "../../../../UI-Component-Library";
import axios from "../../../../custom-axios";
import { ApiConstants } from "../../../../utils/api-constants";
import { FeatureFlagUtils } from "../../../../utils/feature-flag-utils";
import { AppUtils } from "../../../../utils/app-utils";
import { ValidationMessages } from "../../../../utils/validation-messages";
import { withRouter } from "react-router-dom";
import { clearUserDataAfterLogout } from "../../../../redux/actions/authActions";
import { connect } from "react-redux";
const queryString = require("query-string");

class AccountPreference extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      confirmEmail: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      isEmailError: false,
      isConfirmEmailError: false,
      isOldPasswordError: false,
      isNewPasswordError: false,
      isConfirmPasswordError: false,
      isInternalLogin: true,
    };
    this.cRef = React.createRef();
  }
  handleSaveChanges = () => {
    let isEmailError = false;
    let isConfirmEmailError = false;
    let isOldPasswordError = false;
    let isNewPasswordError = false;
    let isConfirmPasswordError = false;
    let errorMessage = "";
    if (
      !this.state.isInternalLogin &&
      (AppUtils.isEmptyString(this.state.email) ||
        !AppUtils.isValidEmail(this.state.email))
    ) {
      isEmailError = true;
    }
    if (
      !this.state.isInternalLogin &&
      this.state.email !== this.state.confirmEmail
    ) {
      isConfirmEmailError = true;
    }
    if (
      this.state.isInternalLogin &&
      AppUtils.isEmptyString(this.state.oldPassword)
    ) {
      isOldPasswordError = true;
    }
    if (
      AppUtils.isEmptyString(this.state.newPassword) ||
      !AppUtils.isValidPassword(this.state.newPassword)
    ) {
      isNewPasswordError = true;
    }
    if (this.state.newPassword.trim() !== this.state.confirmPassword.trim()) {
      isConfirmPasswordError = true;
    }
    this.setState({
      isOldPasswordError,
      isNewPasswordError,
      isConfirmPasswordError,
      errorMessage,
      isEmailError,
      isConfirmEmailError,
    });
    if (!isOldPasswordError && !isConfirmPasswordError && !isNewPasswordError) {
      let requestData;
      if (this.state.isInternalLogin) {
        requestData = (({ oldPassword, newPassword, confirmPassword }) => ({
          oldPassword,
          newPassword,
          confirmPassword,
        }))(this.state);
        requestData = {
          userRelationId: parseInt(AppUtils.getUserRelationId()),
          oldPassword: this.state.oldPassword.trim(),
          newPassword: this.state.newPassword.trim(),
          confirmPassword: this.state.confirmPassword.trim(),
        };
      } else {
        requestData = {
          busRelAccount: AppUtils.getBusRelAccount(),
          userRelationId: parseInt(AppUtils.getUserRelationId()),
          email: this.state.email,
          confirmEmail: this.state.confirmEmail,
          password: this.state.newPassword.trim(),
          confirmPassword: this.state.confirmPassword.trim(),
        };
      }
      axios
        .processPost(
          this.state.isInternalLogin
            ? ApiConstants.URL_CHANGE_PASSWORD
            : ApiConstants.URL_CHANGE_TO_EXTERNAL_LOGIN,
          this.cRef,
          requestData
        )
        .then((response) => {
          this.setState(
            {
              successMessage: this.state.isInternalLogin
                ? "Password changed successfully!"
                : "Successfull!",
              email: "",
              confirmEmail: "",
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
              isEmailError: false,
              isConfirmEmailError: false,
              isOldPasswordError: false,
              isNewPasswordError: false,
              isConfirmPasswordError: false,
            },
            () => {
              if (!this.state.isInternalLogin) {
                setTimeout(() => {
                  this.logoutUser();
                }, 5000);
              } else {
                // setTimeout(() => {
                //   this.setState({
                //     successMessage: "",
                //   });
                // }, 5000);
              }
            }
          );
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              errorMessage: errors[0],
            });
          }
        });
    }
  };
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  logoutUser = () => {
    axios
      .processPost(ApiConstants.URL_LOGOUT)
      .then((response) => {})
      .catch((err) => {})
      .finally((err) => {
        this.props.logOut();
        AppUtils.clearLoginData();
        this.props.history.push("/auth/login");
      });
  };

  componentDidMount() {
    const parsed = queryString.parse(this.props.history.location.search);
    if (parsed.error) {
      this.setState({
        errorMessage: parsed.error,
      });
      AppUtils.keepFocusingOnDiv("ap_form");
    } else if (parsed.success) {
      this.setState(
        {
          successMessage: parsed.success,
        },
        () => {
          AppUtils.keepFocusingOnDiv("ap_form");
          setTimeout(() => {
            this.logoutUser();
          }, 5000);
        }
      );
    }
    this.setState({
      isInternalLogin: AppUtils.getLoginType() === "Internal",
    });
  }
  render() {
    return (
      <div ref={this.cRef} id="ap_form">
        <AccountPreferencesCard
          style={{
            marginBottom: "24px",
          }}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
          onFormSubmit={() => {}}
          userLoggedInWithSocial={!this.state.isInternalLogin}
          newEmailInputFieldProps={{
            value: this.state.email,
            onChange: this.handleInputChange,
            placeholder: "Email Address",
            name: "email",
            error: this.state.isEmailError,
            hint: this.state.isEmailError
              ? ValidationMessages.EMAIL_VALIDATION_MESSAGE
              : "",
            id: "AP_email",
          }}
          confirmEmailInputFieldProps={{
            value: this.state.confirmEmail,
            onChange: this.handleInputChange,
            placeholder: "Re-enter Email Address",
            name: "confirmEmail",
            error: this.state.isConfirmEmailError,
            hint: this.state.isConfirmEmailError
              ? ValidationMessages.EMAIL_DOESNT_MATCH
              : "",
            id: "AP_confirmEmail",
          }}
          currentPasswordInputFieldProps={{
            value: this.state.oldPassword,
            onChange: this.handleInputChange,
            name: "oldPassword",
            placeholder: "Current Password",
            error: this.state.isOldPasswordError,
            hint: this.state.isOldPasswordError
              ? ValidationMessages.PASSWORD_EMPTY
              : "",
            id: "AP_oldPassword",
          }}
          newPasswordInputFieldProps={{
            value: this.state.newPassword,
            onChange: this.handleInputChange,
            name: "newPassword",
            placeholder: "New Password",
            error: this.state.isNewPasswordError,
            hint: this.state.isNewPasswordError
              ? ValidationMessages.PASSWORD_VALIDATION_MESSAGE
              : "",
            id: "AP_newPassword",
          }}
          confirmPasswordInputFieldProps={{
            value: this.state.confirmPassword,
            label: "Confirm New Password",
            onChange: this.handleInputChange,
            name: "confirmPassword",
            placeholder: "Confirm New Password",
            error: this.state.isConfirmPasswordError,
            hint: this.state.isConfirmPasswordError
              ? ValidationMessages.PASSWORD_DOESNT_MATCH
              : "",
            id: "AP_confirmPassword",
          }}
          saveChangesButtonProps={{
            id: "AP_saveChangesButton",
            onClick: () => {
              this.handleSaveChanges();
            },
          }}
          logInGoogleButtonProps={{
            id: "AP_signUpGoogleButton",
            onClick: () => {
              window.location = `${
                ApiConstants.URL_CHANGE_SOCIAL_LOGIN_GOOGLE
              }${AppUtils.getUserRelationId()}`;
            },
          }}
          logInFacebookButtonProps={{
            id: "AP_signUpFacebookButton",
            style: (FeatureFlagUtils.hideFBLoginOption())? {"display" : "none"} : {},
            onClick: () => {
              window.location = `${
                ApiConstants.URL_CHANGE_SOCIAL_LOGIN_FACEBOOK
              }${AppUtils.getUserRelationId()}`;
            },
          }}
        />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(clearUserDataAfterLogout()),
  };
};
export default connect(null, mapDispatchToProps)(withRouter(AccountPreference));

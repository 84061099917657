import React from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Form,
  Input,
  Text
} from '../..'

export class PetplaceAddPromoCodeModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,

    onFormSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
    promoCodeInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    cancelButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    applyButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  render() {
    const {
      open,
      onClose,
      errorMessage,
      promoCodeInputProps,
      cancelButtonProps,
      applyButtonProps,
      onFormSubmit
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-petplace-add-promo-code-modal'>
          <Paper>
            <PaperHeadline>Add a promo code</PaperHeadline>
            <PaperContent>
              <Text>Do you have a discount code? Enter it now.</Text>

              {errorMessage && (
                <Text
                  className='ui-petplace-add-promo-code-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}

              <Form onSubmit={onFormSubmit}>
                <Input placeholder='Promo code' {...promoCodeInputProps} />
                <div className='ui-petplace-add-promo-code-modal__actions'>
                  <Button
                    type='button'
                    variant={Button.Variants.outlined}
                    color={Button.Colors.tertiary}
                    {...cancelButtonProps}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    {...applyButtonProps}
                    color={Button.Colors.tertiary}
                  >
                    Apply Code
                  </Button>
                </div>
              </Form>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, Text, HeaderDivider, Input, Link, Button, IconButton } from '../..'
import { Summary, Promo, Total, CartItem, TotalHead } from "../NewShoppingCart/NewShoppingCartComponents"

// Minimal width for the desktop layout to look good, after this break point render mobile view
const MOBILE_VIEW_BREAKPOINT = 792;

export class NewShoppingCart extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showMobile: window.innerWidth < MOBILE_VIEW_BREAKPOINT
    }

    this.handleViewBasedOnViewportWidth = this.handleViewBasedOnViewportWidth.bind(
      this
    )
  }

  handleViewBasedOnViewportWidth() {
    window.innerWidth < MOBILE_VIEW_BREAKPOINT
      ? this.setState({ showMobile: true })
      : this.setState({ showMobile: false })
  }

  static propTypes = {
    cartItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            productName: PropTypes.string,
            productCost: PropTypes.string
          })
        )
      })
    ),
    /**
     * Function accepts 2 arguments: index of cartItems array and index of items array
     */
    onRemoveClick: PropTypes.func,
    totalBeforeTax: PropTypes.string,
    errorMessage: PropTypes.string,
    showPromoRow: PropTypes.bool,
    addPromoProps: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      discount: PropTypes.string,
      onClick: PropTypes.func
    }),
    promoCodeInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    applyButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool
    }),
    closePromoProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool
    }),
    shippingAmount: PropTypes.string,
    totalTaxes: PropTypes.string,
    subtotal: PropTypes.string,

    showCartContent: PropTypes.bool,
    onCartClick: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func,
    }),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }
  
  static defaultProps = {
    showPromoRow: true,
    showCartContent: false,
    cartItems:[
      {
        title: 'For Brandy',
        items: [
          {
            productName: 'Dkslajdkl',
            productCost: '$24.00'
          },
          {
            productName: 'HJKJkjks',
            productCost: '$23.00'
          },
          {
            productName: 'Ipoiopewq',
            productCost: '$8.00'
          }
        ]
      },
      {
        title: 'For Jonny',
        items: [
          {
            productName: 'Dkslajdkl',
            productCost: '$24.00'
          },
          {
            productName: 'HJKJkjks',
            productCost: '$23.00'
          },
          {
            productName: 'Ipoiopewq',
            productCost: '$8.00'
          }
        ]
      }
    ],
    onRemoveClick:(index1, index2) => console.log(index1, index2),
    totalBeforeTax:'$56.00',
    promoProps:{
      id: 'promoButton',
      label: 'Add promo code',
      onClick: () => {}
    },
    shippingAmount:'$9.00',
    totalTaxes:'$18.00',
    subtotal:'$120.00',
  }

  componentDidMount() {
    this.handleViewBasedOnViewportWidth()

    window.addEventListener('resize', this.handleViewBasedOnViewportWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleViewBasedOnViewportWidth)
  }

  render() {
    const {
      onRemoveClick,
      cartItems,
      totalBeforeTax,
      addPromoProps,
      shippingAmount,
      totalTaxes,
      showPromoRow,
      subtotal,
      promoCodeInputProps,
      applyButtonProps,
      closePromoProps,
      showCartContent,
      onCartClick,
      id,
      className,
      errorMessage,
      style,
      refProp
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-new-shopping-cart__paper', className)}
        style={style}
        ref={refProp}
      >

        {errorMessage && (
          <Text
            type={Text.Types.bodyBold}
            className='ui-new-shopping-cart__error-message'
          >
            {errorMessage}
          </Text>
        )}
        {this.state.showMobile && <div className='ui-new-shopping-cart__body'>
          <TotalHead amount={subtotal} upArrow={showCartContent} onClick={onCartClick?.onClick} />
        </div>}
        {(showCartContent || !this.state.showMobile) && <div>
          {cartItems.map(({ title, items = [] }, i) => (
            <div key={`shoping-cart-${title}`} className='ui-new-shopping-cart__body'>
              <HeaderDivider className='ui-new-shopping-cart__headline-divider'>
                For <span className='ui-new-shopping-cart__headline-divider-text'>{title}</span>
              </HeaderDivider>
              {items.map((item, j) => (
                <CartItem
                  key={Object.values(item).join('-')}
                  onRemoveClick={() => onRemoveClick?.(i, j)}
                  {...item}
                />
              ))}
            </div>
          ))}

          {/* {showPromoRow && <Link className='ui-new-shopping-cart__promo_link' {...addPromoProps}>Add a promo code</Link>} */}

          <div className='ui-new-shopping-cart__body'>

            <Promo label="Add a promo code" {...addPromoProps} />
            {showPromoRow && (
              <>
                <span className='ui-new-shopping-cart__promo-heading'>
                  <Text>Promo code</Text>
                  <IconButton type='close' {...closePromoProps} />
                </span>
                <span className='ui-new-shopping-cart__form__row'>
                  <Input placeholder='Promo code' {...promoCodeInputProps} />
                  <Button
                    className="ui-new-shopping-cart__promo-button"
                    size={Button.Sizes.small}
                    color={Button.Colors.secondary}
                    {...applyButtonProps}
                  >
                    Add
                  </Button>
                </span>
              </>
            )}
            {totalBeforeTax && (
              <Summary label='Subtotal' amount={totalBeforeTax} />
            )}


            {shippingAmount && (
              <Summary label='Shipping' amount={shippingAmount} />
            )}

            {totalTaxes && <Summary label='Taxes' amount={totalTaxes} />}

            <Total amount={subtotal} />
          </div>
        </div>}
        
      </Paper>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperContent, Text, HeaderDivider } from '../..'
import { AccountSuccess, AddPetSuccess } from '../SvgIcons'
import { Link } from '../Link'
import { Button } from '../Button'

export class RmSuccessCard extends React.PureComponent {
  static propTypes = {
    userFirstName: PropTypes.string,
    userEmail: PropTypes.string,
    petHealthEmail: PropTypes.string,
    petHealthPhone: PropTypes.string,
    petName: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    isAddingNewPet: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    viewDashboardButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  render() {
    const {
      userFirstName,
      id,
      className,
      style,
      refProp,
      petHealthPhone,
      petHealthEmail,
      isAddingNewPet,
      userEmail,
      petName,
      type,
      viewDashboardButtonProps
    } = this.props

    const isPhone = type?.toLowerCase() === 'phone';

    return (
      <Paper id={id} className={className} style={style} ref={refProp}>
        <PaperContent>
          <div className='ui-rm-success_frame'>
            <div className={cn('ui-rm-success-card__icon')}>
              {isAddingNewPet ? (
                <AddPetSuccess></AddPetSuccess>
              ) : (
                <AccountSuccess></AccountSuccess>
              )}
            </div>
            <HeaderDivider headingId="RmSuccessHeadingId" className='ui-payment-complete-card__title'>
              Congrats, {userFirstName}!
            </HeaderDivider>
            {isAddingNewPet ? (
              <div>
                {!isPhone && (<Text className='ui-rm-success-card__centered_text'>
                  {petName} ’s microchip has been successfully registered. Please note this pet will be visible on the portal in approximately two hours as our system refreshes.
                </Text>)}
                {isPhone && (<Text className='ui-rm-success-card__centered_text'>
                  {petName} ’s account has been successfully created. Please note this pet will be visible on the{' '}
                  <Link color= {Link.Colors.secondary} href='/pages/dashboard'>mypethealth.com</Link> portal in approximately two hours as our system refreshes.
                </Text>)}
              </div>
            ) : (
              <div>
                <Text className='ui-rm-success-card__centered_text'>
                  Thank you for creating your account!
                </Text>
                <Text className='ui-rm-success-card__centered_text'>
                  We’ve sent you an email with a link to verify your account at
                  the email address. Please be sure to verify your email and set
                  your password so you can access your account!
                </Text>
              </div>
            )}
            <Text className='ui-rm-success-card__centered_text'>
              Sincerely,
            </Text>
            <Text className='ui-rm-success-card__centered_text'>
              {' '}
              The 24Petwatch Team
            </Text>
            {isAddingNewPet && (
              <div className='ui-rm-success-card__view_dashboard_button'>
                <Button
                  color={Button.Colors.tertiary}
                  type='submit'
                  {...viewDashboardButtonProps}
                >
                  View Dashboard
                </Button>
              </div>
            )}
          </div>
          {isAddingNewPet ? (
            <div></div>
          ) : (
            <div className='ui-rm-success_frame_2'>
              <Text>
                If you haven’t received your verification email, follow the
                steps below:
              </Text>
              <Text className='ui-rm-success_option_text'>
                1. Confirm that the email address you’ve provided{' '}
                <Link href={'mail:' + userEmail}>{userEmail}</Link> is complete.
              </Text>
              <Text className='ui-rm-success_option_text'>
                2. Ensure that you are checking the email account that you have
                provided for registration.
              </Text>
              <Text className='ui-rm-success_option_text'>
                3. If it’s been 24 hours and you have not received your account
                verification email, do not panic. It can take at least 24 hours
                for the email to make it to your inbox.
              </Text>
              <Text>
                If you’ve checked your spam/junk folders and after 24 hours
                still haven’t received the account verification email, please
                let us know by emailing us at:{' '}
                <Link href={'mail:' + petHealthEmail}>{petHealthEmail}</Link>
              </Text>
              <Text>
                If the email you provided was incorrect, please email us at{' '}
                <Link href={'mail:' + petHealthEmail}>{petHealthEmail}</Link>.
                or call us at{' '}
                <Link href={'tel:' + petHealthPhone}>{petHealthPhone}</Link>.
              </Text>
            </div>
          )}
        </PaperContent>
      </Paper>
    )
  }
}

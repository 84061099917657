import React from 'react'
import PropTypes from 'prop-types'

import { Modal, Paper, PaperHeadline, PaperContent, Button, Text } from '../../'

export class LoginInfoChangeWarningModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    message: PropTypes.string,

    yesButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    cancelButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  render() {
    const {
      open,
      onClose,
      yesButtonProps,
      cancelButtonProps,
      message
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-login-info-change-warning-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline>Warning</PaperHeadline>
            <PaperContent>
              <Text>{message}</Text>
              <div className='ui-login-info-change-warning-modal__actions'>
                <Button
                  variant={Button.Variants.outlined}
                  {...cancelButtonProps}
                >
                  Cancel
                </Button>
                <Button color={Button.Colors.tertiary} {...yesButtonProps}>
                  Yes
                </Button>
              </div>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

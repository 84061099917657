import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text } from '../../'

export class HeaderDivider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    id: PropTypes.string,
    headingId: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { children, id, headingId, className, style, refProp, onClick } = this.props

    return (
      <div
        id={id}
        className={cn('ui-header-divider__container', className)}
        style={style}
        ref={refProp}
      >
        <div className='ui-header-divider__box' onClick={onClick}>
          <Text id={headingId} type={Text.Types.headline}>{children}</Text>
        </div>
      </div>
    )
  }
}

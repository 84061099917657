import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  HeaderDivider,
  Select,
  Table,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableDataCell,
  Icon
} from '../../'
import { TableRow } from '../TableComponents'

export class PaymentHistoryCard extends React.PureComponent {
  static propTypes = {
    insuranceSelectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),

    page: PropTypes.number,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        dateBilling: PropTypes.string,
        billAmount: PropTypes.string,
        paymentReceived: PropTypes.string
      })
    ),
    itemsPerPage: PropTypes.number,
    onPageChange: PropTypes.func,
    /**
     * Enables right arrow button in case there are more items needs be fetched from backend
     */
    moreItems: PropTypes.bool,
    /**
     * Max number of pagination buttons to render
     */
    paginationRange: PropTypes.number,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    paginationRange: 5
  }

  render() {
    const {
      page,
      items,
      itemsPerPage,
      onPageChange,
      moreItems,
      insuranceSelectProps,
      id,
      className,
      style,
      refProp,
      paginationRange
    } = this.props

    const itemsToRenderStartIndex = (page - 1) * itemsPerPage
    const itemsToRenderEndIndex = itemsToRenderStartIndex + itemsPerPage
    const itemsToRender = items.slice(
      itemsToRenderStartIndex,
      itemsToRenderEndIndex
    )

    return (
      <Paper id={id} className={cn(className)} style={style} ref={refProp}>
        <PaperContent className={cn('ui-payment-history-card')}>
          <div>
            <HeaderDivider>Payment history:</HeaderDivider>
          </div>

          <Select {...insuranceSelectProps} />

          <div className='ui-payment-history-card__table'>
            <Table>
              <TableHead>
                <TableRow className='ui-payment-history-card__table__row'>
                  <TableHeaderCell>Billing date</TableHeaderCell>
                  <TableHeaderCell>Bill amount</TableHeaderCell>
                  <TableHeaderCell>Payment received</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemsToRender.map(
                  ({ dateBilling, billAmount, paymentReceived }) => (
                    <TableRow
                      key={`${dateBilling}-${billAmount}-${paymentReceived}`}
                      className='ui-payment-history-card__table__row'
                    >
                      <TableDataCell>{dateBilling}</TableDataCell>
                      <TableDataCell>{billAmount}</TableDataCell>
                      <TableDataCell>{paymentReceived}</TableDataCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>

            <div className='ui-payment-history-card__table__pagination__container'>
              <Pagination
                page={page}
                items={items}
                itemsPerPage={itemsPerPage}
                onPageChange={onPageChange}
                moreItems={moreItems}
                paginationRange={paginationRange}
              />
            </div>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

const Pagination = ({
  page,
  items,
  itemsPerPage,
  onPageChange,
  paginationRange,
  moreItems
}) => {
  const totalNumberOfPages = Math.ceil(items.length / itemsPerPage)
  const pageButtonsToRender = getPageButtonsToRender(
    page,
    totalNumberOfPages,
    paginationRange
  )

  const classes = getPaginationClasses(page, totalNumberOfPages, moreItems)

  return (
    <div className={cn(classes.container)}>
      <button
        className={cn(classes.leftButton)}
        onClick={() => page !== 1 && onPageChange(page - 1)}
      >
        <Icon type='arrow_left' />
      </button>

      {pageButtonsToRender.map((v) => {
        return (
          <button
            className={cn(classes.pageButton, {
              'ui-table-pagination__page-button--active': v === page
            })}
            key={`ui-pagination-page-${v}`}
            onClick={() => v !== page && onPageChange(v)}
          >
            {v}
          </button>
        )
      })}

      <button
        className={cn(classes.rightButton)}
        onClick={() =>
          (page !== totalNumberOfPages || moreItems) && onPageChange(page + 1)
        }
      >
        <Icon type='arrow_right' />
      </button>
    </div>
  )
}
Pagination.propTypes = {
  page: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      dateBilling: PropTypes.string,
      billAmount: PropTypes.string,
      paymentReceived: PropTypes.string
    })
  ),
  itemsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  moreItems: PropTypes.bool,
  paginationRange: PropTypes.number
}

const getPageButtonsToRender = (page, totalNumberOfPages, paginationRange) => {
  let paginationStartIndex = page - Math.floor(paginationRange / 2)
  paginationStartIndex = Math.max(
    1,
    Math.min(totalNumberOfPages - paginationRange + 1, paginationStartIndex)
  )
  const paginationEndIndex = Math.min(
    paginationStartIndex + paginationRange,
    totalNumberOfPages + 1
  )

  const result = []

  for (let i = paginationStartIndex; i < paginationEndIndex; i++) {
    result.push(i)
  }

  return result
}

const getPaginationClasses = (page, totalNumberOfPages, moreItems) => {
  const classes = {
    container: ['ui-table-pagination__container'],
    leftButton: ['ui-table-pagination__arrow-control'],
    rightButton: ['ui-table-pagination__arrow-control'],
    pageButton: ['ui-table-pagination__page-button']
  }

  page === 1 &&
    classes.leftButton.push('ui-table-pagination__arrow-control--disabled')
  page === totalNumberOfPages &&
    !moreItems &&
    classes.rightButton.push('ui-table-pagination__arrow-control--disabled')

  return classes
}

import React from "react";
import { ForgotPasswordCard, PageContent } from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { withRouter } from "react-router-dom";
import { AppUtils } from "../../../utils/app-utils";
import { ValidationMessages } from "../../../utils/validation-messages";

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      isEmailError: false,
      errorMessage: "",
    };
    this.cRef = React.createRef();
  }
  sendEmail = () => {
    let isEmailError = false;
    let errorMessage = "";
    if (
      !this.state.email ||
      this.state.email.trim() === "" ||
      !AppUtils.isValidEmail(this.state.email)
    ) {
      isEmailError = true;
    }
    this.setState({
      isEmailError,
      errorMessage,
    });
    if (!isEmailError) {
      const requestData = (({ email }) => ({
        email,
      }))(this.state);
      axios
        .processPost(
          ApiConstants.URL_POST_FORGOT_PASSWORD_EMAIL,
          this.cRef,
          requestData
        )
        .then((response) => {
          this.props.history.push("/auth/forgot-password/success");
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              errorMessage: errors[0],
            });
          }
        });
    }
  };
  render() {
    return (
      <div ref={this.cRef}>
        <PageContent>
          <ForgotPasswordCard
            errorMessage={this.state.errorMessage}
            onFormSubmit={() => {}}
            emailInputProps={{
              id: "fp_email",
              value: this.state.email,
              onChange: (e) => {
                this.setState({
                  email: e.target.value,
                });
              },
              error: this.state.isEmailError,
              hint: this.state.isEmailError
                ? ValidationMessages.EMAIL_VALIDATION_MESSAGE
                : "",
              name: "email",
            }}
            sendEmailLinkButtonProps={{
              id: "send_email_link_button",
              onClick: () => {
                this.sendEmail();
              },
            }}
          ></ForgotPasswordCard>
        </PageContent>
      </div>
    );
  }
}
export default withRouter(ForgotPassword);

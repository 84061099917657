import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Modal, Button, Paper, PaperHeadline, PaperContent, Text } from '../../'

export class FoundPetReportSubmittedModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    headline: PropTypes.string,
    submitAnotherReportButtonText: PropTypes.string,
    closeButtonText: PropTypes.string,
    id: PropTypes.string,
    submitAnotherReportButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    closeButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  static defaultProps = {
    title: "Thank you for submitting a found pet report to 24Petwatch. Our recovery specialists are doing everything they can to quickly contact the owner.",
    submitAnotherReportButtonText: "Submit another found pet report",
    closeButtonText: "Close",
    headline: "Found Report Submitted"
  }

  render() {
    const {
      open,
      onClose,
      className,
      id,
      title,
      headline,
      errorMessage,
      submitAnotherReportButtonProps,
      submitAnotherReportButtonText,
      closeButtonProps,
      closeButtonText,

    } = this.props

    return (
      <Modal
        open={open}
        onClose={onClose}
        className={className}
        id={id}
        refProp
      >
        <div className='ui-report-found-pet-submitted-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline> {headline} </PaperHeadline>
            <PaperContent className='ui-report-found-pet-submitted-modal-content'>
              {errorMessage && (
                <Text
                  className='ui-report-found-pet-submitted-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}
              <Text type={Text.Types.title} className="ui-report-found-pet-submitted-card__text" >
                {title}
              </Text>
              <div className='ui-report-found-pet-submitted-modal__actions'>
                <Button
                  color={Button.Colors.secondary}
                  variant={Button.Variants.filled}
                  {...closeButtonProps}
                >
                {closeButtonText}
                </Button>
                <Button
                  color={Button.Colors.secondary}
                  variant={Button.Variants.outlined}
                  {...submitAnotherReportButtonProps}
                >
                  {submitAnotherReportButtonText}
                </Button>
              </div>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  PaperHeadline,
  PaperContent,
  HeaderDivider,
  Button,
  Icon,
  Text
} from '../../'
import cn from 'classnames'

export class NotificationsCard extends React.PureComponent {
  static propTypes = {
    /**
     * notificationMessages Props
     */
    notificationMessages: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        notificationRequestId: PropTypes.string,
        requestDate: PropTypes.string,
        petname: PropTypes.string,
        ownerName: PropTypes.string,
        chipNumber: PropTypes.string,
        animalId: PropTypes.string,
        description: PropTypes.string,
        isRead: PropTypes.bool,
        status: PropTypes.string,
        statusOn: PropTypes.string,
        statusReason: PropTypes.string
      })
    ),
    openButtonProps: PropTypes.func,
    page: PropTypes.number,
    itemsPerPage: PropTypes.number,
    onPageChange: PropTypes.func,
    /**
     * Enables right arrow button in case there are more items needs be fetched from backend
     */
    moreItems: PropTypes.bool,
    /**
     * Max number of pagination buttons to render
     */
    paginationRange: PropTypes.number,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    notificationMessages: [],
    paginationRange: 4
  }

  render() {
    const {
      notificationMessages,
      openButtonProps,
      page,
      itemsPerPage,
      onPageChange,
      moreItems,
      paginationRange,
      id,
      className,
      style,
      refProp
    } = this.props

    const itemsToRenderStartIndex = (page - 1) * itemsPerPage
    const itemsToRenderEndIndex = itemsToRenderStartIndex + itemsPerPage
    const notificationMessagesItems = notificationMessages.slice(
      itemsToRenderStartIndex,
      itemsToRenderEndIndex
    )

    return (
      <Paper
        id={id}
        className={cn('ui-notifications-card', className)}
        style={style}
      >
        <PaperHeadline>
          <HeaderDivider>Notifications:</HeaderDivider>
        </PaperHeadline>

        <PaperContent className='ui-notifications-card_content'>
          <div className='ui-notifications-card-notifications'>
            {notificationMessagesItems.length === 0 && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-notification-card-no-notification-text'
              >
                You have no notifications.
              </Text>
            )}
            {notificationMessagesItems.map((message, index) => {
              return (
                <div
                  key={`Notification${index}`}
                  className={`ui-notifications-card-notifications_message_container ${
                    index % 2 === 0 ? '' : `with-default-background-color`
                  }`}
                >
                  <div className='ui-notifications-card-notifications_message_text'>
                    {message.description}
                    <div className='ui-notifications-card-notifications_message_date'>
                      {message.requestDate}
                      <span className='ui-notification-card__type'>
                        {message.type}
                      </span>
                    </div>
                  </div>
                  <div className='ui-notifications-card-notifications_message_button'>
                    <Button
                      id={`notification-button-${index}`}
                      variant={
                        message.isRead
                          ? Button.Variants.outlined
                          : Button.Variants.filled
                      }
                      onClick={() => openButtonProps(index)}
                      size={Button.Sizes.small}
                    >
                      Open
                    </Button>
                  </div>
                </div>
              )
            })}
            {notificationMessagesItems.length !== 0 && (
              <div className='ui-payment-history-card__table__pagination__container ui-notification-card-pagination'>
                <Pagination
                  page={page}
                  items={notificationMessages}
                  itemsPerPage={itemsPerPage}
                  onPageChange={onPageChange}
                  moreItems={moreItems}
                  paginationRange={paginationRange}
                />
              </div>
            )}
          </div>
        </PaperContent>
      </Paper>
    )
  }
}


const Pagination = ({
  page,
  items,
  itemsPerPage,
  onPageChange,
  paginationRange,
  moreItems
}) => {
  const totalNumberOfPages = Math.ceil(items.length / itemsPerPage)
  const pageButtonsToRender = getPageButtonsToRender(
    page,
    totalNumberOfPages,
    paginationRange
  )

  const classes = getPaginationClasses(page, totalNumberOfPages, moreItems)

  return (
    <div className={cn(classes.container)}>
      <button
        className={cn(classes.leftButton)}
        onClick={() => page !== 1 && onPageChange(page - 1)}
      >
        <Icon type='arrow_left' />
      </button>

      {pageButtonsToRender.map((v) => {
        return (
          <button
            className={cn(classes.pageButton, {
              'ui-table-pagination__page-button--active': v === page
            })}
            key={`ui-pagination-page-${v}`}
            onClick={() => v !== page && onPageChange(v)}
          >
            {v}
          </button>
        )
      })}

      <button
        className={cn(classes.rightButton)}
        onClick={() =>
          (page !== totalNumberOfPages || moreItems) && onPageChange(page + 1)
        }
      >
        <Icon type='arrow_right' />
      </button>
    </div>
  )
}
Pagination.propTypes = {
  page: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      dateBilling: PropTypes.string,
      billAmount: PropTypes.string,
      paymentReceived: PropTypes.string
    })
  ),
  itemsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  moreItems: PropTypes.bool,
  paginationRange: PropTypes.number
}

const getPageButtonsToRender = (page, totalNumberOfPages, paginationRange) => {
  let paginationStartIndex = page - Math.floor(paginationRange / 2)
  paginationStartIndex = Math.max(
    1,
    Math.min(totalNumberOfPages - paginationRange + 1, paginationStartIndex)
  )
  const paginationEndIndex = Math.min(
    paginationStartIndex + paginationRange,
    totalNumberOfPages + 1
  )

  const result = []

  for (let i = paginationStartIndex; i < paginationEndIndex; i++) {
    result.push(i)
  }

  return result
}

const getPaginationClasses = (page, totalNumberOfPages, moreItems) => {
  const classes = {
    container: ['ui-table-pagination__container'],
    leftButton: ['ui-table-pagination__arrow-control'],
    rightButton: ['ui-table-pagination__arrow-control'],
    pageButton: ['ui-table-pagination__page-button']
  }

  page === 1 &&
    classes.leftButton.push('ui-table-pagination__arrow-control--disabled')
  page === totalNumberOfPages &&
    !moreItems &&
    classes.rightButton.push('ui-table-pagination__arrow-control--disabled')

  return classes
}

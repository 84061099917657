import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, Text, HeaderDivider } from '../../'
import { Summary, Promo, Total, CartItem } from './ShoppingCartComponents'

export class ShoppingCart extends React.PureComponent {
  static propTypes = {
    cartItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            productName: PropTypes.string,
            productCost: PropTypes.string
          })
        )
      })
    ),
    /**
     * Function accepts 2 arguments: index of cartItems array and index of items array
     */
    onRemoveClick: PropTypes.func,
    totalBeforeTax: PropTypes.string,
    errorMessage: PropTypes.string,
    showPromoRow: PropTypes.bool,
    promoProps: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func
    }),
    shippingAmount: PropTypes.string,
    totalTaxes: PropTypes.string,
    subtotal: PropTypes.string,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    cartItems: [],
    promoProps: {},
    showPromoRow: true
  }

  render() {
    const {
      onRemoveClick,
      cartItems,
      totalBeforeTax,
      promoProps,
      shippingAmount,
      totalTaxes,
      showPromoRow,
      subtotal,
      id,
      className,
      errorMessage,
      style,
      refProp
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-shopping-cart__paper', className)}
        style={style}
        ref={refProp}
      >
        <div className='ui-shopping-cart__headline'>
          <Text type={Text.Types.headline}>Your purchase</Text>
        </div>

        {errorMessage && (
          <Text
            type={Text.Types.bodyBold}
            className='ui-shopping-cart__error-message'
          >
            {errorMessage}
          </Text>
        )}
        {cartItems.map(({ title, items = [] }, i) => (
          <div key={`shoping-cart-${title}`} className='ui-shopping-cart__body'>
            <HeaderDivider className='ui-shopping-cart__headline-divider'>
              {title}
            </HeaderDivider>
            {items.map((item, j) => (
              <CartItem
                key={Object.values(item).join('-')}
                onRemoveClick={() => onRemoveClick?.(i, j)}
                {...item}
              />
            ))}
          </div>
        ))}

        <div className='ui-shopping-cart__body'>
          {totalBeforeTax && (
            <Summary label='Subtotal' amount={totalBeforeTax} />
          )}

          {showPromoRow && <Promo {...promoProps} />}

          {shippingAmount && (
            <Summary label='Shipping' amount={shippingAmount} />
          )}

          {totalTaxes && <Summary label='Taxes' amount={totalTaxes} />}

          <Total amount={subtotal} />
        </div>
      </Paper>
    )
  }
}

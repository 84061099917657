import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperHeadline,
  PaperContent,
  Select,
  Button,
  TextArea,
  Text,
  Form
} from '../../'

export class MessageUsCard extends React.PureComponent {
  static propTypes = {
    selectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    noteProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    submitButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      selectProps,
      noteProps,
      successMessage,
      errorMessage,
      submitButtonProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn(className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Message us</PaperHeadline>

        <PaperContent className={cn('ui-message-us-card__content')}>
          <Text className='ui-message-us-card__subtitle'>
            How can we help? Let us know using the options below.
          </Text>

          {successMessage && (
            <Text
              type={Text.Types.bodyBold}
              className='ui-message-us-card__success-message'
            >
              {successMessage}
            </Text>
          )}

          {errorMessage && (
            <Text
              type={Text.Types.bodyBold}
              className='ui-message-us-card__error-message'
            >
              {errorMessage}
            </Text>
          )}

          <Form>
            <Select label='What is this regarding?' {...selectProps} />

            <TextArea label='How Can We Help You?' {...noteProps} />

            <div className='ui-message-us-card__action'>
              <Button
                type='submit'
                color={Button.Colors.tertiary}
                {...submitButtonProps}
              >
                Submit
              </Button>
            </div>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

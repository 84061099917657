import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
// Minimal width for the desktop layout to look good, after this break point render mobile view
const MOBILE_VIEW_BREAKPOINT = 927

import {
  Paper,
  PaperContent,
  HeaderDivider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell
} from '../../'

import {
  ClaimHistoryItem,
  RecoveryHistoryItem,
  MobileClaimHistory,
  MobileRecoveryHistory
} from './PetClaimAndRecoveryHistoryComponents'
import { Text } from '../Text'

export class PetClaimAndRecoveryHistory extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      showMobile: window.innerWidth < MOBILE_VIEW_BREAKPOINT
    }
    this.handleViewBasedOnViewportWidth = this.handleViewBasedOnViewportWidth.bind(
      this
    )
  }
  static propTypes = {
    claimsNote: PropTypes.string,
    claimHistory: PropTypes.arrayOf(
      PropTypes.shape({
        instanceID: PropTypes.string,
        submissionNumber: PropTypes.string,
        submissionNumberActive: PropTypes.bool,
        dateSubmitted: PropTypes.string,
        status: PropTypes.shape({
          error: PropTypes.bool,
          name: PropTypes.string
        }),
        submittedAmount: PropTypes.string,
        amountPaid: PropTypes.string,
        documents: PropTypes.arrayOf(
          PropTypes.shape({
            active: PropTypes.bool,
            key: PropTypes.string.isRequired,
            documentData: PropTypes.any
          })
        )
      })
    ),
    /**
     * Callback is triggered when user clicks download button
     */
    onDocumentClick: PropTypes.func,
    /**
     * Callback is triggered when user clicks submission number
     */
    onSubmissionNumberClick: PropTypes.func,

    recoveryHistory: PropTypes.arrayOf(
      PropTypes.shape({
        caseID: PropTypes.string,
        dateOpened: PropTypes.string,
        lastUpdated: PropTypes.string,
        status: PropTypes.string,
        note: PropTypes.string
      })
    ),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    recoveryHistory: [],
    claimHistory: []
  }
  handleViewBasedOnViewportWidth() {
    window.innerWidth < MOBILE_VIEW_BREAKPOINT
      ? this.setState({ showMobile: true })
      : this.setState({ showMobile: false })
  }

  componentDidMount() {
    this.handleViewBasedOnViewportWidth()
    window.addEventListener('resize', this.handleViewBasedOnViewportWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleViewBasedOnViewportWidth)
  }

  render() {
    const {
      claimHistory,
      onSubmissionNumberClick,
      onDocumentClick,
      recoveryHistory,
      id,
      className,
      style,
      claimsNote,
      refProp
    } = this.props

    const isRecoveryHistoryEmpty = Object.keys(recoveryHistory).length === 0
    const isClaimHistoryEmpty = Object.keys(claimHistory).length === 0

    return (
      <Paper
        id={id}
        className={cn(
          {
            'ui-cr-history--hidden':
              isRecoveryHistoryEmpty && isClaimHistoryEmpty
          },
          className
        )}
        style={style}
        ref={refProp}
      >
        <PaperContent>
          <div
            className={cn('ui-cr-history__item', {
              'ui-cr-history__item--hidden': isClaimHistoryEmpty
            })}
          >
            <HeaderDivider>Pet claim history:</HeaderDivider>
            {this.state.showMobile ? (
              <div className='mobile-claim-history-wrapper'>
                {claimHistory.map((claim, index) => {
                  return (
                    <MobileClaimHistory
                      id={`pet-claim-history-table-mobile-${index}`}
                      key={createKeyOutOfClaimProps(claim)}
                      claim={claim}
                      onDocumentClick={onDocumentClick}
                      onSubmissionNumberClick={onSubmissionNumberClick}
                    ></MobileClaimHistory>
                  )
                })}
              </div>
            ) : (
              <div className='ui-cr-history__table-container'>
                <Table id="pet-claim-history-table">
                  <TableHead>
                    <TableRow id="pet-claim-history-table-heading">
                      <TableHeaderCell>Submission #</TableHeaderCell>
                      <TableHeaderCell>Date submitted</TableHeaderCell>
                      <TableHeaderCell>Status</TableHeaderCell>
                      <TableHeaderCell>Submitted amount</TableHeaderCell>
                      <TableHeaderCell>Amount paid</TableHeaderCell>
                      <TableHeaderCell>Document</TableHeaderCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {claimHistory.map((claim, index) => (
                      <ClaimHistoryItem
                        id={`pet-claim-history-table-body-${index}`}
                        key={createKeyOutOfClaimProps(claim)}
                        claim={claim}
                        onDocumentClick={onDocumentClick}
                        onSubmissionNumberClick={onSubmissionNumberClick}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
            <Text type={Text.Types.body} className='ui-claim_history-note'>
              {claimsNote}
            </Text>
          </div>

          <div
            className={cn('ui-cr-history__item', {
              'ui-cr-history__item--hidden': isRecoveryHistoryEmpty
            })}
          >
            <HeaderDivider>Pet recovery history:</HeaderDivider>
            {this.state.showMobile ? (
              <div className='mobile-claim-history-wrapper'>
                {recoveryHistory.map((recovery, index) => {
                  return (
                    <MobileRecoveryHistory
                      id={`pet-recovery-history-table-mobile-${index}`}
                      key={createKeyOutOfRecoveryProps(recovery)}
                      recovery={recovery}
                    ></MobileRecoveryHistory>
                  )
                })}
              </div>
            ) : (
              <div className='ui-cr-history__table-container'>
                <Table id="pet-recovery-history-table">
                  <TableHead>
                    <TableRow id="pet-recovery-history-table-heading">
                      <TableHeaderCell>Case ID</TableHeaderCell>
                      <TableHeaderCell>Opened</TableHeaderCell>
                      <TableHeaderCell>Last updated</TableHeaderCell>
                      <TableHeaderCell>Status</TableHeaderCell>
                      <TableHeaderCell>Note</TableHeaderCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {recoveryHistory.map((recovery, index) => (
                      <RecoveryHistoryItem
                        id={`pet-recovery-history-table-body-${index}`}
                        key={createKeyOutOfRecoveryProps(recovery)}
                        recovery={recovery}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

function createKeyOutOfClaimProps(claim = {}) {
  return `${claim.submissionNumber}-${claim.dateSubmitted}-${claim?.status?.name}-${claim.submittedAmount}`
}

function createKeyOutOfRecoveryProps(recovery = {}) {
  return `${recovery.caseID}-${recovery.dateOpened}-${recovery.status}`
}

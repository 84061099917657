import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperHeadline, PaperContent, Text, Link } from '../../'
import { Locale as importedLocale } from '../../constants'

const defaultHoursOfOperationContent = [
  {
    title: 'Sales',
    lines: [
      'Mon - Thur: 8AM-9PM EST',
      'Fri: 8AM-7PM EST',
      'Sat: CLOSED',
      'Sunday: CLOSED'
    ]
  },

  {
    title: 'Claims & Customer Service',
    lines: [
      'Mon - Thur: 8AM-9PM EST',
      'Fri: 8AM-7PM EST',
      'Sat: CLOSED',
      'Sunday: CLOSED'
    ]
  }
]

export class ContactInfoCard extends React.PureComponent {
  static Locale = importedLocale

  static propTypes = {
    locale: PropTypes.oneOf(Object.values(ContactInfoCard.Locale)),

    id: PropTypes.string,
    className: PropTypes.string,
    companyName: PropTypes.string,
    phoneNumber: PropTypes.string,
    salesText: PropTypes.string,
    header: PropTypes.node,
    hoursOfOperationContent: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        lines: PropTypes.arrayOf(PropTypes.string)
      })
    ),
    isPethealthProgram: PropTypes.bool,

    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    locale: ContactInfoCard.Locale.us,
    salesText: "Sales",
    hoursOfOperationContent: defaultHoursOfOperationContent
  }

  render() {
    const {
      locale,
      id,
      className,
      style,
      refProp,
      companyName,
      phoneNumber,
      salesText,
      hoursOfOperationContent = [],
      isPethealthProgram,
      header
    } = this.props
    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn(className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Contact information</PaperHeadline>

        {locale === ContactInfoCard.Locale.ca && (
          <PaperContent className={cn('ui-contact-info-card__content')}>
            {!isPethealthProgram && (<>
              <Text type={Text.Types.title}>Canada</Text>

              <Text>
                {header ? header : <DefaultCaHeader phoneNumber={phoneNumber} />}
              </Text>

              <Text className={cn( {'ui-text--underline' : isPethealthProgram })}>
                Hours of Operation:
              </Text>
            </>)}
            {hoursOfOperationContent.map(({ title, lines = [] }) => (
              <Text key={`hoursOfOperationContent-${title}`}>
                {title && (
                  <>
                    <Text type={Text.Types.bodyBold}>{title}</Text>
                  </>
                )}
                {lines.map((_line, i) => (
                  <React.Fragment key={_line}>
                    {!!i && <br />}
                    {_line}
                  </React.Fragment>
                ))}
              </Text>
            ))}

            <Text type={Text.Types.bodyBold} className={cn( {'ui-text--underline' : !isPethealthProgram })}>{isPethealthProgram ? 'By Mail' : 'Or write:'}</Text>
            <Text renderAs='address'>
              {isPethealthProgram ? 'PTZ Insurance Services Ltd.' : companyName }
              <br />
              710 Dorval Drive, Suite 400
              <br />
              Oakville, ON L6K 3V7
            </Text>
          </PaperContent>
        )}

        {locale === ContactInfoCard.Locale.us && (
          <PaperContent className={cn('ui-contact-info-card__content')}>
            {!isPethealthProgram && (<>
              <Text type={Text.Types.title}>USA</Text>

              <Text>
                {header ? header : <DefaultUsHeader phoneNumber={phoneNumber} />}
              </Text>

              <Text className={cn( { 'ui-text--underline' : isPethealthProgram })}>
                Hours of Operation:
              </Text>
            </>)}
            {hoursOfOperationContent.map(({ title, lines = [] }) => (
              <Text key={`hoursOfOperationContent-${title}`}>
                {title && (
                  <>
                    <Text type={Text.Types.bodyBold}>{title}</Text>
                  </>
                )}
                {lines.map((_line, i) => (
                  <React.Fragment key={_line}>
                    {!!i && <br />}
                    {_line}
                  </React.Fragment>
                ))}
              </Text>
            ))}

            <Text type={Text.Types.bodyBold} className={cn( {'ui-text--underline' : !isPethealthProgram })}>{isPethealthProgram ? 'By Mail' : 'Or write:'}</Text>
            <Text renderAs='address'>
            {isPethealthProgram ? '24Petwatch' : companyName }
              <br />
              P.O Box 2150
              <br />
              Buffalo, NY 14240-2150
            </Text>
          </PaperContent>
        )}
      </Paper>
    )
  }
}

const DefaultCaHeader = ({ phoneNumber }) => (
  <>
    {/* Lost Pet Recovery: Open 24/7/365
    <br />
    {salesText}, Claims and Customer Service:{' '} */}
    All Services: {' '}
    <Link href={'tel:' + phoneNumber}>{phoneNumber}</Link>
  </>
)

const DefaultUsHeader = ({ phoneNumber }) => (
  <>
    {/* Lost Pet Recovery: Open 24/7/365
    <br />
    {salesText}, Claims and Customer Service:{' '} */}
    All Services: {' '}
    <Link href={'tel:' + phoneNumber}>{phoneNumber}</Link>
  </>
)

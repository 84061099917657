import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { PUBLIC_URL } from '../../constants'

export const TagShapes = Object.freeze({
  bone: 'bone',
  circle: 'circle',
  heart: 'heart'
})

export const TagStyles = Object.freeze({
  red: 'red',
  charcoal: 'charcoal',
  teal: 'teal',
  traditional: 'traditional',
  paw: 'pet paws'
})

export const TagShapeSelect = ({
  onChange,
  selectedShape,
  isStandardMetalTags
}) => {
  return (
    <div className={cn('ui-pet-tags-card__design-container')}>
      <span>Shape:</span>

      <div className={cn('ui-pet-tags-card__shape-select')}>
        <div
          id='bone-shape-tag'
          role='button'
          onClick={() => onChange(TagShapes.bone)}
          className={cn('ui-pet-tags-card__shape-select__item-shape', {
            'ui-pet-tags-card__shape-select__item-shape--active':
              TagShapes.bone === selectedShape
          })}
        >
          <img
            alt={TagShapes.bone}
            src={getImgSrc(shapesSelects[TagShapes.bone])}
          />
        </div>
        {isStandardMetalTags ? (
          <div
            id='heart-shape-tag'
            role='button'
            onClick={() => onChange(TagShapes.heart)}
            className={cn('ui-pet-tags-card__shape-select__item-shape', {
              'ui-pet-tags-card__shape-select__item-shape--active':
                TagShapes.heart === selectedShape
            })}
          >
            <img
              alt={TagShapes.heart}
              src={getImgSrc(shapesSelects[TagShapes.heart])}
            />
          </div>
        ) : (
          <div
            id='circle-shape-tag'
            role='button'
            onClick={() => onChange(TagShapes.circle)}
            className={cn('ui-pet-tags-card__shape-select__item-shape', {
              'ui-pet-tags-card__shape-select__item-shape--active':
                TagShapes.circle === selectedShape
            })}
          >
            <img
              alt={TagShapes.circle}
              src={getImgSrc(shapesSelects[TagShapes.circle])}
            />
          </div>
        )}
      </div>
    </div>
  )
}
TagShapeSelect.propTypes = {
  onChange: PropTypes.func,
  selectedShape: PropTypes.oneOf(Object.values(TagShapes)),
  isStandardMetalTags: PropTypes.bool
}

export const TagStyleSelect = ({
  onChange,
  selectedStyle,
  isStandardMetalTags
}) => {
  return (
    <div className={cn('ui-pet-tags-card__design-container')}>
      <span>Design:</span>

      {isStandardMetalTags ? (
        <div className={cn('ui-pet-tags-card__style-select')}>
          {standardColorSelects.map(([style, color]) => {
            return (
              <div
                id={`standard-${style}-tag`}
                key={`${style}-${color}`}
                className={cn('ui-pet-tags-card__shape-select__item-style', {
                  'ui-pet-tags-card__shape-select__item-style--active':
                    selectedStyle === style
                })}
                style={{ backgroundColor: color }}
                role='button'
                onClick={() => selectedStyle !== style && onChange(style)}
              />
            )
          })}
        </div>
      ) : (
        <div className={cn('ui-pet-tags-card__style-select')}>
          {lifeTimeStyleSelects.map(([style, styleSrc]) => {
            return (
              <div
                id={`lifetime-${style}-tag`}
                key={`${style}-${styleSrc}`}
                className={cn('ui-pet-tags-card__shape-select__item-style', {
                  'ui-pet-tags-card__shape-select__item-style--active':
                    selectedStyle === style
                })}
                style={{ backgroundImage: `url(${getImgSrc(styleSrc)})` }}
                role='button'
                onClick={() => selectedStyle !== style && onChange(style)}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
TagStyleSelect.propTypes = {
  onChange: PropTypes.func,
  selectedStyle: PropTypes.oneOf(Object.values(TagStyles)),
  isStandardMetalTags: PropTypes.bool
}

const getImgSrc = (src) => PUBLIC_URL + src

const shapesSelects = {
  [TagShapes.bone]: 'selectShapeBone.png',
  [TagShapes.heart]: 'selectShapeHeart.png',
  [TagShapes.circle]: 'selectShapeRound.png'
}

const standardColorSelects = [
  [TagStyles.red, '#BF0D3E'],
  [TagStyles.charcoal, '#333F48'],
  [TagStyles.teal, '#007680'],
]

const lifeTimeStyleSelects = [
  [TagStyles.traditional, 'selectStyleTraditional.png'],
  [TagStyles.paw, 'selectStylePaw.png']
]

import React from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Form,
  Input,
  Select,
  Text
} from '../../'

export class AddMicrochipModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    phoneNumber: PropTypes.string,
    onFormSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
    microchipNumberInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    showMicrochipCompanySelect: PropTypes.bool,
    microchipCompanySelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,
      label: PropTypes.string,
      placeholder: PropTypes.string,

      id: PropTypes.string
    }),

    cancelButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    saveButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  static defaultProps = {
    showMicrochipCompanySelect: false
  }

  render() {
    const {
      open,
      onClose,
      errorMessage,
      microchipNumberInputProps,
      microchipCompanySelectFieldProps,
      showMicrochipCompanySelect,
      cancelButtonProps,
      saveButtonProps,
      onFormSubmit,
      phoneNumber
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-add-microchip-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline>Add a microchip</PaperHeadline>
            <PaperContent>
              <Text
                className='ui-add-microchip-modal__text'
                type={Text.Types.caption}
              >
                If you have any questions about your microchip number, please
                call {phoneNumber}.
              </Text>

              {errorMessage && (
                <Text
                  className='ui-add-microchip-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}

              <Form onSubmit={onFormSubmit}>
                {!showMicrochipCompanySelect ? (
                  <Input
                    placeholder='Microchip Number'
                    {...microchipNumberInputProps}
                  />
                ) : (
                  <Select {...microchipCompanySelectFieldProps} />
                )}

                <div className='ui-add-microchip-modal__actions'>
                  <Button
                    type='button'
                    variant={Button.Variants.outlined}
                    color={Button.Colors.tertiary}
                    {...cancelButtonProps}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    color={Button.Colors.tertiary}
                    {...saveButtonProps}
                  >
                    {!showMicrochipCompanySelect ? 'Continue' : 'Save changes'}
                  </Button>
                </div>
              </Form>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

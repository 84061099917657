import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
    Paper,
    PaperContent,
    PaperHeadline,
    OptionCheckbox,
    Checkbox,
    HeaderDivider,
    Text
} from '../../'
import { MedAlert, HelpLine, PetCo, Rover, Dog } from '../SvgIcons'
import { IconText } from './IconText'
import { createArrayIncludesValueCheck } from '../../utils'

export class AnnualProtectionMembershipCard extends React.PureComponent {
    static Icons = Object.freeze({
        petMedAlert: 'petMedAlert',
        vetHelpLine: 'vetHelpLine',
        petco: 'petco',
        rover: 'rover',
        warranty: 'warranty'
    })

    static propTypes = {
        title: PropTypes.string,
        subscriptionOptions: PropTypes.arrayOf(
            PropTypes.shape({
                /**
                 * Unique key prop to render as array
                 */
                key: PropTypes.string.isRequired,
                /**
                 * The rest are OptionCheckbox Props
                 */
                variant: PropTypes.oneOf(Object.values(OptionCheckbox.Variants)),
                checked: PropTypes.bool,
                onClick: PropTypes.func,
                showCheckMark: PropTypes.bool,
                label: PropTypes.any,
                description: PropTypes.shape({
                    price: PropTypes.string,
                    frequency: PropTypes.string,
                    detail: PropTypes.string
                }),
                id: PropTypes.string,
                className: PropTypes.string,
                style: PropTypes.object,
                refProp: PropTypes.oneOfType([
                    PropTypes.func,
                    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
                ]),
                isOneTimeShow: PropTypes.bool
            })
        ),
        petMedAlertIconTextBody: PropTypes.string,
        vetHelpLineIconTextBody: PropTypes.string,
        legalLine: PropTypes.string,
        iconsToHide: PropTypes.arrayOf(
            PropTypes.oneOf(Object.values(AnnualProtectionMembershipCard.Icons))
        ),
        banner: PropTypes.element,
        showCheckbox: PropTypes.bool,
        checkboxProps: PropTypes.shape({
            checked: PropTypes.bool,
            onChange: PropTypes.func,
            name: PropTypes.string,
            value: PropTypes.string,
            id: PropTypes.string,
            errorMessage: PropTypes.string
        }),

        disableContent: PropTypes.bool,
        id: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        refProp: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ])
    }

    static defaultProps = {
        subscriptionOptions: [],
        disableContent: false,
        showCheckbox: true,
        iconsToHide: [],
        title: 'Annual Protection Membership plan',
        petMedAlertIconTextBody:
            'Critical medical and behavioural information will be relayed to the shelter or vet they’re brought to when found. Complimentary access for 1 year with a Lifetime Protection Membership.',
        vetHelpLineIconTextBody:
            'Reach veterinary professionals anytime by phone, email, or live chat. Complimentary access for 1 year with a Lifetime Protection Membership.'
    }

    render() {
        const {
            title,
            banner,
            showCheckbox,
            checkboxProps,
            disableContent,
            subscriptionOptions,
            strikeThroughPrice,
            iconsToHide,
            id,
            className,
            legalLine,
            petMedAlertIconTextBody,
            vetHelpLineIconTextBody,
            style,
            refProp
        } = this.props

        const showIcon = createArrayIncludesValueCheck(iconsToHide)

        return (
            <Paper
                type={Paper.Types.framed}
                id={id}
                className={cn(
                    'ui-annual-protection-membership-card',
                    {
                        'ui-annual-protection-membership-card--disabled': disableContent
                    },
                    className
                )}
                style={style}
                ref={refProp}
            >
                <PaperHeadline>{title}</PaperHeadline>

                {banner}

                <PaperContent>
                    <HeaderDivider>Available subscriptions:</HeaderDivider>

                    <div className='ui-annual-protection-membership-card__options'>
                        {subscriptionOptions.map(({ key, ...restProps }) => (
                            <OptionCheckbox key={key} {...restProps} />
                        ))}
                    </div>

                    <Text className='ui-annual-protection-membership-card__upgrade-prompt'>
                        <strong>Upgrading to Lifetime Protection Membership.</strong>
                        <br />
                        Not only do you save time and money by not having to renew every
                        year, but you also receive the following perks and more.
                    </Text>

                    <div className={cn('ui-annual-protection-membership-card__icons')}>
                        {showIcon(AnnualProtectionMembershipCard.Icons.petMedAlert) && (
                            <IconText
                                svgIcon={<MedAlert />}
                                title={
                                    <span>
                                        24PetMedAlert<sup>®</sup> for 1 year
                                    </span>
                                }
                                body={petMedAlertIconTextBody}
                            />
                        )}

                        {showIcon(AnnualProtectionMembershipCard.Icons.vetHelpLine) && (
                            <IconText
                                svgIcon={<HelpLine />}
                                title='24/7 Vet Helpline for 1 year'
                                body={vetHelpLineIconTextBody}
                            />
                        )}

                        {showIcon(AnnualProtectionMembershipCard.Icons.petco) && (
                            <IconText
                                svgIcon={<PetCo />}
                                title='$25 Petco Coupon'
                                body='Redeem for in-store purchases at Petco with Pals Rewards member sign-up.'
                            />
                        )}

                        {showIcon(AnnualProtectionMembershipCard.Icons.rover) && (
                            <IconText
                                svgIcon={<Rover />}
                                title='$30 Rover Discount'
                                body='Save on pet-sitting and dog walking services from Rover.com'
                            />
                        )}

                        {showIcon(AnnualProtectionMembershipCard.Icons.warranty) && (
                            <IconText
                                svgIcon={<Dog />}
                                title='Lifetime Warranty ID Tag'
                                body='Get an ID tag customized with your pet’s name and unique microchip number.'
                            />
                        )}
                    </div>

                    {showCheckbox && (
                        <Checkbox
                            label='After one year, your complimentary access expires—auto-renew 24PetMedAlert Pet and 24/7 Vet Helpline to keep enjoying these great benefits.'
                            {...checkboxProps}
                        />
                    )}
                    {legalLine && (
                        <Text
                            className='ui-membership-card__legal-line'
                            type={Text.Types.caption}
                        >
                            {legalLine}
                        </Text>
                    )}
                </PaperContent>
            </Paper>
        )
    }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Icon, Text } from '../../'
import { CheckboxSquare, CheckboxSquareUnchecked } from '../SvgIcons'

export class Checkbox extends Component {
  static Colors = Object.freeze({
    secondary: 'secondary',
    tertiary: 'tertiary'
  })

  static propTypes = {
    hidden: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.any,
    errorMessage: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
    id: PropTypes.string,
    showSquareCheckbox: PropTypes.bool,
    showPetplaceCheckbox: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.oneOf(Object.values(Checkbox.Colors)),
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    checked: false,
    hidden: false,
    showSquareCheckbox: false,
    showPetplaceCheckbox: false,
    color: Checkbox.Colors.secondary
  }

  render() {
    const {
      hidden,
      disabled,
      label,
      checked,
      name,
      value,
      id,
      refProp,
      onClick,
      onChange,
      className,
      style,
      showSquareCheckbox,
      showPetplaceCheckbox,
      errorMessage,
      color
    } = this.props

    if (hidden) return null

    const classes = getClasses(checked, disabled, color)

    return (
      <div>
        <label
          style={style}
          onClick={onClick}
          className={cn(classes.container, className)}
          htmlFor={id}
        >
          <div className={cn(classes.iconContainer)}>
            <input
              checked={checked}
              type='checkbox'
              className={cn(classes.hiddenInput)}
              onChange={onChange}
              name={name}
              value={value}
              id={id}
              ref={refProp}
              disabled={disabled}
            />
            {(!showSquareCheckbox && !showPetplaceCheckbox) && <Icon
              className={cn(classes.icon)}
              type={checked ? 'check_circle' : 'radio_button_unchecked'}
            />}
            {showPetplaceCheckbox && <Icon
              className={cn(classes.icon)}
              type={checked ? 'check_box' : 'check_box_outline_blank'}
            />}
            {showSquareCheckbox && <Icon
              className={cn(classes.icon)}
              svgType={checked
                ? <CheckboxSquare></CheckboxSquare>
                : <CheckboxSquareUnchecked></CheckboxSquareUnchecked>}
            />}
          </div>
          <Text className={cn(classes.text)}>{label}</Text>
        </label>
        {errorMessage && (
          <Text
            className={'ui-checkbox__error-message'}
            type={Text.Types.caption}
          >
            {errorMessage}
          </Text>
        )}
      </div>
    )
  }
}

export const getClasses = (checked, disabled, color) => {
  const classes = {
    container: ['ui-checkbox__container'],
    iconContainer: ['ui-checkbox__icon__container'],
    icon: ['ui-checkbox__icon'],
    hiddenInput: ['ui-checkbox__hidden-input'],
    text: ['ui-checkbox__text']
  }

  checked &&
    color === Checkbox.Colors.secondary &&
    classes.icon.push('ui-checkbox__icon--checked-secondary')
  checked &&
    color === Checkbox.Colors.tertiary &&
    classes.icon.push('ui-checkbox__icon--checked-tertiary')

  disabled && classes.container.push('ui-checkbox__container--disabled')

  return classes
}

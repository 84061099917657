import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUserDetailsForSocialLogin } from "../../redux/actions/authActions";
import { loginFailure, loginRequest } from "../../redux/reducers/auth-reducer";
import { DataLayerUtils } from "../../utils/dataLayer-utils";
const queryString = require("query-string");

class SocialLoginHandler extends Component {
  constructor() {
    super();
    this.cRef = React.createRef();
  }
  componentDidMount() {
    const parsed = queryString.parse(this.props.history.location.search);
    if (parsed.error) {
      this.props.loginFailed([parsed.error]);
      this.props.history.push("/auth");
      DataLayerUtils.loginErrorEvent(parsed.error);
    } else {
      this.props.fetchUserDetails(this.props.history, this.cRef, null, null);
    }
  }
  render() {
    return <div className="social-login-div" ref={this.cRef}></div>;
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDetails: (history, cRef, accType, method) =>
      dispatch(fetchUserDetailsForSocialLogin(history, cRef, accType, method)),
    loginFailed: (errors) => dispatch(loginFailure(errors)),
    loginRequest: () => dispatch(loginRequest()),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(withRouter(SocialLoginHandler));

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text, Icon } from '../../'

export const PageHeaderProps = {
  logo: PropTypes.element,
  onLogoClick: PropTypes.func,

  showPetMenu: PropTypes.bool,
  petMenuProps: PropTypes.shape({
    onMenuClick: PropTypes.func,
    elementId: PropTypes.string,
    pets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    onSelectedPetChange: PropTypes.func,
    selectedPetId: PropTypes.string,
    newPetButtonProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string
    })
  }),

  showAccount: PropTypes.bool,
  accountMenuProps: PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func,
    nameOnAccount: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    onSelectedItemChange: PropTypes.func,
    selectedItemId: PropTypes.string,
    showNotificationMark: PropTypes.bool,
  }),
  showSubmitClaim: PropTypes.bool,
  submitClaimButtonProps: PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func
  }),

  showReportLostPetButton: PropTypes.bool,
  reportLostPetButtonProps: PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func
  }),

  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
}

// link-like button with icon designed for PageHeader
export const PageHeaderButton = ({
  children,
  leftIconType,
  rightIconType,
  id,
  className,
  onClick,
  mouseEnter
}) => (
  <button
    id={id}
    className={cn('ui-page-header__page-header-button', className)}
    onClick={onClick}
    onMouseOver={mouseEnter}
  >
    {leftIconType && (
      <Icon
        className='ui-page-header__page-header-button__icon--left'
        type={leftIconType}
      />
    )}
    <Text type={Text.Types.button}>{children}</Text>
    {rightIconType && (
      <Icon
        className='ui-page-header__page-header-button__icon--right'
        type={rightIconType}
      />
    )}
  </button>
)
PageHeaderButton.propTypes = {
  children: PropTypes.any,
  leftIconType: PropTypes.string,
  rightIconType: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  mouseEnter: PropTypes.func,
}

export const LogoSvg = () => {
  return (
    <svg
      width='169'
      height='35'
      viewBox='0 0 169 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M37.5373 17.5052C36.7997 14.2198 33.6033 12.6592 30.4069 13.0699C27.4563 13.727 25.0795 16.191 25.4893 19.3943C26.145 22.5976 29.1775 24.3224 32.2919 23.9939C35.0786 23.5832 37.4554 21.4477 37.5373 18.6551V18.0801C37.6193 17.9159 37.5373 17.7516 37.5373 17.5052Z'
        fill='white'
      />
      <path
        d='M11.0645 21.6941C10.3269 22.1048 10.1629 23.0083 10.5727 23.7475C10.9006 24.1581 11.3923 24.4867 11.8841 24.4867C12.13 24.4867 12.3758 24.4046 12.7037 24.2403C13.4413 23.8296 13.6052 22.9261 13.1954 22.1869C12.9496 21.6941 12.4578 21.4477 11.8841 21.4477C11.6382 21.4477 11.3104 21.5298 11.0645 21.6941Z'
        fill='white'
      />
      <path
        d='M11.8841 11.1808C5.32735 11.1808 0 16.5196 0 23.0904C0 29.6612 5.32735 35 11.8841 35C18.4408 35 23.7682 29.6612 23.7682 23.0904C23.8501 16.5196 18.5228 11.1808 11.8841 11.1808ZM13.0315 24.8152L11.8021 24.9795L7.13046 25.6366L10.6547 20.9549L11.8021 20.5442L21.2274 17.2588L13.0315 24.8152Z'
        fill='white'
      />
      <path
        d='M6.72066 10.8522C8.4418 9.53806 9.58924 7.23828 8.8516 5.10276C8.03201 3.13151 5.90107 3.54219 4.4258 4.60995C3.27837 5.51343 2.45878 6.99187 2.29486 8.55244V9.04525C2.29486 9.37379 2.37682 9.6202 2.45878 9.94874C3.19641 11.92 5.24539 11.92 6.72066 10.8522Z'
        fill='white'
      />
      <path
        d='M13.7692 8.55244C16.3919 8.4703 18.6867 6.49906 19.3424 4.035C19.7522 1.65308 18.195 -0.1539 15.8181 0.0103702C13.4413 0.256776 11.3923 2.06375 10.6547 4.36354C10.2449 6.58119 11.4743 8.4703 13.7692 8.55244Z'
        fill='white'
      />
      <path
        d='M29.0955 3.87073C26.7187 2.22802 23.5223 2.88511 21.4733 4.77422C19.6702 6.66333 19.2604 9.6202 21.2274 11.5093C23.6043 13.3984 26.9646 12.8235 29.0955 10.9344C31.0626 8.88098 31.4724 5.75984 29.0955 3.87073Z'
        fill='white'
      />
      <path
        d='M80.0745 18.2463C80.0745 17.8356 80.0745 17.4249 79.9925 17.0964C79.7467 15.8644 79.091 14.8787 78.2714 14.2217C77.2879 13.4003 75.8126 12.9896 73.8456 12.9075C73.5178 12.9075 73.2719 12.9075 72.944 12.9075C70.5672 12.9075 68.8461 13.0718 67.6167 13.236V30.3202H71.5507V24.16C71.9605 24.16 72.3703 24.2422 72.944 24.2422C73.2719 24.2422 73.5997 24.2422 73.9276 24.2422C75.8126 24.0779 77.4518 23.5029 78.5992 22.4352C79.3369 21.696 79.8286 20.7925 80.0745 19.6426C79.9925 19.1498 80.0745 18.657 80.0745 18.2463ZM73.8456 21.121C73.5178 21.121 73.1899 21.2032 72.7801 21.2032C72.2064 21.2032 71.7966 21.2032 71.4688 21.121V16.0286C71.7966 15.9465 72.2884 15.8644 73.108 15.8644C73.3538 15.8644 73.5997 15.8644 73.8456 15.9465C75.3209 16.1108 76.2224 17.0143 76.2224 18.4106C76.2224 19.8069 75.2389 20.7925 73.8456 21.121Z'
        fill='white'
      />
      <path
        d='M149.576 20.4639C150.559 20.4639 151.297 20.6282 151.789 20.8746L152.445 17.9999C151.625 17.6713 150.478 17.5071 149.412 17.5071C144.74 17.5071 142.118 20.5461 142.118 24.16C142.118 28.1025 144.74 30.6487 148.838 30.6487C150.314 30.6487 151.625 30.4023 152.363 30.0738L151.953 27.199C151.379 27.4454 150.641 27.6097 149.658 27.6097C147.773 27.6097 146.216 26.3777 146.216 24.0779C146.134 21.9424 147.445 20.4639 149.576 20.4639Z'
        fill='white'
      />
      <path
        d='M133.102 22.8459C133.102 19.889 131.791 17.4249 127.529 17.4249H127.447C125.152 17.4249 123.513 18.082 122.611 18.5748L123.349 21.121C124.169 20.6282 125.562 20.1354 126.873 20.1354C127.119 20.1354 127.283 20.1354 127.447 20.1354C128.84 20.2997 129.168 21.121 129.168 21.696V21.8602C128.594 21.8602 128.021 21.8602 127.447 21.9424C125.07 22.1888 123.267 23.0101 122.365 24.4064C121.956 25.0635 121.71 25.8027 121.71 26.7062C121.71 27.4454 121.956 28.1847 122.365 28.8417C123.021 29.8274 124.251 30.5666 125.808 30.5666C126.381 30.5666 126.955 30.4844 127.447 30.3202C128.267 30.0738 128.922 29.581 129.414 29.006H129.496L129.742 30.238H133.266C133.184 29.4988 133.102 28.3489 133.102 27.199V22.8459ZM129.25 25.7206C129.25 25.967 129.25 26.2134 129.168 26.3777C128.922 27.1169 128.267 27.6918 127.447 27.774C127.365 27.774 127.283 27.774 127.119 27.774C126.218 27.774 125.562 27.2812 125.562 26.2955C125.562 25.2278 126.3 24.6528 127.365 24.4886C127.857 24.4064 128.512 24.3243 129.168 24.3243V25.7206H129.25Z'
        fill='white'
      />
      <path
        d='M160.804 17.4249C160.067 17.4249 159.329 17.5892 158.755 17.9178C158.182 18.2463 157.608 18.657 157.198 19.1498V11.9219H153.264V30.238H157.198V22.7637C157.198 22.4352 157.198 22.1888 157.28 21.9424C157.608 21.2032 158.264 20.5461 159.247 20.5461C160.722 20.5461 161.296 21.696 161.296 23.3387V30.3202H165.23V22.928C165.312 19.1498 163.345 17.4249 160.804 17.4249Z'
        fill='white'
      />
      <path
        d='M139.085 14.3038L135.233 15.3716V17.7535H133.594V20.6282H135.315V25.8027C135.315 27.5276 135.725 28.7596 136.381 29.4988C136.954 30.1559 138.102 30.5666 139.331 30.5666C140.478 30.5666 141.38 30.4023 141.872 30.238V27.2812C141.462 27.3633 141.216 27.3633 140.724 27.3633C139.495 27.3633 139.167 26.7062 139.167 25.1457V20.6282H141.954V17.7535H139.167V14.3038H139.085Z'
        fill='white'
      />
      <path
        d='M123.021 12.9896H119.005L117.612 20.0533C117.202 22.1066 116.874 24.16 116.628 26.0491C116.382 24.0779 116.055 22.2709 115.727 20.2175L114.333 12.9075H110.154L108.678 19.9711C108.268 22.1066 107.777 24.16 107.531 26.0491H107.367C107.121 24.3243 106.711 22.0245 106.383 19.9711L105.072 12.9075H100.81L104.99 30.238H109.334L110.973 22.7637C111.383 21.0389 111.629 19.3962 111.957 17.4249H112.039C112.203 19.3962 112.53 21.0389 112.858 22.7637L114.333 30.238H118.595L122.447 15.3716L123.021 12.9896Z'
        fill='white'
      />
      <path
        d='M86.2214 17.4249C86.1395 17.4249 85.9756 17.4249 85.8936 17.4249C82.2874 17.5892 80.4023 20.0533 79.9106 22.7637C79.8286 23.1744 79.8286 23.6672 79.8286 24.0779C79.8286 24.5707 79.8286 25.0635 79.9106 25.5563C80.4023 28.4311 82.5333 30.3202 85.8936 30.4844H86.6312C88.3524 30.4844 89.9096 30.238 91.221 29.6631L90.7292 27.0348C89.6637 27.3633 88.5983 27.5276 87.205 27.5276C86.7132 27.5276 86.3034 27.5276 85.8936 27.3633C84.6642 27.0348 83.6807 26.3777 83.5987 25.1457H91.7947C91.7947 24.9814 91.8766 24.735 91.8766 24.3243C91.8766 24.0779 91.8766 23.8315 91.8766 23.5851C91.8766 23.3387 91.8766 23.0923 91.8766 22.8459C91.6308 20.1354 89.9916 17.4249 86.2214 17.4249ZM83.5987 22.5995C83.6807 21.6138 84.3364 20.1354 85.8936 20.0533H85.9756C87.7787 20.0533 88.1885 21.696 88.1885 22.5995H83.5987Z'
        fill='white'
      />
      <path
        d='M97.3679 14.3038L93.5158 15.3716V17.7535H91.8766V20.6282H93.5158V25.8027C93.5158 27.5276 93.9256 28.7596 94.5813 29.4988C95.237 30.1559 96.3024 30.5666 97.5318 30.5666C98.5973 30.5666 99.5808 30.4023 100.073 30.238V27.2812C99.6628 27.3633 99.4989 27.3633 98.9251 27.3633C97.6958 27.3633 97.3679 26.7062 97.3679 25.1457V20.6282H100.155V17.7535H97.3679V14.3038Z'
        fill='white'
      />
      <path
        d='M62.6172 13.3182H57.7816L57.0439 14.5502L51.3888 23.5851V26.1313H58.8471V30.1559H62.5352V26.1313H64.5842L66.3873 23.1744H62.6172V13.3182ZM58.929 19.3962V23.0923H55.1589L57.1259 19.889L57.4537 19.3962C58.0275 18.3284 58.4373 17.3428 59.011 16.275H59.0929C59.011 17.3428 58.929 18.3284 58.929 19.3962Z'
        fill='white'
      />
      <path
        d='M44.7501 26.7062L46.3073 25.4742C48.7661 23.3387 50.815 21.0389 50.815 18.2463C50.815 15.2073 48.6841 12.9896 44.914 12.9896C42.6191 12.9896 40.6521 13.7289 39.4227 14.7145L40.5701 17.5071C41.4717 16.85 42.7011 16.1108 44.1763 16.1108C46.0614 16.1108 46.963 17.1785 46.963 18.5748C46.963 20.5461 45.1599 22.4352 41.4717 25.6385L39.2588 27.6097V29.9916H51.2248V26.7884L44.7501 26.7062Z'
        fill='white'
      />
      <path
        d='M167.935 28.8417C168.099 28.7596 168.181 28.6775 168.181 28.5132C168.181 28.3489 168.099 28.2668 168.017 28.1847C167.935 28.1025 167.771 28.1025 167.525 28.1025C167.279 28.1025 167.115 28.1025 167.033 28.1847V29.581H167.361V29.006H167.525C167.689 29.006 167.771 29.0881 167.771 29.2524C167.853 29.4167 167.853 29.4988 167.853 29.581H168.181C168.181 29.4988 168.099 29.4167 168.099 29.2524C168.181 29.006 168.099 28.9239 167.935 28.8417ZM167.607 28.7596H167.443V28.3489C167.443 28.3489 167.525 28.3489 167.607 28.3489C167.771 28.3489 167.935 28.4311 167.935 28.5953C167.853 28.6775 167.771 28.7596 167.607 28.7596Z'
        fill='white'
      />
      <path
        d='M167.607 27.4454C166.787 27.4454 166.214 28.0204 166.214 28.8417C166.214 29.581 166.869 30.238 167.607 30.238C168.427 30.238 169 29.6631 169 28.8417C169 28.0204 168.427 27.4454 167.607 27.4454ZM167.607 29.9095C166.951 29.9095 166.542 29.4167 166.542 28.8417C166.542 28.2668 166.951 27.774 167.607 27.774C168.181 27.774 168.672 28.2668 168.672 28.8417C168.672 29.4167 168.263 29.9095 167.607 29.9095Z'
        fill='white'
      />
    </svg>
  )
}

import React from "react";
import { PetMedAlertCard } from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { AppUtils } from "../../../utils/app-utils";
import { connect } from "react-redux";
class PetMedAlert extends React.Component {
  constructor() {
    super();
    this.state = {
      status: "N/A",
    };
    this.cRef = React.createRef();
  }
  downloadMedAlertHistory() {
    axios
      .processGet(`${ApiConstants.URL_GET_24PETMEDALERTFORM}`, null)
      .then((response) => {
        AppUtils.openLinkInNewTab(response.data);
      });
  }
  fetchMedStatus() {
    axios
      .processGet(
        `${ApiConstants.URL_GET_24PETMEDALERT_STATUS}${this.props.selectedPetId}`,
        this.cRef
      )
      .then((response) => {
        this.setState({
          status: response.data,
        });
      });
  }
  componentDidMount() {
    this.fetchMedStatus();
  }
  render() {
    return this.props.isActiveMedAlert ? (
      <div ref={this.cRef}>
        <PetMedAlertCard
          className={(FeatureFlagUtils.hideCardImage())?"hide-card-image":""}
          downloadHistoryButtonProps={{
            disabled: !this.props.isActiveMedAlert,
            id: "downloadHistoryButton",
            onClick: () => {
              this.downloadMedAlertHistory();
            },
          }}
          status={this.state.status}
          emailHistoryLinkProps={{
            disabled: !this.props.isActiveMedAlert,
            className: "tooltiptext",
            style:{ "textDecoration": "none"},
            id: "myTooltip",
            onClick: () => {},
          }}
        ></PetMedAlertCard>
      </div>
    ) : (
      <div></div>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;

  return {
    selectedPetId: customerProfileStore.selectedPetId,
    isActiveMedAlert: customerProfileStore.isActiveMedAlert,
  };
};

export default connect(mapStateToProps, null)(PetMedAlert);

import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Text,
  Form,
  Input,
  Button,
  Link,
  FormAction,
} from "../..";

export class EnterEmailCard extends React.PureComponent {

  static propTypes = {
    emailInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    verbiage1: PropTypes.string,
    verbiage2: PropTypes.string,

    createAccountButtonProps: PropTypes.shape({
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      id: PropTypes.string,
    }),

    onFormSubmit: PropTypes.func,

    errorMessage: PropTypes.string,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
  };

  render() {
    const {
      type,
      emailInputProps,
      verbiage1,
      verbiage2,
      createAccountButtonProps,
      onFormSubmit,
      errorMessage,
      id,
      className,
      style,
    } = this.props;

    return (
      <div
        id={id}
        className={cn("ui-enter-email-card__paper", className)}
        style={style}
      >
        <Paper type={Paper.Types.framed}>
          <PaperHeadline>Create an account</PaperHeadline>
          <PaperContent className="ui-enter-email-card__content">
            {errorMessage && (
              <Text
                className="ui-enter-email-card__error-message"
                type={Text.Types.bodyBold}
              >
                {errorMessage}
              </Text>
            )}
            <Text className='ui-enter-email-card__info'>
              Enter your email address to create a Mypethealth.com account.
            </Text>

            <Form
              className="ui-enter-email-card__form"
              onSubmit={onFormSubmit}
            >
              <Input
                label='Email Address'
                placeholder='Email Address'
                type='email'
                autoComplete='email'
                {...emailInputProps}
              />

              {verbiage1 && <Text>{verbiage1}</Text>}
              {verbiage2 && <Text>{verbiage2}</Text>}
              <FormAction>
                <Button
                  color={Button.Colors.tertiary}
                  type="submit"
                  {...createAccountButtonProps}
                >
                  Continue
                </Button>
              </FormAction>
            </Form>
          </PaperContent>
        </Paper>
      </div>
    );
  }
}

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  NavigationButton,
  Text,
  Form,
  Avatar,
  Input,
  Icon,
  Link
} from '../..'

export class TransferPetCard extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    headLine: PropTypes.string,
    type: PropTypes.string,
    bodyText: PropTypes.string,
    showTransferPet: PropTypes.bool,
    wantToTransferPet: PropTypes.bool,
    confirmTransferPet: PropTypes.bool,
    isDuplicate: PropTypes.bool,
    isAvatar: PropTypes.bool,
    petAvatar: PropTypes.string,
    ownerEmail: PropTypes.string,
    ownerName: PropTypes.string,
    petName: PropTypes.string,
    chipNumber: PropTypes.string,
    transferButtonProps: PropTypes.shape({
        id: PropTypes.string,
        onClick: PropTypes.func
    }),
    notTransferButtonProps: PropTypes.shape({
        id: PropTypes.string,
        onClick: PropTypes.func
    }),
    backButtonProps: PropTypes.shape({
        id: PropTypes.string,
        onClick: PropTypes.func
    }),
    cancelTransferButtonProps: PropTypes.shape({
        id: PropTypes.string,
        onClick: PropTypes.func
    }),
    confirmTransferButtonProps: PropTypes.shape({
        id: PropTypes.string,
        onClick: PropTypes.func
    }),
    newOwnerNextButtonProps: PropTypes.shape({
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        id: PropTypes.string
    }),
    duplicateBackButtonProps: PropTypes.shape({
        onClick: PropTypes.func,
        id: PropTypes.string
    }),
    newOwnerBackButtonProps: PropTypes.shape({
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        id: PropTypes.string
    }),
    firstNameInputProps: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        name: PropTypes.string,
        error: PropTypes.bool,
        hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        id: PropTypes.string
    }),
    emailInputFieldProps: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        name: PropTypes.string,
        error: PropTypes.bool,
        hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        id: PropTypes.string
    }),
    confirmEmailInputFieldProps: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        name: PropTypes.string,
        error: PropTypes.bool,
        hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        id: PropTypes.string
    }),
  }
  

  static defaultProps = {
    type: "Transfer Pet",
    showTransferPet: true,
    wantToTransferPet: false,
    confirmTransferPet: false,
    isDuplicate: false,
    isAvatar: false,
    ownerName: "Julie R.",
    ownerEmail: "julia@oceans11.com",
    petName: "Brandy P.",
    chipNumber: "1234567890",
    headLine: "Do you have the new owner’s information?",
    bodyText: "By entering the new owner’s information, you’re helping to ensure that the pet can be returned home if lost, then found and scanned. If you don’t have the information, this pet will be removed from your account and the new owner will need to re-register the pet on their own."
  }

  render() {
    const {
      id,
      className,
      errorMessage,
      successMessage,
      transferButtonProps,
      notTransferButtonProps,
      backButtonProps,
      type,
      headLine,
      bodyText,
      petAvatar,
      showTransferPet,
      wantToTransferPet,
      confirmTransferPet,
      isDuplicate,
      isAvatar,
      ownerEmail,
      ownerName,
      petName,
      chipNumber,
      emailInputFieldProps,
      firstNameInputProps,
      confirmEmailInputFieldProps,
      newOwnerBackButtonProps,
      newOwnerNextButtonProps,
      confirmTransferButtonProps,
      cancelTransferButtonProps,
      duplicateBackButtonProps
    } = this.props
    return (
      <div 
        id={id}
        className={cn(className, 'ui-transfer-pet-card')}
      >
        <NavigationButton
            color={Button.Colors.tertiary}
            variant={Button.Variants.outlined}
            className={"ui-transfer-pet-card__backButton"}
            label={"Back to profile"}
            {...cancelTransferButtonProps}
        >
        </NavigationButton>
        <Paper
            type={Paper.Types.framed}
        >
            <PaperHeadline>{type}</PaperHeadline>
            <PaperContent>
            {successMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-transfer-pet-card__success-message'
              >
                {successMessage}
              </Text>
            )}

            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-transfer-pet-card__error-message'
              >
                {errorMessage}
              </Text>
            )}
                <Text
                    type={Text.Types.headlineBold}
                    className={"ui-transfer-pet-card__headline"}
                >
                    {headLine}
                </Text>
                <Text
                    type={Text.Types.body}
                    className={"ui-transfer-pet-card__text"}
                >
                    {bodyText}
                </Text>
                { showTransferPet && (<div className='ui-transfer-pet-card__form-action'>
                    <Button
                        color={Button.Colors.tertiary}
                        {...transferButtonProps}
                    >
                        Yes I do
                    </Button>
                    <Button 
                        color={Button.Colors.tertiary} 
                        {...notTransferButtonProps}
                    >
                        No I don't
                    </Button>
                </div>)}
                { wantToTransferPet && (<div className=''>
                    <Form
                        className=''
                    >
                        <div className='ui-transfer-pet-card__input'>
                            <Input
                                label='First name (required):'
                                {...firstNameInputProps}
                            />
                        </div>
                        <div className='ui-transfer-pet-card__horizontal-row'>
                            <Input
                                label='Email (required):'
                                placeholder='example@example.com'
                                {...emailInputFieldProps}
                            />

                            <Input
                                label='Confirm email (required)'
                                placeholder='example@example.com'
                                {...confirmEmailInputFieldProps}
                            />
                        </div>

                        <div className='ui-transfer-pet-card__form-action'>
                            <Button
                                color={Button.Colors.tertiary}
                                type='submit'
                                {...newOwnerNextButtonProps}
                            >
                                Next
                            </Button>
                            <Button
                                variant = {Button.Variants.outlined}
                                color = {Button.Colors.tertiary}
                                {...newOwnerBackButtonProps}
                            >
                                Back
                            </Button>
                        </div>
                    </Form>
                </div>)}
                { confirmTransferPet && (<div>
                    <div>
                        <Text
                            type={Text.Types.headlineBold}
                            className={"ui-transfer-pet-card__heading"}
                        >
                            {ownerName}
                        </Text>
                        <Text
                            type={Text.Types.body}
                            className={"ui-transfer-pet-card__body"}
                        >
                            {ownerEmail}
                        </Text>
                    </div>
                    <div className='ui-transfer-pet-card__petDetails'>
                        <div>
                            {isAvatar && (
                                <Avatar img={petAvatar} />
                            )}
                        </div>
                        <div className={isAvatar ? "ui-transfer-pet-card__avatar" : "ui-transfer-pet-card__no_avatar"}
                        >
                            <Text
                                type={Text.Types.bodyBold}
                                className={"ui-transfer-pet-card__petTransfer"}
                            >
                                Pet being transferred
                            </Text>
                            <Text
                                type={Text.Types.title}
                                className={"ui-transfer-pet-card__details"}
                            >
                                {petName}
                            </Text>
                            <Text
                                type={Text.Types.body}
                                className={"ui-transfer-pet-card__details"}
                            >
                                {chipNumber}
                            </Text>
                        </div>
                    </div>
                    <div className='ui-transfer-pet-card__form-action'>
                        <Button
                            color={Button.Colors.tertiary}
                            {...confirmTransferButtonProps}
                        >
                            Confirm and transfer
                        </Button>
                        <Button
                            variant = {Button.Variants.outlined}
                            color={Button.Colors.tertiary} 
                            {...backButtonProps}
                        >
                            Back
                        </Button>
                    </div>
                </div>)}
                { isDuplicate && (<div className='ui-transfer-pet-card__form-action'>
                    <Button
                        variant = {Button.Variants.outlined}
                        color = {Button.Colors.tertiary}
                        {...duplicateBackButtonProps}
                    >
                        Back
                    </Button>
                </div>)}
            </PaperContent>
      </Paper>
      </div>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class PageContent extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { children, id, className, style, refProp } = this.props

    return (
      <div
        id={id}
        className={cn('ui-page-content', className)}
        style={style}
        ref={refProp}
      >
        {children}
      </div>
    )
  }
}

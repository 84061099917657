import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text, Icon } from '../../'

export class PageBanner extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    showIcon: PropTypes.bool,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    showIcon: false,
  }

  render() {
    const { title, subtitle, showIcon, id, className, style, refProp } = this.props

    return (
      <div
        id={id}
        className={cn('ui-page-banner', className)}
        style={style}
        ref={refProp}
      >
        <Text type={Text.Types.bodyBold}>{title}</Text>
        <span className='ui-page-banner-subtitle'>
          {showIcon && <Icon type='error_outline'></Icon>}
          <Text>{subtitle}</Text>
        </span>
      </div>
    )
  }
}

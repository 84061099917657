import React, { Component } from 'react'
import { RmSuccessCard } from "../../../UI-Component-Library";
class PetSuccess extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    render() {
        return (
          <RmSuccessCard
            id="petSuccessId"
            type = {this.props.type}
            userFirstName={this.props.formValues.firstName}
            isAddingNewPet={true}
            petName={this.props.formValues.petName}
            viewDashboardButtonProps={{
              onClick: (event) => {
                this.props.redirectToDashboard();
              },
            }}
          ></RmSuccessCard>
        );
    }
}

export default PetSuccess;
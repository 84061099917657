import React from "react";
import { connect } from "react-redux";
import axios from "../../../../custom-axios";
import { ApiConstants } from "../../../../utils/api-constants";
import { AppUtils } from "../../../../utils/app-utils";
import { 
  PetplaceCancelInsurancePolicyModal,
  PetplaceInsurancePolicy,
  BreadCrumb,
  Link,
  PetplaceLogoText
 } from "../../../../UI-Component-Library";
import { AppConstants } from "../../../../utils/app-constants";
import { FeatureFlagUtils } from "../../../../utils/feature-flag-utils";
import moment from "moment";
import { showButtonLoader, hideButtonLoader} from "../../../../utils/buttonLoader-util";
import { PetWatchLogo } from "../../../../utils/svg-icons";

class PolicyDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      // selectedPetId: undefined,  
      policy: undefined,
      selectedPolicyNumber: undefined,
      cancelPolicyError: '',
      isCancelPolicyModalOpen: false,
      isPolicyCancelled: false,
      requestedCancelDate: '',
      policyOptions: [],
    };
    this.cRef = React.createRef();
  }
  mapPolictyData = () => {
    const policyOptions = [];
    this.props.policynumbers.forEach((policynumber) => {
      policyOptions.push({
        value: policynumber,
        label: this.props.selectedAnimal?.animalName + "(" + policynumber + ")",
      });
    });
    this.setState({
      policyOptions,
    });
  };
  getPolicy = () => {
    if (!this.state.selectedPolicyNumber) {
      return;
    }
    axios
      .processGet(
        `${ApiConstants.URL_GET_POLICY}${this.state.selectedPolicyNumber}`,
        this.cRef
      )
      .then((response) => {
        this.setState({
          policy: response,
        });
      })
      .catch((err) => {});
  };
  getExtractedPolicies() {
    const extracted = this.props.selectedAnimal?.insurances?.[0]?.policynumber;
    return extracted ? extracted : [];
  }
  debounceHandler = function (fn, d) {
    let timer;
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn();
      }, d);
    };
  };
  debounceGetPolicy = this.debounceHandler(this.getPolicy, 500);
  componentDidMount() {
    if (
      !this.state.selectedPolicyNumber &&
      this.props.policynumbers?.length > 0
    ) {
      this.setState(
        {
          selectedPolicyNumber: this.props.policynumbers[0],
        },
        () => {
          this.mapPolictyData();
          this.debounceGetPolicy();
        }
      );
    }
  }
  componentDidUpdate() {
    if (
      !this.state.selectedPolicyNumber &&
      this.props.policynumbers?.length > 0
    ) {
      this.setState(
        {
          selectedPolicyNumber: this.props.policynumbers[0],
          policy: undefined,
        },
        () => {
          this.mapPolictyData();
          this.debounceGetPolicy();
        }
      );
    }
  }
  handleOnPolicyChange = (e) => {
    this.setState(
      {
        selectedPolicyNumber: e.target.value,
      },
      this.debounceGetPolicy
    );
  };
  downloadDocument = (url, isCurrent = true) => {
    if (!AppUtils.isUrl(url)) {
      return;
    }
    axios
      .processPost(
        ApiConstants.URL_GET_INSURANCE_POLICY_DOCUMENT,
        this.cRef,
        '"' + url + '"',
        {
          responseType: "blob",
          headers: {
            "Content-Type": "text/json",
            responseType: "blob",
          },
        },
        true
      )
      .then((response) => {
        AppUtils.saveToFile(response);
      });
  };

  cancelInsurancePolicy = () => {
    const cancelPolicyButton = document.getElementById("cancel_policy_button");
    showButtonLoader(cancelPolicyButton);
    axios
      .processGet(
        `${ApiConstants.URL_CANCEL_POLICY_EMAIL}/${this.state.selectedPolicyNumber}`,
        null
      )
      .then((response) => {
        this.setState({
          isPolicyCancelled: true,
          requestedCancelDate: moment(Date.now()).format("MM/DD/YYYY")
        })
      })
      .catch((error) => {
        let errors = error?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            cancelPolicyError: errors[0],
          });
        }
      })
      .finally(() => {
        hideButtonLoader(cancelPolicyButton, 'Cancel My Policy');
      });
  }

  getCancelSuccessMessage = (date) => {
    return `Your request will be processed in the next 2 to 3 business days. The cancellation will be reflective of today's date (${date}).`
  }

  render() {
    return (
      <React.Fragment>
        <BreadCrumb
          isPetplace={true}
          breadCrumbs={[
            {
              name: "My Account",
              href: `${AppUtils.getPetPlaceBaseUrl()}/account/`,
              active: false,
              onClick: (e) => {
                window.location.replace(`${AppUtils.getPetPlaceBaseUrl()}/account/`)
                e.preventDefault();
              }
            },
          ]}
        />
        <PetplaceLogoText
          text="Insurance Policy"
          logo={<PetWatchLogo />}
        />
        <div ref={this.cRef}>
          {this.state.policy && (
            <PetplaceInsurancePolicy
              downloadCurrentPolicyButton={{
                id: "downloadCurrentPolicyButton",
                disabled: !AppUtils.isUrl(
                  this.state.policy?.policyDocumentCurrent
                ),
                onClick: () => {
                  this.downloadDocument(
                    this.state.policy.policyDocumentCurrent
                  );
                },
              }}
              downloadUpcomingRenewalPolicyButton={{
                id: "downloadUpcomingRenewalPolicyButton",
                disabled: !AppUtils.isUrl(
                  this.state.policy?.policyDocumentFuture
                ),
                onClick: () => {
                  this.downloadDocument(
                    this.state.policy?.policyDocumentFuture,
                    false
                  );
                },
              }}
              showCancelPolicyLink={FeatureFlagUtils.shouldShowCancelPolicyLink()}
              isPolicyCancelled={false}
              cancelPolicyLinkProps={{
                id: "cancelPolicyId",
                href:"#",
                className: FeatureFlagUtils.showLinkOverrideCss()
                ? "dd-text-link-override"
                : "",
                color: (FeatureFlagUtils.shouldShowDefaultButton())
                  ? Link.Colors.default
                  : Link.Colors.tertiary,
                onClick:(e) => {
                  e.preventDefault();
                  this.setState({
                    isCancelPolicyModalOpen: true,
                  })
                }
              }}
              insuranceSelectProps={{
                disabled: FeatureFlagUtils.shouldDisableInsuranceSelection(),
                options: this.state.policyOptions,
                onChange: this.handleOnPolicyChange,
                value: this.state.selectedPolicyNumber,
              }}
              policyData={[
                {
                  label: "Policy",
                  data: this.state.policy?.policyNumber,
                },
                {
                  label: "Coverage type",
                  data: this.state.policy?.coverageType,
                },
                {
                  label: "Status",
                  data: this.state.policy?.status,
                },
                {
                  label: "Policy term",
                  data: AppUtils.getPDFormattedDate(
                    this.state.policy?.policyTerm
                  ),
                },
                {
                  label: "Coverage amount",
                  data: AppUtils.getCoverageAmount(this.state.policy),        
                },
                {
                  label: "Deductible amount",
                  data:
                    AppConstants.CURRENCY_FORMAT +
                    this.state.policy?.deductibleAmount +
                    "",
                },
                {
                  label: "Co-insurance",
                  data: this.state.policy?.coInsurance * 100 + "%",
                },
              ]}
            />
          )}
        </div>
        <PetplaceCancelInsurancePolicyModal
          id="petplace_cancel_policy_modal"
          errorMessage={this.state.cancelPolicyError}
          open={this.state.isCancelPolicyModalOpen}
          onClose={() => {
            this.setState({
              isCancelPolicyModalOpen: false,
            })
          }}
          title={FeatureFlagUtils.getCancelInsurancePolicyMessage()}
          successText={this.state.isPolicyCancelled
            ? this.getCancelSuccessMessage(this.state.requestedCancelDate)
            : ''
          }
          closeButtonText={ this.state.isPolicyCancelled ? 'Close' : 'Keep My Coverage'}
          closeButtonProps={{
            id:"close_cancel_button",
            onClick: () => {
              this.setState({
                isCancelPolicyModalOpen: false,
              })
            }
          }}
          cancelInsuranceButtonProps={{
            id:"cancel_policy_button",
            onClick: () => {
              this.cancelInsurancePolicy();
            }
          }}
        >
        </PetplaceCancelInsurancePolicyModal>
      </React.Fragment>
    );
  }
}

export default PolicyDetails;

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { HeaderDivider } from '../HeaderDivider'

export class RmPageFooter extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    aboutUsButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    contactUsButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    footerLinks: [],
    logos: [],
    legalLines: []
  }

  render() {
    const {
      id,
      className,
      style,
      refProp,
      aboutUsButtonProps,
      contactUsButtonProps
    } = this.props

    return (
      <div
        id={id}
        className={cn('rm-ui-page-footer', className)}
        style={style}
        ref={refProp}
      >
        <div className='rm-ui-page-footer__container'>
          <div {...aboutUsButtonProps}>
            <HeaderDivider>About Us</HeaderDivider>
          </div>
          <div {...contactUsButtonProps}>
            <HeaderDivider>Contact Us</HeaderDivider>
          </div>
        </div>
      </div>
    )
  }
}

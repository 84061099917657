import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperContent, PaperHeadline, Text, Button, Icon } from '../../'

export class ForgotPasswordSuccessCard extends React.PureComponent {
  static propTypes = {
    headline: PropTypes.string,
    showEmailIcon: PropTypes.bool,
    messageBody: PropTypes.string,
    closeButtonText: PropTypes.string,
    closeButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    headline : "Forgot your password",
    showEmailIcon: true,
    messageBody: "If this email exists, a confirmation email has been sent to this address!",
    closeButtonText: "Close and go back"
  }

  render() {
    const { headline, showEmailIcon, messageBody, closeButtonText, closeButtonProps, id, className, style, refProp } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-forgot-password-success-card', className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>{headline}</PaperHeadline>
        <PaperContent>
          <div className='ui-forgot-password-success-card__text'>
            {showEmailIcon && <Icon type='email' />}
            <Text type={Text.Types.body}>
              {messageBody}
            </Text>
          </div>

          <div className='ui-forgot-password-success-card__action'>
            <Button color={Button.Colors.tertiary} {...closeButtonProps}>
              {closeButtonText}
            </Button>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PageContent } from "../../../../UI-Component-Library";
import PolicyDetails from "./policy-details";
const queryString = require("query-string");

class Policy extends Component {

  constructor() {
    super();
    this.state = {
      policyNumbers: [],
      selectedPetId: undefined,
      selectedAnimal: undefined,
    };
    this.cRef = React.createRef();
  }



  componentDidMount() {
    const parsed = queryString.parse(this.props.history.location.search);

    if( parsed.animalID ) {
      this.setState({
        selectedPetId: parsed.animalID
      })
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.customerAnimals !== prevProps.customerAnimals) {
      console.log(this.props.customerAnimals);
      for (let i = 0; i < this.props.customerAnimals.length; i++) {
        if (this.props.customerAnimals[i].animalId === this.state.selectedPetId) {
          this.setState({
            selectedAnimal: this.props.customerAnimals[i]
          })
          break;
        }
      }
    }  
  }

  getPolicyCard() {
    if (this.state.selectedAnimal) {
      if (
        this.state.selectedAnimal?.insurances?.[0]?.policynumber?.length > 0
      ) {
        return (
          <PolicyDetails
            selectedAnimal={this.state.selectedAnimal}
            policynumbers={
              this.state.selectedAnimal?.insurances?.[0]?.policynumber
            }
          ></PolicyDetails>
        );
      } else {
        return (<div></div>);
      }
    }
  }

  render() {
    return (
      <div>
        <PageContent>
          {this.getPolicyCard()}
        </PageContent>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    customerAnimals: customerProfileStore.customerAnimals,
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(Policy));

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Form,
  Input,
  FormAction,
  Button,
  Text,
  SocialButton
} from '../../'

import { PasswordInput } from '../PasswordInput'

export class AccountPreferencesCard extends React.PureComponent {
  static propTypes = {
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    onFormSubmit: PropTypes.func,

    /**
     * A flag weather user logged in as social (true) or with email (false)
     */
    userLoggedInWithSocial: PropTypes.bool,

    newEmailInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    confirmEmailInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    currentPasswordInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    newPasswordInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    confirmPasswordInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    saveChangesButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    logInGoogleButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    logInFacebookButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    userLoggedInWithSocial: true
  }

  render() {
    const {
      successMessage,
      errorMessage,
      onFormSubmit,
      userLoggedInWithSocial,
      newEmailInputFieldProps,
      confirmEmailInputFieldProps,
      currentPasswordInputFieldProps,
      newPasswordInputFieldProps,
      confirmPasswordInputFieldProps,
      saveChangesButtonProps,
      logInGoogleButtonProps,
      logInFacebookButtonProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-account-preferences-card', className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Account preferences</PaperHeadline>
        <PaperContent>
          <Text type={Text.Types.headline}>
            {userLoggedInWithSocial
              ? 'Change to your own email and password'
              : 'Change Password'}
          </Text>
          <Form onSubmit={onFormSubmit}>
            {successMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-account-preferences-card__success-message'
              >
                {successMessage}
              </Text>
            )}

            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-account-preferences-card__error-message'
              >
                {errorMessage}
              </Text>
            )}

            {userLoggedInWithSocial && (
              <>
                <Input
                  type='email'
                  autoComplete='email'
                  label='Email Address'
                  {...newEmailInputFieldProps}
                />

                <Input
                  type='email'
                  label='Re-enter Email Address'
                  {...confirmEmailInputFieldProps}
                />
              </>
            )}

            {!userLoggedInWithSocial && (
              <PasswordInput
                label='Current Password'
                {...currentPasswordInputFieldProps}
              />
            )}

            <PasswordInput
              autoComplete='new-password'
              label='New Password'
              {...newPasswordInputFieldProps}
            />

            <PasswordInput
              label='Confirm New Password'
              {...confirmPasswordInputFieldProps}
            />

            <FormAction>
              <Button
                color={Button.Colors.tertiary}
                type='submit'
                {...saveChangesButtonProps}
              >
                Save changes
              </Button>
            </FormAction>
          </Form>

          <Text type={Text.Types.headline}>Change social login</Text>
          <div className='ui-account-preferences-card__social'>
            <SocialButton {...logInGoogleButtonProps}>
              Log in with Google
            </SocialButton>
            <SocialButton
              variant={SocialButton.Variants.facebook}
              {...logInFacebookButtonProps}
            >
              Log in with Facebook
            </SocialButton>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Text,
  Form,
  Input,
  Button,
  Link,
  Divider,
  SocialButton,
  Checkbox,
  IconButton
} from '../../'
import { PasswordInput } from '../PasswordInput'

export class LoginCard extends React.PureComponent {
  static propTypes = {
    description: PropTypes.string,

    emailInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    passwordInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    rememberMeCheckboxProps: PropTypes.shape({
      hidden: PropTypes.bool,
      id: PropTypes.string,
      checked: PropTypes.bool,
      onChange: PropTypes.func
    }),

    backArrowProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string
    }),

    forgotEmailLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),

    forgotPasswordLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),

    loginButtonProps: PropTypes.shape({
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      id: PropTypes.string
    }),
    continueButtonProps: PropTypes.shape({
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      id: PropTypes.string
    }),
    hideLoginButton: PropTypes.bool,
    socialButtonGoogleProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string
    }),

    socialButtonFacebookProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string
    }),

    onFormSubmitTwo: PropTypes.func,
    onFormSubmitOne: PropTypes.func,

    errorMessage: PropTypes.string,

    otherSignInText: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
  }

  static defaultProps = {
    description:
      'Access the portal to submit claims, check your claim statuses, update your profile information and more.',

    rememberMeCheckboxProps: {
      hidden: true
    },
    otherSignInText: "Or, sign in with your social account"
  }

  render() {
    const {
      description,
      emailInputProps,
      passwordInputProps,
      rememberMeCheckboxProps,
      forgotPasswordLinkProps,
      forgotEmailLinkProps,
      loginButtonProps,
      continueButtonProps,
      hideLoginButton,
      backArrowProps,
      onFormSubmitOne,
      onFormSubmitTwo,
      socialButtonGoogleProps,
      socialButtonFacebookProps,
      errorMessage,
      otherSignInText,
      id,
      className,
      style
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-login-card__paper', className)}
        style={style}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Login</PaperHeadline>
        <PaperContent className='ui-login-card__content'>
          <Text className="ui-login-card__description_with_arrow">
            {!hideLoginButton && <IconButton type='arrow_back' {...backArrowProps} />}
            {description}
          </Text>

          {errorMessage && (
            <Text
              className='ui-login-card__error-message'
              type={Text.Types.bodyBold}
            >
              {errorMessage}
            </Text>
          )}

          {hideLoginButton && <Form className='ui-login-card__form' onSubmit={onFormSubmitOne}>
            <Input
              placeholder='Email address'
              type='email'
              autoComplete='email'
              {...emailInputProps}
            />
            <div
              className={cn('ui-login-card__form__password-actions', {
                'ui-login-card__form__password-actions--align-left':
                  rememberMeCheckboxProps?.hidden
              })}
            >
              <Checkbox label='Remember me' {...rememberMeCheckboxProps} />
              <Link {...forgotEmailLinkProps}>Forgot email?</Link>
            </div>
            <Button
              type='submit'
              color={Button.Colors.tertiary}
              {...continueButtonProps}
            >
              Continue
            </Button>
          </Form>}

          {!hideLoginButton && <Form className='ui-login-card__form' onSubmit={onFormSubmitTwo}>
            <PasswordInput
               placeholder='Password'
              autoComplete='current-password'
              {...passwordInputProps}
            />
            <div
              className={cn('ui-login-card__form__password-actions', {
                'ui-login-card__form__password-actions--align-left':
                  rememberMeCheckboxProps?.hidden
              })}
            >
              <Checkbox label='Remember me' {...rememberMeCheckboxProps} />
               <Link {...forgotPasswordLinkProps}>Forgot password?</Link>
            </div>
            <Button
              type='submit'
              color={Button.Colors.tertiary}
              {...loginButtonProps}
            >
              Login
            </Button>
          </Form>}

          <Divider />

          <Text className='ui-login-card__text'>
            {otherSignInText}
          </Text>

          <div className='ui-login-card__social-login'>
            <SocialButton {...socialButtonGoogleProps}>
              Login with Google
            </SocialButton>
            <SocialButton
              {...socialButtonFacebookProps}
              variant={SocialButton.Variants.facebook}
            >
              Login with Facebook
            </SocialButton>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import {
  Paper,
  PaperHeadline,
  PaperContent,
  Option,
  Text,
  Select,
  Divider,
} from "../..";
import { AddedToCartCheckMark } from '../SvgIcons';

import { PUBLIC_URL } from "../../constants";


export class ByteTagsCard extends React.PureComponent {
  static ByteTagOptions = Object.freeze({
    slide: "slide",
    round: "round",
    none: "none",
  });

  static TagSizes = Object.freeze({
    small: "small/medium",
    large: "large",
  });

  static TagStyles = Object.freeze({
    black: "black",
    white: "white",
  });

  static TagTypes = Object.freeze({
    dog: 1,
    cat: 2,
  });

  static propTypes = {
    disableByteTagOptions: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(ByteTagsCard.ByteTagOptions))
    ),
    byteTagOption: PropTypes.oneOf(Object.values(ByteTagsCard.ByteTagOptions)),
    byteTagRoundOption: PropTypes.oneOf(Object.values(ByteTagsCard.ByteTagOptions)),
    byteTagSlideOption: PropTypes.oneOf(Object.values(ByteTagsCard.ByteTagOptions)),
    tagStyle: PropTypes.string,
    tagSize: PropTypes.string,
    tagType: PropTypes.number,
    onByteTagRoundOptionChange: PropTypes.func,
    onByteTagSlideOptionChange: PropTypes.func,
    slideTagsPrice: PropTypes.string,
    roundTagsPrice: PropTypes.string,
    showSizeSelect: PropTypes.bool,
    showColorSelect: PropTypes.bool,
    showByteTagRoundOption: PropTypes.bool,
    showByteTagSlideOption: PropTypes.bool,
    banner: PropTypes.element,
    sizeSelectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string,
    }),
    colorSelectProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string,
    }),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
  };

  static defaultProps = {
    byteTagOption: ByteTagsCard.ByteTagOptions.slide,
    showSizeSelect: true,
    showColorSelect: true,
    disableByteTagOptions: [],
  };

  render() {
    const {
      // disableByteTagOptions,
      byteTagOption,
      byteTagRoundOption,
      byteTagSlideOption,
      tagSize,
      tagStyle,
      tagType,
      onByteTagRoundOptionChange,
      onByteTagSlideOptionChange,
      slideTagsPrice,
      showSizeSelect,
      showColorSelect,
      roundTagsPrice,
      sizeSelectProps,
      colorSelectProps,
      showByteTagRoundOption,
      showByteTagSlideOption,
      banner,
      id,
      className,
      style,
      refProp,
    } = this.props;

    const copies = getCopies();

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn("ui-byte-tags-card", className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Shop pet tags</PaperHeadline>
        {banner}
        <PaperContent>
          {( showByteTagRoundOption && <div className={cn("ui-byte-tags-card__tag-customize")}>
            <div>
              <img
                className={cn("ui-byte-tags-card__tag-image")}
                // alt={`${tagShape}-${tagStyle}`}
                src={getMetalTagImage(tagSize, tagStyle, tagType, false)}
              />

            </div>
            <div>
              <Text type={Text.Types.headline} className={cn("ui-byte-tags-card__bytetag-heading")}>
                ByteTag Round
              </Text>
              <Text type={Text.Types.headlineBold} className={cn("ui-byte-tags-card__bytetag-price")}>{roundTagsPrice}</Text>
              <p>
                ByteTag is a scannable pet tag containing all of your pets important information conveniently in one profile.
              </p>
              <Text type={Text.Types.bodyBold}>{copies.title}</Text>

              <ul className={cn("ui-byte-tags-card__copies__pros")}>
                {copies.pros.map((text) => (
                  <li key={text}>{text}</li>
                ))}
              </ul>

              <div className={cn("ui-byte-tags-card__add_cta")}>
                {tagType === '1' && showColorSelect && (
                  <Select
                    id="select-color-input"
                    label="Color"
                    className="ui-byte-tags-card__size-select"
                    {...colorSelectProps}
                  />
                )}
                <Option
                  id="round-tag-option"
                  label={ByteTagsCard.ByteTagOptions.round === byteTagRoundOption ? (<div className="ui-byte-tags-card__add_to_cart"><AddedToCartCheckMark /> <span>Added</span></div>) : "Add to Cart" }
                  active={ByteTagsCard.ByteTagOptions.round === byteTagRoundOption}
                  onClick={() =>
                    onByteTagRoundOptionChange(ByteTagsCard.ByteTagOptions.round)
                  }
                  // disabled={!enabledOption(ByteTagsCard.ByteTagOptions.round)}
                />
              </div>
            </div>
          </div>)}
          <Divider />
          {(showByteTagSlideOption && <div className={cn("ui-byte-tags-card__tag-customize")}>
            <div>
              <img
                className={cn("ui-byte-tags-card__tag-image")}
                // alt={`${tagShape}-${tagStyle}`}
                src={getMetalTagImage(tagSize, tagStyle, tagType, true)}
              />

            </div>
            <div>
              <Text type={Text.Types.headline} className={cn("ui-byte-tags-card__bytetag-heading")}>
                ByteTag Slide
              </Text>
              <Text type={Text.Types.headlineBold} className={cn("ui-byte-tags-card__bytetag-price")}>{slideTagsPrice}</Text>
              <p>
                ByteTag is a scannable pet tag containing all of your pets important information conveniently in one profile.
              </p>
              <Text type={Text.Types.bodyBold}>{copies.title}</Text>

              <ul className={cn("ui-byte-tags-card__copies__pros")}>
                {copies.pros.map((text) => (
                  <li key={text}>{text}</li>
                ))}
              </ul>
              <>
                <Text type={Text.Types.bodyBold}>Sizing</Text>
                <p>Small: 7.7cm x 1.3cm | Large: 8.1cm x 2cm</p>
              </>

              <div className={cn("ui-byte-tags-card__add_cta")}>
                <Select
                  id="select-size-input"
                  label="Size"
                  className="ui-byte-tags-card__size-select"
                  {...sizeSelectProps}
                />
                <Option
                  id="slide-tag-option"
                  label={ByteTagsCard.ByteTagOptions.slide === byteTagSlideOption ? (<div className="ui-byte-tags-card__add_to_cart"><AddedToCartCheckMark /> <span>Added</span></div>) : "Add to Cart"}
                  active={ByteTagsCard.ByteTagOptions.slide === byteTagSlideOption}
                  onClick={() =>
                    onByteTagSlideOptionChange(ByteTagsCard.ByteTagOptions.slide)
                  }
                  // disabled={!enabledOption(ByteTagsCard.ByteTagOptions.slide)}
                />
              </div>
            </div>
          </div> )}
        </PaperContent>
      </Paper>
    );
  }
}

function getCopies() {
  return {
    title: "Tag Features",
    pros: [
      "Waterproof",
      "Scratch, bite, and fade resistant",
      "No batteries needed",
    ],
  }
}

function getMetalTagImage(
  tagSize = "large",
  tagStyle = "black",
  tagType = 1,
  isSlideTags
) {
  let result;
  tagSize = tagSize.toLowerCase();
  tagStyle = tagStyle.toLowerCase();
  if (tagStyle !== "black" && tagStyle !== "white") {
    tagStyle = "black";
  }
  tagType = parseInt(tagType);
  if (isSlideTags) {
    result = TagsImageMap["slide"][tagSize];
  } else {
    result = TagsImageMap["round"][tagStyle][tagType];
  }

  return PUBLIC_URL + result;
}

const TagsImageMap = {
  [ByteTagsCard.ByteTagOptions.slide]: {
    [ByteTagsCard.TagSizes.small]: "SlideTagSmall.jpg",
    [ByteTagsCard.TagSizes.large]: "SlideTagLarge.jpg",
  },
  [ByteTagsCard.ByteTagOptions.round]: {
    [ByteTagsCard.TagStyles.white]: {
      [ByteTagsCard.TagTypes.dog]: "RoundTagDogWhite.jpg",
      [ByteTagsCard.TagTypes.cat]: "RoundTagCatWhite.jpg",
    },
    [ByteTagsCard.TagStyles.black]: {
      [ByteTagsCard.TagTypes.dog]: "RoundTagDogBlack.jpg",
      [ByteTagsCard.TagTypes.cat]: "RoundTagCatBlack.jpg",
    },
  },
};

export class PageUtils {
  static formatPets(pets) {
    return pets.map((pet) => {
      return { id: pet.animalId, name: pet.animalName };
    });
  }

  static getAccountMenuList(showNotification) {
    const items = [
      { id: 'AccountProfile1', name: 'Profile' }
    ];
    if (showNotification) {
      items.push(
        { id: 'AccountNotifications2', name: 'Notifications' },
        { id: 'AccountLogout3', name: 'Logout' }
      );
      return items;
    }
    items.push({ id: 'AccountLogout3', name: 'Logout' });
    return items;
  }
  static getCustomerAnimals(customerProfile) {
    const customerAccounts = customerProfile?.customerAccounts;
    const minimalAnimalDetails = [];
    if (customerAccounts && customerAccounts.length > 0) {
      const animals = [];
      if (customerAccounts[0].customerAnimals) {
        for (let animal of customerAccounts[0].customerAnimals) {
          const detail = animal.isNewRegistered
          ? { ...animal, animalId: Math.random() + "xxx" }
          : animal
          animals.push(detail);
          minimalAnimalDetails.push({
            animalId: detail.animalId,
            animalName: detail.animalName
          })
        }
      }
      this.saveAnimalDetailsInLocalStorage(minimalAnimalDetails);
      return animals;
    }
    return [];
  }
  static getCustomerFirstName(customerProfile) {
    const customerAccounts = customerProfile?.customerAccounts;
    if (customerAccounts && customerAccounts.length > 0) {
      return customerAccounts[0].firstname;
    }
    return "";
  }
  static getCustomerFullName(customerProfile) {
    const customerAccounts = customerProfile?.customerAccounts;
    if (customerAccounts && customerAccounts.length > 0) {
      return customerAccounts[0].firstname + " " + customerAccounts[0].lastname;
    }
    return "";
  }
  static getSelectedAnimal(animals, selectedAnimalId) {
    return animals.find((animal) => animal.animalId === selectedAnimalId);
  }
  static isPetHasInsurance(pet) {
    return pet?.insurances?.[0]?.policynumber?.length > 0;
  }
  static saveAnimalDetailsInLocalStorage(animalDetails) {
    localStorage.setItem("animalInfo", JSON.stringify(animalDetails));
  }
  static getAnimalDetailsFromLocalStorage() {
    const data = localStorage.getItem("animalInfo");
    return data ? JSON.parse(data) : {};
  }
  static destroyAnimalDetailsInLocalStorage() {
    localStorage.removeItem("animalInfo");
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Text,
  Link
} from '../../'

import { PUBLIC_URL } from '../../constants'

export class TransferOfRegistrationModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    className: PropTypes.string,
    downloadOwnershipButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    backButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  render() {
    const {
      open,
      onClose,
      className,
      downloadOwnershipButtonProps,
      backButtonProps
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-transfer-pet-ownership'>
          <Paper
            type={Paper.Types.framed}
            className={className}
          >
            <PaperHeadline>Transfer of registration and service</PaperHeadline>
            <PaperContent
              className='ui-transfer-pet-ownership__content'
              backgroundImg={{
                src: PUBLIC_URL + 'fadeImg1.png',
                position: 'right'
              }}
            >
              <Text type={Text.Types.headlineBold}>
                Sometimes our furry friends find themselves with new families.
              </Text>
              <Text>
                If your pet has a 24Petwatch<sup>®</sup> microchip that is
                registered to his or her previous owner, please fill out the
                Ownership Transfer Form to ensure the correct person is notified
                if your pet goes missing and is subsequently found. If you need
                assistance please contact us at:{' '}
                <Link href='tel:18665972424'>1-866-597-2424</Link>.
              </Text>

              <Button
                color={Button.Colors.tertiary}
                {...downloadOwnershipButtonProps}
              >
                Download ownership transfer form (.pdf)
              </Button>

              <Button
                color={Button.Colors.tertiary}
                variant={Button.Variants.outlined}
                {...backButtonProps}
              >
                Back
              </Button>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

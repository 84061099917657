import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text } from '../../'

export class AccordionGroup extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.element,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { children, title, id, className, style, refProp } = this.props

    return (
      <div
        id={id}
        className={cn('ui-accordion-group', className)}
        style={style}
        ref={refProp}
      >
        <div className={cn('ui-accordion-group__title')}>
          <Text type={Text.Types.bodyBold}>{title}</Text>
        </div>

        <div className={cn('ui-accordion-group__content')}>{children}</div>
      </div>
    )
  }
}

import { MakePaymentCard } from "../../../UI-Component-Library";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "../../../custom-axios";
import { saveCartData } from "../../../redux/reducers/membership-purchase-reducer";
import { ApiConstants } from "../../../utils/api-constants";
import { AppUtils } from "../../../utils/app-utils";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
class MakePaymentWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedCard: "",
      errorMessage: "",
      isPayingWithDifferentCard: false,
      existingCards: [],
    };
    this.cRef = React.createRef();
  }
  proccedToCheckout() {
    const cartData = this.props.cartData;
    const orderItems = this.props.extractCartData(cartData, true);
    if (
      this.state.isPayingWithDifferentCard ||
      this.state.existingCards?.length === 0
    ) {
      DataLayerUtils.beginCheckoutEvent(orderItems, cartData?.total);
      this.props.history.push({
        pathname:"/pages/payment",
        state: orderItems
      });
    }

    const selectedCard = this.state.existingCards.find(
      (card) => card.active === true
    );
    const errorMessage = !selectedCard ? "Card is not selected" : "";
    this.setState({
      errorMessage,
    });
    if (!errorMessage && this.props.cartData) {
      axios
        .processPost(
          `${ApiConstants.URL_CHECKOUT_SHOPPING_CART}${this.props.cartData.cartId}`,
          this.cRef,
          {
            token: selectedCard.tokenId,
            cardType: selectedCard.cardType,
            maskedCardNumber: selectedCard.ccLastFour,
            countrycode: AppUtils.getCountryCode(),
          }
        )
        .then((response) => {
          this.props.history.push({
            pathname: "/pages/payment/success",
            state: orderItems
          });
        })
        .catch((err) => {
          let errors = err?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              errorMessage: errors[0],
            });
          }
        });
    }
  }
  handleCardSelection(
    tokenId,
    resetAll = false,
    isPayingWithDifferentCard = false
  ) {
    DataLayerUtils.selectPaymentMethodEvent(AppUtils.getUserRelationId());
    const existingCards = this.state.existingCards.map((card) => {
      return {
        ...card,
        active: AppUtils.isEqualString(card.tokenId, tokenId) && !resetAll,
      };
    });
    this.setState({
      existingCards,
      isPayingWithDifferentCard: isPayingWithDifferentCard,
    });
  }
  handleNewCardSelection() {
    this.handleCardSelection("", true, !this.state.isPayingWithDifferentCard);
  }
  fetchCreditCards() {
    axios
      .processGet(
        `${ApiConstants.URL_DISPLAY_CC_CARDS}${AppUtils.getBusRelAccount()}`,
        this.cRef
      )
      .then((response) => {
        const cards = response.data;
        const existingCards = [];
        cards.forEach((card) => {
          existingCards.push({
            active: false,
            onClick: () => {
              this.handleCardSelection(card.tokenId);
            },
            description: `${card.paymMode} •••• ${card.ccLastFour}`,
            tokenId: card.tokenId,
            cardType: card.paymMode,
            ccLastFour: card.ccLastFour,
          });
        });
        this.setState({
          existingCards,
        });
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
        }
      });
  }
  componentDidMount() {
    this.fetchCreditCards();
  }
  render() {
    return (
      <div ref={this.cRef}>
        <MakePaymentCard
          caption={
            this.state.existingCards?.length > 0
              ? "Do you want to make a payment with your saved card?"
              : "You have no cards saved, click below to add a payment method"
          }
          errorMessage={this.state.errorMessage}
          existingCardOptions={this.state.existingCards}
          payWithDifferentCardOption={{
            hidden: this.state.existingCards?.length > 0 ? false : true,
            active: this.state.isPayingWithDifferentCard,
            onClick: () => {
              this.handleNewCardSelection();
            },
          }}
          continueButtonProp={{
            label:
              this.state.existingCards?.length > 0
                ? "Continue"
                : "Add a credit card",
            disabled: this.props.disableCheckoutButton,
            id: "buttonId",
            onClick: () => {
              this.proccedToCheckout();
            },
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const membershipPurchaseStore = state.membershipPurchaseStore;
  const customerProfileStore = state.customerProfileStore;

  return {
    cartData: membershipPurchaseStore.cartData,
    promoCode: membershipPurchaseStore.promoCode,
    customerAnimals: customerProfileStore.customerAnimals,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCartData: (data) => dispatch(saveCartData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MakePaymentWrapper));

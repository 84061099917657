import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  PaperContent,
  Paper,
  PaperHeadline,
  Accordion,
  AccordionGroup,
  Text,
  Link,
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableDataCell
} from '../../'

export class FAQMerck extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    handleClaim: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      open: ''
    }
  }

  handleOpen = (identifier, text) => {
    return () => {
      if (identifier !== this.state.open) {
        DataLayerUtils.faqEvent(text);
      }
      this.setState({ open: identifier === this.state.open ? '' : identifier })
    }
  }

  render() {
    const { open } = this.state

    const { id, className, style, refProp, handleClaim } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn(className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Frequently Asked Questions</PaperHeadline>

        <PaperContent>
          <AccordionGroup title='About Insurance:'>
            <Accordion
              title='How do I know if my pet’s treatments will be covered?'
              openContent={open === 'a1'}
              onContentOpenChange={this.handleOpen('a1', 'How do I know if my pet’s treatments will be covered?')}
            >
              <br></br>
              <Link
                href='/pdfs/PPU_ClaimsChecklist_Brochure_R1.pdf'
                target='_blank'
              >
                Making a Claim Checklist
              </Link>
              <br></br>
              <br></br>
              <Text>
                For those seeking additional peace of mind, there is a
                pre-approval service available for claims over $1,000. When
                requesting a pre-approval, you will need to send us all required
                documentation, including an estimate of cost. Our team will
                respond outlining the coverage you have available and any
                applicable limits for these treatments under your selected
                insurance policy. Please select your insurance brand from the
                list below for your pre-approval form.
              </Text>

              <ul>
                <li>
                  <Link href='/pdfs/PPU_PA_MERCK_2021.pdf' target='_blank'>
                    Pets Plus Us USA
                  </Link>
                </li>
              </ul>

              <Text>
                {/* TODO REPLACE LINKS */}
                Please complete and submit the pre-approval form, along with an
                estimate of charges and applicable medical history. You can also
                fax these documents to{' '}
                <Link href='tel:18664282199'>1-866-428-2199</Link> or by email
                to{' '}
                <Link href='mailto:claimsUS@petsplusus.com'>
                  claimsUS@petsplusus.com
                </Link>
                . A response will be provided within 2 business days of receipt
                of all required documentation.
              </Text>
            </Accordion>

            <Accordion
              title='I got my updated claim status, but what does it mean?'
              openContent={open === 'a2'}
              onContentOpenChange={this.handleOpen('a2', 'I got my updated claim status, but what does it mean?')}
            >
              <Text>
                Every claim goes through a number of stages in the assessment
                process. You may see any one of the following statuses on our
                customer portal – The guide below will help you in understanding
                what your current claim status means:
              </Text>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell>Meaning</TableHeaderCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableDataCell>Claim in Process</TableDataCell>
                    <TableDataCell>
                      All of your documents and claim details are in our system.
                      We call this step "pre-processing".
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>Claim in Review</TableDataCell>
                    <TableDataCell>
                      Your claim is with our claims Adjusters.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>
                      Claim on Hold Waiting for Docs
                    </TableDataCell>
                    <TableDataCell>
                      Uh-oh, something was missing from your claim submission.
                      Don’t worry – we're on the case. We advise you and your
                      pet's vet clinic of the documents required to complete
                      your claim.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>
                      1st reminder / 2nd Reminder / Final reminder
                    </TableDataCell>
                    <TableDataCell>
                      We will send reminders to policy holders when awaiting
                      information required to complete a claim. We will reach
                      out as a reminder and to provide updates while your claim
                      is still active.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>Completed</TableDataCell>
                    <TableDataCell>
                      Your claim is complete. This means our adjusters have
                      finished your claim and you will receive your formal
                      Explanation of Benefits shortly.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>Cancelled</TableDataCell>
                    <TableDataCell>
                      Your claim has been cancelled and removed from processing.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>Cancelled - Duplicate</TableDataCell>
                    <TableDataCell>
                      When we receive duplicate claims or when all invoices for
                      a claim are duplicates, the claim will be cancelled.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>Claim Closed</TableDataCell>
                    <TableDataCell>
                      When required information is missing and we have been
                      unable to connect with your or your vet clinic for 30
                      days, your claim will be closed. You will have the
                      opportunity to resubmit your claim when you have acquired
                      the missing documentation.
                    </TableDataCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Accordion>

            <Accordion
              title='What is a pre-existing condition?'
              openContent={open === 'a3'}
              onContentOpenChange={this.handleOpen('a3', 'What is a pre-existing condition?')}
            >
              <Text>
                A condition which first occurred, manifested, displayed signs
                and/or symptoms of, was treated, diagnosed or has been known by
                You, and/or Your Veterinarian prior to Your Pet’s Coverage
                Effective Date, as stated on Your Document of Insurance, or
                during an applicable waiting period, and any subsequent time
                period during which a lapse of Coverage occurs.
              </Text>
            </Accordion>

            <Accordion
              title='Do your plans cover dental?'
              openContent={open === 'a4'}
              onContentOpenChange={this.handleOpen('a4', 'Do your plans cover dental?')}
            >
              <Text>
                Let’s talk! Call us at{' '}
                <Link href='tel:18885047387'>1-888-504-7387</Link> to find out
                more.
              </Text>
            </Accordion>

            <Accordion
              title='How will I receive my reimbursement?'
              openContent={open === 'a5'}
              onContentOpenChange={this.handleOpen('a5', 'How will I receive my reimbursement?')}
            >
              <Text>
                Reimbursement can be provided via direct deposit or check. Get
                the fastest reimbursement possible by signing up for Direct
                Deposit reimbursement through the Customer Portal:{' '}
                <Link href='http://www.mypetsplusus.com'>MyPetsPlusUs.com</Link>
              </Text>
            </Accordion>
          </AccordionGroup>

          <AccordionGroup title='About Customer Portal:'>
            <Accordion
              title='Do I have to submit each condition separately?'
              openContent={open === 'a6'}
              onContentOpenChange={this.handleOpen('a6', 'Do I have to submit each condition separately?')}
            >
              <Text>
                No! You can submit up to 10 separate conditions in one claim!
              </Text>
            </Accordion>

            <Accordion
              title='How will I know if I have submitted my claim incorrectly?'
              openContent={open === 'a7'}
              onContentOpenChange={this.handleOpen('a7', 'How will I know if I have submitted my claim incorrectly?')}
            >
              <Text>
                You will be contacted via email regarding your claim’s status
                throughout submission and review. If there are any issues, or
                missing information, we will let you know.
              </Text>
            </Accordion>

            <Accordion
              title='Can I still submit claims through fax or email?'
              openContent={open === 'a8'}
              onContentOpenChange={this.handleOpen('a8', 'Can I still submit claims through fax or email?')}
            >
              <Text>
                We will still accept claims through fax and email, but you must
                include the claim cover page found{' '}
                <span className='ui-link ui-link--link' onClick={handleClaim}>
                  here
                </span>{' '}
                as the first page of your submission. This page has a unique bar
                code that will be used to identify your claim.
              </Text>
            </Accordion>

            <Accordion
              title='If I call in and update my email address, will I still be able to sign in to the portal?'
              openContent={open === 'a9'}
              onContentOpenChange={this.handleOpen('a9', 'If I call in and update my email address, will I still be able to sign in to the portal?')}
            >
              <Text>
                Yes! If you use your email address as your username, you will
                need to continue to use the original email address. Don't worry
                – you are able to update your username and password within the
                "My Account" section of the portal!
              </Text>
            </Accordion>
          </AccordionGroup>

          <AccordionGroup title='About Automatic Renewals:'>
            <Accordion
              title='What is an automatic renewal?'
              openContent={open === 'a10'}
              onContentOpenChange={this.handleOpen('a10', 'What is an automatic renewal?')}
            >
              <Text>
                Your Pets Plus Us Pet Insurance Policy will automatically renew
                at the end of each 12-month term. This process ensures your pet
                has ongoing continuous coverage. We send renewal notices well in
                advance of the renewal date to provide policy holders with
                information on their policy limits as well as any change in
                premium, this allows time for you to consider your coverage
                options. Policy holders are welcome to contact our Customer Care
                representative at:{' '}
                <Link href='tel:18885047387'>1-888-504-7387</Link> to make
                changes* to their policy or ask any questions they may have.
              </Text>

              <Text type={Text.Types.caption}>
                *Policies may be downgraded at any time within a term as long as
                no claims have been submitted and maintain continuous coverage.
                When upgrading with a desire to maintain continuous coverage,
                this change must be completed within the renewal period - 45
                days prior to the new term start date. A midterm upgrade is
                processed as a cancelation of coverage and a new sale, this
                change does not benefit from continuous coverage
              </Text>
            </Accordion>

            <Accordion
              title='What steps do you take to make sure my credit card information is secure and where is it being stored?'
              openContent={open === 'a11'}
              onContentOpenChange={this.handleOpen('a11', 'What steps do you take to make sure my credit card information is secure and where is it being stored?')}
            >
              <Text>
                Our PCI compliance software allows us to collect and store
                payment information securely without risk of the information
                being compromised. When you provide your method of payment over
                the phone or online, the information is stored as a token with
                our payment processor. Only the last four digits and expiry date
                of your credit card are stored within our internal systems to be
                used in communications with you when your products/services come
                up for renewal.
              </Text>
            </Accordion>
          </AccordionGroup>
        </PaperContent>
      </Paper>
    )
  }
}

import React from "react";
import { ForgotPasswordSuccessCard, Button} from "../../../UI-Component-Library";
import { withRouter } from "react-router-dom";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
class ForgotPasswordSuccess extends React.Component {

  componentDidMount(){
    DataLayerUtils.createAccountEvent(
      null,
      "email",
      "request password reset"
    )
  }
  render() {
    return (
      <ForgotPasswordSuccessCard
        headline= "Reset password"
        showEmailIcon={false}
        messageBody= "Please check your email. We have sent you a link to reset your password. If you’re having trouble, please call 1-866-597-2424."
        closeButtonText= "Go back"
        closeButtonProps={{
          id: "go_back_to_login",
          variant: Button.Variants.outlined,
          size: Button.Sizes.small,
          onClick: () => {
            this.props.history.push("/auth");
          },
        }}
      />
    );
  }
}
export default withRouter(ForgotPasswordSuccess);

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Button } from '../Button'
import { Icon } from '../Icon'

export class NavigationButton extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { label, id, className, style, refProp, onClick } = this.props

    return (
      <Button
        variant={Button.Variants.outlined}
        color={Button.Colors.tertiary}
        id={id}
        className={cn('ui-navigation-button', className)}
        style={style}
        ref={refProp}
        onClick={onClick}
      >
        <Icon type='arrow_back' />
        <span className='ui-navigation-button__text'>{label}</span>
      </Button>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperContent, PaperHeadline, Text, Button, Icon } from '../../'

export class ResetPasswordSuccessCard extends React.PureComponent {
  static propTypes = {
    closeButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { closeButtonProps, id, className, style, refProp } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-reset-password-success-card', className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Done!</PaperHeadline>
        <PaperContent>
          <div className='ui-reset-password-success-card__text'>
            <Icon type='check_circle_outline' />
            <Text type={Text.Types.bodyBold}>
              Your password has been reset!
            </Text>
          </div>

          <div className='ui-reset-password-success-card__action'>
            <Button color={Button.Colors.tertiary} {...closeButtonProps}>
              Go back to Log in
            </Button>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

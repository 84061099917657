import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperContent, Avatar, Text, Button, Icon, Link } from '../../'

export class UserProfileCard extends React.PureComponent {
  static propTypes = {
    /**
     * An id attribute that is applied to pet profile picture (avatar)
     */
    avatarElementId: PropTypes.string,
    onUserAvatarClick: PropTypes.func,

    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userAvatar: PropTypes.string,
    showErrorAlertIcon: PropTypes.bool,
    logoutButtonProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string
    }),
    info: PropTypes.any,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }
  static defaultProps = {
    showErrorAlertIcon: false,
    info : (
      <>
        {(
          ''
        )}
      </>
    )
  }

  render() {
    const {
      avatarElementId,
      onUserAvatarClick,
      userName,
      userEmail,
      userAvatar,
      showErrorAlertIcon,
      info,
      logoutButtonProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-user-profile-card', className)}
        style={style}
        ref={refProp}
      >
        <div className='ui-user-profile-card__avatar'>
          <div className='ui-user-profile-card__avatar-image' id='userImageId'>
            <Avatar
              onClick={onUserAvatarClick}
              id={avatarElementId}
              noImageId={"userAddPhotoId"}
              img={userAvatar}
              className={(showErrorAlertIcon)? "ui-user-profile-card__avatar-outline": ""}
            />
            {showErrorAlertIcon && (
              <Icon
                type='error'
                className='ui-user-profile-card__avatar-alert-icon'
              />
            )}
          </div>
        </div>
        {info}
        <PaperContent className='ui-user-profile-card__content'>
          <Text type={Text.Types.headline}>{userName}</Text>
          <Text>{userEmail}</Text>
          <Button color={Button.Colors.tertiary} {...logoutButtonProps}>
            Logout
          </Button>
        </PaperContent>
      </Paper>
    )
  }
}

import React, { Component } from "react";
import PetReview from "./pet-review";
import PetInformation from "./pet-info-registration";
import PetSuccess from "./pet-success";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { AppUtils } from "../../../utils/app-utils";
import {
  RmStep1Card,
  TwoColumnContent,
  RmStep1InfoCard,
  PageContent,
  InputHelperWithTooltip,
} from "../../../UI-Component-Library";
import { LoadMask } from "../../../utils/loadmask.util";
import { Redirect } from "react-router-dom";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";

class PetRegistrationContainer extends Component {
  constructor(props) {
    super(props);

    this.user = AppUtils.getLoggedInUser();
    this.state = {
      step: 0,
      firstName: "",
      lastName: "",
      zipCode: "",
      street: "",
      city: "",
      state: "",
      homePhone: "",
      emailAddress: "",
      newMphRegistrationId: "",

      microChipNumber: "",
      microchipProvider: 0,
      microchipProviderName: "",
      animalName: "",
      species: "",
      gender: "",
      breedType: "",
      breed: "",
      breedName: "",
      color: "",
      colorName: "",
      spayedNeutered: "",
      petBirthMonth: "",
      petBirthYear: "",
      clinicName: "",
      clinicNameText: "",
      colorList: [],
      clinicList: [],
      infoStart: "",
      chipProviders: "",
      chipValidationError: "",
      petName: "",
      petGender: "",
      petType: "",
      petBreedLabel: "",
      petColor: "",
      birthMonth: "",
      birthYear: "",
      clinicInfo: "",
      clinicInfoLabel: "",
      isSprayed: "",
      isConsent: "",
      country: this.user.country,
      breedsList: [],
      canadaClinicList: [],
      usClinicList: [],
      canadaColorList: [],
      usColorList: [],
      isRedirectToDashboard: false,
      isShowCancelStep: false,
    };
  }

  getBreedsList = () => {
    if (
      this.state.country.length > 0 &&
      this.state.petType.length > 0 &&
      this.state.breedType.length > 0
    ) {
      const payload = {
        species: this.state.petType,
        breedType: this.state.breedType,
        country: this.state.country,
      };

      axios
        .processPost(`${ApiConstants.URL_MICRO_LOAD_PET_LIST}`, null, payload)
        .then((response) => {
          this.setState({
            breedsList: response.data,
          });
        })
        .catch((error) => {});
    }
  };

  getMicrochipListByCountry() {
    axios
      .processGet(
        `${
          ApiConstants.URL_MICRO_CHIP_COLORS_LIST
        }/${this.state.country.toLowerCase()}`
      )
      .then((response) => {
        if (this.state.country.toLowerCase() === "us") {
          this.setState({
            usColorList: response.data,
          });
        }
        if (this.state.country.toLowerCase() === "ca") {
          this.setState({
            canadaColorList: response.data,
          });
        }
      });
    LoadMask.showPreloader(this.refs.regRef);
    axios
      .processGet(
        `${
          ApiConstants.URL_MICRO_CHIP_CLINIC_LIST
        }/${this.state.country.toLowerCase()}`,
        this.regValidRef
      )
      .then((response) => {
        if (this.state.country.toLowerCase() === "us") {
          this.setState({
            usClinicList: response.data,
          });
        }
        if (this.state.country.toLowerCase() === "ca") {
          this.setState({
            canadaClinicList: response.data,
          });
        }
        LoadMask.hidePreloader(this.refs.regRef);
      })
      .catch((err) => {
        LoadMask.hidePreloader(this.refs.regRef);
        this.getMicrochipListByCountry();
      });
  }

  // Call it from pet review page.
  onEditButtonClicked = (stepNumber) => {
    this.setState({
      step: stepNumber,
      isShowCancelStep: true,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.petType !== prevState.petType ||
      this.state.breedType !== prevState.breedType
    )
      this.getBreedsList();
  };

  clickNext = () => {
    this.setState((prevState) => {
      return {
        step: prevState.step + 1,
      };
    });
  };

  clickBack = () => {
    this.setState((prevState) => {
      return {
        step: prevState.step - 1,
      };
    });
  };

  savePetRegistrationInfo = (isFutureDate) => {
    const mcName = this.getMicrochipProviderName();

    if(isFutureDate){
      this.setState({
        savePetErrorMessage: "Future date for DOB of pet is not allowed!",
      });
      AppUtils.focusOnDiv("rm-top");
      return;
    }

    const petInfo = {
      microChipNumber: this.state.microChipNumber,
      microchipProvider: parseInt(this.state.microchipProvider),
      microchipProviderName: mcName,
      animalName: this.state.petName,
      species: this.state.petType,
      gender: this.state.petGender,
      breedType: this.state.breedType,
      breed: this.state.petBreed,
      breedName: this.state.petBreedLabel,
      color: this.state.petColor,
      colorName: this.state.colorLabel,
      spayedNeutered: this.state.isSprayed === "Yes" ? true : false,
      petBirthMonth: parseInt(this.state.birthMonth),
      petBirthYear: parseInt(this.state.birthYear),
      clinicName: this.state.clinicInfo,
      clinicNameText: this.state.clinicInfoLabel,
      contactConsent: this.state.isConsent === "Yes" ? true : false,
      newMphRegistrationId: this.state.newMphRegistrationId,
    };

    LoadMask.showPreloader(this.refs.petReg);
    axios
      .processPost(`${ApiConstants.URL_MICRO_SAVE_PET_INFO}`, null, petInfo)
      .then((response) => {
        LoadMask.hidePreloader(this.refs.petReg);
        this.clickNext();
        DataLayerUtils.registerNewPetEvent(
          AppUtils.getUserRelationId(),
          this.state.microChipNumber,
          "submit pet info"
        )
      })
      .catch((error) => {
        LoadMask.hidePreloader(this.refs.petReg);
        let errors = error?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            savePetErrorMessage: errors[0],
          });
          DataLayerUtils.formErrorEvent("Pet info", null, errors[0]);
        }
        AppUtils.focusOnDiv("rm-top");
      });
  };

  componentDidMount() {
    this.getContactInfo();
    this.getMicrochipListByCountry();

    const type = this.props.location?.state?.type || '';

    if (AppUtils.isEqualString(type, 'phone')) {
      this.clickNext();
    }
  }

  getMicrochipProviderName = () => {
    for (let optionObj of this.state.chipProviders) {
      if (`${optionObj.Key}` === `${this.state.microchipProvider}`) {
        return optionObj.Value;
      }
    }

    return '';
  };

  onJumpPage = (stepNumber) => {
    this.setState({
      step: stepNumber,
    });
  };

  getContactInfo = () => {
    axios
      .processGet(
        `${ApiConstants.URL_GET_CONTACT_INFO}${AppUtils.getBusRelAccount()}`
      )
      .then((response) => {
        this.setState({
          firstName: response.address.firstname,
          lastName: response.address.lastname,
          city: response.address.city,
          state: response.address.state,
          zipCode: response.address.zipcode,
          emailAddress: response.emailAddress,
          homePhone: response.phoneNumber,
          street: response.address.addressLine1,
        });
      })
      .catch((error) => {});
  };

  savePetInfoWithoutChip = (isFutureDate) => {
    const microchipNumber = this.state.microChipNumber;
    this.setState({
      savePetErrorMessage: '',
    });

    const payload = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      country: this.state.country,
      zipCode: this.state.zipCode,
      street: this.state.street,
      city: this.state.city,
      state: this.state.state,
      homePhone: this.state.homePhone,
      emailAddress: AppUtils.getUserEmail(),
      microchipNumber: microchipNumber,
      businessPhone: "",
      AnimalName: this.state.petName,
      Breed: this.state.petBreedLabel,
      isNewUser: false,
      PetBirthMonth: parseInt(this.state.birthMonth),
      PetBirthYear: parseInt(this.state.birthYear),
    };

    LoadMask.showPreloader(this.refs.regRef);
    axios
      .processPost(
        `${ApiConstants.URL_ADD_ANOTHER_PET_VALIDATION}`,
        this.regValidRef,
        payload
      )
      .then((response) => {
        this.setState(
          {
            newMphRegistrationId: response?.newMphRegId,
          },
          () => {
            this.savePetRegistrationInfo(isFutureDate);
          }
        );
        LoadMask.hidePreloader(this.refs.regRef);
      })
      .catch((error) => {
        let errors = error?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            savePetErrorMessage: errors[0],
          });
        }
        LoadMask.hidePreloader(this.refs.regRef);
        AppUtils.focusOnDiv("rm-top");
      });
  }

  // Complete
  registerMicrochip = () => {
    const microchipNumber = this.state.microChipNumber;

    const payload = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      country: this.state.country,
      zipCode: this.state.zipCode,
      street: this.state.street,
      city: this.state.city,
      state: this.state.state,
      homePhone: this.state.homePhone,
      emailAddress: AppUtils.getUserEmail(),
      microchipNumber: microchipNumber,
      businessPhone: "",
      isNewUser: false,
    };

    LoadMask.showPreloader(this.refs.regRef);
    axios
      .processPost(
        `${ApiConstants.URL_ADD_ANOTHER_PET_VALIDATION}`,
        this.regValidRef,
        payload
      )
      .then((response) => {
        this.setState(
          {
            chipProviders: response.data,
            microchipProvider: response.data[0]["Key"],
            newMphRegistrationId: response?.newMphRegId,
          },
          () => {
            const microchipProviderName = this.getMicrochipProviderName();
            this.setState({
              microchipProviderName,
            });
          }
        );
        DataLayerUtils.registerNewPetEvent(AppUtils.getUserRelationId(), this.state.microChipNumber, "submit microchip")
        LoadMask.hidePreloader(this.refs.regRef);
        this.clickNext();
      })
      .catch((error) => {
        let errors = error?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            chipValidationError: errors[0],
          });
          DataLayerUtils.formErrorEvent("Add microchip", "Microchip Number", errors[0]);
        }
        LoadMask.hidePreloader(this.refs.regRef);
      });
  };

  handleChanges = (event, name) => {
    if (name === "infoStart") {
      this.setState({
        infoStart: event,
      });
      return;
    }

    if (name === "petBreed") {
      this.setState({
        petBreed: event.value,
        petBreedLabel: event.label,
      });
      return;
    }

    if (name === "petColor") {
      this.setState({
        colorLabel: event.label,
        petColor: event.value,
      });
      return;
    }

    if (name === "clinicInfo") {
      this.setState({
        clinicInfoLabel: event.label,
        clinicInfo: event.value,
      });
      return;
    }

    const fields = [
      "petGender",
      "petType",
      "isSprayed",
      "isConsent",
      "breedType",
    ];
    if (fields.includes(name)) {
      this.setState({
        [name]: event,
      });
      return;
    }

    this.setState({
      [name]: event.target.value,
    });
  };

  clickOnSubmit = () => {
    LoadMask.showPreloader(this.refs.reviewReg);
    axios
      .processGet(
        `${ApiConstants.URL_MICRO_SAVE_ADDITIONAL_INFO}/${AppUtils.getUserRelationId()}/${this.state.newMphRegistrationId}`,
        null
      )
      .then((response) => {
        LoadMask.hidePreloader(this.refs.reviewReg);
        DataLayerUtils.registerNewPetEvent(AppUtils.getUserRelationId(), this.state.microChipNumber, null, true);
        this.clickNext();
      })
      .catch((error) => {
        let errors = error?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            reviewPageError: errors[0],
          });
        }
        AppUtils.focusOnDiv("rm-top");
        LoadMask.hidePreloader(this.refs.reviewReg);
      });
  };

  redirectToDashboard = () => {
    this.props.history.push("/pages");
    window.location.reload();
  };

  savePetInfo = (isFutureDate) => {
    const type = this.props.location?.state?.type || '';

    if (AppUtils.isEqualString(type, 'phone') && !this.state.newMphRegistrationId) {
      return this.savePetInfoWithoutChip(isFutureDate);
    } else {
      return this.savePetRegistrationInfo(isFutureDate);
    }
  }

  getComponents() {
    const formValues = { ...this.state };
    switch (this.state.step) {
      case 0:
        return (
          <TwoColumnContent
            leftColumn={
              <div ref="regRef">
                <RmStep1Card
                  errorMessage={this.state.chipValidationError}
                  backToLinkProps={{
                    href: "/pages/dashboard",
                    children: "Back to Dashboard Page",
                  }}
                  loginLinkProps={{
                    href: "/auth/login",
                  }}
                  pageTitle="Register a Pet"
                  microchipNumberInputFieldProps={{
                    value: this.state.microChipNumber,
                    name: "microChipNumber",
                    id: "microChipNumber",
                    onChange: (event) => {
                      this.handleChanges(event, "microChipNumber");
                    },
                    helper: (
                      <InputHelperWithTooltip
                        helperContent="How do I find my pet's microchip number?"
                        tooltipContent="You'll find the microchip number on your pet's adaption form. Most numbers start with 982,985 or 60 and can be anywhere from 
                    8 to 15 digits long. Some numbers may also start with 0, 1 8 or 977, or may include letters or characters. Have questions? Call us at 1-866-597-2424"
                      />
                    ),
                  }}
                  emailInputFieldProps={{
                    hidden: true,
                  }}
                  onFormSubmit={() => {
                    this.registerMicrochip();
                  }}
                ></RmStep1Card>
              </div>
            }
            rightColumn={<RmStep1InfoCard></RmStep1InfoCard>}
          ></TwoColumnContent>
        );
      case 1:
        return (
          <div ref="petReg">
            <PetInformation
              type={this.props.location?.state?.type || ''}
              clickNext={this.clickNext}
              clickBack={this.clickBack}
              formValues={formValues}
              savePetRegistrationInfo={this.savePetInfo}
              handleChanges={this.handleChanges}
              onJumpPage={this.onJumpPage}
            ></PetInformation>
          </div>
        );
      case 2:
        return (
          <div ref="reviewReg">
            <PetReview
              type={this.props.location?.state?.type || ''}
              onEditButtonClicked={this.onEditButtonClicked}
              formValues={formValues}
              clickNext={this.clickNext}
              clickOnSubmit={this.clickOnSubmit}
            ></PetReview>
          </div>
        );
      case 3:
        return (
          <PetSuccess
            type={this.props.location?.state?.type || ''}
            formValues={formValues}
            redirectToDashboard={this.redirectToDashboard}
          ></PetSuccess>
        );
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <PageContent>
          <div id="rm-top">{this.getComponents()}</div>
        </PageContent>
      </React.Fragment>
    );
  }
}

export default PetRegistrationContainer;

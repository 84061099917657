import React from 'react'
import PropTypes from 'prop-types'

import { Modal, Paper, PaperHeadline, PaperContent, Button, Text } from '../../'

import { PUBLIC_URL } from '../../constants'

export class OtherPetsNotificationModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    className: PropTypes.string,
    continueButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    backButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  render() {
    const { open, onClose, continueButtonProps, backButtonProps, className } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-other-pets-notification'>
          <Paper
            type={Paper.Types.framed}
            className={className}
          >
            <PaperHeadline>Your other pets</PaperHeadline>
            <PaperContent
              className='ui-other-pets-notification__content'
              backgroundImg={{
                src: PUBLIC_URL + 'fadeImg1.png',
                position: 'right'
              }}
            >
              <Text type={Text.Types.headlineBold}>
                Are you forgetting anyone?
              </Text>
              <Text>
                We noticed you’re only buying items for one of your pets.
              </Text>
              <Button
                color={Button.Colors.tertiary}
                {...backButtonProps}
                variant={Button.Variants.outlined}
              >
                Go Back
              </Button>
              <Button color={Button.Colors.tertiary} {...continueButtonProps}>
                Continue to checkout
              </Button>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

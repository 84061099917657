import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class Text extends React.PureComponent {
  static Types = Object.freeze({
    headline: 'headline',
    headlineBold: 'headlineBold',
    title: 'title',
    bodyBold: 'bodyBold',
    body: 'body',
    button: 'button',
    caption: 'caption'
  })

  static propTypes = {
    children: PropTypes.any,
    type: PropTypes.oneOf(Object.values(Text.Types)).isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object,
    /**
     * HTML Element as string (e.g. `span`, `p`...)
     */
    renderAs: PropTypes.string
  }

  static defaultProps = {
    type: Text.Types.body
  }

  render() {
    const { renderAs, children, type, className, id, style } = this.props

    const [element, classes] = getElementAndClasses(type)

    return React.createElement(
      renderAs || element,
      {
        className: cn(classes, className),
        id,
        style
      },
      children
    )
  }
}

export const getElementAndClasses = (type) => {
  let element = 'p'
  const classes = ['ui-text']

  switch (type) {
    case Text.Types.headline:
      element = 'h3'
      classes.push('ui-text--headline')
      break
    case Text.Types.headlineBold:
      element = 'h3'
      classes.push('ui-text--headlineBold')
      break
    case Text.Types.title:
      element = 'h4'
      classes.push('ui-text--title')
      break
    case Text.Types.bodyBold:
      classes.push('ui-text--bodyBold')
      break
    case Text.Types.button:
      element = 'span'
      classes.push('ui-text--button')
      break
    case Text.Types.caption:
      element = 'span'
      classes.push('ui-text--caption')
      break
    case Text.Types.body:
    default:
      classes.push('ui-text--body')
      break
  }

  return [element, classes]
}

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text } from '../../'
import { Hint } from '../Input/InputComponents'

export class TextArea extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      // track input focused state to apply an appropriate class to label
      focused: false
    }

    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  static propTypes = {
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.any,
    type: PropTypes.string,
    label: PropTypes.any,
    error: PropTypes.bool,
    helper: PropTypes.any,
    hint: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    classes: PropTypes.shape({
      container: PropTypes.string,
      helper: PropTypes.string,
      label: PropTypes.string,
      input: PropTypes.string,
      hint: PropTypes.string
    }),
    style: PropTypes.object,
    refProps: PropTypes.shape({
      container: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
      ]),
      label: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
      ]),
      input: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
      ]),
      hint: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
      ])
    })
  }

  static defaultProps = {
    disabled: false,
    classes: {},
    refProps: {},
    error: false
  }

  handleFocus = (e) => {
    this.setState({ focused: true })
    this.props.onFocus?.(e)
  }

  handleBlur = (e) => {
    this.setState({ focused: false })
    this.props.onBlur?.(e)
  }

  render() {
    const { focused } = this.state

    const {
      onChange,
      onInput,
      name,
      value,
      type,
      label,
      hint,
      helper,
      classes: customClasses,
      placeholder,
      disabled,
      refProps,
      error,
      id,
      ...rest
    } = this.props

    const componentClasses = getClasses(focused, error, disabled)

    return (
      <div
        ref={refProps.container}
        className={cn(componentClasses.container, customClasses.container)}
      >
        {label && (
          <label
            ref={refProps.label}
            className={cn(componentClasses.label, customClasses.label)}
            htmlFor={id}
          >
            {label}
          </label>
        )}

        {helper && (
          <Text
            className={cn(componentClasses.helper, customClasses.helper)}
            type={Text.Types.caption}
          >
            {helper}
          </Text>
        )}

        <div className={componentClasses.inputWrapper}>
          <textarea
            onChange={onChange}
            onInput={onInput}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            value={value}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            className={cn(componentClasses.input, customClasses.input)}
            ref={refProps.input}
            id={id}
            name={name}
            {...rest}
          />
        </div>

        {hint && (
          <Hint
            hint={hint}
            refProp={refProps.hint}
            className={cn(componentClasses.hint, customClasses.hint)}
          />
        )}
      </div>
    )
  }
}

export const getClasses = (focused, error, disabled) => {
  const classes = {
    container: ['ui-textarea__container'],
    label: ['ui-textarea__label'],
    helper: ['ui-textarea__helper'],
    inputWrapper: ['ui-textarea--wrapper'],
    input: ['ui-textarea'],
    hint: ['ui-textarea__hint']
  }

  focused && classes.container.push('ui-textarea__container--focused')
  disabled && classes.container.push('ui-textarea__container--disabled')
  error && !disabled && classes.container.push('ui-textarea__container--error')
  error && !disabled && classes.input.push('ui-textarea--error')

  return classes
}

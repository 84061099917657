import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Icon, Button } from '../../'

export class CartFloatButton extends React.PureComponent {
  static propTypes = {
    cartTotal: PropTypes.string,
    onClick: PropTypes.func,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { onClick, cartTotal, id, className, style, refProp } = this.props

    return (
      <Button
        id={id}
        className={cn('ui-cart-float-button', className)}
        style={style}
        refProp={refProp}
        color={Button.Colors.success}
        onClick={onClick}
      >
        <Icon type='shopping_cart' />
        {cartTotal}
      </Button>
    )
  }
}

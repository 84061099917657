import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Form,
  Input,
  FormAction,
  Button,
  Text,
  Select,
  Checkbox,
  Divider
} from '../..'
import { Locale as importedLocale } from '../../constants'
import { getLabelsByLocale } from '../../utils'

export class PetplacePaymentSettingsCard extends React.PureComponent {
  static Locale = importedLocale

  static propTypes = {
    locale: PropTypes.oneOf(Object.values(PetplacePaymentSettingsCard.Locale)),

    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    onFormSubmit: PropTypes.func,

    useMailingAddressCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string,
      errorMessage: PropTypes.string
    }),

    showPreAuthorizedWithdrawalField: PropTypes.bool,
    showAllowMicrochipMembershipRenewal: PropTypes.bool,
    allowMicrochipMembershipRenewalCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string,
      errorMessage: PropTypes.string
    }),

    showAllowInsurancePremium: PropTypes.bool,
    allowInsurancePremiumCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string,
      errorMessage: PropTypes.string
    }),

    showUseCreditCardForAllPolicies: PropTypes.bool,
    useCreditCardForAllPoliciesCheckboxProps: PropTypes.shape({
      checked: PropTypes.bool,
      onChange: PropTypes.func,
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string,
      errorMessage: PropTypes.string
    }),

    /**
     * Select Pet
     */
    petSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Select product
     */
    productSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Bank Account Type
     */
    bankAccountTypeSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Country select field for Pre-authorized withdrawal
     */
    preAuthorizedWithdrawalCountySelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Account Name
     */
    accountNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Bank Name
     */
    bankNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Routing number
     */
    routingNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Transit number
     */
    transitNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Institution number
     */
    institutionNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Account number
     */
    accountNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Payment Method
     */
    paymentMethodSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Credit card number
     */
    creditCardNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Card holder name
     */
    creditCardHolderNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Expiry date
     */
    creditCardExpiryInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * CVV
     */
    creditCardCVVInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    /**
     * Phone number
     */
    phoneInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * First name
     */
    firstNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Last name
     */
    lastNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Country
     */
    countrySelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Address line 1
     */
    address1InputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Address line 2
     */
    address2InputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * City
     */
    cityInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * State
     */
    stateSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Zip code
     */
    zipInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    footerText: PropTypes.string,
    showSaveChangesButton: PropTypes.bool,
    saveChangesButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    useMailingAddressCheckboxProps: {},
    showAllowInsurancePremium: true,
    showAllowMicrochipMembershipRenewal: true,
    showUseCreditCardForAllPolicies: true,
    locale: PetplacePaymentSettingsCard.Locale.us,
    showSaveChangesButton: true
  }

  render() {
    const {
      locale,
      successMessage,
      errorMessage,
      onFormSubmit,

      showPreAuthorizedWithdrawalField,
      showAllowInsurancePremium,
      showAllowMicrochipMembershipRenewal,
      showUseCreditCardForAllPolicies,
      useMailingAddressCheckboxProps,
      allowMicrochipMembershipRenewalCheckboxProps,
      allowInsurancePremiumCheckboxProps,
      useCreditCardForAllPoliciesCheckboxProps,

      bankAccountTypeSelectFieldProps,
      preAuthorizedWithdrawalCountySelectFieldProps,
      accountNameInputFieldProps,
      bankNameInputFieldProps,
      routingNumberInputFieldProps,
      transitNumberInputFieldProps,
      institutionNumberInputFieldProps,
      accountNumberInputFieldProps,

      petSelectFieldProps,
      productSelectFieldProps,
      paymentMethodSelectFieldProps,
      creditCardNumberInputFieldProps,
      creditCardHolderNameInputFieldProps,
      creditCardExpiryInputFieldProps,
      creditCardCVVInputFieldProps,

      phoneInputFieldProps,
      firstNameInputFieldProps,
      lastNameInputFieldProps,
      countrySelectFieldProps,
      address1InputFieldProps,
      address2InputFieldProps,
      cityInputFieldProps,
      stateSelectFieldProps,
      zipInputFieldProps,
      saveChangesButtonProps,
      footerText,
      showSaveChangesButton,

      id,
      className,
      style,
      refProp
    } = this.props

    const { stateLabel, zipCodeLabel } = getLabelsByLocale(locale)

    return (
      <Paper
        id={id}
        className={cn('ui-petplace-payment-settings-card', className)}
        style={style}
        ref={refProp}
        // type={Paper.Types.framed}
      >
        <PaperContent>
          <Form onSubmit={onFormSubmit}>
            {successMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-petplace-payment-settings-card__success-message'
              >
                {successMessage}
              </Text>
            )}

            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-petplace-payment-settings-card__error-message'
              >
                {errorMessage}
              </Text>
            )}

            <div className='ui-petplace-payment-settings-card__form__row'>
              <Select isPetplace= {true} label='Select a Pet' {...petSelectFieldProps} />
              <Select isPetplace= {true} label='Select a Product' {...productSelectFieldProps} />
            </div>

            <div className='ui-petplace-payment-settings-card__form__row'>
              <Select isPetplace= {true} label='Payment Method' {...paymentMethodSelectFieldProps} />
              {showPreAuthorizedWithdrawalField ? (
                <Select
                  isPetplace= {true}
                  label='Bank Account Type'
                  {...bankAccountTypeSelectFieldProps}
                />
              ) : (
                <Input
                  label='Credit Card Number'
                  {...creditCardNumberInputFieldProps}
                />
              )}
            </div>


            {showPreAuthorizedWithdrawalField ? (
              <>
                <div className='ui-petplace-payment-settings-card__form__row'>
                  <Select
                    isPetplace= {true}
                    label='Country'
                    {...preAuthorizedWithdrawalCountySelectFieldProps}
                  />
                  <Input label='Bank Name' {...bankNameInputFieldProps} />
                </div>
                {locale === PetplacePaymentSettingsCard.Locale.us && (
                  <Input
                    label='Routing Number'
                    {...routingNumberInputFieldProps}
                  />
                )}
                <div className='ui-petplace-payment-settings-card__form__row'>
                  <Input label='Account Name' {...accountNameInputFieldProps} />
                  <Input
                    label='Account Number'
                    {...accountNumberInputFieldProps}
                  />
                </div>

                {locale === PetplacePaymentSettingsCard.Locale.ca && (
                  <div className='ui-petplace-payment-settings-card__form__row'>
                    <Input
                      label='Transit Number'
                      {...transitNumberInputFieldProps}
                    />

                    <Input
                      label='Institution Number'
                      {...institutionNumberInputFieldProps}
                    />
                  </div>
                )}

              </>
            ) : (
              <>

                <Input
                  label='Cardholder Name'
                  {...creditCardHolderNameInputFieldProps}
                />

                <div className='ui-petplace-payment-settings-card__form__row_expiry_cvv'>
                  <Input label='Expiration Date' {...creditCardExpiryInputFieldProps} />
                  <Input label='CVV' {...creditCardCVVInputFieldProps} />
                </div>

                <Checkbox
                  showPetplaceCheckbox= {true}
                  label='Use my mailing address'
                  {...useMailingAddressCheckboxProps}
                />

                {!useMailingAddressCheckboxProps?.checked && (
                  <>
                    <Text type={Text.Types.headline}>Billing Address</Text>
                    <div className='ui-petplace-payment-settings-card__form__row'>
                      <Input label='First Name' {...firstNameInputFieldProps} />

                      <Input label='Last Name' {...lastNameInputFieldProps} />
                    </div>
                    <Select isPetplace= {true} label='Country' {...countrySelectFieldProps} />
                    <div className='ui-petplace-payment-settings-card__form__row'>
                      <Input
                        label='Address Line 1'
                        {...address1InputFieldProps}
                      />

                      <Input
                        label='Address Line 2'
                        {...address2InputFieldProps}
                      />
                    </div>
                    <div className='ui-petplace-payment-settings-card__form__row'>
                      <Select isPetplace= {true} label={stateLabel} {...stateSelectFieldProps} />
                      <Input label='City' {...cityInputFieldProps} />
                    </div>
                    <div className='ui-petplace-payment-settings-card__form__row'>
                      <Input label={zipCodeLabel} {...zipInputFieldProps} />
                      <Input label='Phone Number' {...phoneInputFieldProps} />
                    </div>
                  </>
                )}
              </>
            )}

            {/* <Divider /> */}

            {showAllowMicrochipMembershipRenewal && (
              <Checkbox
                className="ui-petplace-payment-settings-card__checkbox"
                showPetplaceCheckbox= {true}
                label='I understand that by updating my payment information I authorize 24Petwatch Pet Protection Services to collect my annual microchip membership renewal fees unless I withdraw from recurring billing by updating My Pet’s Profile or by calling 1-833-461-8756.'
                {...allowMicrochipMembershipRenewalCheckboxProps}
              />
            )}

            {showAllowInsurancePremium && (
              <Checkbox
                showPetplaceCheckbox= {true}
                label='I understand that by updating my payment information I authorize the collection of any outstanding premiums associated with my 24Petwatch pet insurance policy to be collected and authorize use of this method of payment for ongoing premium collection associated with my policy unless I send notification in writing to the contrary.'
                {...allowInsurancePremiumCheckboxProps}
              />
            )}

            {showUseCreditCardForAllPolicies && (
              <Checkbox
                showPetplaceCheckbox= {true}
                label='Optional – Please update my payment information across all my pet insurance policies and membership products.'
                {...useCreditCardForAllPoliciesCheckboxProps}
              />
            )}

            <FormAction>
              {footerText && (
                <Text type={Text.Types.bodyBold}>{footerText}</Text>
              )}

              {showSaveChangesButton && (
                <Button
                  color={Button.Colors.tertiary}
                  type='submit'
                  {...saveChangesButtonProps}
                >
                  Save Changes
                </Button>
              )}
            </FormAction>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
    Modal,
    Paper,
    PaperHeadline,
    PaperContent,
    Button,
    Text
} from '../../'

export class PetHasInsuranceModal extends React.PureComponent {
    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        callInMessage: PropTypes.string,
        cancelButtonProps: PropTypes.shape({
            id: PropTypes.string,
            onClick: PropTypes.func
        })
    }


    render() {
        const {
            open,
            onClose,
            callInMessage,
            cancelButtonProps
        } = this.props


        return (
            <Modal open={open} onClose={onClose}>
                <div className='ui-pet-has-insurance-modal'>
                    <Paper type={Paper.Types.framed}>
                        <PaperHeadline>Remove pet</PaperHeadline>
                        <PaperContent>
                            <Text 
                                className='ui-pet-has-insurance-modal__text'
                                type={Text.Types.body}
                            >{callInMessage}</Text>
                            <div className='ui-pet-has-insurance-modal__actions'>
                                <Button
                                    type='button'
                                    variant={Button.Variants.outlined}
                                    color={Button.Colors.tertiary}
                                    {...cancelButtonProps}
                                >
                                    OK
                                </Button>
                            </div>
                        </PaperContent>
                    </Paper>
                </div>
            </Modal>

            )
    }
}
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text } from '../../'

export const IconText = ({ svgIcon, title, body, className }) => (
  <div className={cn('ui-memberships-icon-text ui-icon-text', className)}>
    {svgIcon}
    <Text>
      <strong>{title}</strong>
      <br />
      {body}
    </Text>
  </div>
)
IconText.propTypes = {
  className: PropTypes.string,
  svgIcon: PropTypes.any,
  title: PropTypes.any,
  body: PropTypes.any
}

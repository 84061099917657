import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class Link extends React.PureComponent {
  static Types = Object.freeze({
    link: 'link',
    button: 'button'
  })

  static Colors = Object.freeze({
    default: 'default',
    secondary: 'secondary',
    tertiary: 'tertiary'
  })

  static propTypes = {
    disabled: PropTypes.bool,
    color: PropTypes.oneOf(Object.values(Link.Colors)),
    type: PropTypes.oneOf(Object.values(Link.Types)),
    children: PropTypes.any,
    href: PropTypes.string,
    target: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    type: Link.Types.link,
    color: Link.Colors.default,
    disabled: false
  }

  render() {
    const {
      disabled,
      color,
      type,
      children,
      href,
      target,
      id,
      className,
      style,
      refProp,
      ...rest
    } = this.props

    const classes = getClasses(type, color, disabled)

    return (
      <a
        href={disabled ? undefined : href}
        target={target}
        id={id}
        className={cn(classes, className)}
        style={style}
        ref={refProp}
        {...rest}
      >
        {children}
      </a>
    )
  }
}

const getClasses = (type, color, disabled) => {
  const classes = ['ui-link']

  type === Link.Types.button
    ? classes.push('ui-link--button')
    : classes.push('ui-link--link')

  color === Link.Colors.secondary && classes.push('ui-link--secondary')
  color === Link.Colors.tertiary && classes.push('ui-link--tertiary')
  disabled && classes.push('ui-link--disabled')

  return classes
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Text,
  Link
} from '../..'

export class ForgotEmailModal extends React.PureComponent {
	static propTypes = {
		open: PropTypes.bool,
		onClose: PropTypes.func,
    cancelButtonProps: PropTypes.shape({
      id: PropTypes.string,
      size: PropTypes.string,
      onClick: PropTypes.func
    }),
    phoneNumber: PropTypes.string,
		buttonText: PropTypes.string,
		modalHeadline: PropTypes.string,
	}
    
	static defaultProps = {
		buttonText: "Close",
		modalHeadline: "Forgot Email"
	}

  render() {
    const {
      open,
      onClose,
      cancelButtonProps,
      buttonText,
      phoneNumber,
      modalHeadline,
    } = this.props


    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-forgot-email-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline>{modalHeadline}</PaperHeadline>
            <PaperContent>
              <Text 
                className='ui-forgot-email-modal__text'
                type={Text.Types.body}
              >
                Call us at{' '}
                <Link href={'tel:' + phoneNumber ? phoneNumber : '1-866-597-2424'}>
                {phoneNumber ? phoneNumber : '1-866-597-2424'}
                </Link>
                {' '} to recover the email address associated with your account.
              </Text>
              <div className='ui-forgot-email-modal__actions'>
                <Button
                    type='button'
                    variant={Button.Variants.outlined}
                    color={Button.Colors.tertiary}
                    {...cancelButtonProps}
                >
                  {buttonText}
                </Button>
              </div>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}
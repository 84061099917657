import React from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Form,
  Option,
  Text,
  Link
} from '../../'


export class FoundPetAlertModal extends React.PureComponent {
  // constructor(){
  //   super();
  //   this.state={
  //     active:[false, false, false, false, false],
  //   }
  //   this.handleClick = this.handleClick.bind(this);
  // }
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onFormSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
    messageBodyText: PropTypes.string,
    turnOffReasonSubmited: PropTypes.bool,
    closeFoundPetAlertLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),
    notificationType: PropTypes.string,
    alertType: PropTypes.string,
    optionsArray: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        onClick: PropTypes.func,
        active: PropTypes.bool,
        label: PropTypes.string,
      })
    ),
    optionClickedProp: PropTypes.func,
    submitButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    cancelButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }
  
  // handleClick(index){
  //   console.log(index);
  //   let active= [false, false, false, false, false];
  //   active[index] = true;
  //   this.setState({
  //     active,
  //   })
  // }

  static defaultProps = {
    messageBodyText : "Please provide us with context as to why you'd like to turn off this alert."
  }
  render() {
    const {
      open,
      onClose,
      errorMessage,
      turnOffReasonSubmited,
      alertType,
      optionsArray,
      messageBodyText,
      optionClickedProp,
      cancelButtonProps,
      submitButtonProps,
      closeFoundPetAlertLinkProps,
      onFormSubmit
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-found-pet-alert-modal'>
          <Paper type={Paper.Types.framed} id="found-pet-alert-modal">
            <PaperHeadline>{alertType}</PaperHeadline>
            <PaperContent>
              {errorMessage && (
                <Text
                  className='ui-found-pet-alert-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}
              <Text
                className='ui-found-pet-alert-modal__text'
                type={Text.Types.headlineBold}
              >
                {turnOffReasonSubmited
                  ? 'Success message'
                  : 'Why are you turning off this alert?'}

                <Text type={Text.Types.body}>
                  {messageBodyText}
                </Text>
                {turnOffReasonSubmited ? (
                  <Link id="close-alert" {...closeFoundPetAlertLinkProps}>Close</Link>
                ) : (
                  <Form onSubmit={onFormSubmit}>
                    {optionsArray.map((option, index) => {
                      return (
                        <Option
                          id={`turn-off-reason-${index}`}
                          showCheckMark
                          key= {`options#${index}`}
                          className= 'ui-found-pet-alert-modal__options'
                          label= {option.label}
                          active= {option.active}
                          onClick= { () => optionClickedProp(index, option.label) }
                        ></Option>
                    )})}
                    <div className='ui-found-pet-alert-modal__actions'>
                      <Button
                        id="turn-off-cancel"
                        type='button'
                        variant={Button.Variants.outlined}
                        color={Button.Colors.tertiary}
                        {...cancelButtonProps}
                      >
                        Cancel
                      </Button>
                      <Button
                        id="turn-off-submit"
                        type='button'
                        color={Button.Colors.tertiary}
                        {...submitButtonProps}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Text>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperContent, PaperHeadline, Text, Button, Icon } from '../../'
import { EmailIcon, MicrochipIcon, PolicyIcon } from '../SvgIcons'
export class NoAccountCard extends React.PureComponent {
  static propTypes = {
    showCreateAccountFromMicrochip: PropTypes.bool,
    showUpdatedIcon: PropTypes.bool,
    createAccountFromMicrochipProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string,
      disabled: PropTypes.bool
    }),

    createAccountFromPolicyProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string,
      disabled: PropTypes.bool
    }),

    createAccountWithoutChipProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string,
      disabled: PropTypes.bool
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    showCreateAccountFromMicrochip: true,
    showUpdatedIcon: true
  }

  render() {
    const {
      showCreateAccountFromMicrochip,
      showUpdatedIcon,
      createAccountFromMicrochipProps,
      createAccountFromPolicyProps,
      createAccountWithoutChipProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        id={id}
        className={cn("ui-no-account-card__paper", className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Don’t have an account?</PaperHeadline>
        <PaperContent>
          <div className="ui-no-account-card__content">
            {showCreateAccountFromMicrochip && (
              <div className="ui-no-account-card__content__item">
                {/* <Icon
                  className='ui-no-account-card__icon'
                  size={Icon.Sizes.large}
                  type='memory'
                /> */}
                <MicrochipIcon></MicrochipIcon>
                <Text className="ui-no-account-card__text">
                  <b>Has your pet been microchipped?</b>
                  <br />
                  You can create a new account from the chip.
                </Text>
                <Button
                  fluid
                  color={Button.Colors.tertiary}
                  {...createAccountFromMicrochipProps}
                >
                  Create account from microchip
                </Button>
              </div>
            )}

            <div className="ui-no-account-card__content__item">
              {/* {!showUpdatedIcon && (<Icon
                className='ui-no-account-card__icon'
                size={Icon.Sizes.large}
                type='assignment'
              />)} */}
              {showUpdatedIcon && <PolicyIcon />}
              <Text className="ui-no-account-card__text">
                <b>Do you already have an insurance policy?</b>
                <br />
                We’ll create an account from your policy.
              </Text>
              <Button
                fluid
                color={Button.Colors.tertiary}
                {...createAccountFromPolicyProps}
              >
                Create account from policy #
              </Button>
            </div>

            <div className="ui-no-account-card__content__item">
              {<EmailIcon />}
              <Text className="ui-no-account-card__text">
                <b>Don’t have a microchip or insurance policy?</b>
                <br />
                Create an account with your phone number and email address.
              </Text>
              <Button
                fluid
                color={Button.Colors.tertiary}
                {...createAccountWithoutChipProps}
              >
                Create account with phone and email
              </Button>
            </div>
          </div>
        </PaperContent>
      </Paper>
    );
  }
}

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Modal, Button, Paper, PaperHeadline, PaperContent, Text } from '../..'

export class CancelTransferPetModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    className: PropTypes.string,
    id: PropTypes.string,
    errorMessage: PropTypes.string,
    successText: PropTypes.string,
    rejectButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    title: PropTypes.string,
    cancelTransferButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    backButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  static defaultProps = {
    // successText: "You have cancelled the transfer request of this pet successfully.",
    successText: "",
    rejectButtonText: "Yes",
    cancelButtonText: "Back",
    title: "Are you sure you want to cancel the transfer of this pet?", // or title: "Success"
  }

  render() {
    const {
      open,
      onClose,
      className,
      id,
      successText,
      errorMessage,
      rejectButtonText,
      cancelButtonText,
      title,
      cancelTransferButtonProps,
      backButtonProps
    } = this.props

    return (
      <Modal
        open={open}
        onClose={onClose}
        className={className}
        id={id}
        refProp
      >
        <div className='ui-cancel-transfer-pet-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline> Transfer pet </PaperHeadline>
            <PaperContent className='ui-cancel-transfer-pet-modal-content'>
              {errorMessage && (
                <Text
                  className='ui-cancel-transfer-pet-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}
              <Text type={Text.Types.bodyBold}>
                {title}
              </Text>
              {successText && <Text type={Text.Types.body} className="ui-cancel-transfer-pet-card__text">
                {successText}
              </Text> }
              
              <div className='ui-cancel-transfer-pet-modal__actions'>
                {!successText && <Button
                  type='button'
                  variant={Button.Variants.filled}
                  {...cancelTransferButtonProps}
                >
                  {rejectButtonText}
                </Button>}
                <Button
                  variant={Button.Variants.outlined}
                  {...backButtonProps}
                >
                  {cancelButtonText}
                </Button>
              </div>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

import React from "react";
import {
  DepositDetailsCard,
  BankingInfoModal,
  Link,
} from "../../../../UI-Component-Library";
import { AppUtils } from "../../../../utils/app-utils";
import axios from "../../../../custom-axios";
import { ApiConstants } from "../../../../utils/api-constants";
import { PaymentUtils } from "./payment-utils";
import { ValidationMessages } from "../../../../utils/validation-messages";
import { FeatureFlagUtils } from "../../../../utils/feature-flag-utils";
import { DataLayerUtils } from "../../../../utils/dataLayer-utils";

class DirectDepositContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      errorMessage: "",
      successMessage: "",
      isUsCountry: AppUtils.getCountryCode().toLowerCase() === "us",
      customerAddress: undefined,
      busRelAccount: undefined,
      country: undefined,
      ddInfo: {
        accountHolderName: "",
        bankName: "",
        routingNumber: "",
        accountNumber: "",
        institutionNumber: "",
        transitNumber: "",
      },
      isBankingInfoModalOpen: false,
      showSaveChangesButton: true,
      errors: PaymentUtils.getDefaultDdErrors(),
    };
    this.cRef = React.createRef();
  }

  render() {
    return (
      <div ref={this.cRef} id="DD_FORM">
        <DepositDetailsCard
          subtitle={"Connect your bank account and have your claims reimbursed faster!"}
          showSaveChangesButton={this.state.showSaveChangesButton}
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
          locale={
            this.state.isUsCountry
              ? DepositDetailsCard.Locale.us
              : DepositDetailsCard.Locale.ca
          }
          onFormSubmit={() => {}}
          accountHolderNameInputFieldProps={{
            id: "DD_accountHolderName",
            name: "accountHolderName",
            label: "Account Holder's Name",
            maxLength: 30,
            disabled: !this.state.showSaveChangesButton,
            value: this.state.ddInfo.accountHolderName,
            onChange: this.handleDdInfoInputChange,
            error: this.state?.errors?.accountNameError,
            hint: this.state?.errors?.accountNameError
              ? "Account holder name" + ValidationMessages.IS_REQUIRED
              : "",
          }}
          bankNameInputFieldProps={{
            id: "DD_bankName",
            name: "bankName",
            disabled: !this.state.showSaveChangesButton,
            value: this.state.ddInfo.bankName,
            error: this.state?.errors?.bankNameError,
            hint: this.state?.errors?.bankNameError
              ? "Bank name" + ValidationMessages.IS_REQUIRED
              : "",
            onChange: this.handleDdInfoInputChange,
          }}
          routingNumberInputFieldProps={{
            id: "DD_routingNumber",
            name: "routingNumber",
            disabled: !this.state.showSaveChangesButton,
            value: this.state.ddInfo.routingNumber,
            error: this.state?.errors?.routingNumberError,
            hint: this.state?.errors?.routingNumberError
              ? "Routing number" + ValidationMessages.IS_REQUIRED
              : "",
            onChange: (event) => {
              if (AppUtils.isOnlyDigitsOrEmptyString(event.target.value)) {
                this.handleDdInfoInputChange(event);
              }
            },
          }}
          accountNumberInputFieldProps={{
            id: "DD_accountNumber",
            name: "accountNumber",
            disabled: !this.state.showSaveChangesButton,
            value: this.state.ddInfo.accountNumber,
            error: this.state?.errors?.accountNumberError,
            hint: this.state?.errors?.accountNumberError
              ? "Account number" + ValidationMessages.IS_REQUIRED
              : "",
            onChange: (event) => {
              if (AppUtils.isOnlyDigitsOrEmptyString(event.target.value)) {
                this.handleDdInfoInputChange(event);
              }
            },
          }}
          transitNumberInputFieldProps={{
            id: "DD_transitNumber",
            name: "transitNumber",
            disabled: !this.state.showSaveChangesButton,
            value: this.state.ddInfo.transitNumber,
            error: this.state?.errors?.transitNumberError,
            hint: this.state?.errors?.transitNumberError
              ? "Transit number" + ValidationMessages.IS_REQUIRED
              : "",
            onChange: (event) => {
              if (AppUtils.isOnlyDigitsOrEmptyString(event.target.value)) {
                this.handleDdInfoInputChange(event);
              }
            },
          }}
          institutionNumberInputFieldProps={{
            id: "DD_institutionNumber",
            name: "institutionNumber",
            disabled: !this.state.showSaveChangesButton,
            value: this.state.ddInfo.institutionNumber,
            error: this.state?.errors?.institutionNumberError,
            hint: this.state?.errors?.institutionNumberError
              ? "Institution number" + ValidationMessages.IS_REQUIRED
              : "",
            onChange: (event) => {
              if (AppUtils.isOnlyDigitsOrEmptyString(event.target.value)) {
                this.handleDdInfoInputChange(event);
              }
            },
          }}
          linkProps={{
            id: "DD_link",
            className:"dd-text-link-override",
            href: "",
            color: FeatureFlagUtils.shouldShowTertiaryButton()
              ? Link.Colors.tertiary
              : Link.Colors.default,
            onClick: (e) => {
              e.preventDefault();
              this.setState({
                isBankingInfoModalOpen: true,
              });
            },
          }}
          editLinkProps={{
            id: "DD_edit_link",
            className:"dd-text-link-override",
            href: "",
            color: FeatureFlagUtils.shouldShowTertiaryButton()
              ? Link.Colors.tertiary
              : Link.Colors.default,
            onClick: (e) => {
              e.preventDefault();
              this.setState({
                showSaveChangesButton: true,
              });
            },
          }}
          saveChangesButtonProps={{
            id: "DD_saveDdChangesButton",
            onClick: () => {
              this.saveDdChanges();
            },
          }}
        />
        <BankingInfoModal
          open={this.state.isBankingInfoModalOpen}
          onClose={() => {
            this.setState({
              isBankingInfoModalOpen: false,
            });
          }}
          locale={
            this.state.isUsCountry
              ? BankingInfoModal.Locale.us
              : BankingInfoModal.Locale.ca
          }
        />
      </div>
    );
  }
  getDdInfo(country, response) {
    return country.toLowerCase() === "us"
      ? response.usDirectDeposit
      : response.caDirectDeposit;
  }
  getDirectDepositDetails(message) {
    axios
      .processPost(ApiConstants.URL_GET_DIRECT_DEPOSIT_CLAIM, this.cRef, {
        busRelAccount: AppUtils.getBusRelAccount(),
        country: AppUtils.getCountryCode(),
      })
      .then((res) => {
        const response = res.data;
        const country = response.country;
        this.setState({
          customerAddress: response.customerAddress,
          busRelAccount: response.busRelAccount,
          country: response.country,
          ddInfo: this.getDdInfo(country, response)
            ? this.getDdInfo(country, response)
            : this.state.ddInfo,
          showSaveChangesButton:
            !response.usDirectDeposit && !response.caDirectDeposit,
        });
        if (message) {
          this.setState({
            successMessage: message,
            errorMessage: ""
          });
        }
      });
  }
  componentDidMount() {
    this.getDirectDepositDetails();
  }
  handleDdInfoInputChange = (e) => {
    let ddInfo = { ...this.state.ddInfo };
    ddInfo[e.target.name] = e.target.value;
    this.setState({
      ddInfo,
    });
  };
  isValidRequest(requestData) {
    const errors = {
      ...PaymentUtils.getDefaultDdErrors(),
    };
    const ddInfo = requestData.ddInfo;
    if (ddInfo) {
      if (AppUtils.isEmptyString(ddInfo.accountHolderName)) {
        errors.accountNameError = true;
      }
      if (AppUtils.isEmptyString(ddInfo.bankName)) {
        errors.bankNameError = true;
      }
      if (AppUtils.isEmptyString(ddInfo.routingNumber)) {
        errors.routingNumberError = true;
      }
      if (AppUtils.isEmptyString(ddInfo.accountNumber)) {
        errors.accountNumberError = true;
      }
      if (AppUtils.isEmptyString(ddInfo.institutionNumber)) {
        errors.institutionNumberError = true;
      }
      if (AppUtils.isEmptyString(ddInfo.transitNumber)) {
        errors.transitNumberError = true;
      }
    }
    if (this.state.isUsCountry) {
      delete errors.transitNumberError;
      delete errors.institutionNumberError;
    } else {
      delete errors.routingNumberError;
    }

    this.setState({
      errors: { ...errors },
    });

    let isValid = true;
    for (let key in errors) {
      if (errors[key]) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }
  saveDdChanges() {
    const customerAddress = { ...this.state.customerAddress };
    customerAddress.addressLine1 = customerAddress.street;
    const requestData = {
      emailAddress: AppUtils.getUserEmail(),
      ddInfo: this.state.ddInfo,
      billingAddress: customerAddress,
      animalId: null,
      productType: null,
      paymentMethod: null,
      ccInfo: null,
    };
    if (!this.isValidRequest(requestData)) {
      return;
    }
    axios
      .processPost(ApiConstants.URL_UPDATE_CARD_INFO, this.cRef, requestData)
      .then((response) => {
        this.getDirectDepositDetails(response.data);
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
            successMessage: "",
          });
          DataLayerUtils.formErrorEvent("Direct deposit", null, errors[0]);
        }
      });
  }
}
export default DirectDepositContainer;

import React from 'react'
import PropTypes from 'prop-types'

import { Paper, PaperHeadline, PaperContent, Button, Text } from '../../'

import { PUBLIC_URL } from '../../constants'

export class StartInsuranceCard extends React.PureComponent {
  static propTypes = {
    getStartedButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { getStartedButtonProps, id, className, style, refProp } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={className}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Insurance</PaperHeadline>
        <PaperContent
          className='ui-start-insurance-card__content'
          backgroundImg={{
            src: PUBLIC_URL + 'fadeImg2.png',
            position: 'right'
          }}
        >
          <Text type={Text.Types.headlineBold}>Insurance you can count on</Text>
          <Text>
            Let us help make cost less of an obstacle, ensuring you can do your
            best for your pet.
          </Text>
          <Button color={Button.Colors.tertiary} {...getStartedButtonProps}>
            Get started
          </Button>
        </PaperContent>
      </Paper>
    )
  }
}

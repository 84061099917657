import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
    FoundPetMessageCard,
    PageContent,
    IncomingTransferMessageCard,
    OutgoingTransferMessageCard,
    ConfirmTransferPetModal,
    CancelTransferPetModal,
    FoundPetAlertModal,
    PetHasInsuranceModal
} from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { AppUtils } from "../../../utils/app-utils";
import { ApiConstants } from "../../../utils/api-constants";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import moment from "moment";
import { LoadMask } from "../../../utils/loadmask.util";
import { AppConstants } from "../../../utils/app-constants";
import { withRouter } from "react-router-dom";
import { showButtonLoader, hideButtonLoader } from "../../../utils/buttonLoader-util";

class NotificationDetails extends PureComponent {
    constructor(props) {
      super(props);
        this.state = {
            isFoundPetAlertModalOpen: false,
            isConfirmTransferPetModalOpen: false,
            isRejectTransferPetModalOpen: false,
            isCancelTransferPetModalOpen: false,
            cancelAlertOptions: FeatureFlagUtils.getCancelFoundPetAlertOptions(),
            selectedOptionValue: "",
            notifications: this.props.location.state.notifications,
            messageBody: "",
            date: "",
            isAlertTurnedOffSubmitted: false,
            otherContactDetails: [],
            cancelAlertError: '',
            notificationid: '',
            isTransferAccepted: false,
            isTransferRejected: false,
            isRemovePetHasInsuranceDialogOpen: false,
            isTransferCancelled: false,
            initiatorBRN: "",
            initiatorName: "",
            recipientBRN: "",
            recipientName: "",
        };
      this.cRef = React.createRef();
    }

    
    defaultOptionValues(){
        this.setState({
            cancelAlertOptions: FeatureFlagUtils.getCancelFoundPetAlertOptions(),
        })
    }

    optionSelectHandler(index, label){
        let cancelAlertOptions = FeatureFlagUtils.getCancelFoundPetAlertOptions();
        let selectedOptionValue = cancelAlertOptions[index].value;
        cancelAlertOptions[index]["active"] = true;
        this.setState({
            cancelAlertOptions,
            selectedOptionValue
        })
    }

    getFormattedName = (name) => {
        let formattedName = name;
        formattedName = formattedName.split(" ");
        if(formattedName.length === 1) {
          return formattedName[0];
        }

        return `${formattedName[0]} ${formattedName[1].charAt(0)}.`
    }

    getMessage(message){
        if(message){
           return <div className="communciationMessageBody" dangerouslySetInnerHTML={{__html: message}} ></div>;
        }
        return "";
    }

    getFormattedDetails(contact){
        let details = {};
        details["type"] = AppConstants.RECIPIENT_TYPE[contact.recipientType];
        details['title'] = [];
        if(contact.emailMessage){
            let time = moment(contact?.emailDate).format("hh:mm A");
            details['title'].push(`${contact?.emailId} at ${time} Email`);
        }
        if(contact.smsMessage){
            let time =  moment(contact?.smsDate).format("hh:mm A");
            details['title'].push(`${contact.smsPhoneNumber} at ${time} Text Message`);
        }
        if(contact.phoneCallMessage){
            let time =  moment(contact?.phoneCallDate).format("hh:mm A");
            details['title'].push(`${contact.phoneCallPhoneNumber} at ${time} Phone Call`);
        }

        return details;
    }

    getContactedPersonDetails(communications){
        let contactedPersonDetails = [];
        communications.forEach( (communication, index) => {
            contactedPersonDetails.push(this.getFormattedDetails(communication));
        })
        return contactedPersonDetails;
    }

    getNotifications(){
        const divRef = this.cRef.current;
        LoadMask.showPreloader(divRef);
        axios
        .processGet(
         `${ApiConstants.URL_GET_NOTIFICATIONS}?brAccount=${AppUtils.getBusRelAccount()}`,
          null
        )
        .then((response) => {
          if(!Array.isArray(response)){
            this.setState({
              notifications: [],
            })
            return;
          }
          const petID = this.props.location.state.selectedPetId;
          const notifications = (!petID)
                                ? response
                                : response.filter( (value) => value.animalId === petID)
          this.setState({
            notifications,
          })
        })
        .catch((err) => {})
        .finally(() => {
          LoadMask.hidePreloader(divRef);
        });
    }

    getNotificationDetails(id) {
        const filtered_notification = this.state.notifications.filter((value) => (value.communicationGroupId === id && value.notificationCategoryId === 2) )

        this.setState({
            initiatorBRN: filtered_notification[0]?.initiatorBrn,
            initiatorName: filtered_notification[0]?.initiatorName,
            recipientBRN: filtered_notification[0]?.targetBrn,
            recipientName: filtered_notification[0]?.targetAccountName,
        })
        const divRef = this.cRef.current;
        LoadMask.showPreloader(divRef);
        axios
        .processGet(
          `${ApiConstants.URL_GET_NOTIFICATION_DETAILS}/${id}`
        )
        .then((response) => {
          const state = {
              date: response.communicationDate,
              messageBody: this.getMessage(response.message),
              otherContactDetails: this.getContactedPersonDetails(response.communications),
              notificationid: response.notificationRequestId,
          }

          this.setState({
            ...state,
          })
          LoadMask.hidePreloader(divRef);
        })
        .catch((err) => {
          LoadMask.hidePreloader(divRef);
        });
    }

    checkIsAlertCancelled(notification){
        if( notification.status === 'A' ||
            notification.status === 'N' ||
            notification.status === 'P'
        ){
            return true;
        }

        return false;

    }

    getAlertMessage(type, status, date) {
        if (AppUtils.isEqualString(type, "TransferIncomingPush")) {
            if (status === 'S' || status === 'R') {
                return `${AppConstants.ALERT_INCOMING_TRANSFER_PUSH[status]} ${moment(date).format("MM/DD/YYYY")}`
            }

            if (status === 'C') {
                return `${this.getFormattedName(this.state.initiatorName)} ${AppConstants.ALERT_INCOMING_TRANSFER_PUSH[status]} ${moment(date).format("MM/DD/YYYY")}`
            }

            return AppConstants.ALERT_INCOMING_TRANSFER_PUSH[status];
        }

        if (AppUtils.isEqualString(type, "TransferOutgoingPush")) {
            if (status === 'C') {
                return `${AppConstants.ALERT_OUTGOING_TRANSFER_PUSH[status]} ${moment(date).format("MM/DD/YYYY")}`
            }
            if (status === 'S' || status === 'R') {
                return `${this.getFormattedName(this.state.recipientName)} ${AppConstants.ALERT_OUTGOING_TRANSFER_PUSH[status]} ${moment(date).format("MM/DD/YYYY")}`
            }

            return AppConstants.ALERT_OUTGOING_TRANSFER_PUSH[status];
        }

        if (AppUtils.isEqualString(type, "FoundPet")) {
            if(status === 'C'){
                return `${AppConstants.ALERT_MESSAGES_FOUND_PET[status]} ${moment(date).format("MM/DD/YYYY")}`
            }

            return AppConstants.ALERT_MESSAGES_FOUND_PET[status];
        } 
    }

    componentDidMount() {
        this.getNotificationDetails(this.props.match.params.id);
    }

    cancelNotification = (notificationid, elementId) => {
        this.setState({
            cancelAlertError: ""
        });

        const filtered_notification = this.state.notifications.filter((value) => value.notificationRequestId === notificationid)

        if ( this.props.selectedAnimal.insurances[0]?.policynumber.length && (
            this.state.selectedOptionValue === 11 ||
            this.state.selectedOptionValue === 3 ||
            this.state.selectedOptionValue === 18
        )) {
            let hasActiveInsurance = (this.props.selectedAnimal.insurances[0]?.policynumber.length && filtered_notification[0].status === 'A') ? true : false;
            this.setState({
                isFoundPetAlertModalOpen: false,
                cancelAlertOptions: FeatureFlagUtils.getCancelFoundPetAlertOptions(),
                isRemovePetHasInsuranceDialogOpen: hasActiveInsurance
            })
            return;
        }

        if (this.state.selectedOptionValue === "") {
            this.setState({
                cancelAlertError: "Please select atleast one option."
            });
        }
        const payload = {
            notificationRequestId: notificationid,
            reason: this.state.selectedOptionValue,
            busRelAccount: AppUtils.getBusRelAccount(),
            animalId: filtered_notification[0].animalId,
            chipNumber: filtered_notification[0].chipNumber,
            action : 'cancel'
        }

        const closeFoundPetAlert = document.getElementById(elementId)
        showButtonLoader(closeFoundPetAlert);

        axios
            .processPost(ApiConstants.URL_CANCEL_LOST_PET_ALERTS, null, payload)
            .then((response) => {
                this.setState({
                    isAlertTurnedOffSubmitted: true
                })
                hideButtonLoader(closeFoundPetAlert);
            })
            .catch((err) => {
                hideButtonLoader(closeFoundPetAlert);
        });
    }

    confirmCancelTransferPet = (action, elementId) => {
        const payload = {
            "reason": "",
            "notificationRequestId": this.state.notificationid,
            "action": action,
            "brAccount": AppUtils.getBusRelAccount()
        }

        const transferButton = document.getElementById(elementId)
        showButtonLoader(transferButton);

        axios
            .processPut(ApiConstants.URL_PUT_ACCEPT_CANCEL_NOTIFICATION_ALERT, null, payload)
            .then((response) => {
                const isTransferAccepted = (AppUtils.isEqualString(action, "accept")) ? true : false;
                const isTransferRejected = (AppUtils.isEqualString(action, "reject")) ? true : false;
                const isTransferCancelled = (AppUtils.isEqualString(action, "cancel")) ? true : false;
                this.setState({
                    isTransferAccepted,
                    isTransferRejected,
                    isTransferCancelled,
                })
                hideButtonLoader(transferButton);
            })
            .catch((err) => {
                hideButtonLoader(transferButton);
            });
    }

    getFoundPetMessageCard = ( notification ) => {
        const selectedPetId = this.props.location.state.selectedPetId;
        const isAlertTurnedOff =  this.checkIsAlertCancelled(notification[0]);
        return (
            <FoundPetMessageCard
                date= {moment(this.state.date).format("MM/DD/YYYY")}
                foundPetAlertMessage= {this.state.messageBody}
                otherContactDetails= {this.state.otherContactDetails}
                showTurnOffAlertButton= { isAlertTurnedOff }
                alertMessage= { (!isAlertTurnedOff)? this.getAlertMessage("FoundPet", notification[0].status, notification[0].statusOn) : ''}
                turnOffAlertButtonProps= {{
                    onClick: () => {
                        this.setState({
                            isFoundPetAlertModalOpen: true,
                        })
                    }
                }}
                goBackLinkProps= {{
                    className: "dd-text-link-override",
                    onClick: () => {
                        this.props.history.push({
                            pathname: "/pages/notifications",
                            state: selectedPetId
                        })
                    }
                }}
            >

            </FoundPetMessageCard>
        )
    }

    getOutgoingTransferPush = (notification) => {
        const selectedPetId = this.props.location.state.selectedPetId;
        const isAlertCancelled =  this.checkIsAlertCancelled(notification[0]);
        return (
            <OutgoingTransferMessageCard
                date={moment(this.state.date).format("MM/DD/YYYY")}
                outgoingTransferAlertMessage= {this.state.messageBody}
                showCancelTransferButton= { isAlertCancelled }
                alertCancelMessage={(!isAlertCancelled) ? this.getAlertMessage("TransferOutgoingPush", notification[0].status, notification[0].statusOn) : ''}
                cancelTransferButtonProp = {{
                    onClick: () => {
                        this.setState({
                            isCancelTransferPetModalOpen: true
                        })
                    }
                }}
                goBackLinkProps= {{
                    className: "dd-text-link-override",
                    onClick: () => {
                        this.props.history.push({
                            pathname: "/pages/notifications",
                            state: selectedPetId
                        })
                    }
                }}
            >
            </OutgoingTransferMessageCard>
        )
    }

    getIncomingTransferPush = (notification) => {
        const selectedPetId = this.props.location.state.selectedPetId;
        const isAlertTurnedOff =  this.checkIsAlertCancelled(notification[0]);
        return (
            <IncomingTransferMessageCard
                date= {moment(this.state.date).format("MM/DD/YYYY")}
                incomingTransferAlertMessage= {this.state.messageBody}
                showTurnOffAlertButton= { isAlertTurnedOff }
                alertCancelMessage={(!isAlertTurnedOff) ? this.getAlertMessage("TransferIncomingPush", notification[0].status, notification[0].statusOn) : ''}
                acceptButtonProps= {{
                    onClick: () => {
                        this.setState({
                            isConfirmTransferPetModalOpen: true
                        })
                    }
                }}
                rejectButtonProps= {{
                    onClick: () => {
                        this.setState({
                            isRejectTransferPetModalOpen: true
                        })
                    }
                }}
                goBackLinkProps= {{
                    className: "dd-text-link-override",
                    onClick: () => {
                        this.props.history.push({
                            pathname: "/pages/notifications",
                            state: selectedPetId
                        })
                    }
                }}
            >
            </IncomingTransferMessageCard>
        )
    }

    getTransferPushCard = (notification) => {
        const isInitiator = AppUtils.isEqualString(this.state.initiatorBRN, AppUtils.getBusRelAccount());
        if ( isInitiator ) {
            return this.getOutgoingTransferPush(notification);
        } else {
            return this.getIncomingTransferPush(notification);
        }
    }
    getMessageCard(type, notification) {
        switch (type) {
            case 2:
                return this.getTransferPushCard(notification);
            case 3:
                return this.getTransferPullCard(notification);
            default:
                return this.getFoundPetMessageCard(notification);
        }
    }

    render() {
      const notification = this.state.notifications.filter((value) => (value.communicationGroupId === this.props.match.params.id))
      return (
        <div ref={this.cRef}>
            <PageContent>
                  {this.getMessageCard(AppConstants.NOTIFICATIONTYPES[notification[0].type], notification)}
            </PageContent>
            <FoundPetAlertModal
                errorMessage= {this.state.cancelAlertError}
                open= {this.state.isFoundPetAlertModalOpen}
                onClose= {() => {
                    this.setState({
                        isFoundPetAlertModalOpen: false
                    })
                    this.defaultOptionValues();
                }}
                alertType="Turn off Found Pet Alert"
                optionsArray={this.state.cancelAlertOptions}
                optionClickedProp = { (index, label) => {
                    this.optionSelectHandler(index, label);
                }}
                turnOffReasonSubmited= { this.state.isAlertTurnedOffSubmitted}
                messageBodyText={
                    (this.state.isAlertTurnedOffSubmitted)
                    ? <div style={{"margin-top": "16px"}}>You have turned off this pet alert.</div>
                    : "Please provide us with context as to why you'd like to turn off this alert."
                }
                closeFoundPetAlertLinkProps= {{
                    className: "dd-text-link-override",
                    onClick: () => {
                        this.setState({
                            isFoundPetAlertModalOpen: false,
                            selectedOptionValue: ""
                        })
                        this.getNotifications();
                        this.getNotificationDetails(this.props.match.params.id);
                    }
                }}
                cancelButtonProps= {{
                    onClick: () => {
                        this.setState({
                            isFoundPetAlertModalOpen: false,
                            cancelAlertError: "",
                        })
                        this.defaultOptionValues();
                    }
                }}
                submitButtonProps={{
                    id: "close_found_pet_alert",
                    onClick: () => {
                        // call method to cancel alert
                        this.cancelNotification(this.state.notificationid, "close_found_pet_alert");
                    }
                }}
            >

            </FoundPetAlertModal>
            <PetHasInsuranceModal
              open={this.state.isRemovePetHasInsuranceDialogOpen}
              onClose={() => {}}
              cancelButtonProps={{
                onClick: () => {
                    this.setState({
                        isRemovePetHasInsuranceDialogOpen: false,
                        isFoundPetAlertModalOpen: false,
                    })
                }
              }}
                  callInMessage={"There is an active insurance policy associated with this pet. Please call 24Petwatch for further assistance 1-877-821-2424"}
            />
            <ConfirmTransferPetModal
                open={this.state.isConfirmTransferPetModalOpen}
                onClose={() => {
                    this.setState({
                    isConfirmTransferPetModalOpen: false,
                    })
                }}
                title={this.state.isTransferAccepted ? "Success" : "Are you sure you want to accept the transfer of this pet?" }
                successText={this.state.isTransferAccepted ? 'You have accepted the pet transfer request successfully.' : ''}
                acceptButtonText={"Accept"}
                cancelButtonText={ this.state.isTransferAccepted ? 'Close' : 'Back'}
                confirmTransferButtonProps={{
                    id:"accept_transfer_pet",
                    onClick: () => {
                        this.confirmCancelTransferPet("accept", "accept_transfer_pet");
                    }
                }}
                cancelTransferButtonProps = {{
                    onClick: () => {
                        if (this.state.isTransferAccepted) {
                            this.getNotifications();
                            this.getNotificationDetails(this.props.match.params.id);
                        }
                        this.setState({
                            isConfirmTransferPetModalOpen: false,
                            isTransferAccepted: false,
                        })
                    }
                }}
            />
            <CancelTransferPetModal
                id="reject_transfer_pet"
                open={this.state.isRejectTransferPetModalOpen}
                onClose={() => {
                  this.setState({
                    isRejectTransferPetModalOpen: false,
                  })
                }}
                title={this.state.isTransferRejected ? "Success" : "Are you sure you want to reject the transfer of this pet?" }
                successText={this.state.isTransferRejected ? 'You have rejected the pet transfer request successfully.' : ''}
                rejectButtonText={"Reject"}
                cancelButtonText={ this.state.isTransferRejected ? 'Close' : 'Back'}
                cancelTransferButtonProps={{
                  id:"reject_transfer_pet",
                  onClick: () => {
                    this.confirmCancelTransferPet("reject", "reject_transfer_pet");
                  }
                }}
                backButtonProps = {{
                    onClick: () => {
                        if (this.state.isTransferRejected) {
                            this.getNotifications();
                            this.getNotificationDetails(this.props.match.params.id);
                        }
                        this.setState({
                            isRejectTransferPetModalOpen: false,
                            isTransferRejected: false
                        })
                    }
                }}
            />
            <CancelTransferPetModal
                id="cancel_transfer_pet"
                open={this.state.isCancelTransferPetModalOpen}
                onClose={() => {
                  this.setState({
                    isCancelTransferPetModalOpen: false,
                  })
                }}
                title={this.state.isTransferCancelled ? "Success" : "Are you sure you want to cancel the transfer of this pet?" }
                successText={this.state.isTransferCancelled ? 'You have cancelled the transfer request of this pet successfully.' : ''}
                rejectButtonText={"Yes"}
                cancelButtonText={ this.state.isTransferCancelled ? 'Close' : 'Back'}
                cancelTransferButtonProps={{
                  id:"cancel_transfer_pet",
                  onClick: () => {
                    this.confirmCancelTransferPet("cancel", "cancel_transfer_pet");
                  }
                }}
                backButtonProps = {{
                    onClick: () => {
                        if (this.state.isTransferCancelled) {
                            this.getNotifications();
                            this.getNotificationDetails(this.props.match.params.id);
                        }
                        this.setState({
                            isCancelTransferPetModalOpen: false,
                            isTransferCancelled: false
                        })
                    }
                }}
            />
        </div>
      );
    }
}

const mapStateToProps = (state) => {
    const customerProfileStore = state.customerProfileStore;
    return {
      selectedAnimal: customerProfileStore.selectedAnimal,
    };
};

export default connect(mapStateToProps)(withRouter(NotificationDetails));



import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Button, Text, Icon } from '../../'
import {
  LogoSvg,
  PageHeaderProps,
  PageHeaderButton
} from './PageHeaderComponents'

const DesktopPageHeader = ({
  logo,
  onLogoClick,
  showDropDown,
  showPetMenu,
  petMenuProps,
  showAccount,
  accountMenuProps,
  submitClaimButtonProps,
  showSubmitClaim,
  showReportLostPetButton,
  showPetProPortalButton,
  petProPortalButtonProps,
  reportLostPetButtonProps,
  reportLostPetButtonText,
  id,
  className,
  style,
  refProp
}) => {
  
  const { nameOnAccount, ...restAccountProps } = accountMenuProps;
  return (
    <div
      id={id}
      className={cn('ui-page-header', className)}
      style={style}
      ref={refProp}
    >
      <div className='ui-page-header__logo' role='button' onClick={onLogoClick}>
        {logo ? logo : <LogoSvg />}
      </div>

      <div className='ui-page-header__items'>
        {showPetMenu && <PetMenu {...petMenuProps} />}

        {(showAccount && showDropDown) && <AccountMenu {...accountMenuProps} />}

        {(showAccount && !showDropDown) && (<div className={cn('ui-page-header__item')}>
              <PageHeaderButton
                leftIconType='account_circle'
                onClick={() => {
                  restAccountProps.onAccountClick()
                }}
                {...restAccountProps}
              >
                {nameOnAccount || 'Account'}
              </PageHeaderButton>
            </div> )
        }

        {showSubmitClaim && (
          <div className={cn('ui-page-header__item')}>
            <Button
              color={Button.Colors.success}
              size={Button.Sizes.small}
              {...submitClaimButtonProps}
            >
              Submit a claim
            </Button>
          </div>
        )}

        {showPetProPortalButton && (
          <div className={cn('ui-page-header__item')}>
            <Button
              variant={Button.Variants.outlined}
              size={Button.Sizes.small}
              className={"ui-page-header__petpro-portal"}
              {...petProPortalButtonProps}
            >
              <span className='ui-page-header__petpro-text'>Pet Professionals</span><Icon type="open_in_new"></Icon>
            </Button>
          </div>
        )}

        {showReportLostPetButton && (
          <div className={cn('ui-page-header__item')}>
            <Button
              color={Button.Colors.danger}
              size={Button.Sizes.small}
              {...reportLostPetButtonProps}
            >
              {reportLostPetButtonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
DesktopPageHeader.propTypes = PageHeaderProps

export default DesktopPageHeader

const PetMenu = ({ id, onMenuClick, ...rest }) => {
  const [showMenu, setShowMenu] = React.useState(false)

  const menuContainerRef = React.useRef()

  const handleClick = React.useCallback(
    (e) => !menuContainerRef?.current?.contains(e.target) && setShowMenu(false),
    [menuContainerRef, setShowMenu]
  )

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)

    return () => document.removeEventListener('mousedown', handleClick, false)
  }, [handleClick])

  return (
    <div
      id={id}
      className={cn('ui-page-header__item', 'ui-page-header__item__pet-menu')}
      ref={menuContainerRef}
      onMouseLeave={() => setShowMenu(false)}
    >
      <PageHeaderButton
        mouseEnter={() => setShowMenu(true)}
        onClick={() => {
          setShowMenu(!showMenu)
          onMenuClick?.(showMenu)
        }}
        leftIconType='pets'
        rightIconType='arrow_drop_down'
      >
        My Pets
      </PageHeaderButton>

      {showMenu && <Menu {...rest} />}
    </div>
  )
}
PetMenu.propTypes = {
  onMenuClick: PropTypes.func,
  id: PropTypes.string
}

const Menu = ({
  selectedPetId,
  pets = [],
  newPetButtonProps,
  onSelectedPetChange
}) => {
  return (
    <div className='ui-page-header__pet-menu'>
      <div className='ui-page-header__pet-menu__list__container'>
        <div className='ui-page-header__pet-menu__list__title'>
          <Text type={Text.Types.caption}>
            {pets.length ? 'My Pets' : 'No pets yet'}
          </Text>
        </div>

        <div className='ui-page-header__pet-menu__list--scrollable'>
          {pets.map(({ id, ...restPet }) => (
            <MenuItem
              onClick={() => onSelectedPetChange(id)}
              selected={id === selectedPetId}
              key={id}
              id={`menu${id}`}
              showNotificationMark={false}
              {...restPet}
            />
          ))}
        </div>
      </div>

      <div className='ui-page-header__item__pet-menu__add-new-action'>
        <PageHeaderButton {...newPetButtonProps} leftIconType='pets'>
          Add another pet
        </PageHeaderButton>
      </div>
    </div>
  )
}
Menu.propTypes = {
  onSelectedPetChange: PropTypes.func,
  newPetButtonProps: PropTypes.shape({
    onClick: PropTypes.func,
    id: PropTypes.string
  }),
  selectedPetId: PropTypes.string,
  pets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  )
}

const MenuItem = ({ id, name, selected, onClick, showNotificationMark }) => (
  <div
    className={cn('ui-page-header__pet-menu__list__item', {
      'ui-page-header__pet-menu__list__item--selected': selected
    })}
  >
    <PageHeaderButton
      onClick={onClick}
      id={id}
      leftIconType={selected ? 'check' : null}
    >
      <div>
        {name}
        {name === 'Notifications' && showNotificationMark && (
            <div className='ui-page-header-desktop-notification-mark_'></div>
        )}
      </div>
    </PageHeaderButton>
  </div>
)
MenuItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  showNotificationMark: PropTypes.bool
}



const AccountMenu = ({
  nameOnAccount,
  id,
  onAccountClick,
  ...rest
}) => {
  const [ showAccountMenu, setShowAccountMenu ] = React.useState(false)
  const accountMenuContainerRef = React.useRef()

  const handleAccountClick = React.useCallback(
    (event) => !accountMenuContainerRef?.current?.contains(event.target) && setShowAccountMenu(false),
    [accountMenuContainerRef, setShowAccountMenu]
  )

  React.useEffect(() => {
    document.addEventListener('mousedown', handleAccountClick, false)

    return () => document.removeEventListener('mousedown', handleAccountClick, false)
  }, [ handleAccountClick ])
  
  const handleButtonClick = () => {
    setShowAccountMenu(!showAccountMenu)
    onAccountClick?.(showAccountMenu)
  }
  return (
    <div
      id={id}
      className={ cn('ui-page-header__item', 'ui-page-header__item__pet-menu') }
      ref={accountMenuContainerRef}
      onMouseLeave={() => setShowAccountMenu(false)}
    >
      <PageHeaderButton
        mouseEnter={() => setShowAccountMenu(true)}
        onClick={handleButtonClick}
        leftIconType='account_circle'
        rightIconType='arrow_drop_down'
      >
        {nameOnAccount}
        {rest.showNotificationMark && <div className="ui-page-header-notification-mark"></div> }
      </PageHeaderButton>
      {showAccountMenu && <AccountMenuList {...rest}/>}
    </div>
  )
}
AccountMenu.propTypes = {
  id: PropTypes.string,
  onAccountClick:PropTypes.func,
  nameOnAccount: PropTypes.string,
}

const AccountMenuList = ({
  selectedItemId,
  onSelectedItemChange,
  items = [],
  ...rest
}) => {
  return (
    <div className='ui-page-header__pet-menu'>
      <div className='ui-page-header__pet-menu__list__container'>
        <div className='ui-page-header__pet-menu__list--scrollable'>
          {items.map(({ id, ...restItem }) => (
            <MenuItem
              onClick={() => onSelectedItemChange(id)}
              selected={id === selectedItemId}
              key={id}
              id={id}
              showNotificationMark={rest.showNotificationMark}
              {...restItem}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
AccountMenuList.propTypes = {
  onSelectedItemChange: PropTypes.func,
  selectedItemId: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  showNotificationMark: PropTypes.bool,
}
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text, Icon } from '../..'

export const PageHeaderProps = {
  onLogoClick: PropTypes.func,

  showPetMenu: PropTypes.bool,
  petMenuProps: PropTypes.shape({
    onMenuClick: PropTypes.func,
    elementId: PropTypes.string,
    pets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    onSelectedPetChange: PropTypes.func,
    selectedPetId: PropTypes.string,
    newPetButtonProps: PropTypes.shape({
      onClick: PropTypes.func,
      id: PropTypes.string
    })
  }),

  showAccount: PropTypes.bool,
  accountButtonProps: PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func,
    nameOnAccount: PropTypes.string
  }),

  showSubmitClaim: PropTypes.bool,
  submitClaimButtonProps: PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func
  }),

  showReportLostPetButton: PropTypes.bool,
  reportLostPetButtonProps: PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func
  }),

  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
}

// link-like button with icon designed for PageHeader
export const PageHeaderButton = ({
  children,
  leftIconType,
  rightIconType,
  id,
  className,
  onClick
}) => (
  <button
    id={id}
    className={cn('ui-page-header__page-header-button', className)}
    onClick={onClick}
  >
    {leftIconType && (
      <Icon
        className='ui-page-header__page-header-button__icon--left'
        type={leftIconType}
      />
    )}
    <Text type={Text.Types.button}>{children}</Text>
    {rightIconType && (
      <Icon
        className='ui-page-header__page-header-button__icon--right'
        type={rightIconType}
      />
    )}
  </button>
)
PageHeaderButton.propTypes = {
  children: PropTypes.any,
  leftIconType: PropTypes.string,
  rightIconType: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
}

export const LogoSvg = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="375"
    height="80.74"
    version="1.1"
    viewBox="0 0 1414.96 169.41333"
  >
    <g transform="matrix(1.33333 0 0 -1.33333 0 139.413)">
      <g transform="scale(.1)">
        <path
          fill="#66686d"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M4582.79 130.152l-200.25 551.703c-4.09 8.172-8.17 16.344-8.17 28.606 0 24.523 20.43 44.953 49.04 44.953 24.52 0 40.87-16.344 49.04-40.863l163.47-490.406 167.55 494.492c8.18 24.519 24.52 40.867 49.04 40.867h4.09c24.52 0 44.95-16.348 49.04-40.867l163.47-494.492 167.55 494.492c8.18 20.433 20.44 36.777 44.96 36.777 28.6 0 49.04-20.43 49.04-44.953 0-8.172-4.09-20.434-8.18-28.606l-200.24-555.792c-8.18-28.606-28.61-44.954-53.13-44.954h4.08c-24.52 0-44.95 16.348-53.12 44.954L4856.6 604.207l-163.47-478.144c-8.17-28.606-28.61-44.954-53.13-44.954h-4.08c-20.44 4.086-40.87 20.434-53.13 49.043"
        ></path>
        <path
          fill="#66686d"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M5845.58 334.484v61.301c-49.04 12.262-114.43 28.61-192.08 28.61-122.6 0-192.07-53.129-192.07-134.864v-4.086c0-81.734 77.65-130.773 163.47-130.773 122.6 4.086 220.68 77.644 220.68 179.812m-482.23-49.039c0 138.946 114.43 212.508 277.89 212.508 81.74 0 143.04-12.258 200.25-28.605v24.519c0 118.512-73.56 179.813-196.16 179.813-65.39 0-122.6-16.348-171.64-36.778-8.17-4.089-12.26-4.089-16.35-4.089-24.52 0-44.95 20.433-44.95 40.867 0 20.433 12.26 36.781 24.52 40.867 65.39 28.609 130.77 44.957 212.51 44.957 93.99 0 163.47-24.524 212.51-73.563 44.95-44.953 65.38-106.253 65.38-192.074V134.238c0-28.609-20.43-49.043-44.95-49.043-28.61 0-44.95 20.434-44.95 44.957v61.297c-44.96-61.297-122.61-114.426-237.03-114.426-114.43 4.086-237.03 73.563-237.03 208.422"
        ></path>
        <path
          fill="#66686d"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M6098.95 269.098v396.41h-53.12c-24.52 0-44.96 20.433-44.96 44.953 0 24.523 20.44 44.953 44.96 44.953h53.12v155.297c0 24.519 20.44 49.039 49.04 49.039 24.53 0 49.05-24.52 49.05-49.039V755.414h171.64c24.52 0 44.95-20.43 44.95-44.953 0-24.52-20.43-44.953-44.95-44.953h-171.64V281.359c0-81.734 44.95-110.339 110.34-110.339 32.69 0 53.12 8.171 61.3 8.171 24.52 0 40.86-20.433 40.86-40.867 0-16.347-12.25-32.691-28.6-36.777-28.61-12.262-57.22-16.348-98.08-16.348-102.17-.004-183.91 49.039-183.91 183.899"
        ></path>
        <path
          fill="#66686d"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M6442.24 420.309c0 187.988 143.03 343.281 335.11 343.281 110.34 0 183.9-40.867 241.11-93.996 8.17-8.172 16.35-20.434 16.35-32.692 0-28.605-20.44-44.953-44.96-44.953-12.26 0-24.52 4.086-32.69 12.258-44.95 44.953-98.08 77.648-179.81 77.648-134.86 0-232.95-114.425-232.95-253.375v-4.089c0-143.032 102.17-253.375 241.12-253.375 73.56 0 134.86 32.695 183.9 81.734 8.17 8.176 16.35 12.262 28.61 12.262 24.52 0 44.95-20.434 44.95-44.957 0-12.258-4.09-24.52-12.26-28.606-61.3-61.301-134.86-106.254-245.2-106.254-200.25-4.086-343.28 151.211-343.28 335.114"
        ></path>
        <path
          fill="#66686d"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M7128.8 976.098c0 28.602 20.43 49.042 49.04 49.042s49.04-24.52 49.04-49.042V636.902c44.96 69.473 110.34 126.688 224.77 126.688 159.38 0 249.29-106.258 249.29-261.551V138.324c0-28.605-20.43-49.039-49.04-49.039s-49.04 20.434-49.04 49.039V477.52c0 122.601-65.39 196.16-179.82 196.16-114.42 0-196.16-81.735-196.16-204.332V138.324c0-28.609-20.43-49.043-49.04-49.043s-49.04 20.434-49.04 49.043v837.774"
        ></path>
        <path
          fill="#3b393d"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M1064.14 236.406l269.73 228.856c118.51 102.168 163.46 155.293 163.46 237.027 0 85.82-61.3 134.859-138.94 134.859-69.48 0-118.52-32.691-171.64-98.082-12.26-12.257-28.61-24.519-53.13-24.519-36.78 0-69.48 32.695-69.48 69.476 0 16.344 8.18 32.692 16.35 49.04 69.48 89.91 151.21 138.949 290.16 138.949 171.64 0 286.07-106.254 286.07-261.551v-4.086c0-138.945-73.56-212.508-228.86-339.195l-179.81-151.207h355.54c36.78 0 65.39-28.61 65.39-65.387 0-36.781-28.61-65.39-65.39-65.39H1109.1c-49.04 0-81.74 28.609-81.74 73.562-4.08 32.691 8.18 57.215 36.78 77.648"
        ></path>
        <path
          fill="#3b393d"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M2147.12 408.047v343.281l-286.07-343.281zm0-126.688h-388.24c-40.86 0-73.56 28.606-73.56 73.559 0 28.605 12.26 53.125 32.7 81.734l408.67 486.317c28.6 32.691 49.04 49.039 93.99 49.039 40.87 0 73.56-32.692 73.56-73.559V408.047h65.39c36.78 0 65.38-28.609 65.38-65.391 0-36.777-28.6-61.297-65.38-61.297h-65.39V150.582c0-40.867-32.69-73.559-73.56-73.559-40.87 0-73.56 32.692-73.56 73.559v130.777"
        ></path>
        <path
          fill="#3b393d"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M2854.12 506.125c114.42 0 187.99 65.391 187.99 155.297v4.086c0 102.168-73.56 155.293-187.99 155.293H2674.3V506.125h179.82zM2523.1 882.102c0 44.957 32.69 77.648 77.64 77.648h265.64c204.33 0 331.02-114.426 331.02-290.156v-4.086c0-196.16-155.29-298.328-347.37-298.328h-171.64V154.672c0-40.867-32.69-77.649-77.65-77.649-44.95 0-77.64 32.692-77.64 77.649v727.43"
        ></path>
        <path
          fill="#3b393d"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M3708.24 461.172c-8.18 98.082-65.39 171.644-167.56 171.644-93.99 0-155.29-69.476-171.64-171.644zM3557.03 72.937c-192.08 0-339.2 138.95-339.2 343.282v4.09c0 187.988 134.86 343.281 322.85 343.281 208.42 0 314.68-171.645 314.68-322.852 0-40.867-32.69-73.558-69.48-73.558h-412.75c16.34-106.254 93.99-167.555 187.99-167.555 65.38 0 114.42 20.434 155.29 57.215 12.26 8.172 20.43 12.258 36.78 12.258 32.69 0 61.3-24.52 61.3-61.301 0-20.434-8.17-36.777-20.43-44.953-61.3-57.215-134.86-89.907-237.03-89.907"
        ></path>
        <path
          fill="#3b393d"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M3965.7 269.098v347.371h-24.52c-36.78 0-65.39 28.605-65.39 65.386 0 36.782 28.61 65.387 65.39 65.387h24.52v110.34c0 40.867 32.69 73.563 77.65 73.563 40.86 0 73.56-32.696 73.56-73.563v-110.34h118.51c36.78 0 65.39-28.605 65.39-65.387 0-36.781-28.61-65.386-65.39-65.386h-118.51V293.617c0-57.211 28.6-81.73 81.73-81.73 16.35 0 32.69 4.086 36.78 4.086 32.69 0 65.39-28.61 65.39-61.301 0-28.609-20.44-49.043-40.87-57.215-32.69-12.258-65.39-16.348-102.17-16.348-114.42-4.086-192.07 44.954-192.07 187.989"
        ></path>
        <path
          fill="#d61648"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M688.168 481.605c-44.953-65.386-4.086-138.945 49.043-155.292 61.297-16.344 114.426 0 147.117 61.3 24.524 40.867 16.348 93.992-20.433 122.602-53.125 44.953-138.946 28.605-175.727-28.61"
        ></path>
        <path
          fill="#d61648"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M103.77 922.969c-36.778-53.125-24.52-143.035 40.867-179.813 28.605-16.347 85.824-8.176 106.254 8.172 36.781 32.695 53.129 73.563 44.957 122.602-8.176 44.953-44.957 85.82-85.825 93.992-40.867 8.176-81.734-8.172-106.253-44.953"
        ></path>
        <path
          fill="#d61648"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M851.637 673.68c4.086 4.09 8.172 12.261 8.172 16.347 20.433 44.953 20.433 89.907-8.172 130.774-28.61 40.867-69.477 53.129-118.516 44.957-61.301-8.176-114.426-65.391-122.601-130.778-8.172-69.472 44.957-130.773 114.429-130.773 57.211 4.086 98.078 28.606 126.688 69.473"
        ></path>
        <path
          fill="#d61648"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M581.914 841.234c16.348 24.524 24.52 57.215 20.434 93.996-8.172 49.04-32.696 85.82-77.649 102.17-44.953 16.34-85.82 8.17-118.511-24.52-44.954-40.872-61.301-89.911-49.04-151.212 8.172-40.867 32.692-69.473 69.473-85.82 53.125-20.434 114.426 4.086 155.293 65.386"
        ></path>
        <path
          fill="#d61648"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M561.48 171.02c-12.257-16.348-32.695-32.696-57.21-40.868-24.524-8.175-49.043-4.089-73.563 8.172-24.519 12.262-44.953 32.696-65.387 53.125-28.605 28.61-53.129 65.391-73.562 98.082-8.172 12.258-20.434 28.61-36.778 36.782-12.261 8.171-28.609 12.257-44.957 16.343-28.605 8.176-57.21 16.348-85.82 28.61-28.605 12.257-57.21 24.519-81.734 44.953C22.035 432.566 5.69 448.914 1.602 477.52c-4.086 20.433 0 40.867 8.175 57.214 12.258 24.52 32.692 44.954 53.125 61.301 24.524 16.344 49.043 32.692 77.649 49.039 61.301 28.61 122.601 49.043 192.074 57.215 28.605 4.086 53.129 4.086 81.734 0 24.52-4.09 44.954-12.262 65.387-20.434 40.867-20.433 69.473-49.042 89.906-85.82 20.434-36.781 28.61-77.648 36.782-118.515 4.086-36.782 8.171-73.559 4.086-106.254-4.086-53.125-8.172-102.168-24.52-151.207-4.086-16.348-8.172-28.61-24.52-49.039"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M8938.54 304.137c-22-5.61-46.58 15.957-54.34 47.875-8.2 31.914 3.45 62.535 25.44 68.14 22 5.61 46.58-15.957 54.35-47.871 8.19-31.918-3.45-62.539-25.45-68.144"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M8992.88 232.109c-18.11 1.727-30.62 24.582-27.6 50.895 2.59 26.309 19.84 46.148 37.95 43.988 18.12-1.722 30.63-24.582 27.61-50.89-3.02-26.309-19.84-46.149-37.96-43.993"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M8843.65 289.039c8.2 8.195 19.84 12.508 30.63 12.941h.43c10.78-.433 22.42-4.746 30.62-12.941l99.2-99.195c6.04-6.039 8.19-14.664 6.47-22.86l-15.96-66.851c-2.59-10.781-12.08-18.547-23.29-18.547h-47.44c-12.08 0-22 9.922-22 21.996v43.129c0 15.527-12.51 27.605-27.6 28.035-15.53 0-27.61-12.508-27.61-28.035v-43.129c0-12.074-9.92-21.996-21.99-21.996h-47.88c-11.21 0-20.7 7.766-23.29 18.547l-15.95 66.851c-2.16 8.196.43 16.821 6.47 22.86l99.19 99.195"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M8810.44 304.137c-21.99 5.605-33.21 35.797-25.44 68.144 8.19 31.914 32.34 53.481 54.34 47.871 22-5.605 33.21-35.797 25.45-68.14-8.2-31.918-32.35-53.485-54.35-47.875"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M8746.18 327.426c18.12 1.726 34.94-17.684 37.95-43.992 2.59-26.309-9.48-49.168-27.6-50.895-18.11-1.723-34.93 17.684-37.95 43.992-3.02 25.879 9.49 48.739 27.6 50.895"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M9089.49 120.832h27.17v175.109h-27.17c-.86 0-1.72.864-1.72 1.723v29.762c0 .863.86 1.726 1.72 1.726h114.3c14.66 0 28.89-.863 40.97-6.039 26.31-10.785 43.13-37.523 43.13-71.597 0-34.5-17.68-63.832-46.15-73.75-11.21-3.883-23.72-4.746-37.09-4.746h-43.13v-52.618h27.6c.86 0 1.73-.863 1.73-1.722V88.918c0-.863-.87-1.727-1.73-1.727h-99.2c-.86 0-1.72.864-1.72 1.727v29.762c-.43 1.293.43 2.152 1.29 2.152m109.55 88.418c9.92 0 18.12 1.727 24.59 4.746 13.37 6.469 19.84 20.27 19.84 37.953 0 16.821-6.47 29.758-17.69 36.231-6.47 3.879-15.09 5.605-26.31 5.605h-37.95V209.25h37.52"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M9392.26 265.32c50.03 0 77.64-36.66 77.64-82.379 0-2.589 0-6.039-.43-9.058l-.87-5.606h-120.33c2.59-31.918 25.88-48.304 52.19-48.304 14.66 0 26.74 3.879 35.8 9.054 4.74 2.157 9.05 5.176 12.93 7.766.87.863 2.16.43 3.02-.434l15.1-27.171c.43-.86.43-1.723-.43-2.586-4.75-3.883-10.78-7.333-17.69-10.782-12.07-6.468-29.76-12.078-51.32-12.078-57.79 0-93.59 41.836-93.59 91.004-.43 53.051 35.8 90.574 87.98 90.574m32.78-71.597c-.86 24.152-15.09 38.386-33.64 38.386-22 0-37.95-15.093-41.84-38.386h75.48"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M9509.14 229.09h-24.58c-.86 0-1.72.863-1.72 1.726v28.465c0 .864.86 1.727 1.72 1.727h25.88v45.715c0 .863.86 1.726 1.72 1.726h37.96c.86 0 1.72-.863 1.72-1.726v-45.715h37.53c.86 0 1.72-.863 1.72-1.727v-28.465c0-.863-.86-1.726-1.72-1.726h-37.53v-73.32c0-30.192 22.43-33.641 34.94-33.641 1.29 0 3.88 0 6.04.43 1.29 0 2.15-.864 2.15-1.727V88.918c0-.863-.86-1.727-1.72-1.727-2.16-.43-6.47-.859-11.65-.859-24.58-.434-72.89 7.332-72.89 65.555l.43 77.203"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M9634.22 120.832h27.17v175.109h-27.17c-.86 0-1.72.864-1.72 1.723v29.762c0 .863.86 1.726 1.72 1.726h114.29c14.67 0 28.9-.863 40.98-6.039 26.31-10.785 43.13-37.523 43.13-71.597 0-34.5-17.69-63.832-46.15-73.75-11.21-3.883-23.72-4.746-37.09-4.746h-43.13v-52.618h27.6c.86 0 1.73-.863 1.73-1.722V88.918c0-.863-.87-1.727-1.73-1.727h-99.2c-.86 0-1.72.864-1.72 1.727v29.762c-.43 1.293.43 2.152 1.29 2.152m109.55 88.418c9.92 0 18.11 1.727 24.58 4.746 13.37 6.469 19.84 20.27 19.84 37.953 0 16.821-6.47 29.758-17.68 36.231-6.47 3.879-15.09 5.605-26.31 5.605h-37.95V209.25h37.52"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M9870.14 297.234h-25.01c-.87 0-1.73.864-1.73 1.727v28.465c0 .863.86 1.726 1.73 1.726h68.14V144.988c0-15.961 3.02-23.722 17.68-23.722 1.3 0 4.32 0 6.04.429 1.29 0 2.16-.863 2.16-1.722V88.918c0-.863-.87-1.727-1.73-1.727-2.59-.43-7.33-.859-13.8-.859-27.6 0-53.05 9.055-53.05 56.5v154.402h-.43"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M10244.5 265.32c28.9 0 71.2-10.785 71.2-41.836v-21.996c0-.863-.9-1.726-1.8-1.726h-33.6c-.9 0-1.7.863-1.7 1.726v9.489c0 12.074-17.7 17.25-32.4 17.25-31.9 0-51.3-21.997-51.3-53.481 0-35.797 25-55.207 54.8-55.207 23.3 0 42.2 12.078 52.6 19.84.9.863 2.2.434 3-.859l13.8-27.172c.5-.864.5-1.727-.4-2.16-12.5-11.211-38.4-25.876-71.2-25.876-50.9 0-95.7 34.934-95.7 90.571-.5 51.758 39.2 91.437 92.7 91.437"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M10430.4 265.32c50 0 77.6-36.66 77.6-82.379 0-2.589 0-6.039-.4-9.058l-.9-5.606h-120.3c2.6-31.918 25.9-48.304 52.2-48.304 14.7 0 26.7 3.879 35.8 9.054 4.7 2.157 9 5.176 12.9 7.766.9.863 2.2.43 3-.434l15.1-27.171c.5-.86.5-1.723-.4-2.586-4.7-3.883-10.8-7.333-17.7-10.782-12.1-6.468-29.7-12.078-51.3-12.078-57.8 0-93.6 41.836-93.6 91.004-.4 53.051 35.8 90.574 88 90.574m32.8-71.597c-.9 24.152-15.1 38.386-33.7 38.386-22 0-37.9-15.093-41.8-38.386h75.5"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M10126.3 119.539c-7.7 0-12.5 2.59-15.1 7.332v64.695c0 11.645-1.7 21.997-5.1 30.192-3.5 8.195-8.2 14.664-14.3 19.84-6 5.175-13.3 8.625-21.5 10.781-8.7 2.156-18.2 3.449-28.1 3.449-12.9 0-25.4-2.152-37.1-6.035-10.31-3.449-20.23-8.629-30.15-14.664-.87-.434-1.3-1.727-.87-2.59l11.22-23.719c.43-.863 1.72-1.297 2.58-.863 6.04 3.02 12.08 5.176 18.52 6.898 7.8 2.161 17.3 3.02 27.2 3.02 5.2 0 9.9-.43 13.8-1.293 4.3-.863 8.2-2.59 11.6-4.742 3.5-2.16 6.1-5.18 7.8-8.629 1.7-3.449 2.6-7.762 2.6-12.508v-9.055l-2.6.43c-5.2 1.293-11.2 2.156-17.2 3.02-6.1.863-13 1.293-20.3 1.293-9.9 0-19-1.293-26.8-3.879-7.72-2.59-14.18-6.039-19.36-10.785-5.18-4.313-9.06-9.918-12.08-15.957-2.58-6.469-4.31-13.372-4.31-20.704 0-7.332 1.29-14.234 3.88-20.269a47.648 47.648 0 0111.65-16.39c5.17-4.743 11.64-8.196 19.02-10.782 7.3-2.59 16.3-3.883 25.8-3.883 3.9 0 7.4.434 10.4.864s6 1.292 9 2.156c3.1.863 6.1 2.156 9.1 3.883 3 1.722 6.5 3.878 9.9 6.039l4.3 3.015 4.8 3.02c9.9-15.094 26.3-18.977 44-18.977 6.9 0 11.6.434 14.2.864.9 0 1.7.863 1.7 1.726v31.914c0 1.293-.8 2.156-2.1 1.727-1.8-.434-4.3-.434-6.1-.434m-73.7.434c-6.5-2.59-13.8-3.883-22-3.883s-14.2 2.156-18.1 6.039c-4.3 3.883-6.5 9.059-6.5 15.094 0 3.453.9 6.039 2.2 8.629 1.2 2.586 3 4.742 5.1 6.035 2.2 1.726 4.8 3.019 7.4 3.883 2.6.863 5.1 1.293 8.2 1.293h41.4v-26.309l-1.3-.863c-4.3-3.879-10-7.332-16.4-9.918"
        ></path>
        <g transform="scale(10)">
          <text
            fill="#3d3c41"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            fontFamily="Museo"
            fontSize="26.166"
            fontVariant="normal"
            transform="matrix(1 0 0 -1 824.39 11.9)"
            writingMode="lr-tb"
            xmlSpace="preserve"
            style={{ InkscapeFontSpecification: "Museo-500" }}
          >
            <tspan x="0 15.437469" y="0">
              by
            </tspan>
          </text>
        </g>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M10578.2 121.137c4.7 0 7.4 2.472 7.4 5.984 0 3.918-2.7 5.981-7.4 5.981h-8.9v-11.965zm-15.9 14.648c0 1.856 1.4 3.512 3.5 3.512h13c8 0 14-3.926 14-11.559 0-5.57-3.1-9.492-8-11.347l7-8.254c.8-1.032 1-1.856 1-2.684 0-1.855-1.4-3.504-3.3-3.504-1.4 0-2.4.821-3.3 1.852l-9.5 11.558h-7.4v-9.699c0-2.062-1.6-3.711-3.5-3.711-2.1 0-3.5 1.649-3.5 3.711zm45.8-15.476v.211c0 17.535-13.6 31.98-31.8 31.98-17.9 0-31.7-14.652-31.7-32.191v-.204c0-17.539 13.6-31.984 31.7-31.984 18 0 31.8 14.649 31.8 32.188zm-67.7-.204v.204c0 19.601 15.9 36.109 35.9 36.109 20.2 0 35.9-16.301 35.9-35.898v-.211c0-19.598-15.9-36.11-35.9-36.11-20.2 0-35.9 16.301-35.9 35.906"
        ></path>
        <path
          fill="#3d3c41"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M7895.09 208.039c15.18 0 23.75 7.91 23.75 19.129 0 12.52-8.57 19.113-23.75 19.113h-28.34v-38.242zm-50.75 46.809c0 5.937 4.61 11.218 11.21 11.218h41.53c25.71 0 44.82-12.531 44.82-36.929 0-17.801-9.87-30.332-25.71-36.258l22.41-26.383c2.66-3.297 3.3-5.922 3.3-8.562 0-5.942-4.61-11.207-10.54-11.207-4.61 0-7.9 2.628-10.56 5.925l-30.32 36.93h-23.73V158.59c0-6.598-5.28-11.863-11.2-11.863-6.6 0-11.21 5.265-11.21 11.863zm146.35-49.45v.668c0 56.036-43.51 102.2-101.52 102.2-57.37 0-101.53-46.825-101.53-102.868v-.656c0-56.047 43.51-102.195 101.53-102.195 57.37 0 101.52 46.808 101.52 102.851zm-216.24-.656v.656c0 62.641 50.75 115.387 114.72 115.387 64.61 0 114.71-52.09 114.71-114.719v-.668c0-62.625-50.75-115.386-114.71-115.386-64.61 0-114.72 52.09-114.72 114.73"
        ></path>
      </g>
    </g>
  </svg>
  )
}

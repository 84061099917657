import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { AppUtils } from "../../../utils/app-utils";
import Checkout from "./checkout/checkout";
import NewCardPayment from "./checkout/new-card-payment";
import PaymentCompleteComponent from "./checkout/payment-complete";
import PaymentSettings from "./payment-settings/payment-settings";
import Policy from "./policy/policy";
import {
  saveCustomerProfile,
} from "../../../redux/reducers/customer-profile-reducer";
import { PageHeader } from "../../../UI-Component-Library";
import { PetplaceLogo } from "../../../utils/svg-icons";

class PetplaceContainer extends React.Component {

  getCustomerProfile = () => {
    axios
      .processGet(
        `${ApiConstants.URL_CUSTOMER_PROFILE}${AppUtils.getToken()}`,
        this.cRef
      )
      .then((response) => {
        this.props.saveCustomerProfile(response);
      })
      .catch(() => {});
  };

  componentDidMount(){
    this.getCustomerProfile();
  }
  render() {
    return (
      <React.Fragment>
        <PageHeader
          showMobileMenu={false}
          showAccount= {false}
          logo={<PetplaceLogo />}
          showReportLostPetButton={false}
          reportLostPetButtonProps={{
            id: "auth_report_lost_found_button",
            onClick: () => {},
          }}
          accountMenuProps={{
            id: "account_button",
            items: [],
            showNotificationMark: false,
            closeMenu: () => {}
          }}
          showPetProPortalButton={false}
          petProPortalButtonProps={{
            onClick: () => {}
          }}
          onLogoClick={() => {
            DataLayerUtils.navigationEvent(
              `${window.innerWidth > MOBILE_VIEW_BREAKPOINT  ? 'header' : 'mobile'}`,
              "logo"
            );
            this.props.history.replace("/");
          }}
        ></PageHeader>
        <Switch>
          <Route path="/petplace/cart" component={Checkout}></Route>
          <Route
            path="/petplace/payment/success"
            component={PaymentCompleteComponent}
          ></Route>
          <Route path="/petplace/payment" component={NewCardPayment}></Route>
          <Route path="/petplace/payment-setting" component={PaymentSettings}></Route>
          <Route path="/petplace/policy" component={Policy}></Route>
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;

  return {
    customerAnimals: customerProfileStore.customerAnimals,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCustomerProfile: (data) => dispatch(saveCustomerProfile(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PetplaceContainer));
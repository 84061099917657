
const showButtonLoader = (saveButton) => {
    const theLoader = saveButton.childNodes[0];
    theLoader.textContent="";
    theLoader.classList.add("spinner-border-md");
    theLoader.classList.add("spinner-border");
}

const hideButtonLoader = (saveButton, text="Continue") => {
    const theLoader = saveButton.childNodes[0];
    theLoader.textContent=text;
    theLoader.classList.remove("spinner-border");
    theLoader.classList.remove("spinner-border-md");
}

export { showButtonLoader, hideButtonLoader};
import React from "react";
import { connect } from "react-redux";
import axios from "../../../../custom-axios";
import { ApiConstants } from "../../../../utils/api-constants";
import { PaymentHistoryCard } from "../../../../UI-Component-Library";
import { AppConstants } from "../../../../utils/app-constants";
import { AppUtils } from "../../../../utils/app-utils";

class PaymentHistory extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedPetId: undefined,
      paymentHistoryData: [],
      selectedPolicyNumber: undefined,
      policyOptions: [],
      pageNumber: 1,
    };
    this.cRef = React.createRef();
  }

  getPaymentHistory = () => {
    if (!this.state.selectedPolicyNumber) {
      return;
    }
    axios
      .processGet(
        `${ApiConstants.URL_GET_PAYMENT_HISTORY}/${this.state.selectedPolicyNumber}`,
        this.cRef
      )
      .then((response) => {
        let paymentHistoryData = [];
        for (let responseObject of response) {
          paymentHistoryData.push({
            dateBilling: AppUtils.getDateWithoutSeconds(
              responseObject.billingDate
            ),
            billAmount: `${
              AppConstants.CURRENCY_FORMAT
            }${responseObject.billAmount.toFixed(2)}`,
            paymentReceived: `${
              AppConstants.CURRENCY_FORMAT
            }${responseObject.paymentReceived.toFixed(2)}`,
          });
        }
        this.setState({
          paymentHistoryData: paymentHistoryData,
        });
      })
      .catch((err) => {});
  };

  debounceHandler = function (fn, d) {
    let timer;
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn();
      }, d);
    };
  };

  debounceGetPaymentHistory = this.debounceHandler(this.getPaymentHistory, 500);

  componentDidMount() {
    this.getPolicyOptions();
  }

  handlePolicyNumberChange = (e) => {
    this.setState(
      {
        selectedPolicyNumber: e.target.value,
        pageNumber: 1,
      },
      this.getPaymentHistory
    );
  };

  getPolicyOptions = () => {
    const allPetsForUser = this.props.allAnimals;

    const petsPolicies = [];

    if (allPetsForUser) {
      for (let animalObject of allPetsForUser) {
        const animalInsurances = animalObject.insurances;
        if (animalInsurances) {
          for (let animalInsurance of animalInsurances) {
            const animalPolicyNumbers = animalInsurance.policynumber;
            if (animalPolicyNumbers) {
              for (let animalPolicyNumber of animalPolicyNumbers) {
                petsPolicies.push({
                  value: animalPolicyNumber,
                  label:
                    animalObject.animalName + " (" + animalPolicyNumber + ")",
                });
              }
            }
          }
        }
      }
      this.setState(
        {
          policyOptions: petsPolicies,
          selectedPolicyNumber:
            petsPolicies && petsPolicies.length > 0
              ? petsPolicies[0].value
              : "",
        },
        this.debounceGetPaymentHistory
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.selectedPolicyNumber && (
          <div ref={this.cRef}>
            <PaymentHistoryCard
              insuranceSelectProps={{
                onChange: this.handlePolicyNumberChange,
                options: this.state.policyOptions,
                value: this.state.selectedPolicyNumber,
              }}
              page={this.state.pageNumber}
              onPageChange={(newPage) => {
                this.setState({
                  pageNumber: newPage,
                });
              }}
              itemsPerPage={5}
              items={this.state.paymentHistoryData}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    selectedAnimal: customerProfileStore.selectedAnimal,
    selectedPetId: customerProfileStore.selectedPetId,
  };
};

export default connect(mapStateToProps, null)(PaymentHistory);

import React from 'react'
import PropTypes from 'prop-types'

import { Paper, PaperContent, Text, HeaderDivider } from '../..'
import {
  RmStep1InfoIcon1,
  RmStep1InfoIcon2,
  RmStep1InfoIco3
} from '../SvgIcons'

export class RmStep1InfoCard extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    isAddingNewPet: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { id, className, style, refProp } = this.props

    return (
      <Paper id={id} className={className} style={style} ref={refProp}>
        <PaperContent>
          <HeaderDivider className='ui-payment-complete-card__title'>
            The portal allows you to:
          </HeaderDivider>
          {/* <Text type={Text.Types.title}>

           
          </Text> */}
          <div>
            <Text type={Text.Types.body}>
              <div className='rm_step_1_info_card_icon-div'>
                <RmStep1InfoIcon1></RmStep1InfoIcon1> Update your personal
                account information
              </div>
            </Text>
            <Text type={Text.Types.body}>
              <div className='rm_step_1_info_card_icon-div'>
                <RmStep1InfoIcon2></RmStep1InfoIcon2> Upload a photo of your pet
              </div>
            </Text>
            <Text type={Text.Types.body}>
              <div className='rm_step_1_info_card_icon-div'>
                <RmStep1InfoIco3></RmStep1InfoIco3>Purchase a 24Petwatch
                Membership
              </div>
            </Text>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperHeadline, PaperContent, Text, Link } from '../..'

export const AccessibilityTextMerck = ({ phoneNumber }) => {
  return (
    <div>
      <Text>
        Pethealth and its subsidiaries and brands, collectively, "Pethealth,"
        are committed to providing websites that are accessible to the widest
        possible audience, regardless of technology or ability. We are actively
        working to increase the accessibility and usability of our websites.
      </Text>

      <Text>
        Pethealth endeavors to conform to level Double-A of the World Wide Web
        Consortium (W3C){' '}
        <Link target='_blank' href='https://www.w3.org/TR/WCAG20/'>
          Web Content Accessibility Guidelines (WCAG) 2.0
        </Link>
        . These guidelines explain how to make web content more accessible for
        people with disabilities.
      </Text>

      <Text>
        While we strive to conform and adhere to the WCAG guidelines for
        accessibility and usability, it is not always possible to do so in all
        areas of the websites. For example, there may be some screen reader
        software available for use which may not be fully compatible with our
        websites.
      </Text>

      <Text>
        We are seeking out solutions to bring as much of the websites up to the
        same level of overall accessibility as possible. In the meantime, if you
        experience any difficulty accessing our websites, please contact us by
        calling <Link href={'tel:' + phoneNumber}>{phoneNumber}</Link> or
        emailing us at{' '}
        <Link href='mailto:accessibility@pethealthinc.com'>
          accessibility@pethealthinc.com
        </Link>
        .
      </Text>
    </div>
  )
}

export const AccessibilityTextPet = () => {
  return (
    <div>
      <Text>
        Pethealth and its subsidiaries and brands are committed to providing
        accessible service to customers and employees. For more information,
        please consult our{' '}
        <Link
          target='_blank'
          href='https://mypethealth.com/pdfs/HR018%20-%20Accessible%20Customer%20Service%20Policy.pdf?ver=2016-11-10-145050-003'
        >
          accessible customer service policy
        </Link>{' '}
        and{' '}
        <Link
          target='_blank'
          href='https://mypethealth.com/pdfs/Multi-Year%20Accessibility%20Plan.pdf?ver=2016-11-10-145049-987'
        >
          multi-year accessibility plan
        </Link>
        .
      </Text>

      <Text>
        This website endeavours to conform to user standards set-out in Level A
        of the World Wide Web Consortium (W3C){' '}
        <Link target='_blank' href='https://www.w3.org/TR/WCAG20/'>
          Web Content Accessibility Guidelines (WCAG) 2.0
        </Link>
        , as required by the{' '}
        <Link
          target='_blank'
          href='https://www.ontario.ca/page/accessibility-laws'
        >
          Accessibility for Ontarians with Disabilities Act
        </Link>
        . This website has been built using W3C standards for HTML and CSS so
        that it will display correctly in current browsers and will support the
        use of screen-reading software.
      </Text>

      <Text>
        While we strive to adhere to the accepted guidelines and standards for
        accessibility and usability, it is not always possible to do so in all
        areas of the website. We are continually seeking out solutions that will
        improve web accessibility. Should you experience any difficulty in
        accessing this website, please bring it to our attention by emailing us
        at{' '}
        <Link href='mailto:accessibility@pethealthinc.com'>
          accessibility@pethealthinc.com
        </Link>
        .
      </Text>
    </div>
  )
}

export const AccessibilityTextUs = (props) => {
  return (
    <div>
      <Text>
        Pethealth and its subsidiaries and brands, collectively, "Pethealth,"
        are committed to providing websites that are accessible to the widest
        possible audience, regardless of technology or ability. We are actively
        working to increase the accessibility and usability of our websites.
      </Text>
      <Text>
        Pethealth endeavors to conform to level Double-A of the World Wide Web
        Consortium (W3C){' '}
        <Link target='_blank' href='https://www.w3.org/TR/WCAG20/'>
          Web Content Accessibility Guidelines (WCAG) 2.0
        </Link>
        . These guidelines explain how to make web content more accessible for
        people with disabilities.
      </Text>
      <Text>
        While we strive to conform and adhere to the WCAG guidelines for
        accessibility and usability, it is not always possible to do so in all
        areas of the websites. For example, there may be some screen reader
        software available for use which may not be fully compatible with our
        websites.
      </Text>

      <Text>
        We are seeking out solutions to bring as much of the websites up to the
        same level of overall accessibility as possible. In the meantime, if you
        experience any difficulty accessing our websites, please contact us by
        calling{' '}
        <Link href={'tel:' + props.phoneNumber}>{props.phoneNumber}</Link> or
        emailing us at{' '}
        <Link href='mailto:accessibility@pethealthinc.com'>
          accessibility@pethealthinc.com
        </Link>
      </Text>
    </div>
  )
}

export class AccessibilityCard extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    isMerckProgram: PropTypes.bool,
    isUs: PropTypes.bool,
    phoneNumber: PropTypes.string
  }
  getAccessibilityText(isMerckProgram, phoneNumber, isUs) {
    if (isMerckProgram) {
      return <AccessibilityTextMerck phoneNumber={phoneNumber} />
    } else if (isUs) {
      return <AccessibilityTextUs phoneNumber={phoneNumber} />
    } else {
      return <AccessibilityTextPet />
    }
  }

  render() {
    const {
      id,
      className,
      style,
      refProp,
      isUs,
      isMerckProgram,
      phoneNumber
    } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn(className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Accessibility</PaperHeadline>
        <PaperContent>
          {this.getAccessibilityText(isMerckProgram, phoneNumber, isUs)}
        </PaperContent>
      </Paper>
    )
  }
}

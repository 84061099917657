import React, { Component } from 'react'
import { PrivacyCommitmentCard, PageContent } from "../../UI-Component-Library";
import { FeatureFlagUtils } from "../../utils/feature-flag-utils";

export default class Privacy extends Component {
    render() {
        return (
          <PageContent>
            <PrivacyCommitmentCard
              usLinkProps={{
                // href: require("../../assets/"+FeatureFlagUtils.getPrivacyPolicyUS()),
                // download: "privacy_policy_us.pdf",
                href: "https://www.24petwatch.com/privacy-policy"
              }}
              caLinkProps={{
                // href: require("../../assets/"+FeatureFlagUtils.getPrivacyPolicyCA()),
                // download: "privacy_policy_ca.pdf",
                href: "https://www.24petwatch.com/ca/privacy-policy"
              }}
              backButtonProps={{
                  id: "back-button",
                  onClick: (e) => {
                      this.props.history.push("/auth/login");
                      e.preventDefault();
                  } 
              }}
            />
          </PageContent>
        );
    }
}

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUserDetailsForPetPlaceLogin } from "../../redux/actions/authActions";
import { loginFailure, loginRequest } from "../../redux/reducers/auth-reducer";
const queryString = require("query-string");

class PetPlaceLoginHandler extends Component {
    constructor() {
        super();
        this.cRef = React.createRef();
    }
    componentDidMount() {
        const parsed = queryString.parse(this.props.history.location.search);
        if (parsed.error) {
            this.props.loginFailed([parsed.error]);
            this.props.history.push("/petplace-login");
            DataLayerUtils.loginErrorEvent(parsed.error);
        } else {
            parsed.redirectUrl && parsed.redirectUrl !== "" ?
                this.props.fetchUserDetails(this.props.history, this.cRef, decodeURIComponent(parsed.redirectUrl), null) :
                this.props.fetchUserDetails(this.props.history, this.cRef, null, null);
        }
    }
    render() {
        return <div className="petplace-login-div" ref={this.cRef}></div>;
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserDetails: (history, cRef, redirectUrl, method) =>
            dispatch(fetchUserDetailsForPetPlaceLogin(history, cRef, redirectUrl, method)),
        loginFailed: (errors) => dispatch(loginFailure(errors)),
        loginRequest: () => dispatch(loginRequest()),
    };
};
export default connect(
    null,
    mapDispatchToProps
)(withRouter(PetPlaceLoginHandler));

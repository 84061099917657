import { AppUtils } from "./app-utils";

export const MaskSize = {
  DEFAULT: "DEFAULT",
  MINI: "MINI",
  FIXED_HEIGHT: "FIXED_HEIGHT",
};

/**
 * Utility to show/hide loadmask
 */
export class LoadMask {
  static preloaderStack = {};
  /**
   * Show a preloader mask on particular element
   * @param maskableEl The HtmlElement we want to mask.
   * @param maskSize Padding around the maskElement
   * @param forceShowMask Forces the mask into view if the loadmask is inside a scrollable section not currently in view
   */
  static showPreloader(
    maskableEl,
    maskSize = MaskSize.DEFAULT,
    forceShowMask = false
  ) {
    if (!maskableEl) {
      return;
    }
    if (!maskableEl.maskId) {
      maskableEl.maskId = AppUtils.uuid();
      LoadMask.preloaderStack[maskableEl.maskId] = 0;
      maskableEl.loadMask = this.createMaskEl();
    }
    if (LoadMask.preloaderStack[maskableEl?.maskId] === 0) {
      maskableEl.appendChild(maskableEl.loadMask);
      maskableEl.originStyle = {};
      maskableEl.originStyle.position = maskableEl.style.position;
      maskableEl.originStyle.minHeight = maskableEl.style.minHeight;
      maskableEl.originStyle.height = maskableEl.style.height;
      maskableEl.originStyle.display = maskableEl.style.display;
      maskableEl.originStyle.top = maskableEl.style.top;
      maskableEl.originStyle.overflow = maskableEl.style.overflow;

      maskableEl.style.position = "relative";
      maskableEl.style.display = "block";
      maskableEl.style.overflow = "hidden";
      switch (maskSize) {
        case MaskSize.MINI:
          maskableEl.style.minHeight = "75px";

          break;
        case MaskSize.DEFAULT:
          maskableEl.style.minHeight = "200px";
          break;

        case MaskSize.FIXED_HEIGHT:
          maskableEl.style.height = "200px";
          break;

        default:
          maskableEl.style.minHeight = "75px";
      }
    }
    if (forceShowMask) {
      maskableEl.scrollIntoView();
    }
    LoadMask.preloaderStack[maskableEl.maskId]++;
  }
  static hidePreloader(maskableEl) {
    if (!maskableEl) {
      return;
    }
    if (--LoadMask.preloaderStack[maskableEl.maskId] === 0) {
      maskableEl.style.position = maskableEl.originStyle.position;
      maskableEl.style.minHeight = maskableEl.originStyle.minHeight;
      maskableEl.style.height = maskableEl.originStyle.height;
      maskableEl.style.display = maskableEl.originStyle.display;
      maskableEl.style.top = maskableEl.originStyle.top;
      maskableEl.style.overflow = maskableEl.originStyle.overflow;
      delete maskableEl.originStyle;
      maskableEl.removeChild(maskableEl.loadMask);
    }
  }
  static createMaskEl() {
    const loadMaskWrap = document.createElement("div");
    loadMaskWrap.className = "loadMaskWrap";
    const loadMask = document.createElement("div");
    loadMask.className = "loadMask";
    const loader = document.createElement("div");
    loader.className = "ui-loading-spinner ui-loading-spinner--regular";
    loadMask.appendChild(loader);
    const text = document.createElement("div");
    loadMask.appendChild(text);
    loadMaskWrap.appendChild(loadMask);
    return loadMaskWrap;
  }
}

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  TransferPetCard,
  RemovePetModal,
  PetHasInsuranceModal,
} from "../../../UI-Component-Library";
import RemovePetWrapperComponent from "../RemovePetWrapperComponent/RemovePetWrapperComponent";
import { ValidationMessages } from "../../../utils/validation-messages";
import axios from "../../../custom-axios";
import { AppUtils } from "../../../utils/app-utils";
import { ApiConstants } from "../../../utils/api-constants";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import moment from "moment";
import { LoadMask } from "../../../utils/loadmask.util";
import { AppConstants } from "../../../utils/app-constants";
import { withRouter } from "react-router-dom";

class TransferPet extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        isRemovePetModalOpen: false,
        isRemovePetHasInsuranceDialogOpen : false,
        headLine: "Do you have the new owner’s information?",
        bodyText: "By entering the new owner’s information, you’re helping to ensure that the pet can be returned home if lost, then found and scanned. If you don’t have the information, this pet will be removed from your account and the new owner will need to re-register the pet on their own.",
        showTransferPet: true,
        wantToTransferPet: false,
        confirmTransferPet: false,
        firstName: "",
        emailAddress: "",
        confirmEmailAddress: "",
        error: "",
        emailError: "",
        petPhoto: undefined,
        ownerName: "",
        ownerEmail: "",
        petName: "",
        chipNumber: "",
        isNewOwnerNextButtonClicked: false,
        isDuplicateAccount: false,
        recipientOwnerBr: "",
      };
      this.cRef = React.createRef();
    }

    removePetModalHandler = (isRemovePetModalOpen, isRemovePetHasInsuranceDialogOpen) => {
      this.setState({
          isRemovePetModalOpen,
          isRemovePetHasInsuranceDialogOpen,
      })
    }

    defaultState = () => {
      const state = {
        headLine: "Do you have the new owner’s information?",
        bodyText: "By entering the new owner’s information, you’re helping to ensure that the pet can be returned home if lost, then found and scanned. If you don’t have the information, this pet will be removed from your account and the new owner will need to re-register the pet on their own.",
        showTransferPet: true,
        wantToTransferPet: false,
        confirmTransferPet: false,
        firstName: "",
        emailAddress: "",
        confirmEmailAddress: "",
        error: "",
        emailError: "",
        petPhoto: undefined,
        ownerName: "",
        ownerEmail: "",
        petName: "",
        chipNumber: "",
        isNewOwnerNextButtonClicked: false,
        isDuplicateAccount: false,
        recipientOwnerBr: "",
      }
      this.setState({
        ...state,
      })
    }
    handleInputChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };

    transferPet = () => {
      this.setState({
        headLine: "Enter new pet owner’s name and email",
        bodyText: "If we already have the new owner’s email on file, we’ll send them alerts to encourage them to accept the transfer. If not, we’ll request more information on the next page so that we can set up an account for them. ",
        showTransferPet: false,
        wantToTransferPet: true,
        confirmTransferPet: false,
      })
    }

    getFormattedPetName = (name) => {
      if (!name) return "";
      let formattedName = name;
      formattedName = formattedName.split(" ");
      if(formattedName.length === 1) {
        return formattedName[0];
      }

      return `${formattedName[0]} ${formattedName[1].charAt(0)}.`
    }

    newOwnerTransferPet = () => {
      this.setState({
        error: "",
        emailError: "",
        isNewOwnerNextButtonClicked: true,
      })

      if (AppUtils.isEmptyString(this.state.firstName) &&
        !AppUtils.isValidEmail(this.state.emailAddress) &&
        !AppUtils.isValidEmail(this.state.confirmEmailAddress)
      ){
        return;
      }

      if (!AppUtils.isEqualString(this.state.emailAddress, this.state.confirmEmailAddress)) {
        this.setState({
          emailError: "Email Address not match"
        })
        return;
      }

      const petInfo = this.props.animalFullDetails[this.props.selectedPetId];
      axios
        .processGet(
          `${ApiConstants.URL_GET_TRANSFER_PET_OWNER_INFO}/${this.state.emailAddress}/${this.state.firstName}`,
          this.cRef,
        )
        .then((response) => {
          if (response.length === 0) {
            this.setState({
              error: "Sorry! No such user exist.",
            })
            return;
          }
          if (response.length >= 2) {
            this.setState({
              headLine: "Duplicate accounts found",
              bodyText: "We have found duplicate accounts for this name and email address. Please call us at 1-xxx-xxx-xxxx",
              showTransferPet: false,
              wantToTransferPet: false,
              confirmTransferPet: false,
              isDuplicateAccount: true,
            })
            return;
          }
          let petFullPrintableName = this.getFormattedPetName(petInfo?.name);
          let ownerName = `${response[0]?.firstName} ${response[0]?.lastName[0]}.`,
              recipientOwnerBr = response[0]?.busrelRelation,
              petPhoto = petInfo?.photo,
              ownerEmail = this.state.emailAddress,
              petName = petFullPrintableName,
              chipNumber = petInfo?.microchipNumber;
          
          this.setState({
            ownerName,
            recipientOwnerBr,
            petPhoto,
            ownerEmail,
            chipNumber,
            petName,
            headLine: "Confirm your transfer",
            bodyText: "We already have that email address in our system. Confirm that this is the correct email address before transferring the pet. Please encourage the new owner to log onto their account and verify that all their contact information is up to date.",
            showTransferPet: false,
            wantToTransferPet: false,
            confirmTransferPet: true,
          })
        })
        .catch((err) => {
 
      })

    }

  confirmTransferPet = () => {
    this.setState({
      error: "",
    })

    const payload = {
      chipNumber: this.state.chipNumber,
      ownerBrn:  AppUtils.getBusRelAccount(),
      targetBrn: this.state.recipientOwnerBr,
      animalId: this.props.selectedPetId,
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      street: null,
      city: null,
      state: null,
      zipCode: null,
      country: null
    }

    axios
    .processPost(
      `${ApiConstants.URL_POST_SAVE_TRANSFER_PET_OWNERSHIP_REQUEST}`,
      this.cRef,
      payload
    )
    .then((response) => {
      this.setState({
        headLine: "Success",
        bodyText: "This pet transfer is pending.",
        showTransferPet: false,
        wantToTransferPet: false,
        confirmTransferPet: false,
      })
    })
    .catch((err) => {
      let errors = err?.response?.data?.errors;
      if (errors && errors.length > 0) {
        this.setState({
          error: errors[0],
        });
      }
    })

    }
    componentDidMount() {}
    render() {
    
      return (
        <React.Fragment>
          <div ref={this.cRef}>
            <TransferPetCard
              errorMessage = {this.state.error}
              headLine = {this.state.headLine}
              bodyText = {this.state.bodyText}
              showTransferPet = {this.state.showTransferPet}
              wantToTransferPet = {this.state.wantToTransferPet}
              confirmTransferPet = {this.state.confirmTransferPet}
              isAvatar = { (this.state.petPhoto) ? true : false}
              petAvatar = {this.state.petPhoto}
              ownerName =  {this.state.ownerName}
              ownerEmail = {this.state.ownerEmail}
              petName =  {this.state.petName}
              chipNumber=  {this.state.chipNumber}
              cancelTransferButtonProps = {{
                onClick: () => {
                  this.props.history.push("/pages/dashboard")
                }
              }}
              transferButtonProps = {{
                onClick: () => {
                  this.transferPet();
                }
              }}
              notTransferButtonProps = {{
                onClick: () => {
                  this.setState({
                    isRemovePetModalOpen: (this.props.selectedAnimal.insurances[0]?.policynumber.length)? false : true,
                    isRemovePetHasInsuranceDialogOpen: (this.props.selectedAnimal.insurances[0]?.policynumber.length) ? true : false
                  })
                }
              }}
              firstNameInputProps = {{
                id: "TP_firstname",
                value: this.state.firstName,
                name: "firstName",
                error:
                  AppUtils.isEmptyString(this.state.firstName) &&
                  this.state.isNewOwnerNextButtonClicked,
                hint:
                  AppUtils.isEmptyString(this.state.firstName) &&
                  this.state.isNewOwnerNextButtonClicked
                    ? "Please enter first name."
                    : "",
                onChange: this.handleInputChange,
              }}
              emailInputFieldProps = {{
                id: "TP_email",
                value: this.state.emailAddress,
                name: "emailAddress",
                error:
                  !AppUtils.isValidEmail(this.state.emailAddress) &&
                  this.state.isNewOwnerNextButtonClicked,
                hint:
                  !AppUtils.isValidEmail(this.state.emailAddress) &&
                  this.state.isNewOwnerNextButtonClicked
                    ? "Please enter email address."
                    : "",
                onChange: this.handleInputChange,
              }}
              confirmEmailInputFieldProps = {{
                id: "TP_confirmEmail",
                value: this.state.confirmEmailAddress,
                name: "confirmEmailAddress",
                error:
                  (!AppUtils.isValidEmail(this.state.confirmEmailAddress) || this.state.emailError) &&
                  this.state.isNewOwnerNextButtonClicked,
                hint:
                  (!AppUtils.isValidEmail(this.state.confirmEmailAddress) || this.state.emailError) &&
                  this.state.isNewOwnerNextButtonClicked
                    ? (this.state.emailError)? this.state.emailError : "Please enter email address."
                    : "",
                onChange: this.handleInputChange,
              }}
              newOwnerNextButtonProps = {{
                onClick: () => {
                  this.newOwnerTransferPet()
                }
              }}
              newOwnerBackButtonProps = {{
                onClick: () => {
                  this.defaultState();
                }
              }}
              confirmTransferButtonProps = {{
                onClick: () => {
                  // call api
                  this.confirmTransferPet();
                }
              }}
              backButtonProps = {{
                onClick: () => {
                  this.setState({
                    headLine: "Enter new pet owner’s name and email",
                    bodyText: "If we already have the new owner’s email on file, we’ll send them alerts to encourage them to accept the transfer. If not, we’ll request more information on the next page so that we can set up an account for them. ",
                    showTransferPet: false,
                    wantToTransferPet: true,
                    confirmTransferPet: false,
                  })
                }
              }}
              isDuplicate = {this.state.isDuplicateAccount}
              duplicateBackButtonProps={{
                onClick: () => {
                  this.setState({
                    headLine: "Enter new pet owner’s name and email",
                    bodyText: "If we already have the new owner’s email on file, we’ll send them alerts to encourage them to accept the transfer. If not, we’ll request more information on the next page so that we can set up an account for them. ",
                    showTransferPet: false,
                    wantToTransferPet: true,
                    confirmTransferPet: false,
                    isDuplicateAccount: false,
                  })
                }
              }}
            >
            </TransferPetCard>
          </div>
          <RemovePetWrapperComponent
            isRemovePetModalOpen={this.state.isRemovePetModalOpen}
            removePetModalHandler={this.removePetModalHandler}
            isRemovePetHasInsuranceDialogOpen={this.state.isRemovePetHasInsuranceDialogOpen}
          />
        </React.Fragment>
      );
    }
}

const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    selectedPetId: customerProfileStore.selectedPetId,
    selectedAnimal: customerProfileStore.selectedAnimal,
    animalFullDetails: customerProfileStore.animalFullDetails,
  };
};

export default connect(mapStateToProps)(withRouter(TransferPet));



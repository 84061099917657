import React from 'react'
import PropTypes from 'prop-types'

import DesktopPageHeader from './DesktopPageHeader'
import MobilePageHeader from './MobilePageHeader'

// Minimal width for the desktop layout to look good, after this break point render mobile view
const MOBILE_VIEW_BREAKPOINT = 927

export class PageHeader extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showMobile: window.innerWidth < MOBILE_VIEW_BREAKPOINT
    }

    this.handleViewBasedOnViewportWidth = this.handleViewBasedOnViewportWidth.bind(
      this
    )
  }

  static propTypes = {
    /**
     * react SVG logo
     */
    logo: PropTypes.element,
    onLogoClick: PropTypes.func,
    showMobileMenu: PropTypes.bool,
    showPetMenu: PropTypes.bool,
    petMenuProps: PropTypes.shape({
      onMenuClick: PropTypes.func,
      elementId: PropTypes.string,
      pets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      ),
      onSelectedPetChange: PropTypes.func,
      selectedPetId: PropTypes.string,
      newPetButtonProps: PropTypes.shape({
        onClick: PropTypes.func,
        id: PropTypes.string
      })
    }),

    showAccount: PropTypes.bool,
    accountMenuProps: PropTypes.shape({
      id: PropTypes.string,
      onAccountClick: PropTypes.func,
      nameOnAccount: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      ),
      onSelectedItemChange: PropTypes.func,
      selectedItemId: PropTypes.string,
      showNotificationMark: PropTypes.bool,
    }),
    showSubmitClaim: PropTypes.bool,
    submitClaimButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    showReportLostPetButton: PropTypes.bool,
    showPetProPortalButton: PropTypes.bool,
    petProPortalButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    reportLostPetButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    reportLostPetButtonText: PropTypes.string,
    showDropDown: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    showSubmitClaim: false,
    showMobileMenu: true,
    showDropDown: false,
    submitClaimButtonProps: {},
    showAccount: false,
    accountButtonProps: {},
    showPetMenu: false,
    showPetProPortalButton: false,
    showReportLostPetButton: true,
    reportLostPetButtonText: "Report a Lost or Found Pet"
  }

  handleViewBasedOnViewportWidth() {
    window.innerWidth < MOBILE_VIEW_BREAKPOINT
      ? this.setState({ showMobile: true })
      : this.setState({ showMobile: false })
  }

  componentDidMount() {
    this.handleViewBasedOnViewportWidth()

    window.addEventListener('resize', this.handleViewBasedOnViewportWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleViewBasedOnViewportWidth)
  }

  render() {
    return this.state.showMobile ? (
      <MobilePageHeader {...this.props} />
    ) : (
      <DesktopPageHeader {...this.props} />
    )
  }
}

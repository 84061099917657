import React from "react";
import { connect } from "react-redux";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { AppUtils } from "../../../utils/app-utils";
import {
  PetClaimAndRecoveryHistory,
  IframeModal,
} from "../../../UI-Component-Library";
import { AppConstants } from "../../../utils/app-constants";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";

class PetClaimHistory extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedPetId: undefined,
      claimHistory: undefined,
      mappedClaimHistory: [],
      mappedRecoveryHistory: [],
      isClaimModalOpen: false,
      recoveryHistory: undefined,
      formInstId: "",
    };
    this.cRef = React.createRef();
  }
  getClaimHistory = () => {
    if (!this.props.selectedPetId) {
      return;
    }
    axios
      .processGet(
        `${ApiConstants.URL_GET_CLAIM_HISTORY}${AppUtils.getUserRelationId()}/${
          this.props.selectedPetId
        }`,
        this.cRef
      )
      .then((response) => {
        this.setState(
          {
            claimHistory: response,
          },
          this.mapClaimHistory
        );
      })
      .catch((err) => {});
  };
  getRecoveryHistory = () => {
    if (
      !this.props.selectedPetId ||
      !FeatureFlagUtils.shouldAllowFetchingPetRecoveryHistory() //||
    //  AppUtils.isEmptyString(this.props.animalDetails?.microchipNumber)
    ) {
      return;
    }
    axios
      .processPost(`${ApiConstants.URL_GET_RECOVERY_HISTORY}`, null, {
        userRelationId: AppUtils.getUserRelationId(),
        animalId: this.props.selectedPetId,
      })
      .then((response) => {
        this.setState(
          {
            recoveryHistory: this.getSortedRecoveryHistory(response.data),
          },
          this.mapRecoveryHistory
        );
      })
      .catch((err) => {});
  };
  getSortedRecoveryHistory(data) {
    if (!data) {
      return [];
    }
    return data.sort((d1, d2) => d1.order - d2.order);
  }

  getSubmissionNumber(claim) {
    return AppUtils.isEmptyString(claim.bplSubmissionID)
      ? claim.claimSubmissionID
      : claim.bplSubmissionID;
  }
  mapClaimHistory = () => {
    const mappedClaimHistory = [];
    this.state.claimHistory &&
      this.state.claimHistory.forEach((claim) => {
        const documents = [];
        if (claim.documents && claim.documents.length > 0) {
          claim.documents.forEach((document) => {
            documents.push({
              active: true,
              key: document.activityNo,
              documentData: document,
            });
          });
        }
        mappedClaimHistory.push({
          submissionNumber: this.getSubmissionNumber(claim),
          submissionNumberActive: String(claim.approvalStatus).startsWith(
            "Claim on Hold"
          )
            ? true
            : false,
          instanceID: claim.bplClaimFormInstId,
          dateSubmitted: AppUtils.getCHFormattedDate(claim.dateSubmissionStart),
          status: {
            name: claim.approvalStatus,
          },
          submittedAmount: claim.submittedAmount,
          amountPaid: claim.amountPaid,
          documents:
            documents && documents.length !== 0
              ? documents
              : [
                  {
                    active: false,
                    key: "no-d",
                  },
                ],
        });
      });
    this.setState({
      mappedClaimHistory,
    });
  };
  mapRecoveryHistory = () => {
    const mappedRecoveryHistory = [];
    this.state.recoveryHistory.forEach((recovery) => {
      mappedRecoveryHistory.push({
        caseID: "" + recovery.caseId,
        dateOpened: recovery.opened,
        lastUpdated: recovery.updated,
        status: recovery.status,
        note: recovery.notes,
      });
    });
    this.setState({
      mappedRecoveryHistory,
    });
  };
  debounceClaimHistoryHandler = function (fn, d) {
    let timer;
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn();
      }, d);
    };
  };
  debounceRecoveryHistoryHandler = function (fn, d) {
    let timer;
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn();
      }, d);
    };
  };
  debounceGetClaimHistory = this.debounceClaimHistoryHandler(
    this.getClaimHistory,
    500
  );
  debounceGetRecoveryHistory = this.debounceRecoveryHistoryHandler(
    this.getRecoveryHistory,
    500
  );

  componentDidMount() {
    this.setState(
      {
        selectedPetId: this.props.selectedPetId,
      },
      () => {
        this.debounceGetClaimHistory();
        this.debounceGetRecoveryHistory();
      }
    );
  }
  componentDidUpdate() {
    if (
      this.props.selectedPetId &&
      this.props.selectedPetId !== this.state.selectedPetId
    ) {
      this.setState(
        {
          selectedPetId: this.props.selectedPetId,
          claimHistory: undefined,
          mappedClaimHistory: [],
          mappedRecoveryHistory: [],
          isClaimModalOpen: false,
          recoveryHistory: undefined,
        },
        () => {
          this.debounceGetClaimHistory();
          this.debounceGetRecoveryHistory();
        }
      );
    }
  }

  downloadDocument = (activityNo) => {
    if (!activityNo) {
      return;
    }
    axios
      .processGet(
        `${ApiConstants.URL_GET_CLAIM_DOCUMENT_URL}${activityNo}`,
        this.cRef,
        {
          responseType: "blob",
        },
        true
      )
      .then((response) => {
        AppUtils.saveToFile(response);
      });
  };
  render() {
    return (
      <React.Fragment>
        <IframeModal
          modalHeader="Claim info"
          open={this.state.isClaimModalOpen}
          onClose={() => {
            this.setState({
              isClaimModalOpen: false,
            });
            window.location.reload();
          }}
          iframeProps={{
            src:
              AppConstants.CONFIG_JSON.URL_BPL_CLAIM_DETAIL +
              this.state.formInstId,
          }}
          forceHideSpinner={false}
        />
        <div ref={this.cRef}>
          <PetClaimAndRecoveryHistory
            claimsNote="Please Note: Our average claims processing time is currently 16 calendar days, upon receipt of all required documentation."
            claimHistory={this.state.mappedClaimHistory}
            onDocumentClick={(data) => {
              this.downloadDocument(data.documentData?.activityNo);
            }}
            onSubmissionNumberClick={(instanceID) => {
              this.setState({
                isClaimModalOpen: true,
                formInstId: instanceID,
              });
            }}
            recoveryHistory={this.state.mappedRecoveryHistory}
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    selectedAnimal: customerProfileStore.selectedAnimal,
    selectedPetId: customerProfileStore.selectedPetId,
  };
};

export default connect(mapStateToProps, null)(PetClaimHistory);
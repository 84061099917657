import { AppUtils } from "./app-utils"
export class DataLayerUtils{

  static SPECIESMAPPING = {
    '1': 'dog',
    '2': 'cat'
  }

  static getProductDetails(product) {
    const productInfo = {
      item_id: product.itemId,
      item_name: product.productName,
      coupon: null,
      currency: AppUtils.isUsCountry ? "usd" : "cad",
      discount: product.discountAmount,
      price: AppUtils.getExtractedPrice(product.productCost),
      quantity: 1,
    };
    let itemCategory, itemVariant, itemShape;

    if (AppUtils.isBytetagOrder(product.itemId)) {
      itemCategory = "bytetags";
      const byteTagDetails = AppUtils.getByteTagProductDetails(product.productName);
      itemVariant = byteTagDetails.variant;
      itemShape = byteTagDetails.shape;
      productInfo["item_size"] = byteTagDetails.size;
    }else {
      const tagProduct = AppUtils.getShapeAndStyle(product);
      itemCategory = (tagProduct) ? "tags" : "membership";
      itemVariant = (tagProduct) ? tagProduct.style : null;
      itemShape = (tagProduct) ? tagProduct.shape : null;
    }

    productInfo["item_category"] = itemCategory;
    productInfo["item_variant"] = itemVariant;
    productInfo["item_shape"] = itemShape;
    return productInfo;
  }

  static addToCartEvent(product) {
    const items = [];
    items.push(this.getProductDetails(product));
    const currDataLayer = {
      'event': 'add_to_cart',
      'ecommerce': {
        'items': items,
      }
    }
    window.dataLayer.push(currDataLayer);
  }

  static viewCartEvent(products, totalValue) {
    const items = [];
    for (let i = 0; i < products.length; i++){
      products[i].items.forEach(product => {
        items.push(this.getProductDetails(product));
      });
    }
    const currDataLayer = {
      'event': 'view_cart',
      'ecommerce': {
        'currency': (AppUtils.isUsCountry)? "usd" : "cad" ,
        'value': totalValue || null,
        'items': items,
      }
    }
    window.dataLayer.push(currDataLayer);
  }

  static removeFromCartEvent(product) {
    const items = [];
    items.push(this.getProductDetails(product));
    const currDataLayer = {
      'event': 'remove_from_cart',
      'ecommerce': {
        'items': items,
      }
    }
    window.dataLayer.push(currDataLayer);
  }

  static beginCheckoutEvent(products, totalValue) {
    const items = [];
    for (let i = 0; i < products.length; i++){
      products[i].items.forEach(product => {
        items.push(this.getProductDetails(product));
      });
    }
    const currDataLayer = {
      'event': 'begin_checkout',
      'ecommerce': {
        'currency': (AppUtils.isUsCountry)? "usd" : "cad" ,
        'value': (totalValue) ? totalValue : null,
        'items': items,
      }
    }
    window.dataLayer.push(currDataLayer);
  }

  static addPaymentInfoEvent(products, totalValue, tax, coupon) {
    const items = [];
    for (let i = 0; i < products.length; i++){
      products[i].items.forEach(product => {
        items.push(this.getProductDetails(product));
      });
    }
    const currDataLayer = {
      'event': 'add_payment_info',
      'ecommerce': {
        'currency': (AppUtils.isUsCountry)? "usd" : "cad" ,
        'value': (totalValue) ? totalValue : null,
        'coupon': (coupon) ? coupon : null,
        'payment_type': "credit card",
        'tax': (tax) ? tax : null,
        'items': items,
      }
    }
    window.dataLayer.push(currDataLayer);
  }

  static purchaseEvent(products, totalValue, tax, coupon, shipping) {
    const items = [];
    for (let i = 0; i < products.length; i++){
      products[i].items.forEach(product => {
        items.push(this.getProductDetails(product));
      });
    }
    const transactionId = Math.floor(10000000 + Math.random() * 90000000);
    const currDataLayer = {
      'event': 'purchase',
      'ecommerce': {
        'currency': (AppUtils.isUsCountry)? "usd" : "cad" ,
        'value': (totalValue) ? totalValue : null,
        'coupon': (coupon) ? coupon : null,
        'payment_type': "credit card",
        'tax': (tax) ? tax : null,
        'transaction_id': transactionId,
        'affiliation': null,
        'shipping': (shipping) ? shipping : null,
        'items': items,
      }
    }
    window.dataLayer.push(currDataLayer);
  }

  // Custom Events
  static registerNewPetEvent(ownerId, microchipNumber, step = null, isSuccess = false) {
    const currDataLayer = {
      'event':  (isSuccess) ? 'register_new_pet_success' : 'register_new_pet',
      'owner_id' : ownerId,
    }
    if (microchipNumber) {
      currDataLayer["microchip_id"] = microchipNumber;
    }
    if (step) {
      currDataLayer["step"] = step;
    }
    window.dataLayer.push(currDataLayer);
  }

  static removePetEvent(ownerId, microchipNumber, step = null, reason = null, isSuccess = false) {
    const currDataLayer = {
      'event': (isSuccess) ? 'remove_pet_success' : 'remove_pet',
      'reason': reason,
      'microchip_id': microchipNumber,
      'owner_id': ownerId
    }
    if (step) {
      currDataLayer["step"] = step;
    }
    window.dataLayer.push(currDataLayer);
  }

  static reportLostPetEvent(ownerId, microchipNumber, type, step = 'start') {
    const currDataLayer = {
      'event': 'report_lost_pet',
      'type': type,
      'step': step,
      'owner_id': ownerId,
      'microchip_id': microchipNumber
    };
    window.dataLayer.push(currDataLayer);
  }

  static transferPetRegistrationEvent(step, ownerId) {
    const currDataLayer = {
      'event': 'transfer_pet_registration',
      'step': step,
      'owner_id': ownerId
    }
    window.dataLayer.push(currDataLayer);
  }

  static createAccountEvent(method, step, flow, policyNumber = null) {
    const currDataLayer = {
      'event': 'create_account',
      'flow': flow,
      'step': step,
      'method': method === 'phone' ? 'phone and email' : method
    }
    if(AppUtils.isEqualString(method, 'microchip')){
      currDataLayer.microchip_id = policyNumber;
    }

    if(AppUtils.isEqualString(method, 'policy')){
      currDataLayer.policy_id = policyNumber;
    }
    window.dataLayer.push(currDataLayer);
  }

  static createAccountSuccessEvent(method, ownerId, petId, flow, signup_method, policyNumber = null ) {
    const currDataLayer = {
      'event': 'create_account_success',
      'flow': flow,
      'owner_id': ownerId,
      'pet_id': petId,
      'method': method === 'phone' ? 'phone and email' : method,
      'signup_method': signup_method
    }
    if(AppUtils.isEqualString(method, 'microchip')){
      currDataLayer.microchip_id = policyNumber;
    }

    if(AppUtils.isEqualString(method, 'policy')){
      currDataLayer.policy_id = policyNumber;
    }
    window.dataLayer.push(currDataLayer);
  }

  static loginEvent(method, ownerId = null) {
    const currDataLayer = {
      'event': 'login',
      'method': method,
      'owner_id': ownerId,
    }
    window.dataLayer.push(currDataLayer);
  }

  static loginErrorEvent(error) {
    const currDataLayer = {
      'event': 'login_error',
      'error': error,
    }
    window.dataLayer.push(currDataLayer);
  }

  static accountVerificationSubmitPassword(ownerId) {
    const currDataLayer = {
      'event': 'account_verification_submit_password',
      'owner_id': ownerId
    }
    window.dataLayer.push(currDataLayer);
  }

  static forgotPasswordEvent(step) {
    const currDataLayer = {
      'event': 'forgot_password',
      'step': step,
    }
    window.dataLayer.push(currDataLayer);
  }

  static submitClaimEvent(ownerId, microchipNumber, step) {
    const currDataLayer = {
      'event': 'submit_claim',
      'step': step,
      'owner_id': ownerId,
      'microchip_id': microchipNumber
    }
    window.dataLayer.push(currDataLayer);
  }

  static navigationEvent(menu, text) {
    const currDataLayer = {
      'event': 'navigation',
      'location': menu,
      'button_name': text
    }
    window.dataLayer.push(currDataLayer);
  }

  static faqEvent(question) {
    const currDataLayer = {
      'event': 'faq_question',
      'faq_question_text': question
    }
    window.dataLayer.push(currDataLayer);
  }

  static getStartedInsuranceEvent(ownerId, microchipNumber) {
    const currDataLayer = {
      'event': 'get_started_insurance',
      'owner_id': ownerId,
      'microchip_id': microchipNumber
    }
    window.dataLayer.push(currDataLayer);
  }

  static clickToCallEvent() {
    const currDataLayer = {
      'event': 'click_to_call',
    }
    window.dataLayer.push(currDataLayer);
  }

  static formErrorEvent(form, step, error) {
    const currDataLayer = {
      'event':'form_error',
      'form_name' : form,
      'error': error,
      'step': step === 'phone' ? 'phone and email' : step
    }
    window.dataLayer.push(currDataLayer);
  }

  static selectPaymentMethodEvent(ownerId) {
    const currDataLayer = {
      'event': 'select_payment_method',
      'owner_id': ownerId,
    }
    window.dataLayer.push(currDataLayer);
  }

  static consentToMarketingEvent(ownerId) {
    const currDataLayer = {
      'event': 'consent_to_marketing',
      'owner_id': ownerId,
    }
    window.dataLayer.push(currDataLayer);
  }
}
import React from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Paper,
  PaperHeadline,
  PaperContent,
  Button,
  Select,
  Form,
  Input,
  Text,
  TextArea,
} from '../../'

export class EditPetNameModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,

    onFormSubmit: PropTypes.func,

    errorMessage: PropTypes.string,
    petNameInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    microchipNumberInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    dobInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    speciesInputProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,
      id: PropTypes.string
    }),
    primaryBreedInputProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,
      id: PropTypes.string
    }),
    secondaryBreedInputProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,
      id: PropTypes.string
    }),
    insuranceCarrierInputProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,
      id: PropTypes.string
    }),
    generalDescriptionInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    sexInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    neuterInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    cancelButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    saveButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  render() {
    const {
      open,
      onClose,
      errorMessage,
      petNameInputProps,
      microchipNumberInputProps,
      dobInputProps,
      speciesInputProps,
      sexInputProps,
      primaryBreedInputProps,
      secondaryBreedInputProps,
      insuranceCarrierInputProps,
      generalDescriptionInputProps,
      neuterInputProps,
      cancelButtonProps,
      saveButtonProps,
      onFormSubmit
    } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <div className='ui-edit-pet-name-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline>Change pet's details</PaperHeadline>
            <PaperContent>
              {errorMessage && (
                <Text
                  className='ui-edit-pet-name-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}
              <Form onSubmit={onFormSubmit}>
                <div className='ui-edit-pet-name-modal__horizontal-row-margin'>
                  <Input
                    label='Microchip number:'
                    {...microchipNumberInputProps}
                  />
                  <Input label='Pet name' {...petNameInputProps} />
                  <Input label='Date of birth (DD/MM/YYYY):' {...dobInputProps} />
                  <Select label='Species' {...speciesInputProps} />
                </div>
                <Select label='Primary breed:' {...primaryBreedInputProps} />
                <Select
                  label='Secondary breed:'
                  {...secondaryBreedInputProps}
                />
                <div className='ui-edit-pet-name-modal__horizontal-row-margin'>
                  <Input label='Sex:' {...sexInputProps} />
                  <Input label='Spayed/Neutered:' {...neuterInputProps} />
                </div>
                <Select
                  label='Insurance carrier:'
                  {...insuranceCarrierInputProps}
                />
                <TextArea
                  label="Here is where you let the finder know any pertinent information about your pet. 4000 character limit"
                  placeholder="Notes"
                  {...generalDescriptionInputProps}
                />
                <div className='ui-edit-pet-name-modal__actions'>
                  <div>
                    <Button
                      type='button'
                      variant={Button.Variants.outlined}
                      color={Button.Colors.tertiary}
                      {...cancelButtonProps}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      type='submit'
                      color={Button.Colors.tertiary}
                      {...saveButtonProps}
                    >
                      Save changes
                    </Button>
                  </div>
                </div>
              </Form>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperHeadline, PaperContent, Text, Link } from '../../'

export class PaperlessBillingCard extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    phoneNumber: PropTypes.string,
    enableEmoji: PropTypes.bool
  }

  static defaultProps = {
    enableEmoji: true
  }

  render() {
    const {
      id,
      className,
      style,
      refProp,
      phoneNumber,
      enableEmoji
    } = this.props

    const emoji = (em) => (enableEmoji ? em : '')

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn('ui-paperless-billing-card', className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>{`${emoji('🌲')} Go Paperless ${emoji(
          '🌳'
        )}`}</PaperHeadline>

        <PaperContent className={cn('ui-paperless-billing-card__content')}>
          <Text type={Text.Types.bodyBold}>
            Electronic Communications Selected
          </Text>

          <Text>You have opted in to paperless communications.</Text>

          <Text>
            Not only are you helping to reduce paper usage, you are ensuring you
            receive timely communications regarding your coverage.
          </Text>

          <Text>
            If you need to make changes to your delivery preference in the
            future please contact us at:{' '}
            <Link href={'tel:' + phoneNumber}>{phoneNumber}</Link>.
          </Text>
        </PaperContent>
      </Paper>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { IconButton } from '../IconButton'
import { Link } from '../Link'
import { Icon } from '../Icon'

export const Summary = ({ label, amount }) => (
  <div
    className={cn('ui-new-shopping-cart__cart-summary', 'ui-new-shopping-cart__row')}
  >
    <span>{label}</span>
    <span>{amount}</span>
  </div>
)
Summary.propTypes = {
  label: PropTypes.string,
  amount: PropTypes.string
}

export const Promo = ({ label, discount='', ...rest }) => (
  <div
    className={cn('ui-new-shopping-cart__cart-summary', 'ui-new-shopping-cart__row')}
  >
    <span>{discount !== '' ? 'Discount' : ''}</span>
    <div className='ui-new-shopping-cart__discount_row'>
      {discount !== '' && <span>{discount}</span>}
      <Link className='ui-new-shopping-cart__promo_link' {...rest}>
        {label}
      </Link>
    </div>
  </div>
)
Promo.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string
}

export const Total = ({ amount }) => (
  <div
    className={cn('ui-new-shopping-cart__cart-total', 'ui-new-shopping-cart__row')}
  >
    <span>Total</span>
    <span>{amount}</span>
  </div>
)
Total.propTypes = {
  amount: PropTypes.string
}

export const TotalHead = ({ amount, upArrow, onClick }) => (
  <div
    className={cn('ui-new-shopping-cart__cart-total', 'ui-new-shopping-cart__row')}
    onClick= {onClick}
  >
    <span className='ui-new-shopping-cart_arrow'>
      Total
      {upArrow && <Icon type='expand_less' />}
      {!upArrow && <Icon type='expand_more' />}
    </span>
    <span>{amount}</span>
  </div>
)
TotalHead.propTypes = {
  amount: PropTypes.string
}

export const CartItem = ({ productName, productCost, onRemoveClick }) => (
  <div className={cn('ui-new-shopping-cart__cart-item', 'ui-new-shopping-cart__row')}>
    <span>{productName}</span>
    <span className='ui-new-shopping-cart__cart-item__price'>{productCost}</span>
    <IconButton
      className='ui-new-shopping-cart__cart-item__remove'
      type='remove_circle_outline'
      onClick={onRemoveClick}
    />
  </div>
)
CartItem.propTypes = {
  productName: PropTypes.string,
  productCost: PropTypes.string,
  onRemoveClick: PropTypes.func
}

import React from 'react'
import PropTypes from 'prop-types'

import { Modal, Paper, PaperHeadline, PaperContent, Icon, Text } from '../../'

export class IframeModal extends React.PureComponent {
  static propTypes = {
    modalHeader: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,

    errorMessage: PropTypes.string,
    iframeProps: PropTypes.shape({
      src: PropTypes.string,
      onLoad: PropTypes.func
    })
  }

  static defaultProps = {
    iframeProps: {}
  }

  render() {
    const { open, onClose, errorMessage, iframeProps, modalHeader } = this.props

    return (
      <Modal open={open}>
        <div className='ui-iframe-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline>
              <button
                className='ui-iframe-modal__close-button'
                onClick={onClose}
              >
                <Icon type='close' />
              </button>
              {modalHeader}
            </PaperHeadline>

            <PaperContent
              className='ui-iframe-modal__content'
              refProp={this.refContent}
            >
              {{ errorMessage } && (
                <Text
                  className='ui-iframe-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}

              <iframe id="iframe" width='100%' height='100%' {...iframeProps}  />
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

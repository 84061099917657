import React from "react";
import axios from "../../.././../custom-axios";
import { ApiConstants } from "../../../../utils/api-constants";
import { AppConstants } from "../../../../utils/app-constants";
import { AppUtils } from "../../../../utils/app-utils";
import {
  UserContactInfoCard,
  GoPaperlessCard,
  PaperlessBillingCard,
  LoginInfoChangeWarningModal,
  Button
} from "../../../../UI-Component-Library";
import { ValidationMessages } from "../../../../utils/validation-messages";
import AccountPreference from "../account-preference/account-preference";
import EmergencyContact from "../emergency-contact/emergency-contact";
import { LoadMask } from "../../../../utils/loadmask.util";
import { connect } from "react-redux";
import { FeatureFlagUtils } from "../../../../utils/feature-flag-utils";
import { DataLayerUtils } from "../../../../utils/dataLayer-utils";
class ContactInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      busRelAccount: "",
      emailAddress: "",
      oldEmailAddress: "",
      paperless: false,
      phoneNumber: "",
      workNumber: "",
      homeNumber: "",
      mobileNumber: "",
      phoneType: "",
      phoneNumberFields: [],
      firstname: "",
      lastname: "",
      countryregionid: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipcode: "",
      isEmailError: false,
      isPhoneNumberError: false,
      isWorkPhoneNumberError: false,
      isHomePhoneNumberError: false,
      isAddressLine1Error: false,
      successMessage: "",
      errorMessage: "",
      errorPaperlessMessage: "",
      successPaperlessMessage: "",
      userProfile: undefined,
      hasActiveInsurance: false,
      shouldShowCommonFields: false,
      isChangeWarningOpen: false,
      isEmailChanged: false,
      contactConsent: false,
      petReleaseinformation: false,
    };
  }
  getContactInfo = (showSuccessMessage) => {
    LoadMask.showPreloader(this.refs.divRef);
    LoadMask.showPreloader(this.refs.paperlessRef);
    axios
      .processGet(
        `${ApiConstants.URL_GET_CONTACT_INFO}${AppUtils.getBusRelAccount()}`
      )
      .then((response) => {
        const state = {
          address: response.address,
          busRelAccount: response.busRelAccount,
          emailAddress: response.emailAddress,
          oldEmailAddress: response.emailAddress,
          contactConsent: response.contactConsent,
          petReleaseinformation: response.petReleaseinformation,
          paperless: response.paperless,
          phoneNumber: response.phoneNumber,
          phoneType: response.phoneType,
          homeNumber: response.homePhone,
          workNumber: response.businessPhone,
          mobileNumber: response.cellularPhone,
          firstname: response.address.firstname,
          lastname: response.address.lastname,
          countryregionid: response.address.countryregionid,
          addressLine1: response.address.addressLine1,
          addressLine2: response.address.addressLine2,
          city: response.address.city,
          state: response.address.state,
          zipcode: response.address.zipcode,
        };
        if (showSuccessMessage) {
          state.successMessage = "User information updated successfully!";
        }

        let initialPhoneNumbersFields = this.showPhoneNumbers(response.phoneType, response.phoneNumber, response.homePhone, response.cellularPhone, response.businessPhone);
        this.setState({
          phoneNumberFields: initialPhoneNumbersFields
        })
        this.setState(
          {
            ...state,
          },
          () => {
            this.shouldShowInfo();
          }
        );
      })
      .finally(() => {
        LoadMask.hidePreloader(this.refs.divRef);
        LoadMask.hidePreloader(this.refs.paperlessRef);
      });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };



  showPhoneNumbers(type, mainNumber, number1, number2, number3){
    let initialPhoneNumbers = [];
    let phoneType = AppConstants.PHONEWISETYPE[type];
    initialPhoneNumbers = this.setInitialPhoneNumbers(initialPhoneNumbers, phoneType, mainNumber);
    if(phoneType !== "Home" && number1) {
      initialPhoneNumbers = this.setInitialPhoneNumbers(initialPhoneNumbers, "Home", number1);
    }
    if(phoneType !== "Mobile" && number2) {
      initialPhoneNumbers = this.setInitialPhoneNumbers(initialPhoneNumbers, "Mobile", number2);
    }
    if(phoneType !== "Work" && number3) {
      initialPhoneNumbers = this.setInitialPhoneNumbers(initialPhoneNumbers, "Work", number3);
    }
    
    return initialPhoneNumbers;
  }

  changePhoneTypeHandler = (event, index) =>{
    let updatedPhoneNumberFields = this.state.phoneNumberFields;
    updatedPhoneNumberFields[index]["selectPhoneFieldProps"].value = event.target.value;
    updatedPhoneNumberFields[index].inputPhoneFieldProps["hint"] = "";
    updatedPhoneNumberFields[index].inputPhoneFieldProps["error"] = false;
    if(event.target.value === "Home") {
      updatedPhoneNumberFields[index].inputPhoneFieldProps["hint"] = "You’re not required to own a ‘Home’ phone, however if it’s left blank, our system will auto-populate this field with your mobile or work number.  This will not impact our ability to contact you if your pet is lost and found."
    }
    this.setState({
      phoneNumberFields: updatedPhoneNumberFields,
    })
  }

  handlePhoneInputNumber = (e, index) => {
    let updatedPhoneNumberFields = this.state.phoneNumberFields;
    let phoneNumberElement = e.target.value;
    const phoneElements = phoneNumberElement.split("");
    if (
      phoneNumberElement.length === 4 &&
      updatedPhoneNumberFields[index]["inputPhoneFieldProps"].value.length !== 5
    ) {
      phoneNumberElement =
        phoneNumberElement.substr(0, 3) + "-" + phoneElements[3];
    }

    if (
      phoneNumberElement.length === 8 &&
      updatedPhoneNumberFields[index]["inputPhoneFieldProps"].value.length !== 9
    ) {
      const phoneElements = phoneNumberElement.split("");
      phoneNumberElement =
        phoneNumberElement.substr(0, 7) + "-" + phoneElements[7];
    }

    updatedPhoneNumberFields[index]["inputPhoneFieldProps"].value = phoneNumberElement;
    this.setState({
      phoneNumberFields: [...updatedPhoneNumberFields],
    })
  };

  getPhoneTypeOptions(index) {
    let options = AppConstants.PHONEOPTIONS;
    if (index === 1) {
      let option1 = this.state.phoneNumberFields[0].selectPhoneFieldProps.value;
      options = options.filter((option) => option.value !== option1);
    }
    if (index === 2) {
      let option2 = this.state.phoneNumberFields[0].selectPhoneFieldProps.value;
      let option3 = this.state.phoneNumberFields[1].selectPhoneFieldProps.value;
      options = options.filter(function (option) {
        if (option.value === option2 || option.value === option3) {
          return false
        }
        return true;
      });
    }
    return options;
  }

  setInitialPhoneType(phoneNumbers, index){
    let options = AppConstants.PHONEOPTIONS;
    if (index === 1) {
      let option1 = phoneNumbers[0].selectPhoneFieldProps.value;
      options = options.filter((option) => option.value !== option1);
    }
    if (index === 2) {
      let option2 = phoneNumbers[0].selectPhoneFieldProps.value;
      let option3 = phoneNumbers[1].selectPhoneFieldProps.value;
      options = options.filter(function (option) {
        if (option.value === option2 || option.value === option3) {
          return false
        }
        return true;
      });
    }
    return options;
  }

  setInitialPhoneNumbers  = (phoneNumbers, phoneType, phoneValue) => {
    let index = phoneNumbers.length;
    let updatePhoneNumberFields = [...phoneNumbers];
    if (index !== 0) updatePhoneNumberFields[index - 1].selectPhoneFieldProps["disabled"] = true;
    updatePhoneNumberFields[index] = {
      selectPhoneFieldProps: {
        value: phoneType,
        name: "selectPhoneFieldProps",
        disabled: false,
        options: this.setInitialPhoneType(phoneNumbers, index),
        onChange: (event) => {
          this.changePhoneTypeHandler(event, index)
        }
      },
      inputPhoneFieldProps: {
        value: phoneValue,
        name: "inputPhoneFieldProps",
        placeholder: "###-###-####",
        maxLength: "12",
        error: false,
        hint: "",
        onChange: (event) => {
          this.handlePhoneInputNumber(event, index)
        }
      }
    }
    if(phoneType === "Home") {
      updatePhoneNumberFields[index].inputPhoneFieldProps["hint"] = "You’re not required to own a ‘Home’ phone, however if it’s left blank, our system will auto-populate this field with your mobile or work number.  This will not impact our ability to contact you if your pet is lost and found."
    }

    return updatePhoneNumberFields;
  }

  addNewPhoneNumberField(phoneType, phoneValue) {
    let index = this.state.phoneNumberFields.length;
    let updatePhoneNumberFields = [...this.state.phoneNumberFields];
    if (index !== 0) updatePhoneNumberFields[index - 1].selectPhoneFieldProps["disabled"] = true;
    updatePhoneNumberFields[index] = {
      selectPhoneFieldProps: {
        value: phoneType,
        name: "selectPhoneFieldProps",
        disabled: false,
        options: this.getPhoneTypeOptions(index),
        onChange: (event) => {
          this.changePhoneTypeHandler(event, index)
        }
      },
      inputPhoneFieldProps: {
        value: phoneValue,
        name: "inputPhoneFieldProps",
        placeholder: "###-###-####",
        maxLength: "12",
        error: false,
        hint: "",
        onChange: (event) => {
          this.handlePhoneInputNumber(event, index)
        }
      }
    }
    if(phoneType === "Home") {
      updatePhoneNumberFields[index].inputPhoneFieldProps["hint"] = "You’re not required to own a ‘Home’ phone, however if it’s left blank, our system will auto-populate this field with your mobile or work number.  This will not impact our ability to contact you if your pet is lost and found."
    }
    this.setState({
      phoneNumberFields: [...updatePhoneNumberFields],
    })
  }


  removePhoneNumber(index){
    let phoneNumberFields = this.state.phoneNumberFields;
    let length = this.state.phoneNumberFields.length;
    phoneNumberFields.splice(index, 1);
    if(index !== length-1) {
      phoneNumberFields[index].selectPhoneFieldProps["options"] = this.getPhoneTypeOptions(index);
      phoneNumberFields[index].selectPhoneFieldProps.onChange = (event) => {
        this.changePhoneTypeHandler(event, index)
      }
      phoneNumberFields[index].inputPhoneFieldProps.onChange = (event) => {
        this.handlePhoneInputNumber(event, index)
      }
    }
    if (index === length-1 && index !== 0){
      phoneNumberFields[index - 1].selectPhoneFieldProps["disabled"] = false;
    }
    this.setState({
      phoneNumberFields,
    })
  }

  getPhoneNumbers(payload) {
    let home = "",
        mobile = "",
        work = "";
    for (let number of payload) {
      if (number.value && number.type === "Home" ) home = number.value;
      if (number.value && number.type === "Mobile" ) mobile = number.value;
      if (number.value && number.type === "Work") work = number.value;
    }

    return {
      type: AppConstants.TYPEWISEPHONE[payload[0].type],
      main: payload[0].value,
      home: home,
      mobile: mobile,
      work: work,
    }
  }

  checkValidPhoneNumber(phoneType, value, index) {
    if (!AppUtils.isEmptyString(value)) {
      if (!AppUtils.isValidPhoneNumber(value)) {
        return {
          error: !AppUtils.isValidPhoneNumber(value),
          hint: ValidationMessages.INVALID_PHONE_NUMBER
        }
      }
      return {
        error: false,
        hint: (phoneType === "Home")
        ? "You’re not required to own a ‘Home’ phone, however if it’s left blank, our system will auto-populate this field with your mobile or work number.  This will not impact our ability to contact you if your pet is lost and found."
        : "",
      }
    }

    if (index === 0) {
      return {
        error:true,
        hint:`Please fill out your phone number before continuing.`
      }
    }
    return {
      error: false,
      hint: (phoneType === "Home")
      ? "You’re not required to own a ‘Home’ phone, however if it’s left blank, our system will auto-populate this field with your mobile or work number.  This will not impact our ability to contact you if your pet is lost and found."
      : "",
    }
  }
  validatePhoneNumberValues(){
    let phoneError = false,
        phoneNumberPayload = [],
        updatePhoneNumberFields = this.state.phoneNumberFields;

    this.state.phoneNumberFields.forEach((number, index) => {
      let showError = this.checkValidPhoneNumber(number.selectPhoneFieldProps.value, number.inputPhoneFieldProps.value, index);

      if (showError.error) {
        phoneError = true;
      }
      updatePhoneNumberFields[index].inputPhoneFieldProps.error = showError.error;
      updatePhoneNumberFields[index].inputPhoneFieldProps.hint = showError.hint;
      phoneNumberPayload.push({
        type: number.selectPhoneFieldProps.value,
        value: number.inputPhoneFieldProps.value,
      })
    })

    if (phoneError) {
      this.setState({
        phoneNumbers: updatePhoneNumberFields
      })
      return -1;
    } else {
      return this.getPhoneNumbers(phoneNumberPayload);
    }
  }

  updateContactInfo() {
    let isEmailError = false,
        isPhoneNumberError = false;
    const updateInfo = {};

    // Validations
    if (
      this.state.emailAddress &&
      AppUtils.isValidEmail(this.state.emailAddress)
    ) {
      updateInfo.emailAddress = this.state.emailAddress;
    } else {
      isEmailError = true;
    }

    if (!AppUtils.isEmptyString(this.state.busRelAccount)) {
      updateInfo.busRelAccount = this.state.busRelAccount;
    }

    let phoneNumbers = this.validatePhoneNumberValues();
    if(phoneNumbers === -1){
      isPhoneNumberError=true;
    }

    let primaryPhoneNumber = (phoneNumbers.home)
    ? phoneNumbers.home
    : (phoneNumbers.mobile) ? phoneNumbers.mobile : phoneNumbers.work;

    updateInfo.phoneNumber = phoneNumbers.main;
    updateInfo.phoneType = phoneNumbers.type;
    updateInfo.homePhone = primaryPhoneNumber;
    updateInfo.cellularPhone = phoneNumbers.mobile;
    updateInfo.businessPhone = phoneNumbers.work;

    this.setState({
      isEmailError,
      isPhoneNumberError,
      errorMessage: "",
      successMessage: "",
    });
    if( isEmailError || isPhoneNumberError){
      AppUtils.focusOnDiv("CI_FORM");
      return;
    }
    if (this.state.address) {
      // Update address
      const updatedAddress = {};
      if (!AppUtils.isEmptyString(this.state.firstname)) {
        updatedAddress.firstname = this.state.firstname;
      }
      if (!AppUtils.isEmptyString(this.state.lastname)) {
        updatedAddress.lastname = this.state.lastname;
      }
      if (!AppUtils.isEmptyString(this.state.addressLine1)) {
        updatedAddress.addressLine1 = this.state.addressLine1;
      } else {
        this.setState({
          isAddressLine1Error: true,
        });
      }
      if (this.state.addressLine2) {
        updatedAddress.addressLine2 = this.state.addressLine2;
      }
      if (this.state.city) {
        updatedAddress.city = this.state.city;
      }
      if (this.state.state) {
        updatedAddress.state = this.state.state;
      }
      if (this.state.countryregionid) {
        updatedAddress.countryregionid = this.state.countryregionid;
      }
      if (this.state.zipcode) {
        updatedAddress.zipcode = this.state.zipcode;
      }
      updateInfo.address = updatedAddress;
      updateInfo.contactConsent = this.state.contactConsent;
      updateInfo.petReleaseinformation = this.state.petReleaseinformation;
    }

    window.qas_callbackFunc = (address) => {
      if (address) {
        this.setState(
          {
            addressLine1: address.street.join(" "),
            addressLine2: "",
            city: address.city,
            state: address.region,
            zipcode: address.postcode,
          },
          () => {
            updateInfo.address.state = address.region;
            updateInfo.address.addressLine1 = address.street.join(" ");
            updateInfo.address.city = address.city;
            updateInfo.address.state = address.region;
            updateInfo.address.zipcode = address.postcode;

              LoadMask.showPreloader(this.refs.divRef);
              axios
                .processPost(
                  `${ApiConstants.URL_POST_CONTACT_INFO}`,
                  this.cref,
                  updateInfo
                )
                .then((response) => {
                  if (this.state.isEmailChanged) {
                    AppUtils.changeEmailAddress(updateInfo.emailAddress);
                    this.props.isEmailChanged(true);
                    this.setState({
                      isEmailChanged: false,
                    });
                  }
                  this.getContactInfo(true);
                })
                .catch((err) => {
                  let errors = err?.response?.data?.errors;
                  if (errors && errors.length > 0) {
                    this.setState({
                      errorMessage: errors[0],
                    });
                    DataLayerUtils.formErrorEvent("Contact info", null, errors[0]);
                  } else {
                    this.setState({
                      errorMessage: "Error while updating the info!",
                    });
                    DataLayerUtils.formErrorEvent("Contact info", null, "Error while updating the info!");
                  }
                })
                .finally(() => {
                  LoadMask.hidePreloader(this.refs.divRef);
                  AppUtils.focusOnDiv("CI_FORM");
                });
          }
        );
      }
    };
    // Address Verification Step

    window.qasVerify(true);
  }

  componentDidMount() {
    this.setState({
      phoneNumberFields: [],
    })
    this.getContactInfo();
  }

  shouldShowInfo() {
    const isMember = this.props.customerProfile?.status?.isMember;
    const hasActiveInsurance =
      this.props.customerProfile?.status?.hasActiveInsurance;
    const isAllActiveInsuredPetsLost =
      this.props.customerProfile?.status?.allActiveInsuredPetsLost;

    this.setState({
      hasActiveInsurance,
    });
    let shouldShowCommonFields = false;
    if (
      isMember === true ||
      (hasActiveInsurance === true && isAllActiveInsuredPetsLost === false) ||
      (isMember === false && hasActiveInsurance === false)
    ) {
      shouldShowCommonFields = true;
    }
    this.setState({
      shouldShowCommonFields,
    });
  }

  updatePaperlessStatusToTrue() {
    LoadMask.showPreloader(this.refs.paperlessRef);
    axios
      .processPost(
        `${ApiConstants.URL_POST_UPDATE_PAPERLESS}/${this.state.busRelAccount}/true`
      )
      .then((response) => {
        this.setState({
          paperless: true,
          successPaperlessMessage: "Paper less status updated successfully!",
        });
      })
      .catch((error) => {
        let errors = error?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorPaperlessMessage: errors[0],
          });
        } else {
          this.setState({
            errorPaperlessMessage: "Oops, an error has occured",
          });
        }
      })
      .finally(() => {
        LoadMask.hidePreloader(this.refs.paperlessRef);
        AppUtils.focusOnDiv("PL_FORM");
      });
  }

  getOptInPaperlessCard() {
    if (this.state.hasActiveInsurance) {
      if (!this.state.paperless) {
        return (
          <GoPaperlessCard
            enableEmoji={FeatureFlagUtils.getIsEmojiEnabled()}
            optInToPaperLessButtonProps={{
              id: "paperlessCard",
              color: Button.Colors.intermediate,
              onClick: () => {
                this.updatePaperlessStatusToTrue();
              },
            }}
            errorMessage={this.state.errorPaperlessMessage}
            successMessage={this.state.successPaperlessMessage}
          ></GoPaperlessCard>
        );
      } else {
        return (
          <PaperlessBillingCard
            enableEmoji={FeatureFlagUtils.getIsEmojiEnabled()}
            phoneNumber={FeatureFlagUtils.getPhoneNumber()}
          ></PaperlessBillingCard>
        );
      }
    } else {
      return <div></div>;
    }
  }

  getLocale() {
    if (this.state.countryregionid.toLowerCase() === "us") {
      return UserContactInfoCard.Locale.us;
    } else {
      return UserContactInfoCard.Locale.ca;
    }
  }

  getStatesOrProvinces() {
    if (this.state.countryregionid.toLowerCase() === "us") {
      return AppConstants.STATES;
    } else {
      return AppConstants.PROVINCES;
    }
  }
  render() {
    const optionStates = this.getStatesOrProvinces();

    return (
      <React.Fragment>
        <div ref="divRef" id="CI_FORM">
          <LoginInfoChangeWarningModal
            message={FeatureFlagUtils.getContactInfoChangeWarning()}
            open={this.state.isChangeWarningOpen}
            onClose={() => {
              this.setState({
                isChangeWarningOpen: false,
              });
            }}
            cancelButtonProps={{
              variant:(FeatureFlagUtils.shouldShowInlineVariant())?Button.Variants.inline:Button.Variants.outlined,
              color: (FeatureFlagUtils.shouldShowInlineVariant())?Button.Colors.secondary:Button.Colors.tertiary,
              onClick: () => {
                this.setState({
                  isChangeWarningOpen: false,
                });
              },
            }}
            yesButtonProps={{
              onClick: () => {
                this.setState({
                  isChangeWarningOpen: false,
                });
                this.updateContactInfo();
              },
            }}
          />

          <UserContactInfoCard
            id="UpdateAccountForm"
            successMessage={this.state.successMessage}
            errorMessage={this.state.errorMessage}
            locale={this.getLocale()}
            isPetHealthProgram={AppUtils.isPethealthProgram()}
            onFormSubmit={() => {}}
            phoneNumberFieldsProps={this.state.phoneNumberFields}
            phoneLinkProps={{
              className: "dd-text-link-override",
              onClick: () => {
                let index = this.state.phoneNumberFields.length;
                let options = this.getPhoneTypeOptions(index);
                this.addNewPhoneNumberField(options[0].value, "");
              }
            }}
            showAddPhoneLink={ (this.state.phoneNumberFields.length <= 2) ? true : false }
            closePhoneNumberProps={(index) => {
              this.removePhoneNumber(index);
            }}
            emailInputFieldProps={{
              id: "CI_email",
              value: this.state.emailAddress,
              name: "emailAddress",
              error: this.state.isEmailError,
              hint: this.state.isEmailError
                ? ValidationMessages.EMAIL_VALIDATION_MESSAGE
                : "",
              onChange: this.handleInputChange,
            }}
            firstNameInputFieldProps={{
              id: "CI_firstname",
              value: this.state.firstname,
              name: "firstname",
              disabled: true,
              hidden: FeatureFlagUtils.isHideUserAddressField(),
            }}
            lastNameInputFieldProps={{
              id: "CI_lastname",
              value: this.state.lastname,
              name: "lastname",
              disabled: true,
              hidden: FeatureFlagUtils.isHideUserAddressField(),
            }}
            countrySelectFieldProps={{
              value: this.state.countryregionid.toLowerCase(),
              options: AppConstants.COUNTRIES,
              name: "countryregionid",
              id: "UpdateAccountInfo_Country",
              hint: this.state.hasActiveInsurance ? FeatureFlagUtils.getInsuranceRestrictionMessage():"",
              disabled:
                this.state.hasActiveInsurance ||
                !this.state.shouldShowCommonFields,
              onChange: this.handleInputChange,
              hidden: FeatureFlagUtils.isHideUserAddressField(),
            }}
            address1InputFieldProps={{
              value: this.state.addressLine1,
              name: "addressLine1",
              id: "UpdateAccountInfo_Street1",
              disabled:
                this.state.hasActiveInsurance ||
                !this.state.shouldShowCommonFields,
              error: this.state.isAddressLine1Error,
              hint: this.state.isAddressLine1Error
                ? ValidationMessages.EMPTY_VALUE
                : "",
              onChange: this.handleInputChange,
              hidden: FeatureFlagUtils.isHideUserAddressField(),
            }}
            address2InputFieldProps={{
              value: this.state.addressLine2,
              id: "UpdateAccountInfo_Street2",
              disabled:
                this.state.hasActiveInsurance ||
                !this.state.shouldShowCommonFields,
              name: "addressLine2",
              onChange: this.handleInputChange,
              hidden: FeatureFlagUtils.isHideUserAddressField(),
            }}
            cityInputFieldProps={{
              value: this.state.city,
              name: "city",
              id: "UpdateAccountInfo_City",
              disabled:
                this.state.hasActiveInsurance ||
                !this.state.shouldShowCommonFields,
              onChange: this.handleInputChange,
              hidden: FeatureFlagUtils.isHideUserAddressField(),
            }}
            stateSelectFieldProps={{
              value: this.state.state,
              options: optionStates,
              id: "UpdateAccountInfo_State",
              name: "state",
              hint: this.state.hasActiveInsurance ? FeatureFlagUtils.getInsuranceRestrictionMessage():"",
              disabled:
                this.state.hasActiveInsurance ||
                !this.state.shouldShowCommonFields,
              onChange: (event) => {
                const stateValue = event.target.value;
                this.setState({
                  state: stateValue,
                });
              },
              hidden: FeatureFlagUtils.isHideUserAddressField(),
            }}
            zipInputFieldProps={{
              value: this.state.zipcode,
              disabled:
                this.state.hasActiveInsurance ||
                !this.state.shouldShowCommonFields,
              name: "zipcode",
              id: "UpdateAccountInfo_ZipCode",
              onChange: this.handleInputChange,
              hidden: FeatureFlagUtils.isHideUserAddressField(),
            }}
            saveChangesButtonProps={{
              id: "saveButtonId",
              onClick: () => {
                if (this.state.emailAddress !== this.state.oldEmailAddress) {
                  this.setState({
                    isEmailChanged: true,
                    isChangeWarningOpen: true,
                  });
                } else {
                  this.updateContactInfo();
                }
              },
              hidden: FeatureFlagUtils.isHideUserAddressField(),
            }}
            checkboxProps={{
              label: FeatureFlagUtils.getCheckBoxConsentText(),
              checked: this.state.contactConsent,
              onChange: () => {
                if (!this.state.contactConsent) {
                  DataLayerUtils.consentToMarketingEvent(AppUtils.getUserRelationId())
                }
                this.setState({
                  contactConsent: !this.state.contactConsent,
                });
              },
            }}
            petParentInfoConsentProps={{
              checked: this.state.petReleaseinformation,
              onChange: () => {
                this.setState({
                  petReleaseinformation: !this.state.petReleaseinformation,
                });
              },
            }}
          ></UserContactInfoCard>
        </div>
        <EmergencyContact></EmergencyContact>
        <AccountPreference></AccountPreference>
        <div ref="paperlessRef" id="PL_FORM">
          {this.getOptInPaperlessCard()}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    customerProfile: customerProfileStore.customerProfile,
  };
};

export default connect(mapStateToProps, null)(ContactInfo);

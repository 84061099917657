import React from 'react'
import PropTypes from 'prop-types'

import { Input, Icon } from '../../'

export class PasswordInput extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showPassword: false
    }
  }

  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    name: PropTypes.string,
    error: PropTypes.bool,
    hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    id: PropTypes.string
  }

  render() {
    const {
      value,
      onChange,
      onBlur,
      onFocus,
      name,
      error,
      hint,
      id,
      ...rest
    } = this.props

    const { showPassword } = this.state

    return (
      <Input
        type={showPassword ? 'text' : 'password'}
        rightIcon={
          <VisibilityIcon
            showPassword={showPassword}
            onClick={() => this.setState({ showPassword: !showPassword })}
          />
        }
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        error={error}
        hint={hint}
        id={id}
        {...rest}
      />
    )
  }
}

const VisibilityIcon = ({ showPassword, onClick }) => {
  const type = showPassword ? 'visibility_off' : 'visibility'

  return (
    <button
      type='button'
      onClick={onClick}
      className='ui-password-input__icon-button'
    >
      <Icon type={type} />
    </button>
  )
}

VisibilityIcon.propTypes = {
  showPassword: PropTypes.bool,
  onClick: PropTypes.func
}

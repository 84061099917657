import React from "react";
import { PaymentCompleteCard, PageContent } from "../../../UI-Component-Library";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { clearCartData } from "../../../redux/reducers/membership-purchase-reducer";
import { MembershipPurchaseUtils } from "../membership/membership-purchase.util";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
import { AppUtils } from "../../../utils/app-utils";
class PaymentCompleteComponent extends React.Component {
  componentDidMount() {
    const orderItems = this.props.location.state;
    const cartData = this.props.cartData;
    AppUtils.triggerAdobeTrackEvent();
    DataLayerUtils.purchaseEvent(
      orderItems,
      cartData?.total,
      cartData?.taxes,
      this.props.promoCode,
      cartData?.shipping,
    );
    MembershipPurchaseUtils.removeCartDataFromLocalStorage();
    this.props.clearCartData();
  }
  render() {
    return (
      <PageContent>
        <PaymentCompleteCard
          userFirstName={this.props.customerName}
          message="Thank you for your order. If your purchase includes tags, you will receive them in approximately 4-6 weeks."
          continueButtonProps={{
            id: "continue-button",
            onClick: () => {
              MembershipPurchaseUtils.removeCartDataFromLocalStorage();
              this.props.clearCartData();
              this.props.history.push("/pages");
            },
          }}
        />
      </PageContent>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  const membershipPurchaseStore = state.membershipPurchaseStore;

  return {
    customerName: customerProfileStore.customerName,
    cartData: membershipPurchaseStore.cartData,
    promoCode: membershipPurchaseStore.promoCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearCartData: () => dispatch(clearCartData()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentCompleteComponent));

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Step } from './StepsComponents'
import { Link } from '../Link'

export class Steps extends React.PureComponent {
  static propTypes = {
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        active: PropTypes.bool,
        completed: PropTypes.bool,
        onClick: PropTypes.func
      })
    ),
    showSteps: PropTypes.bool,
    showLink: PropTypes.bool,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    linkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    })
  }

  static defaultProps = {
    steps: []
  }

  render() {
    const {
      steps,
      id,
      className,
      style,
      refProp,
      showSteps,
      showLink,
      linkProps
    } = this.props

    return (
      <div
        id={id}
        className={cn('ui-steps__container', className)}
        style={style}
        ref={refProp}
      >
        {showSteps &&
          steps.map((step, i) => (
            <Step key={step.label} stepNumber={i + 1} {...step} />
          ))}
        {showLink && (
          <Link {...linkProps} color={Link.Colors.secondary}>
            {'<  Back to Review'}
          </Link>
        )}
      </div>
    )
  }
}

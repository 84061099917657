import React from "react";
import { PageContent, FAQ, FAQMerck, FAQGeneric, FAQPHCA, FAQSPOTCA } from "../../../UI-Component-Library";
import { useDispatch } from "react-redux";
import { handleOpenClaim } from "../../../redux/actions/globalActions";
import { AppConstants } from "./../../../utils/app-constants";
import { AppUtils } from "../../../utils/app-utils";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";

const FAQComponent = () => {
  const dispatch = useDispatch();

  const handleClaim = () => {
    dispatch(handleOpenClaim());
  };

  const handleFag = () => {
    switch (AppConstants.CONFIG_JSON?.Program?.toLowerCase()) {
      case "pethealth":
        if(AppUtils.isUsCountry()) {
          return <FAQ handleClaim={handleClaim} />
        } else {
          return <FAQPHCA handleClaim={handleClaim} />
        }
      case "merck":
        return <FAQMerck handleClaim={handleClaim}/>;
      case "spot":
        return <FAQSPOTCA handleClaim={handleClaim}/>
      default:
        return <FAQGeneric 
          handleClaim={handleClaim}
          phoneNumber={FeatureFlagUtils.getFaxNumber()}
          mail={FeatureFlagUtils.getFAQMail()}
          responseDays={FeatureFlagUtils.getResponseDays()}
          checkListLink={FeatureFlagUtils.getFAQChecklist()}
          preApprovalLinks={FeatureFlagUtils.getFAQPreApprovalLink()}
          hideMPHSections={FeatureFlagUtils.hideMphFAQSections()}
        ></FAQGeneric>
    }
  };

  return <PageContent>{handleFag()}</PageContent>;
};

export default FAQComponent;

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Icon } from '../Icon'
import { Hint } from '../Input/InputComponents'

export class FileInput extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    capture: PropTypes.string,
    accept: PropTypes.string,
    label: PropTypes.any,
    hint: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    error: PropTypes.bool,
    fileName: PropTypes.string,
    onChange: PropTypes.func,
    /**
     * `id` is required prop! without it file input might now work
     */
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      id,
      label,
      capture,
      accept,
      fileName,
      onChange,
      className,
      style,
      refProp,
      hint,
      error
    } = this.props

    return (
      <div
        className={cn('ui-file-input', className, {
          'ui-file-input--empty': !fileName,
          'ui-file-input--error': error
        })}
        style={style}
      >
        <input
          id={id}
          ref={refProp}
          type='file'
          onChange={onChange}
          capture={capture}
          accept={accept}
        />
        <span className='ui-file-input__label'>{label}</span>
        <label htmlFor={id}>
          <span>{fileName ? fileName : 'Select a file'}</span>

          <Icon type='attach_file' />
        </label>

        {hint && <Hint hint={hint} className={cn('ui-file-input__hint')} />}
      </div>
    )
  }
}

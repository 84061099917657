import React from "react";
import {
  ContactInfoCard,
  TwoColumnContent,
  MessageUsCard,
  PageContent,
  Banner,
  Text,
  Link
} from "../../../UI-Component-Library";
import { AppUtils } from "../../../utils/app-utils";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
export class ContactUs extends React.Component {
  constructor() {
    super();
    this.state = {
      reasons: [],
      reasonError: false,
      note: "",
      noteError: false,
      errorMessage: "",
      selectedReason: "",
      isShowSuccessMessage: false,
    };
    this.cRef = React.createRef();
  }
  fetchEnquiryList() {
    axios
      .processGet(ApiConstants.URL_GET_ENQUIRY_LIST, this.cRef)
      .then((response) => {
        const data = response.data;
        data.sort((a, b) => (a.Key > b.Key ? 1 : -1));
        if (data) {
          const reasons = data.map((item) => {
            return { value: "" + item.Key, label: item.Value };
          });
          this.setState({
            reasons: [{ value: "", label: "Select One" }, ...reasons],
          });
        }
      })
      .catch((error) => {
        let errors = error?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
        }
      });
  }
  componentDidMount() {
    AppUtils.focusOnDiv("root");
    this.fetchEnquiryList();
  }
  componentDidUpdate() {
    AppUtils.focusOnDiv("root");
  }
  changeReason(event) {
    this.setState({
      selectedReason: event.target.value,
    });
  }
  handleNoteChange(e) {
    this.setState({
      note: e.target.value,
    });
  }
  handleSubmit() {
    let noteError = false,
      reasonError = false;
    if (AppUtils.isEmptyString(this.state.note)) {
      noteError = true;
    }
    if (AppUtils.isEmptyString(this.state.selectedReason)) {
      reasonError = true;
    }
    this.setState({
      noteError,
      reasonError,
    });
    if (!noteError && !reasonError) {
      axios
        .processPost(ApiConstants.URL_POST_CONTACT_US, this.cRef, {
          busRelAccount: AppUtils.getBusRelAccount(),
          country: AppUtils.getCountryCode(),
          emailAddress: AppUtils.getUserEmail(),
          contactReason: parseInt(this.state.selectedReason),
          helpText: this.state.note,
          contactUsEmailDestination: AppUtils.getUserEmail(),
        })
        .then((response) => {
          this.setState({
            isShowSuccessMessage: true,
            selectedReason: "",
            note: "",
          });
        })
        .catch((error) => {
          let errors = error?.response?.data?.errors;
          if (errors && errors.length > 0) {
            this.setState({
              errorMessage: errors[0],
            });
            DataLayerUtils.formErrorEvent("Message us", null, errors[0]);
          }
        });
    }
  }
  render() {
    return (
      <div>
        {this.state.isShowSuccessMessage && (
          <Banner
            title="Your message has been successfully sent."
            color={Banner.Colors.success}
          />
        )}
        <PageContent>
          <TwoColumnContent
            leftColumn={
              <div ref={this.cRef}>
                <MessageUsCard
                  errorMessage={this.state.errorMessage}
                  selectProps={{
                    id: "reason",
                    name: "reason",
                    value: this.state.selectedReason,
                    error: this.state.reasonError,
                    hint: this.state.reasonError ? "Select one" : "",
                    options: this.state.reasons,
                    onChange: (event) => {
                      this.changeReason(event);
                    },
                  }}
                  noteProps={{
                    placeholder: "How Can We Help You?",
                    value: this.state.note,
                    onChange: (e) => {
                      this.handleNoteChange(e);
                    },
                    name: "note",
                    error: this.state.noteError,
                    hint: this.state.noteError ? "Cannot be empty" : "",
                    id: "note_input",
                  }}
                  submitButtonProps={{
                    id: "note_submit-button",
                    onClick: () => {
                      this.handleSubmit();
                    },
                  }}
                />
              </div>
            }
            rightColumn={
              <ContactInfoCard
                header={
                  FeatureFlagUtils.showCustomContactHeader()
                  ? <Text><Link href={'tel:' + FeatureFlagUtils.getPhoneNumber()}>{FeatureFlagUtils.getPhoneNumber()}</Link></Text>
                  : null
                }
                phoneNumber={FeatureFlagUtils.getPhoneNumber()}
                salesText={FeatureFlagUtils.getSalesText()}
                isPethealthProgram={AppUtils.isPethealthProgram()}
                locale={
                  AppUtils.isUsCountry()
                    ? ContactInfoCard.Locale.us
                    : ContactInfoCard.Locale.ca
                }
                companyName={FeatureFlagUtils.getCompanyNameInContactUs()}
                hoursOfOperationContent={FeatureFlagUtils.getHoursOfOperationContent(FeatureFlagUtils.showHoursOfOperationContent())}
              />
            }
          />
        </PageContent>
      </div>
    );
  }
}

import React, { Component } from "react";
import {
  RmCreatePassword,
  RmPageHeader,
  RmPageFooter,
  PageContent,
  RmPasswordCreationSuccess,
} from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import { Redirect } from "react-router-dom";
import { LoadMask } from "../../../utils/loadmask.util";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";

class PasswordCreation extends Component {
  constructor(props) {
    super(props);

    const search = props.location.search;
    const params = new URLSearchParams(search);

    const userId = params.get("userId");
    const code = params.get("code");

    this.state = {
      password: "",
      confirmPassword: "",
      userId: userId,
      code: code,
      isPasswordSubmitSuccessful: false,
      isConsent: false,
      errorMessage: "",
      firstName: "",
      isLoginClicked: false,
      passwordErrorMessage: "",
    };
  }

  validateUserAndEmailConfirmCode = () => {
    const payload = {
      userId: this.state.userId,
      code: this.state.code,
    };

    axios
      .processPost(
        `${ApiConstants.URL_VALIDATE_USER_CONFIRM_CODE}`,
        null,
        payload
      )
      .then((response) => {})
      .catch((error) => {
        this.setState({
          errorMessage: "Error while user validation",
        });
      });
  };

  componentDidMount() {
    this.validateUserAndEmailConfirmCode();
    DataLayerUtils.createAccountEvent(
      'microchip',
      'create password',
      'organic'
    )
  }

  postPassword = () => {
    const payload = {
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      userId: this.state.userId,
      emailOptIn: this.state.isConsent,
    };

    LoadMask.showPreloader(this.refs.pass);
    axios
      .processPost(`${ApiConstants.URL_CONFIRM_PASSWORD}`, null, payload)
        .then((response) => {
        this.setState({
          firstName: response.data,
          isPasswordSubmitSuccessful: true,
        });
          LoadMask.hidePreloader(this.refs.pass);
          DataLayerUtils.accountVerificationSubmitPassword(this.state.userId);
          DataLayerUtils.createAccountSuccessEvent(
            "microchip",
            this.state.userId,
            null, // no petId
            "organic",
            null // no sign up method
          );
      })
      .catch((error) => {
        let errors = error?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            passwordErrorMessage: errors[0],
          });
          DataLayerUtils.formErrorEvent("create password", null, errors[0]);
        }
        LoadMask.hidePreloader(this.refs.pass);
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  isValidNumberOfCharacters = () => {
    if (this.state.password.length >= 8) {
      return true;
    }
    return false;
  };

  isOneNumber = () => {
    return /\d/.test(this.state.password);
  };

  isOneUpperCase = () => {
    return /[A-Z]/.test(this.state.password);
  };

  isOneLowerCase = () => {
    return /[a-z]/.test(this.state.password);
  };

  showValidations = () => {
    if (this.state.password.length > 0) {
      return true;
    }
    return false;
  };

  isValidPassword = () => {
    if (
      this.isOneLowerCase() &&
      this.isOneNumber() &&
      this.isOneUpperCase() &&
      this.isValidNumberOfCharacters() &&
      this.state.confirmPassword === this.state.password
    ) {
      return false;
    } else {
      return true;
    }
  };

  isSamePassword = () => {
    if (
      this.state.confirmPassword.length > 0 &&
      this.state.password.length > 0
    ) {
      if (this.state.confirmPassword === this.state.password) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  getComponent = () => {
    const isValidNumberOfCharacters = this.isValidNumberOfCharacters();
    const isOneNumberValid = this.isOneNumber();
    const isOneUpperCaseValid = this.isOneUpperCase();
    const isOneLowerCaseValid = this.isOneLowerCase();

    if (!this.isPasswordSubmitSuccessful) {
      return (
        <div ref="pass">
          <RmCreatePassword
            errorMessage={this.state.passwordErrorMessage}
            passwordInputProps={{
              name: "password",
              label: "Password",
              value: this.state.password,
              id: "createPassword",
              type: "password",
              onChange: (event) => {
                this.handleChange(event);
              },
            }}
            confirmPasswordInputProps={{
              name: "confirmPassword",
              label: "Confirm Password",
              id: "confirmPassword",
              value: this.state.confirmPassword,
              type: "password",
              error: !this.isSamePassword(),
              hint: !this.isSamePassword() ? "Password does not match" : "",
              onChange: (event) => {
                this.handleChange(event);
              },
            }}
            submitButtonProps={{
              id: "submitPasswordBtn",
              disabled: this.isValidPassword(),
              onClick: (event) => {
                this.postPassword();
              },
            }}
            consentCheckboxProps={{
              checked: this.state.isConsent,
              onChange: (event) => {
                if (!this.state.isConsent) {
                  DataLayerUtils.consentToMarketingEvent(this.state.userId);
                }
                this.setState({
                  isConsent: !this.state.isConsent,
                });
              },
              name: "consent",
              id: "consent",
            }}
            showValidations={this.showValidations()}
            is8CharsEntered={isValidNumberOfCharacters}
            isAtLeastOneNumberEntered={isOneNumberValid}
            isUppercaseEntered={isOneUpperCaseValid}
            isLowercaseEntered={isOneLowerCaseValid}
          ></RmCreatePassword>
        </div>
      );
    }
  };
  render() {
    if (this.state.isLoginClicked) {
      return (
        <Redirect
          to={{
            pathname: "/auth/login",
          }}
        ></Redirect>
      );
    }
    this.showValidations();

    const isPasswordSubmitSuccessful = this.state.isPasswordSubmitSuccessful;
    if (isPasswordSubmitSuccessful) {
      return (
        <React.Fragment>
          <RmPageHeader></RmPageHeader>
          <PageContent>
            <RmPasswordCreationSuccess
              userFirstName={this.state.firstName}
              loginNowButtonProps={{
                id: "loginButton",
                onClick: (event) => {
                  this.setState({
                    isLoginClicked: true,
                  });
                },
              }}
            ></RmPasswordCreationSuccess>
          </PageContent>
          <RmPageFooter
            aboutUsButtonProps={{
              style: {
                cursor: "pointer",
              },
              onClick: () => {
                window.open("https://www.24petwatch.com/about-us");
              },
            }}
            contactUsButtonProps={{
              style: {
                cursor: "pointer",
              },
              onClick: () => {
                window.open("https://www.24petwatch.com/contact-us");
              },
            }}
          ></RmPageFooter>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <RmPageHeader></RmPageHeader>
        <PageContent>{this.getComponent()}</PageContent>
        <RmPageFooter
          aboutUsButtonProps={{
            style: {
              cursor: "pointer",
            },
            onClick: () => {
              window.open("https://www.24petwatch.com/about-us");
            },
          }}
          contactUsButtonProps={{
            style: {
              cursor: "pointer",
            },
            onClick: () => {
              window.open("https://www.24petwatch.com/contact-us");
            },
          }}
        ></RmPageFooter>
      </React.Fragment>
    );
  }
}

export default PasswordCreation;

import { Route, Redirect } from "react-router-dom";
import React, { Component } from "react";
import { AppUtils } from "./app-utils";

class PrivateRoute extends Component {
  render() {
    return (
      <Route
        {...this.props.rest}
        render={({ location }) =>
          AppUtils.isAuthenticated() ? (
            this.props.children
          ) : (
            <Redirect
              to={{
                pathname: "/auth",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
}
export default PrivateRoute;

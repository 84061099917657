import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  PaperContent,
  Paper,
  PaperHeadline,
  Accordion,
  AccordionGroup,
  Text,
  Link,
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableDataCell
} from '../../'
import { DataLayerUtils } from '../../../utils/dataLayer-utils'

export class FAQ extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    handleClaim: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      open: ''
    }
  }

  handleOpen = (identifier, text) => {
    return () => {
      if (identifier !== this.state.open) {
        DataLayerUtils.faqEvent(text);
      }
      this.setState({ open: identifier === this.state.open ? '' : identifier })
    }
  }

  render() {
    const { open } = this.state

    const { id, className, style, refProp, handleClaim } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn(className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Frequently Asked Questions</PaperHeadline>

        <PaperContent>
          <AccordionGroup title='About Insurance:'>
            <Accordion
              title='How do I know if my pet’s treatments will be covered?'
              openContent={open === 'a1'}
              onContentOpenChange={this.handleOpen('a1', 'How do I know if my pet’s treatments will be covered?')}
            >
              <br></br>
              <Link
                href='/pdfs/24PW_ClaimsChecklist_Brochure_R6_USA.pdf'
                target='_blank'
              >
                Making a Claim Checklist
              </Link>

              <br></br>
              <br></br>
              <Text>
                For those seeking additional peace of mind, there is a
                pre-approval service available. When requesting a pre-approval,
                you will need to send us all required documentation, including
                an estimate of cost. Our team will respond outlining the
                coverage you have available and any applicable limits for these
                treatments under your selected insurance policy. Please select
                your insurance brand from the list below for your pre-approval
                form.
              </Text>

              <ul>
                <li>
                  <Link
                    href='/pdfs/24PetWatch_Pre-approval_USA.pdf'
                    target='_blank'
                  >
                    24Petwatch US
                  </Link>
                </li>
                {/* <li>
                  <Link
                    href='/pdfs/24PetWatch_Pre-approval_CANADA.pdf'
                    target='_blank'
                  >
                    24Petwatch Canada
                  </Link>
                </li> */}
              </ul>

              <Text>
                {/* TODO REPLACE LINKS */}
                Please complete and submit the pre-approval form, along with an
                estimate of charges and applicable medical history. You can also
                fax these documents to{' '}
                <Link href='tel:18664282199'>1-866-428-2199</Link> or by email
                to{' '}
                <Link href='mailto:medicals@pethealthinc.com'>
                  medicals@pethealthinc.com
                </Link>
                . A response will be provided within 2 business days of receipt
                of all required documentation.
              </Text>
            </Accordion>

            <Accordion
              title='I got my updated claim status, but what does it mean?'
              openContent={open === 'a2'}
              onContentOpenChange={this.handleOpen('a2', 'I got my updated claim status, but what does it mean?')}
            >
              <Text>
                Every claim goes through a number of stages in the assessment
                process. Here is a helpful guide to explain what your current
                claim status means.
              </Text>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell>Meaning</TableHeaderCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableDataCell>Claim in Process</TableDataCell>
                    <TableDataCell>
                      All of your documents and claim details are in our system.
                      We call this step "pre-processing".
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>Claim in Review</TableDataCell>
                    <TableDataCell>
                      Your claim is with our claims adjusters.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>
                      Claim on Hold Waiting for Docs
                    </TableDataCell>
                    <TableDataCell>
                      Uh-oh, something was missing from your claim submission.
                      Don’t worry – we're on the case. We will either reach out
                      to you or your pet's vet clinic for more information.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>
                      1st reminder / 2nd Reminder / Final reminder
                    </TableDataCell>
                    <TableDataCell>
                      If we’re in the process of collecting missing information,
                      we want to keep you updated. We may reach out to you a few
                      times to remind you.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>Completed</TableDataCell>
                    <TableDataCell>
                      Your claim is complete. This means our adjusters have
                      finished your claim and you will receive your formal
                      Explanation of Benefits from us shortly.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>Cancelled</TableDataCell>
                    <TableDataCell>
                      Your claim has been cancelled and removed from processing.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>Cancelled - Duplicate</TableDataCell>
                    <TableDataCell>
                      When we receive duplicate claims or when all invoices for
                      a claim are duplicates, the claim will be cancelled.
                    </TableDataCell>
                  </TableRow>

                  <TableRow>
                    <TableDataCell>Claim Closed</TableDataCell>
                    <TableDataCell>
                      When something was deemed missing from your claim, and we
                      were unable to connect with your or your vet clinic for 30
                      days, your claim will be closed. You will have the
                      opportunity to resubmit your claim when you have the
                      missing documents.
                    </TableDataCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Accordion>

            <Accordion
              title='What is a pre-existing condition?'
              openContent={open === 'a3'}
              onContentOpenChange={this.handleOpen('a3', 'What is a pre-existing condition?')}
            >
              <Text>
                A condition is considered pre-existing if it first occurred or
                showed signs or symptoms of, was treated, or diagnosed prior to
                your pet being covered by insurance.
              </Text>
            </Accordion>

            <Accordion
              title='Do your plans cover dental?'
              openContent={open === 'a4'}
              onContentOpenChange={this.handleOpen('a4', 'Do your plans cover dental?')}
            >
              <Text>
                This will depend on which policy you have purchased. Let’s talk!
                Call us at 1-866-597-2424 to find out more.
              </Text>
            </Accordion>

            <Accordion
              title='How will I receive my reimbursement?'
              openContent={open === 'a5'}
              onContentOpenChange={this.handleOpen('a5', 'How will I receive my reimbursement?')}
            >
              <Text>
                Reimbursement is provided via check or direct deposit, depending
                on the option you have selected.
              </Text>
            </Accordion>
          </AccordionGroup>

          <AccordionGroup title='About Lost Pet Services:'>
            <Accordion
              title='How do I report a lost pet?'
              openContent={open === 'a6'}
              onContentOpenChange={this.handleOpen('a6', 'About Lost Pet Services:')}
            >
              <Text>
              To report a lost pet, go to the 'Pets' tab on the left side menu, select the pet
              that you need to report and click on the 'Report as Missing' button.
              Complete the form at any time, day or night. Please have your microchip number
              ready in order to complete the report.
              </Text>
            </Accordion>

            <Accordion
              title='What happens after I report a pet lost?'
              openContent={open === 'a7'}
              onContentOpenChange={this.handleOpen('a7', 'What happens after I report a pet lost?')}
            >
              <Text>
                Once you have submitted a lost pet report, you will receive a
                confirmation email to the email address provided in the report.
                A Pet Protection Specialist will search our database and run a
                real-time search of all animals reported found. You will be
                immediately notified when your pet is located and we'll assist
                in reuniting you as safely as possible.
              </Text>
            </Accordion>

            <Accordion
              title='How do I know if someone has found my pet?'
              openContent={open === 'a8'}
              onContentOpenChange={this.handleOpen('a8', 'How do I know if someone has found my pet?')}
            >
              <Text>
                As soon as your missing pet is reported found, we will contact
                you at the primary phone number and/or email address provided on
                your profile. We will include information on how you can reunite
                with your pet.
              </Text>
            </Accordion>

            <Accordion
              title='Do I need to close my lost pet report?'
              openContent={open === 'a9'}
              onContentOpenChange={this.handleOpen('a9', 'Do I need to close my lost pet report?')}
            >
              <Text>
                Yes. We need to know you’ve been reunited with your pet,
                otherwise the report will remain open and you will continue to
                receive notifications.
              </Text>
            </Accordion>

            <Accordion
              title='What happens if my pet is taken to a shelter, veterinary clinic or rescue centre?'
              openContent={open === 'a10'}
              onContentOpenChange={this.handleOpen('a10', 'What happens if my pet is taken to a shelter, veterinary clinic or rescue centre?')}
            >
              <Text>
                Animals that are brought to a shelter, vet clinic or rescue are
                routinely scanned for a microchip. If your pet is microchipped,
                the staff will know to contact 24Petwatch®. You will be
                immediately notified when your pet is located and we'll assist
                in reuniting you as safely as possible.
              </Text>
            </Accordion>
          </AccordionGroup>

          <AccordionGroup title='About Customer Portal:'>
            <Accordion
              title='How will I know if I have submitted my claim incorrectly?'
              openContent={open === 'a11'}
              onContentOpenChange={this.handleOpen('a11', 'How will I know if I have submitted my claim incorrectly?')}
            >
              <Text>
                You will be contacted using the email address you provided if
                there is anything missing with any of your claims submissions.
              </Text>
            </Accordion>

            <Accordion
              title='Can I still submit claims through fax or email?'
              openContent={open === 'a12'}
              onContentOpenChange={this.handleOpen('a12', 'Can I still submit claims through fax or email?')}
            >
              <Text>
                We will still accept claims through fax and email, but you must
                include the claim cover page found{' '}
                <span className='ui-link ui-link--link' onClick={handleClaim}>
                  here
                </span>{' '}
                as the first page of your submission. This page has a unique bar
                code that will be used to identify your claim.
              </Text>
            </Accordion>

            <Accordion
              title='If I call in and update my email address, will I still be able to sign in to the portal?'
              openContent={open === 'a13'}
              onContentOpenChange={this.handleOpen('a13', 'If I call in and update my email address, will I still be able to sign in to the portal?')}
            >
              <Text>
                Yes! If you use your email address as your username, you will
                need to continue to use the original email address. Don't worry
                – you are able to update your username and password within the
                "My Account" section of the portal!
              </Text>
            </Accordion>
          </AccordionGroup>

          <AccordionGroup title='About Automatic Renewals:'>
            <Accordion
              title='What is an automatic renewal?'
              openContent={open === 'a14'}
              onContentOpenChange={this.handleOpen('a14', 'What is an automatic renewal?')}
            >
              <Text>
                Automatic renewal allows you to have your Annual Membership, or
                whiskerDocs and 24PetMedAlert subscription renew automatically
                each year on the anniversary date. If you enroll into automatic
                renewal, the cost of the renewable portion of your membership
                will be billed to the last valid method of payment provided in
                accordance with our billing procedure (see section 6). This will
                continue on an annual basis unless you withdraw from automatic
                renewal.
              </Text>
            </Accordion>

            <Accordion
              title='How do I enroll into or withdraw from automatic renewal?'
              openContent={open === 'a15'}
              onContentOpenChange={this.handleOpen('a15', 'How do I enroll into or withdraw from automatic renewal?')}
            >
              <Text>
                You can enroll into or withdraw from automatic renewal at any
                time by selecting ‘My Pets’ in your account and then clicking on
                the ‘Membership’ tab for the respective pet you wish to update.
              </Text>
            </Accordion>

            <Accordion
              title='Is there a discount if I decide to enroll into automatic renewal?'
              openContent={open === 'a16'}
              onContentOpenChange={this.handleOpen('a16', 'Is there a discount if I decide to enroll into automatic renewal?')}
            >
              <Text>
                Yes. After your first year, the Annual Membership will renew at
                a discounted rate of $19.95 per year, plus applicable taxes.
              </Text>
              <Text>
                24PetMedAlert and whiskerDocs subscriptions are offered at a
                discounted rate of $19.95, plus applicable taxes, for Lifetime
                Protection Members when renewed together. Subscriptions can be
                renewed individually at a rate of $30.00 per year, plus
                applicable taxes for 24PetMedAlert, and $21.00 per year, plus
                applicable taxes for whiskerDocs.
              </Text>
              <Text type={Text.Types.bodyBold}>
                Prices are subject to change.
              </Text>
            </Accordion>

            <Accordion
              title='I purchased a membership plan for my pet. Which of the services are lifetime and which are renewable annually?'
              openContent={open === 'a17'}
              onContentOpenChange={this.handleOpen('a17', 'I purchased a membership plan for my pet. Which of the services are lifetime and which are renewable annually?')}
            >
              <Text>Annual Membership</Text>
              <Text type={Text.Types.bodyBold}>Renewable Services:</Text>

              <ul>
                <li>
                  <Text>
                    Recovery specialists who are available to quickly
                    launch the recovery process
                  </Text>
                </li>
                <li>
                  <Text>
                    Unlimited access to your account through mypethealth.com and
                    through our Customer Service Representatives
                  </Text>
                </li>
                <li>
                  <Text>
                    DirectConnect service to connect you directly with your
                    pet's finder and help arrange a quick and safe reunion
                  </Text>
                </li>
              </ul>

              <Text>Lifetime Protection Membership</Text>
              <Text type={Text.Types.bodyBold}>Lifetime Services:</Text>

              <ul>
                <li>
                  <Text>
                    Recovery specialists who are available to quickly
                    launch the recovery process
                  </Text>
                </li>
                <li>
                  <Text>
                    Unlimited access to your account through mypethealth.com and
                    through our Customer Service Representatives
                  </Text>
                </li>
                <li>
                  <Text>
                    DirectConnect service to connect you directly with your
                    pet's finder and help arrange a quick and safe reunion
                  </Text>
                </li>
              </ul>

              <Text type={Text.Types.bodyBold}>Renewable Services:</Text>

              <ul>
                <li>
                  <Text>
                    <b>24PetMedAlert</b> - critical medical information stored
                    and relayed with your pet’s finder: shelter, rescue or the
                    vet they are brought to
                  </Text>
                </li>
                <li>
                  <Text>
                    <b>whiskerDocs</b> - veterinary professionals available to
                    you 24/7 through live chat, email or by phone
                  </Text>
                </li>
              </ul>
            </Accordion>

            <Accordion
              title='I purchased a membership plan prior to the introduction of automatic renewal (before October 24, 2018). Is my membership set to automatically auto-renew?'
              openContent={open === 'a18'}
              onContentOpenChange={this.handleOpen('a18', 'I purchased a membership plan prior to the introduction of automatic renewal (before October 24, 2018). Is my membership set to automatically auto-renew?')}
            >
              <Text>
                No. If you wish to enroll into automatic renewal, please update
                your preferences within your account by selecting ‘My Pets’ and
                then clicking on the ‘Membership’ tab for the respective pet you
                wish to update.
              </Text>
            </Accordion>

            <Accordion
              title='When will you attempt to charge my credit card?'
              openContent={open === 'a19'}
              onContentOpenChange={this.handleOpen('a19', 'When will you attempt to charge my credit card?')}
            >
              <Text>
                We will make three (3) attempts to bill the renewable portion of
                your membership. The first attempt will be made on your renewal
                date to ensure that your around-the-clock protection continues
                without interruption. The second attempt will be made three (3)
                days after your renewal date in the case where the first attempt
                fails. The third and final attempt will be made six (6) days
                after your renewal date in the case where the second attempt
                fails.
              </Text>
            </Accordion>

            <Accordion
              title='How do I update my payment information?'
              openContent={open === 'a20'}
              onContentOpenChange={this.handleOpen('a20', 'How do I update my payment information?')}
            >
              <Text>
                To update your payment information, please call us at{' '}
                <Link href='tel:18334618756'>1-833-461-8756</Link>.
              </Text>
              <Text>
                We ask that credit card updates be made at least two (2) days
                prior to your next renewal date.
              </Text>
            </Accordion>

            <Accordion
              title='What if my membership plan is not renewed due to non-payment?'
              openContent={open === 'a21'}
              onContentOpenChange={this.handleOpen('a21', 'What if my membership plan is not renewed due to non-payment?')}
            >
              <Text>
                If you have an Annual Membership, your membership will be
                cancelled and you will be notified by email of the cancellation.
                If you have the Lifetime Protection Membership, only the
                24PetMedAlert and whiskerDocs services will be cancelled if
                payment is not received. Should you wish to continue any of the
                services, you can call{' '}
                <Link href='tel:18334618756'>1-833-461-8756</Link> within the
                30-day grace period after the renewal date to have your services
                renewed at the discounted rate.
              </Text>
              <Text>
                When a renewal occurs within the 30-day grace period, your
                membership plan is renewed for another full year. For the
                renewable services under the Lifetime Protection Membership,
                24Petwatch will notify whiskerDocs of the account renewal to
                ensure the service continues for another full year.
              </Text>
            </Accordion>

            <Accordion
              title='I withdrew from automatic renewal too late and my credit card was charged. What is your refund policy?'
              openContent={open === 'a22'}
              onContentOpenChange={this.handleOpen('a22', 'I withdrew from automatic renewal too late and my credit card was charged. What is your refund policy?')}
            >
              <Text>
                If you wish to cancel, you can do so up to thirty (30) days
                after your renewal date.
              </Text>

              <Text>Refund Policy:</Text>

              <ul>
                <li>
                  full refund if cancelled within the 30-day grace period;
                </li>
                <li>
                  50% refund if cancelled after the 30-day grace period but
                  before 6 months;
                </li>
                <li>
                  no refund will be issued if cancelled after 6 months of
                  service
                </li>
              </ul>
            </Accordion>

            <Accordion
              title='Can I enroll into automatic renewal after my renewal date has passed?'
              openContent={open === 'a23'}
              onContentOpenChange={this.handleOpen('a23', 'Can I enroll into automatic renewal after my renewal date has passed?')}
            >
              <Text>
                Yes. We have a 30-day grace period after the renewal date has
                passed allowing you to enroll back into automatic renewal, and
                take advantage of the discounted Annual Membership, or
                whiskerDocs and 24PetMedAlert subscriptions.
              </Text>
            </Accordion>

            <Accordion
              title='What steps do you take to make sure my credit card information is secure and where is it being stored?'
              openContent={open === 'a24'}
              onContentOpenChange={this.handleOpen('a24', 'What steps do you take to make sure my credit card information is secure and where is it being stored?')}
            >
              <Text>
                Our PCI compliance software allows us to collect and store
                payment information securely without risk of the information
                being compromised. When you provide your method of payment over
                the phone or online, the information is stored as a token with
                our payment processor. Only the last four digits and expiry date
                of your credit card are stored within our internal systems to be
                used in communications with you when your products/services come
                up for renewal.
              </Text>
            </Accordion>
          </AccordionGroup>
        </PaperContent>
      </Paper>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text, Icon } from '../../'

export const Step = ({ stepNumber, label, active, completed, onClick }) => {
  const classes = getClasses(active, completed)

  return (
    <div className={cn(classes.container)} role='button' onClick={onClick}>
      <div className={cn(classes.number)}>
        {completed ? <Icon type='done' /> : stepNumber}
      </div>
      <Text type={Text.Types.bodyBold} className={cn(classes.label)}>
        {label}
      </Text>
    </div>
  )
}

Step.propTypes = {
  stepNumber: PropTypes.number,
  label: PropTypes.string,
  active: PropTypes.bool,
  completed: PropTypes.bool,
  onClick: PropTypes.func
}

const getClasses = (active, completed) => {
  const classes = {
    container: ['ui-steps__step'],
    number: ['ui-steps__step__number'],
    label: ['ui-steps__step__label']
  }

  active && classes.container.push('ui-steps__step--active')
  completed && classes.container.push('ui-steps__step--completed')

  return classes
}

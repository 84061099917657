import React from 'react'
import PropTypes from 'prop-types'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Option,
  Text,
  Button
} from '../../'

export class MakePaymentCard extends React.PureComponent {
  static propTypes = {
    caption: PropTypes.string,

    errorMessage: PropTypes.string,
    existingCardOptions: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        onClick: PropTypes.func,
        description: PropTypes.string
      })
    ),
    payWithDifferentCardOption: PropTypes.shape({
      active: PropTypes.bool,
      hidden: PropTypes.bool,
      onClick: PropTypes.func
    }),

    continueButtonProp: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func
    }),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    caption: 'Do you want to make a payment with your saved card?',
    existingCardOptions: [],
    payWithExistingCardOption: {},
    payWithDifferentCardOption: {}
  }

  render() {
    const {
      caption,
      errorMessage,
      existingCardOptions,
      payWithDifferentCardOption,
      continueButtonProp,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={className}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Make a Payment</PaperHeadline>

        <PaperContent className='ui-make-a-payment-card__content'>
          <Text type={Text.Types.caption}>{caption}</Text>

          {errorMessage && (
            <Text
              type={Text.Types.bodyBold}
              className='ui-make-a-payment-card__error-message'
            >
              {errorMessage}
            </Text>
          )}

          <div className='ui-make-a-payment-card__options'>
            {existingCardOptions.map(({ description, ...rest }) => (
              <Option
                key={`payment-option-${description}`}
                label={
                  <>
                    <span className='ui-make-a-payment-card__option__label--desktop'>
                      Pay with my saved credit card
                    </span>
                    <span className='ui-make-a-payment-card__option__label--mobile'>
                      Pay with card
                    </span>
                  </>
                }
                description={<Text>{description}</Text>}
                {...rest}
              />
            ))}

            {!payWithDifferentCardOption.hidden && (
              <Option
                label='Pay with a different card'
                {...payWithDifferentCardOption}
              />
            )}
          </div>

          <Button
            className='ui-make-a-payment-card__action'
            color={Button.Colors.tertiary}
            {...continueButtonProp}
          >
            {continueButtonProp.label}
          </Button>
        </PaperContent>
      </Paper>
    )
  }
}

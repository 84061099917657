import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperContent, PaperHeadline, Button, Text, Link } from '../../'

export class PrivacyCommitmentCard extends React.PureComponent {
  static propTypes = {
    backButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    usLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),
    caLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      backButtonProps,
      usLinkProps,
      caLinkProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <div>
        <Button
          variant={Button.Variants.outlined}
          className='ui-privacy-commitment-card__action-back'
          {...backButtonProps}
        >
          Back to Login
        </Button>

        <Paper
          id={id}
          className={cn(className)}
          style={style}
          ref={refProp}
          type={Paper.Types.framed}
        >
          <PaperHeadline>Our Privacy Commitment to You</PaperHeadline>

          <PaperContent>
            <Text>
              At Pethealth we are dedicated to protecting your privacy and
              ensuring your personal information is kept current, confidential
              and secure. Pethealth is committed to following strict guidelines
              of confidentiality and security to responsibly safeguard your
              information. Our privacy policy protects the collection, use and
              disclosure of your personal information. You have the right to
              access, correct and update your own information at any time.
            </Text>

            <div className='ui-privacy-commitment-card__links'>
              <Link {...usLinkProps} color={Link.Colors.secondary}>
                USA
              </Link>
              <Link {...caLinkProps} color={Link.Colors.secondary}>
                Canada
              </Link>
            </div>
          </PaperContent>
        </Paper>
      </div>
    )
  }
}

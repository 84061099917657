import React from "react";
import { PetTagsCard, Banner } from "../../../UI-Component-Library";
import axios from "../../../custom-axios";
import { AppUtils } from "../../../utils/app-utils";
import { connect } from "react-redux";
import {
  changeCartTotal,
  addToShoppingCartItems,
  changeSelectedPetTagOption,
  removeFromShoppingCartItems,
  syncExistingOrder,
  setPromoCodeApplied,
  setExistingOrdersLoaded,
  changeSeletectedTagShape,
  changeSeletectedTagStyle,
  changeSeletectedTagSize,
} from "../../../redux/reducers/membership-purchase-reducer";
import { MembershipPurchaseUtils } from "./membership-purchase.util";
import { ApiConstants } from "../../../utils/api-constants";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
class TagPurchaseComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedPetId: undefined,
      ...MembershipPurchaseUtils.getDefaultData(),
    };
    this.cRef = React.createRef();
  }
  loadExistingOrders() {
    if (this.props.existingOrdersLoaded) {
      return;
    }
    this.props.setPromoCodeApplied("");
    axios
      .processGet(
        `${
          ApiConstants.URL_LOAD_EXISTING_ORDERS
        }${AppUtils.getBusRelAccount()}`,
        this.cRef
      )
      .then((response) => {
        this.props.setExistingOrdersLoaded(true);
        response.forEach((order) => {
          if (!AppUtils.isEmptyString(order.promoCode)) {
            this.props.setPromoCodeApplied(order.promoCode);
          }
        });
        const orders = MembershipPurchaseUtils.getFilteredOrdersForLifetimeProduct(
          response,
        );
        orders.forEach((order) => {
          this.props.syncExistingOrder({
            selectedPetId: order.animalId,
            item: {
              ...order,
              standardTagList: this.state.standardTagList,
              lifetimeTagList: this.state.lifetimeTagList,
              productName: AppUtils.getProductNameFromItemId(order.itemId),
              productCost: MembershipPurchaseUtils.getPriceForExistingOrder(order),
            },
          });
        });
        orders.forEach((order) => {
          if(
            !this.props.winbackOfferAvailability[order.animalId]
          ){
            const indexes = MembershipPurchaseUtils.getPetIndexAndItemType(
              this.props.petShoppingCart,
              order.animalId,
              "WINBACK Product"
            );
            if(indexes){
              this.props.removeFromShoppingCartItems(indexes);
              return;
            }
          }
          this.props.addToShoppingCartItems({
            selectedPetId: order.animalId,
            item: {
              ...order,
              productName: AppUtils.getProductNameFromItemId(order.itemId),
              productCost: MembershipPurchaseUtils.getPriceForExistingOrder(order),
            },
            isExistingItem: true,
          });
        });
      });
  }
  componentDidMount() {
    this.setState(
      {
        selectedPetId: this.props.selectedPetId,
      },
      this.mapParentPropsToState
    );
  }
  shouldDisablePurchasing() {
    return AppUtils.isEmptyString(
      this.props.animalFullDetails[this.props.selectedPetId]?.microchipNumber
    );
  }
  mapParentPropsToState() {
    const membershipProducts = this.props.membershipProducts;

    let annualMembership, lpmMembership,lpmPlusMembership, petMedAlertProduct, vetHelplineProduct, winBackBundle;
    annualMembership = membershipProducts.annualMembership;
    if (annualMembership) {
      petMedAlertProduct = annualMembership.additionalProductList.find((item) =>
        AppUtils.isEqualString(item.itemName, "24PetMedAlert")
      );
      vetHelplineProduct = annualMembership?.additionalProductList.find(
        (item) => AppUtils.isEqualString(item.itemName, "WD Annual Membership")
      );
    }
    lpmMembership = membershipProducts?.lpmMembership;
    if (lpmMembership) {
      lpmMembership.bundleList = MembershipPurchaseUtils.getFilteredBundleList(
        lpmMembership.bundleList
      );
    }

    lpmPlusMembership = membershipProducts?.lpmPlusMembership;
    if (lpmPlusMembership) {
      lpmPlusMembership.bundleList = MembershipPurchaseUtils.getFilteredBundleList(
        lpmPlusMembership.bundleList
      );
    }

    winBackBundle = membershipProducts?.winBackBundle;
    if (winBackBundle) {
      winBackBundle.bundleList = MembershipPurchaseUtils.getFilteredBundleList(
        winBackBundle.bundleList
      );
    }
    this.setState(
      {
        annualMembership,
        petMedAlertProduct,
        vetHelplineProduct,
        lpmMembership,
        lpmPlusMembership,
        winBackBundle,
        standardTagList: membershipProducts.tags.standardTagList,
        lifetimeTagList: membershipProducts.tags.lifetimeTagList,
      },
      this.loadExistingOrders
    );
  }
  syncPetStyle(tagStyle) {
    this.props.changeSeletectedTagStyle({
      petId: this.props.selectedPetId,
      tagStyle,
    });
  }
  syncPetShape(tagShape) {
    this.props.changeSeletectedTagShape({
      petId: this.props.selectedPetId,
      tagShape,
    });
  }
  syncPetSize(size) {
    this.props.changeSeletectedTagSize({
      petId: this.props.selectedPetId,
      size,
    });
  }
  changePetTagOption(option) {
    let defaultOptions;
    let style, shape, size;
    if (option === "standard") {
      style = PetTagsCard.TagStyles.red;
      shape = PetTagsCard.TagShapes.bone;
      // this.syncPetStyle(PetTagsCard.TagStyles.pink);
      // this.syncPetShape(PetTagsCard.TagShapes.bone);

      defaultOptions = {
        showSizeSelect: false,
      };
    }
    if (option === "lifetime") {
      // this.syncPetStyle(PetTagsCard.TagStyles.traditional);
      // this.syncPetShape(PetTagsCard.TagShapes.bone);
      style = PetTagsCard.TagStyles.traditional;
      shape = PetTagsCard.TagShapes.bone;
      size = "Large";
      defaultOptions = {
        showSizeSelect: true,
      };
    }
    this.props.changeSelectedPetTagOption({
      selectedPetTagOption: option,
      petId: this.props.selectedPetId,
    });
    this.syncPetStyle(style);
    this.syncPetShape(shape);
    this.setState(
      {
        ...defaultOptions,
      },
      () => {
        this.addTagToCart(option, style, shape, size);
      }
    );
  }
  addTagToCart(option, style, shape, size) {
    if (!style) {
      style = this.getSelectedStyle(option);
    }
    if (!shape) {
      shape = this.getSelectedShape(option);
    }
    if (!size) {
      size = this.getSelectedSize();
    }
    this.removePreviousTagsFromCart();
    let item;
    if (option === "standard") {
      const tag = this.state.standardTagList.find((iTag) => {
        return (
          AppUtils.isEqualString(iTag.shape, shape) &&
          AppUtils.isEqualString(iTag.color, style)
        );
      });
      if (tag) {
        item = {
          itemId: tag.itemId,
          itemType: tag.itemType,
          productName: AppUtils.getProductNameFromItemId(tag.itemId),
          productCost: AppUtils.getConvertedPrice(tag.price),
        };
      }
    }
    if (option === "lifetime") {
      const tag = this.state.lifetimeTagList.find((iTag) => {
        return (
          AppUtils.isEqualString(iTag.shape, shape) &&
          AppUtils.isEqualString(iTag.design, style) &&
          AppUtils.isEqualString(iTag.size, size)
        );
      });
      if (tag) {
        item = {
          itemId: tag.itemId,
          itemType: tag.itemType,
          productName: AppUtils.getProductNameFromItemId(tag.itemId),
          productCost: AppUtils.getConvertedPrice(tag.price),
        };
      }
    }
    if (item) {
      DataLayerUtils.addToCartEvent(item);
      this.props.addToShoppingCartItems({
        selectedPetId: this.props.selectedPetId,
        item,
      });
    }
  }
  changeTagStyle(style) {
    const tagType = this.props.selectedPetTagOptions[this.props.selectedPetId];
    this.syncPetStyle(style);
    this.addTagToCart(tagType, style, null, null);
  }
  changeTagShape(shape) {
    const tagType = this.props.selectedPetTagOptions[this.props.selectedPetId];
    this.syncPetShape(shape);
    this.addTagToCart(tagType, null, shape, null);
  }
  changeSize(e) {
    const tagType = this.props.selectedPetTagOptions[this.props.selectedPetId];
    this.syncPetSize(e.target.value);
    this.addTagToCart(tagType, null, null, e.target.value);
  }

  removePreviousTagsFromCart() {
    const indexes = MembershipPurchaseUtils.getPetIndexAndItemType(
      this.props.petShoppingCart,
      this.props.selectedPetId,
      "TagProduct"
    );
    if (indexes) {
      this.props.removeFromShoppingCartItems(indexes);
    }
  }
  getDisabledTags() {
    if (this.shouldDisablePurchasing()) {
      return [
        PetTagsCard.PetTagOptions.lifetime,
        PetTagsCard.PetTagOptions.standard,
        PetTagsCard.PetTagOptions.none,
      ];
    }
    return [];
  }
  getSelectedStyle(option) {
    const style = this.props.selectTagStylesMap[this.props.selectedPetId];
    if (!style) {
      return option === "standard" ? "Red" : "Traditional";
    }
    return style;
  }
  getSelectedSize() {
    const size = this.props.selectTagSizeMap[this.props.selectedPetId];
    return size ? size : "Large";
  }
  getSelectedShape(option) {
    const shape = this.props.selectTagShapesMap[this.props.selectedPetId];
    return shape ? shape : "Bone";
  }
  getMembershipText() {
    return (MembershipPurchaseUtils.isLPMPlusProductSelected(this.props.selectedPlans[this.props.selectedPetId]))
      ? "The Lifetime Protection Membership Plus"
      : "The Lifetime Protection Membership"
  }
  render() {

    return (
      <React.Fragment>
        <div ref={this.cRef}>
          <PetTagsCard
            banner={
              this.props.disableLpmTags[this.props.selectedPetId] ? (
                <Banner
                  title={` ${this.getMembershipText()} includes a Circle Lifetime Warranty Tag.  Please note purchasing any other tags will be an additional cost.`}
                  color={Banner.Colors.secondary}
                />
              ) : null
            }
            disablePetTagOptions={this.getDisabledTags()}
            petTagOption={
              this.props.selectedPetTagOptions[this.props.selectedPetId] ||
              "none"
            }
            onTagOptionChange={(newOption) => {
              this.changePetTagOption(newOption);
            }}
            tagShape={this.props.selectTagShapesMap[this.props.selectedPetId]}
            tagStyle={this.props.selectTagStylesMap[this.props.selectedPetId]}
            onTagShapeChange={(newShape) => {
              this.changeTagShape(newShape);
            }}
            onTagStyleChange={(newStyle) => {
              this.changeTagStyle(newStyle);
            }}
            showSizeSelect={this.state.showSizeSelect}
            sizeSelectProps={{
              value: this.props.selectTagSizeMap[this.props.selectedPetId],
              onChange: (e) => {
                this.changeSize(e);
              },
              options: [
                { value: "Large", label: "Large" },
                { value: "Small", label: "Small" },
              ],
            }}
            standardMetalTagsPrice="$19.95"
            lifetimeWarrantyTagsPrice="$19.95"
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  const membershipPurchaseStore = state.membershipPurchaseStore;
  return {
    selectedPlans: membershipPurchaseStore.selectedPlans,
    petShoppingCart: membershipPurchaseStore.petShoppingCart,
    selectedPetTagOptions: membershipPurchaseStore.selectedPetTagOptions,
    existingOrdersLoaded: membershipPurchaseStore.existingOrdersLoaded,
    selectTagShapesMap: membershipPurchaseStore.selectTagShapesMap,
    selectTagStylesMap: membershipPurchaseStore.selectTagStylesMap,
    selectTagSizeMap: membershipPurchaseStore.selectTagSizeMap,
    winbackOfferAvailability: membershipPurchaseStore.winbackOfferAvailability,
    disableLpmTags: membershipPurchaseStore.disableLpmTags,
    selectedAnimal: customerProfileStore.selectedAnimal,
    selectedPetId: customerProfileStore.selectedPetId,
    animalFullDetails: customerProfileStore.animalFullDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeCartTotal: (price) => dispatch(changeCartTotal(price)),
    setExistingOrdersLoaded: (flag) => dispatch(setExistingOrdersLoaded(flag)),
    changeSelectedPetTagOption: (option) =>
      dispatch(changeSelectedPetTagOption(option)),
    addToShoppingCartItems: (item) => dispatch(addToShoppingCartItems(item)),
    syncExistingOrder: (item) => dispatch(syncExistingOrder(item)),
    removeFromShoppingCartItems: (data) =>
      dispatch(removeFromShoppingCartItems(data)),
    setPromoCodeApplied: (code) => dispatch(setPromoCodeApplied(code)),
    changeSeletectedTagShape: (data) =>
      dispatch(changeSeletectedTagShape(data)),
    changeSeletectedTagStyle: (data) =>
      dispatch(changeSeletectedTagStyle(data)),
    changeSeletectedTagSize: (data) => dispatch(changeSeletectedTagSize(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagPurchaseComponent);

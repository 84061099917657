import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperHeadline,
  PaperContent,
  Text,
  Option,
  Button,
  HeaderDivider
} from '../../'

import { IconText } from '../PurchaseMembershipPlanCard/IconText'
import { LostPet, DirectConnect, MedAlert, HelpLine } from '../SvgIcons'
import { DiscountBadge } from './ChangeMembershipPlanComponents'

export class ChangeMembershipPlan extends React.PureComponent {
  static propTypes = {
    petMedAlertOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      onClick: PropTypes.func,
      price: PropTypes.string
    }),

    vetHelplineOptionProps: PropTypes.shape({
      active: PropTypes.bool,
      onClick: PropTypes.func,
      price: PropTypes.string
    }),
    /**
     * Discount price will be shown when `vetHelplineOptionProps.active && petMedAlertOptionProps.active`
     */
    discountPrice: PropTypes.string,

    changeAccountButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    hideChangeAccountButton: PropTypes.bool,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      changeAccountButtonProps,
      discountPrice,
      petMedAlertOptionProps,
      vetHelplineOptionProps,
      hideChangeAccountButton,
      id,
      className,
      style,
      refProp
    } = this.props

    const {
      price: petMedAlertPrice,
      ...restPetMedAlertOptionProps
    } = petMedAlertOptionProps

    const {
      price: vetHelpLinePrice,
      ...restVetHelplineOptionProps
    } = vetHelplineOptionProps

    const isDiscounted =
      restVetHelplineOptionProps.active && restPetMedAlertOptionProps.active

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={className}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Lifetime membership plan</PaperHeadline>
        <PaperContent>
          <div className='ui-membership-card__icon-text__container'>
            <IconText
              svgIcon={<LostPet />}
              title='Lost Pet Recovery Specialists'
              body='Get 24/7 access to specialists who can quickly launch the process of finding your pet'
            />
            <IconText
              svgIcon={<DirectConnect />}
              title='DirectConnect'
              body='Connect with the person who found your pet, and arrange a quick, safe reunion'
            />
          </div>

          <HeaderDivider className='ui-membership-card__header-divider'>
            Your subscriptions:
          </HeaderDivider>

          <div
            className={cn(
              'ui-membership-card__options__container',
              'ui-membership-card__options__container--discounted'
            )}
          >
            <Option
              showCheckMark
              label={
                <>
                  24PetMedAlert<sup>®</sup> annual subscription
                </>
              }
              description={
                <Text
                  className={
                    isDiscounted && 'ui-change-membership-card__extra-space'
                  }
                >
                  {!isDiscounted && (
                    <>
                      <strong>{petMedAlertPrice}</strong>/year
                    </>
                  )}
                </Text>
              }
              {...restPetMedAlertOptionProps}
            />

            <Option
              showCheckMark
              label='24/7 Vet Helpline annual subscription'
              description={
                <Text
                  className={
                    isDiscounted && 'ui-change-membership-card__extra-space'
                  }
                >
                  {!isDiscounted && (
                    <>
                      <strong>{vetHelpLinePrice}</strong>/year
                    </>
                  )}
                </Text>
              }
              {...restVetHelplineOptionProps}
            />

            {isDiscounted && <DiscountBadge discountPrice={discountPrice} />}
          </div>

          <div className='ui-membership-card__icon-text__container'>
            <IconText
              svgIcon={<MedAlert />}
              title={
                <>
                  24PetMedAlert<sup>®</sup> for 1 year
                </>
              }
              body="Critical medical and behavioral information will be relayed to the shelter or vet they're brought to when found (complimentary for 1 year)."
            />
            <IconText
              svgIcon={<HelpLine />}
              title='24/7 Vet Helpline for 1 year'
              body='Reach veterinary professionals anytime by phone, email or live chat (complimentary for 1 year, provided by whiskerDocs® ).'
            />
          </div>

          {!hideChangeAccountButton && (
            <Button
              className='ui-change-membership-card__action'
              color={Button.Colors.tertiary}
              {...changeAccountButtonProps}
            >
              Change my account
            </Button>
          )}
        </PaperContent>
      </Paper>
    )
  }
}

import React from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'

export class Modal extends React.PureComponent {
  constructor(props) {
    super(props)

    this.portalElement = document.createElement('div')
    this.handleClick = this.handleClick.bind(this)
    this.modalChildren = React.createRef()
  }

  static propTypes = {
    open: PropTypes.bool,
    children: PropTypes.any,
    onClose: PropTypes.func
  }

  handleClick(e) {
    // if the element that targets the click is inside portal
    // important for opening the portal without immediately closing it
    this.portalElement.contains(e.target) &&
      // if the click is outside of modals children, trigger onclose function
      !this.modalChildren.current.contains(e.target) &&
      this.props.onClose?.()
  }

  componentDidMount() {
    document.body.appendChild(this.portalElement)

    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
    document.body.removeChild(this.portalElement)
  }

  render() {
    const { children, open } = this.props

    const portalClass = open
      ? 'ui-modal__portal--open'
      : 'ui-modal__portal--closed'

    return ReactDom.createPortal(
      <div className={cn('ui-modal__portal', portalClass)}>
        <Overlay />
        <div className='ui-modal__portal__children' ref={this.modalChildren}>
          {open && children}
        </div>
      </div>,
      this.portalElement
    )
  }
}

const Overlay = () => <div className='ui-modal__overlay' />

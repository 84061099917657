import React from 'react'
import PropTypes from 'prop-types'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'
import cn from 'classnames'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { Icon, Paper } from '../../'

export class PaperCarousel extends React.PureComponent {
  static propTypes = {
    /**
     * Index of selected item
     */
    selectedItem: PropTypes.number,
    children: PropTypes.array,
    /**
     * function accepts two params (index: number, reactComponent: jsx.element) => {}
     */
    onChange: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      selectedItem,
      onChange,
      id,
      className,
      style,
      refProp,
      children
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-carousel__container', className)}
        style={style}
        ref={refProp}
      >
        <ReactCarousel
          className='ui-carousel'
          selectedItem={selectedItem}
          showStatus={false}
          swipeable={false}
          showIndicators={false}
          showThumbs={false}
          autoPlay={false}
          onChange={onChange}
          renderArrowPrev={(clickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                id="carousel_prev"
                className={cn(
                  'ui-carousel__button',
                  'ui-carousel__button-prev'
                )}
                alt={label}
                onClick={clickHandler}
              >
                <Icon type='navigate_before' />
              </button>
            )
          }
          renderArrowNext={(clickHandler, hasNext, label) =>
            hasNext && (
              <button
                id="carousel_next"
                className={cn(
                  'ui-carousel__button',
                  'ui-carousel__button-next'
                )}
                alt={label}
                onClick={clickHandler}
              >
                <Icon type='navigate_next' />
              </button>
            )
          }
        >
          {children}
        </ReactCarousel>
      </Paper>
    )
  }
}

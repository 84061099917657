import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text, Icon } from '../../'

export class OptionCheckbox extends React.PureComponent {
    static Variants = Object.freeze({
        inactive: 'inactive',
        error: 'error',
        active: 'active',
        disabled: 'disabled'
    })

    static propTypes = {
        variant: PropTypes.oneOf(Object.values(OptionCheckbox.Variants)),
        checked: PropTypes.bool,
        onClick: PropTypes.func,
        strikePrice: PropTypes.bool,

        showCheckMark: PropTypes.bool,
        label: PropTypes.any,
        description: PropTypes.shape({
            price: PropTypes.string,
            frequency: PropTypes.string,
            detail: PropTypes.string
        }),

        id: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        refProp: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]),
        isOneTimeShow: PropTypes.bool
    }

    static defaultProps = {
        variant: OptionCheckbox.Variants.inactive,
        checked: false,
        showCheckMark: true,
        description: {},
        isOneTimeShow: false
    }

    render() {
        const {
            variant,
            onClick,
            showCheckMark,
            strikePrice,
            label,
            description,
            checked,
            id,
            className,
            style,
            refProp,
            isOneTimeShow
        } = this.props

        const classes = getClasses(variant, showCheckMark, checked)

        const checkMarkType = getCheckMarkIconType(variant, showCheckMark, checked)

        const { price, frequency = 'year', detail } = description

        return (
            <div
                id={id}
                className={cn(classes.container, className)}
                style={style}
                ref={refProp}
                role='button'
                onClick={(e) =>
                    variant !== OptionCheckbox.Variants.disabled && onClick(e)
                }
            >
                {checkMarkType && (
                    <Icon className={cn(classes.checkMark)} type={checkMarkType} />
                )}

                <div className={cn(classes.content)}>
                    <Text className={cn(classes.label)} type={Text.Types.bodyBold}>
                        {label}
                    </Text>

                    <div className={cn(classes.descriptionContainer)}>
                        <Text>
                            <span className='ui-option-checkbox__description__price'>
                                {strikePrice ? <del> {price} </del> : price}
                            </span>
                            <span className='ui-option-checkbox__description__freq'>
                                {!isOneTimeShow ? `/${frequency}` : "(one-time fee)"}
                            </span>
                        </Text>
                        <Text className='ui-option-checkbox__description__detail'>
                            {detail}
                        </Text>
                    </div>
                </div>
            </div>
        )
    }
}

const getClasses = (variant, showCheckMark, checked) => {
    const classes = {
        container: ['ui-option-checkbox__container'],
        content: ['ui-option-checkbox__content'],
        checkMark: ['ui-option-checkbox__check-mark'],
        label: ['ui-option-checkbox__label'],
        descriptionContainer: ['ui-option-checkbox__description-container']
    }

    switch (variant) {
        case OptionCheckbox.Variants.disabled:
            classes.container.push('ui-option-checkbox__container--disabled')
            break
        case OptionCheckbox.Variants.active:
            classes.container.push('ui-option-checkbox__container--active')
            break
        case OptionCheckbox.Variants.error:
            classes.container.push('ui-option-checkbox__container--error')
            break
        case OptionCheckbox.Variants.inactive:
        default:
            classes.container.push('ui-option-checkbox__container--inactive')
            break
    }

    showCheckMark &&
        classes.container.push('ui-option-checkbox__container--check-mark')

    showCheckMark &&
        variant !== OptionCheckbox.Variants.inactive &&
        checked &&
        classes.container.push('ui-option-checkbox__container--check-mark-active')

    return classes
}

const getCheckMarkIconType = (variant, showCheckMark, checked) => {
    if (!showCheckMark) return ''

    if (variant === OptionCheckbox.Variants.inactive)
        return 'check_circle_outline'

    return checked ? 'check_circle' : 'check_circle_outline'
}

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Modal, Button, Paper, PaperHeadline, PaperContent, Text } from '../..'

export class RemoveReunificationContactModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    successText: PropTypes.string,
    removeButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    id: PropTypes.string,
    removeButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    cancelTransferButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }
  
  static defaultProps = {
    title: "Are you sure you want to remove the reunification contact of this pet?",
    removeButtonText: "Remove",
    cancelButtonText: "Cancel",
    successText: "",
  }

  render() {
    const {
      open,
      onClose,
      className,
      id,
      title,
      errorMessage,
      removeButtonText,
      cancelButtonText,
      successText,
      removeButtonProps,
      cancelTransferButtonProps
    } = this.props

    return (
      <Modal
        open={open}
        onClose={onClose}
        className={className}
        id={id}
        refProp
      >
        <div className='ui-remove-reunification-contact-modal'>
          <Paper type={Paper.Types.framed}>
            <PaperHeadline> Remove reunification contact </PaperHeadline>
            <PaperContent className='ui-remove-reunification-contact-modal-content'>
              {errorMessage && (
                <Text
                  className='ui-remove-reunification-contact-modal__error-message'
                  type={Text.Types.bodyBold}
                >
                  {errorMessage}
                </Text>
              )}
              <Text type={Text.Types.headlineBold}>
              {title}
              </Text>
              {successText && <Text type={Text.Types.body} className="ui-remove-reunification-contact-card__text">
                {successText}
              </Text> }
              <div className='ui-remove-reunification-contact-modal__actions'>
                <Button
                  variant={Button.Variants.outlined}
                  color={Button.Colors.tertiary}
                  {...cancelTransferButtonProps}
                >
                  {cancelButtonText}
                </Button>
                {!successText && (
                  <Button
                    color={Button.Colors.tertiary}
                    variant={Button.Variants.filled}
                    {...removeButtonProps}
                  >
                    {removeButtonText}
                  </Button>
                )}
              </div>
            </PaperContent>
          </Paper>
        </div>
      </Modal>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Form,
  Input,
  FormAction,
  Button,
  Text,
  Link
} from '../../'
import { Locale as importedLocale } from '../../constants'

export class DepositDetailsCard extends React.PureComponent {
  static Locale = importedLocale

  static propTypes = {
    subtitle: PropTypes.any,
    locale: PropTypes.oneOf(Object.values(DepositDetailsCard.Locale)),
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    onFormSubmit: PropTypes.func,

    accountHolderNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    bankNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    routingNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    transitNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    institutionNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    accountNumberInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Link for `Where do I find these numbers?`
     */
    linkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),
    editLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),

    showSaveChangesButton: PropTypes.bool,
    saveChangesButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    locale: DepositDetailsCard.Locale.us
  }

  render() {
    const {
      subtitle,
      locale,
      successMessage,
      errorMessage,
      onFormSubmit,
      transitNumberInputFieldProps,
      institutionNumberInputFieldProps,
      accountHolderNameInputFieldProps,
      bankNameInputFieldProps,
      routingNumberInputFieldProps,
      accountNumberInputFieldProps,
      linkProps,
      editLinkProps,
      showSaveChangesButton,
      saveChangesButtonProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-deposit-details-card', className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Claim Reimbursement</PaperHeadline>
        <PaperContent>
          <Text type={Text.Types.headline}>Direct Deposit</Text>

          <Text className='ui-deposit-details-card__subtitle'>{subtitle}</Text>

          <Form onSubmit={onFormSubmit}>
            {successMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-deposit-details-card__success-message'
              >
                {successMessage}
              </Text>
            )}

            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-deposit-details-card__error-message'
              >
                {errorMessage}
              </Text>
            )}

            <Input
              label='Account Holder`s Name'
              {...accountHolderNameInputFieldProps}
            />

            <Input label='Bank Name' {...bankNameInputFieldProps} />

            {locale === DepositDetailsCard.Locale.us && (
              <Input label='Routing Number' {...routingNumberInputFieldProps} />
            )}

            {locale === DepositDetailsCard.Locale.ca && (
              <div className='ui-deposit-details-card__input-row'>
                <Input
                  label='Transit Number'
                  {...transitNumberInputFieldProps}
                />

                <Input
                  label='Institution Number'
                  {...institutionNumberInputFieldProps}
                />
              </div>
            )}

            <Input label='Account Number' {...accountNumberInputFieldProps} />

            {!showSaveChangesButton && (
              <Link {...editLinkProps} className={cn("ui-deposit-details-card__edit", editLinkProps?.className)}>
                Edit direct deposit details
              </Link>
            )}

            {showSaveChangesButton && (
              <Link {...linkProps}>Where do I find these numbers?</Link>
            )}

            {showSaveChangesButton && (
              <FormAction>
                <Button
                  color={Button.Colors.tertiary}
                  type='submit'
                  {...saveChangesButtonProps}
                >
                  Save changes
                </Button>
              </FormAction>
            )}
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

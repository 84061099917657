import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperContent, PaperHeadline, Text, Button } from '../../'

export class ReportLostOrFoundPetCard extends React.PureComponent {
  static propTypes = {
    lostPetButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    foundPetButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    lostPetButtonText: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    lostPetButtonText: "I have lost a pet"
  }

  render() {
    const {
      foundPetButtonProps,
      lostPetButtonProps,
      id,
      className,
      style,
      refProp,
      lostPetButtonText
    } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={cn('ui-report-lost-found-pet-card', className)}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Report a lost or found pet</PaperHeadline>
        <PaperContent>
          <Text type={Text.Types.title}>
            While many pets find their way home after a short time, many more
            don’t.
          </Text>
          <Text type={Text.Types.title}>
            Thanks to microchips, 24Petwatch is able to help reunite 1000s of
            lost pets with their owners each month.
          </Text>

          <div className='ui-report-lost-found-pet-card__actions'>
            <Button color={Button.Colors.tertiary} {...lostPetButtonProps}>
              {lostPetButtonText}
            </Button>
            <Button color={Button.Colors.tertiary} {...foundPetButtonProps}>
              I have found a pet
            </Button>
          </div>
        </PaperContent>
      </Paper>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Form,
  Input,
  Text,
  Button,
  FormAction
} from '../../'
import { PasswordInput } from '../PasswordInput'

export class PersonalSignUp extends React.PureComponent {
  static propTypes = {
    errorMessage: PropTypes.string,
    showAccountMessage: PropTypes.bool,
    companyName: PropTypes.string,
    emailInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    checkEmailInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    passwordInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    checkPasswordInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    signUpButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    phoneNumber: PropTypes.string,
    isConfirmEmailHidden: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      errorMessage,
      emailInputProps,
      checkEmailInputProps,
      passwordInputProps,
      checkPasswordInputProps,
      signUpButtonProps,
      companyName,
      id,
      className,
      showAccountMessage,
      phoneNumber,
      isConfirmEmailHidden,
      style,
      refProp
    } = this.props

    return (
      <Paper
        type={Paper.Types.framed}
        id={id}
        className={className}
        style={style}
        ref={refProp}
      >
        <PaperHeadline>Enter your info to sign up</PaperHeadline>

        <PaperContent>
          <Form>
            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-personal-sign-up__error-message'
              >
                {errorMessage}
              </Text>
            )}

            <Input
              type='email'
              autoComplete='email'
              placeholder='Email address'
              label='Email address'
              {...emailInputProps}
            />
            <Input
              type='email'
              placeholder='Re-enter email address'
              label='Re-enter email address'
              {...checkEmailInputProps}
            />
            <PasswordInput
              autoComplete='new-password'
              placeholder='Password'
              label='Password'
              {...passwordInputProps}
            />
            <PasswordInput
              placeholder='Re-enter password'
              label='Re-enter password'
              {...checkPasswordInputProps}
            />

            {!isConfirmEmailHidden && (
              <>
                <Text type={Text.Types.caption}>
                  Please note the email address associated with your social account
                  or input now will be used for any future communications from{' '}
                  {companyName}.
                </Text>
                {showAccountMessage && (
                  <Text type={Text.Types.caption}>
                    If you have an account on 24petwatch.com, you will now need to
                    login using this email address as well. (Changes may take 2-3
                    business days to take effect).
                  </Text>)}
              </>
            )}

            {isConfirmEmailHidden && (<Text type={Text.Types.body} className="ui-personal-sign-up__message">
              To change the email associated with your account or for any assistance please call<br />
              {phoneNumber}.
            </Text>)}

            <Text type={isConfirmEmailHidden ? Text.Types.body : Text.Types.caption}>
              By clicking “Sign up”, you agree to our Terms of Use and Privacy
              Policy including the use of cookies and transfer of your personal
              information to Canada, a jurisdiction that may not provide an
              equivalent level of data protection to the laws in your home
              country.
            </Text>

            <FormAction>
              <Button
                type='submit'
                color={Button.Colors.tertiary}
                {...signUpButtonProps}
              >
                Sign up
              </Button>
            </FormAction>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SignUpStepOne from "./signup-step-one";
import SignUpStepTwo from "./signup-step-two";
import { SignUpSuccess } from "./sign-up-success";

class SignUpContainer extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.history.location.pathname === "/auth/signup" && (
          <Route
            path="/"
            render={() => {
              return <Redirect to="/auth/signup/step1" />;
            }}
          ></Route>
        )}
        <Switch>
          <Route path="/auth/signup/step1" component={SignUpStepOne}></Route>
          <Route path="/auth/signup/step2" component={SignUpStepTwo}></Route>
          <Route path="/auth/signup/success" component={SignUpSuccess}></Route>
        </Switch>
      </React.Fragment>
    );
  }
}
export default SignUpContainer;

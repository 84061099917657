import React from "react";
import { ResetPasswordSuccessCard } from "../../../UI-Component-Library";
import { withRouter } from "react-router-dom";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";

class ResetPasswordSuccess extends React.Component {
  render() {
    return (
      <ResetPasswordSuccessCard
        closeButtonProps={{
          id: "go_back_to_login",
          onClick: () => {
            DataLayerUtils.forgotPasswordEvent("go back to login");
            this.props.history.push("/auth");
          },
        }}
      />
    );
  }
}
export default withRouter(ResetPasswordSuccess);

export class  AppConstants {
  static CONFIG_JSON = {};
  static CONFIG_THEME_JSON = {};
  static COUNTRIES = [
    {
      value: "us",
      label: "United States",
    },
    {
      value: "ca",
      label: "Canada",
    },
  ];

  static SEX = {
    1: "Male",
    2: "Female",
    3: "Unknown"
  }

  static SPAYEDNEUTER = {
    1: "Yes",
    0: "No"
  }

  static REMOVEOPTIONSENUM = {
    GAVE_AWAY: "Gave away",
    DECEASED: "Deceased",
    OTHER : "Other",
    NONE : "None"
  }

  static REMOVEPETOPTIONS = {
    "None": 0,
    "Deceased" : 1,
    "Other" : 2,
    "Gave away" : 3
  }

  static SPECIESMAP = {
    1:"Dog",
    2:"Cat",
    3:"Livestock",
    4:"Bird",
    5:"Horse",
    6:"Pig",
    7:"Rabbit",
    8:"Reptile",
    9:"SmallMammal",
    10:"Other",
  }

  static PHONEOPTIONS = [
    {
      label: "Home",
      value: "Home",
    },
    {
      label: "Mobile",
      value: "Mobile",
    },
    {
      label: "Work",
      value: "Work",
    }
  ]

  static PHONEWISETYPE = {
    HomePhone: "Home",
    CellularPhone: "Mobile",
    BusinessPhone: "Work",
    Phone: "Work"
  }

  static TYPEWISEPHONE = {
    Home: "HomePhone",
    Work: "Phone",
    Mobile: "CellularPhone",
  }


  static RECIPIENT_TYPE = {
     "O_M": "Owner",
     "O_NM": "Owner",
     "S_M": "Secondary owner",
     "S_NM": "Secondary owner",
     "E": "Emergency contact",
     "R": "Finder",
     "M": "Chip implanter",
     "P": "Rescue Contact",
     "V": "Veterinarian Contact",
     "O": "Owner",
     "S": "Secondary Owner",
     "T": "Target",
     "I": "Initiator",
  }

  static ALERT_MESSAGES_FOUND_PET = {
    'S' : "Alert schedule is completed",
    'K' : "Alert schedule is skipped",
    'X' : "Alert schedule is expired",
    'E' : "Alert schedule is having error",
    'C' : "You turned this alert off on"
  }

  static ALERT_OUTGOING_TRANSFER_PUSH = {
    'S': "accepted this transfer on",
    'R': "rejected this transfer on",
    'C': "You cancelled this transfer on",
    'X': "Alert schedule is expired"
  }

  static ALERT_INCOMING_TRANSFER_PUSH = {
    'S': "You accepted this transfer on",
    'R': "You rejected this transfer on",
    'C': "cancelled this transfer on",
    'X': "Alert schedule is expired"
  }

  static NOTIFICATIONTYPES = {
    'FoundPet': 1,
    'Transfer Ownership Push': 2,
    'Transfer Ownership Pull': 3
  }

  static SPECIES = [
    {
      label:"Dog",
      value:"1",
    },
    {
      label:"Cat",
      value:"2",
    },
    {
      label:"Livestock",
      value:"3",
    },
    {
      label:"Bird",
      value:"4",
    },
    {
      label:"Horse",
      value:"5",
    },
    {
      label:"Pig",
      value:"6",
    },
    {
      label:"Rabbit",
      value:"7",
    },
    {
      label:"Reptile",
      value:"8",
    },
    {
      label:"Small Mammal",
      value:"9",
    },
    {
      label:"Other",
      value:"10",
    }
  ]

  static STATES = [
    {
      label: "Start typing...",
      value: "",
    },
    {
      label: "Alabama",
      value: "AL",
    },
    {
      label: "Alaska",
      value: "AK",
    },
    {
      label: "Arizona",
      value: "AZ",
    },
    {
      label: "Arkansas",
      value: "AR",
    },
    {
      label: "California",
      value: "CA",
    },
    {
      label: "Colorado",
      value: "CO",
    },
    {
      label: "Connecticut",
      value: "CT",
    },
    {
      label: "Delaware",
      value: "DE",
    },
    {
      label: "District Of Columbia",
      value: "DC",
    },
    {
      label: "Florida",
      value: "FL",
    },
    {
      label: "Georgia",
      value: "GA",
    },
    {
      label: "Hawaii",
      value: "HI",
    },
    {
      label: "Idaho",
      value: "ID",
    },
    {
      label: "Illinois",
      value: "IL",
    },
    {
      label: "Indiana",
      value: "IN",
    },
    {
      label: "Iowa",
      value: "IA",
    },
    {
      label: "Kansas",
      value: "KS",
    },
    {
      label: "Kentucky",
      value: "KY",
    },
    {
      label: "Louisiana",
      value: "LA",
    },
    {
      label: "Maine",
      value: "ME",
    },
    {
      label: "Maryland",
      value: "MD",
    },
    {
      label: "Massachusetts",
      value: "MA",
    },
    {
      label: "Michigan",
      value: "MI",
    },
    {
      label: "Minnesota",
      value: "MN",
    },
    {
      label: "Mississippi",
      value: "MS",
    },
    {
      label: "Missouri",
      value: "MO",
    },
    {
      label: "Montana",
      value: "MT",
    },
    {
      label: "Nebraska",
      value: "NE",
    },
    {
      label: "Nevada",
      value: "NV",
    },
    {
      label: "New Hampshire",
      value: "NH",
    },
    {
      label: "New Jersey",
      value: "NJ",
    },
    {
      label: "New Mexico",
      value: "NM",
    },
    {
      label: "New York",
      value: "NY",
    },
    {
      label: "North Carolina",
      value: "NC",
    },
    {
      label: "North Dakota",
      value: "ND",
    },
    {
      label: "Ohio",
      value: "OH",
    },
    {
      label: "Oklahoma",
      value: "OK",
    },
    {
      label: "Oregon",
      value: "OR",
    },
    {
      label: "Pennsylvania",
      value: "PA",
    },
    {
      label: "Rhode Island",
      value: "RI",
    },
    {
      label: "South Carolina",
      value: "SC",
    },
    {
      label: "South Dakota",
      value: "SD",
    },
    {
      label: "Tennessee",
      value: "TN",
    },
    {
      label: "Texas",
      value: "TX",
    },
    {
      label: "Utah",
      value: "UT",
    },
    {
      label: "Vermont",
      value: "VT",
    },
    {
      label: "Virginia",
      value: "VA",
    },
    {
      label: "Virgin Islands",
      value: "VI",
    },
    {
      label: "Washington",
      value: "WA",
    },
    {
      label: "West Virginia",
      value: "WV",
    },
    {
      label: "Wisconsin",
      value: "WI",
    },
    {
      label: "Wyoming",
      value: "WY",
    },
  ];

  static PROVINCES = [
    {
      label: "Start typing...",
      value: "",
    },
    {
      value: "AB",
      label: "Alberta",
    },
    {
      value: "BC",
      label: "British Columbia",
    },
    {
      value: "MB",
      label: "Manitoba",
    },
    {
      value: "NB",
      label: "New Brunswick",
    },
    {
      value: "NL",
      label: "Newfoundland and Labrador",
    },
    {
      value: "NS",
      label: "Nova Scotia",
    },
    {
      value: "NU",
      label: "Nunavut",
    },
    {
      value: "NT",
      label: "Northwest Territories",
    },
    {
      value: "ON",
      label: "Ontario",
    },
    {
      value: "PE",
      label: "Prince Edward Island",
    },
    {
      value: "QC",
      label: "Quebec",
    },
    {
      value: "SK",
      label: "Saskatchewan",
    },
    {
      value: "YT",
      label: "Yukon",
    },
  ];

  static CURRENCY_FORMAT = "$";
  static CH_DATE_FORMAT = "L";
  static PD_DATE_FORMAT = "ll";
}

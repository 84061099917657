import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text } from '../Text'

export class DashboardFooter extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { id, className, style, refProp } = this.props

    return (
      <div
        id={id}
        className={cn('ui-dashboard-footer', className)}
        style={style}
        ref={refProp}
      >
        <Text type={Text.Types.caption}>
          You consent and authorize Pethealth Services Inc. to enroll you into
          recurring billing for the above noted products/services via credit
          card on the renewal date, plus applicable taxes. You will be sent a
          reminder email 30 days prior to your renewal date and the charge will
          appear on your credit card statement as 24Petwatch. When you agree to
          renew your products/services, you authorize Pethealth Services Inc. to
          charge your credit card plus applicable taxes. Once charged,
          membership fees for our products/services, discount codes and coupons
          are non-refundable. Prices are subject to change. You have the option
          to withdraw from recurring billing at any time by logging into your
          account at mypethealth.com or calling 1-833-461-8756.
          <br />
        </Text>
      </div>
    )
  }
}

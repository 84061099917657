import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Modal, Button, Paper, PaperHeadline, PaperContent } from '../../'
import { Locale as importedLocale, PUBLIC_URL } from '../../constants'

export class BankingInfoModal extends React.PureComponent {
  static Locale = importedLocale

  static propTypes = {
    locale: PropTypes.oneOf(Object.values(BankingInfoModal.Locale)),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    className: PropTypes.string,
    id: PropTypes.string,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    locale: BankingInfoModal.Locale.us
  }

  render() {
    const { open, locale, onClose, refProp, className, id } = this.props

    const imgSrc =
      locale === BankingInfoModal.Locale.us
        ? 'ddchequeus.png'
        : 'ddchequeca.png'

    return (
      <Modal
        open={open}
        onClose={onClose}
        id={id}
        className={cn(className)}
        ref={refProp}
      >
        <Paper
          type={Paper.Types.framed}
          className={cn('ui-banking-info-modal')}
        >
          <PaperHeadline>How to find your banking{'\u00A0'}info</PaperHeadline>
          <PaperContent>
            <img src={PUBLIC_URL + imgSrc} alt='image of a cheque' />

            <Button onClick={onClose} color={Button.Colors.tertiary}>
              Close
            </Button>
          </PaperContent>
        </Paper>
      </Modal>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import { Paper, PaperHeadline, PaperContent, Text } from '../../'

export class UpgradeAvailableCard extends React.PureComponent {
  static propTypes = {
    message: PropTypes.any,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const { id, className, style, refProp, message } = this.props

    return (
      <Paper
        id={id}
        className={className}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Upgrade Available</PaperHeadline>
        <PaperContent className='ui-upgrade-available-card__content'>
          <Text type={Text.Types.headlineBold}>
            A Message for our Shelter Partners
          </Text>

          <Text>{message}</Text>

          <Text>
            Please click on the link above and add it to your bookmarks. Thank
            you.
          </Text>
        </PaperContent>
      </Paper>
    )
  }
}

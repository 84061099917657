import React from 'react'
import PropTypes from 'prop-types'

import {
  Paper,
  PaperHeadline,
  PaperContent,
  Text,
  Button,
  FormAction,
  Form
} from '../..'

export class NoDataCard extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    message: PropTypes.string,
    phoneNumber: PropTypes.string,
    logoutButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool
    }),
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  render() {
    const {
      id,
      className,
      style,
      refProp,
      logoutButtonProps,
      message
    } = this.props

    return (
      <Paper
        id={id}
        className={className}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Profile Error</PaperHeadline>
        <PaperContent className='ui-no-active-policies-card__content'>
          <Form className=''>
            <Text>{message}.</Text>
            <FormAction>
              <Button
                color={Button.Colors.tertiary}
                type='submit'
                {...logoutButtonProps}
              >
                Logout
              </Button>
            </FormAction>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const Table = ({ children, className, id }) => (
  <table id={id}  className={cn('ui-table', className)}>{children}</table>
)
const TableHead = ({ children, className, id }) => (
  <thead id={id}  className={cn('ui-table__head', className)}>{children}</thead>
)
const TableBody = ({ children, className, id }) => (
  <tbody id={id}  className={cn('ui-table__body', className)}>{children}</tbody>
)
const TableRow = ({ children, className, id }) => (
  <tr id={id}  className={cn('ui-table__row', className)}>{children}</tr>
)
const TableHeaderCell = ({ children, className, id }) => (
  <th id={id} className={cn('ui-table__cell', 'ui-table__header-cell', className)}>
    {children}
  </th>
)
const TableDataCell = ({ children, className, id }) => (
  <td id={id}  className={cn('ui-table__cell', 'ui-table__data-cell', className)}>
    {children}
  </td>
)

const TableComponentProps = {
  children: PropTypes.any,
  className: PropTypes.string
}
Table.propTypes = TableComponentProps
TableHead.propTypes = TableComponentProps
TableBody.propTypes = TableComponentProps
TableRow.propTypes = TableComponentProps
TableHeaderCell.propTypes = TableComponentProps
TableDataCell.propTypes = TableComponentProps

export { Table, TableHead, TableBody, TableRow, TableHeaderCell, TableDataCell }

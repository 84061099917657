import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text, Icon } from '../../'

export class Button extends React.PureComponent {
  static Variants = Object.freeze({
    outlined: 'outlined',
    filled: 'filled',
    inline: 'inline'
  })

  static Colors = Object.freeze({
    secondary: 'secondary',
    danger: 'danger',
    success: 'success',
    tertiary: 'tertiary',
    intermediate:'intermediate'
  })

  static Sizes = Object.freeze({
    small: 'small',
    regular: 'regular'
  })

  static propTypes = {
    color: PropTypes.oneOf(Object.values(Button.Colors)),
    variant: PropTypes.oneOf(Object.values(Button.Variants)),
    size: PropTypes.oneOf(Object.values(Button.Sizes)),
    children: PropTypes.any,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    fluid: PropTypes.bool,
    onClick: PropTypes.func,
    /**
     * Same as Icon `type` prop
     */
    rightIcon: PropTypes.string,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  static defaultProps = {
    variant: Button.Variants.filled,
    color: Button.Colors.secondary,
    size: Button.Sizes.regular,
    disabled: false,
    fluid: false
  }

  render() {
    const {
      variant,
      className,
      children,
      disabled,
      color,
      size,
      fluid,
      onClick,
      refProp,
      type,
      style,
      id,
      rightIcon
    } = this.props

    const classes = getClasses(variant, color, disabled, size, fluid)

    return (
      <button
        type={type}
        ref={refProp}
        role='button'
        disabled={disabled}
        className={cn(classes, className)}
        onClick={onClick}
        id={id}
        style={style}
      >
        <Text type={Text.Types.button}>{children}</Text>
        {rightIcon && (
          <Icon
            className={cn('ui-button__icon', 'ui-button__icon--right')}
            type={rightIcon}
          />
        )}
      </button>
    )
  }
}

export const getClasses = (variant, color, disabled, size, fluid) => {
  const classes = ['ui-button']

  classes.push(getTypeClasses(variant))
  classes.push(getColorClasses(color, disabled))
  classes.push(getSizeClasses(size))
  fluid && classes.push('ui-button--fluid')

  return classes
}

const getTypeClasses = (variant) => {
  switch (variant) {
    case Button.Variants.outlined:
      return 'ui-button--outlined'
    case Button.Variants.inline:
      return 'ui-button--inline'
    case Button.Variants.filled:
    default:
      return 'ui-button--filled'
  }
}

const getColorClasses = (color, disabled) => {
  if (disabled) {
    return 'ui-button--disabled'
  }

  switch (color) {
    case Button.Colors.danger:
      return 'ui-button--danger'
    case Button.Colors.success:
      return 'ui-button--success'
    case Button.Colors.tertiary:
      return 'ui-button--tertiary'
    case Button.Colors.intermediate:
      return 'ui-button--intermediate'
    case Button.Colors.secondary:
    default:
      return 'ui-button--secondary'
  }
}

const getSizeClasses = (size) => {
  switch (size) {
    case Button.Sizes.small:
      return 'ui-button--small'
    case Button.Sizes.regular:
    default:
      return 'ui-button--regular'
  }
}

import React from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Text } from '../../'
import { IconButton } from '../IconButton'
import {
  getArrowPositionStyles,
  getTooltipPositionStyles,
  getPositions
} from './helperFunctions'

export class Tooltip extends React.PureComponent {
  _mounted = false

  static propTypes = {
    children: PropTypes.any,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    /**
     * Name of the child ref prop. e.g. all components in the this ui library have a prop called `refProp`, however, if a regular `button` is rendered the name of the ref prop is `ref`
     */
    nameOfRefProp: PropTypes.string,
    tooltipContent: PropTypes.any
  }

  static defaultProps = {
    open: false,
    nameOfRefProp: 'refProp'
  }

  constructor(props) {
    super(props)

    this.state = {
      tooltipPosition: {},
      arrowPosition: {},
      internalOpen: this.props.open // before the tooltip is shown, we need to get its coords; internalOpen controls the opacity of the tooltip
    }

    this.bodyNode = document.querySelector('body')

    this.tooltipRef = React.createRef()
    this.childNode = React.createRef()
    this.childrenProps = { ...this.props.children.props }

    // in order to get access to the position of a child that triggers tooltip, we need to pass down ref
    // note that this might be overriding the ref that is passed to the component
    // if this becomes a issue the ref needs to be forked
    this.childrenProps[this.props.nameOfRefProp] = this.childNode
  }

  handlePosition = () => {
    if (!this.props.open && !this.state.internalOpen) return

    const [x, y, ax, ay] = getPositions(this.childNode, this.tooltipRef)

    if (
      x &&
      y &&
      (x !== this.state.tooltipPosition.x || y !== this.state.tooltipPosition.y)
    ) {
      const newState = {
        tooltipPosition: { x, y }
      }

      if (ax || ay) {
        newState.arrowPosition = { x: ax, y: ay }
      }

      this.setState((oldState) => ({
        ...oldState,
        ...newState
      }))
    }
  }

  // handles on click outside of the tooltip
  handleClick = (e) => {
    if (
      !this.childNode.current?.contains(e.target) &&
      !this.tooltipRef.current?.contains(e.target)
    ) {
      this.handleClose()
    }
  }

  // closes the tooltip
  handleClose = () => {
    if (this.props.open && this.state.internalOpen) {
      this._mounted &&
        this.setState((oldState) => ({
          ...oldState,
          internalOpen: false
        }))
    }

    this.props?.onClose?.()
  }

  componentDidUpdate() {
    if (this.props.open && this.state.internalOpen) {
      this.handlePosition()
    } else if (this.props.open && !this.state.internalOpen) {
      this.setState((oldState) => ({
        ...oldState,
        internalOpen: true
      }))
    }
  }

  componentDidMount() {
    this._mounted = true
    this.handlePosition()

    window.addEventListener('resize', this.handlePosition)
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    this._mounted = false
    window.removeEventListener('resize', this.handlePosition)
    window.removeEventListener('click', this.handleClick)
  }

  render() {
    const { tooltipPosition, arrowPosition, internalOpen } = this.state
    const { tooltipContent, children, onClose, open } = this.props

    const classes = getClasses(arrowPosition, internalOpen)

    const positionStyles = getTooltipPositionStyles(tooltipPosition)
    const arrowPositionStyles = getArrowPositionStyles(arrowPosition)

    return (
      <>
        {React.cloneElement(children, this.childrenProps)}

        {open &&
          ReactDom.createPortal(
            <div
              role='tooltip'
              className={cn(classes.tooltip)}
              style={{
                ...positionStyles
              }}
              ref={this.tooltipRef}
            >
              <div className={cn(classes.tooltipContent)}>
                <Text renderAs='span'>{tooltipContent}</Text>

                <IconButton onClick={onClose} type='close' />
              </div>

              <i
                className={cn(classes.arrowBorder)}
                style={arrowPositionStyles}
              />
              <i className={cn(classes.arrow)} style={arrowPositionStyles} />
            </div>,
            this.bodyNode
          )}
      </>
    )
  }
}

const getClasses = (arrowPosition, internalOpen) => {
  const classes = {
    tooltip: ['ui-tooltip'],
    tooltipContent: ['ui-tooltip__content'],
    arrow: ['ui-tooltip-arrow'],
    arrowBorder: ['ui-tooltip-arrow__border'],
    closeButton: ['ui-tooltip__close-button']
  }

  internalOpen && classes.tooltip.push('ui-tooltip--open')
  arrowPosition.x && classes.arrow.push('ui-tooltip-arrow--top')
  arrowPosition.x && classes.arrowBorder.push('ui-tooltip-arrow__border--top')
  arrowPosition.y && classes.arrow.push('ui-tooltip-arrow--left')
  arrowPosition.y && classes.arrowBorder.push('ui-tooltip-arrow__border--left')

  return classes
}

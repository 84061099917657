import React from "react";
import { PaymentSettingsCard, Checkbox } from "../../../../UI-Component-Library";
import axios from "../../../../custom-axios";
import { ApiConstants } from "../../../../utils/api-constants";
import { AppUtils } from "../../../../utils/app-utils";
import { AppConstants } from "../../../../utils/app-constants";
import DirectDepositContainer from "./direct-deposit";
import { PaymentUtils } from "./payment-utils";
import { connect } from "react-redux";
import { ValidationMessages } from "../../../../utils/validation-messages";
import { FeatureFlagUtils } from "../../../../utils/feature-flag-utils";
import { DataLayerUtils } from "../../../../utils/dataLayer-utils";

class PaymentSetting extends React.Component {
  constructor() {
    super();
    this.state = {
      isUsCountry: AppUtils.getCountryCode().toLowerCase() === "us",
      billingInfoList: undefined,
      petSelectionFields: [],
      statesList: [],
      showPreAuthorizedWithdrawalField: false,
      ...PaymentUtils.getDefaultData(),
      showFooterText: false,
    };
    this.cRef = React.createRef();
  }
  handleCardExpiryChange(e) {
    const formatted = PaymentUtils.formatExpiryDate(
      this.state.ccInfo?.cardExpiry,
      e.target.value
    );
    let ccInfo = { ...this.state.ccInfo };
    ccInfo[e.target.name] = formatted;

    this.setState({
      ccInfo,
    });
  }
  clearConsents() {
    this.setState({
      membershipConsent: false,
      insuranceConsent: false,
      allProductsConsent: false,
    });
  }
  render() {
    return (
      <React.Fragment>
        <div ref={this.cRef} id="PS_FORM">
          <PaymentSettingsCard
            errorMessage={this.state.errorMessage}
            successMessage={this.state.successMessage}
            locale={
              this.state.isUsCountry
                ? PaymentSettingsCard.Locale.us
                : PaymentSettingsCard.Locale.ca
            }
            showAllowMicrochipMembershipRenewal={PaymentUtils.isMembership(
              this.state.selectedProduct?.productType
            )}
            showAllowInsurancePremium={PaymentUtils.isInsurance(
              this.state.selectedProduct?.productType
            )}
            showUseCreditCardForAllPolicies={true}
            onFormSubmit={() => {}}
            useMailingAddressCheckboxProps={{
              checked: this.state.useMailingAddress,
              color: FeatureFlagUtils.shouldShowTertiaryCheckbox()
              ? Checkbox.Colors.tertiary
              : Checkbox.Colors.secondary,
              onChange: (e) => {
                this.handleUseMailingAddressChange(e);
              },
            }}
            allowMicrochipMembershipRenewalCheckboxProps={{
              name: "membershipConsent",
              id: "membershipConsent",
              checked: this.state.membershipConsent,
              color: FeatureFlagUtils.shouldShowTertiaryCheckbox()
              ? Checkbox.Colors.tertiary
              : Checkbox.Colors.secondary,
              onChange: this.handleConsentChange,
              errorMessage: this.state.errors.membershipConsentError
                ? "Please check the legal consent"
                : "",
            }}
            allowInsurancePremiumCheckboxProps={{
              name: "insuranceConsent",
              id: "insuranceConsent",
              label: FeatureFlagUtils.getPaymentSettingsMessage(),
              checked: this.state.insuranceConsent,
              color: FeatureFlagUtils.shouldShowTertiaryCheckbox()
              ? Checkbox.Colors.tertiary
              : Checkbox.Colors.secondary,
              onChange: this.handleConsentChange,
              errorMessage: this.state.errors.insuranceConsentError
                ? "Please check the legal consent"
                : "",
            }}
            useCreditCardForAllPoliciesCheckboxProps={{
              name: "allProductsConsent",
              id: "allProductsConsent",
              label: PaymentUtils.getLabel(
                this.state.selectedProduct?.productType
              ),
              checked: this.state.allProductsConsent,
              color: FeatureFlagUtils.shouldShowTertiaryCheckbox()
              ? Checkbox.Colors.tertiary
              : Checkbox.Colors.secondary,
              onChange: this.handleConsentChange,
            }}
            petSelectFieldProps={{
              options: this.state.petSelectionFields,
              value: this.state.selectedPetId,
              onChange: (e) => {
                this.handlePetSelectionChange(e.target.value);
              },
              id: "PS_petSelectField",
            }}
            productSelectFieldProps={{
              id: "PS_productSelectField",
              hidden: FeatureFlagUtils.shouldDisableProductSelection(),
              options: this.state.productSelectionFields,
              value: this.state.selectedProduct?.productType,
              onChange: (e) => {
                this.handleProductSelectionChange(e.target.value);
              },
            }}
            paymentMethodSelectFieldProps={{
              id: "PS_paymentSelectField",
              options: this.state.paymentMethodSelectionFields,
              value: this.state.selectedPaymentMethod,
              onChange: (e) => {
                this.handlePaymentMethodChange(e.target.value);
              },
            }}
            creditCardNumberInputFieldProps={{
              id: "PS_cardNumber",
              name: "cardNumber",
              maxLength: 16,
              placeholder: this.state.ccInfo?.placeholders
                ?.cardNumberPlaceholder,
              value: this.state.ccInfo?.cardNumber,
              error: this.state?.errors?.cardNumberError,
              hint: this.state?.errors?.cardNumberError
                ? ValidationMessages.INVALID_CARD_NUMBER
                : "",
              onChange: (event) => {
                if (AppUtils.isOnlyDigitsOrEmptyString(event.target.value)) {
                  this.handleCardInputChange(event);
                }
              },
            }}
            creditCardHolderNameInputFieldProps={{
              id: "PS_cardHolderName",
              name: "cardHolderName",
              maxLength: 30,
              error: this.state?.errors?.cardHolderNameError,
              hint: this.state?.errors?.cardHolderNameError
                ? "Card holder name" + ValidationMessages.IS_REQUIRED
                : "",
              placeholder: this.state.ccInfo?.placeholders
                ?.cardHolderNamePlaceholder,
              value: this.state.ccInfo?.cardHolderName,
              onChange: this.handleCardInputChange,
            }}
            creditCardExpiryInputFieldProps={{
              id: "PS_cardExpiry",
              name: "cardExpiry",
              placeholder: this.state.ccInfo?.placeholders
                ?.cardExpiryPlaceholder,
              value: this.state.ccInfo?.cardExpiry,
              error: this.state?.errors?.cardExpirtyError,
              hint: this.state?.errors?.cardExpirtyError
                ? ValidationMessages.INVALID_CARD_EXPIRY
                : "",
              onChange: (e) => {
                this.handleCardExpiryChange(e);
              },
            }}
            creditCardCVVInputFieldProps={{
              id: "PS_cvv",
              name: "cvv",
              maxLength: 4,
              error: this.state?.errors?.cvvError,
              hint: this.state?.errors?.cvvError
                ? "CVV" + ValidationMessages.IS_REQUIRED
                : "",
              value: this.state.ccInfo?.cvv,
              placeholder: this.state.ccInfo?.placeholders?.cardCvvPlaceholder,
              onChange: (event) => {
                if (AppUtils.isOnlyDigitsOrEmptyString(event.target.value)) {
                  this.handleCardInputChange(event);
                }
              },
            }}
            phoneInputFieldProps={{
              name: "phoneNumber",
              id: "PS_phoneNumber",
              value: this.state.billingAddress.phoneNumber,
              error: this.state?.errors?.phoneNumberError,
              hint: this.state?.errors?.phoneNumberError
                ? ValidationMessages.INVALID_PHONE_NUMBER
                : "",
              placeholder: "Phone Number",
              onChange: (event) => {
                if (AppUtils.isValidPhoneNumberCharacters(event.target.value)) {
                  this.handlePhoneNumber(event);
                }
              },
              maxLength: "12",
            }}
            firstNameInputFieldProps={{
              name: "firstname",
              id: "PS_firstname",
              maxLength: 30,
              error: this.state?.errors?.firstNameError,
              hint: this.state?.errors?.firstNameError
                ? "Firstname" + ValidationMessages.IS_REQUIRED
                : "",
              value: this.state.billingAddress.firstname,
              placeholder: "First Name",
              onChange: this.handleBillingAddressInputChange,
            }}
            lastNameInputFieldProps={{
              name: "lastname",
              id: "PS_lastname",
              maxLength: 30,
              placeholder: "Last Name",
              error: this.state?.errors?.lastNameError,
              hint: this.state?.errors?.lastNameError
                ? "Lastname" + ValidationMessages.IS_REQUIRED
                : "",
              value: this.state.billingAddress.lastname,
              onChange: this.handleBillingAddressInputChange,
            }}
            countrySelectFieldProps={{
              name: "country",
              id: "PS_country",
              value: this.state.billingAddress.country,
              onChange: this.handleBillingAddressInputChange,
              options: AppConstants.COUNTRIES,
              disabled: true,
            }}
            address1InputFieldProps={{
              name: "addressLine1",
              id: "PS_addressLine1",
              maxLength: 30,
              placeholder: "Address Line 1",
              error: this.state?.errors?.addressLine1Error,
              hint: this.state?.errors?.addressLine1Error
                ? "Address" + ValidationMessages.IS_REQUIRED
                : "",
              value: this.state.billingAddress.addressLine1,
              onChange: this.handleBillingAddressInputChange,
            }}
            address2InputFieldProps={{
              name: "addressLine2",
              id: "PS_addressLine2",
              maxLength: 30,
              value: this.state.billingAddress.addressLine2,
              placeholder: "Address Line 2",
              onChange: this.handleBillingAddressInputChange,
            }}
            cityInputFieldProps={{
              name: "city",
              id: "PS_city",
              maxLength: 30,
              error: this.state?.errors?.cityError,
              hint: this.state?.errors?.cityError
                ? "City" + ValidationMessages.IS_REQUIRED
                : "",
              value: this.state.billingAddress.city,
              placeholder: "City",
              onChange: this.handleBillingAddressInputChange,
            }}
            stateSelectFieldProps={{
              name: "state",
              id: "PS_state",
              value: this.state.billingAddress.state,
              error: this.state?.errors?.stateError,
              hint: this.state?.errors?.stateError
                ? "State" + ValidationMessages.IS_REQUIRED
                : "",
              onChange: (e) => {
                const billingAddress = this.state.billingAddress;
                billingAddress.state = e.target.value;
                this.setState({
                  billingAddress,
                });
              },
              options: this.state.statesList,
            }}
            zipInputFieldProps={{
              name: "zipcode",
              id: "PS_zipcode",
              error: this.state?.errors?.zipCodeError,
              hint: this.state?.errors?.zipCodeError
                ? "Zip code" + ValidationMessages.IS_REQUIRED
                : "",
              value: this.state.billingAddress.zipcode,
              onChange: this.handleBillingAddressInputChange,
            }}
            saveChangesButtonProps={{
              id: "PS_saveChangesButton",
              onClick: () => {
                this.saveChanges();
              },
            }}
            showPreAuthorizedWithdrawalField={
              this.state.showPreAuthorizedWithdrawalField
            }
            bankAccountTypeSelectFieldProps={{
              name: "bankAccountType",
              id: "PS_bankAccountType",
              value: this.state.selectedBankAccountType,
              onChange: (e) => {
                this.setState({
                  selectedBankAccountType: e.target.value,
                });
              },
              options: this.state.bankAccountTypeSelectFields,
            }}
            preAuthorizedWithdrawalCountySelectFieldProps={{
              name: "preAuthCountry",
              id: "PS_preAuthCountry",
              value: this.state.selectedPreAuthCountry,
              onChange: (e) => {
                this.setState({
                  selectedPreAuthCountry: e.target.value,
                });
              },
              disabled: true,
              options: AppConstants.COUNTRIES,
            }}
            accountNameInputFieldProps={{
              name: "accountHolderName",
              id: "PS_accountHolderName",
              placeholder: this.state.ddInfo?.placeholders
                ?.accountHolderNamePlaceholder,
              error: this.state?.errors?.accountNameError,
              hint: this.state?.errors?.accountNameError
                ? "Account holder name" + ValidationMessages.IS_REQUIRED
                : "",
              value: this.state.ddInfo?.accountHolderName,
              onChange: this.handlePreAuthDataChange,
            }}
            bankNameInputFieldProps={{
              name: "bankName",
              id: "PS_bankName",
              placeholder: this.state.ddInfo?.placeholders?.bankNamePlaceholder,
              value: this.state.ddInfo?.bankName,
              error: this.state?.errors?.bankNameError,
              hint: this.state?.errors?.bankNameError
                ? "Bank name" + ValidationMessages.IS_REQUIRED
                : "",
              onChange: this.handlePreAuthDataChange,
            }}
            routingNumberInputFieldProps={{
              name: "routingNumber",
              id: "PS_routingNumber",
              placeholder: "Routing Number",
              error: this.state?.errors?.routingNumberError,
              hint: this.state?.errors?.routingNumberError
                ? "Routing number" + ValidationMessages.IS_REQUIRED
                : "",
              value: this.state.ddInfo?.routingNumber,
              onChange: this.handlePreAuthDataChange,
            }}
            transitNumberInputFieldProps={{
              name: "transitNumber",
              id: "PS_transitNumber",
              placeholder: "Transit Number",
              error: this.state?.errors?.transitNumberError,
              hint: this.state?.errors?.transitNumberError
                ? "Transit number" + ValidationMessages.IS_REQUIRED
                : "",
              value: this.state.ddInfo?.transitNumber,
              onChange: this.handlePreAuthDataChange,
            }}
            institutionNumberInputFieldProps={{
              name: "institutionNumber",
              id: "PS_institutionNumber",
              error: this.state?.errors?.institutionNumberError,
              hint: this.state?.errors?.institutionNumberError
                ? "Institution number" + ValidationMessages.IS_REQUIRED
                : "",
              placeholder: "Institution Number",
              value: this.state.ddInfo?.institutionNumber,
              onChange: this.handlePreAuthDataChange,
            }}
            accountNumberInputFieldProps={{
              name: "accountNumber",
              id: "PS_accountNumber",
              placeholder: this.state.ddInfo?.placeholders
                ?.accountNumberPlaceholder,
              error: this.state?.errors?.accountNumberError,
              hint: this.state?.errors?.accountNumberError
                ? "Account number" + ValidationMessages.IS_REQUIRED
                : "",
              value: this.state.ddInfo?.accountNumber,
              onChange: this.handlePreAuthDataChange,
            }}
            footerText={
              this.state.showFooterText
                ? "Payment settings submitted! It may take 2-3 business days for this information to be updated."
                : ""
            }
            showSaveChangesButton={!this.state.showFooterText}
          />
        </div>
        <div>
          <DirectDepositContainer></DirectDepositContainer>
        </div>
      </React.Fragment>
    );
  }

  selectPet(billingInfo) {
    this.setState({
      selectedPetId: billingInfo?.animalId,
      ...PaymentUtils.getDefaultData(),
    });
    this.generateProductSelectionFields(billingInfo);
  }
  generateProductSelectionFields(billingInfo) {
    const selectedPet = billingInfo;

    const productMapping = {
      Insurance: "Insurance only",
      Membership: "Membership only",
    };
    const productSelectionFields = [];
    const selectedPetId = billingInfo?.animalId;
    let allProductList = [];

    if (billingInfo) {
      if (billingInfo?.ccPaymentMethods?.length > 0) {
        billingInfo.ccPaymentMethods.forEach((ccMethod) => {
          productSelectionFields.push({
            value: ccMethod.productType,
            label: productMapping[ccMethod.productType]
              ? productMapping[ccMethod.productType]
              : ccMethod.productType,
          });
          allProductList.push({ ...ccMethod, isCcMethod: true });
        });
      }
      if (billingInfo?.ddPaymentMethod) {
        const productType = billingInfo.ddPaymentMethod.productType;
        const existingProduct = PaymentUtils.getExistingProduct(
          allProductList,
          productType
        );
        if (existingProduct) {
          existingProduct.directDebit = billingInfo.ddPaymentMethod.directDebit;
        } else {
          productSelectionFields.push({
            value: productType,
            label: productMapping[productType]
              ? productMapping[productType]
              : productType,
          });
          allProductList.push({
            ...billingInfo.ddPaymentMethod,
            isCcMethod: false,
          });
        }
      }
      if (billingInfo?.multiProducts) {
        const productType = billingInfo?.multiProducts?.productType;
        productSelectionFields.push({
          value: productType,
          label: productType,
        });
        allProductList.push({ ...billingInfo.multiProducts, isCcMethod: true });
      }
      if (allProductList?.length > 0) {
        allProductList = allProductList.sort((item1, item2) => {
          if (item1.productType < item2.productType) {
            return -1;
          }
          if (item1.productType > item2.productType) {
            return 1;
          }
          return 0;
        });
        this.selectProduct(allProductList[0], selectedPet);
      }
      this.setState({
        productSelectionFields,
        selectedPetId,
        allProductList,
        selectedPet,
      });
    }
  }
  selectProduct(selectedProduct, billingInfo) {
    if (!selectedProduct || !billingInfo) {
      return;
    }
    let paymentMethodSelectionFields = [];
    let showPreAuthorizedWithdrawalField = false;

    let selectedPaymentMethod;
    let ccInfo,
      ddInfo = null;

    if (selectedProduct.isCcMethod) {
      paymentMethodSelectionFields = [
        ...selectedProduct.paymentMethodSelectionList,
      ];
      let selectedCard;
      if (selectedProduct?.creditCardList?.length > 0) {
        selectedCard = selectedProduct.creditCardList[0];
        selectedPaymentMethod = selectedCard.cardType;
      }
      if (!selectedPaymentMethod || selectedPaymentMethod.trim() === "") {
        selectedPaymentMethod = paymentMethodSelectionFields[0].value;
      }
      ccInfo = PaymentUtils.copyCcInfo(selectedCard, selectedPaymentMethod);
    } else {
      if (billingInfo?.ddPaymentMethod?.paymentMethodSelectionList) {
        paymentMethodSelectionFields = [
          ...billingInfo.ddPaymentMethod.paymentMethodSelectionList,
        ];
      }
      selectedProduct = billingInfo.ddPaymentMethod;
      selectedPaymentMethod = selectedProduct.paymentMethod;
      const selectedCard = selectedProduct.directDebit;
      ddInfo = PaymentUtils.copyDdInfo(selectedCard);
      showPreAuthorizedWithdrawalField = true;
    }
    if (!selectedPaymentMethod) {
      selectedPaymentMethod = paymentMethodSelectionFields[0].value;
    }
    this.setState({
      selectedProduct,
      paymentMethodSelectionFields,
      selectedPaymentMethod,
      ddInfo,
      showPreAuthorizedWithdrawalField,
      ccInfo,
    });
  }

  fetchPaymentSettings(showSuccess) {
    axios
      .processGet(
        `${
          ApiConstants.URL_GET_PAYMENT_CARD_INFO
        }${AppUtils.getBusRelAccount()}`,
        this.cRef
      )
      .then((response) => {
        if (!response || !response.data || response.data.length === 0) {
          return;
        }
        let billingInfoList = [];
        const animalData = response.data[0];
        for (let data of response.data) {
          billingInfoList = [
            ...billingInfoList,
            ...data.billingInfoList.map((billingInfo) => {
              return {
                ...billingInfo,
                busrelaccount: data.busRelAccount,
                customerAddress: data.customerAddress,
              };
            }),
          ];
        }
        animalData.billingInfoList = billingInfoList;
        if (showSuccess) {
          AppUtils.focusOnDiv("PS_saveChangesButton");
          this.setState(
            {
              billingInfoList: animalData.billingInfoList,
              showFooterText: true,
            },
            this.mapResponseToCard
          );
        } else {
          this.setState(
            {
              billingInfoList: animalData.billingInfoList,
            },
            this.mapResponseToCard
          );
        }
      });
  }
  handlePaymentMethodChange = (cardType) => {
    this.clearConsents();
    this.setState({
      selectedPaymentMethod: cardType,
    });
    this.clearErrors();
    if (PaymentUtils.isPreAuthWithdrawal(cardType)) {
      this.handleDirectDebitSelected(cardType);
    } else {
      this.handleCreditCardSelected(cardType);
    }
  };
  handleCreditCardSelected(cardType) {
    if (this.state.selectedProduct) {
      const selectedProduct = this.state.selectedProduct;
      if (!selectedProduct.isCcMethod) {
        selectedProduct.isCcMethod = true;
        this.setState({
          selectedProduct,
        });
      }
    }
    const selectedCard = this.state.selectedProduct?.creditCardList?.find(
      (card) => card.cardType === cardType
    );
    let ccInfo = PaymentUtils.copyCcInfo(selectedCard, cardType);
    this.setState({ ccInfo, showPreAuthorizedWithdrawalField: false });
  }
  handleDirectDebitSelected(cardType) {
    if (this.state.selectedProduct) {
      const selectedProduct = this.state.selectedProduct;
      if (selectedProduct.isCcMethod) {
        selectedProduct.isCcMethod = false;
        this.setState({
          selectedProduct,
        });
      }
    }
    const selectedCard = this.state.selectedProduct.directDebit;
    let ddInfo = PaymentUtils.copyDdInfo(selectedCard);
    this.setState({ ddInfo, showPreAuthorizedWithdrawalField: true });
  }
  saveChanges() {
    let membershipConsent = false;
    let insuranceConsent = false;
    const allProductsConsent = this.state.allProductsConsent;
    let allMembershipConsent = false;
    let allInsuranceConsent = false;
    switch (this.state.selectedProduct?.productType) {
      case "Insurance":
        allInsuranceConsent = allProductsConsent;
        insuranceConsent = this.state.insuranceConsent;
        break;
      case "Membership":
        allMembershipConsent = allProductsConsent;
        membershipConsent = this.state.membershipConsent;
        break;
      case "Membership & Insurance":
        allMembershipConsent = allProductsConsent;
        allInsuranceConsent = allProductsConsent;
        insuranceConsent = this.state.insuranceConsent;
        membershipConsent = this.state.membershipConsent;
        break;
      default:
        break;
    }

    if (!this.state.selectedProduct) {
      return;
    }
    let errorMessage = "";
    let successMessage = "";
    this.setState({
      errorMessage,
      successMessage,
    });
    const customerAddress = {
      ...PaymentUtils.getCustomerAddress(
        this.state.billingInfoList,
        this.state.selectedPetId
      ),
    };
    customerAddress.addressLine1 = customerAddress.street;
    let ccInfo = PaymentUtils.getUpdatedCcInfo(this.state.ccInfo);
    if (ccInfo?.cardExpiry && ccInfo?.cardExpiry.length > 0) {
      const splitted = ccInfo.cardExpiry.split("/");
      if (splitted && splitted.length === 2) {
        ccInfo.cardExpiryMonth = splitted[0];
        ccInfo.cardExpiryYear = splitted[1];
      }
    }
    let ddInfo = PaymentUtils.getUpdatedDdInfo(this.state.ddInfo);
    const requestData = {
      emailAddress: AppUtils.getUserEmail(),
      animalId: this.state.selectedPetId,
      productType: this.state.selectedProduct.productType,
      paymentMethod: this.state.selectedPaymentMethod,
      ccInfo:
        this.state.selectedPaymentMethod === "Pre-Authorised Withdrawal"
          ? null
          : ccInfo,
      ddInfo:
        this.state.selectedPaymentMethod === "Pre-Authorised Withdrawal"
          ? ddInfo
          : null,
      billingAddress: this.state.useMailingAddress
        ? customerAddress
        : {
            ...this.state.billingAddress,
          },
      membershipConsent,
      insuranceConsent,
      allMembershipConsent,
      allInsuranceConsent,
    };
    requestData.billingAddress.busrelaccount = PaymentUtils.getBusRelAccount(
      this.state.billingInfoList,
      this.state.selectedPetId
    );
    if (!this.isValidRequest(requestData)) {
      return;
    }

    axios
      .processPost(ApiConstants.URL_UPDATE_CARD_INFO, this.cRef, requestData)
      .then((response) => {
        this.fetchPaymentSettings(true);
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            errorMessage: errors[0],
          });
          DataLayerUtils.formErrorEvent("Payment settings", null, errors[0]);
        }
        AppUtils.focusOnDiv("PS_FORM");
      });
  }
  clearErrors() {
    this.setState({
      errors: PaymentUtils.getDefaultData().errors,
    });
  }
  isValidRequest(requestData) {
    const errors = {
      ...PaymentUtils.getDefaultData().errors,
    };

    const ccInfo = requestData.ccInfo;
    const ddInfo = requestData.ddInfo;
    const billingAddress = requestData.billingAddress;
    if (ccInfo) {
      if (AppUtils.isEmptyString(ccInfo.cardHolderName)) {
        errors.cardHolderNameError = true;
      }
      if (
        AppUtils.isEmptyString(ccInfo.cardNumber) ||
        !AppUtils.isOnlyDigits(ccInfo.cardNumber)
      ) {
        errors.cardNumberError = true;
      }
      if (
        AppUtils.isEmptyString(ccInfo.cvv) ||
        !AppUtils.isOnlyDigits(ccInfo.cvv)
      ) {
        errors.cvvError = true;
      }
      if (
        !AppUtils.isValidExpiry(ccInfo.cardExpiry) ||
        ccInfo.cardExpiryMonth < 1 ||
        ccInfo.cardExpiryMonth > 12
      ) {
        errors.cardExpirtyError = true;
      }
    }
    if (ddInfo) {
      if (AppUtils.isEmptyString(ddInfo.accountHolderName)) {
        errors.accountNameError = true;
      }
      if (AppUtils.isEmptyString(ddInfo.bankName)) {
        errors.bankNameError = true;
      }
      if (AppUtils.isEmptyString(ddInfo.routingNumber)) {
        errors.routingNumberError = true;
      }
      if (AppUtils.isEmptyString(ddInfo.accountNumber)) {
        errors.accountNumberError = true;
      }
      if (AppUtils.isEmptyString(ddInfo.institutionNumber)) {
        errors.institutionNumberError = true;
      }
      if (AppUtils.isEmptyString(ddInfo.transitNumber)) {
        errors.transitNumberError = true;
      }
    }
    if (
      PaymentUtils.isInsurance(requestData.productType) &&
      !this.state.insuranceConsent
    ) {
      errors.insuranceConsentError = true;
    }
    if (
      PaymentUtils.isMembership(requestData.productType) &&
      !this.state.membershipConsent
    ) {
      errors.membershipConsentError = true;
    }
    if (this.state.isUsCountry) {
      delete errors.transitNumberError;
      delete errors.institutionNumberError;
    } else {
      delete errors.routingNumberError;
    }
    if (!this.state.useMailingAddress) {
      if (!AppUtils.isValidPhoneNumber(billingAddress.phoneNumber)) {
        errors.phoneNumberError = true;
      }
      if (AppUtils.isEmptyString(billingAddress.firstname)) {
        errors.firstNameError = true;
      }
      if (AppUtils.isEmptyString(billingAddress.lastname)) {
        errors.lastNameError = true;
      }
      if (AppUtils.isEmptyString(billingAddress.zipcode)) {
        errors.zipCodeError = true;
      }
      if (AppUtils.isEmptyString(billingAddress.state)) {
        errors.stateError = true;
      }
      if (AppUtils.isEmptyString(billingAddress.city)) {
        errors.cityError = true;
      }
      if (AppUtils.isEmptyString(billingAddress.addressLine1)) {
        errors.addressLine1Error = true;
      }
    }
    this.setState({
      errors: { ...errors },
    });

    let isValid = true;
    for (let key in errors) {
      if (errors[key]) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }

  mapResponseToCard = () => {
    const petSelectionFields = [];
    if (this.state.billingInfoList?.length > 0) {
      const selectedPet = this.state.billingInfoList[0];
      this.selectPet(selectedPet);
      this.state.billingInfoList.forEach((info) => {
        petSelectionFields.push({
          value: info.animalId,
          label: info.petName,
        });
      });
    }
    this.setState({
      petSelectionFields,
    });
  };
  componentDidMount() {
    const isUs = this.state.isUsCountry;
    this.fetchPaymentSettings();
    const statesList = isUs ? AppConstants.STATES : AppConstants.PROVINCES;
    this.setState({
      statesList: [
        { value: "", label: isUs ? "Select State" : "Select Province" },
        ...statesList,
      ],
    });
  }
  handleUseMailingAddressChange(event) {
    this.clearErrors();
    this.setState({
      useMailingAddress: event.target.checked,
    });
  }
  handleCardInputChange = (e) => {
    let ccInfo = { ...this.state.ccInfo };
    ccInfo[e.target.name] = e.target.value;
    this.setState({
      ccInfo,
    });
  };

  handleBillingAddressInputChange = (e) => {
    let billingAddress = { ...this.state.billingAddress };
    billingAddress[e.target.name] = e.target.value;
    this.setState({
      billingAddress,
    });
  };

  handlePhoneNumber = (e) => {
    const phoneNumberElement = document.getElementById("PS_phoneNumber");
    const phoneElements = phoneNumberElement.value.split("");
    if (
      phoneNumberElement.value.length === 4 &&
      this.state.billingAddress.phoneNumber.length !== 5
    ) {
      phoneNumberElement.value =
        phoneNumberElement.value.substr(0, 3) + "-" + phoneElements[3];
    }

    if (
      phoneNumberElement.value.length === 8 &&
      this.state.billingAddress.phoneNumber.length !== 9
    ) {
      const phoneElements = phoneNumberElement.value.split("");
      phoneNumberElement.value =
        phoneNumberElement.value.substr(0, 7) + "-" + phoneElements[7];
    }

    let billingAddress = { ...this.state.billingAddress };
    billingAddress[e.target.name] = e.target.value;
    this.setState({
      billingAddress,
    });
  };

  handlePreAuthDataChange = (e) => {
    let ddInfo = { ...this.state.ddInfo };
    ddInfo[e.target.name] = e.target.value;
    this.setState({
      ddInfo,
    });
  };
  handleConsentChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };
  handlePetSelectionChange(selectedAnimalId) {
    this.clearErrors();
    const selectedPetId = this.state.billingInfoList.find(
      (pet) => pet.animalId === selectedAnimalId
    );
    this.selectPet(selectedPetId);
  }
  handleProductSelectionChange(productType) {
    this.clearConsents();
    this.clearErrors();
    const selectedProduct = this.state.allProductList.find(
      (product) => product.productType === productType
    );
    this.selectProduct(selectedProduct, this.state.selectedPet);
  }
}

const mapStateToProps = (state) => {
  const customerProfileStore = state.customerProfileStore;
  return {
    selectedPetId: customerProfileStore.selectedPetId,
  };
};

export default connect(mapStateToProps, null)(PaymentSetting);
